// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OdvyWKPzxZnE6QbvPq2v {\n    background-color: var(--highlight-word-bg-color);\n}\n", "",{"version":3,"sources":["webpack://./base/components/word-highlighted/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;AACpD","sourcesContent":[".highlighted {\n    background-color: var(--highlight-word-bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted": "OdvyWKPzxZnE6QbvPq2v"
};
export default ___CSS_LOADER_EXPORT___;
