// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zs7KAZDl793uKcT1Qde8 {\n    text-align: center;\n    font-weight: 600;\n}\n\n.igjhjbg6WCczVmCjenID {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.uas1lJEYn5_AyjOYixUV {\n    text-align: left;\n    padding: 0 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-grade-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".header {\n    text-align: center;\n    font-weight: 600;\n}\n\n.content {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.form {\n    text-align: left;\n    padding: 0 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Zs7KAZDl793uKcT1Qde8",
	"content": "igjhjbg6WCczVmCjenID",
	"form": "uas1lJEYn5_AyjOYixUV"
};
export default ___CSS_LOADER_EXPORT___;
