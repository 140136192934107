import React, { useState, useEffect } from "react";

import classNames from "@/base/lib/class-names.js";

import ButtonFlat from "@/base/components/button-flat/index.js";

import styles from "./styles.module.css";


const SnackbarBox = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [shouldAutoClose, setShouldAutoClose] = useState(false);

    /* --- */

    const onClose = (closeFn) => {
        if (!isVisible) {
            return;
        }

        setIsVisible(false);

        globalThis.setTimeout(closeFn, 1000);
    };

    /* --- */

    useEffect(() => {
        globalThis.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        globalThis.setTimeout(() => {
            setShouldAutoClose(true);
        }, props.autoCloseInSeconds * 1000);
    }, []);

    useEffect(() => {
        if (!shouldAutoClose) {
            return;
        }

        onClose(props.onClose);
    }, [shouldAutoClose]);

    /* --- */

    const renderControls = () => {
        if (!props.withUndo) {
            return null;
        }

        return (
            <div className={styles.controls}>
                <ButtonFlat
                    onClick={() => {
                        onClose(props.onCloseWithUndo);
                    }}
                    uppercase
                >
                    Undo
                </ButtonFlat>
            </div>
        );
    };

    /* --- */

    const snackbarClassName = classNames({
        [styles.snackbar]: true,
        [styles.snackbarVisible]: isVisible,
    });

    return (
        <div className={snackbarClassName}>
            <div className={styles.message}>
                {props.message}
            </div>
            {renderControls()}
        </div>
    );
};

SnackbarBox.defaultProps = {
    message: "",
    autoCloseInSeconds: 5,
    onClose: () => { },
    onCloseWithUndo: () => { },
    withUndo: false,
};

export default SnackbarBox;
