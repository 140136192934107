import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconArrowDouble = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="9"
                    height="12"
                    viewBox="0 0 9 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M9 1.81641L7.94531 0.761719L4.5 4.17188L1.05469 0.761719L0 1.81641L4.5 6.31641L9 1.81641ZM9 6.73828L7.94531 5.68359L4.5 9.12891L1.05469 5.68359L0 6.73828L4.5 11.2383L9 6.73828Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconArrowDouble;
