import React from "react";

import device from "@/base/lib/device.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import Video from "@/base/components/video/index.js";

import styles from "./styles.module.css";


const ContentVideo = (props) => {
    if (props.isLoading) {
        return (
            <div className={styles.popupContent}>
                <RequestLoader />
            </div>
        );
    }

    return (
        <div className={styles.popupContent}>
            <Video
                videoId={props.id}
                src={props.src}
                poster={props.poster}
                type={props.mimeType}
                captionSrc={props.captionSrc}
                videoHeight={410}
                isDefault={!device.isChrome}
                withPosterCover={false}
            />
        </div>
    );
};

ContentVideo.defaultProps = {
    id: "",
    src: "",
    poster: "",
    mimeType: "",
    captionSrc: "",
    isLoading: false,
};

export default ContentVideo;
