export default (apiURL, methods) => {
    return {
        generateClassCode(params = {}) {
            // NOTE: action=sign_up_generate_class_code
            const url = `${apiURL}/signup/class-code/generate`;

            return methods.get3(url, {
                session_id: params.session,
                class_id: params.classId,
            });
        },

        validateClassCode(params = {}) {
            // NOTE: action=sign_up_validate_class_code
            const url = `${apiURL}/signup/class-code/validate`;

            return methods.get3(url, {
                code: params.code,
            });
        },

        sendInvitation(params = {}) {
            // NOTE: action=sign_up_send_invitation_link_by_teacher
            const url = `${apiURL}/signup/invitation`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                emails: params.emails,
            });
        },

        postPersonalInfo(params = {}) {
            // NOTE: action=sign_up
            const url = `${apiURL}/signup`;

            return methods.postForm(url, {
                username: params.username || "",
                first_name: params.firstName || "",
                last_name: params.lastName || "",
                email: params.email || "",
                code: params.code || "",
            });
        },

        postPassword(params = {}) {
            // NOTE: action=sign_up_set_password_by_username
            const url = `${apiURL}/signup/password`;

            return methods.postForm(url, {
                username: params.username,
                password: params.password,
            });
        },

        /* --- */

        validateReferralCode(params = {}) {
            // NOTE: action=get_referral_code_by_code
            const url = `${apiURL}/signup/referral-code/validate`;

            return methods.get3(url, {
                code: params.code,
            });
        },

        signUpByReferralCode(params = {}) {
            // NOTE: action=sign_up_by_referral_code
            const url = `${apiURL}/signup/referral-code`;

            return methods.postForm(url, {
                code: params.code || "",
                username: params.username || "",
                first_name: params.firstname || "",
                last_name: params.lastname || "",
                email: params.email || "",
                password: params.password || "",
            });
        },
    };
};
