import text from "@/base/text/index.js";


const confirmBadEmail = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.user.confirmBadEmail({
            session: state.user.session,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setIsBadEmail(false));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const changeBadEmail = (services, params) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { email } = params;

        const res = await api.user.updateBadEmail({
            session: state.user.session,
            email,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setEmail(email));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

export default {
    confirmBadEmail,
    changeBadEmail,
};
