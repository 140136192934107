export default (apiURL, methods) => {
    return {
        getStandardsByState(params = {}) {
            // NOTE: action=get_standards_by_state
            const url = `${apiURL}/standards/by-state`;

            return methods.get3(url, {
                state: params.state || "",
            });
        },

        getStandardsByGroups(params = {}) {
            // NOTE: action=get_standards_by_anchor_groups
            const url = `${apiURL}/standards/groups`;

            return methods.get3(url, {
                session_id: params.session || "",
                state: params.state || "",
            });
        },
    };
};
