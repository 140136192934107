export const SET_DEFAULT_TRANSLATION_LANGUAGE = "SET_DEFAULT_TRANSLATION_LANGUAGE";

export const SET_TRANSLATION_LANGUAGES = "SET_TRANSLATION_LANGUAGES";

export const SET_TRANSLATION_STORY_LOADING_BY_ID = "SET_TRANSLATION_STORY_LOADING_BY_ID";
export const SET_TRANSLATION_STORY_ERROR_BY_ID = "SET_TRANSLATION_STORY_ERROR_BY_ID";
export const SET_TRANSLATION_STORY_BY_ID = "SET_TRANSLATION_STORY_BY_ID";

export const SET_TRANSLATION_VOCAB_WORD_LOADING = "SET_TRANSLATION_VOCAB_WORD_LOADING";
export const SET_TRANSLATION_VOCAB_WORD = "SET_TRANSLATION_VOCAB_WORD";

export const UI_CHANGE_STORY_TRANSLATION = "UI_CHANGE_STORY_TRANSLATION";
