import React from "react";

import text from "@/base/text/index.js";

import staticFiles from "@/base/static-files.js";

import styles from "./styles.module.css";
import ButtonLink from "./button-link.js";


const UserMainFooter = (props) => {
    const renderLogo = () => {
        return (
            <img
                className={styles.logo}
                src={staticFiles.juiceLogoRegular}
                alt="The Juice"
            />
        );
    };

    const renderCopyright = () => {
        return (
            <div className={styles.copyright}>
                <div>Copyright</div>
                <div>
                    &copy;
                    {`${props.copyright}.`}
                </div>
                <div>All rights reserved.</div>
            </div>
        );
    };

    const renderLinks = () => {
        return (
            <div className={styles.links}>
                <div>
                    <ButtonLink
                        title="Privacy Policy"
                        href={props.linkPrivacyPolicy}
                        dataComment="privacy-policy"
                    />
                    <ButtonLink
                        title={text.termsOfUse}
                        href={props.linkTermsOfUse}
                        dataComment="terms-of-use"
                    />
                    <ButtonLink
                        title="FAQ"
                        href={props.linkFaq}
                        dataComment="faq"
                    />
                    <ButtonLink
                        title="Support"
                        href={props.linkSupport}
                        dataComment="support"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.footer}>
            {renderLogo()}
            {renderCopyright()}
            {renderLinks()}
        </div>
    );
};

UserMainFooter.defaultProps = {
    linkPrivacyPolicy: "",
    copyright: "",
    linkSupport: "",
    linkFaq: "",
    linkTermsOfUse: "",
};

export default UserMainFooter;
