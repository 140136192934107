import React, { useState } from "react";

import Assignments from "@/base/project/assignments.js";
import Grades from "@/base/project/grades.js";
import Standards from "@/base/project/standards.js";

import classNames from "@/base/lib/class-names.js";
import date from "@/base/lib/date.js";

import text from "@/base/text/index.js";

import useAccordion from "@/base/hooks/use-accordion/index.js";

import IconDownload from "@/base/icons/download/index.js";
import IconMenuFurl from "@/base/icons/menu-furl/index.js";
import IconMenuUnfurl from "@/base/icons/menu-unfurl/index.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import DLCustom from "@/base/components/dl-custom/index.js";
import Message from "@/base/components/message/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";
import ButtonFAB, { ButtonFabPosition } from "@/base/components/button-fab/index.js";

import styles from "./styles.module.css";

import ColumnNameWithSort from "./column-name-with-sort.js";
import RowText from "./row-text.js";


const SORT_BY = Assignments.getSortValues();

const AssignmentsTable = (props) => {
    const [sortBy, setSortBy] = useState(null);

    const expandedRows = useAccordion();

    /* --- */

    const getStandardValue = (data) => {
        const withStateStandards = Standards.hasStateStandard(data.standards);

        const standardValue = Standards.getDefaultStandard(
            props.withCommonCore,
            withStateStandards,
        );

        const standard = Standards.getStandardsByType(
            data.standards,
            standardValue,
        );

        return standard?.[0]?.standard || "";
    };

    const getFormattedDate = (d) => {
        if (!d) {
            return "-";
        }

        return date.formatMonthDayYear(date.newDateUTC(d));
    };

    const getGrade = (score) => {
        if (score === -1) {
            return "-";
        }

        return `${score}%`;
    };

    /* --- */

    const onToggleAllRows = () => {
        const indexes = [];

        for (let i = 0; i < props.data.length; i += 1) {
            indexes.push(i);
        }

        expandedRows.toggleAll(indexes);
    };

    /* --- */

    const onSort = (from, to, onClick) => {
        return () => {
            const newSort = sortBy === from ? to : from;

            setSortBy(newSort);
            onClick(newSort);
        };
    };

    /* --- */

    const renderButtonsFAB = () => {
        if (props.isLoading) {
            return null;
        }

        const downloadIcon = (
            <IconDownload isSky />
        );

        let iconToggle = null;

        if (props.data.length === expandedRows.expanded.length) {
            iconToggle = (
                <IconMenuFurl isWhite />
            );
        } else {
            iconToggle = (
                <IconMenuUnfurl isWhite />
            );
        }

        return (
            <>
                <ButtonFabPosition
                    placeUpper
                    alignRightByVW={props.alignButtonFABByVW}
                >
                    <ButtonFAB
                        icon={downloadIcon}
                        onClick={props.onExport}
                        whiteTheme
                    />
                </ButtonFabPosition>
                <ButtonFabPosition
                    alignRightByVW={props.alignButtonFABByVW}
                >
                    <ButtonFAB
                        icon={iconToggle}
                        onClick={onToggleAllRows}
                    />
                </ButtonFabPosition>
            </>
        );
    };

    const renderExportButton = () => {
        const icon = (
            <IconDownload
                title="Download"
                isSky
            />
        );

        return (
            <div className={styles.exportButton}>
                <ButtonCircle
                    icon={icon}
                    onClick={props.onExport}
                />
            </div>
        );
    };

    const renderHeaderDesktop = () => {
        const columns = [
            {
                name: "Assigned",
                isSorted: sortBy === SORT_BY.dateAssignedNewestFirst,
                onClick: onSort(
                    SORT_BY.dateAssignedNewestFirst,
                    SORT_BY.dateAssignedOldestFirst,
                    props.onSortByDateAssigned,
                ),
            },
            {
                name: "Completed",
                isSorted: sortBy === SORT_BY.dateCompletedNewestFirst,
                onClick: onSort(
                    SORT_BY.dateCompletedNewestFirst,
                    SORT_BY.dateCompletedOldestFirst,
                    props.onSortByDateCompleted,
                ),
            },
            {
                name: "Grade",
                isSorted: sortBy === SORT_BY.gradeHighToLow,
                onClick: onSort(
                    SORT_BY.gradeHighToLow,
                    SORT_BY.gradeLowToHigh,
                    props.onSortByGrade,
                ),
            },
            { name: "Article" },
            { name: "Question" },
            {
                name: "Reading Level",
                isSorted: sortBy === SORT_BY.readingLevelHighToLow,
                onClick: onSort(
                    SORT_BY.readingLevelHighToLow,
                    SORT_BY.readingLevelLowToHigh,
                    props.onSortByReadingLevel,
                ),
            },
            {
                name: "Standard",
                isSorted: sortBy === SORT_BY.standardHighToLow,
                onClick: onSort(
                    SORT_BY.standardHighToLow,
                    SORT_BY.standardLowToHigh,
                    props.onSortByStandard,
                ),
            },
            {
                customElement: renderExportButton(),
            },
        ];

        const columnsDesktop = columns.map((column) => {
            if (column.customElement) {
                return column.customElement;
            }

            if (column.onClick) {
                return (
                    <ColumnNameWithSort
                        name={column.name}
                        onClick={column.onClick}
                        isSorted={column.isSorted}
                    />
                );
            }

            const columnClassName = classNames({
                [styles.columnName]: true,
                [column.className]: column.className,
            });

            return (
                <div className={columnClassName}>
                    {column.name}
                </div>
            );
        });

        return (
            <div className={styles.rowDesktopHeader}>
                {columnsDesktop}
            </div>
        );
    };

    const renderRows = () => {
        const sortedData = Assignments.sort(props.data, sortBy);

        const rows = sortedData.map((row) => {
            const dateAssigned = getFormattedDate(row.dateAssigned);
            const dateCompleted = getFormattedDate(row.dateCompleted);

            const readingLevel = Grades.getGradeGroup(row.grades);

            const standardsId = row?.quiz?.standardId || null;
            const standards = row?.quiz?.standards || [];

            return (
                <div className={styles.row}>
                    <RowText
                        text={dateAssigned}
                    />
                    <RowText
                        text={dateCompleted}
                    />
                    <RowText
                        text={getGrade(row.score)}
                    />
                    <RowText
                        text={row.storyName}
                        onClick={() => {
                            props.onOpenStory(row.storyId);
                        }}
                        isClickable
                    />
                    <RowText
                        text={row.storyQuestion}
                        onClick={() => {
                            props.onOpenQuiz(row.quiz);
                        }}
                        isClickable
                    />
                    <RowText
                        text={readingLevel}
                    />
                    <RowText
                        text={getStandardValue(row)}
                        onClick={() => {
                            props.onOpenStandards(standardsId, standards);
                        }}
                        isClickable
                    />
                </div>
            );
        });

        return rows;
    };

    const renderLoadMoreButton = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!props.hasMore) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonFlat
                    uppercase
                    onClick={props.onLoadMore}
                >
                    Load more
                </ButtonFlat>
            </div>
        );
    };

    /* --- */

    const renderMobileTable = () => {
        const rows = props.data.map((d, index) => {
            const grades = Grades.getGradeGroup(d.grades);

            const standardsId = d?.quiz?.standardId || null;
            const standards = d?.quiz?.standards || [];
            const dateCompleted = getFormattedDate(d.dateCompleted);

            const values = [
                { label: "Completed", value: dateCompleted },
                { label: "Grade", value: getGrade(d.score) },
                {
                    label: "Article",
                    value: (
                        <RowText
                            text={d.storyName}
                            onClick={() => {
                                props.onOpenStory(d.storyId);
                            }}
                            isClickable
                            isMobile
                        />
                    ),
                },
                {
                    label: "Question",
                    value: (
                        <RowText
                            text={d.storyQuestion}
                            onClick={() => {
                                props.onOpenQuiz(d.quiz);
                            }}
                            isClickable
                            isMobile
                        />
                    ),
                },
                { label: "Reading Level", value: grades },
                {
                    label: "Standard",
                    value: (
                        <RowText
                            text={getStandardValue(d)}
                            onClick={() => {
                                props.onOpenStandards(standardsId, standards);
                            }}
                            isClickable
                            isMobile
                        />
                    ),
                },
            ];

            const dateAssigned = getFormattedDate(d.dateAssigned);

            return (
                <div className={styles.rowMobile}>
                    <DLCustom
                        title={dateAssigned}
                        values={values}
                        isExpanded={expandedRows.isExpanded(index)}
                        onToggle={() => {
                            expandedRows.onSetExpanded(index);
                        }}
                    />
                </div>
            );
        });

        return (
            <>
                {rows}
                {renderLoadMoreButton()}
                {renderButtonsFAB()}
            </>
        );
    };

    const renderDesktopTable = () => {
        return (
            <>
                {renderHeaderDesktop()}
                {renderRows()}
                {renderLoadMoreButton()}
            </>
        );
    };

    /* --- */

    if (props.data.length === 0 && !props.isLoading) {
        return (
            <Message>
                {text.noAssignments}
            </Message>
        );
    }

    if (props.isMobile) {
        return renderMobileTable();
    }

    return renderDesktopTable();
};

AssignmentsTable.defaultProps = {
    data: [],
    onSortByDateAssigned: () => { },
    onSortByDateCompleted: () => { },
    onSortByGrade: () => { },
    onSortByReadingLevel: () => { },
    onSortByStandard: () => { },
    onLoadMore: () => { },
    onOpenStory: () => { },
    onOpenStandards: () => { },
    onOpenQuiz: () => { },
    onExport: () => { },
    isLoading: false,
    isMobile: false,
    hasMore: false,
    alignButtonFABByVW: false,
    withCommonCore: false,
};

export default AssignmentsTable;
