const getScrollbarWidth = () => {
    if (!globalThis.document) {
        return 0;
    }

    const outer = globalThis.document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll";
    outer.style.msOverflowStyle = "scrollbar";
    globalThis.document.body.appendChild(outer);

    const inner = globalThis.document.createElement("div");
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
};

const elementScrollTo = (element, top, left, isSmooth = false) => {
    if (!element) {
        return;
    }

    const options = {
        top,
        left,
        behavior: isSmooth ? "smooth" : "auto",
    };

    if (element.scroll) {
        element.scroll(options);
        return;
    }

    if (element.scrollTo) {
        element.scrollTo(options);
        return;
    }

    if (element.scrollTop) {
        // eslint-disable-next-line no-param-reassign
        element.scrollTop = 0;
    }
};

const scrollToTop = () => {
    elementScrollTo(window, 0, 0, false);
};

const hideBodyScroll = () => {
    const body = window.document.getElementsByTagName("body");

    if (body && body[0] && body[0].style) {
        body[0].style.overflowY = "hidden";
    }
};

const unhideBodyScroll = () => {
    const body = window.document.getElementsByTagName("body");

    if (body && body[0] && body[0].style) {
        body[0].style.overflowY = "auto";
    }
};

export default {
    getScrollbarWidth,
    elementScrollTo,
    scrollToTop,
    hideBodyScroll,
    unhideBodyScroll,
};
