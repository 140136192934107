import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconSun from "@/base/icons/sun/index.js";
import IconMoon from "@/base/icons/moon/index.js";
import IconContrast from "@/base/icons/contrast/index.js";

import styles from "./styles.module.css";


const ThemeSelector = (props) => {
    const renderTitle = () => {
        if (props.isLight) {
            return "Light";
        }

        if (props.isDark) {
            return "Dark";
        }

        if (props.isHighContrast) {
            return "High Contrast";
        }

        return "";
    };

    const renderIcon = () => {
        if (props.isLight) {
            return (
                <IconSun
                    className={styles.themeIcon}
                    isOrange
                />
            );
        }

        if (props.isDark) {
            return (
                <IconMoon
                    className={styles.themeIcon}
                    isOrange
                />
            );
        }

        if (props.isHighContrast) {
            return (
                <IconContrast
                    className={styles.themeIcon}
                    isOrange
                />
            );
        }

        return null;
    };

    const selectorClassName = classNames({
        [styles.themeSelector]: true,
        [styles.themeSelectorSelected]: props.selected && !props.disableOutline,
    });

    const selectorInnerClassName = classNames({
        [styles.themeSelectorInner]: true,
        [styles.themeSelectorInnerLight]: props.isLight,
        [styles.themeSelectorInnerDark]: props.isDark,
        [styles.themeSelectorInnerHighContrast]: props.isHighContrast,
    });

    return (
        <div className={styles.container}>
            <div
                className={selectorClassName}
                onClick={props.onClick}
                onKeyPress={props.onClick}
                role="button"
                tabIndex="-1"
            >
                <div className={selectorInnerClassName}>
                    {renderIcon()}
                </div>
            </div>
            <div className={styles.title}>
                {renderTitle()}
            </div>
        </div>
    );
};

ThemeSelector.defaultProps = {
    selected: false,
    isLight: false,
    isDark: false,
    isHighContrast: false,
    disableOutline: false,
    onClick: () => { },
};

export default ThemeSelector;
