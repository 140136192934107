import * as types from "./types.js";


export const setJuiceStoryLoading = (payload) => ({
    type: types.T2S_SET_JUICE_STORY_LOADING,
    payload,
});

export const setJuiceStoryError = (payload) => ({
    type: types.T2S_SET_JUICE_STORY_ERROR,
    payload,
});

export const setJuiceStory = (payload) => ({
    type: types.T2S_SET_JUICE_STORY,
    payload,
});

export const clearJuiceStory = (payload) => ({
    type: types.T2S_CLEAR_JUICE_STORY,
    payload,
});

/* --- */

export const setExtraJuiceLoading = (payload) => ({
    type: types.T2S_SET_EXTRA_JUICE_LOADING,
    payload,
});

export const setExtraJuiceError = (payload) => ({
    type: types.T2S_SET_EXTRA_JUICE_ERROR,
    payload,
});

export const setExtraJuice = (payload) => ({
    type: types.T2S_SET_EXTRA_JUICE,
    payload,
});

export const clearExtraJuice = (payload) => ({
    type: types.T2S_CLEAR_EXTRA_JUICE,
    payload,
});

/* --- */

export const setWordLoading = (payload) => ({
    type: types.T2S_SET_WORD_LOADING,
    payload,
});

export const setWordError = (payload) => ({
    type: types.T2S_SET_WORD_ERROR,
    payload,
});

export const setWord = (payload) => ({
    type: types.T2S_SET_WORD,
    payload,
});

export const clearWord = (payload) => ({
    type: types.T2S_CLEAR_WORD,
    payload,
});

/* --- */

export const setHighlightLoading = (payload) => ({
    type: types.T2S_SET_HIGHLIGHT_LOADING,
    payload,
});

export const setHighlight = (payload) => ({
    type: types.T2S_SET_HIGHLIGHT,
    payload,
});
