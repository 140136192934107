const getFullName = (user) => {
    return [
        user.firstName || "",
        user.lastName || "",
    ].filter((val) => val).join(" ");
};

const getLastName = (user) => {
    const names = (user.fullName || "").split(" ");
    return names[1] || "";
};

const getInitials = (user) => {
    const names = (user.fullName || "").split(" ");

    const char1 = (names[0] || "").charAt(0);
    const char2 = (names[1] || "").charAt(0);

    return [char1, char2].join("");
};

const getFirstInitial = (user) => {
    const name = user.fullName || "";
    return name.charAt(0);
};

/* --- */

const isTypeTrial = (user) => {
    return user && user.userType === "Trial";
};

const isTypeFree = (user) => {
    return user && user.userType === "Free";
};

const isTypeRegular = (user) => {
    return user && user.userType === "Regular";
};

const isTypeHome = (user) => {
    return user && user.userType === "Home";
};

/* --- */

const isLegacy = (user) => {
    return user
        && user.userType === "Regular"
        && (user.referralCodeType === "trial"
            || user.referralCodeType === "");
};

/* --- */

const isExpired = (user) => {
    return user && user.isExpired;
};

const hasEnoughExpirationDaysLeft = (user, days) => {
    const isFree = isTypeFree(user);

    if (!isFree) {
        return true;
    }

    const expirationDate = user?.expiration || "";

    if (!expirationDate) {
        return true;
    }

    if (user?.isExpired) {
        return false;
    }

    const daysLeftToExpire = user?.daysLeftToExpire || 0;

    return daysLeftToExpire <= days;
};

/* --- */

const hasRole = (user, role) => {
    return user && user.roles && user.roles.indexOf(role) !== -1;
};

const hasRoleTeacher = (user) => {
    return hasRole(user, "teacher");
};

const hasRoleParent = (user) => {
    return hasRole(user, "parent");
};

const hasRoleDistrictAdmin = (user) => {
    return hasRole(user, "district_admin");
};

const hasRoleGuardian = (user) => {
    return hasRole(user, "guardian");
};

const hasRoleStudent = (user) => {
    return hasRole(user, "student");
};

const hasRoleAdmin = (user) => {
    return hasRole(user, "administrator");
};

const hasRoleChild = (user) => {
    return hasRole(user, "child");
};

/* --- */

const isSignUpRoleTeacher = (user) => {
    return user?.signUpRole === "teacher";
};

const isSignUpRoleParent = (user) => {
    return user?.signUpRole === "parent";
};

// TODO: remove later
const isSignUpRoleFamily = (user) => {
    return user?.signUpRole === "family";
};

/* --- */

const hasCardDetails = (paymentDetails) => {
    if (paymentDetails?.stripeCustomerId) {
        return true;
    }

    return false;
};

const hasPlan = (user) => {
    if (user?.subscription?.subscriptionEncId) {
        return true;
    }

    return false;
};

const hasActivePlan = (user) => {
    return user?.subscription?.status === "Active";
};

/* --- */

const optionsArrToObj = (options = []) => {
    const ret = {};

    options.forEach((opt) => {
        let val = opt.value;

        if (opt.value === "true") {
            val = true;
        } else if (opt.value === "false") {
            val = false;
        }

        ret[opt.key] = val;
    });

    return ret;
};

/* --- */

const getNavSections = (user, sectionsByRoles) => {
    let userSections = [];

    if (isTypeTrial(user)) {
        if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.trial.student || [];
        }
    } else if (isTypeFree(user)) {
        if (hasRoleTeacher(user)) {
            userSections = sectionsByRoles.free.teacher || [];
        } else if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.free.student || [];
        } else if (hasRoleParent(user)) {
            userSections = sectionsByRoles.free.parent || [];
        } else if (hasRoleChild(user)) {
            userSections = sectionsByRoles.free.child || [];
        }
    } else if (isTypeHome(user)) {
        if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.home.student || [];
        } else if (hasRoleGuardian(user)) {
            userSections = sectionsByRoles.home.guardian || [];
        } else if (hasRoleTeacher(user)) {
            userSections = sectionsByRoles.home.teacher || [];
        }
    } else if (isTypeRegular(user)) {
        if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.regular.student || [];
        } else if (hasRoleGuardian(user)) {
            userSections = sectionsByRoles.regular.guardian || [];
        } else if (hasRoleTeacher(user)) {
            userSections = sectionsByRoles.regular.teacher || [];
        } else if (hasRoleDistrictAdmin(user)) {
            userSections = sectionsByRoles.regular.districtAdmin || [];
        } else if (hasRoleParent(user)) {
            userSections = sectionsByRoles.regular.parent || [];
        } else if (hasRoleChild(user)) {
            userSections = sectionsByRoles.regular.child || [];
        } else if (hasRoleAdmin(user)) {
            userSections = sectionsByRoles.regular.admin || [];
        }
    }

    return userSections;
};

/* --- */

export default {
    getFullName,
    getLastName,
    getInitials,
    getFirstInitial,

    isTypeTrial,
    isTypeFree,
    isTypeRegular,
    isTypeHome,

    isLegacy,

    isExpired,
    hasEnoughExpirationDaysLeft,

    hasCardDetails,
    hasPlan,
    hasActivePlan,

    hasRoleTeacher,
    hasRoleParent,
    hasRoleDistrictAdmin,
    hasRoleGuardian,
    hasRoleStudent,
    hasRoleAdmin,
    hasRoleChild,

    isSignUpRoleTeacher,
    isSignUpRoleFamily,
    isSignUpRoleParent,

    optionsArrToObj,

    getNavSections,
};
