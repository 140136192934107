// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nB51j464iNcmhu0FU69_ {\n    overflow: hidden;\n\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.ob6KGR6uKxuoxol9TTGw {\n    white-space: initial;\n}\n", "",{"version":3,"sources":["webpack://./base/components/text-line/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;IAEhB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".textLine {\n    overflow: hidden;\n\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.textLineMultiline {\n    white-space: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textLine": "nB51j464iNcmhu0FU69_",
	"textLineMultiline": "ob6KGR6uKxuoxol9TTGw"
};
export default ___CSS_LOADER_EXPORT___;
