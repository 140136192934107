import text from "@/base/text/index.js";


const loadPrivatePlanByCode = (services, params) => {
    const { api, actions } = services;
    const { code } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.subscription.setPrivatePlanByCodeLoading(code));

        const res = await api.paymentPlans.getPrivatePlanByCode({
            session: state.user.session,
            code,
        });

        let error = "";
        let data = null;

        if (res.ok) {
            data = res.plan || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.subscription.setPrivatePlanByCode({
            code,
            data,
            error,
        }));
    };
};


export default {
    loadPrivatePlanByCode,
};
