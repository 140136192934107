export const SET_STUDENTS_NOTIFICATIONS = "SET_STUDENTS_NOTIFICATIONS";

export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";
export const SET_NOTIFICATION_VIEWED = "SET_NOTIFICATION_VIEWED";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export const SET_NOTIFICATION_BY_ID_LOADING = "SET_NOTIFICATION_BY_ID_LOADING";
export const SET_NOTIFICATION_BY_ID = "SET_NOTIFICATION_BY_ID";

export default {};
