// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MLxSleCGkGO2zZVV1eWo {\n    width: 100%;\n    max-width: 50rem;\n\n    overflow: hidden;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.4rem;\n\n    color: var(--text-primary-color);\n    background-color: var(--text-primary-bg-color);\n}\n\n.KO6rfxwlQO0IluWc8zmx {\n    box-shadow: var(--box-shadow-1);\n}\n\n.n5LH7vSj3gvV8haszXya {\n    padding: 2rem;\n}\n\n.uUPeqiUdLtiQciW8zkqA {\n    max-width: 100%;\n}\n\n.uBap6f7K5Olf61O5eYiO {\n    border: none;\n    box-shadow: none;\n}\n\n.li0wwNwBHgXoRYad_Hhx {\n    color: var(--story-category-color);\n}\n\n.Q7mTB7IXyWvVouIlgBoH {\n    color: var(--text-primary-color);\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-common/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;;IAEhB,gBAAgB;;IAEhB,gDAAgD;IAChD,qBAAqB;;IAErB,gCAAgC;IAChC,8CAA8C;AAClD;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".block {\n    width: 100%;\n    max-width: 50rem;\n\n    overflow: hidden;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.4rem;\n\n    color: var(--text-primary-color);\n    background-color: var(--text-primary-bg-color);\n}\n\n.blockShadow {\n    box-shadow: var(--box-shadow-1);\n}\n\n.blockWithPadding {\n    padding: 2rem;\n}\n\n.blockFullWidth {\n    max-width: 100%;\n}\n\n.blockWithoutBorder {\n    border: none;\n    box-shadow: none;\n}\n\n.header {\n    color: var(--story-category-color);\n}\n\n.headline {\n    color: var(--text-primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": "MLxSleCGkGO2zZVV1eWo",
	"blockShadow": "KO6rfxwlQO0IluWc8zmx",
	"blockWithPadding": "n5LH7vSj3gvV8haszXya",
	"blockFullWidth": "uUPeqiUdLtiQciW8zkqA",
	"blockWithoutBorder": "uBap6f7K5Olf61O5eYiO",
	"header": "li0wwNwBHgXoRYad_Hhx",
	"headline": "Q7mTB7IXyWvVouIlgBoH"
};
export default ___CSS_LOADER_EXPORT___;
