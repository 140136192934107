import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isIncompletedJuicesLoaded: false,
    isIncompletedJuicesLoading: false,
    incompletedJuices: [],
    page: 0,
    hasMorePages: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_INCOMPLETED_JUICES: {
            const { incompletedJuices } = state;

            const { hasMorePages, page } = action.payload;


            return {
                ...state,
                isIncompletedJuicesLoaded: true,
                isIncompletedJuicesLoading: false,
                hasMorePages,
                page,
                incompletedJuices: [
                    ...incompletedJuices,
                    ...action.payload.incompletedJuices,
                ],
            };
        }

        case types.SET_INCOMPLETED_JUICES_LOADING: {
            return {
                ...state,
                isIncompletedJuicesLoading: true,
            };
        }

        case types.CLEAR_INCOMPLETED_JUICES: {
            return {
                ...state,
                isIncompletedJuicesLoaded: false,
                isIncompletedJuicesLoading: false,
                incompletedJuices: [],
                page: 0,
                hasMorePages: false,
            };
        }

        case types.DELETE_INCOMPLETE_JUICE_BY_ID: {
            const newJuices = state.incompletedJuices
                .filter((juice) => juice.id !== action.payload.juiceId);

            return {
                ...state,
                incompletedJuices: newJuices,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
