import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Urls from "@/base/project/urls.js";

import array from "@/base/lib/array.js";

import navigationSelectors from "@/base/store-selectors/navigation.js";

import IconArrowBack from "@/base/icons/arrow-back/index.js";

import PopupWindow from "@/base/components/popup-window/index.js";
import Checkbox from "@/base/components/forms/checkbox/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";

import app from "@/app/app.js";

import styles from "./styles.module.css";

import ClassRow from "./class-row.js";


const getState = () => ({
    selectedClasses: [],
    classesStatusesById: {},
    isRestorePage: false,
    isRestoring: false,
    isRestoringFinished: false,
});

const storeSelector = (state) => ({
    derosteredClasses: state.teacher.derosteredClasses,
    pathname: state.navigation.location.pathname,
    classUrlParams: navigationSelectors.classParams(state),
});

const PopupRestoreClasses = (props) => {
    const [state, setState] = useState(() => getState());

    const store = useSelector(storeSelector);

    const derosteredClasses = useMemo(() => {
        return store.derosteredClasses?.data || [];
    }, [store.derosteredClasses]);

    /* --- */

    const onSelectClass = (id) => {
        setState((prev) => {
            let selectedClasses = [...prev.selectedClasses];

            if (selectedClasses.indexOf(id) === -1) {
                selectedClasses.push(id);
            } else {
                selectedClasses = selectedClasses.filter((classId) => classId !== id);
            }

            return {
                ...prev,
                selectedClasses,
            };
        });
    };

    const goToRestore = () => {
        setState((prev) => ({
            ...prev,
            isRestorePage: true,
        }));
    };

    /* --- */

    const restoreClasses = useCallback(async (ids) => {
        let hasSuccess = false;

        for (let i = 0; i < ids.length; i += 1) {
            const classId = ids[i];
            const derosteringId = array.findOneById(derosteredClasses, classId)?.derosteringId
                || null;

            // eslint-disable-next-line no-await-in-loop
            const { error } = await app.actions.teacher.classes.restoreClass({
                derosteringId,
            });

            if (!error) {
                hasSuccess = true;
            }

            setState((prev) => ({
                ...prev,
                classesStatusesById: {
                    ...prev.classesStatusesById,
                    [classId]: {
                        ...prev.classesStatusesById[classId],
                        error,
                        isSuccess: !error,
                        isLoading: false,
                    },
                },
            }));
        }

        if (hasSuccess) {
            app.actions.teacher.classes.loadAllClasses();

            if (Urls.isClass(store.pathname)) {
                if (store.classUrlParams.isDailyJuiceView) {
                    app.actions.teacher.dailyJuices.loadOrReloadDailyJuicesScores();
                } else if (store.classUrlParams.isAssignmentsView) {
                    app.actions.teacher.classAssignments.loadOrReloadAssignmentsScores();
                } else if (store.classUrlParams.isProgressView) {
                    app.actions.teacher.studentsProgress.loadOrReloadClassProgressReport();
                } else if (store.classUrlParams.isScoreReportView) {
                    app.actions.teacher.studentsScoreReport.loadOrReloadClassScoreReport();
                }
            }
        }

        setState((prev) => ({
            ...prev,
            isRestoringFinished: true,
        }));
    }, [derosteredClasses]);

    const onRestore = () => {
        const { selectedClasses } = state;
        const classesStatusesById = {};

        for (let i = 0; i < selectedClasses.length; i += 1) {
            const id = selectedClasses[i];

            classesStatusesById[id] = {
                error: "",
                isSuccess: false,
                isLoading: true,
            };
        }

        setState((prev) => ({
            ...prev,
            classesStatusesById,
            isRestoring: true,
        }));

        restoreClasses(selectedClasses);
    };

    /* --- */

    const onBack = () => {
        setState((prev) => ({
            ...prev,
            isRestorePage: false,
        }));
    };

    const onClose = useCallback(() => {
        if (state.isRestoringFinished) {
            app.actions.teacher.classes.loadDerosteredClasses();
        }

        props.onClose();
    }, [state.isRestoringFinished]);

    /* --- */

    const renderClassesPageControls = () => {
        return (
            <div className={styles.controls}>
                <ButtonBig
                    disabled={!state.selectedClasses.length}
                    onClick={goToRestore}
                >
                    Continue
                </ButtonBig>
            </div>
        );
    };

    const renderRestorePageControls = () => {
        const { isRestoring, isRestoringFinished } = state;

        if (isRestoring) {
            return (
                <div className={styles.controls}>
                    <ButtonBig
                        onClick={onClose}
                        disabled={!isRestoringFinished}
                    >
                        Close
                    </ButtonBig>
                </div>
            );
        }

        return (
            <div className={styles.controlsColumn}>
                <ButtonBig
                    onClick={onBack}
                >
                    Back
                </ButtonBig>
                <ButtonBig
                    onClick={onRestore}
                    disabled={isRestoring}
                >
                    Restore
                </ButtonBig>
            </div>
        );
    };

    const renderControls = () => {
        if (state.isRestorePage) {
            return renderRestorePageControls();
        }

        return renderClassesPageControls();
    };

    /* --- */

    const renderRestorePage = () => {
        const classes = state.selectedClasses.map((id) => {
            const data = state.classesStatusesById[id] || {};
            const className = array.findOneById(derosteredClasses, id)?.name || "";

            return (
                <ClassRow
                    name={className}
                    error={data.error}
                    isSuccess={data.isSuccess}
                    isLoading={data.isLoading}
                />
            );
        });

        return (
            <div className={styles.classes}>
                {classes}
            </div>
        );
    };

    const renderClassesPage = () => {
        const classes = derosteredClasses.map(({ id, name }) => {
            return (
                <Checkbox
                    name={`derostered-class-${id}`}
                    label={name}
                    onChange={() => {
                        onSelectClass(id);
                    }}
                    checked={state.selectedClasses.indexOf(id) !== -1}
                />
            );
        });

        return (
            <div className={styles.classes}>
                {classes}
            </div>
        );
    };

    const renderContent = () => {
        if (state.isRestorePage) {
            return renderRestorePage();
        }

        return renderClassesPage();
    };

    /* --- */

    const renderPopupBackButton = () => {
        if (!state.isRestorePage || state.isRestoring) {
            return null;
        }

        const icon = (
            <IconArrowBack
                isBlack
            />
        );

        return (
            <ButtonCircle
                icon={icon}
                onClick={onBack}
            />
        );
    };

    /* --- */

    return (
        <PopupWindow
            title="Restore classes"
            leftControl={renderPopupBackButton()}
            onClose={onClose}
            hideClose={state.isRestoring && !state.isRestoringFinished}
            isCentered
            isSmall
        >
            <div className={styles.content}>
                {renderContent()}
                {renderControls()}
            </div>
        </PopupWindow>
    );
};

PopupRestoreClasses.defaultProps = {
    onClose: () => { },
};

export default PopupRestoreClasses;
