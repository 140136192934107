import * as types from "./types.js";


export const setIncompletedJuices = (payload) => ({
    type: types.SET_INCOMPLETED_JUICES,
    payload,
});

export const setIncompletedJuicesLoading = (payload) => ({
    type: types.SET_INCOMPLETED_JUICES_LOADING,
    payload,
});

export const clearIncompletedJuices = () => ({
    type: types.CLEAR_INCOMPLETED_JUICES,
});


export const deleteIncompleteJuiceById = (payload) => ({
    type: types.DELETE_INCOMPLETE_JUICE_BY_ID,
    payload,
});
