import vocabulary from "@/base/api-common/vocabulary.js";
import t2s from "@/base/api-common/t2s.js";
import translation from "@/base/api-common/translation.js";
import videos from "@/base/api-common/videos.js";
import events from "@/base/api-common/events.js";
import pages from "@/base/api-common/pages.js";
import search from "@/base/api-common/search.js";
import juiceStories from "@/base/api-common/juice-stories.js";
import fivesy from "@/base/api-common/fivesy.js";

import site from "./site.js";

import user from "./user.js";
import signup from "./signup.js";

import jobs from "./jobs.js";
import dailyJuices from "./daily-juices.js";
import extraJuices from "./extra-juices.js";
import quizzes from "./quizzes.js";
import polls from "./polls.js";

import classes from "./classes.js";
import teachers from "./teachers.js";
import teacherClassesAssignments from "./teacher-classes-assignments.js";
import teacherClasses from "./teacher-classes.js";
import teacherClass from "./teacher-class.js";

import student from "./student.js";
import students from "./students.js";

import districtAdmin from "./district-admin.js";

import admin from "./admin.js";

import tutorials from "./tutorials.js";
import templates from "./templates.js";
import popups from "./popups.js";

import geo from "./geo.js";

import subscription from "./subscription.js";
import paymentPlans from "./payment-plans.js";

import standards from "./standards.js";

import exp from "./export.js";
import print from "./print.js";

import lms from "./lms.js";

import notifications from "./notifications.js";
import rate from "./rate.js";

import assignments from "./assignments.js";

import error from "./error.js";


const createAPI = (url, methods) => {
    return {
        site: site(url, methods),

        user: user(url, methods),
        signup: signup(url, methods),

        jobs: jobs(url, methods),
        dailyJuices: dailyJuices(url, methods),
        extraJuices: extraJuices(url, methods),
        juiceStories: juiceStories(url, methods),
        quizzes: quizzes(url, methods),
        polls: polls(url, methods),
        vocabulary: vocabulary(url, methods),
        videos: videos(url, methods),
        fivesy: fivesy(url, methods),

        search: search(url, methods),

        classes: classes(url, methods),

        teachers: teachers(url, methods),
        teacherClassesAssignments: teacherClassesAssignments(url, methods),
        teacherClasses: teacherClasses(url, methods),
        teacherClass: teacherClass(url, methods),

        students: students(url, methods),
        student: student(url, methods),

        districtAdmin: districtAdmin(url, methods),

        admin: admin(url, methods),

        templates: templates(url, methods),
        tutorials: tutorials(url, methods),
        pages: pages(url, methods),
        popups: popups(url, methods),

        t2s: t2s(url, methods),
        translation: translation(url, methods),

        geo: geo(url, methods),

        subscription: subscription(url, methods),
        paymentPlans: paymentPlans(url, methods),

        standards: standards(url, methods),

        export: exp(url, methods),
        print: print(url, methods),

        lms: lms(url, methods),

        assignments: assignments(url, methods),

        events: events(url, methods),

        notifications: notifications(url, methods),
        rate: rate(url, methods),

        error: error(url, methods),
    };
};

export default createAPI;
