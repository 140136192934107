import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconCheck from "@/base/icons/check/index.js";

import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const Option = (props) => {
    if (!props.option) {
        return null;
    }

    /* --- */

    const onSelect = (evt) => {
        props.onSelect(evt, props.option);
    };

    /* --- */

    const renderCheckIcon = () => {
        if (!props.isSelected) {
            return null;
        }

        if (props.orangeTheme) {
            return (
                <IconCheck isOrange />
            );
        }

        return (
            <IconCheck isRed />
        );
    };

    const renderIconBefore = () => {
        if (!props.option.iconBefore) {
            return null;
        }

        return props.option.iconBefore;
    };

    const renderIconAfter = () => {
        if (!props.option.iconAfter) {
            return null;
        }

        return props.option.iconAfter;
    };

    const optClassName = classNames({
        [styles.option]: true,
        [styles.optionSmall]: props.small,
        [styles.optionWithIcon]: props.withIcon && !props.option.iconBefore,
        [styles.optionAlreadySelected]: props.isSelected,
        [styles.optionAlreadySelectedOrange]: props.isSelected && props.orangeTheme,
        [styles.optionOrange]: props.orangeTheme,
    });

    return (
        <DivButton
            className={optClassName}
            onClick={onSelect}
        >
            <div className={styles.optionLabel}>
                {renderIconBefore()}
                {props.option.label}
                {renderIconAfter()}
            </div>
            {renderCheckIcon()}
        </DivButton>
    );
};

Option.defaultProps = {
    option: null,
    isSelected: false,
    withIcon: false,
    orangeTheme: false,
    small: false,
    onSelect: () => { },
};

export default Option;
