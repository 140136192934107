export default (apiURL, methods) => {
    return {
        getExtraJuice(params) {
            // NOTE: action=get_extra_juice_by_id
            const url = `${apiURL}/extra-juices/${params.extraJuiceId}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getExtraJuices(params) {
            // NOTE: action=get_user_extra_juices
            const url = `${apiURL}/extra-juices/by-page`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
                only_first: params.onlyFirst ? 1 : 0,
            });
        },
    };
};
