// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PVV17DbSlrKQdq1K_XJm {\n    display: grid;\n    grid-row-gap: 2rem;\n    padding: 2rem;\n}\n\n/* --- */\n\n.FUFlvsEXYdPyQnDGJS5H {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.LHgwBziiC80g9iIMoT4w {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n}\n\n/* --- */\n\n.KeJam_MPrGGLA_gqbLIy,\n.qlUrqnD1JxJ3vGn0W8q4 {\n    display: grid;\n}\n\n.qlUrqnD1JxJ3vGn0W8q4 {\n    grid-template-columns: 1fr 1fr;\n    grid-column-gap: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/popup-restore-classes/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,aAAa;AACjB;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;;IAErB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA,QAAQ;;AAER;;IAEI,aAAa;AACjB;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB","sourcesContent":[".content {\n    display: grid;\n    grid-row-gap: 2rem;\n    padding: 2rem;\n}\n\n/* --- */\n\n.classes {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.classRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n}\n\n/* --- */\n\n.controls,\n.controlsColumn {\n    display: grid;\n}\n\n.controlsColumn {\n    grid-template-columns: 1fr 1fr;\n    grid-column-gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "PVV17DbSlrKQdq1K_XJm",
	"classes": "FUFlvsEXYdPyQnDGJS5H",
	"classRow": "LHgwBziiC80g9iIMoT4w",
	"controls": "KeJam_MPrGGLA_gqbLIy",
	"controlsColumn": "qlUrqnD1JxJ3vGn0W8q4"
};
export default ___CSS_LOADER_EXPORT___;
