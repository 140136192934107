import { useState } from "react";


const getState = () => ({
    classes: [],
    isVisible: false,
});

const usePopupConfirmClassesEndDate = () => {
    const [state, setState] = useState(() => getState());

    const open = (classes) => {
        setState((prev) => ({
            ...prev,
            classes,
            isVisible: true,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};

export default usePopupConfirmClassesEndDate;
