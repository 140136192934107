import * as types from "./types.js";


export const setSiteDate = (payload) => ({
    type: types.SET_SITE_DATE,
    payload,
});

export const setSponsors = (sponsors) => ({
    type: types.SET_SPONSORS,
    payload: {
        sponsors,
    },
});

export const setFeatures = (payload) => ({
    type: types.SET_FEATURES,
    payload,
});

export default {};
