import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconCheckInArrows = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M14.0001 15.2499H16.0001V16.9166H11.0001V11.9166H12.6667V14.1916C14.1917 12.9666 15.1667 11.0999 15.1667 8.99992C15.1667 5.60825 12.6167 2.79992 9.33342 2.39159V0.708252C13.5417 1.12492 16.8334 4.67492 16.8334 8.99992C16.8334 11.4916 15.7334 13.7249 14.0001 15.2499ZM1.83341 8.99992C1.83341 6.89992 2.80841 5.02492 4.33342 3.80825V6.08325H6.00008V1.08325H1.00008V2.74992H3.00008C1.26675 4.27492 0.166748 6.50825 0.166748 8.99992C0.166748 13.3249 3.45841 16.8749 7.66675 17.2916V15.6083C4.38341 15.1999 1.83341 12.3916 1.83341 8.99992ZM12.0334 5.75825L7.31675 10.4749L4.95842 8.11659L3.78342 9.29159L7.31675 12.8249L13.2084 6.93325L12.0334 5.75825Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCheckInArrows;
