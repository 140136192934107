import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        show() {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniShow,
            };

            debugEvent("mini show", event);
            methods.sendEvent(event);
        },

        openStory(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryOpen,
                objectId: params.juiceId,
                subObjectId: params.storyId,
            };

            debugEvent("mini open story", event);
            methods.sendEvent(event);
        },

        openDailyJuice(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniDailyJuiceOpen,
                objectId: params.juiceId,
            };

            debugEvent("mini open daily juice", event);
            methods.sendEvent(event);
        },

        storyExpandInfographic(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryExpandInfographic,
                objectId: params.storyId,
            };

            debugEvent("mini expand infographic", event);
            methods.sendEvent(event);
        },

        storyVocabOpen(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVocabOpen,
                objectId: params.wordId,
            };

            debugEvent("mini dj vocab open", event);
            methods.sendEvent(event);
        },

        storyOpenEJFromButton(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryOpenEJFromButton,
                objectId: params.storyId,
                subObjectId: params.extraJuiceId,
            };

            debugEvent("mini dj open EJ from button", event);
            methods.sendEvent(event);
        },

        storyOpenEJFromStoryLink(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryOpenEJFromStoryLink,
                objectId: params.storyId,
                subObjectId: params.extraJuiceId,
            };

            debugEvent("mini dj open EJ from story link", event);
            methods.sendEvent(event);
        },

        extraJuiceOpenVocabWord(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniExtraJuiceOpenVocabWord,
                objectId: params.extraJuiceId,
                subObjectId: params.wordId,
            };

            debugEvent("mini EJ open vocab word", event);
            methods.sendEvent(event);
        },

        storyQuizAnswerClick(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryQuizAnswerClick,
                objectId: params.quizId,
                subObjectId: params.answerId,
            };

            debugEvent("mini DJ quiz answer click", event);
            methods.sendEvent(event);
        },

        storyQuizSubmit(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryQuizSubmit,
                objectId: params.quizId,
                subObjectId: params.answerId,
            };

            debugEvent("mini DJ quiz submit", event);
            methods.sendEvent(event);
        },

        storyListen(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryListen,
                objectId: params.storyId,
            };

            debugEvent("mini DJ listen story", event);
            methods.sendEvent(event);
        },

        storyPause(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryPause,
                objectId: params.storyId,
            };

            debugEvent("mini DJ pause story", event);
            methods.sendEvent(event);
        },

        storyVocabPlay(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVocabPlay,
                objectId: params.wordId,
            };

            debugEvent("mini DJ vacab play", event);
            methods.sendEvent(event);
        },

        storyVocabPlayDefinition(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVocabPlayDefinition,
                objectId: params.wordId,
            };

            debugEvent("mini DJ vacab play definition", event);
            methods.sendEvent(event);
        },

        storyVocabPause(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVocabPause,
                objectId: params.wordId,
            };

            debugEvent("mini DJ vacab pause", event);
            methods.sendEvent(event);
        },

        storyVocabPauseDefinition(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVocabPauseDefinition,
                objectId: params.wordId,
            };

            debugEvent("mini DJ vacab pause definition", event);
            methods.sendEvent(event);
        },

        storyVideoPlay(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVideoPlay,
                objectId: params.storyId,
                subObjectId: params.videoId,
            };

            debugEvent("mini DJ video play", event);
            methods.sendEvent(event);
        },

        storyVideoEnd(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVideoEnd,
                objectId: params.storyId,
                subObjectId: params.videoId,
            };

            debugEvent("mini DJ video end", event);
            methods.sendEvent(event);
        },

        storyVideoPlayTime(params) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.miniStoryVideoPlayTime,
                objectId: params.videoId,
                timeTook: params.timeTook,
            };

            debugEvent("mini DJ story video play time", event);
            methods.sendEvent(event);
        },

        translateStory(params = {}) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.translateMiniStory,
                objectId: params.storyId,
                subObjectId: params.languageId,
            };

            debugEvent("Translate mini story", event);
            methods.sendEvent(event);
        },

        translateExtraJuice(params = {}) {
            const event = {
                locationId: ids.location.mini,
                eventId: ids.event.translateMiniExtraJuice,
                objectId: params.storyId,
                subObjectId: params.languageId,
            };

            debugEvent("Translate mini extra juice", event);
            methods.sendEvent(event);
        },
    };
};
