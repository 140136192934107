export default (apiURL, methods) => {
    return {
        getWord(params = {}) {
            // NOTE: action=fivesy_get_current_word
            const url = `${apiURL}/fivesy/word`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        getUserStats(params = {}) {
            // NOTE: action=fivesy_get_user_stats
            const url = `${apiURL}/fivesy/stats`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        getUserAttempts(params = {}) {
            // NOTE: action=fivesy_get_user_attempts
            const url = `${apiURL}/fivesy/word/attempts`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        guessWord(params = {}) {
            // NOTE: action=fivesy_guess_word
            const url = `${apiURL}/fivesy/word/guess`;

            return methods.get3(url, {
                session_id: params.session || "",
                word: params.word || "",
            });
        },

        setGameFinished(params = {}) {
            // NOTE: action=fivesy_set_game_finished
            const url = `${apiURL}/fivesy/finished`;

            return methods.post(url, {
                session_id: params.session || "",
                is_won: params.isWon ? "1" : "0",
            });
        },
    };
};
