import React from "react";
import { Formik } from "formik";

import classNames from "@/base/lib/class-names.js";

import Geo from "@/base/project/geo.js";
import Schools from "@/base/project/schools.js";

import Select from "@/base/components/forms/select/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import ButtonToggler from "@/base/components/button-toggler/index.js";

import styles from "./styles.module.css";


const TeacherSchoolDetailsForm = (props) => {
    const hasSubdivision = (subdivision) => {
        if (subdivision) {
            return subdivision?.value !== "";
        }

        return false;
    };

    const isDisabledNextButton = (values) => {
        const {
            country,
            subdivision,
            schoolDistrict,
            schoolCity,
            schoolName,
        } = values;

        return !country
            || !subdivision
            || (!schoolDistrict && !schoolCity)
            || !schoolName;
    };

    /* --- */

    const renderSchoolDistrictOrCityField = (values, setFieldValue) => {
        const isDisabled = !hasSubdivision(values.subdivision)
            || props.isFamily;

        if (Schools.isPublicSchoolType(props.selectedSchoolType)) {
            return (
                <Select
                    name="schoolDistrict"
                    label="School District *"
                    selected={values.schoolDistrict}
                    options={props.districtsOptions}
                    onSelect={(val) => {
                        setFieldValue("schoolDistrict", val);
                        setFieldValue("schoolName", "");
                        props.onSelectDistrict(val);
                    }}
                    isDisabled={isDisabled}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    isSearchable
                    isCreatable
                />
            );
        }

        return (
            <Select
                name="schoolCity"
                label="School City *"
                selected={values.schoolCity}
                options={props.citiesOptions}
                onSelect={(val) => {
                    setFieldValue("schoolCity", val);
                    setFieldValue("schoolName", "");
                    props.onSelectCity(val);
                }}
                isDisabledTabIndex={props.isDisabledTabIndex}
                isDisabled={isDisabled}
                isSearchable
                isCreatable
            />
        );
    };

    const renderSchoolDetailsFields = (values, setFieldValue) => {
        const { schoolDistrict, schoolCity, subdivision } = values;

        const buttons = Schools.getSchoolTypes();

        const isDisabledSchoolName = (!schoolDistrict && !schoolCity)
            || props.isFamily;

        const hasSubdiv = hasSubdivision(subdivision);

        const className = classNames({
            [styles.hidden]: props.isFamily,
        });

        return (
            <div className={className}>
                <div className={styles.schoolTypesToggler}>
                    <ButtonToggler
                        buttons={buttons}
                        selected={props.selectedSchoolType}
                        onSelect={(val) => {
                            props.onChangeSchoolType(val);
                            setFieldValue("schoolName", "");
                            setFieldValue("schoolDistrict", "");
                            setFieldValue("schoolCity", "");
                        }}
                        disabled={!hasSubdiv || props.isFamily}
                        uppercase={false}
                    />
                </div>

                {renderSchoolDistrictOrCityField(values, setFieldValue)}

                <Select
                    name="schoolName"
                    label="School Name *"
                    selected={values.schoolName}
                    options={props.schoolNamesOptions}
                    onSelect={(val) => {
                        setFieldValue("schoolName", val);
                        props.onSelectSchoolName(val);
                    }}
                    isDisabled={isDisabledSchoolName}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    isSearchable
                    isCreatable
                />
            </div>
        );
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleSubmit,
            setFieldValue,
        } = formProps;

        const isSubdivDisabled = !values.country
            || props.isDisabledSubdivision
            || !Geo.isCountryWithSubdivisions(values?.country?.value);

        const isSubdivCreatable = values?.country?.value === "Other";

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.fields}>
                    <Select
                        name="country"
                        label="Country *"
                        selected={values.country}
                        options={props.countries}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        isSearchable
                        onSelect={(value) => {
                            setFieldValue("country", value);
                            setFieldValue("subdivision", "");

                            if (!props.isFamily) {
                                setFieldValue("schoolDistrict", "");
                                setFieldValue("schoolCity", "");
                                setFieldValue("schoolName", "");
                            }

                            if (value?.value !== Geo.DEFAULT_ALPHA_2) {
                                setFieldValue("subdivision", {
                                    value: "Other",
                                });
                            }

                            if (value?.isCreated) {
                                return;
                            }

                            props.onCountryChange(value?.value || "");
                        }}
                    />

                    <Select
                        name="subdivision"
                        label={`${props.subdivisionName} *`}
                        selected={values.subdivision}
                        options={props.subdivisions}
                        isDisabled={isSubdivDisabled}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        isSearchable
                        isCreatable={isSubdivCreatable}
                        onSelect={(val) => {
                            setFieldValue("subdivision", val);

                            if (!props.isFamily) {
                                setFieldValue("schoolDistrict", "");
                                setFieldValue("schoolCity", "");
                                setFieldValue("schoolName", "");
                            }

                            props.onSubdivisionsChange(val?.value || "");
                        }}
                    />

                    {renderSchoolDetailsFields(values, setFieldValue)}
                </div>

                <div className={styles.submitButton}>
                    <ButtonBig
                        disabled={isDisabledNextButton(values)}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        type="submit"
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

TeacherSchoolDetailsForm.defaultProps = {
    initialValues: {},

    subdivisionName: "State",
    selectedSchoolType: "",

    districtsOptions: [],
    schoolNamesOptions: [],
    countries: [],
    subdivisions: [],
    citiesOptions: [],

    onCountryChange: () => { },
    onSubdivisionsChange: () => { },
    onSelectDistrict: () => { },
    onSelectCity: () => { },
    onSelectSchoolName: () => { },
    onChangeSchoolType: () => { },
    onSubmit: () => { },

    isFamily: false,
    isDisabledSubdivision: false,
    isDisabledTabIndex: false,
};

export default TeacherSchoolDetailsForm;
