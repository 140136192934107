import React from "react";

import text from "@/base/text/index.js";

import IconScoresHorizontal from "@/base/icons/scores-horizontal/index.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import ButtonRounded from "@/base/components/button-rounded/index.js";
import Message from "@/base/components/message/index.js";
import PollSection from "@/base/components/poll-section/index.js";

import SectionStudentV2 from "@/base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const DailyJuicePolls = (props) => {
    const renderPoll = () => {
        const { poll, isLoading } = props;

        if (isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!poll) {
            return (
                <Message>
                    {text.noClosedPolls}
                </Message>
            );
        }

        return (
            <div className={styles.section}>
                <PollSection
                    isDisabled
                    isVisibleMessage={false}
                    isOutdated={poll?.isOutdated || false}
                    selectedAnswer={poll?.userAnswerId || null}
                    question={poll?.question || ""}
                    answers={poll?.answers || []}
                />
            </div>
        );
    };

    const renderButtonMore = () => {
        if (!props.poll) {
            return null;
        }

        return (
            <div className={styles.buttonMore}>
                <ButtonRounded
                    uppercase
                    onClick={props.onViewMore}
                >
                    View More
                </ButtonRounded>
            </div>
        );
    };

    return (
        <SectionStudentV2
            title="Polls"
            icon={<IconScoresHorizontal isOrange />}
        >
            <div className={styles.container}>
                {renderPoll()}
                {renderButtonMore()}
            </div>
        </SectionStudentV2>
    );
};

DailyJuicePolls.defaultProps = {
    poll: null,
    isLoading: false,
    onViewMore: () => { },
};

export default DailyJuicePolls;
