import Classes from "@/base/project/classes.js";

import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    schools: {},
    departmentsBySchoolId: {},
    classesByDepartmentId: {},
    teachersByDepartmentId: {},

    classPerformanceById: {},
    classCategoriesByRange: {},
    classStandardsStats: {},

    selectedSchoolId: null,
    selectedDepartmentId: null,
    selectedClassId: null,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_DISTRICT_ADMIN_SCHOOLS: {
            const { schools, error } = action.payload;

            return {
                ...state,
                schools: {
                    schools,
                    error,
                    isLoaded: true,
                },
            };
        }

        case types.SET_CLASS_CODE_BY_DEPARTMENT_ID: {
            const { departmentId, classId, classCode } = action.payload;

            const classesByDepartmentId = state.classesByDepartmentId?.[departmentId]?.classes
                || [];

            const classes = Classes.setClassCodeById(classesByDepartmentId, classId, classCode);

            return {
                ...state,
                classesByDepartmentId: {
                    ...state.classesByDepartmentId,
                    [departmentId]: {
                        ...state.classesByDepartmentId[departmentId],
                        classes,
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_SCHOOL_NAME: {
            const { schoolId, schoolName } = action.payload;

            const schools = [...state.schools.schools];

            for (let i = 0; i < schools.length; i += 1) {
                if (schools[i].id === schoolId) {
                    schools[i].name = schoolName;
                }
            }

            return {
                ...state,
                schools: {
                    ...state.schools,
                    schools,
                },
            };
        }

        case types.SET_DEPARTMENT_BY_SCHOOL_ID: {
            const { schoolId, departmentId, departmentName } = action.payload;

            const departmentData = state.departmentsBySchoolId?.[schoolId] || {};
            const prevDepartments = departmentData?.departments || [];

            const departments = [];

            for (let i = 0; i < prevDepartments.length; i += 1) {
                const d = prevDepartments[i];

                if (d.id === departmentId) {
                    d.name = departmentName;
                }

                departments.push(d);
            }

            return {
                ...state,
                departmentsBySchoolId: {
                    ...state.departmentsBySchoolId,
                    [schoolId]: {
                        ...departmentData,
                        departments,
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_DEPARTMENTS_BY_SCHOOL_ID: {
            const { schoolId, departments, error } = action.payload;

            const data = state.departmentsBySchoolId || {};

            return {
                ...state,
                departmentsBySchoolId: {
                    ...data,
                    [schoolId]: {
                        departments,
                        error,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_CLASSES_BY_DEPARTMENT_ID: {
            const { departmentId, classes, error } = action.payload;

            const data = state.classesByDepartmentId || {};

            return {
                ...state,
                classesByDepartmentId: {
                    ...data,
                    [departmentId]: {
                        classes,
                        error,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_TEACHERS_BY_DEPARTMENT_ID: {
            const { departmentId, teachers, error } = action.payload;

            const data = state.teachersByDepartmentId || {};

            return {
                ...state,
                teachersByDepartmentId: {
                    ...data,
                    [departmentId]: {
                        teachers,
                        error,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case types.SET_DISTRICT_ADMIN_CLASS_PERFORMANCE_LOADING: {
            const { classId, range, categories } = action.payload;

            const dataByClassId = state.classPerformanceById?.[classId] || {};
            const performanceByRange = dataByClassId?.[range] || {};

            return {
                ...state,
                classPerformanceById: {
                    ...state.classPerformanceById,
                    [classId]: {
                        ...dataByClassId,
                        [range]: {
                            ...performanceByRange,
                            [categories]: {
                                data: null,
                                error: "",
                                isLoading: true,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_CLASS_PERFORMANCE: {
            const {
                classId,
                range,
                categories,
                data,
                error,
            } = action.payload;

            const dataByClassId = state.classPerformanceById?.[classId] || {};
            const performanceByRange = dataByClassId?.[range] || {};

            return {
                ...state,
                classPerformanceById: {
                    ...state.classPerformanceById,
                    [classId]: {
                        ...dataByClassId,
                        [range]: {
                            ...performanceByRange,
                            [categories]: {
                                data,
                                error,
                                isLoading: false,
                            },
                        },
                    },
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_CLASS_PERFORMANCE: {
            return {
                ...state,
                classPerformanceById: {},
            };
        }

        /* --- */

        case types.SET_DISTRICT_ADMIN_CLASS_CATEGORIES_LOADING: {
            const { classId, range } = action.payload;

            const dataByClassId = state.classCategoriesByRange?.[classId] || {};

            return {
                ...state,
                classCategoriesByRange: {
                    ...state.classCategoriesByRange,
                    [classId]: {
                        ...dataByClassId,
                        [range]: {
                            data: null,
                            error: "",
                            isLoading: true,
                        },
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_CLASS_CATEGORIES: {
            const {
                classId,
                range,
                data,
                error,
            } = action.payload;

            const dataByClassId = state.classCategoriesByRange?.[classId] || {};

            return {
                ...state,
                classCategoriesByRange: {
                    ...state.classCategoriesByRange,
                    [classId]: {
                        ...dataByClassId,
                        [range]: {
                            data,
                            error,
                            isLoading: false,
                        },
                    },
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_CLASS_CATEGORIES: {
            return {
                ...state,
                classCategoriesByRange: {},
            };
        }

        /* --- */

        case types.SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS_LOADING: {
            const { classId, range } = action.payload;

            const dataByClassId = state.classStandardsStats?.[classId] || {};

            return {
                ...state,
                classStandardsStats: {
                    ...state.classStandardsStats,
                    [classId]: {
                        ...dataByClassId,
                        [range]: {
                            data: null,
                            error: "",
                            isLoading: true,
                        },
                    },
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS: {
            const {
                classId,
                range,
                data,
                error,
            } = action.payload;

            const dataByClassId = state.classStandardsStats?.[classId] || {};

            return {
                ...state,
                classStandardsStats: {
                    ...state.classStandardsStats,
                    [classId]: {
                        ...dataByClassId,
                        [range]: {
                            data,
                            error,
                            isLoading: false,
                        },
                    },
                },
            };
        }

        /* --- */

        case types.SET_DISTRICT_ADMIN_SELECTED_SCHOOL: {
            return {
                ...state,
                selectedSchoolId: action.payload.schoolId,
                selectedDepartmentId: null,
                selectedClassId: null,
            };
        }

        case types.SET_DISTRICT_ADMIN_SELECTED_DEPARTMENT: {
            return {
                ...state,
                selectedDepartmentId: action.payload.departmentId,
                selectedClassId: null,
            };
        }

        case types.SET_DISTRICT_ADMIN_SELECTED_CLASS: {
            return {
                ...state,
                selectedClassId: action.payload.classId,
            };
        }

        /* --- */

        case types.CLEAR_DISTRICT_ADMIN_CLASS_STANDARDS_STATS: {
            return {
                ...state,
                classStandardsStats: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
