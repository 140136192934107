import React, { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import scroll from "@/base/lib/scroll.js";
import classNames from "@/base/lib/class-names.js";

import text from "@/base/text/index.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";

import IconClose from "@/base/icons/close/index.js";

import ButtonCircle from "@/base/components/button-circle/index.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";

import Notifications from "./notifications.js";
import NotificationControls from "./controls.js";
import useNotificationsNavHook from "./use-notifications.js";


const BAR_CONTENT_ELEMENT_ID = "notification-nav-bar-content";

const NotificationsNav = (props) => {
    const barRef = useRef(null);

    /* --- */

    useGlobalClose((evt) => {
        if (props.isGlobalCloseDisabled
            || !props.isBarVisible
            || barRef?.current?.contains?.(evt.target)) {
            return;
        }

        props.onClose();
    }, [
        props.isBarVisible,
        props.isGlobalCloseDisabled,
    ]);

    useEffect(() => {
        scroll.hideBodyScroll();

        return () => {
            scroll.unhideBodyScroll();
        };
    }, []);

    /* --- */

    const renderHeaderClose = () => {
        const closeIcon = (
            <IconClose
                inBlack
            />
        );

        return (
            <ButtonCircle
                icon={closeIcon}
                onClick={props.onClose}
            />
        );
    };

    const renderHeader = () => {
        const headerStyle = {};

        if (props.bodyScroll.hasScrollableContent) {
            headerStyle.paddingRight = `calc(2rem + ${props.bodyScroll.scrollbarWidth}px)`;
        }

        return (
            <div
                className={styles.header}
                style={headerStyle}
            >
                {text.notifications}
                {renderHeaderClose()}
            </div>
        );
    };

    /* --- */

    const renderNotifications = () => {
        const contentClassName = classNames({
            [styles.content]: true,
            [scrollbarStyles.scrollbar]: true,
        });

        const {
            data,
            error,
            onLoadMore,
            hasMore,
            isLoading,
        } = props.notifications;

        return (
            <div
                className={contentClassName}
                id={BAR_CONTENT_ELEMENT_ID}
            >
                <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={onLoadMore}
                    loader={null}
                    scrollableTarget={BAR_CONTENT_ELEMENT_ID}
                    hasMore={hasMore}
                >
                    <Notifications
                        data={data}
                        error={error}
                        onReadMore={props.onSelect}
                        onMarkAsRead={props.onMarkAsRead}
                        isLoading={isLoading}
                    />
                    <NotificationControls
                        error={error}
                        onLoadMore={onLoadMore}
                        isLoading={isLoading}
                    />
                </InfiniteScroll>
            </div>
        );
    };

    const renderBar = () => {
        const barClassName = classNames({
            [styles.bar]: true,
            [styles.barVisible]: props.isBarVisible,
        });

        return (
            <div
                ref={barRef}
                className={barClassName}
            >
                {renderHeader()}
                {renderNotifications()}
            </div>
        );
    };

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerVisible]: props.isBarVisible,
    });

    return (
        <div className={containerClassName}>
            {renderBar()}
        </div>
    );
};

NotificationsNav.defaultProps = {
    notifications: null,
    bodyScroll: {},
    onSelect: () => { },
    onClose: () => { },
    isBarVisible: false,
    isGlobalCloseDisabled: false,
};

export const useNotificationsNav = useNotificationsNavHook;
export default NotificationsNav;
