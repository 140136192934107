import text from "@/base/text/index.js";


const moveClass = (services, params) => {
    const { api, actions } = services;
    const {
        districtId,
        schoolId,
        departmentId,
        classId,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiAdminPopups.setClassMoveLoading(true));

        const res = await api.admin.moveClass({
            session: state.user.session,
            districtId,
            schoolId,
            departmentId,
            classId,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.uiAdminPopups.setClassMoveSuccess());
        } else {
            error = res.error || text.error;
            dispatch(actions.uiAdminPopups.setClassMoveLoading(false));
        }

        return {
            error,
        };
    };
};


export default {
    moveClass,
};
