// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u50kMbESjY80F2Vu_VW4 {\n    width: 1rem;\n    height: 1rem;\n\n    border: 0.1rem solid var(--color-1);\n    border-radius: 50%;\n\n    background-color: var(--color-6);\n}\n\n.jJuFN6TUdhvOF5imHFSS {\n    background-color: var(--color-3);\n}\n", "",{"version":3,"sources":["webpack://./base/components/status/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,mCAAmC;IACnC,kBAAkB;;IAElB,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".status {\n    width: 1rem;\n    height: 1rem;\n\n    border: 0.1rem solid var(--color-1);\n    border-radius: 50%;\n\n    background-color: var(--color-6);\n}\n\n.statusGreen {\n    background-color: var(--color-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "u50kMbESjY80F2Vu_VW4",
	"statusGreen": "jJuFN6TUdhvOF5imHFSS"
};
export default ___CSS_LOADER_EXPORT___;
