export default (apiURL, methods) => {
    return {
        getCountries(params = {}) {
            // NOTE: action=get_countries
            const url = `${apiURL}/geo/countries`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getSubdivisions(params = {}) {
            // NOTE: action=get_subdivisions
            const url = `${apiURL}/geo/subdivisions`;

            return methods.get3(url, {
                session_id: params.session,
                alpha2: params.alpha2,
            });
        },

        getDistricts(params = {}) {
            // NOTE: action=get_districts
            const url = `${apiURL}/geo/districts`;

            return methods.get3(url, {
                session_id: params.session,
                search: params.search,
                subdivision: params.subdivision,
            });
        },

        getSchools(params = {}) {
            // NOTE: action=get_schools
            const url = `${apiURL}/geo/schools`;

            return methods.get3(url, {
                session_id: params.session,
                search: params.search,
                district_id: params.districtId,
            });
        },

        getPrivateSchoolsCities(params = {}) {
            // NOTE: action=get_private_schools_cities_by_subdivision
            const url = `${apiURL}/geo/subdivisions/schools/private`;

            return methods.get3(url, {
                session_id: params.session,
                subdivision: params.subdivision,
            });
        },

        getPrivateSchools(params = {}) {
            // NOTE: action=get_private_schools_by_city
            const url = `${apiURL}/geo/cities/schools/private`;

            return methods.get3(url, {
                session_id: params.session,
                city: params.city,
            });
        },

        getDepartments(params = {}) {
            // NOTE: action=get_departments
            const url = `${apiURL}/geo/departments`;

            return methods.get3(url, {
                session_id: params.session,
                search: params.search,
                school_id: params.schoolId,
            });
        },
    };
};
