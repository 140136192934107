const signupCompleted = () => {
    if (globalThis?.dataLayer?.push) {
        globalThis.dataLayer.push({
            event: "signup_completed",
        });
    }
};

export default {
    signupCompleted,
};
