import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    uiEntitiesAdd: {},
    uiSchoolEdit: {},
    uiSchoolDelete: {},
    uiDepartmentEdit: {},
    uiDepartmentDelete: {},
    uiClassEdit: {},
    uiClassDelete: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.OPEN_DISTRICT_ADMIN_POPUP_EDIT_SCHOOL: {
            const { schoolId } = action.payload;

            return {
                ...state,
                uiSchoolEdit: {
                    schoolId,
                    isVisible: true,
                    isSuccess: false,
                    isLoading: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                uiSchoolEdit: {
                    ...state.uiSchoolEdit,
                    isLoading,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS: {
            return {
                ...state,
                uiSchoolEdit: {
                    ...state.uiSchoolEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT: {
            return {
                ...state,
                uiSchoolEdit: {},
            };
        }

        /* --- */

        case types.OPEN_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE: {
            const { schoolId } = action.payload;

            return {
                ...state,
                uiSchoolDelete: {
                    schoolId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_LOADING: {
            return {
                ...state,
                uiSchoolDelete: {
                    ...state.uiSchoolDelete,
                    error: "",
                    isLoading: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_ERROR: {
            const { error } = action.payload;

            return {
                ...state,
                uiSchoolDelete: {
                    ...state.uiSchoolDelete,
                    error,
                    isLoading: false,
                    isDeleted: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS: {
            return {
                ...state,
                uiSchoolDelete: {
                    ...state.uiSchoolDelete,
                    error: "",
                    isLoading: false,
                    isDeleted: true,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE: {
            return {
                ...state,
                uiSchoolDelete: {},
            };
        }

        /* --- */

        case types.OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT: {
            const { schoolId, departmentId } = action.payload;

            return {
                ...state,
                uiDepartmentEdit: {
                    schoolId,
                    departmentId,
                    isSuccess: false,
                    isLoading: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                uiDepartmentEdit: {
                    ...state.uiDepartmentEdit,
                    isLoading,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS: {
            return {
                ...state,
                uiDepartmentEdit: {
                    ...state.uiDepartmentEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT: {
            return {
                ...state,
                uiDepartmentEdit: {},
            };
        }

        /* --- */

        case types.OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE: {
            const { schoolId, departmentId } = action.payload;

            return {
                ...state,
                uiDepartmentDelete: {
                    schoolId,
                    departmentId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING: {
            return {
                ...state,
                uiDepartmentDelete: {
                    ...state.uiDepartmentDelete,
                    error: "",
                    isLoading: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_ERROR: {
            const { error } = action.payload;

            return {
                ...state,
                uiDepartmentDelete: {
                    ...state.uiDepartmentDelete,
                    error,
                    isLoading: false,
                    isDeleted: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS: {
            return {
                ...state,
                uiDepartmentDelete: {
                    ...state.uiDepartmentDelete,
                    error: "",
                    isDeleted: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE: {
            return {
                ...state,
                uiDepartmentDelete: {},
            };
        }

        /* --- */

        case types.OPEN_DISTRICT_ADMIN_POPUP_CLASS_EDIT: {
            const { schoolId, departmentId, classId } = action.payload;

            return {
                ...state,
                uiClassEdit: {
                    schoolId,
                    departmentId,
                    classId,
                    isLoading: false,
                    isSuccess: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                uiClassEdit: {
                    ...state.uiClassEdit,
                    isLoading,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_SUCCESS: {
            return {
                ...state,
                uiClassEdit: {
                    ...state.uiClassEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_CLASS_EDIT: {
            return {
                ...state,
                uiClassEdit: {},
            };
        }

        /* --- */

        case types.OPEN_DISTRICT_ADMIN_POPUP_CLASS_DELETE: {
            const { schoolId, departmentId, classId } = action.payload;

            return {
                ...state,
                uiClassDelete: {
                    schoolId,
                    departmentId,
                    classId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_LOADING: {
            return {
                ...state,
                uiClassDelete: {
                    ...state.uiClassDelete,
                    error: "",
                    isLoading: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_ERROR: {
            const { error } = action.payload;

            return {
                ...state,
                uiClassDelete: {
                    ...state.uiClassDelete,
                    error,
                    isLoading: false,
                    isDeleted: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_SUCCESS: {
            return {
                ...state,
                uiClassDelete: {
                    ...state.uiClassDelete,
                    error: "",
                    isDeleted: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_CLASS_DELETE: {
            return {
                ...state,
                uiClassDelete: {},
            };
        }

        /* --- */

        case types.OPEN_DISTRICT_ADMIN_POPUP_ENTITIES_ADD: {
            const { selectedTab, selectedSchool, selectedDepartment } = action.payload;

            return {
                ...state,
                uiEntitiesAdd: {
                    selectedTab,
                    selectedSchool: selectedSchool || "",
                    selectedDepartment: selectedDepartment || "",
                    isLoading: false,
                    isSchoolAdded: false,
                    isDepartmentAdded: false,
                    isClassAdded: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    isLoading,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED: {
            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    selectedSchool: action.payload.schoolId,
                    selectedDepartment: action.payload.departmentId,
                    isDepartmentAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED: {
            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    isSchoolAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED: {
            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    isClassAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL: {
            const { selectedSchool } = action.payload;

            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    selectedSchool,
                    selectedDepartment: "",
                },
            };
        }

        case types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT: {
            const { selectedDepartment } = action.payload;

            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    selectedDepartment,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_STATUSES: {
            return {
                ...state,
                uiEntitiesAdd: {
                    ...state.uiEntitiesAdd,
                    isSchoolAdded: false,
                    isDepartmentAdded: false,
                    isClassAdded: false,
                },
            };
        }

        case types.CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD: {
            return {
                ...state,
                uiEntitiesAdd: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
