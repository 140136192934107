import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconFivesyHC = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="40.001202"
                    height="26.000774"
                    viewBox="0 0 40.001202 26.000774"
                    fill="none"
                    id="svg132"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {iconProps.title}

                    <path
                        d="m 1.33333,11.999974 c -0.366663,0 -0.680552,-0.1305 -0.941663,-0.3916 C 0.130556,11.347174 0,11.033374 0,10.666674 V 1.333334 C 0,0.966664 0.130556,0.652774 0.391667,0.391664 0.652778,0.13055 0.966667,0 1.33333,0 h 9.33337 c 0.3666,0 0.6805,0.13055 0.9416,0.391664 0.2611,0.26111 0.3917,0.575 0.3917,0.94167 v 9.33334 c 0,0.3667 -0.1306,0.6805 -0.3917,0.9417 -0.2611,0.2611 -0.575,0.3916 -0.9416,0.3916 z"
                        fill="#075985"
                    />
                    <path
                        d="M 8,3.899974 V 2.500004 H 4 v 6.99997 h 1.33333 v -2.8 h 2 v -1.4 h -2 v -1.4 z"
                        fill="#ffffff"
                    />

                    <path
                        d="m 15.3306,11.999974 c -0.3666,0 -0.6805,-0.1305 -0.9416,-0.3916 -0.2611,-0.2612 -0.3917,-0.575 -0.3917,-0.9417 v -9.33334 c 0,-0.36667 0.1306,-0.68056 0.3917,-0.94167 C 14.6501,0.13055 14.964,0 15.3306,0 h 9.3334 c 0.3666,0 0.6805,0.13055 0.9416,0.391664 0.2612,0.26111 0.3917,0.575 0.3917,0.94167 v 9.33334 c 0,0.3667 -0.1305,0.6805 -0.3917,0.9417 -0.2611,0.2611 -0.575,0.3916 -0.9416,0.3916 z"
                        fill="#7c2d12"
                    />
                    <path
                        d="m 22.1973,3.899974 v -1.39997 h -4.2 v 1.39997 h 1.4 v 4.2 h -1.4 v 1.4 h 4.2 v -1.4 h -1.4 v -4.2 z"
                        fill="#ffffff"
                    />

                    <path
                        d="m 29.3346,11.999974 c -0.3667,0 -0.6806,-0.1305 -0.9417,-0.3916 -0.2611,-0.2612 -0.3917,-0.575 -0.3917,-0.9417 v -9.33334 c 0,-0.36667 0.1306,-0.68056 0.3917,-0.94167 C 28.654,0.13055 28.9679,0 29.3346,0 h 9.3333 c 0.3667,0 0.6805,0.13055 0.9417,0.391664 0.2611,0.26111 0.3916,0.575 0.3916,0.94167 v 9.33334 c 0,0.3667 -0.1305,0.6805 -0.3916,0.9417 -0.2612,0.2611 -0.575,0.3916 -0.9417,0.3916 z"
                        fill="#075985"
                    />
                    <path
                        d="m 36.2012,2.500004 h -1.4 v 4.26017 c 0,0.1855 -0.0738,0.3637 -0.2051,0.4949 l -0.4949,0.4949 -0.4949,-0.4949 c -0.1312,-0.1312 -0.2051,-0.3094 -0.2051,-0.4949 v -4.26017 h -1.4 v 3.93857 c 0,0.8309 0.3693,1.6191 1.0077,2.1511 l 1.0923,0.9103 1.0924,-0.9103 c 0.6384,-0.5324 1.0076,-1.3202 1.0076,-2.1511 z"
                        fill="#ffffff"
                    />

                    <path
                        d="m 1.33333,26.000774 c -0.366663,0 -0.680552,-0.1306 -0.941663,-0.3917 C 0.130556,25.347974 0,25.034074 0,24.667374 v -9.3333 c 0,-0.3667 0.130556,-0.6806 0.391667,-0.9417 0.261111,-0.2611 0.575,-0.3916 0.941663,-0.3916 h 9.33337 c 0.3666,0 0.6805,0.1305 0.9416,0.3916 0.2611,0.2611 0.3917,0.575 0.3917,0.9417 v 9.3333 c 0,0.3667 -0.1306,0.6806 -0.3917,0.9417 -0.2611,0.2611 -0.575,0.3917 -0.9416,0.3917 z"
                        fill="#374151"
                    />
                    <path
                        d="m 8.2,17.900774 v -1.4 H 4 v 7 h 4.2 v -1.4 H 5.4 v -1.4 h 2.1 v -1.4 H 5.4 v -1.4 z"
                        fill="#ffffff"
                    />

                    <path
                        d="m 15.3306,26.000774 c -0.3666,0 -0.6805,-0.1306 -0.9416,-0.3917 -0.2611,-0.2611 -0.3917,-0.575 -0.3917,-0.9417 v -9.3333 c 0,-0.3667 0.1306,-0.6806 0.3917,-0.9417 0.2611,-0.2611 0.575,-0.3916 0.9416,-0.3916 h 9.3334 c 0.3666,0 0.6805,0.1305 0.9416,0.3916 0.2612,0.2611 0.3917,0.575 0.3917,0.9417 v 9.3333 c 0,0.3667 -0.1305,0.6806 -0.3917,0.9417 -0.2611,0.2611 -0.575,0.3917 -0.9416,0.3917 z"
                        fill="#075985"
                    />
                    <path
                        d="m 21.8473,17.900774 0.35,-1.4 h -2.8 c -0.7731,0 -1.4,0.6268 -1.4,1.4 v 1.4 c 0,0.7731 0.6269,1.4 1.4,1.4 h 1.4 v 1.4 h -2.45 l -0.35,1.4 h 2.8 c 0.7732,0 1.4,-0.6269 1.4,-1.4 v -1.4 c 0,-0.7732 -0.6268,-1.4 -1.4,-1.4 h -1.4 v -1.4 z"
                        fill="#ffffff"
                    />

                    <path
                        d="m 29.3346,26.000774 c -0.3667,0 -0.6806,-0.1306 -0.9417,-0.3917 -0.2611,-0.2611 -0.3917,-0.575 -0.3917,-0.9417 v -9.3333 c 0,-0.3667 0.1306,-0.6806 0.3917,-0.9417 0.2611,-0.2611 0.575,-0.3916 0.9417,-0.3916 h 9.3333 c 0.3667,0 0.6805,0.1305 0.9417,0.3916 0.2611,0.2611 0.3916,0.575 0.3916,0.9417 v 9.3333 c 0,0.3667 -0.1305,0.6806 -0.3916,0.9417 -0.2612,0.2611 -0.575,0.3917 -0.9417,0.3917 z"
                        fill="#374151"
                    />
                    <path
                        d="m 34.8012,16.500774 v 1.75 c 0,0.2205 -0.105,0.427 -0.28,0.56 l -0.42,0.315 -0.42,-0.315 c -0.175,-0.133 -0.28,-0.3395 -0.28,-0.56 v -1.75 h -1.4 v 1.75 c 0,0.6615 0.3115,1.2845 0.84,1.68 l 0.0945,0.07 0.4655,0.3496 v 3.1504 h 1.4 v -3.1504 l 0.4655,-0.3496 0.0945,-0.07 c 0.5289,-0.3955 0.84,-1.0185 0.84,-1.68 v -1.75 z"
                        fill="#ffffff"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconFivesyHC;
