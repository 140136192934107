import React, { useState } from "react";

import Standards from "@/base/project/standards.js";

import IconStandards from "@/base/icons/standards/index.js";

import PopupWindow from "@/base/components/popup-window/index.js";
import Tabs from "@/base/components/tabs/index.js";

import styles from "./styles.module.css";


const PopupLearningStandards = (props) => {
    const [selectedStandardType, setSelectedStandardType] = useState(() => {
        if (props.defaultStandardType) {
            return props.defaultStandardType;
        }

        return Standards.getDefaultStandard(
            props.withCommonCore,
            props.withStateStandard,
        );
    });

    const onTabChange = (value) => {
        setSelectedStandardType(value);
    };

    const renderLeftControl = () => {
        if (!props.isMobile) {
            return null;
        }

        return (
            <div className={styles.standardsIcon}>
                <IconStandards
                    title="Standards"
                    isRed
                />
            </div>
        );
    };

    const renderTabs = () => {
        const tabs = Standards.getFilteredStandards(
            props.withCommonCore,
            props.withStateStandard,
        );

        return (
            <Tabs
                tabs={tabs}
                selectedTab={selectedStandardType}
                onChange={onTabChange}
                onlyTabs
            />
        );
    };

    const renderContent = () => {
        const standards = Standards.getStandardsByType(props.standards, selectedStandardType);

        const selectedStandards = standards.map((s) => {
            let standard = (
                <div className={styles.standard}>
                    {s?.standard || ""}
                </div>
            );

            if (selectedStandardType === "state-standard") {
                standard = (
                    <div className={styles.stateStandard}>
                        <div>{s.state}</div>
                        <div>|</div>
                        <div className={styles.stateStandardStandards}>
                            {s.standard}
                        </div>
                    </div>
                );
            }

            return (
                <div className={styles.standardContent}>
                    {standard}
                    <div className={styles.standardDetails}>
                        {s?.details || ""}
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.standards}>
                {selectedStandards}
            </div>
        );
    };

    return (
        <PopupWindow
            title="Learning Standards"
            leftControl={renderLeftControl()}
            titleToStart={props.isMobile}
            hideScrollbar={props.hideScrollbar}
            onClose={props.onClose}
        >
            {renderTabs()}
            <div className={styles.content}>
                {renderContent()}
            </div>
        </PopupWindow>
    );
};

PopupLearningStandards.defaultProps = {
    standards: [],
    defaultStandardType: null,

    onClose: () => { },

    withCommonCore: false,
    withStateStandard: false,
    isMobile: false,
    hideScrollbar: true,
};

export default PopupLearningStandards;
