import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAODTablet = (props) => {
    // NOTE: always-on-display tablet
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M5.5 5.25V4H12.5V5.25H5.5ZM6.5 8V6.75H11.5V8H6.5ZM1.5 12C1.0875 12 0.734375 11.853 0.440625 11.5591C0.146875 11.2652 0 10.9119 0 10.4992V1.4941C0 1.08137 0.146875 0.729167 0.440625 0.4375C0.734375 0.145833 1.0875 0 1.5 0H16.5C16.9125 0 17.2656 0.146958 17.5594 0.440875C17.8531 0.734791 18 1.08811 18 1.50083V10.5059C18 10.9186 17.8531 11.2708 17.5594 11.5625C17.2656 11.8542 16.9125 12 16.5 12H1.5ZM4 10.5H14V1.5H4V10.5Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAODTablet;
