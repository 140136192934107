
const RATING = {
    liked: 1,
    disliked: -1,
};

/* --- */

const isStoryRateLiked = (rate) => {
    return rate === RATING.liked;
};

const isStoryRateDisliked = (rate) => {
    return rate === RATING.disliked;
};

/* --- */

const getDislikeValue = () => {
    return RATING.disliked;
};

const getLikeValue = () => {
    return RATING.liked;
};

const getRatingValue = (isLiked) => {
    if (isLiked) {
        return RATING.liked;
    }

    return RATING.disliked;
};

/* --- */

export default {
    getRatingValue,
    getDislikeValue,
    getLikeValue,
    isStoryRateDisliked,
    isStoryRateLiked,
};
