import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    popup: {
        isVisible: false,
        offsetY: 0,
        translationLanguage: "",
        wordAlias: "",
        word: "",
    },

    isWordsLoaded: false,
    words: [],

    wordsById: {},
    wordsByAlias: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.OPEN_VOCABULARY_WORD_POPUP: {
            const { translationLanguage, wordAlias, offsetY } = action.payload;
            const { wordsByAlias } = state;

            return {
                ...state,
                popup: {
                    ...state.popup,
                    isVisible: true,
                    offsetY: offsetY || 0,
                    wordAlias,
                    translationLanguage: translationLanguage || "",
                },
                wordsByAlias: {
                    ...wordsByAlias,
                    [wordAlias]: {
                        isLoaded: false,
                    },
                },
            };
        }

        case types.CLOSE_VOCABULARY_WORD_POPUP: {
            return {
                ...state,
                popup: {
                    ...initialState.popup,
                },
            };
        }

        /* --- */

        case types.SET_VOCABULARY_WORDS: {
            return {
                ...state,
                isWordsLoaded: true,
                words: action.payload.words,
            };
        }

        case types.SET_VOCABULARY_WORD: {
            const {
                word,
                definitions,
                wordAlias,
                error,
            } = action.payload;

            const wordsByAlias = { ...state.wordsByAlias };
            const prevByWordAlias = wordsByAlias[wordAlias] || {};

            if (error) {
                return {
                    ...state,
                    wordsByAlias: {
                        ...wordsByAlias,
                        [wordAlias]: {
                            ...prevByWordAlias,
                            error,
                            isLoaded: true,
                        },
                    },
                };
            }

            const wordsById = { ...state.wordsById };

            const newWord = {
                word,
                definitions,
                error,
                isLoaded: true,
            };

            return {
                ...state,
                wordsById: {
                    ...wordsById,
                    [word.id]: newWord,
                },
                wordsByAlias: {
                    ...wordsByAlias,
                    [wordAlias]: newWord,
                },
            };
        }

        case types.SET_VOCABULARY_WORD_AND_POPUP: {
            const {
                word,
                definitions,
                wordAlias,
                error,
            } = action.payload;

            const wordsByAlias = { ...state.wordsByAlias };
            const prevByWordAlias = wordsByAlias[wordAlias] || {};

            if (error) {
                return {
                    ...state,
                    wordsByAlias: {
                        ...wordsByAlias,
                        [wordAlias]: {
                            ...prevByWordAlias,
                            error,
                            isLoaded: true,
                        },
                    },
                };
            }

            const wordsById = { ...state.wordsById };

            const newWord = {
                word,
                definitions,
                error,
                isLoaded: true,
            };

            return {
                ...state,
                wordsById: {
                    ...wordsById,
                    [word.id]: newWord,
                },
                wordsByAlias: {
                    ...wordsByAlias,
                    [wordAlias]: newWord,
                },
                popup: {
                    ...state.popup,
                    wordAlias,
                    word: word.word,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
