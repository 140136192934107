const loadTemplate = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        dispatch(actions.templates.setTemplateLoadingBySlug({
            slug: params.slug,
        }));

        const res = await api.templates.getTemplate({
            slug: params.slug,
        });

        let template = null;

        if (res.ok) {
            template = res.template;
        }

        dispatch(actions.templates.setTemplateBySlug({
            slug: params.slug,
            template,
        }));
    };
};

const loadImportStudentsTemplate = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.students.getImportStudentsTemplate({
            session: state.user.session,
        });

        let link = "";

        if (res.ok) {
            link = res.templateLink || "";
        }

        dispatch(actions.templates.setImportStudentsTemplate(link));
    };
};

export default {
    loadTemplate,
    loadImportStudentsTemplate,
};
