export const hasEmptyValue = (obj) => {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i += 1) {
        if (!obj[keys[i]]) {
            return true;
        }
    }

    return false;
};

export const hasValue = (obj) => {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i += 1) {
        if (obj[keys[i]]) {
            return true;
        }
    }

    return false;
};

export const filterByKey = (obj, key) => {
    const keys = Object.keys(obj);

    const newObj = {};

    for (let i = 0; i < keys.length; i += 1) {
        if (keys[i] !== key) {
            newObj[keys[i]] = obj[keys[i]];
        }
    }

    return newObj;
};

export const filterByKeys = (obj, keys = []) => {
    const objKeys = Object.keys(obj);

    const newObj = {};

    for (let i = 0; i < objKeys.length; i += 1) {
        if (keys.indexOf(objKeys[i]) === -1) {
            newObj[objKeys[i]] = obj[objKeys[i]];
        }
    }

    return newObj;
};


export default {
    hasEmptyValue,
    hasValue,

    filterByKey,
    filterByKeys,
};
