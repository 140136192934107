import React from "react";

import Achievements from "@/base/project/achievements.js";

import classNames from "@/base/lib/class-names.js";

import IconAchievement from "@/base/icons/achievement/index.js";

import TooltipSmall, { TooltipSmallWrapper } from "@/base/components/tooltip-small/index.js";

import AchievementIcon from "@/base/business/achievement-icon/index.js";

import styles from "./styles.module.css";


const AchievementsWidget = (props) => {
    const renderWidget = (params) => {
        const {
            achievementType,
            value,
            tooltipLabel,
            tooltipPosition,
            isLast,
        } = params;

        let tooltip = null;

        if (props.withTooltip) {
            tooltip = (
                <TooltipSmall
                    position={tooltipPosition || "center"}
                >
                    {tooltipLabel}
                </TooltipSmall>
            );
        }

        const widgetClassName = classNames({
            [styles.widget]: true,
            [styles.widgetLast]: isLast,
            [styles.widgetMini]: props.isMini,
        });

        return (
            <TooltipSmallWrapper tooltip={tooltip}>
                <div className={widgetClassName}>
                    <AchievementIcon
                        className={styles.widgetIcon}
                        type={achievementType}
                        theme={props.theme}
                        isMini
                    />
                    <div>
                        {value}
                    </div>
                </div>
            </TooltipSmallWrapper>
        );
    };

    const renderContent = () => {
        const achievements = Achievements.getAchievements();

        const storiesRead = renderWidget({
            achievementType: achievements.storiesRead.value,
            value: props.storiesRead,
            tooltipLabel: achievements.storiesRead.label,
        });

        const questionsAttempted = renderWidget({
            achievementType: achievements.questionsAttempted.value,
            value: props.questionsAttempted,
            tooltipLabel: achievements.questionsAttempted.label,
            tooltipPosition: "left",
            isLast: true,
        });

        return (
            <>
                {storiesRead}
                {questionsAttempted}
            </>
        );
    };

    const iconAchievementContainerClassName = classNames({
        [styles.iconAchievementContainer]: true,
        [styles.iconAchievementContainerMini]: props.isMini,
    });

    return (
        <div className={styles.container}>
            <div className={iconAchievementContainerClassName}>
                <IconAchievement
                    className={styles.iconAchievement}
                    isOrange
                />
            </div>
            {renderContent()}
        </div>
    );
};

AchievementsWidget.defaultProps = {
    theme: "",
    storiesRead: 0,
    questionsAttempted: 0,
    withTooltip: false,
    isMini: false,
};

export default AchievementsWidget;
