import moment from "moment";

const getFullYear = () => {
    return moment().format("YYYY");
};

const getDaysBefore = (days) => {
    return moment().subtract(days, "days");
};

const getDaysAfter = (days) => {
    return moment().add(days, "days");
};

const dateFromString = (dateStr) => {
    return moment(dateStr);
};

export default {
    getFullYear,
    getDaysBefore,
    getDaysAfter,
    dateFromString,
};
