import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconCheck from "@/base/icons/check/index.js";

import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const OptionWithLabels = (props) => {
    if (!props.option) {
        return null;
    }

    const onSelect = (evt) => {
        props.onSelect(evt, props.option);
    };

    const renderSelectedIcon = () => {
        if (!props.isSelected) {
            return null;
        }

        return (
            <IconCheck
                isRed
            />
        );
    };

    const label2 = props.option.label2 || "";

    const optClassName = classNames({
        [styles.option]: true,
        [styles.optionWithLabel2]: !!label2,
        [styles.optionWithIcon]: props.withIcon && !props.option.iconBefore,
        [styles.optionAlreadySelected]: props.isSelected,
    });

    const optionWithLabelsClassName = classNames({
        [styles.optionWithLabel]: true,
        [styles.optionWithLabels]: !!label2,
    });

    return (
        <DivButton
            className={optClassName}
            onClick={onSelect}
        >
            <div className={styles.optionLabel}>
                {props.option.iconBefore || null}
                <div className={optionWithLabelsClassName}>
                    <div>
                        {props.option.label || ""}
                    </div>
                    <div className={styles.optionLabel2}>
                        {label2}
                    </div>
                </div>
                {props.option.iconAfter || null}
            </div>
            {renderSelectedIcon()}
        </DivButton>
    );
};

OptionWithLabels.defaultProps = {
    option: null,
    isSelected: false,
    withIcon: false,
    onSelect: () => { },
};

export default OptionWithLabels;
