import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const SliderRange = (props) => {
    const renderSliderProgress = () => {
        const width = (props.value * 100) / props.max;

        const style = {
            width: `${width}%`,
        };

        return (
            <div
                className={styles.sliderProgress}
                style={style}
            />
        );
    };

    const renderSteps = () => {
        if (!props.isStepsVisible) {
            return null;
        }

        const {
            max,
            min,
            step,
            value,
        } = props;

        const totalSteps = max - min + 1;
        const steps = [];

        for (let i = 0; i < totalSteps; i += 1) {
            const stepValue = i * step;

            const stepClassName = classNames({
                [styles.step]: true,
                [styles.stepPassed]: stepValue < value,
                [styles.stepHidden]: stepValue === value,
            });

            steps.push(
                <div
                    key={`slider-step-${i}`}
                    className={stepClassName}
                />,
            );
        }

        return (
            <div className={styles.steps}>
                {steps}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.sliderWrapper}>
                <input
                    className={styles.slider}
                    id={props.id}
                    type="range"
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    value={props.value}
                    onChange={(evt) => {
                        const value = parseInt(evt.target.value, 10);
                        props.onChange(value);
                    }}
                />
                {renderSliderProgress()}
                {renderSteps()}
            </div>
        </div>
    );
};

SliderRange.defaultProps = {
    id: "",
    min: 1,
    max: 100,
    step: 1,
    value: 50,
    onChange: () => { },
    isStepsVisible: false,
};

export default SliderRange;
