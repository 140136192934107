import React, { useState } from "react";

import IconStandards from "@/base/icons/standards/index.js";
import IconPlusInRing from "@/base/icons/plus-in-ring/index.js";
import IconMinusInRing from "@/base/icons/minus-in-ring/index.js";
import IconCheck from "@/base/icons/check/index.js";
import IconClose from "@/base/icons/close/index.js";

import PopupWindow from "@/base/components/popup-window/index.js";
import Accordion from "@/base/components/accordion/index.js";
import Text from "@/base/components/text/index.js";

import styles from "./styles.module.css";


const PopupQuiz = (props) => {
    const [expandedStandards, setExpandedStandards] = useState([]);

    /* ----- */

    const onExpandStandard = (type) => {
        setExpandedStandards((prev) => {
            if (prev.indexOf(type) === -1) {
                return [...prev].concat(type);
            }

            return [...prev].filter((t) => t !== type);
        });
    };

    /* ----- */

    const renderTitle = () => {
        return (
            <div className={styles.header}>
                <div>Question</div>
                <Text>
                    {props.question}
                </Text>
            </div>
        );
    };

    const renderAnswerIcon = (answer) => {
        if (answer.isUserChoice || answer.isCorrect) {
            if (answer.isCorrect) {
                return (
                    <IconCheck
                        className={styles.answerIcon}
                        isGreen
                    />
                );
            }

            return (
                <IconClose
                    className={styles.answerIcon}
                    isRed
                />
            );
        }

        return <div />;
    };

    const renderAnswers = () => {
        return props.answers.map((answer) => {
            const icon = renderAnswerIcon(answer);

            return (
                <div className={styles.answer}>
                    {icon}
                    <Text>
                        {answer.answer}
                    </Text>
                </div>
            );
        });
    };

    const renderStandards = () => {
        const standards = props.standards.map((s) => {
            const isExpanded = expandedStandards.indexOf(s.type) !== -1;

            const label = (
                <div className={styles.standardLabel}>
                    <IconStandards
                        className={styles.standardLabelIcon}
                        isRed
                    />
                    <div>{s.name}</div>
                </div>
            );

            let icon = null;

            if (isExpanded) {
                icon = (
                    <IconMinusInRing
                        className={styles.standardIcon}
                        isRed
                    />
                );
            } else {
                icon = (
                    <IconPlusInRing
                        className={styles.standardIcon}
                        isRed
                    />
                );
            }

            return (
                <div className={styles.standard}>
                    <Accordion
                        label={label}
                        icon={icon}
                        iconClassName={styles.standardIcon}
                        onClick={() => {
                            onExpandStandard(s.type);
                        }}
                        withContentPadding={false}
                        expanded={isExpanded}
                    >
                        <div className={styles.standardContent}>
                            {s.details}
                        </div>
                    </Accordion>
                </div>
            );
        });

        return (
            <div>
                {standards}
            </div>
        );
    };

    /* ------ */

    return (
        <PopupWindow
            title={renderTitle()}
            barClassName={styles.popupBar}
            onClose={props.onClose}
            hideScrollbar={props.hideScrollbar}
            isCentered={!props.isMobile}
            titleToStart
        >
            <div>
                {renderAnswers()}
                {renderStandards()}
            </div>
        </PopupWindow>
    );
};

PopupQuiz.defaultProps = {
    question: "",

    answers: [],
    standards: [],

    onClose: () => { },

    isMobile: false,
    hideScrollbar: false,
};

export default PopupQuiz;
