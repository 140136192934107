import * as types from "./types.js";


export const setStudentSponsor = ({ sponsor }) => ({
    type: types.SET_STUDENT_SPONSOR,
    payload: {
        sponsor,
    },
});

export const setStudentAnnouncement = ({ announcement }) => ({
    type: types.SET_STUDENT_ANNOUNCEMENT,
    payload: {
        announcement,
    },
});

/* --- */

export const setStudentQuizResultsLoading = (payload) => ({
    type: types.SET_STUDENT_QUIZ_RESULTS_LOADING,
    payload,
});

export const setStudentQuizResults = (payload) => ({
    type: types.SET_STUDENT_QUIZ_RESULTS,
    payload,
});

/* --- */

export const setStudentGrades = (payload) => ({
    type: types.SET_STUDENT_GRADES,
    payload,
});

export const setStudentPendingGrade = (pendingGrade) => ({
    type: types.SET_STUDENT_PENDING_GRADE,
    payload: { pendingGrade },
});

/* --- */
