import React from "react";

import IconPencilFilled from "@/base/icons/pencil-filled/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import MenuContentRow from "@/base/components/menu-content-row/index.js";


const StudentOverview = (props) => {
    const renderReadingLevel = () => {
        let icon = null;

        if (props.withEditGrade) {
            icon = (
                <IconClickable
                    onClick={props.onEditGrade}
                >
                    <IconPencilFilled
                        title="Edit grade"
                        isBlack
                    />
                </IconClickable>
            );
        }

        return (
            <MenuContentRow
                label="Reading level"
                value={props.grade}
                icon={icon}
            />
        );
    };

    const renderQuizScore = () => {
        const val = props.quizAverage ? `${props.quizAverage}%` : "0%";

        return (
            <MenuContentRow
                label="Cumulative quiz average"
                value={val}
            />
        );
    };

    return (
        <div>
            {renderReadingLevel()}
            {renderQuizScore()}
        </div>
    );
};

StudentOverview.defaultProps = {
    grade: "",
    quizAverage: "",
    onEditGrade: () => { },
    withEditGrade: false,
};

export default StudentOverview;
