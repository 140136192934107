import React, { useEffect, useRef, useState } from "react";

import RequestLoader from "@/base/components/request-loader/index.js";

import "./styles.css";
import styles from "./styles.module.css";


const CAPTION_PARAMS = {
    kind: "captions",
    srclang: "en",
    label: "English",
    default: false,
};

const VideoCustom = (props) => {
    // TODO: finish lib global loading
    if (!globalThis.videojs) {
        return (
            <RequestLoader />
        );
    }

    const videoRef = useRef(null);
    const playerRef = useRef(null);

    /* --- */

    useEffect(() => {
        if (!props.captionSrc || !playerRef.current) {
            return;
        }

        playerRef.current.addRemoteTextTrack({
            ...CAPTION_PARAMS,
            src: props.captionSrc,
        }, false);

        if (playerRef.current?.controlBar?.getChild) {
            const captionButton = playerRef.current.controlBar.getChild("SubsCapsButton");

            if (captionButton.show) {
                captionButton.show();
            }
        }
    }, [props.captionSrc]);

    useEffect(() => {
        if (playerRef.current) {
            return;
        }

        if (videoRef?.current && globalThis.videojs) {
            const playerOptions = {
                poster: props.poster,
                sources: [{
                    src: props.src,
                    type: props.type,
                }],
                autoplay: false,
                controls: true,
                playsinline: true,
            };

            playerRef.current = globalThis.videojs(videoRef.current, playerOptions);
            playerRef.current.addClass("component-video-js");
        }
    }, [videoRef]);

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [videoRef]);

    useEffect(() => {
        if (!props.canPlay && videoRef.current) {
            videoRef.current.pause();
        }
    }, [props.canPlay]);

    /* --- */

    const renderCaptions = () => {
        if (!props.captionSrc) {
            return null;
        }

        return (
            <track
                src={props.captionSrc}
                kind={CAPTION_PARAMS.kind}
                srcLang={CAPTION_PARAMS.srclang}
                label={CAPTION_PARAMS.label}
                default={CAPTION_PARAMS.default}
            />
        );
    };

    /* --- */

    return (
        <div
            className={styles.videoPlayer}
            style={{ height: props.videoHeight || "auto" }}
        >
            <div data-vjs-player>
                <video
                    ref={videoRef}
                    className="video-js vjs-big-play-centered"
                    onPlay={() => {
                        props.onPlayStart(props.src, props.videoId);
                    }}
                    onEnded={() => {
                        props.onPlayEnd(props.src, props.videoId);
                    }}
                    onPause={() => {
                        props.onPause(props.src, props.videoId);
                    }}
                    preload="none"
                >
                    {renderCaptions()}
                    <p className="vjs-no-js">
                        To view this video please enable JavaScript,
                        and consider upgrading to a web browser that
                        <a
                            href="https://videojs.com/html5-video-support/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            supports HTML5 video
                        </a>
                    </p>
                </video>
            </div>
        </div>
    );
};

VideoCustom.defaultProps = {
    videoId: -1,
    src: "",
    type: "",
    poster: "",
    videoHeight: "auto",
    canPlay: true,
    withPosterCover: true,
    captionSrc: null,
    onPlayStart: () => { },
    onPlayEnd: () => { },
    onPause: () => { },
};

export default VideoCustom;
