import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import User from "@/base/project/user.js";
import Grades from "@/base/project/grades.js";
import Schools from "@/base/project/schools.js";
import Geo from "@/base/project/geo.js";
import Urls from "@/base/project/urls.js";

import text from "@/base/text/index.js";
import array from "@/base/lib/array.js";
import device from "@/base/lib/device.js";
import urls from "@/base/lib/urls.js";
import repeater from "@/base/lib/repeater.js";
import storage from "@/base/lib/storage/index.js";
import gtag from "@/base/lib/gtag.js";

import actions from "@/base/store/actions.js";
import teacherActions from "@/base/actions/teacher.js";
import lmsActions from "@/base/actions/lms.js";
import actionsGeo from "@/base/actions/geo.js";

import usePopup from "@/base/hooks/use-popup/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import IconClose from "@/base/icons/close/index.js";

import Message from "@/base/components/message/index.js";
import WizardMultiStep from "@/base/components/wizard-multi-step/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import PopupConfirmError from "@/base/components/popup-confirm-error/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";

import PopupConfirmSignOut from "@/base/business/popup-confirm-sign-out/index.js";
import PopupTourVideo from "@/base/business/popup-tour-video/index.js";

import TeacherSchoolDetailsForm from "@/base/forms/teacher-school-details/index.js";
import LMSClassesForm from "@/base/forms/lms-classes/index.js";

import UserCheckoutPayment from "@/app/containers/user-checkout-payment/index.js";

import app from "@/app/app.js";
import api from "@/app/api.js";
import events from "@/app/events.js";
import settings from "@/app/settings.js";

import styles from "./styles.module.css";

import PageConfirmation from "./page-confirmation.js";
import PageClassSetup from "./page-class-setup.js";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,

    dimensions: state.device.dimensions,

    classes: state.teacher.classes,
    isClassesLoaded: state.teacher.isClassesLoaded,

    lms: state.lms,
    lmsSignInUrl: state.info.features.lmsSignInUrl,

    geo: state.geo,

    location: state.navigation.location,
});

const TeacherPopupWelcome = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isMobile = store.dimensions.width < 920;
    const isTypeRegular = User.isTypeRegular(store.user);

    const wizard = useMemo(() => {
        const info = {
            steps: 3,
            withStepPayment: false,
        };

        if (isTypeRegular && store.user.isReferralCodeWithPayment) {
            info.steps = 4;
            info.withStepPayment = true;
        }

        return info;
    }, [isTypeRegular, store.user]);

    const isParentOrFamily = useMemo(() => {
        const isParent = User.hasRoleParent(store.user);
        const isSignUpRoleFamily = User.isSignUpRoleFamily(store.user);

        return isParent || isSignUpRoleFamily;
    }, [store.user]);

    /* --- */

    const jobRepeater = useRef(null);

    const [isLMSSynced, setIsLMSSynced] = useState(false);

    /* --- */

    const contentTabs = [
        { value: "import-classes", label: "Import classes" },
        { value: "crate-class", label: "Create a class" },
    ];

    const [selectedTab] = useState(() => {
        if (settings.features.LMS && store?.user?.isLmsUser) {
            return contentTabs[0].value;
        }

        return contentTabs[1].value;
    });

    /* --- */

    const withScrollRef = useRef(null);

    const [currentWizardStep, setCurrentWizardStep] = useState(0);
    const [isWizardDisabledPrevSteps, setIsWizardDisabledPrevSteps] = useState(false);

    const signOutPopup = usePopup();
    const popupError = usePopup();

    const [departments, setDepartments] = useState([]);

    const [selectedSchoolType, setSelectedSchoolType] = useState(() => {
        if (isParentOrFamily) {
            return Schools.getPrivateSchoolTypeValue();
        }

        return Schools.getPublicSchoolTypeValue();
    });

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedSubdivision, setSelectedSubdivision] = useState(null);
    const [selectedDistrictId, setSelectedDistrictId] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedSchoolName, setSelectedSchoolName] = useState(null);

    const [schoolDetailsFromState, setSchoolDetailsFormState] = useState({});

    const [isVisibleTourPopup, setIsVisibleTourPopup] = useState(false);

    const [toursLinks, setToursLinks] = useState({
        mobile: null,
        desktop: null,
        isLoaded: false,
    });

    /* --- */

    const getDistrict = () => {
        if (schoolDetailsFromState?.schoolDistrict?.isCreated) {
            return schoolDetailsFromState?.schoolDistrict?.value || "";
        }

        const districts = store.geo.districtsBySubdivision?.[selectedSubdivision] || [];

        const dId = schoolDetailsFromState?.schoolDistrict?.value || "";

        if (!dId) {
            return "";
        }

        const d = array.findByFieldName(districts, "id", dId);

        return d?.name || dId;
    };

    /* --- */

    const loadProviders = async () => {
        dispatch(lmsActions.loadProviders({ api, actions }, {
            session: store.session,
        }));
    };

    const loadCountries = async () => {
        const res = await api.geo.getCountries({
            session: store.session,
        });

        if (res.ok) {
            dispatch(actions.geo.setCountries({
                countries: res.countries,
            }));
        }
    };

    const loadSubdivisionsByAlpha2 = (alpha2) => {
        if (!alpha2) {
            return;
        }

        if (store.geo.subdivisionsByAlpha2?.[alpha2]?.isLoaded) {
            return;
        }

        dispatch(actionsGeo.loadSubdivisionsByAlpha2(
            { actions, api },
            { session: store.session, alpha2 },
        ));
    };

    /* --- */

    const loadDepartments = async () => {
        const res = await api.geo.getDepartments({
            session: store.session,
            search: "",
            schoolId: selectedSchoolName,
        });

        if (!res.ok) {
            setDepartments([]);
            return;
        }

        const ds = (res.departments || []).map((d) => ({
            value: d,
            name: d,
        }));

        setDepartments(ds);
    };

    const loadSchoolDistricts = async (subdivision) => {
        if (store.geo.districtsBySubdivision?.[subdivision]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolDistricts(
            { api, actions },
            { session: store.session, subdivision },
        ));
    };

    const loadSchoolCities = async (subdivision) => {
        if (store.geo.citiesBySubdivision?.[subdivision]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolCities(
            { api, actions },
            { session: store.session, subdivision },
        ));
    };

    const loadSchoolsByCity = async (city) => {
        if (store.geo.schoolsByCity?.[city]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolsByCity(
            { actions, api },
            { session: store.session, city },
        ));
    };

    const loadSchoolsByDistrictId = async (districtId) => {
        if (store.geo.schoolsByDistrictId?.[districtId]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolsByDistrictId(
            { actions, api },
            { session: store.session, districtId },
        ));
    };

    /* --- */

    const loadUser = async () => {
        const userRes = await api.user.checkSession({
            session: store.session,
        });

        if (userRes.ok) {
            dispatch(actions.user.setUser(userRes.user));
        }
    };

    const loadTourVideoLink = async () => {
        const res = await api.tutorials.getTutorialVideoLink({
            session: store.session,
        });

        if (res.ok) {
            setToursLinks({
                mobile: res.data.mobile,
                desktop: res.data.desktop,
                isLoaded: true,
            });
        }
    };

    const onExploreOnMyOwn = () => {
        events.teacher.exploreOnMyOwn({
            session: store.session,
            isMobile,
        });

        props.onExploreOnMyOwn();
    };

    const onJob = (jobId) => {
        if (jobRepeater.current) {
            return;
        }

        dispatch(lmsActions.startSync({ actions }));

        jobRepeater.current = repeater.getFunctionRepeater(() => {
            const params = {
                session: store.session,
                jobId,
            };

            dispatch(lmsActions.checkSyncJob({ api, actions }, params, {
                onStop() {
                    dispatch(lmsActions.stopSync({ actions }));
                    jobRepeater.current.stop();
                },
                onSuccess() {
                    dispatch(lmsActions.stopSync({ actions }));

                    setIsLMSSynced(true);
                    jobRepeater.current.stop();

                    loadUser();

                    if (Urls.isDashboard(store.location.pathname)) {
                        dispatch(teacherActions.reloadDashboard({
                            api,
                            actions,
                        }));
                    }

                    onExploreOnMyOwn();
                },
            }));
        });

        jobRepeater.current.start();
    };

    const onSyncLMSClasses = async (values) => {
        dispatch(lmsActions.syncClasses({ api, actions }, {
            session: store.session,
            classes: values.classes,
        }, {
            onSuccess: onJob,
        }));
    };

    /* --- */

    const onLogout = () => {
        signOutPopup.open();
    };

    const onSignOut = useCallback(() => {
        app.actions.common.user.signOut();
    }, []);

    /* --- */

    const onCountryChange = (alpha2) => {
        setSelectedCountry(alpha2);
        loadSubdivisionsByAlpha2(alpha2);
    };

    const onSubdivisionsChange = (value) => {
        if (!value) {
            return;
        }

        setSelectedSubdivision(value);

        if (Schools.isPublicSchoolType(selectedSchoolType)) {
            loadSchoolDistricts(value);
            return;
        }

        loadSchoolCities(value);
    };

    const onSelectDistrict = (values) => {
        if (!values?.value || values.isCreated) {
            return;
        }

        setSelectedDistrictId(values.value);

        loadSchoolsByDistrictId(values.value);
    };

    const onSelectSchoolCity = (values) => {
        if (!values?.value) {
            return;
        }

        setSelectedCity(values.value);

        loadSchoolsByCity(values.value);
    };

    const onSelectSchoolName = (values) => {
        if (!values?.value) {
            return;
        }

        setSelectedSchoolName(values.value);
    };

    /* --- */

    const onChangeSchoolType = (type) => {
        setSelectedSchoolType(type);

        if (Schools.isPrivateSchoolType(type)) {
            loadSchoolCities(selectedSubdivision);
            return;
        }

        loadSchoolDistricts(selectedSubdivision);
    };

    /* --- */

    const onTakeTour = () => {
        events.teacher.takeTheTour({
            session: store.session,
            isMobile,
        });

        setIsVisibleTourPopup(true);
        props.onTakeTour();
    };

    /* --- */

    const onGoToStep = (newStepIndex) => {
        if (withScrollRef?.current?.scrollTo) {
            withScrollRef.current.scrollTo(0, 0);
        }

        setCurrentWizardStep(newStepIndex);

        const isLast = newStepIndex === wizard.steps - 1;

        if (isLast) {
            gtag.signupCompleted();
        }
    };

    /* --- */

    const onSchoolDetailsSubmit = (values) => {
        onGoToStep(1);

        setSchoolDetailsFormState(values);
    };

    const onClassSetupSubmit = async (values, { setSubmitting }) => {
        const userLastName = User.getLastName(store.user);

        const department = values?.department?.value || userLastName || "My department";
        const className = values?.className || userLastName || "My class";
        const grades = values?.grades?.value || Grades.getGradesRanges()[0] || "";

        setIsWizardDisabledPrevSteps(true);

        const { error } = await app.actions.teacher.class.setupClass({
            selectedSchoolType,
            selectedDistrictId,
            selectedCity,
            country: schoolDetailsFromState?.country?.value || "",
            subdivision: schoolDetailsFromState?.subdivision?.value || "",
            district: getDistrict(),
            city: schoolDetailsFromState?.schoolCity?.value || "",
            schoolName: schoolDetailsFromState?.schoolName?.value || "",
            department,
            className,
            grades,
            endDate: values.endDate || "",
        });

        if (error) {
            setSubmitting(false);
            setIsWizardDisabledPrevSteps(false);

            popupError.setMessage(error);
            popupError.open();
            return;
        }

        loadUser();
        onGoToStep(2);
    };

    /* --- */

    const onCloseTourVideo = () => {
        setIsVisibleTourPopup(false);
        props.onCloseTourVideo();
    };

    const onTourVideoPlay = (src, videoId) => {
        events.teacher.tourVideoPlay({
            session: store.session,
            videoId,
            isMobile,
        });
    };

    const onTourVideoPause = (src, videoId, timeTook) => {
        events.teacher.signUpTourVideoPause({
            session: store.session,
            videoId,
            timeTook,
            isMobile,
        });
    };

    const onTourVideoEnd = (src, videoId) => {
        events.teacher.signUpTourVideoEnd({
            session: store.session,
            videoId,
            isMobile,
        });
    };

    /* --- */

    useEffect(() => {
        if (isTypeRegular && !store.isClassesLoaded) {
            dispatch(teacherActions.loadClasses({
                api,
                storage,
                actions,
            }, {
                session: store.session,
            }));
        }

        if (store.user.isLmsUser) {
            loadProviders();
        }

        if (settings.features.VIDEO_TOUR) {
            loadTourVideoLink();
        }

        loadCountries();
        loadDepartments();

        const defaultAlpha2 = Geo.getDefaultAlpha2();
        onCountryChange(defaultAlpha2);
    }, []);

    useEffect(() => {
        // NOTE: move to payment if class already created
        if (isTypeRegular
            && store.isClassesLoaded
            && store.classes.length > 0
            && currentWizardStep < 2) {
            setCurrentWizardStep(2);
            setIsWizardDisabledPrevSteps(true);
        }
    }, [store.isClassesLoaded]);

    /* --- */

    const renderLeftControl = () => {
        return (
            <div className={styles.close} />
        );
    };

    const renderRightControl = () => {
        return (
            <IconClickable
                className={styles.close}
                onClick={onLogout}
            >
                <IconClose
                    isBlack
                />
            </IconClickable>
        );
    };

    const renderSchoolDetailsMessage = () => {
        const detailsText = isParentOrFamily
            ? text.teacherSignUpForm3
            : text.teacherSignUpForm1;

        return (
            <div className={styles.schoolDetailsLabel}>
                {detailsText}
            </div>
        );
    };

    const renderSchoolDetails = () => {
        const {
            subdivisionsByAlpha2,
            districtsBySubdivision,
            citiesBySubdivision,
            schoolsByCity,
            schoolsByDistrictId,
            isCountriesLoaded,
        } = store.geo;

        if (!isCountriesLoaded) {
            return (
                <RequestLoader />
            );
        }

        let initCountry = "";
        let initSchoolCity = "";
        let initSchoolName = "";

        if (isParentOrFamily) {
            initSchoolCity = {
                value: User.getLastName(store.user) || "Family",
                isCreated: true,
            };

            initSchoolName = {
                value: "Family",
                isCreated: true,
            };
        }

        const countries = store.geo.countries.map((country) => ({
            value: country.alpha2,
            name: country.name,
        }));

        const defaultCountry = Geo.getDefaultCountry(countries);

        if (defaultCountry) {
            initCountry = {
                value: defaultCountry,
                isCreated: false,
            };
        }

        const initialValues = {
            country: initCountry,
            subdivision: "",
            schoolDistrict: "",
            schoolCity: initSchoolCity,
            schoolName: initSchoolName,
        };

        let subdivisionName = "State";
        let subdivisions = [];

        const isSubdivisionsLoaded = subdivisionsByAlpha2?.[selectedCountry]?.isLoaded || false;

        if (selectedCountry && isSubdivisionsLoaded) {
            subdivisionName = subdivisionsByAlpha2[selectedCountry].subdivisionName;
            subdivisions = subdivisionsByAlpha2[selectedCountry].subdivisions.map((state) => ({
                value: state.code,
                name: state.name,
                name2: state.code,
            }));
        }

        const districtsOptions = (districtsBySubdivision?.[selectedSubdivision] || []).map((d) => ({
            value: d.id,
            name: d.name,
        }));

        const citiesOptions = (citiesBySubdivision?.[selectedSubdivision] || []).map((c) => ({
            value: c.name,
            name: c.name,
        }));

        let schoolNamesOptions = [];

        if (Schools.isPublicSchoolType(selectedSchoolType)) {
            schoolNamesOptions = (schoolsByDistrictId?.[selectedDistrictId] || []).map((s) => ({
                value: s.name,
                name: s.name,
            }));
        } else {
            schoolNamesOptions = (schoolsByCity?.[selectedCity] || []).map((s) => ({
                value: s.name,
                name: s.name,
            }));
        }

        return (
            <div className={styles.wizardPage}>
                {renderSchoolDetailsMessage()}
                <TeacherSchoolDetailsForm
                    initialValues={initialValues}
                    subdivisionName={subdivisionName}
                    selectedSchoolType={selectedSchoolType}
                    countries={countries}
                    subdivisions={subdivisions}
                    districtsOptions={districtsOptions}
                    citiesOptions={citiesOptions}
                    schoolNamesOptions={schoolNamesOptions}
                    onCountryChange={onCountryChange}
                    onSubdivisionsChange={onSubdivisionsChange}
                    onSelectDistrict={onSelectDistrict}
                    onSelectCity={onSelectSchoolCity}
                    onSelectSchoolName={onSelectSchoolName}
                    onChangeSchoolType={onChangeSchoolType}
                    onSubmit={onSchoolDetailsSubmit}
                    isFamily={isParentOrFamily}
                    isDisabledSubdivision={!isSubdivisionsLoaded}
                    isDisabledTabIndex={currentWizardStep !== 0}
                />
            </div>
        );
    };

    const renderClassSetUp = () => {
        const { withStepPayment } = wizard;

        return (
            <PageClassSetup
                userLastName={User.getLastName(store.user)}
                departments={departments}
                onBack={() => {
                    onGoToStep(0);
                }}
                onSubmit={onClassSetupSubmit}
                isParentOrFamily={isParentOrFamily}
                isDisabledTabIndex={currentWizardStep !== 1}
                withStepPayment={withStepPayment}
                withEndDate={settings.features.END_DATE && withStepPayment}
            />
        );
    };

    const renderPayment = () => {
        if (User.hasActivePlan(store.user)) {
            return (
                <div className={styles.wizardPage}>
                    <Message>
                        Payment completed
                    </Message>
                    <ButtonBig
                        uppercase
                        isDisabledTabIndex
                        onClick={() => {
                            onGoToStep(3);
                        }}
                    >
                        Finish
                    </ButtonBig>
                </div>
            );
        }

        return (
            <div className={styles.wizardPage}>
                <UserCheckoutPayment
                    isDisabledTabIndex={currentWizardStep !== 2}
                    onPayment={() => {
                        onGoToStep(3);
                    }}
                />
            </div>
        );
    };

    const renderConfirmation = () => {
        const isDisabledTabIndex = isTypeRegular
            ? currentWizardStep !== 3
            : currentWizardStep !== 2;

        return (
            <PageConfirmation
                onExploreOnMyOwn={onExploreOnMyOwn}
                onTakeTour={onTakeTour}
                isParentOrFamily={isParentOrFamily}
                isDisabledTabIndex={isDisabledTabIndex}
                isToursLinksLoaded={toursLinks.isLoaded}
                withTourButton={settings.features.VIDEO_TOUR}
            />
        );
    };

    const renderLMSContent = () => {
        if (isLMSSynced) {
            return (
                <div className={styles.lmsError}>
                    Ok
                </div>
            );
        }

        if (store.lms.syncingError) {
            return (
                <div>
                    <div className={styles.lmsError}>
                        {store.lms.syncingError}
                    </div>
                    <div className={styles.lmsControl}>
                        <ButtonBig
                            type="button"
                            onClick={() => {
                                urls.reload();
                            }}
                        >
                            Try again
                        </ButtonBig>
                    </div>
                </div>
            );
        }

        if (store.lms.isSyncingClasses) {
            return (
                <div>
                    <RequestLoader />
                    <div className={styles.lmsError}>
                        Syncing classes ...
                    </div>
                </div>
            );
        }

        if (store.lms.providersError) {
            return (
                <div>
                    <div className={styles.lmsError}>
                        {store.lms.providersError}
                    </div>
                    <div className={styles.lmsControl}>
                        <ButtonBig
                            type="button"
                            onClick={() => {
                                urls.redirect(store.lmsSignInUrl);
                            }}
                        >
                            Sign In with LMS
                        </ButtonBig>
                    </div>
                </div>
            );
        }

        if (!store.lms.isProvidersLoaded) {
            return (
                <RequestLoader />
            );
        }

        return (
            <LMSClassesForm
                providers={store.lms.providers}
                onSubmit={onSyncLMSClasses}
            />
        );
    };

    const renderCreateClassContent = () => {
        const wizardSteps = [
            { name: "School Details", content: renderSchoolDetails() },
            { name: "Class Set Up", content: renderClassSetUp() },
        ];

        if (wizard.withStepPayment) {
            wizardSteps.push({ name: "Payment", content: renderPayment() });
        }

        wizardSteps.push({ name: "Confirmation", content: renderConfirmation() });

        return (
            <div className={styles.wizardSteps}>
                <WizardMultiStep
                    disablePrevSteps={isWizardDisabledPrevSteps}
                    contentStepClassName={styles.wizardContentStep}
                    step={currentWizardStep}
                    steps={wizardSteps}
                    onStepChange={onGoToStep}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (selectedTab === contentTabs[0].value) {
            return renderLMSContent();
        }

        if (selectedTab === contentTabs[1].value) {
            return renderCreateClassContent();
        }

        return null;
    };

    const renderSignOutPopup = () => {
        if (!signOutPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmSignOut
                onSignOut={onSignOut}
                onClose={signOutPopup.close}
            />
        );
    };

    const renderErrorPopup = () => {
        if (!popupError.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmError
                error={popupError.state.message}
                onClose={popupError.close}
            />
        );
    };

    const renderTourPopup = () => {
        if (!isVisibleTourPopup) {
            return null;
        }

        let video = toursLinks.desktop;
        let videoCaptionSrc = null;

        if (isMobile) {
            video = toursLinks.mobile;
        }

        if (video?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: video?.id,
                session: store.session,
            });
        }

        return (
            <PopupTourVideo
                video={video}
                videoCaptionSrc={videoCaptionSrc}
                onClose={onCloseTourVideo}
                onPlayStart={onTourVideoPlay}
                onPause={onTourVideoPause}
                onPlayEnd={onTourVideoEnd}
                isDefaultVideo={!device.isChrome}
                isMobile={isMobile}
            />
        );
    };

    return (
        <>
            <PopupWindow
                title="Welcome to The Juice!"
                leftControl={renderLeftControl()}
                rightControl={renderRightControl()}
                withTitleEllipsis={false}
                withScrollRef={withScrollRef}
                hideClose
                orangeBar
            >
                <div className={styles.content}>
                    {renderContent()}
                </div>
            </PopupWindow>

            {renderTourPopup()}
            {renderSignOutPopup()}
            {renderErrorPopup()}
        </>
    );
};

TeacherPopupWelcome.defaultProps = {
    onTakeTour: () => { },
    onExploreOnMyOwn: () => { },
    onCloseTourVideo: () => { },
};

export default TeacherPopupWelcome;
