// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NJA2FPyYHw_HwUHs3ncV {\n    max-width: 38rem;\n\n    margin: 0 auto;\n    padding: 2rem 2rem 0 2rem;\n}\n\n.GYifxHzDn4Ojb9dAW94e {\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius);\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-fivesy/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;IAEhB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,gDAAgD;IAChD,2CAA2C;AAC/C","sourcesContent":[".gradesSelectorContainer {\n    max-width: 38rem;\n\n    margin: 0 auto;\n    padding: 2rem 2rem 0 2rem;\n}\n\n.gradesSelector {\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradesSelectorContainer": "NJA2FPyYHw_HwUHs3ncV",
	"gradesSelector": "GYifxHzDn4Ojb9dAW94e"
};
export default ___CSS_LOADER_EXPORT___;
