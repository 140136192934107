import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAudioStop = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <circle
                        cx="13"
                        cy="13"
                        r="12"
                        stroke="#48494b"
                        strokeWidth="1"
                    />
                    <path
                        fill="currentColor"
                        d="M10.4,8.67h5.2a1.74,1.74,0,0,1,1.73,1.73v5.2a1.74,1.74,0,0,1-1.73,1.73H10.4A1.74,1.74,0,0,1,8.67,15.6V10.4A1.74,1.74,0,0,1,10.4,8.67Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAudioStop;
