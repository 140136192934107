import * as types from "./types.js";


export const setClassesLoading = () => ({
    type: types.SET_TEACHER_CLASSES_LOADING,
});

export const setClasses = (payload) => ({
    type: types.SET_TEACHER_CLASSES,
    payload,
});

/* --- */

export const setClassProgressReport = (payload) => ({
    type: types.SET_TEACHER_CLASS_PROGRESS_REPORT,
    payload,
});

/* --- */

export const setClassStudentsById = (payload) => ({
    type: types.SET_TEACHER_CLASS_STUDENTS,
    payload,
});

export const setLoadingClassStudentsById = (payload) => ({
    type: types.SET_LOADING_TEACHER_CLASS_STUDENTS,
    payload,
});

export const setClassCode = (payload) => ({
    type: types.SET_TEACHER_CLASS_CODE,
    payload,
});

export const setClassEndDate = (payload) => ({
    type: types.SET_TEACHER_CLASS_END_DATE,
    payload,
});

export const setTeacherSelectedClass = (payload) => ({
    type: types.SET_TEACHER_SELECTED_CLASS,
    payload,
});

export const setTeacherStatsRange = (payload) => ({
    type: types.SET_TEACHER_STATS_RANGE,
    payload,
});

export const setTeacherClassesSponsors = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS,
    payload,
});

export const setTeacherClassesSponsorsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_LOADING,
    payload,
});

export const setTeacherSponsorsByIdSaving = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_BY_ID_SAVING,
    payload,
});

export const setTeacherSponsorsContentById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_CONTENT_BY_ID,
    payload,
});

export const setTeacherSponsorsImageByIdClear = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_CLEAR,
    payload,
});

export const setTeacherSponsorsImageByIdLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_LOADING,
    payload,
});

export const setTeacherSponsorsImageById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID,
    payload,
});

export const setTeacherSponsorsByIdLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_BY_ID_LOADING,
    payload,
});

export const setTeacherSponsorsById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_SPONSORS_BY_ID,
    payload,
});

export const clearStudentsScoresByRange = (payload) => ({
    type: types.CLEAR_STUDENTS_SCORES_BY_RANGE,
    payload,
});

export const setTeacherClassScoresByRangeLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_RANGE_LOADING,
    payload,
});

export const setTeacherClassScoresByRange = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_RANGE,
    payload,
});

export const updateTeacherClassScoresByDailyJuice = (payload) => ({
    type: types.UPDATE_TEACHER_CLASS_SCORES_BY_DAILY_JUICE,
    payload,
});

export const clearUpdatedStatusTeacherClassScoresByDailyJuice = (payload) => ({
    type: types.CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_DAILY_JUICE,
    payload,
});

export const setTeacherClassesAnnouncements = (payload) => ({
    type: types.SET_TEACHER_CLASSES_ANNOUNCEMENTS,
    payload,
});

export const setTeacherClassesAnnouncementsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_ANNOUNCEMENTS_LOADING,
    payload,
});

export const setTeacherAnnouncementByIdSaving = (payload) => ({
    type: types.SET_TEACHER_CLASSES_ANNOUNCEMENT_BY_ID_SAVING,
    payload,
});

export const setTeacherAnnouncementsByIdLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID_LOADING,
    payload,
});

export const setTeacherAnnouncementContentById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_ANNOUNCEMENT_CONTENT_BY_ID,
    payload,
});

export const setTeacherAnnouncementsById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID,
    payload,
});

export const setTeacherStudentWithForgottenPasswords = ({ students }) => ({
    type: types.SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS,
    payload: {
        students,
    },
});

export const clearClassesScores = (payload) => ({
    type: types.CLEAR_CLASSES_SCORES,
    payload,
});

export const deleteTeacherStudentWithForgottenPassword = (payload) => ({
    type: types.DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD,
    payload,
});

export const setTeacherClassRateDetailsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_RATE_DETAILS_LOADING,
    payload,
});

export const setTeacherClassRateDetails = (payload) => ({
    type: types.SET_TEACHER_CLASS_RATE_DETAILS,
    payload,
});

export const setTeacherClassRateLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_RATE_LOADING,
    payload,
});

export const setTeacherClassRate = (payload) => ({
    type: types.SET_TEACHER_CLASS_RATE,
    payload,
});

export const clearClassesCategories = (payload) => ({
    type: types.CLEAR_TEACHER_CLASSES_CATEGORIES,
    payload,
});

export const setTeacherClassesCategoriesLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE_LOADING,
    payload,
});

export const setTeacherClassesCategories = (payload) => ({
    type: types.SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE,
    payload,
});

export const setTeacherClassAverageScoresLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_AVERAGE_SCORES_LOADING,
    payload,
});

export const setTeacherClassAverageScores = (payload) => ({
    type: types.SET_TEACHER_CLASS_AVERAGE_SCORES,
    payload,
});

export const setTeacherClassesAverageScoresByStandardsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS_LOADING,
    payload,
});

export const setTeacherClassesAverageScoresByStandards = (payload) => ({
    type: types.SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS,
    payload,
});

export const deleteStudentWithForgottenPassword = (payload) => {
    return (dispatch) => {
        window.setTimeout(() => {
            dispatch(deleteTeacherStudentWithForgottenPassword(payload));
        }, 200);
    };
};

export const setTeacherStudentsWithPendingStatus = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    payload,
});

export const setTeacherStudentsWithPendingStatusLoading = () => ({
    type: types.SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING,
});

export const clearTeacherStudentsWithPendingStatus = () => ({
    type: types.CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS,
});

export const deleteTeacherStudentWithPendingStatusById = (payload) => ({
    type: types.DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID,
    payload,
});

export const deleteStudentWithPendingStatusById = (payload) => {
    return (dispatch) => {
        window.setTimeout(() => {
            dispatch(deleteTeacherStudentWithPendingStatusById(payload));
        }, 200);
    };
};

/* --- */

export const setTeacherClassesPerformanceByRangeLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE_LOADING,
    payload,
});

export const setTeacherClassesPerformanceByRange = (payload) => ({
    type: types.SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE,
    payload,
});

export const updateTeacherClassesPerformanceByRange = (payload) => ({
    type: types.UPDATE_TEACHER_CLASSES_PERFORMANCE_BY_RANGE,
    payload,
});

export const clearUpdatesStatusTeacherClassesPerformanceByRange = (payload) => ({
    type: types.CLEAR_UPDATED_STATUS_TEACHER_CLASSES_PERFORMANCE_BY_RANGE,
    payload,
});

export const clearTeacherClassesPerformance = (payload) => ({
    type: types.CLEAR_TEACHER_CLASSES_PERFORMANCE,
    payload,
});

/* --- */

export const setTeacherClassesStatsByLearningStandardsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS_LOADING,
    payload,
});

export const setTeacherClassesStatsByLearningStandards = (payload) => ({
    type: types.SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS,
    payload,
});

export const clearTeacherClassesStatsByLearningStandards = (payload) => ({
    type: types.CLEAR_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS,
    payload,
});


export const setTeacherClassesStudentsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_STUDENTS_LOADING,
    payload,
});

export const setTeacherClassesStudents = (payload) => ({
    type: types.SET_TEACHER_CLASSES_STUDENTS,
    payload,
});

/* --- */

export const setTeacherClassScoresByDailyJuicesLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_LOADING,
    payload,
});

export const setTeacherClassScoresByDailyJuices = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES,
    payload,
});

export const clearTeacherClassScoresByDailyJuices = (payload) => ({
    type: types.CLEAR_TEACHER_CLASSES_SCORES_BY_DAILY_JUICES,
    payload,
});

/* --- */

export const setStudentDailyJuicesScoresUpdatedStatus = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_STUDENT_ANSWERED,
    payload,
});

export const clearStudentDailyJuicesScoresUpdatedStatus = (payload) => ({
    type: types.CLEAR_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_STUDENT_ANSWERED_UPDATED_STATUS,
    payload,
});

/* --- */

export const setTeacherClassScoresByAssignmentsLoading = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS_LOADING,
    payload,
});

export const setTeacherClassScoresByAssignments = (payload) => ({
    type: types.SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS,
    payload,
});

export const updateTeacherClassScoresByAssignments = (payload) => ({
    type: types.UPDATE_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS,
    payload,
});

export const clearUpdatedStatusTeacherClassScoresByAssignments = (payload) => ({
    type: types.CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS,
    payload,
});

export const clearTeacherClassScoresByAssignments = (payload) => ({
    type: types.CLEAR_TEACHER_CLASSES_SCORES_BY_ASSIGNMENTS,
    payload,
});

/* ----- */

export const setTeacherClassesDetailsByIdLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_DETAILS_BY_ID_LOADING,
    payload,
});

export const setTeacherClassesDetailsById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_DETAILS_BY_ID,
    payload,
});

/* ----- */

export const setTeacherClassesTeachersByIdLoading = (payload) => ({
    type: types.SET_TEACHER_CLASSES_TEACHERS_BY_ID_LOADING,
    payload,
});

export const setTeacherClassesTeachersById = (payload) => ({
    type: types.SET_TEACHER_CLASSES_TEACHERS_BY_ID,
    payload,
});

export const setTeacherClassesPrimaryTeacher = (payload) => ({
    type: types.SET_TEACHER_CLASSES_PRIMARY_TEACHER,
    payload,
});

/* --- */

export const setTeacherDerosteredClassesLoading = (payload) => ({
    type: types.SET_TEACHER_DEROSTERED_CLASSES_LOADING,
    payload,
});

export const setTeacherDerosteredClasses = (payload) => ({
    type: types.SET_TEACHER_DEROSTERED_CLASSES,
    payload,
});
