// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EOBANK_LoT9XzPhA_Loa {\n    min-height: auto;\n}\n\n/* ----- */\n\n.EnvVZyg7kQxIWGDtS98A {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 0.5rem 1rem;\n\n    color: var(--text-secondary-color);\n    font-size: 1.4rem;\n}\n\n.HzINripwD4_Ru77YgNID {\n    color: var(--field-info-color);\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/input/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,8BAA8B;IAC9B,mBAAmB;;IAEnB,oBAAoB;;IAEpB,kCAAkC;IAClC,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".fieldAutoHeight {\n    min-height: auto;\n}\n\n/* ----- */\n\n.maxLengthMessage {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 0.5rem 1rem;\n\n    color: var(--text-secondary-color);\n    font-size: 1.4rem;\n}\n\n.maxLengthMessageMax {\n    color: var(--field-info-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldAutoHeight": "EOBANK_LoT9XzPhA_Loa",
	"maxLengthMessage": "EnvVZyg7kQxIWGDtS98A",
	"maxLengthMessageMax": "HzINripwD4_Ru77YgNID"
};
export default ___CSS_LOADER_EXPORT___;
