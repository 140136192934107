// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uWmNev14lUn3MznShqbf {\n    padding: 1.5rem 0.8rem 1.5rem 2rem;\n\n    display: grid;\n    grid-template-columns: 5rem auto max-content;\n    grid-column-gap: 1rem;\n}\n\n.VyhUa9EnVf7uxi35Na4m {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.WUC30I4SADakSNYWLxXg {\n    width: 2.5rem;\n    height: 3rem;\n}\n\n._VN4abrfBD1SRBMliSQ_ {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n.oYrhQoXQh1gvEoFRUI4A {\n    color: var(--color-1);\n}\n\n.maSrZ3Th4Rt98OM4kX80 {\n    color: var(--color-5);\n    font-size: 1.3rem;\n}\n\n.B9gjLKJvL8xIkOmvfsT3 {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n@media only screen and (min-width: 601px) {\n    .maSrZ3Th4Rt98OM4kX80 {\n        font-size: 1.6rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/profile-reading-mode/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;;IAElC,aAAa;IACb,4CAA4C;IAC5C,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".readingMode {\n    padding: 1.5rem 0.8rem 1.5rem 2rem;\n\n    display: grid;\n    grid-template-columns: 5rem auto max-content;\n    grid-column-gap: 1rem;\n}\n\n.iconWrapper {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.icon {\n    width: 2.5rem;\n    height: 3rem;\n}\n\n.content {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n.header {\n    color: var(--color-1);\n}\n\n.description {\n    color: var(--color-5);\n    font-size: 1.3rem;\n}\n\n.switch {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n@media only screen and (min-width: 601px) {\n    .description {\n        font-size: 1.6rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readingMode": "uWmNev14lUn3MznShqbf",
	"iconWrapper": "VyhUa9EnVf7uxi35Na4m",
	"icon": "WUC30I4SADakSNYWLxXg",
	"content": "_VN4abrfBD1SRBMliSQ_",
	"header": "oYrhQoXQh1gvEoFRUI4A",
	"description": "maSrZ3Th4Rt98OM4kX80",
	"switch": "B9gjLKJvL8xIkOmvfsT3"
};
export default ___CSS_LOADER_EXPORT___;
