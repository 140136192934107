// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SytZx1JrzrqzWJAydq_o,\n._luBJBby7wz3oNy89yPX {\n    width: 4rem;\n    height: 4rem;\n\n    background-color: var(--avatar-bg-color);\n\n    border-radius: 50%;\n    border: var(--border-contrast);\n\n    justify-self: center;\n\n    overflow: hidden;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.SytZx1JrzrqzWJAydq_o {\n    display: grid;\n    justify-content: center;\n    justify-self: center;\n    align-items: center;\n\n    color: var(--avatar-color);\n    font-size: 1.5rem;\n    text-transform: uppercase;\n    text-decoration: none;\n\n    user-select: none;\n}\n\n.zpDbjSRRsshebbV9MddF {\n    cursor: default;\n}\n\n._luBJBby7wz3oNy89yPX > img {\n    width: 100%;\n    height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./base/components/avatar-icon/styles.module.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;;IAEZ,wCAAwC;;IAExC,kBAAkB;IAClB,8BAA8B;;IAE9B,oBAAoB;;IAEpB,gBAAgB;;IAEhB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;IACpB,mBAAmB;;IAEnB,0BAA0B;IAC1B,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;;IAErB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".avatar,\n.avatarImage {\n    width: 4rem;\n    height: 4rem;\n\n    background-color: var(--avatar-bg-color);\n\n    border-radius: 50%;\n    border: var(--border-contrast);\n\n    justify-self: center;\n\n    overflow: hidden;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.avatar {\n    display: grid;\n    justify-content: center;\n    justify-self: center;\n    align-items: center;\n\n    color: var(--avatar-color);\n    font-size: 1.5rem;\n    text-transform: uppercase;\n    text-decoration: none;\n\n    user-select: none;\n}\n\n.avatarDisabled {\n    cursor: default;\n}\n\n.avatarImage > img {\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "SytZx1JrzrqzWJAydq_o",
	"avatarImage": "_luBJBby7wz3oNy89yPX",
	"avatarDisabled": "zpDbjSRRsshebbV9MddF"
};
export default ___CSS_LOADER_EXPORT___;
