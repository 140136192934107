import React from "react";

import styles from "./styles.module.css";


const ButtonWithIcon = (props) => {
    return (
        <button
            type={props.type} // eslint-disable-line react/button-has-type
            className={styles.button}
            onClick={props.onClick}
            onKeyPress={props.onClick}
        >
            {props.icon}
            <div className={styles.content}>
                {props.children}
            </div>
        </button>
    );
};

ButtonWithIcon.defaultProps = {
    icon: null,
    iconName: "",
    type: "submit",
    children: null,
    onClick: () => { },
};

export default ButtonWithIcon;
