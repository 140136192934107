// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vVzZppvCHOyIxmZ7uUpL {\n    display: grid;\n    grid-auto-flow: row;\n    grid-auto-columns: 1fr;\n    grid-column-gap: 2rem;\n\n    align-items: start;\n}\n\n@media only screen and (min-width: 600px) {\n    .vVzZppvCHOyIxmZ7uUpL {\n        grid-auto-flow: column;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/form-row/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;;IAErB,kBAAkB;AACtB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".row {\n    display: grid;\n    grid-auto-flow: row;\n    grid-auto-columns: 1fr;\n    grid-column-gap: 2rem;\n\n    align-items: start;\n}\n\n@media only screen and (min-width: 600px) {\n    .row {\n        grid-auto-flow: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "vVzZppvCHOyIxmZ7uUpL"
};
export default ___CSS_LOADER_EXPORT___;
