// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dsfm7dwmcalYRZmhY6bN {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n/* --- */\n\n.pJK5MdjPaDfQg7UZIYzA {\n    height: fit-content;\n    margin: 0 auto;\n\n    display: grid;\n    grid-template-columns: auto 6rem 6rem;\n\n    overflow: hidden;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius-2);\n}\n\n.pJK5MdjPaDfQg7UZIYzA > div:not(:last-child) {\n    border-right: 0.1rem solid var(--border-default-color);\n}\n\n/* --- */\n\n.Xb9ibVXWerZFNVDrbVFh {\n    height: 100%;\n    padding: 0 2rem;\n\n    display: grid;\n    align-items: center;\n\n    color: var(--text-primary-color);\n    font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./base/components/rate-with-like/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA,QAAQ;;AAER;IACI,mBAAmB;IACnB,cAAc;;IAEd,aAAa;IACb,qCAAqC;;IAErC,gBAAgB;;IAEhB,gDAAgD;IAChD,6CAA6C;AACjD;;AAEA;IACI,sDAAsD;AAC1D;;AAEA,QAAQ;;AAER;IACI,YAAY;IACZ,eAAe;;IAEf,aAAa;IACb,mBAAmB;;IAEnB,gCAAgC;IAChC,gBAAgB;AACpB","sourcesContent":[".container {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n/* --- */\n\n.rate {\n    height: fit-content;\n    margin: 0 auto;\n\n    display: grid;\n    grid-template-columns: auto 6rem 6rem;\n\n    overflow: hidden;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius-2);\n}\n\n.rate > div:not(:last-child) {\n    border-right: 0.1rem solid var(--border-default-color);\n}\n\n/* --- */\n\n.title {\n    height: 100%;\n    padding: 0 2rem;\n\n    display: grid;\n    align-items: center;\n\n    color: var(--text-primary-color);\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Dsfm7dwmcalYRZmhY6bN",
	"rate": "pJK5MdjPaDfQg7UZIYzA",
	"title": "Xb9ibVXWerZFNVDrbVFh"
};
export default ___CSS_LOADER_EXPORT___;
