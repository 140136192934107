export const SET_TEACHER_STUDENTS_PROGRESS_LOADING_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_LOADING_BY_CLASS_ID";
export const SET_TEACHER_STUDENTS_PROGRESS_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_BY_CLASS_ID";

export const SET_TEACHER_STUDENTS_PROGRESS_ERROR_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_ERROR_BY_CLASS_ID";
export const SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID";
export const SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID_LOADING = "SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID_LOADING";
export const SET_TEACHER_STUDENTS_PROGRESS_QUIZ_TYPE_BY_CLASS_ID_LOADING = "SET_TEACHER_STUDENTS_PROGRESS_QUIZ_TYPE_BY_CLASS_ID_LOADING";
export const SET_TEACHER_STUDENTS_PROGRESS_QUIZ_TYPE_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_QUIZ_TYPE_BY_CLASS_ID";
export const SET_TEACHER_STUDENTS_PROGRESS_RENEWING_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_RENEWING_BY_CLASS_ID";
export const SET_TEACHER_STUDENTS_PROGRESS_AUTO_ADJUST_BY_CLASS_ID = "SET_TEACHER_STUDENTS_PROGRESS_AUTO_ADJUST_BY_CLASS_ID";
export const SET_TEACHER_STUDENTS_PROGRESS_STUDENT_IS_LOADING = "SET_TEACHER_STUDENTS_PROGRESS_STUDENT_IS_LOADING";
export const SET_TEACHER_STUDENTS_PROGRESS_STUDENT_RECOMMENDATION_ACCEPTED = "SET_TEACHER_STUDENTS_PROGRESS_STUDENT_RECOMMENDATION_ACCEPTED";
