import { useState } from "react";


const useAccordion = () => {
    const [expanded, setExpanded] = useState([]);

    const onSetExpanded = (id) => {
        setExpanded((prev) => {
            const isExpanded = prev.indexOf(id) !== -1;

            if (isExpanded) {
                return [...prev].filter((item) => item !== id);
            }

            return [...prev].concat(id);
        });
    };

    const isExpanded = (id) => {
        return expanded.indexOf(id) !== -1;
    };

    const expandAll = (ids) => {
        setExpanded([...ids]);
    };

    const closeAll = () => {
        setExpanded([]);
    };

    const toggleAll = (ids) => {
        if (expanded.length > 0) {
            closeAll();
            return;
        }

        expandAll(ids);
    };

    return {
        expanded,
        onSetExpanded,
        expandAll,
        closeAll,
        toggleAll,
        isExpanded,
    };
};

export default useAccordion;
