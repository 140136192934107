import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAchievementStoriesReadHighContrast = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="94"
                    height="94"
                    viewBox="0 0 94 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <g clipPath="url(#clip0_2474_12152)">
                        <rect width="94" height="94" rx="16" fill="#F5F3FF" />
                        <g filter="url(#filter0_dd_2474_12152)">
                            <path d="M69.0318 27.6615C66.3147 26.8047 63.3282 26.4375 60.4641 26.4375C55.6907 26.4375 50.5501 27.4167 47.0006 30.1094C43.4511 27.4167 38.3105 26.4375 33.537 26.4375C28.7636 26.4375 23.623 27.4167 20.0735 30.1094V65.9714C20.0735 66.5833 20.6855 67.1953 21.2974 67.1953C21.5422 67.1953 21.6646 67.0729 21.9094 67.0729C25.2141 65.4818 29.9876 64.3802 33.537 64.3802C38.3105 64.3802 43.4511 65.3594 47.0006 68.0521C50.3053 65.9714 56.3027 64.3802 60.4641 64.3802C64.5032 64.3802 68.6647 65.1146 72.0917 66.9505C72.3365 67.0729 72.4589 67.0729 72.7037 67.0729C73.3157 67.0729 73.9277 66.4609 73.9277 65.849V30.1094C72.4589 29.0078 70.8678 28.2734 69.0318 27.6615ZM24.9693 60.7083V32.5573C27.662 31.7005 30.5995 31.3333 33.537 31.3333C36.8172 31.3333 41.199 32.337 44.5527 33.7568V61.9078C41.199 60.488 36.8172 59.4844 33.537 59.4844C30.5995 59.4844 27.662 59.8516 24.9693 60.7083ZM69.0318 60.7083C66.3391 59.8516 63.4016 59.4844 60.4641 59.4844C57.1839 59.4844 52.8021 60.488 49.4485 61.9078V33.7568C52.8021 32.3125 57.1839 31.3333 60.4641 31.3333C63.4016 31.3333 66.3391 31.7005 69.0318 32.5573V60.7083Z" fill="#5B21B6" />
                        </g>
                        <path d="M44.5521 33.7078C41.1984 32.288 36.8167 31.2844 33.5365 31.2844C30.599 31.2844 27.6615 31.6516 24.9688 32.5083V60.6594C27.6615 59.8026 30.599 59.4354 33.5365 59.4354C36.8167 59.4354 41.1984 60.4391 44.5521 61.8588V33.7078Z" fill="white" />
                        <path d="M49.4479 33.7078C52.8016 32.288 57.1833 31.2844 60.4635 31.2844C63.401 31.2844 66.3385 31.6516 69.0312 32.5083V60.6594C66.3385 59.8026 63.401 59.4354 60.4635 59.4354C57.1833 59.4354 52.8016 60.4391 49.4479 61.8588V33.7078Z" fill="white" />
                    </g>
                    <rect x="2.5" y="2.5" width="89" height="89" rx="13.5" stroke="#5B21B6" strokeWidth="5" />
                    <defs>
                        <filter id="filter0_dd_2474_12152" x="-6.36402" y="7.71093" width="106.729" height="94.4896" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="7.71094" />
                            <feGaussianBlur stdDeviation="13.2188" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2474_12152" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2.20313" />
                            <feGaussianBlur stdDeviation="4.77344" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.039 0" />
                            <feBlend mode="normal" in2="effect1_dropShadow_2474_12152" result="effect2_dropShadow_2474_12152" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2474_12152" result="shape" />
                        </filter>
                        <clipPath id="clip0_2474_12152">
                            <rect width="94" height="94" rx="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievementStoriesReadHighContrast;
