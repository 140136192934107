// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes BZxnAhzHrCybE7m4GcAC {\n    100% {\n        opacity: 0;\n        transform: scale(2.5);\n    }\n}\n\n.ldTGiviF3gB4tqIVXrsc {\n    position: absolute;\n    display: block;\n    border-radius: 100%;\n    transform: scale(0);\n}\n\n._4Ugb9EPXlf890sbu0uQ {\n    animation: BZxnAhzHrCybE7m4GcAC 0.65s linear;\n}", "",{"version":3,"sources":["webpack://./base/hooks/use-button-animation/styles.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,qBAAqB;IACzB;AACJ;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,4CAA8B;AAClC","sourcesContent":["@keyframes ripple {\n    100% {\n        opacity: 0;\n        transform: scale(2.5);\n    }\n}\n\n.animation {\n    position: absolute;\n    display: block;\n    border-radius: 100%;\n    transform: scale(0);\n}\n\n.animate {\n    animation: ripple 0.65s linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animation": "ldTGiviF3gB4tqIVXrsc",
	"animate": "_4Ugb9EPXlf890sbu0uQ",
	"ripple": "BZxnAhzHrCybE7m4GcAC"
};
export default ___CSS_LOADER_EXPORT___;
