import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const Status = (props) => {
    const statusClassName = classNames({
        [styles.status]: true,
        [styles.statusGreen]: props.isGreen,
    });

    return (
        <div
            className={statusClassName}
        />
    );
};

Status.defaultProps = {
    isGreen: false,
};

export default Status;
