
export const getDimensions = () => {
    const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    return {
        width,
        height,
    };
};

export const convertRemToPixels = (rem) => {
    if (window.getComputedStyle) {
        const { fontSize } = window.getComputedStyle(window.document.documentElement);

        return rem * parseFloat(fontSize);
    }

    return rem * 10;
};

export default {
    getDimensions,
    convertRemToPixels,
};
