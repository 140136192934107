import React, { useState, useCallback, useEffect } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

import classNames from "@/base/lib/class-names.js";

import useDrag from "./use-drag.js";
import { LeftArrow, RightArrow } from "./arrows.js";

import ChipComponent from "./chip.js";

import "./styles.css";
import styles from "./styles.module.css";


const HorizontalScrollingMenu = (props) => {
    const [isGrabbing, setGrabbing] = useState(false);
    const [position, setPosition] = useState(0);

    const [isLastItemVisible, setIsLastItemVisible] = useState(true);
    const [isFirstItemVisible, setIsFirstItemVisible] = useState(true);

    useEffect(() => {
        props.onFirstLastItemsVisibleChange({
            isLastItemVisible,
            isFirstItemVisible,
        });
    }, [isLastItemVisible, isFirstItemVisible]);

    const {
        dragStart,
        dragStop,
        dragMove,
        dragging,
    } = useDrag();

    /* --- */

    const onMouseDown = () => {
        setGrabbing(true);
    };

    const onMouseLeave = () => {
        setGrabbing(false);
    };

    const onMouseUp = () => {
        setGrabbing(false);
    };

    /* --- */

    const getSelectedElementOffset = (rootElem) => {
        const elems = rootElem?.children || [];

        for (let i = 0; i < elems.length; i += 1) {
            if (elems[i].dataset.key === props.selected
                || elems[i].dataset.key === `${props.selected}`) {
                return elems[i].offsetLeft || 0;
            }
        }

        return 0;
    };

    const restorePosition = useCallback((ctx) => {
        if (ctx.scrollContainer.current) {
            const offset = getSelectedElementOffset(ctx.scrollContainer.current);
            ctx.scrollContainer.current.scrollLeft = offset || position;
        }
    }, [position]);

    const savePosition = useCallback((ctx) => {
        if (ctx.scrollContainer?.current) {
            setPosition(ctx.scrollContainer.current.scrollLeft);
            props.onPositionChange(ctx.scrollContainer.current.scrollLeft);
        }
    }, []);

    const handleDrag = (ctx) => {
        return (evt) => {
            dragMove(evt, (newPos) => {
                if (ctx.scrollContainer.current) {
                    const currentScroll = ctx.scrollContainer.current.scrollLeft;
                    ctx.scrollContainer.current.scrollLeft = currentScroll + newPos;
                }
            });
        };
    };

    const handleItemClick = (params) => {
        return () => {
            if (dragging) {
                return;
            }

            // NOTE: it is scroll on click functionality
            // const item = ctx.getItemElementById(params.itemId);
            // if (item) {
            //     ctx.scrollToItem(item, "smooth", "center", "nearest");
            // }

            props.onSelect(params.value);
        };
    };

    /* --- */

    const renderChildren = () => {
        const items = props.children({
            dragging,
            handleItemClick,
        });

        return items;
    };

    /* --- */

    const menuClassName = classNames({
        [styles.menuGrabbing]: isGrabbing,
    });

    const rightArrow = (
        <RightArrow
            isHidden={props.hideArrows}
            autoHide={props.autoHideArrows}
            isButtonInCircle={props.isButtonInCircle}
            onLastItemVisibleChange={setIsLastItemVisible}
        />
    );

    const leftArrow = (
        <LeftArrow
            isHidden={props.hideArrows}
            autoHide={props.autoHideArrows}
            isButtonInCircle={props.isButtonInCircle}
            onFirstItemVisibleChange={setIsFirstItemVisible}
        />
    );

    return (
        <div
            className={menuClassName}
            data-comment={props.dataComment}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            role="button"
            tabIndex="-1"
        >
            <ScrollMenu
                RightArrow={rightArrow}
                LeftArrow={leftArrow}
                onInit={restorePosition}
                onScroll={savePosition}
                onWheel={() => { }}
                onMouseDown={() => (evt) => dragStart(evt)}
                onMouseUp={() => dragStop}
                onMouseMove={handleDrag}
            >
                {renderChildren()}
            </ScrollMenu>
        </div>
    );
};

HorizontalScrollingMenu.defaultProps = {
    dataComment: "",
    items: [],
    selected: 0,
    hideArrows: false,
    autoHideArrows: true,
    isButtonInCircle: false,
    onFirstLastItemsVisibleChange: () => { },
    onPositionChange: () => { },
};

export const Chip = ChipComponent;
export default HorizontalScrollingMenu;
