import React, { useMemo } from "react";

import classNames from "@/base/lib/class-names.js";

import Wrapper from "./wrapper.js";
import styles from "./styles.module.css";


const TooltipSmall = (props) => {
    const position = useMemo(() => ({
        isCentered: props.position === "center",
        isLeft: props.position === "left",
        isRight: props.position === "right",
    }), [props.position]);

    const tooltipClassName = classNames({
        [styles.tooltip]: true,
        [styles.tooltipDown]: !props.isArrowUp,
        [styles.tooltipCentered]: position.isCentered,
        [styles.tooltipLeft]: position.isLeft,
        [styles.tooltipRight]: position.isRight,
        [props.className]: props.className,
    });

    const arrowClassName = classNames({
        [styles.arrow]: true,
        [styles.arrowDown]: !props.isArrowUp,
        [styles.arrowUpLeft]: position.isLeft,
        [styles.arrowUpRight]: position.isRight,
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [props.contentClassName]: props.contentClassName,
    });

    return (
        <div className={tooltipClassName}>
            <div className={arrowClassName} />

            <div className={contentClassName}>
                {props.children}
            </div>
        </div>
    );
};

TooltipSmall.defaultProps = {
    className: "",
    contentClassName: "",
    children: null,
    position: "center",
    isArrowUp: true,
};

export const TooltipSmallWrapper = Wrapper;
export default TooltipSmall;
