import * as types from "./types.js";

export const setJuicesVideos = ({ videos, page, hasMorePages }) => ({
    type: types.SET_JUICES_VIDEOS,
    payload: {
        videos,
        page,
        hasMorePages,
    },
});

export const setJuicesVideosLoading = () => ({
    type: types.SET_JUICES_VIDEOS_LOADING,
    payload: {},
});

export const setJuiceVideoByIdLoading = (id) => ({
    type: types.SET_JUICE_VIDEO_BY_ID_LOADING,
    payload: { id },
});

export const setJuiceVideoById = (payload) => ({
    type: types.SET_JUICE_VIDEO_BY_ID,
    payload,
});
