// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CHiw9UEqJ7d2VRuVGAbh {\n    display: grid;\n    grid-template-columns: max-content auto max-content;\n    grid-gap: 1.6rem;\n\n    align-items: center;\n\n    padding: 0.8rem 1.6rem;\n}\n\n.CHiw9UEqJ7d2VRuVGAbh img {\n    width: 3.2rem;\n    height: 3.2rem;\n}", "",{"version":3,"sources":["webpack://./base/components/google-classroom-share-button-with-label/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mDAAmD;IACnD,gBAAgB;;IAEhB,mBAAmB;;IAEnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".container {\n    display: grid;\n    grid-template-columns: max-content auto max-content;\n    grid-gap: 1.6rem;\n\n    align-items: center;\n\n    padding: 0.8rem 1.6rem;\n}\n\n.container img {\n    width: 3.2rem;\n    height: 3.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CHiw9UEqJ7d2VRuVGAbh"
};
export default ___CSS_LOADER_EXPORT___;
