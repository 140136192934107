import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const NotificationBadge = (props) => {
    const containerClassName = classNames({
        [styles.container]: true,
        [props.className]: !!props.className,
    });

    const badgeClassName = classNames({
        [styles.badge]: true,
        [styles.badgeHidden]: !props.isVisible,
    });

    return (
        <div className={containerClassName}>
            <div className={badgeClassName} />
        </div>
    );
};

NotificationBadge.defaultProps = {
    className: "",
    isVisible: true,
};

export default NotificationBadge;
