import React from "react";

import ButtonFlat from "@/base/components/button-flat/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";

import styles from "./styles.module.css";


const SectionLoadMore = (props) => {
    if (props.isLoading) {
        return (
            <RequestLoader />
        );
    }

    if (!props.hasMore) {
        return null;
    }

    return (
        <div className={styles.loadMoreButton}>
            <ButtonFlat
                onClick={props.onClick}
                hugePadding
            >
                Load More
            </ButtonFlat>
        </div>
    );
};

SectionLoadMore.defaultProps = {
    onClick: () => { },
    hasMore: false,
    isLoading: false,
};

export default SectionLoadMore;
