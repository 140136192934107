// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t2u_r_gncieHxA3C7b_j {}\n\n.rMIT1PJJgch_Flnu_3cD {}\n", "",{"version":3,"sources":["webpack://./base/business/popup-new-password/styles.module.css"],"names":[],"mappings":"AAAA,uBAAU;;AAEV,uBAAU","sourcesContent":[".content {}\n\n.message {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "t2u_r_gncieHxA3C7b_j",
	"message": "rMIT1PJJgch_Flnu_3cD"
};
export default ___CSS_LOADER_EXPORT___;
