import array from "@/base/lib/array.js";


const isPopupForTeacher = (popup) => {
    return popup?.userRole === "teacher";
};

const isPopupForAllRoles = (popup) => {
    return popup?.userRole === "all";
};

const isPopupForAllTypes = (popup) => {
    return popup?.userType === "All";
};

const isAccessiblePopup = (popup, user) => {
    if (!popup) {
        return false;
    }

    const isAllRoles = isPopupForAllRoles(popup);
    const isAllTypes = isPopupForAllTypes(popup);

    if (isAllRoles && isAllTypes) {
        return true;
    }

    // NOTE: if user is not loaded yet
    if (!popup.userRole || !user.userType) {
        return false;
    }

    const userRoles = user.roles || [];
    const isSameRole = userRoles.indexOf(popup.userRole) !== -1;
    const isSameType = popup.userType === user.userType || isAllTypes;

    return isSameRole && isSameType;
};

const getPopupById = (popups, popupId) => {
    const popup = array.findOneById(popups, popupId);
    return popup || null;
};

export default {
    isPopupForTeacher,
    isAccessiblePopup,
    getPopupById,
};
