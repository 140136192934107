import React from "react";

import classNames from "@/base/lib/class-names.js";

import Position from "./position.js";
import styles from "./styles.module.css";


// NOTE: FAB - floating action button
const ButtonFAB = (props) => {
    if (!props.icon && !props.text) {
        return null;
    }

    const renderIcon = () => {
        return (
            <div className={styles.fabIconImg}>
                {props.icon}
            </div>
        );
    };

    const renderContent = () => {
        if (props.icon && props.text) {
            return (
                <div className={styles.fabIconWithText}>
                    {renderIcon()}
                    {props.text}
                </div>
            );
        }

        if (props.icon) {
            return renderIcon();
        }

        if (props.text) {
            return props.text;
        }

        return null;
    };

    const buttonClassName = classNames({
        [styles.fab]: true,
        [styles.fabIcon]: props.icon && !props.text,
        [styles.fabWhite]: props.whiteTheme,
    });

    return (
        <div
            className={buttonClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        >
            {renderContent()}
        </div>
    );
};

ButtonFAB.defaultProps = {
    icon: null,
    text: "",

    onClick: () => { },

    whiteTheme: false,
};

export const ButtonFabPosition = Position;
export default ButtonFAB;
