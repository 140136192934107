import * as types from "./types.js";


const initialState = {
    pagesBySlug: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_PAGE_LOADING: {
            const { slug } = action.payload;

            const page = state.pagesBySlug[slug] || {
                isLoading: false,
                page: {},
                error: "",
            };

            return {
                ...state,
                pagesBySlug: {
                    ...state.pagesBySlug,
                    [slug]: {
                        ...page,
                        isLoading: true,
                        error: "",
                    },
                },
            };
        }

        case types.SET_PAGE: {
            const { slug, page, error } = action.payload;

            const statePage = state.pagesBySlug[slug] || {
                isLoading: false,
                page: {},
            };

            return {
                ...state,
                pagesBySlug: {
                    ...state.pagesBySlug,
                    [slug]: {
                        ...statePage,
                        isLoading: false,
                        page: page || {},
                        error: error || "",
                    },
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
