import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const TextWithIcon = (props) => {
    if (!props.text) {
        return null;
    }

    const renderIcon = () => {
        if (!props.iconComponent) {
            return null;
        }

        return (
            <props.iconComponent
                isWhite={props.isWhite}
                isBlack={props.isBlack}
                isRed={props.isRed}
                isOrange={props.isOrange}
                isSky={props.isSky}
                isGreen={props.isGreen}
            />
        );
    };

    const className = classNames({
        [styles.textWithIcon]: true,
        [styles.textWithIconWithBorder]: props.withBorder,

        [styles.textWithIconWhite]: props.isWhite,
        [styles.textWithIconBlack]: props.isBlack,
        [styles.textWithIconRed]: props.isRed,
        [styles.textWithIconOrange]: props.isOrange,
        [styles.textWithIconSky]: props.isSky,
        [styles.textWithIconGreen]: props.isGreen,
    });

    return (
        <div className={className}>
            {renderIcon()}
            {props.text}
        </div>
    );
};

TextWithIcon.defaultProps = {
    iconComponent: null,
    text: "",

    withBorder: false,

    isWhite: false,
    isBlack: false,
    isRed: false,
    isOrange: false,
    isSky: false,
    isGreen: false,
};

export default TextWithIcon;
