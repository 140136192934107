export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_SESSION = "SET_USER_SESSION";
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";

export const SET_USER_EMAIL_NOTIFICATION = "SET_USER_EMAIL_NOTIFICATION";
export const SET_USER_AUTO_RENEW_SUBSCRIPTION = "SET_USER_AUTO_RENEW_SUBSCRIPTION";
export const SET_USER_HAS_ACTIVE_CANCEL_REQUEST = "SET_USER_HAS_ACTIVE_CANCEL_REQUEST";

export const SET_USER_FULL_NAME = "SET_USER_FULL_NAME";
export const SET_USER_AVATAR = "SET_USER_AVATAR";

export const SET_USER_REMEMBER_ME = "SET_USER_REMEMBER_ME";

export const SET_USER_GRADES_LOADING = "SET_USER_GRADES_LOADING";
export const SET_USER_GRADES = "SET_USER_GRADES";

export const SET_USER_TRANSLATION_LANGUAGE = "SET_USER_TRANSLATION_LANGUAGE";

export const SET_USER_IS_BAD_EMAIL = "SET_USER_IS_BAD_EMAIL";
export const SET_USER_EMAIL = "SET_USER_EMAIL";

export const SET_USER_READING_MODE = "SET_USER_READING_MODE";

export const SET_USER_OPTIONS = "SET_USER_OPTIONS";
export const SET_USER_OPTION = "SET_USER_OPTION";

export const SET_USER_PAYMENT_DETAILS = "SET_USER_PAYMENT_DETAILS";
export const SET_USER_PAYMENT_DETAILS_LOADED = "SET_USER_PAYMENT_DETAILS_LOADED";

export const SET_USER_CLASSES_LIMIT_INFO_LOADING = "SET_USER_CLASSES_LIMIT_INFO_LOADING";
export const SET_USER_CLASSES_LIMIT_INFO = "SET_USER_CLASSES_LIMIT_INFO";

export const SET_TEACHER_WELCOME_POPUP_OPENED = "SET_TEACHER_WELCOME_POPUP_OPENED";
export const SET_TEACHER_WELCOME_POPUP_CLOSED = "SET_TEACHER_WELCOME_POPUP_CLOSED";
