import React, { useRef } from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupCommonV2 = (props) => {
    const layoutRef = useRef(null);
    const contentRef = useRef(null);

    const onClose = (evt) => {
        const isClickOnLayout = layoutRef?.current === evt.target
            || contentRef?.current === evt.target;

        if (!props.disabledClose && isClickOnLayout) {
            props.onClose(evt);
        }
    };

    const contentClassName = classNames({
        [styles.popupContent]: true,
        [styles.popupContentCentered]: true,
    });

    return (
        <div
            ref={layoutRef}
            className={styles.popup}
            onClick={onClose}
            onKeyPress={onClose}
            role="button"
            tabIndex={props.disabledTabIndex ? "-1" : ""}
            data-comment="popup-common"
        >
            <div
                ref={contentRef}
                className={contentClassName}
            >
                {props.children}
            </div>
        </div>
    );
};

PopupCommonV2.defaultProps = {
    children: null,
    disabledClose: false,
    disabledTabIndex: true,
    onClose: () => { },
};

export default PopupCommonV2;
