// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GbjvDg8d6icANrIdaYXF {\n    display: grid;\n    grid-template-columns: 3rem auto;\n    grid-gap: 1rem;\n\n    align-items: center;\n}\n\n.hUoDpUHIkJMD3fq_aVij {\n    font-weight: 600;\n}\n\n.PZRGWAhLhvUbQUmHAsAQ {\n    display: grid;\n}\n\n.KF39ItBe0Sh6wFdzJlVI {\n    border-bottom: 0.1rem solid var(--border-default-color);\n\n    padding: 2rem;\n}\n\n._52SwM7I8B_b77l8bDHm {\n    margin: 2rem 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-theme-settings/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gCAAgC;IAChC,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uDAAuD;;IAEvD,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".header {\n    display: grid;\n    grid-template-columns: 3rem auto;\n    grid-gap: 1rem;\n\n    align-items: center;\n}\n\n.headerTitle {\n    font-weight: 600;\n}\n\n.theme {\n    display: grid;\n}\n\n.themeAutoSwitcher {\n    border-bottom: 0.1rem solid var(--border-default-color);\n\n    padding: 2rem;\n}\n\n.themeSelectors {\n    margin: 2rem 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "GbjvDg8d6icANrIdaYXF",
	"headerTitle": "hUoDpUHIkJMD3fq_aVij",
	"theme": "PZRGWAhLhvUbQUmHAsAQ",
	"themeAutoSwitcher": "KF39ItBe0Sh6wFdzJlVI",
	"themeSelectors": "_52SwM7I8B_b77l8bDHm"
};
export default ___CSS_LOADER_EXPORT___;
