import text from "@/base/text/index.js";


const loadClassProgressReport = (services, params = {}) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        const state = getState();
        const { session } = state.user;

        dispatch(actions.teacherStudentsProgress.setProgressLoadingByClassId({
            classId,
        }));

        const res = await api.teacherClass.getProgressReport({
            session,
            classId,
        });

        if (!res.ok) {
            dispatch(actions.teacherStudentsProgress.setStudentProgressErrorByClassId({
                classId,
                error: res.error || text.error,
            }));
            return;
        }

        const students = res?.students || [];
        const dates = res?.dates || [];
        const reportId = res?.reportInfo?.reportId || "";
        const autoAdjust = res?.reportInfo?.autoAdjust || false;

        events.progressReport.open({
            session,
            reportId,
        });

        dispatch(actions.teacherStudentsProgress.setProgressByClassId({
            classId,
            reportId,
            students,
            dates,
            autoAdjust,
        }));
    };
};

const reloadClassProgressReport = (services, params = {}) => {
    const { api, actions, events } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        if (!classId || classId === -1) {
            return;
        }

        const state = getState();
        const { session } = state.user;

        const res = await api.teacherClass.getProgressReport({
            session,
            classId,
        });

        const students = res?.students || [];
        const dates = res?.dates || [];
        const reportId = res?.reportInfo?.reportId || "";
        const autoAdjust = res?.reportInfo?.autoAdjust || false;

        events.progressReport.open({
            session,
            reportId,
        });

        dispatch(actions.teacherStudentsProgress.setProgressByClassId({
            classId,
            reportId,
            students,
            dates,
            autoAdjust,
        }));
    };
};

const loadOrReloadClassProgressReport = (services) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { classId } = state.navigation.params;

        if (!classId) {
            return;
        }

        const isLoaded = !!state?.teacherStudentsProgress?.progressByClassId?.[classId]?.students;

        const params = {
            classId,
        };

        if (isLoaded) {
            dispatch(reloadClassProgressReport(services, params));
            return;
        }

        dispatch(loadClassProgressReport(services, params));
    };
};

/* --- */

const generateClassProgressReport = (services, params = {}) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        dispatch(actions.teacherStudentsProgress.setProgressRenewingByClassId({
            classId,
            isRenewing: true,
        }));

        const res = await api.teacherClass.generateProgressReport({
            session,
            classId,
        });

        dispatch(actions.teacherStudentsProgress.setProgressRenewingByClassId({
            classId,
            isRenewing: false,
        }));

        if (!res.ok) {
            return res;
        }

        // TODO: smart reloading

        const resProgress = await api.teacherClass.getProgressReport({
            session,
            classId,
        });

        const students = resProgress?.students || [];
        const dates = resProgress?.dates || [];
        const reportId = resProgress?.reportInfo?.reportId || "";
        const autoAdjust = resProgress?.reportInfo.autoAdjust || false;

        events.progressReport.runNow({
            session,
            reportId,
        });

        dispatch(actions.teacherStudentsProgress.setProgressByClassId({
            classId,
            reportId,
            students,
            dates,
            autoAdjust,
        }));

        return res;
    };
};

const setClassReportFrequency = (services, params = {}) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        events.progressReport.updateFrequencySetting({
            session,
            frequency: params.frequency,
        });

        const res = await api.teacherClass.setProgressReportFrequency({
            session,
            classId,
            frequency: params.frequency,
        });

        if (res.ok) {
            dispatch(actions.teacherStudentsProgress.setStudentProgressFrequency({
                classId,
                frequency: params.frequency,
            }));
        }

        return res;
    };
};

const setClassReportAutoAdjust = (services, params = {}) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        dispatch(actions.teacherStudentsProgress.setProgressLoadingByClassId({
            classId,
        }));

        const res = await api.teacherClass.setProgressReportAutoAdjust({
            session,
            classId,
            autoAdjust: params.autoAdjust,
        });

        if (!res.ok) {
            return;
        }

        const resReport = await api.teacherClass.getProgressReport({
            session,
            classId,
        });

        const students = resReport?.students || [];
        const dates = resReport?.dates || [];
        const reportId = resReport?.reportInfo?.reportId || "";
        const autoAdjust = resReport?.reportInfo?.autoAdjust || false;

        events.progressReport.changeAutoAdjust({
            session,
            reportId,
            isEnabled: params.autoAdjust,
        });

        dispatch(actions.teacherStudentsProgress.setProgressByClassId({
            classId,
            reportId,
            students,
            dates,
            autoAdjust,
        }));
    };
};

const updateStudentGrade = (services, params = {}) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        dispatch(actions.teacherStudentsProgress.setStudentProgressLoading({
            classId,
            studentId: params.studentId,
            isLoading: true,
        }));

        const reportId = state.teacherStudentsProgress.progressByClassId?.[classId]?.reportId
            || "";

        events.progressReport.updateStudentGrade({
            session,
            reportId,
            studentId: params.studentId,
            grade: params.grade,
        });

        const res = await api.students.updateStudentGrade({
            session,
            grade: params.grade,
            studentId: params.studentId,
            classId,
        });

        dispatch(actions.teacherStudentsProgress.setStudentProgressLoading({
            classId,
            studentId: params.studentId,
            isLoading: false,
        }));

        if (res.ok) {
            dispatch(actions.teacherStudentsProgress.setStudentProgressRecommendationAccepted({
                classId,
                studentId: params.studentId,
                isRecommendationAccepted: true,
                summary: res?.data?.summary || "",
            }));
        }
    };
};

const updateStudentsGrade = (services, params = {}) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { session } = state.user;

        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        const students = params.students || [];

        if (students.length < 1) {
            return;
        }

        const reportId = state.teacherStudentsProgress.progressByClassId?.[classId]?.reportId
            || "";

        events.progressReport.bulkUpdateStudentsGrade({
            session,
            reportId,
        });

        for (let i = 0; i < students.length; i += 1) {
            const student = students[i];

            dispatch(actions.teacherStudentsProgress.setStudentProgressLoading({
                classId,
                studentId: student.id,
                isLoading: true,
            }));

            // eslint-disable-next-line no-await-in-loop
            const res = await api.students.updateStudentGrade({
                session,
                grade: student.grade,
                studentId: student.id,
                classId,
            });

            dispatch(actions.teacherStudentsProgress.setStudentProgressLoading({
                classId,
                studentId: student.id,
                isLoading: false,
            }));

            if (res.ok) {
                dispatch(actions.teacherStudentsProgress.setStudentProgressRecommendationAccepted({
                    classId,
                    studentId: student.id,
                    isRecommendationAccepted: true,
                    summary: res?.data?.summary || "",
                }));
            }
        }
    };
};

const undoStudentGrade = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const classId = params.classId || -1;

        if (!classId || classId === -1) {
            return;
        }

        dispatch(actions.teacherStudentsProgress.setStudentProgressLoading({
            classId,
            studentId: params.studentId,
            isLoading: true,
        }));

        const res = await api.students.undoGradeChange({
            session: state.user.session,
            studentId: params.studentId,
        });

        dispatch(actions.teacherStudentsProgress.setStudentProgressLoading({
            classId,
            studentId: params.studentId,
            isLoading: false,
        }));

        if (res.ok) {
            dispatch(actions.teacherStudentsProgress.setStudentProgressRecommendationAccepted({
                classId,
                studentId: params.studentId,
                isRecommendationAccepted: false,
                summary: res?.data?.summary || "",
            }));
        }
    };
};

export default {
    loadClassProgressReport,
    reloadClassProgressReport,
    loadOrReloadClassProgressReport,
    generateClassProgressReport,
    setClassReportFrequency,
    setClassReportAutoAdjust,
    updateStudentGrade,
    updateStudentsGrade,
    undoStudentGrade,
};
