import React, { useEffect, useState } from "react";

import Video from "@/base/components/video/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";


const PopupTourVideo = (props) => {
    const [isLoading, setLoading] = useState(true);

    /* ---- */

    useEffect(() => {
        setLoading(true);

        window.setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [props.video]);

    /* ---- */

    const renderTitle = () => {
        if (props.isMobile) {
            return "Mobile Video Tour";
        }

        return "Desktop Video Tour";
    };

    const renderVideo = () => {
        if (isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <Video
                videoId={props?.video?.id || null}
                src={props?.video?.url || null}
                captionSrc={props.videoCaptionSrc}
                type={props?.video?.mimeType || "video/mp4"}
                poster={props?.video?.poster?.url || null}
                onPlayStart={props.onPlayStart}
                onPlayEnd={props.onPlayEnd}
                onPause={props.onPause}
                isDefault={props.isDefaultVideo}
            />
        );
    };

    return (
        <PopupWindow
            title={renderTitle()}
            onClose={props.onClose}
        >
            {renderVideo()}
        </PopupWindow>
    );
};

PopupTourVideo.defaultProps = {
    video: {},
    videoCaptionSrc: null,
    onPlayStart: () => { },
    onPause: () => { },
    onPlayEnd: () => { },
    onClose: () => { },
    isDefaultVideo: true,
    isMobile: false,
};

export default PopupTourVideo;
