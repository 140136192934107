import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconUserAdd = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M12 4.00098C12 1.79098 10.21 0.000976562 8 0.000976562C5.79 0.000976562 4 1.79098 4 4.00098C4 6.21098 5.79 8.00098 8 8.00098C10.21 8.00098 12 6.21098 12 4.00098ZM14 6.00098V8.00098H17V11.001H19V8.00098H22V6.00098H19V3.00098H17V6.00098H14ZM0 14.001V16.001H16V14.001C16 11.341 10.67 10.001 8 10.001C5.33 10.001 0 11.341 0 14.001Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconUserAdd;
