import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const Wrapper = (props) => {
    const wrapperClassName = classNames({
        [styles.wrapper]: true,
        [props.className]: props.className,
    });

    return (
        <div className={wrapperClassName}>
            {props.children}
        </div>
    );
};

Wrapper.defaultProps = {
    className: "",
    children: null,
};

export default Wrapper;
