import React from "react";

import { Formik } from "formik";

import v from "@/base/lib/form-validators.js";

import Input from "@/base/components/forms/input/index.js";
import { PopupConfirmButtons } from "@/base/components/popup-confirm/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";
import AlertBox from "@/base/components/alert-box/index.js";

import styles from "./styles.module.css";


const BadEmailForm = (props) => {
    const validateForm = (values) => {
        const valueError = v.validate(values.email, [
            v.email("Please enter a valid email"),
        ]);

        const errors = {};

        if (valueError) {
            errors.email = valueError;
        }

        return errors;
    };

    const renderContent = (formProps, emailError) => {
        const {
            values,
            handleChange,
            handleBlur,
        } = formProps;

        return (
            <Input
                name="email"
                value={values.email}
                label="Email"
                error={emailError}
                onChange={handleChange}
                onBlur={handleBlur}
                isDisabled={props.isLoading}
                isRounded
            />
        );
    };

    const renderControls = (formProps, emailError) => {
        const { values } = formProps;

        return (
            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={props.onBack}
                    disabled={props.isLoading}
                >
                    Back
                </ButtonFlat>
                <ButtonFlat
                    type="submit"
                    disabled={!values.email || props.isLoading || emailError}
                >
                    {props.isLoading ? "Saving..." : "Save"}
                </ButtonFlat>
            </PopupConfirmButtons>
        );
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <AlertBox theme="danger">
                {props.error}
            </AlertBox>
        );
    };

    const renderForm = (formProps) => {
        const emailError = v.getError(formProps, "email");

        return (
            <form
                className={styles.form}
                onSubmit={formProps.handleSubmit}
            >
                {renderContent(formProps, emailError)}
                {renderError()}
                {renderControls(formProps, emailError)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSave}
        >
            {renderForm}
        </Formik>
    );
};

BadEmailForm.defaultProps = {
    initialValues: { },
    error: "",
    onSave: () => { },
    onBack: () => { },
    isLoading: false,
};

export default BadEmailForm;
