import * as types from "@/base/store-common/types.js";

import * as app from "@/base/store-common/app/actions.js";
import * as device from "@/base/store-common/device/actions.js";
import * as monitors from "@/base/store-common/monitors/actions.js";
import * as pages from "@/base/store-common/pages/actions.js";
import * as vocabulary from "@/base/store-common/vocabulary/actions.js";
import * as t2s from "@/base/store-common/text2speech/actions.js";
import * as translation from "@/base/store-common/translation/actions.js";
import * as player from "@/base/store-common/player/actions.js";
import * as search from "@/base/store-common/search/actions.js";

import * as navigation from "./navigation/actions.js";

import * as info from "./info/actions.js";
import * as user from "./user/actions.js";
import * as lms from "./lms/actions.js";

import * as notifications from "./notifications/actions.js";
import * as tutorials from "./tutorials/actions.js";
import * as templates from "./templates/actions.js";
import * as popups from "./popups/actions.js";

import * as juices from "./juices/actions.js";
import * as juiceStories from "./juice-stories/actions.js";
import * as juicesVideos from "./juices-videos/actions.js";
import * as extraJuices from "./extra-juices/actions.js";
import * as polls from "./polls/actions.js";

import * as teacher from "./teacher/actions.js";
import * as teacherStudents from "./teacher-students/actions.js";
import * as teacherStudentsAchievements from "./teacher-students-achievements/actions.js";
import * as teacherStudentsProgress from "./teacher-students-progress/actions.js";
import * as teacherStudentsScoreReport from "./teacher-students-score-report/actions.js";
import * as teacherStudentsAssignments from "./teacher-students-assignments/actions.js";

import * as guardian from "./guardian/actions.js";
import * as student from "./student/actions.js";
import * as studentTodo from "./student-todo/actions.js";
import * as studentAssignments from "./student-assignments/actions.js";
import * as studentAchievements from "./student-achievements/actions.js";
import * as studentClasses from "./student-classes/actions.js";
import * as studentDailyJuices from "./student-daily-juices/actions.js";

import * as districtAdmin from "./district-admin/actions.js";
import * as districtAdminPopups from "./district-admin-popups/actions.js";

import * as uiAdminPopups from "./ui-admin-popups/actions.js";
import * as admin from "./admin/actions.js";

import * as standards from "./standards/actions.js";
import * as assignments from "./assignments/actions.js";
import * as fivesy from "./fivesy/actions.js";
import * as geo from "./geo/actions.js";
import * as subscription from "./subscription/actions.js";

import * as uiPopups from "./ui-popups/actions.js";
import uiStoriesRating from "./ui-stories-rating/actions.js";


const clearAfterSignOut = () => ({
    type: types.CLEAR_AFTER_SIGNOUT,
});

export default {
    clearAfterSignOut,

    app,
    device,
    monitors,
    navigation,

    info,
    user,
    lms,

    t2s,
    translation,
    player,

    notifications,
    tutorials,
    templates,
    popups,
    pages,
    vocabulary,

    juices,
    juiceStories,
    juicesVideos,
    extraJuices,
    polls,

    teacher,
    teacherStudents,
    teacherStudentsAchievements,
    teacherStudentsProgress,
    teacherStudentsScoreReport,
    teacherStudentsAssignments,

    guardian,
    student,
    studentTodo,
    studentAssignments,
    studentAchievements,
    studentClasses,
    studentDailyJuices,

    districtAdmin,
    districtAdminPopups,

    uiAdminPopups,
    admin,

    search,

    standards,
    assignments,
    fivesy,
    geo,
    subscription,

    uiPopups,
    uiStoriesRating,
};
