import React from "react";

import { PopupConfirmTitle, PopupConfirmContent } from "@/base/components/popup-confirm/index.js";
import FormBadEmail from "@/base/forms/bad-email/index.js";


const EmailChange = (props) => {
    return (
        <>
            <PopupConfirmTitle>
                Please enter new email address
            </PopupConfirmTitle>
            <PopupConfirmContent>
                <FormBadEmail
                    initialValues={{
                        email: props.email,
                    }}
                    error={props.error}
                    onBack={props.onBack}
                    onSave={props.onSave}
                    isLoading={props.isLoading}
                />
            </PopupConfirmContent>
        </>
    );
};

EmailChange.defaultProps = {
    email: "",
    error: "",
    onSave: () => { },
    onBack: () => { },
    isLoading: false,
};

export default EmailChange;
