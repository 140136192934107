export const SET_TEACHER_STUDENT_BY_ID = "SET_TEACHER_STUDENT_BY_ID";

export const SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_BY_ID = "SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_BY_ID";
export const SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_LOADING_BY_ID = "SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_LOADING_BY_ID";
export const CLEAR_TEACHER_STUDENT_QUIZ_PERFORMANCES = "CLEAR_TEACHER_STUDENT_QUIZ_PERFORMANCES";

export const SET_TEACHER_STUDENT_ASSIGNMENTS_LOADING_BY_ID = "SET_TEACHER_STUDENT_ASSIGNMENTS_LOADING_BY_ID";
export const SET_TEACHER_STUDENT_ASSIGNMENTS_BY_ID = "SET_TEACHER_STUDENT_ASSIGNMENTS_BY_ID";
export const CLEAR_TEACHER_STUDENT_ASSIGNMENTS = "CLEAR_TEACHER_STUDENT_ASSIGNMENTS";

export const SET_TEACHER_STUDENT_DAILY_JUICES_LOADING_BY_ID = "SET_TEACHER_STUDENT_DAILY_JUICES_LOADING_BY_ID";
export const SET_TEACHER_STUDENT_DAILY_JUICES_BY_ID = "SET_TEACHER_STUDENT_DAILY_JUICES_BY_ID";
export const CLEAR_TEACHER_STUDENT_DAILY_JUICES = "CLEAR_TEACHER_STUDENT_DAILY_JUICES";
