import React, { useRef, useState } from "react";

import classNames from "@/base/lib/class-names.js";
import array from "@/base/lib/array.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";

import IconCaret from "@/base/icons/caret/index.js";

import LoaderSmall from "@/base/components/loader-small/index.js";
import DivButton from "@/base/components/div-button/index.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";

import Option from "./option.js";


const SelectCustomSmall = (props) => {
    const containerRef = useRef(null);

    const [isOptionsVisible, setOptionsVisible] = useState(false);

    /* --- */

    const toggleOptions = () => {
        if (props.isLoading) {
            return;
        }

        setOptionsVisible((prev) => !prev);
    };

    /* --- */

    useGlobalClose((evt) => {
        if (containerRef.current?.contains?.(evt.target)) {
            return;
        }

        setOptionsVisible(false);
    }, []);

    /* --- */

    const renderOptionLabels = () => {
        if (!props.optionsLabel) {
            return null;
        }

        let label2 = null;

        if (props.optionsLabel2) {
            label2 = (
                <div className={styles.optionsLabel2}>
                    {props.optionsLabel2}
                </div>
            );
        }

        return (
            <div className={styles.optionsLabels}>
                <div className={styles.optionsLabel}>
                    {props.optionsLabel}
                </div>
                {label2}
            </div>
        );
    };

    const renderOptions = () => {
        if (!isOptionsVisible) {
            return null;
        }

        const options = props.options.map((opt) => {
            const { value, label, label2 } = opt;

            return (
                <Option
                    key={`opt-${value}`}
                    label={label}
                    label2={label2}
                    onSelect={() => {
                        props.onSelect(value);
                        setOptionsVisible(false);
                    }}
                    isSelected={value === props.selected}
                    isSky={props.isSky}
                    isClickable
                />
            );
        });

        if (!options.length) {
            options.push(
                <Option
                    key="no-options"
                    label="No options"
                />,
            );
        }

        const withScroll = props.options.length > 4;

        const optionsClassName = classNames({
            [styles.options]: true,
            [styles.optionsWithScroll]: withScroll,
            [scrollbarStyles.scrollbar]: withScroll,
        });

        return (
            <div className={optionsClassName}>
                {renderOptionLabels()}
                {options}
            </div>
        );
    };

    const renderIconOrLoader = () => {
        if (props.isLoading) {
            return (
                <LoaderSmall />
            );
        }

        if (!props.icon) {
            return null;
        }

        let isOrange = false;
        let isSky = false;

        if (props.isSky) {
            isSky = true;
        } else {
            isOrange = true;
        }

        return (
            <props.icon
                className={styles.icon}
                isOrange={isOrange}
                isSky={isSky}
            />
        );
    };

    const renderSelected = () => {
        if (props.isIconOnly) {
            return null;
        }

        const selected = array.findOneByValue(props.options, props.selected);
        const label = selected?.label || "Select...";

        return (
            <div>
                {label}
            </div>
        );
    };

    const renderCaret = () => {
        const caretClassName = classNames({
            [styles.caret]: true,
            [styles.caretUp]: isOptionsVisible,
        });

        let isOrange = false;
        let isSky = false;

        if (isOptionsVisible) {
            if (props.isSky) {
                isSky = true;
            } else {
                isOrange = true;
            }
        }

        return (
            <IconCaret
                className={caretClassName}
                isOrange={isOrange}
                isSky={isSky}
            />
        );
    };

    /* --- */

    const selectClassName = classNames({
        [styles.select]: true,
        [styles.selectIconOnly]: props.isIconOnly,
        [styles.selectWithBorder]: props.withBorder,

        [styles.selectOpened]: isOptionsVisible,
        [styles.selectOpenedSky]: props.isSky && isOptionsVisible,

        [styles.selectDisabled]: props.isLoading,

        [props.selectClassName]: !!props.selectClassName,
    });

    return (
        <div
            className={styles.container}
            ref={containerRef}
        >
            <DivButton
                onClick={toggleOptions}
                className={selectClassName}
            >
                {renderIconOrLoader()}
                {renderSelected()}
                {renderCaret()}
            </DivButton>
            {renderOptions()}
        </div>
    );
};

SelectCustomSmall.defaultProps = {
    icon: null,
    selected: null,
    optionsLabel: "",
    optionsLabel2: "",
    options: [],
    selectClassName: "",
    onSelect: () => { },
    withBorder: false,
    isSky: false,
    isIconOnly: false,
    isLoading: false,
};

export default SelectCustomSmall;
