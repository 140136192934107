import React from "react";

import symbols from "@/base/lib/symbols.js";

import IconProfileSettings from "@/base/icons/profile-settings/index.js";
import IconSignOut from "@/base/icons/sign-out/index.js";
import IconSupport from "@/base/icons/support/index.js";
import IconDisplaySettings from "@/base/icons/display-settings/index.js";

import AvatarIcon from "@/base/components/avatar-icon/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import ProfileRow from "@/base/components/profile-row/index.js";
import ProfileReadingMode from "@/base/components/profile-reading-mode/index.js";

import styles from "./styles.module.css";


const AvatarMenu = (props) => {
    const renderUsername = () => {
        const avatarIcon = (
            <AvatarIcon
                name={props.data.name}
                avatarUrl={props.data.avatarUrl}
                disabled
            />
        );

        return (
            <ProfileRow
                customLeftControl={avatarIcon}
                customName={props.data.fullname}
                value={props.data.username}
            />
        );
    };

    const renderEmail = () => {
        return (
            <ProfileRow
                value={props.data.email || symbols.nbsp}
                onClickRightControl={props.onEditEmail}
                customRightControl={<div />}
                isEmail
            />
        );
    };

    const renderPassword = () => {
        if (props.data.isLmsUser) {
            return null;
        }

        return (
            <ProfileRow
                value="********"
                onClickRightControl={props.onEditPassword}
                isPassword
            />
        );
    };

    const renderFreeTrialRow = () => {
        if (!props.isVisibleFreeTrialRow) {
            return null;
        }

        const { daysLeftToExpire } = props.data;

        let expirationText = "";

        if (daysLeftToExpire === -1) {
            expirationText = "Expired";
        } else if (daysLeftToExpire === 0) {
            expirationText = "No expiration";
        } else {
            expirationText = `${daysLeftToExpire} days left`;
        }

        return (
            <ProfileRow
                value={expirationText}
                isExpiration
            />
        );
    };

    /* --- */

    const renderReadingMode = () => {
        if (!props.isVisibleReadingMode) {
            return null;
        }

        return (
            <div className={styles.readingMode}>
                <ProfileReadingMode
                    onChange={props.onChangeReadingMode}
                    isChecked={props.isEnabledReadingMode}
                />
            </div>
        );
    };

    /* --- */

    const renderManageButton = () => {
        if (!props.isVisibleManageButton) {
            return null;
        }

        return (
            <ButtonBig
                icon={IconProfileSettings}
                onClick={props.onManageAccount}
                isRounded
                outlined
            >
                Manage Account
            </ButtonBig>
        );
    };

    const renderThemeSettingsButton = () => {
        return (
            <ButtonBig
                icon={IconDisplaySettings}
                onClick={props.onSettings}
                isRounded
                outlined
            >
                Theme Settings
            </ButtonBig>
        );
    };

    const renderControls = () => {
        return (
            <div className={styles.menuControls}>
                <ButtonBig
                    icon={IconSignOut}
                    onClick={props.onLogout}
                    isRounded
                >
                    Log out
                </ButtonBig>

                {renderManageButton()}
                {renderThemeSettingsButton()}

                <ButtonBig
                    icon={IconSupport}
                    onClick={props.onContactSupport}
                    isRounded
                    outlined
                >
                    Contact Support
                </ButtonBig>
            </div>
        );
    };

    return (
        <div
            className={styles.menu}
            data-comment="avatar-menu"
        >
            <div className={styles.menuData}>
                {renderUsername()}
                {renderEmail()}
                {renderPassword()}
                {renderFreeTrialRow()}
            </div>
            {renderReadingMode()}
            {renderControls()}
        </div>
    );
};

AvatarMenu.defaultProps = {
    data: {
        name: "A",
        username: "",
        avatarUrl: "",
        fullname: "",
        email: "",
        daysLeftToExpire: 0,
        isLmsUser: false,
    },

    onEditPassword: () => { },
    onEditEmail: () => { },
    onChangeReadingMode: () => { },
    onLogout: () => { },
    onManageAccount: () => { },
    onSettings: () => { },
    onContactSupport: () => { },

    isVisibleReadingMode: false,
    isEnabledReadingMode: false,
    isVisibleFreeTrialRow: false,
    isVisibleManageButton: true,
};

export default AvatarMenu;
