import array from "@/base/lib/array.js";


const loadCountries = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.geo.getCountries({
            session: state.user.session,
        });

        // TODO: error handler
        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setCountries({
            countries: res.countries,
        }));
    };
};

const loadSubdivisionsByAlpha2 = (services, params) => {
    const { actions, api } = services;
    const { alpha2 } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.geo.setSubdivisionsByAlpha2Loading({ alpha2 }));

        const res = await api.geo.getSubdivisions({
            session: state.user.session,
            alpha2,
        });

        if (!res.ok) {
            return;
        }

        const subs = res.subdivisions || [];
        const subdivisions = array.sortByFieldATOZ(subs, "name");

        dispatch(actions.geo.setSubdivisionsByAlpha2({
            alpha2,
            subdivisionName: res.subdivisionName,
            subdivisions,
        }));
    };
};

const loadSchoolDistricts = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        subdivision,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getDistricts({
            session,
            search: "",
            subdivision,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setDistrictsBySubdivision({
            subdivision,
            districts: res?.districts || [],
        }));
    };
};

const loadSchoolCities = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        subdivision,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getPrivateSchoolsCities({
            session,
            subdivision,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setCitiesBySubdivision({
            subdivision,
            cities: res?.cities || [],
        }));
    };
};

const loadSchoolsByCity = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        city,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getPrivateSchools({
            session,
            city,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setSchoolsByCity({
            city,
            schools: res?.schools || [],
        }));
    };
};

const loadSchoolsByDistrictId = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        districtId,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getSchools({
            session,
            search: "",
            districtId,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setSchoolsByDistrictId({
            districtId,
            schools: res.schools || [],
        }));
    };
};

export default {
    loadCountries,
    loadSubdivisionsByAlpha2,
    loadSchoolDistricts,
    loadSchoolCities,
    loadSchoolsByCity,
    loadSchoolsByDistrictId,
};
