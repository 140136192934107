import React from "react";

import staticFiles from "@/base/static-files.js";

import parseTags from "@/base/lib/parse-tags.js";
import classNames from "@/base/lib/class-names.js";

import IconOrange from "@/base/icons/orange/index.js";

import Text from "@/base/components/text/index.js";
import ButtonRounded from "@/base/components/button-rounded/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import stylesCommon from "@/base/components/juice-content-common/styles.module.css";
import styles from "./styles.module.css";


const JuiceContent = (props) => {
    const onContentClick = (evt) => {
        if (evt.target && evt.target.dataset.word) {
            const word = (evt.target.innerText || "").trim();

            if (evt.target.dataset.word === "word") {
                props.onWordClick(word);
            } else if (evt.target.dataset.word === "extraJuiceWord") {
                props.onExtraJuiceWordClick("story");
            }
        }
    };

    const onExtraJuiceClick = () => {
        props.onExtraJuiceWordClick("story-button");
    };

    const renderExtraJuices = () => {
        if (props.extraJuices.length === 0 || props.hideExtraJuicesButtons) {
            return null;
        }

        const extraJuicesWords = [];

        props.extraJuices.forEach((ej) => {
            extraJuicesWords.push(
                <ButtonRounded onClick={onExtraJuiceClick}>
                    <IconOrange
                        className={styles.extraJuiceIcon}
                        isOrange
                    />
                    {`Extra Juice: ${ej.title}`}
                </ButtonRounded>,
            );
        });

        return (
            <div className={styles.extraJuices}>
                {extraJuicesWords}
            </div>
        );
    };

    const renderJuiceContent = () => {
        let newContent = parseTags.parse(props.content).map((line) => {
            if (parseTags.isTag(line)) {
                const wordVal = parseTags.trimTag(line);
                return `<span data-word="word" class="${styles.vocabWord}">${wordVal}</span>`;
            }

            return line;
        }).join("");

        newContent = parseTags.parseCurlyBrackets(newContent).map((line) => {
            if (parseTags.isTagCurlyBrackets(line)) {
                const wordVal = parseTags.trimTagCurlyBrackets(line);

                if (props.hideExtraJuicesButtons) {
                    return (`
                        <span class="${styles.extraJuiceWordOnDesktop}">${wordVal}</span>
                    `);
                }

                return (`
                    <span data-word="extraJuiceWord" class="${styles.extraJuiceWord}">
                        <img
                            src="${staticFiles.iconOrange}"
                            alt="extra-juice"
                            title="extra-juice"
                        />${wordVal}</span>
                `);
            }

            return line;
        }).join("");

        newContent = parseTags.parseShortcode(newContent).map((line) => {
            if (!parseTags.isTagShortcode(line)) {
                return line;
            }

            if (parseTags.isTagShortcodeName(line, "caption")) {
                const contentLines = parseTags.parse(line.tagContent, "<", "/>");

                if (contentLines.length === 0) {
                    return "";
                }

                let captionText = null;

                if (contentLines[1]) {
                    captionText = `
                        <div class="${styles.captionText}">${contentLines[1]}</div>
                    `;
                }

                return (`
                    <div class="${styles.captionImg}">${contentLines[0]}${captionText}</div>
                `);
            }

            return line.tagContent;
        }).join("");

        return (
            <Text onClick={onContentClick}>
                {newContent}
            </Text>
        );
    };

    const contentClassName = classNames({
        [stylesFonts.content]: true,
        [stylesFonts.contentDyslexic]: props.isDyslexic,
        [stylesCommon.content]: true,
        [props.className]: props.className,
    });

    return (
        <div className={contentClassName}>
            {renderJuiceContent()}
            {renderExtraJuices()}
        </div>
    );
};

JuiceContent.defaultProps = {
    className: "",
    content: "",
    extraJuices: [],
    hideExtraJuicesButtons: false,
    isDyslexic: false,
    onWordClick: () => { },
    onExtraJuiceWordClick: () => { },
};

export default JuiceContent;
