const PLAN_SLUG = {
    large: "large-class",
};

const getLargePlanSlug = () => {
    return PLAN_SLUG.large;
};

/* --- */

const isPlanLimitExceeded = (classes, plan) => {
    const maxClasses = plan?.maxClasses || 0;

    if (classes.length > maxClasses) {
        return true;
    }

    const maxStudents = plan?.maxStudents || 0;
    let totalStudents = 0;

    for (let i = 0; i < classes.length; i += 1) {
        totalStudents += classes[i].studentsCount || 0;
    }

    return totalStudents > maxStudents;
};

/* --- */

export default {
    getLargePlanSlug,
    isPlanLimitExceeded,
};
