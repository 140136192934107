import React from "react";

import styles from "./styles.module.css";


const Card = (props) => {
    const features = props.features.map((feature) => {
        return (
            <li>{feature}</li>
        );
    });

    return (
        <div className={styles.card}>
            <div className={styles.cardPrice}>
                <div>{`$${props.price}`}</div>
                <div>{props.billingCycle}</div>
            </div>
            <ul>
                {features}
            </ul>
        </div>
    );
};

Card.defaultProps = {
    price: "",
    billingCycle: "",
    features: [],
};

export default Card;
