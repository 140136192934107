import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isSponsorLoaded: false,
    sponsor: {},
    isAnnouncementLoaded: false,
    announcement: {},
    quizResults: {
        weeks: {},
        months: {},
    },
    grades: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_STUDENT_SPONSOR: {
            return {
                ...state,
                isSponsorLoaded: true,
                sponsor: action.payload.sponsor || {},
            };
        }

        case types.SET_STUDENT_ANNOUNCEMENT: {
            return {
                ...state,
                isAnnouncementLoaded: true,
                announcement: action.payload.announcement || {},
            };
        }

        /* --- */

        case types.SET_STUDENT_QUIZ_RESULTS_LOADING: {
            const { type, amount } = action.payload;

            let quizResults = {};

            if (type === "weeks") {
                quizResults = {
                    ...state.quizResults,
                    weeks: {
                        ...state.quizResults.weeks,
                        [amount]: {
                            isLoading: true,
                            isLoaded: false,
                            data: null,
                            error: "",
                        },
                    },
                };
            } else if (type === "months") {
                quizResults = {
                    ...state.quizResults,
                    months: {
                        ...state.quizResults.months,
                        [amount]: {
                            isLoading: true,
                            isLoaded: false,
                            data: null,
                            error: "",
                        },
                    },
                };
            }

            return {
                ...state,
                quizResults,
            };
        }

        case types.SET_STUDENT_QUIZ_RESULTS: {
            const {
                type,
                amount,
                data,
                error,
            } = action.payload;

            let quizResults = {};

            if (type === "weeks") {
                quizResults = {
                    ...state.quizResults,
                    weeks: {
                        ...state.quizResults.weeks,
                        [amount]: {
                            isLoading: false,
                            isLoaded: true,
                            data,
                            error,
                        },
                    },
                };
            } else if (type === "months") {
                quizResults = {
                    ...state.quizResults,
                    months: {
                        ...state.quizResults.months,
                        [amount]: {
                            isLoading: false,
                            isLoaded: true,
                            data,
                            error,
                        },
                    },
                };
            }

            return {
                ...state,
                quizResults,
            };
        }

        /* --- */

        case types.SET_STUDENT_GRADES: {
            const {
                label,
                data,
                error,
                pendingGrade,
                isAdjustmentAllowed,
            } = action.payload;

            return {
                ...state,
                grades: {
                    label,
                    data,
                    error,
                    pendingGrade,
                    isAdjustmentAllowed,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        case types.SET_STUDENT_PENDING_GRADE: {
            const { pendingGrade } = action.payload;

            return {
                ...state,
                grades: {
                    ...state.grades,
                    pendingGrade,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
