import React, { useCallback, useMemo } from "react";

import array from "@/base/lib/array.js";
import classNames from "@/base/lib/class-names.js";

import IconOrange from "@/base/icons/orange/index.js";
import IconPlayerHeadphone from "@/base/icons/player-headphone/index.js";
import IconTranslate from "@/base/icons/translate/index.js";

import ButtonCircle from "@/base/components/button-circle/index.js";
import JuiceContentWithTranslation from "@/base/components/juice-content-with-translation/index.js";
import PlayerAudioV2 from "@/base/components/player-audio-v2/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";
import ToggleDropdown from "@/base/components/toggle-dropdown/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import styles from "./styles.module.css";


const PopupExtraJuice = (props) => {
    const { storyId, extraJuice } = props;

    const translatedLanguage = props.translation?.language || "";
    const allLanguages = props.translation?.languages || [];

    const selectedStoryLanguage = useMemo(() => {
        return array.findOneByValue(
            allLanguages,
            translatedLanguage,
        );
    }, [translatedLanguage]);

    /* --- */

    const onTranslate = useCallback((values) => {
        props.onTranslate({
            storyId: props.extraJuice.id,
            languageCode: values.selectedValue,
            languageName: values.selectedName,
            isChecked: values.isChecked,
        });
    }, [props.onTranslate]);

    /* --- */

    const renderPopupTitle = () => {
        return (
            <div className={styles.headerTitle}>
                <IconOrange
                    className={styles.headerTitleIcon}
                    title="Extra Juice"
                />
                <div className={styles.headerTitleText}>
                    {extraJuice.title}
                </div>
            </div>
        );
    };

    const renderPlayer = () => {
        if (!props.audio) {
            return null;
        }

        return (
            <PlayerAudioV2
                image={extraJuice.image || ""}
                title={extraJuice.title}
                category="Extra Juice"
                audio={props.audio}
                highlight={props.audioHighlight}
                playback={props.playback}
                error={props.audio.error || ""}
                onFirstPlay={props.onFirstPlay}
                onRetryPlay={props.onRetryPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                onClose={props.onPlayerClose}
                isMobile={props.isPlayerMobile}
            />
        );
    };

    const renderTitle = () => {
        const titleBoxStyles = {};

        if (extraJuice.image) {
            titleBoxStyles.backgroundImage = `url(${extraJuice.image})`;
        }

        const titleClassName = classNames({
            [stylesFonts.textDyslexic]: props.isDyslexic,
            [styles.titleBox]: true,
        });

        return (
            <div
                className={titleClassName}
                style={titleBoxStyles}
            >
                {extraJuice.title}
            </div>
        );
    };

    const renderListenButton = () => {
        const iconHeadPhone = (
            <IconPlayerHeadphone isOrange />
        );

        return (
            <ButtonCircle
                icon={iconHeadPhone}
                onClick={() => {
                    if (!props.audio) {
                        props.onAudioPlay();
                    }
                }}
            />
        );
    };

    const renderTranslationButton = () => {
        const translation = props.translation || {};

        return (
            <ToggleDropdown
                name="Translate"
                selectedOption={translation?.language}
                options={allLanguages}
                iconToggle={<IconTranslate isOrange />}
                onChange={onTranslate}
                isChecked={translation?.isChecked}
                isCentered
            />
        );
    };

    const renderRightControl = () => {
        const controls = [renderListenButton()];

        if (allLanguages.length > 0) {
            controls.push(renderTranslationButton());
        }

        return controls;
    };

    /* --- */

    const renderJuiceContent = () => {
        const translation = props.translation || {};

        const isTranslated = translation.isChecked || false;
        const isTranslationLoading = translation?.isLoading || false;
        const translationError = translation?.error || "";

        let highlight = null;

        if (props.contentHighlight?.isContent) {
            highlight = props.contentHighlight;
        }

        return (
            <div className={styles.content}>
                <JuiceContentWithTranslation
                    content={extraJuice.content}
                    translation={translation?.translation}
                    translationDirection={selectedStoryLanguage?.direction}
                    translationError={translationError}
                    highlight={highlight}
                    onWordClick={props.onWordClick}
                    isTranslationLoading={isTranslationLoading}
                    isTranslated={isTranslated}
                    isDyslexic={props.isDyslexic}
                    isMobile
                />
            </div>
        );
    };

    /* --- */

    if (storyId === -1 || !extraJuice) {
        return null;
    }

    return (
        <PopupWindow
            title={renderPopupTitle()}
            rightControl={renderRightControl()}
            player={renderPlayer()}
            withScrollRef={props.withScrollRef}
            hideScrollbar={props.hideScrollbar}
            onClose={props.onClose}
            titleToStart
        >
            <div className={styles.popupContent}>
                {renderTitle()}
                {renderJuiceContent()}

                <div className={styles.storyRate}>
                    {props.rate}
                </div>
            </div>
        </PopupWindow>
    );
};

PopupExtraJuice.defaultProps = {
    storyId: -1,
    extraJuice: null,
    rate: null,
    playback: null,
    withScrollRef: null,
    audioHighlight: null,
    contentHighlight: null,
    translation: {
        language: "",
        languages: [],
        isChecked: false,
        isLoading: false,
        translation: null,
        error: "",
    },

    onTranslate: () => { },
    onWordClick: () => { },
    onFirstPlay: () => { },
    onRetryPlay: () => { },
    onPlay: () => { },
    onPause: () => { },
    onRewind: () => { },
    onForward: () => { },
    onChangeRate: () => { },
    onPlayerClose: () => { },
    onClose: () => { },

    hideScrollbar: true,
    isPlayerMobile: false,
    isDyslexic: false,
};

export default PopupExtraJuice;
