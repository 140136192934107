// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WTdBX_akVmabRv2eIUnp {\n    display: grid;\n    grid-gap: 7rem;\n}\n\n.vkOewRxXaJzDSUDaGuag {\n    display: grid;\n}\n\n.XIxh4HNj3KdZ4kmNE4BY {\n    display: grid;\n}\n\n.c515S5lyrWKq9RAgykgw {\n    margin-bottom: 3.5rem;\n}\n\n._7XslJbkaOK1EUeWTFNYK {\n    visibility: hidden;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/teacher-school-details/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 7rem;\n}\n\n.fields {\n    display: grid;\n}\n\n.submitButton {\n    display: grid;\n}\n\n.schoolTypesToggler {\n    margin-bottom: 3.5rem;\n}\n\n.hidden {\n    visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "WTdBX_akVmabRv2eIUnp",
	"fields": "vkOewRxXaJzDSUDaGuag",
	"submitButton": "XIxh4HNj3KdZ4kmNE4BY",
	"schoolTypesToggler": "c515S5lyrWKq9RAgykgw",
	"hidden": "_7XslJbkaOK1EUeWTFNYK"
};
export default ___CSS_LOADER_EXPORT___;
