export default (apiURL, methods) => {
    return {
        getNotifications(params = {}) {
            // NOTE: action=get_user_notifications_by_page
            const url = `${apiURL}/user/notifications`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
            });
        },

        getNotificationById(params = {}) {
            // NOTE: action=get_notification_by_id
            const url = `${apiURL}/user/notifications/${params.id}`;

            return methods.get(url, {
                session_id: params.session,
                id: params.id,
            });
        },

        setNotificationViewed(params = {}) {
            // NOTE: action=set_user_notification_viewed
            const url = `${apiURL}/user/notifications/set-viewed`;

            return methods.post(url, {
                session_id: params.session,
                id: params.id,
            });
        },

        setNotificationDismiss(params = {}) {
            // NOTE: action=set_user_notification_dismissed
            const url = `${apiURL}/user/notifications/dismiss`;

            return methods.post(url, {
                session_id: params.session,
                id: params.id,
            });
        },
    };
};
