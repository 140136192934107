import text from "@/base/text/index.js";


const loadFirstVideos = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch) => {
        dispatch(actions.juicesVideos.setJuicesVideosLoading());

        const page = 0;
        const onlyFirst = params.onlyFirst || false;

        const res = await api.dailyJuices.getJuicesVideos({
            page,
            onlyFirst,
        });

        let videos = [];

        if (res.ok) {
            videos = [
                ...res.featuredVideos,
            ];
        }

        const hasMorePages = res.ok ? res.hasMore : false;

        dispatch(actions.juicesVideos.setJuicesVideos({
            videos,
            page,
            hasMorePages,
        }));
    };
};

const loadVideos = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juicesVideos.setJuicesVideosLoading());

        const page = params.page || 0;

        const res = await api.dailyJuices.getJuicesVideos({
            page,
        });

        let videos = [];

        if (res.ok) {
            // TODO: fix incorrect redux usage
            videos = [
                ...state.juicesVideos.videos,
                ...res.featuredVideos,
            ];
        }

        const hasMorePages = res.ok ? res.hasMore : false;

        dispatch(actions.juicesVideos.setJuicesVideos({
            videos,
            page,
            hasMorePages,
        }));
    };
};

const loadMoreVideos = (services) => {
    return (dispatch, getState) => {
        const state = getState();

        const vs = state.juicesVideos.videos || [];
        const page = state.juicesVideos.page || 0;

        if (page === 0 && vs.length < 10) {
            dispatch(loadFirstVideos(services, {
                onlyFirst: false,
            }));
            return;
        }

        dispatch(loadVideos(services, {
            page: page + 1,
        }));
    };
};

const loadVideoById = (services, params) => {
    const { api, actions } = services;
    const { id } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juicesVideos.setJuiceVideoByIdLoading(id));

        const res = await api.videos.getJuiceVideo({
            session: state.user.session,
            videoId: id,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.video;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juicesVideos.setJuiceVideoById({
            id,
            data,
            error,
        }));
    };
};

export default {
    loadFirstVideos,
    loadVideos,
    loadMoreVideos,
    loadVideoById,
};
