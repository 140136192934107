import * as net from "@/base/lib/net.js";
import createAPI from "@/base/api-app/index.js";

const apiURL = API_URL; // eslint-disable-line no-undef


const getProjectAPI = () => {
    return createAPI(apiURL, {
        get: net.get,
        get3: net.get3,
        post: net.post,
        postForm: net.postForm,
        del: net.del,
    });
};

export default getProjectAPI();
