import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const SectionStudentV2 = (props) => {
    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <div className={styles.icon}>
                {props.icon}
            </div>
        );
    };

    const renderHeader = () => {
        if (!props.title) {
            return null;
        }

        let subTitle = null;

        if (props.subTitle) {
            subTitle = (
                <div className={styles.subTitle}>
                    {props.subTitle}
                </div>
            );
        }

        const headerClassName = classNames({
            [styles.header]: true,
            [styles.headerWithBottomBorder]: props.titleBottomBorder,
            [props.headerClassName]: props.headerClassName,
        });

        return (
            <div className={headerClassName}>
                <div className={styles.headerTitle}>
                    {renderIcon()}
                    <div>
                        <div className={styles.title}>
                            {props.title}
                        </div>
                        {subTitle}
                    </div>
                </div>
                {props.rightControl}
            </div>
        );
    };

    return (
        <div
            className={styles.section}
            data-comment={props.dataComment}
        >
            {renderHeader()}
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

SectionStudentV2.defaultProps = {
    headerClassName: "",
    dataComment: "",
    title: "",
    subTitle: "",
    icon: null,
    rightControl: null,
    children: null,
    titleBottomBorder: true,
};

export default SectionStudentV2;
