import React from "react";

import {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";
import AlertBox from "@/base/components/alert-box/index.js";


const Index = (props) => {
    return (
        <>
            <PopupConfirmTitle>
                Bad email detected
            </PopupConfirmTitle>
            <PopupConfirmContent>
                <AlertBox theme="danger">
                    {`We detected that your email address ${props.email} is incorrect. Please change your email address to continue using The Juice.`}
                </AlertBox>
            </PopupConfirmContent>
            <PopupConfirmButtons
                vertical
            >
                <ButtonFlat onClick={props.onConfirm}>
                    My email address is correct
                </ButtonFlat>
                <ButtonFlat onClick={props.onChange}>
                    Change my email address
                </ButtonFlat>
            </PopupConfirmButtons>
        </>
    );
};

Index.defaultProps = {
    email: "",
    onConfirm: () => { },
    onChange: () => { },
};

export default Index;
