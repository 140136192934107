import React from "react";

import IconAudioPlay from "@/base/icons/audio-play/index.js";
import IconAudioSound from "@/base/icons/audio-sound/index.js";
import IconAudioStop from "@/base/icons/audio-stop/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import IconSoundLoading from "@/base/components/icon-sound-loading/index.js";

import styles from "./audio-button.module.css";


const WordCardAudioButton = (props) => {
    const {
        text,
        word,
        audio,
        isDefinition,
    } = props;

    /* --- */

    const onSpeech = () => {
        if (!text) {
            return;
        }

        if (!audio[text]) {
            props.onLoad(text, word.id, isDefinition);
        }
    };

    /* --- */

    const renderAudioPlaying = () => {
        return (
            <IconClickable
                className={styles.controlIcon}
                onClick={() => {
                    props.onStop(text, word.id, isDefinition);
                }}
            >
                <IconAudioStop
                    className={styles.controlIcon}
                    title="Stop"
                    isSky
                />
            </IconClickable>
        );
    };

    const renderAudioResume = () => {
        return (
            <IconClickable
                className={styles.controlIcon}
                onClick={() => {
                    props.onPlay(text, word.id, isDefinition);
                }}
            >
                <IconAudioPlay
                    className={styles.controlIcon}
                    title="Play"
                    isSky
                />
            </IconClickable>
        );
    };

    const renderAudioStart = () => {
        return (
            <IconClickable
                className={styles.controlIcon}
                onClick={() => {
                    onSpeech(text, isDefinition);
                }}
            >
                <IconAudioSound
                    className={styles.controlIcon}
                    title="Sound"
                    isSky
                />
            </IconClickable>
        );
    };

    /* --- */

    const data = audio[text];

    if (data?.isLoading) {
        return (
            <IconSoundLoading />
        );
    }

    if (data?.isPlaying) {
        return renderAudioPlaying(text, isDefinition);
    }

    if (data) {
        return renderAudioResume(text, isDefinition);
    }

    return renderAudioStart(text, isDefinition);
};

WordCardAudioButton.defaultProps = {
    text: "",
    word: null,
    audio: {},
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
    isDefinition: false,
};

export default WordCardAudioButton;
