import React from "react";

import styles from "./styles.module.css";


const AsideNavButton = (props) => {
    return (
        <div
            className={styles.sectionWithIcon}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <props.icon
                className={styles.sectionIcon}
            />
            {props.label}
        </div>
    );
};

AsideNavButton.defaultProps = {
    label: "",
    icon: null,
    onClick: () => { },
};

export default AsideNavButton;
