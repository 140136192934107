import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonFlat = (props) => {
    const {
        danger,
        uppercase,
        hugePadding,
        disabled,
    } = props;

    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <props.icon
                className={styles.icon}
                isSky={!danger && !disabled}
                isRed={danger && !disabled}
                isBlack={disabled}
            />
        );
    };

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.uppercase]: uppercase,
        [styles.danger]: danger,
        [styles.hugePadding]: hugePadding,
    });

    return (
        <button
            type={props.type}
            className={buttonClassName}
            disabled={disabled}
            onClick={props.onClick}
        >
            {renderIcon()}
            {props.children}
        </button>
    );
};

ButtonFlat.defaultProps = {
    type: "button",
    icon: null,
    children: null,

    onClick: () => { },

    danger: false,
    disabled: false,
    uppercase: true,
    hugePadding: false,
};

export default ButtonFlat;
