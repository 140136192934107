import text from "@/base/text/index.js";


const loadLastDailyJuice = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentDailyJuices.setLastDailyJuiceLoading());

        const state = getState();

        const res = await api.dailyJuices.getLastJuice({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.juice || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentDailyJuices.setLastDailyJuice({
            data,
            error,
        }));
    };
};

const loadJuiceStoriesInfoById = (services, params) => {
    const { api, actions } = services;
    const { juiceId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juiceStories.setStoriesInfoByJuiceIdLoading({ juiceId }));

        const res = await api.student.getJuiceStoriesInfoByJuiceIdV2({
            session: state.user.session,
            juiceId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juiceStories.setStoriesInfoByJuiceId({
            juiceId,
            data,
            error,
        }));
    };
};


export default {
    loadLastDailyJuice,
    loadJuiceStoriesInfoById,
};
