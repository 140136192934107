import text from "@/base/text/index.js";


const loadDailyJuicesScores = (services, params = {}) => {
    const { api, actions } = services;

    const forceUpdate = params.forceUpdate || false;

    return async (dispatch, getState) => {
        const state = getState();

        const classId = params.classId || "";
        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        if (!classId || !dateFrom || !dateToOrLoadType) {
            return;
        }

        const range = `${dateFrom}-${dateToOrLoadType}`;

        const isLoaded = state.teacher.classesScoresByDailyJuices?.[classId]?.[range]?.data;

        if (isLoaded && !forceUpdate) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassScoresByDailyJuicesLoading({
            range,
            classId,
        }));

        const res = await api.teacherClasses.getTeacherClassScoresByDailyJuices({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo: dateToOrLoadType,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassScoresByDailyJuices({
            classId,
            range,
            data,
            error,
        }));
    };
};

const reloadDailyJuiceScores = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const classId = params.classId || "";
        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        if (!classId || !dateFrom || !dateToOrLoadType) {
            return;
        }

        const range = `${dateFrom}-${dateToOrLoadType}`;

        const res = await api.teacherClasses.getTeacherClassScoresByDailyJuices({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo: dateToOrLoadType,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.updateTeacherClassScoresByDailyJuice({
            classId,
            range,
            data,
            error,
        }));

        /* --- */

        const secondsInMS = 5 * 1000;

        globalThis.setTimeout(() => {
            dispatch(actions.teacher.clearUpdatedStatusTeacherClassScoresByDailyJuice({
                classId,
                range,
                data,
            }));
        }, secondsInMS);
    };
};

const loadOrReloadDailyJuicesScores = (services) => {
    return (dispatch, getState) => {
        const state = getState();

        const classId = state.navigation?.params?.classId || null;

        if (!classId) {
            return;
        }

        const { dateFrom, dateToOrLoadType } = state.navigation.params;
        const range = `${dateFrom}-${dateToOrLoadType}`;

        const isLoaded = state.teacher.classesScoresByDailyJuices?.[classId]?.[range]?.data;

        if (isLoaded) {
            const reloadAction = reloadDailyJuiceScores(services, { classId });
            reloadAction(dispatch, getState);
            return;
        }

        const loadAction = loadDailyJuicesScores(services, { classId });
        loadAction(dispatch, getState);
    };
};

export default {
    loadDailyJuicesScores,
    reloadDailyJuiceScores,
    loadOrReloadDailyJuicesScores,
};
