import * as types from "./types.js";


export const setSearchStandards = (payload) => ({
    type: types.SET_SEARCH_STANDARDS,
    payload,
});

/* --- */

export const setSearchCategories = (payload) => ({
    type: types.SET_SEARCH_CATEGORIES,
    payload,
});

/* --- */

export const setSearchEmptyLoading = () => ({
    type: types.SET_SEARCH_EMPTY_LOADING,
});

export const setSearchEmptyData = (payload) => ({
    type: types.SET_SEARCH_EMPTY_DATA,
    payload,
});

/* --- */

export const setSearchByKeywordLoading = (payload) => ({
    type: types.SET_SEARCH_BY_KEYWORD_LOADING,
    payload,
});

export const setSearchByKeyword = (payload) => ({
    type: types.SET_SEARCH_BY_KEYWORD,
    payload,
});

/* --- */

export const setSearchStoryRating = (payload) => ({
    type: types.SET_SEARCH_STORY_RATING,
    payload,
});

export const setSearchStoryExtraJuiceRating = (payload) => ({
    type: types.SET_SEARCH_STORY_EXTRA_JUICE_RATING,
    payload,
});

/* --- */

export const setSearchContentTagsLoading = (payload) => ({
    type: types.SET_SEARCH_CONTENT_TAGS_LOADING,
    payload,
});

export const setSearchContentTags = (payload) => ({
    type: types.SET_SEARCH_CONTENT_TAGS,
    payload,
});
