import * as types from "./types.js";


export const setLanguages = (payload) => ({
    type: types.SET_TRANSLATION_LANGUAGES,
    payload,
});

/* --- */

export const setStoryLoadingById = (payload) => ({
    type: types.SET_TRANSLATION_STORY_LOADING_BY_ID,
    payload,
});

export const setStoryErrorById = (payload) => ({
    type: types.SET_TRANSLATION_STORY_ERROR_BY_ID,
    payload,
});

export const setStoryById = (payload) => ({
    type: types.SET_TRANSLATION_STORY_BY_ID,
    payload,
});

/* --- */

export const setVocabWordLoading = (payload) => ({
    type: types.SET_TRANSLATION_VOCAB_WORD_LOADING,
    payload,
});

export const setVocabWord = (payload) => ({
    type: types.SET_TRANSLATION_VOCAB_WORD,
    payload,
});

/* --- */

export const changeStoryUITranslationById = (payload) => ({
    type: types.UI_CHANGE_STORY_TRANSLATION,
    payload,
});
