import createEvents from "@/base/api-common/events-custom/index.js";

import api from "@/app/api.js";


const getProjectEvents = () => {
    return createEvents({
        sendEvent: api.events.sendEvent,
    });
};

export default getProjectEvents();
