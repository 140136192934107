import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    studentsNotifications: {},
    notifications: {},
    notificationsById: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_STUDENTS_NOTIFICATIONS: {
            const { classId, notifications } = action.payload;

            return {
                ...state,
                studentsNotifications: {
                    ...state.studentsNotifications,
                    [classId]: notifications,
                },
            };
        }

        /* --- */

        case types.SET_NOTIFICATIONS_LOADING: {
            const data = state.notifications.data || [];
            const page = state.notifications.page || 0;
            const hasMore = state.notifications.hasMore || false;

            return {
                ...state,
                notifications: {
                    data,
                    page,
                    error: "",
                    hasMore,
                    isLoading: true,
                },
            };
        }

        case types.SET_NOTIFICATION_VIEWED: {
            const { id } = action.payload;

            const notifications = { ...state.notifications };
            const notificationsById = { ...state.notificationsById };

            notifications.data = (notifications.data || []).map((data) => {
                const notification = { ...data };

                if (notification.id === id) {
                    notification.isViewed = true;
                }

                return notification;
            });

            if (notificationsById[id]) {
                notificationsById[id] = {
                    ...notificationsById[id],
                    data: {
                        ...notificationsById[id].data,
                        isViewed: true,
                    },
                };
            }

            return {
                ...state,
                notifications,
                notificationsById,
            };
        }

        case types.SET_NOTIFICATIONS: {
            const { data, error, hasMore } = action.payload;

            const notifications = {
                ...state.notifications,
                isLoading: false,
            };

            if (error) {
                notifications.error = error;
            } else {
                notifications.page += 1;
                notifications.hasMore = hasMore;
                notifications.data = [
                    ...notifications.data,
                    ...data,
                ];
            }

            return {
                ...state,
                notifications,
            };
        }

        /* --- */

        case types.SET_NOTIFICATION_BY_ID_LOADING: {
            const { id } = action.payload;
            const notificationsById = { ...state.notificationsById };

            return {
                ...state,
                notificationsById: {
                    ...notificationsById,
                    [id]: {
                        ...notificationsById[id],
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_NOTIFICATION_BY_ID: {
            const { id, data, error } = action.payload;
            const notificationsById = { ...state.notificationsById };

            return {
                ...state,
                notificationsById: {
                    ...notificationsById,
                    [id]: {
                        data,
                        error,
                        isLoaded: true,
                        isLoading: false,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
