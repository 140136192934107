import React from "react";

import styles from "./styles.module.css";


const Beacon = (props) => {
    /* eslint-disable */
    return (
        <button
            key="tutorial-beacon"
            className={styles.beacon}
            type="button"
            tabIndex="-1"
            {...props}
        >
            <span className={styles.beaconInner} />
            <span className={styles.beaconOuter} />
        </button>
    );
    /* eslint-enable */
};

export default Beacon;
