import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isPopupsLoaded: false,
    popups: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_POPUPS: {
            return {
                ...state,
                isPopupsLoaded: true,
                popups: action.payload.popups || [],
            };
        }

        case types.SET_POPUP_OPENED: {
            const { popupId } = action.payload;

            const popups = state.popups.map((data) => {
                if (data.popup.id !== popupId) {
                    return data;
                }

                return {
                    popup: data.popup,
                    state: {
                        ...data.state,
                        isClosing: false,
                        isClosed: false,
                    },
                };
            });

            return {
                ...state,
                popups,
            };
        }

        case types.SET_POPUP_CLOSING: {
            const { popupId } = action.payload;

            const popups = state.popups.map((data) => {
                if (data.popup.id !== popupId) {
                    return data;
                }

                return {
                    popup: data.popup,
                    state: {
                        ...data.state,
                        timeLastClosed: new Date().getTime(),
                        isClosing: true,
                        isClosed: false,
                    },
                };
            });

            return {
                ...state,
                popups,
            };
        }

        case types.SET_POPUP_CLOSED: {
            const { popupId } = action.payload;

            const popups = state.popups.map((data) => {
                if (data.popup.id !== popupId) {
                    return data;
                }

                return {
                    popup: data.popup,
                    state: {
                        ...data.state,
                        timeLastClosed: new Date().getTime(),
                        timesDisplayed: data.state.timesDisplayed + 1,
                        isClosing: false,
                        isClosed: true,
                    },
                };
            });

            return {
                ...state,
                popups,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* ----- */

        default: {
            return state || { ...initialState };
        }
    }
};
