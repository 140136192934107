import { useState } from "react";


const getState = () => ({
    storyId: -1,
    ejId: -1,
    isVisiblePlayer: false,
});

const useExtraJuicePopup = () => {
    const [state, setState] = useState(() => getState());

    const open = (storyId, ejId) => {
        setState((prev) => ({
            ...prev,
            storyId,
            ejId,
        }));
    };

    const close = () => {
        setState(getState());
    };

    const isVisible = () => {
        return !(state.storyId === -1
                 || state.ejId === -1);
    };

    const showPlayer = () => {
        setState((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));
    };

    const hidePlayer = () => {
        setState((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    return {
        state,
        open,
        close,
        isVisible,
        showPlayer,
        hidePlayer,
    };
};

export default useExtraJuicePopup;
