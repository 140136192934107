import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconMessageOutlined = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M4 6.00098H14.1C14.32 7.07098 14.89 8.00098 15.67 8.71098L12 11.001L4 6.00098ZM4 8.00098V18.001H20V9.90098C19.68 9.97098 19.34 10.001 19 10.001C18.4 10.001 17.84 9.88098 17.3 9.68098L12 13.001L4 8.00098Z"
                    />
                    <path
                        fill="currentColor"
                        d="M20 9.90098C20.74 9.75098 21.42 9.42098 22 8.98098V18.001C22 19.101 21.1 20.001 20 20.001H4C2.9 20.001 2 19.101 2 18.001V6.00098C2 4.90098 2.9 4.00098 4 4.00098H14.1C14.04 4.32098 14 4.66098 14 5.00098C14 5.34098 14.04 5.68098 14.1 6.00098H4L12 11.001L15.67 8.71098C16.14 9.14098 16.69 9.47098 17.3 9.69098L12 13.001L4 8.00098V18.001H20V9.90098Z"
                    />
                    <path
                        fill="currentColor"
                        d="M19 8.00098C17.34 8.00098 16 6.66098 16 5.00098C16 3.34098 17.34 2.00098 19 2.00098C20.66 2.00098 22 3.34098 22 5.00098C22 6.66098 20.66 8.00098 19 8.00098Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconMessageOutlined;
