import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconFlashlight = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M2.50094 9.5L4.75094 11.75V15.5H9.25094V11.75L11.5009 9.5V5.75H2.50094V9.5ZM4.00094 7.25H10.0009V8.8775L7.75094 11.1275V14H6.25094V11.1275L4.00094 8.8775V7.25ZM6.25094 0.5H7.75094V2.75H6.25094V0.5ZM0.627441 3.4055L1.68794 2.345L3.27944 3.935L2.21894 4.99625L0.627441 3.4055ZM10.7209 3.9365L12.3132 2.3465L13.3729 3.4085L11.7807 4.9985L10.7209 3.9365Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconFlashlight;
