export default (apiURL, methods) => {
    return {
        getVideoCaptionURL(params = {}) {
            const session = params.session || "";
            return `${apiURL}/videos/${params.id}/caption?session_id=${session}`;
        },

        getJuiceVideo(params = {}) {
            const url = `${apiURL}/videos/${params.videoId}`;

            return methods.get(url, {
                session_id: params.session,
                video_id: params.videoId,
            });
        },
    };
};
