export default (apiURL, methods) => {
    return {
        getStory(params = {}) {
            // NOTE: action=get_news_by_id
            const url = `${apiURL}/juice-stories/${params.storyId}`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        getStoryByStudent(params = {}) {
            // NOTE: action=get_student_news_by_id
            const url = `${apiURL}/juice-stories/${params.storyId}/by-student`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },
    };
};
