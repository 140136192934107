import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const TooltipInfo = (props) => {
    const { isLeft, isRight } = props;

    /* --- */

    const renderArrow = () => {
        if (props.isArrowDown) {
            const arrowClassName = classNames({
                [styles.arrowDown]: true,
                [styles.arrowLeft]: isLeft,
                [styles.arrowRight]: isRight,
            });

            return (
                <div className={arrowClassName} />
            );
        }

        const arrowClassName = classNames({
            [styles.arrowUp]: true,
            [styles.arrowLeft]: isLeft,
            [styles.arrowRight]: isRight,
        });

        return (
            <div className={arrowClassName} />
        );
    };

    /* --- */

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentCenter]: props.isTextCentered,
    });

    return (
        <div className={styles.tooltip}>
            {renderArrow()}
            <div className={contentClassName}>
                {props.children}
            </div>
        </div>
    );
};

TooltipInfo.defaultProps = {
    children: null,
    isTextCentered: false,
    isArrowDown: false,
    isRight: false,
    isLeft: false,
};


export default TooltipInfo;
