import React, { useCallback } from "react";

import classNames from "@/base/lib/class-names.js";

import TooltipInfoWithIcon from "@/base/components/tooltip-info-with-icon/index.js";
import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const TabsV2 = (props) => {
    const getSelectedTabIndex = () => {
        for (let i = 0; i < props.tabs.length; i += 1) {
            const value = props.tabs[i].value || "";

            if (props.selectedTab === value) {
                return i;
            }
        }

        return 0;
    };

    const getUnderlineStyle = () => {
        const tabWidth = 100 / props.tabs.length;
        const selectedIndex = getSelectedTabIndex();

        return {
            width: `${tabWidth}%`,
            left: `${tabWidth * selectedIndex}%`,
        };
    };

    /* --- */

    const onChange = useCallback((value, isDisabled) => {
        if (isDisabled) {
            return;
        }

        props.onChange(value);
    }, [props.onChange]);

    /* --- */

    const renderTooltip = (value) => {
        if (!props.tooltips[value]) {
            return null;
        }

        return (
            <TooltipInfoWithIcon
                tooltip={{
                    message: props.tooltips[value],
                }}
            />
        );
    };

    const renderIconBefore = (tab, isSelected) => {
        if (!tab.iconBefore) {
            return null;
        }

        const iconProps = {
            isSky: false,
            isOrange: false,
        };

        if (isSelected) {
            if (props.isOrange) {
                iconProps.isOrange = true;
            } else {
                iconProps.isSky = true;
            }
        }

        return (
            <tab.iconBefore
                isSky={iconProps.isSky}
                isOrange={iconProps.isOrange}
            />
        );
    };

    const renderIconAfter = (tab) => {
        if (!tab.iconAfter) {
            return null;
        }

        return (
            <tab.iconAfter
                isGreen
            />
        );
    };

    const renderCount = (count) => {
        const isBigNumber = count >= 100;

        const countClassName = classNames({
            [styles.tabLabelCount]: true,
            [styles.tabLabelCountSmallFont]: isBigNumber,
        });

        return (
            <div className={countClassName}>
                {isBigNumber ? "99+" : count}
            </div>
        );
    };

    const renderTabLabel = (tab) => {
        let countOrNotification = null;

        if (tab.withNotification) {
            countOrNotification = (
                <div className={styles.tabLabelNotification} />
            );
        } else if (tab.count) {
            countOrNotification = renderCount(tab.count);
        }

        return (
            <div className={styles.tabLabelContainer}>
                <div className={styles.tabLabel}>
                    {tab.label}
                </div>
                {countOrNotification}
            </div>
        );
    };

    /* --- */

    const renderTabs = () => {
        const tabs = props.tabs.map((tab) => {
            const isSelected = props.selectedTab === tab.value;
            const { isDisabled } = tab;

            const tabClassName = classNames({
                [styles.tab]: true,
                [styles.tabSelected]: isSelected,
                [styles.tabSelectedOrange]: isSelected && props.isOrange,
                [styles.tabDisabled]: isDisabled,
            });

            return (
                <DivButton
                    key={tab.value}
                    dataComment={tab.dataComment || ""}
                    className={tabClassName}
                    onClick={() => {
                        onChange(tab.value, isDisabled);
                    }}
                >
                    {renderIconBefore(tab, isSelected)}
                    {renderTabLabel(tab)}
                    {renderIconAfter(tab)}
                    {renderTooltip(tab.value)}
                </DivButton>
            );
        });

        return (
            <div className={styles.tabs}>
                {tabs}
            </div>
        );
    };

    const renderUnderline = () => {
        const underlineClassName = classNames({
            [styles.underline]: true,
            [styles.underlineOrange]: props.isOrange,
        });

        return (
            <div
                className={underlineClassName}
                style={getUnderlineStyle()}
            />
        );
    };

    return (
        <div
            className={styles.tabsCommon}
            data-comment={props.dataComment}
        >
            {renderTabs()}
            {renderUnderline()}
        </div>
    );
};

TabsV2.defaultProps = {
    dataComment: "",
    tabs: [],
    selectedTab: 0,
    tooltips: {},
    onChange: () => { },
    isOrange: false,
};

export default TabsV2;
