import * as types from "./types.js";


const initialState = {
    isCountriesLoaded: false,
    countries: [],

    subdivisionsByAlpha2: {},

    districtsBySubdivision: {},
    citiesBySubdivision: {},

    schoolsByDistrictId: {},
    schoolsByCity: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_COUNTRIES: {
            return {
                ...state,
                isCountriesLoaded: true,
                countries: action.payload.countries,
            };
        }

        case types.SET_SUBDIVISIONS_BY_APLHA2_LOADING: {
            return {
                ...state,
                subdivisionsByAlpha2: {
                    ...state.subdivisionsByAlpha2,
                    [action.payload.alpha2]: {
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_SUBDIVISIONS_BY_APLHA2: {
            return {
                ...state,
                subdivisionsByAlpha2: {
                    ...state.subdivisionsByAlpha2,
                    [action.payload.alpha2]: {
                        isLoaded: true,
                        subdivisionName: action.payload.subdivisionName,
                        subdivisions: action.payload.subdivisions,
                    },
                },
            };
        }

        /* ------- */

        case types.SET_DISTRICTS_BY_SUBDIVISION: {
            const { districts, subdivision } = action.payload;

            return {
                ...state,
                districtsBySubdivision: {
                    ...state.districtsBySubdivision,
                    [subdivision]: [...districts],
                },
            };
        }

        /* ------- */

        case types.SET_CITIES_BY_SUBDIVISION: {
            const { cities, subdivision } = action.payload;

            return {
                ...state,
                citiesBySubdivision: {
                    ...state.citiesBySubdivision,
                    [subdivision]: [...cities],
                },
            };
        }

        /* ------- */

        case types.SET_SCHOOLS_BY_CITY: {
            const { schools, city } = action.payload;

            return {
                ...state,
                schoolsByCity: {
                    ...state.schoolsByCity,
                    [city]: [...schools],
                },
            };
        }

        /* ------- */

        case types.SET_SCHOOLS_BY_DISTRICT_ID: {
            const { schools, districtId } = action.payload;

            return {
                ...state,
                schoolsByDistrictId: {
                    ...state.schoolsByDistrictId,
                    [districtId]: [...schools],
                },
            };
        }

        /* ------- */

        default: {
            return state || { ...initialState };
        }
    }
};
