import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    scoreReportByClassId: {},
    scoreReportByClassIdByStandards: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENTS_SCORE_REPORT_LOADING: {
            const {
                classId,
                standardIds,
                dateFrom,
                dateTo,
            } = action.payload;

            const range = `${dateFrom}-${dateTo}`;
            const standards = standardIds.join("-");

            const data = {
                error: "",
                isLoading: true,
            };

            if (standards) {
                const scoreReportByClassIdByStandards = { ...state.scoreReportByClassIdByStandards };
                const prevByClassId = scoreReportByClassIdByStandards?.[classId] || {};
                const prevByRange = prevByClassId[range] || {};

                return {
                    ...state,
                    scoreReportByClassIdByStandards: {
                        ...scoreReportByClassIdByStandards,
                        [classId]: {
                            ...prevByClassId,
                            [range]: {
                                ...prevByRange,
                                [standards]: data,
                            },
                        },
                    },
                };
            }

            const scoreReportByClassId = { ...state.scoreReportByClassId };
            const prevByClassId = scoreReportByClassId?.[classId] || {};

            return {
                ...state,
                scoreReportByClassId: {
                    ...scoreReportByClassId,
                    [classId]: {
                        ...prevByClassId,
                        [range]: data,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_SCORE_REPORT: {
            const {
                classId,
                standardIds,
                students,
                error,
                summary,
                dateFrom,
                dateTo,
            } = action.payload;

            const range = `${dateFrom}-${dateTo}`;
            const standards = standardIds.join("-");

            const data = {
                students,
                summary,
                error,
                isLoading: false,
                isLoaded: true,
            };

            if (standards) {
                const scoreReportByClassIdByStandards = { ...state.scoreReportByClassIdByStandards };
                const prevByClassId = scoreReportByClassIdByStandards?.[classId] || {};
                const prevByRange = prevByClassId[range] || {};

                return {
                    ...state,
                    scoreReportByClassIdByStandards: {
                        ...scoreReportByClassIdByStandards,
                        [classId]: {
                            ...prevByClassId,
                            [range]: {
                                ...prevByRange,
                                [standards]: data,
                            },
                        },
                    },
                };
            }

            const scoreReportByClassId = { ...state.scoreReportByClassId };
            const prevByClassId = scoreReportByClassId?.[classId] || {};

            return {
                ...state,
                scoreReportByClassId: {
                    ...scoreReportByClassId,
                    [classId]: {
                        ...prevByClassId,
                        [range]: data,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
