import React from "react";

import IconAODTablet from "@/base/icons/aod-tablet/index.js";
import IconPlayerHeadphone from "@/base/icons/player-headphone/index.js";
import IconTranslate from "@/base/icons/translate/index.js";

import ButtonBig from "@/base/components/button-big/index.js";
import ButtonCircleToggler from "@/base/components/button-circle-toggler/index.js";
import JuiceContentWithTranslation from "@/base/components/juice-content-with-translation/index.js";
import ToggleDropdown from "@/base/components/toggle-dropdown/index.js";
import RateWithLike from "@/base/components/rate-with-like/index.js";

import styles from "./styles.module.css";


const ContentExtraJuice = (props) => {
    const renderListenButton = () => {
        return (
            <ButtonBig
                icon={IconPlayerHeadphone}
                onClick={props.onListen}
                isOrangeTheme
                isRounded
            >
                Listen
            </ButtonBig>
        );
    };

    const renderTranslationButton = () => {
        if (!props.withTranslation) {
            return null;
        }

        const translation = props.translation || {};

        return (
            <ToggleDropdown
                name="Translate"
                selectedOption={translation.language}
                options={translation.languages}
                iconToggle={<IconTranslate isOrange />}
                onChange={props.onTranslate}
                isChecked={translation.isChecked}
                isCentered
            />
        );
    };

    const renderReadingModeButton = () => {
        if (!props.withReadingModeButton) {
            return null;
        }

        return (
            <ButtonCircleToggler
                icon={IconAODTablet}
                onClick={props.onReadingModeChange}
                isChecked={props.isDyslexic}
            />
        );
    };

    const renderControls = () => {
        return (
            <div className={styles.controls}>
                {renderListenButton()}
                {renderTranslationButton()}
                {renderReadingModeButton()}
            </div>
        );
    };

    const renderJuiceContent = () => {
        const contentHighlight = props.highlight?.isContent
            ? props.highlight
            : null;

        return (
            <JuiceContentWithTranslation
                storyId={props.extraJuiceId}
                content={props.content}
                highlight={contentHighlight}
                onWordClick={props.onWordClick}
                /* --- */
                translation={props.translation?.translation}
                translationDirection={props.translation?.direction}
                translationError={props.translation?.error}
                isTranslationLoading={props.translation?.isLoading}
                isTranslated={props.translation?.isChecked}
                /* --- */
                isDyslexic={props.isDyslexic}
                isDyslexicFont={props.isDyslexicFont}
                isMobile
            />
        );
    };

    const renderRate = () => {
        return (
            <div className={styles.rate}>
                <RateWithLike
                    rate={props.rate.rate}
                    error={props.rate.error}
                    loadingRatingByName={props.rate.loadingRatingByName}
                    onRate={props.rate.onRate}
                />
            </div>
        );
    };

    return (
        <div className={styles.popupContent}>
            {renderControls()}
            {renderJuiceContent()}
            {renderRate()}
        </div>
    );
};

ContentExtraJuice.defaultProps = {
    extraJuiceId: "",
    content: "",
    highlight: null,
    translation: {
        isChecked: false,
        isLoading: false,
        languages: [],
        language: "",
        translation: null,
        error: null,
    },
    rate: {
        rate: null,
        error: "",
        loadingRatingByName: "",
        onRate: () => { },
    },

    onListen: () => { },
    onTranslate: () => { },
    onReadingModeChange: () => { },
    onWordClick: () => { },

    withTranslation: false,
    withReadingModeButton: false,
    isDyslexic: false,
    isDyslexicFont: false,
};

export default ContentExtraJuice;
