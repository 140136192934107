const loadSiteDate = (services) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.site.getSiteDate();

        if (res.ok) {
            dispatch(actions.info.setSiteDate({
                date: res.date,
            }));
        }
    };
};

export default {
    loadSiteDate,
};
