import React, { useEffect } from "react";
import { createRoot } from "react-dom/client"; // eslint-disable-line import/extensions

import actions from "@/base/store/actions.js";
import actionsPopups from "@/base/actions/popups.js";

import interval from "@/base/workers/interval.js";

import EventsSocket from "./monitors/socket.js";
import configureRoutes from "./routes/index.js";
import init from "./init.js";
import api from "./api.js";

import app from "./app.js";

const createEventsSocket = (store) => {
    // eslint-disable-next-line no-undef
    const eventsSocketUrl = EVENTS_SOCKET_URL;

    if (!eventsSocketUrl) {
        // eslint-disable-next-line no-console
        console.log("[WS]: No events URL");
        return null;
    }

    return new EventsSocket(eventsSocketUrl, store);
};

const AppWithCallbackAfterRender = (props) => {
    useEffect(() => {
        init.postInit();
    }, []);

    return props.app;
};

const main = () => {
    globalThis.app = app;

    const eventsSocket = createEventsSocket(app.services.store);
    globalThis.eventsSocket = eventsSocket;

    const routes = configureRoutes({
        store: app.services.store,
        actions,
        api,
    });

    init.preInit(app);

    interval.start(() => {
        app.services.store.dispatch(actionsPopups.checkPopupsStates({
            actions,
        }));
    });

    const rootElement = document.getElementById("app");
    const root = createRoot(rootElement);

    root.render(<AppWithCallbackAfterRender app={routes} />);
};

main();
