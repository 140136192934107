import * as types from "./types.js";

/* --- */

export const openEditSchool = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_EDIT_SCHOOL,
    payload,
});

export const setSchoolEditLoading = (isLoading) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_LOADING,
    payload: { isLoading },
});

export const setSchoolEditSuccess = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS,
    payload,
});

export const clearSchoolEdit = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT,
    payload,
});

/* --- */

export const openDeleteSchool = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE,
    payload,
});

export const setSchoolDeleteLoading = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_LOADING,
    payload,
});

export const setSchoolDeleteError = (error) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_ERROR,
    payload: { error },
});

export const setSchoolDeleteSuccess = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS,
    payload,
});

export const clearSchoolDelete = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE,
    payload,
});

/* --- */

export const openEditDepartment = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT,
    payload,
});

export const setDepartmentEditLoading = (isLoading) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING,
    payload: { isLoading },
});

export const setDepartmentEditSuccess = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS,
    payload,
});

export const clearDepartmentEdit = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT,
    payload,
});

/* --- */

export const openDeleteDepartment = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE,
    payload,
});

export const setDepartmentDeleteLoading = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING,
    payload,
});

export const setDepartmentDeleteError = (error) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_ERROR,
    payload: { error },
});

export const setDepartmentDeleteSuccess = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS,
    payload,
});

export const clearDepartmentDelete = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE,
    payload,
});

/* --- */

export const openEditClass = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_CLASS_EDIT,
    payload,
});

export const setClassEditLoading = (isLoading) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_LOADING,
    payload: { isLoading },
});

export const setClassEditSuccess = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_SUCCESS,
    payload,
});

export const clearClassEdit = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_CLASS_EDIT,
    payload,
});

/* --- */

export const openDeleteClass = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_CLASS_DELETE,
    payload,
});

export const setClassDeleteLoading = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_LOADING,
    payload,
});

export const setClassDeleteError = (error) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_ERROR,
    payload: { error },
});

export const setClassDeleteSuccess = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_SUCCESS,
    payload,
});

export const clearClassDelete = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_CLASS_DELETE,
    payload,
});

/* --- */

export const openEntitiesAddSchool = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "schools",
    },
});

export const openEntitiesAddDepartment = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "departments",
    },
});

export const openEntitiesAddClass = (payload) => ({
    type: types.OPEN_DISTRICT_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "classes",
    },
});

export const setEntitiesAddLoading = (isLoading) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_LOADING,
    payload: { isLoading },
});

export const setEntitiesAddDepartmentAdded = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED,
    payload,
});

export const setEntitiesAddSchoolAdded = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED,
    payload,
});

export const setEntitiesAddClassAdded = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED,
    payload,
});

export const setEntitiesAddSelectedSchool = (selectedSchool) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL,
    payload: { selectedSchool },
});

export const setEntitiesAddSelectedDepartment = (selectedDepartment) => ({
    type: types.SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT,
    payload: { selectedDepartment },
});

export const clearEntitiesAddStatuses = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_STATUSES,
    payload,
});

export const clearEntitiesAdd = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD,
    payload,
});
