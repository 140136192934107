import DOMPurify from "dompurify";


const isEmptyContent = (text) => {
    const cleanText = DOMPurify.sanitize(text || "", {
        ALLOWED_TAGS: [],
    });

    return cleanText.trim() === "";
};

export default {
    isEmptyContent,
};
