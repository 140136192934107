import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconCopyWithArrow = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M2.3335 4H4.3335V7H14.3335V4H16.3335V10H18.3335V4C18.3335 2.9 17.4335 2 16.3335 2H12.1535C11.7335 0.84 10.6335 0 9.3335 0C8.0335 0 6.9335 0.84 6.5135 2H2.3335C1.2335 2 0.333496 2.9 0.333496 4V18C0.333496 19.1 1.2335 20 2.3335 20H7.3335V18H2.3335V4ZM9.3335 2C9.8835 2 10.3335 2.45 10.3335 3C10.3335 3.55 9.8835 4 9.3335 4C8.7835 4 8.3335 3.55 8.3335 3C8.3335 2.45 8.7835 2 9.3335 2Z"
                        fill="currentColor"
                    />
                    <path
                        d="M15.3435 12L13.9235 13.41L15.5035 14.99H9.3335V16.99H15.5035L13.9235 18.58L15.3435 19.99L19.3335 15.99L15.3435 12Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCopyWithArrow;
