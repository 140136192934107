export default (apiURL, methods) => {
    return {
        getUserPaymentDetails(params = {}) {
            // NOTE: action=payment_get_user_details
            const url = `${apiURL}/payment/details`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        /* --- */

        getPlans() {
            // NOTE: action=payment_get_plans
            const url = `${apiURL}/payment/plans`;

            return methods.get3(url, {});
        },

        getActivePlans() {
            // NOTE: action=payment_get_all_active_plans
            const url = `${apiURL}/payment/plans/active`;

            return methods.get3(url, {});
        },

        getPlanBySession(params = {}) {
            // NOTE: action=get_payment_referral_plan_by_session
            const url = `${apiURL}/payment/plan-by-session`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        createPaymentIntentByPlan(params = {}) {
            // NOTE: action=payment_create_subscription
            const url = `${apiURL}/payment/intent/create`;

            return methods.post(url, {
                session_id: params.session,
                plan: params.plan,
                coupon: params.coupon,
                is_auto_renew: params.isAutoRenew,
            });
        },

        getPaymentDetails(params = {}) {
            // NOTE: action=payment_get_intent_details
            const url = `${apiURL}/payment/intent/details`;

            return methods.post(url, {
                session_id: params.session,
                intent_external_id: params.intentId,
            });
        },

        paymentCompleted(params = {}) {
            // NOTE: action=payment_completed
            const url = `${apiURL}/payment/hook/completed`;

            return methods.post(url, {
                session_id: params.session,
                intent_external_id: params.intentId,
            });
        },

        paymentCompleteWithDiscount100(params = {}) {
            // NOTE: action=payment_complete_100_percent_discount
            const url = `${apiURL}/payment/hook/completed-with-discount-100`;

            return methods.post(url, {
                session_id: params.session || "",
                subscription_id: params.subscriptionId || "",
            });
        },

        paymentLogActivity(params = {}) {
            // NOTE: action=payment_log_activity
            const url = `${apiURL}/payment/log/activity`;

            return methods.post(url, {
                session_id: params.session,
                intent_external_id: params.intentId,
                body: params.body,
            });
        },

        paymentRequestQuote(params = {}) {
            // NOTE: action=payment_request_quote
            const url = `${apiURL}/payment/plans/request-quote`;

            return methods.post(url, {
                session_id: params.session,
                role: params.role,
                phone: params.phone,
                message: params.message,
            });
        },

        subscriptionChangeAutoRenew(params = {}) {
            // NOTE: action=subscription_change_auto_renew
            const url = `${apiURL}/payment/subscription/auto-renew`;

            return methods.get3(url, {
                session_id: params.session,
                subscription_enc_id: params.subscriptionEncId,
                value: params.value,
            });
        },

        paymentAttachPaymentMethod(params = {}) {
            // NOTE: action=payment_stripe_attach_pm_to_customer
            const url = `${apiURL}/payment/method/attach`;

            return methods.post(url, {
                session_id: params.session,
                pm_id: params.paymentMethodId,
            });
        },

        subscriptionApplyCoupon(params = {}) {
            // NOTE: action=check_coupon
            const url = `${apiURL}/payment/plans/coupons/apply`;

            return methods.get(url, {
                session_id: params.session,
                coupon: params.coupon,
                plan: params.plan,
            });
        },

        cancelSubscription(params = {}) {
            // NOTE: action=account_manual_cancellation
            const url = `${apiURL}/payment/subscription/cancel`;

            return methods.post(url, {
                session_id: params.session,
                cancel_type: params.cancelType,
                cancellation_reason: params.cancelReason,
                cancellation_message: params.cancelReasonMessage,
                cancel_preference: params.cancelPreference,
            });
        },
    };
};
