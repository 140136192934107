import React from "react";

import styles from "./styles.module.css";


const Control = (props) => {
    return (
        <div
            className={styles.controlsButton}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <props.icon
                isOrange
            />
            <div>
                {props.title}
            </div>
        </div>
    );
};

Control.defaultProps = {
    title: "",
    icon: null,
    onClick: () => { },
};

export default Control;
