import text from "@/base/text/index.js";


const moveSchool = (services, params) => {
    const { api, actions } = services;
    const {
        districtId,
        schoolId,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiAdminPopups.setSchoolMoveLoading(true));

        const res = await api.admin.moveSchool({
            session: state.user.session,
            districtId,
            schoolId,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.uiAdminPopups.setSchoolMoveSuccess());
        } else {
            error = res.error || text.error;
            dispatch(actions.uiAdminPopups.setSchoolMoveLoading(false));
        }

        return {
            error,
        };
    };
};

export default {
    moveSchool,
};
