import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconDepartment = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="22"
                    height="15"
                    viewBox="0 0 22 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M20 0.996094V14.9961H22V0.996094H20ZM16 14.9961H18V0.996094H16V14.9961ZM13 0.996094H1C0.45 0.996094 0 1.44609 0 1.99609V13.9961C0 14.5461 0.45 14.9961 1 14.9961H13C13.55 14.9961 14 14.5461 14 13.9961V1.99609C14 1.44609 13.55 0.996094 13 0.996094ZM7 3.74609C8.24 3.74609 9.25 4.75609 9.25 5.99609C9.25 7.23609 8.24 8.24609 7 8.24609C5.76 8.24609 4.75 7.23609 4.75 5.99609C4.75 4.75609 5.76 3.74609 7 3.74609ZM11.5 12.9961H2.5V12.2461C2.5 10.7461 5.5 9.99609 7 9.99609C8.5 9.99609 11.5 10.7461 11.5 12.2461V12.9961Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconDepartment;
