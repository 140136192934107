import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";

import IconInformation from "@/base/icons/information/index.js";

import IconJumping from "@/base/components/icon-jumping/index.js";
import Tooltip from "@/base/components/tooltip/index.js";

import styles from "./styles.module.css";

const TOOLTIP_POSITIONS = {
    bottomCenter: "bottomCenter",
    bottomLeft: "bottomLeft",
    rightCenter: "rightCenter",
};

const InfoTooltip = (props) => {
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

    const onShowTooltip = (evt) => {
        setIsVisibleTooltip(true);
        evt.stopPropagation();
    };

    const onCloseTooltip = () => {
        setIsVisibleTooltip(false);
    };

    const onToggleTooltip = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    /* --- */

    useGlobalClose(() => {
        setIsVisibleTooltip(false);
    }, []);

    /* --- */

    const renderTooltip = () => {
        if (!isVisibleTooltip || !props.tooltip) {
            return null;
        }

        const tooltipClassName = classNames({
            [styles.tooltip]: true,
            [styles.tooltipBottomCenter]: TOOLTIP_POSITIONS.bottomCenter === props.position,
            [styles.tooltipBottomLeft]: TOOLTIP_POSITIONS.bottomLeft === props.position,
            [styles.tooltipRightCenter]: TOOLTIP_POSITIONS.rightCenter === props.position,
        });

        return (
            <div className={tooltipClassName}>
                <Tooltip>
                    {props.tooltip}
                </Tooltip>
            </div>
        );
    };

    return (
        <div
            className={styles.infoTooltip}
            onClick={onToggleTooltip}
            onKeyPress={onToggleTooltip}
            role="button"
            tabIndex="-1"
        >
            <div
                onMouseOver={onShowTooltip}
                onFocus={onShowTooltip}
                onMouseOut={onCloseTooltip}
                onBlur={onCloseTooltip}
            >
                <IconJumping>
                    <IconInformation
                        isRed
                    />
                </IconJumping>
            </div>
            {renderTooltip()}
        </div>
    );
};

InfoTooltip.defaultProps = {
    tooltip: "",
    position: "",
};

export default InfoTooltip;
