import * as types from "./types.js";

const setStateStandardsByState = (payload) => ({
    type: types.SET_STATE_STANDARDS_BY_STATE,
    payload,
});

const setStandardsByGroupsLoading = (payload) => ({
    type: types.SET_STANDARDS_BY_GROUPS_LOADING,
    payload,
});

const setStandardsByGroups = (payload) => ({
    type: types.SET_STANDARDS_BY_GROUPS,
    payload,
});

export {
    setStateStandardsByState,
    setStandardsByGroupsLoading,
    setStandardsByGroups,
};
