import React, { useState } from "react";

import date from "@/base/lib/date.js";

import text from "@/base/text/index.js";

import NotificationBadge from "@/base/components/notification-badge/index.js";
import ButtonBig from "@/base/components/button-big/index.js";

import styles from "./styles.module.css";


const NotificationBlock = (props) => {
    const [isMaskAsReadLoading, setIsMarkAsReadLoading] = useState(false);

    /* --- */

    const onMarkAsRead = () => {
        setIsMarkAsReadLoading(true);

        const callbacks = {
            setLoaded() {
                setIsMarkAsReadLoading(false);
            },
        };

        props.onMarkAsRead(props.id, callbacks);
    };

    /* --- */

    const renderReadMore = () => {
        if (!props.isContentExists) {
            if (props.isViewed) {
                return null;
            }

            return (
                <ButtonBig
                    onClick={onMarkAsRead}
                    outlined
                    isRounded
                    isDefaultBorder
                    disabled={isMaskAsReadLoading}
                >
                    {isMaskAsReadLoading ? "Loading..." : "Mark as read"}
                </ButtonBig>
            );
        }

        return (
            <ButtonBig
                onClick={() => {
                    props.onReadMore(props.id);
                }}
                outlined
                isRounded
                isDefaultBorder
            >
                {text.readMore}
            </ButtonBig>
        );
    };

    const renderTitle = () => {
        return (
            <div className={styles.notificationBlockTitle}>
                {props.title}
                <NotificationBadge
                    isVisible={!props.isViewed}
                />
            </div>
        );
    };

    const renderImage = () => {
        if (!props.imageUrl) {
            return null;
        }

        return (
            <img
                className={styles.notificationBlockImage}
                src={props.imageUrl}
                alt="Notification"
            />
        );
    };

    return (
        <div className={styles.notificationBlock}>
            {renderImage()}
            <div>
                {renderTitle()}
                <div className={styles.notificationBlockDate}>
                    {date.tryFormatDateUTC(props.date, date.formatMonthDayYearDate)}
                </div>
            </div>
            {renderReadMore()}
        </div>
    );
};

NotificationBlock.defaultProps = {
    id: null,
    imageUrl: "",
    title: "",
    date: "",
    onReadMore: () => { },
    onMarkAsRead: () => { },
    isContentExists: false,
    isViewed: false,
};

export default NotificationBlock;
