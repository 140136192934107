import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconShare = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M15.75 14.7499C14.9583 14.7499 14.25 15.0624 13.7083 15.552L6.28125 11.2291C6.33333 10.9895 6.375 10.7499 6.375 10.4999C6.375 10.2499 6.33333 10.0103 6.28125 9.77075L13.625 5.4895C14.1875 6.01033 14.9271 6.33325 15.75 6.33325C17.4792 6.33325 18.875 4.93742 18.875 3.20825C18.875 1.47909 17.4792 0.083252 15.75 0.083252C14.0208 0.083252 12.625 1.47909 12.625 3.20825C12.625 3.45825 12.6667 3.69783 12.7188 3.93742L5.375 8.21867C4.8125 7.69784 4.07292 7.37492 3.25 7.37492C1.52083 7.37492 0.125 8.77075 0.125 10.4999C0.125 12.2291 1.52083 13.6249 3.25 13.6249C4.07292 13.6249 4.8125 13.302 5.375 12.7812L12.7917 17.1145C12.7396 17.3333 12.7083 17.5624 12.7083 17.7916C12.7083 19.4687 14.0729 20.8333 15.75 20.8333C17.4271 20.8333 18.7917 19.4687 18.7917 17.7916C18.7917 16.1145 17.4271 14.7499 15.75 14.7499Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconShare;
