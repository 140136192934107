import React from "react";

import text from "@/base/text/index.js";

import ButtonBig from "@/base/components/button-big/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";

import styles from "./styles.module.css";


const Controls = (props) => {
    const renderError = () => {
        return (
            <MessageDanger>
                {props.error}
            </MessageDanger>
        );
    };

    const renderTryAgainButton = () => {
        return (
            <ButtonBig
                onClick={(evt) => {
                    evt.stopPropagation();
                    props.onLoadMore();
                }}
                outlined
                isRounded
                isDefaultBorder
            >
                {text.tryAgain}
            </ButtonBig>
        );
    };

    if (props.isLoading) {
        return (
            <RequestLoader />
        );
    }

    if (!props.error) {
        return null;
    }

    return (
        <div className={styles.controls}>
            {renderError()}
            {renderTryAgainButton()}
        </div>
    );
};

Controls.defaultProps = {
    error: "",
    onLoadMore: () => { },
    isLoading: false,
};

export default Controls;
