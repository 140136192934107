import axios from "axios";
import qs from "qs";

import sleep from "./sleep.js";


const innerGet = async (url, params = {}) => {
    try {
        const response = await axios.get(url, { params });

        if (response.statusText === "Failed to fetch") {
            return [false, {}];
        }

        return [true, response.data];
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return [false, {}];
    }
};

export const get = async (url, params = {}) => {
    const data = await innerGet(url, params);
    return data[1];
};

export const get3 = async (url, params = {}) => {
    const sleepSeconds = 2;

    const [isValid1, response1] = await innerGet(url, params);

    if (isValid1) {
        return response1;
    }

    await sleep.sleepSeconds(sleepSeconds);

    const [isValid2, response2] = await innerGet(url, params);

    if (isValid2) {
        return response2;
    }

    await sleep.sleepSeconds(sleepSeconds);

    const [isValid3, response3] = await innerGet(url, params);

    if (isValid3) {
        return response3;
    }

    return {};
};

export const post = async (url, params = {}) => {
    try {
        const response = await axios.post(url, qs.stringify(params));
        return response.data;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return {};
    }
};

export const postForm = async (url, params = {}) => {
    try {
        const formData = new FormData(); // eslint-disable-line compat/compat

        Object.keys(params).forEach((paramName) => {
            const isFile = params[paramName] instanceof window.File; // eslint-disable-line compat/compat, max-len

            if (typeof params[paramName] === "object" && !isFile) {
                const obj = params[paramName] || {};
                const keys = Object.keys(obj);

                for (let i = 0; i < keys.length; i += 1) {
                    const objParamName = `${paramName}[${keys[i]}]`;
                    formData.append(objParamName, obj[keys[i]]);
                }
            } else {
                formData.append(paramName, params[paramName]);
            }
        });

        const response = await axios.post(url, formData);
        return response.data;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return {};
    }
};

export const del = async (url, params = {}) => {
    try {
        const response = await axios.delete(url, { params });
        return response.data;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return {};
    }
};
