import text from "@/base/text/index.js";


const loadClassInfo = (services, params) => {
    const { actions, api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.studentClasses.setClassInfoLoading({
            classId,
        }));

        const state = getState();

        const res = await api.student.getClassInfo({
            session: state.user.session,
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentClasses.setClassInfo({
            classId,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

const loadClasses = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentClasses.setClassesLoading());

        const state = getState();

        const res = await api.student.getClasses({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.classes || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentClasses.setClasses({
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

export default {
    loadClassInfo,
    loadClasses,
};
