import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    entitiesAdd: {},
    districtEdit: {},
    districtDelete: {},
    schoolEdit: {},
    schoolDelete: {},
    schoolMove: {},
    departmentEdit: {},
    departmentDelete: {},
    departmentMove: {},
    classEdit: {},
    classDelete: {},
    classMove: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.OPEN_ADMIN_POPUP_EDIT_SCHOOL: {
            const { schoolId } = action.payload;

            return {
                ...state,
                schoolEdit: {
                    schoolId,
                    isVisible: true,
                    isSuccess: false,
                    isLoading: false,
                },
            };
        }

        case types.SET_ADMIN_POPUP_SCHOOL_EDIT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                schoolEdit: {
                    ...state.schoolEdit,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS: {
            return {
                ...state,
                schoolEdit: {
                    ...state.schoolEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_SCHOOL_EDIT: {
            return {
                ...state,
                schoolEdit: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_SCHOOL_DELETE: {
            const { schoolId } = action.payload;

            return {
                ...state,
                schoolDelete: {
                    schoolId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_SCHOOL_DELETE_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                schoolDelete: {
                    ...state.schoolDelete,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS: {
            return {
                ...state,
                schoolDelete: {
                    ...state.schoolDelete,
                    error: "",
                    isLoading: false,
                    isDeleted: true,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_SCHOOL_DELETE: {
            return {
                ...state,
                schoolDelete: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_DEPARTMENT_EDIT: {
            const { schoolId, departmentId } = action.payload;

            return {
                ...state,
                departmentEdit: {
                    schoolId,
                    departmentId,
                    isSuccess: false,
                    isLoading: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                departmentEdit: {
                    ...state.departmentEdit,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS: {
            return {
                ...state,
                departmentEdit: {
                    ...state.departmentEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_DEPARTMENT_EDIT: {
            return {
                ...state,
                departmentEdit: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_DEPARTMENT_DELETE: {
            const { schoolId, departmentId } = action.payload;

            return {
                ...state,
                departmentDelete: {
                    schoolId,
                    departmentId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                departmentDelete: {
                    ...state.departmentDelete,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS: {
            return {
                ...state,
                departmentDelete: {
                    ...state.departmentDelete,
                    error: "",
                    isDeleted: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_DEPARTMENT_DELETE: {
            return {
                ...state,
                departmentDelete: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_CLASS_EDIT: {
            const { schoolId, departmentId, classId } = action.payload;

            return {
                ...state,
                classEdit: {
                    schoolId,
                    departmentId,
                    classId,
                    isLoading: false,
                    isSuccess: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_EDIT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                classEdit: {
                    ...state.classEdit,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_EDIT_SUCCESS: {
            return {
                ...state,
                classEdit: {
                    ...state.classEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_CLASS_EDIT: {
            return {
                ...state,
                classEdit: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_CLASS_DELETE: {
            const { schoolId, departmentId, classId } = action.payload;

            return {
                ...state,
                classDelete: {
                    schoolId,
                    departmentId,
                    classId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_DELETE_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                classDelete: {
                    ...state.classDelete,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_DELETE_SUCCESS: {
            return {
                ...state,
                classDelete: {
                    ...state.classDelete,
                    error: "",
                    isDeleted: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_CLASS_DELETE: {
            return {
                ...state,
                classDelete: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_CLASS_MOVE: {
            const {
                districtId,
                schoolId,
                departmentId,
                classId,
                className,
            } = action.payload;

            return {
                ...state,
                classMove: {
                    defaultDistrictId: districtId,
                    defaultSchoolId: schoolId,
                    defaultDepartmentId: departmentId,
                    districtId,
                    schoolId,
                    classId,
                    className,
                    isVisible: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_MOVE_DISTRICT: {
            const { districtId } = action.payload;

            return {
                ...state,
                classMove: {
                    ...state.classMove,
                    districtId,
                    schoolId: "",
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_MOVE_SCHOOL: {
            const { schoolId } = action.payload;

            return {
                ...state,
                classMove: {
                    ...state.classMove,
                    schoolId,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_MOVE_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                classMove: {
                    ...state.classMove,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_CLASS_MOVE_SUCCESS: {
            return {
                ...state,
                classMove: {
                    ...state.classMove,
                    isLoading: false,
                    isSuccess: true,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_CLASS_MOVE: {
            return {
                ...state,
                classMove: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_DEPARTMENT_MOVE: {
            const {
                districtId,
                schoolId,
                departmentId,
                departmentName,
            } = action.payload;

            return {
                ...state,
                departmentMove: {
                    defaultDistrictId: districtId,
                    defaultSchoolId: schoolId,
                    districtId,
                    departmentId,
                    departmentName,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_MOVE_DISTRICT: {
            const { districtId } = action.payload;

            return {
                ...state,
                departmentMove: {
                    ...state.departmentMove,
                    districtId,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_MOVE_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                departmentMove: {
                    ...state.departmentMove,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DEPARTMENT_MOVE_SUCCESS: {
            return {
                ...state,
                departmentMove: {
                    ...state.departmentMove,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_DEPARTMENT_MOVE: {
            return {
                ...state,
                departmentMove: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_SCHOOL_MOVE: {
            const {
                districtId,
                schoolId,
                schoolName,
            } = action.payload;

            return {
                ...state,
                schoolMove: {
                    defaultDistrictId: districtId,
                    schoolId,
                    schoolName,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_SCHOOL_MOVE_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                schoolMove: {
                    ...state.schoolMove,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_SCHOOL_MOVE_SUCCESS: {
            return {
                ...state,
                schoolMove: {
                    ...state.schoolMove,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_SCHOOL_MOVE: {
            return {
                ...state,
                schoolMove: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_ENTITIES_ADD: {
            const {
                selectedTab,
                selectedDistrict,
                selectedSchool,
                selectedDepartment,
            } = action.payload;

            return {
                ...state,
                entitiesAdd: {
                    selectedTab,
                    selectedDistrict: selectedDistrict || "",
                    selectedSchool: selectedSchool || "",
                    selectedDepartment: selectedDepartment || "",
                    districtsSearch: {},
                    isLoading: false,
                    isDistrictAdded: false,
                    isSchoolAdded: false,
                    isDepartmentAdded: false,
                    isClassAdded: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED: {
            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    selectedSchool: action.payload.schoolId,
                    selectedDepartment: action.payload.departmentId,
                    isDepartmentAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_DISTRICT_ADDED: {
            const { selectedDistrict } = action.payload;

            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    selectedDistrict,
                    isDistrictAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED: {
            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    isSchoolAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED: {
            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    isClassAdded: true,
                    isLoading: false,
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DISTRICT: {
            const { selectedDistrict } = action.payload;

            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    selectedDistrict,
                    selectedSchool: "",
                    selectedDepartment: "",
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL: {
            const { selectedSchool } = action.payload;

            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    selectedSchool,
                    selectedDepartment: "",
                },
            };
        }

        case types.SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT: {
            const { selectedDepartment } = action.payload;

            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    selectedDepartment,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_ENTITIES_ADD_STATUSES: {
            return {
                ...state,
                entitiesAdd: {
                    ...state.entitiesAdd,
                    isDistrictAdded: false,
                    isSchoolAdded: false,
                    isDepartmentAdded: false,
                    isClassAdded: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_ENTITIES_ADD: {
            return {
                ...state,
                entitiesAdd: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_DELETE_DISTRICT: {
            const { districtId } = action.payload;

            return {
                ...state,
                districtDelete: {
                    districtId,
                    error: "",
                    isLoading: false,
                    isDeleted: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DELETE_DISTRICT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                districtDelete: {
                    ...state.districtDelete,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_DELETE_DISTRICT_SUCCESS: {
            return {
                ...state,
                districtDelete: {
                    ...state.districtDelete,
                    isDeleted: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_DELETE_DISTRICT: {
            return {
                ...state,
                districtDelete: {},
            };
        }

        /* --- */

        case types.OPEN_ADMIN_POPUP_EDIT_DISTRICT: {
            const { districtId } = action.payload;

            return {
                ...state,
                districtEdit: {
                    districtId,
                    isSuccess: false,
                    isLoading: false,
                    isVisible: true,
                },
            };
        }

        case types.SET_ADMIN_POPUP_EDIT_DISTRICT_LOADING: {
            const { isLoading } = action.payload;

            return {
                ...state,
                districtEdit: {
                    ...state.districtEdit,
                    isLoading,
                },
            };
        }

        case types.SET_ADMIN_POPUP_EDIT_DISTRICT_SUCCESS: {
            return {
                ...state,
                districtEdit: {
                    ...state.districtEdit,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ADMIN_POPUP_EDIT_DISTRICT: {
            return {
                ...state,
                districtEdit: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
