import React from "react";

import WithScroll from "@/base/components/with-scroll/index.js";
import PopupWithTitle from "@/base/components/popup-with-title/index.js";
import FivesyLetter from "@/base/components/fivesy-letter/index.js";

import styles from "./styles.module.css";


const PopupFivesyHowto = (props) => {
    const renderContentPopup = () => {
        return (
            <WithScroll className={styles.content}>
                <div className={styles.text}>
                    Guess the word in 6 tries.
                    After each guess, the color of the tiles will change to show
                    how close your guess was to the word.
                </div>

                <div className={styles.letters}>
                    <FivesyLetter value="j" isCorrect />
                    <FivesyLetter value="u" />
                    <FivesyLetter value="i" />
                    <FivesyLetter value="c" />
                    <FivesyLetter value="e" />
                </div>

                <div className={styles.text}>
                    The letter J is in the word and in the correct spot.
                </div>

                <div className={styles.letters}>
                    <FivesyLetter value="l" />
                    <FivesyLetter value="e" />
                    <FivesyLetter value="a" isPresent />
                    <FivesyLetter value="r" />
                    <FivesyLetter value="n" />
                </div>

                <div className={styles.text}>
                    The letter A is in the word but in the wrong spot.
                </div>

                <div className={styles.letters}>
                    <FivesyLetter value="w" />
                    <FivesyLetter value="o" />
                    <FivesyLetter value="r" />
                    <FivesyLetter value="d" isAbsent />
                    <FivesyLetter value="s" />
                </div>

                <div className={styles.text}>
                    The letter D is not in the word in any spot.
                </div>
            </WithScroll>
        );
    };

    return (
        <PopupWithTitle
            title="How to play"
            onClose={props.onClose}
        >
            {renderContentPopup()}
        </PopupWithTitle>
    );
};

PopupFivesyHowto.defaultProps = {
    onClose: () => { },
};

export default PopupFivesyHowto;
