const validateReferralCode = (services, params) => {
    const { api } = services;
    const { code } = params;

    return async () => {
        const res = await api.signup.validateReferralCode({
            code,
        });

        let error = "";
        let isActive = false;
        let isCreateAccountWithUsername = false;
        let isUsageLimitReached = false;

        if (res.ok) {
            isCreateAccountWithUsername = !!res.data?.createAccountWithUsername;
            isActive = !!res.data?.isActive;
        } else {
            isUsageLimitReached = !!res.isUsageLimitReached;
            error = res.error || text.error;
        }

        return {
            isCreateAccountWithUsername,
            error,
            isActive,
            isUsageLimitReached,
        };
    };
};

export default {
    validateReferralCode,
};
