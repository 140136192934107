import React from "react";

import date from "@/base/lib/date.js";
import classNames from "@/base/lib/class-names.js";

import IconPlayerHeadphone from "@/base/icons/player-headphone/index.js";
import IconCopyWithArrow from "@/base/icons/copy-with-arrow/index.js";
import IconPrint from "@/base/icons/print/index.js";

import Video from "@/base/components/video/index.js";
import FeaturedImage from "@/base/components/featured-image/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import ButtonShare from "@/base/components/button-share/index.js";
import EventsWrapper from "@/base/components/events-wrapper/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";
import JuiceContent from "@/base/components/juice-content/index.js";
import JuiceContentWithHighlight from "@/base/components/juice-content-with-highlight/index.js";
import LineWithSeparators from "@/base/components/line-with-separators/index.js";

import ContentTagsMenu from "@/base/business/content-tags-menu/index.js";
import JuiceStoryTitle from "@/base/business/juice-story-title/index.js";

import stylesDailyJuiceCommon from "@/base/business/daily-juice-common/styles.module.css";
import stylesFonts from "@/base/components/fonts/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStory = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    const onListenStory = () => {
        props.onAudioPlay();
    };

    const onShareButtonClick = (evt) => {
        evt.stopPropagation();
        props.share.onClick();
    };

    const renderFeaturedVideo = () => {
        const { featuredVideo } = story;

        let posterUrl = "";

        if (featuredVideo.featuredImage && featuredVideo.featuredImage.url) {
            posterUrl = featuredVideo.featuredImage.url;
        }

        return (
            <EventsWrapper>
                <Video
                    videoId={featuredVideo.id}
                    src={featuredVideo.url}
                    type={featuredVideo.mimeType}
                    captionSrc={props.videoCaptionSrc}
                    poster={posterUrl}
                    onPlayStart={props.onVideoPlayStart}
                    onPlayEnd={props.onVideoPlayEnd}
                    onPause={props.onVideoPlayPause}
                    canPlay={props.isVisible}
                    isDefault={props.isDefaultVideo}
                />
            </EventsWrapper>
        );
    };

    const renderFeaturedContent = () => {
        if (story?.featuredImage?.url) {
            return (
                <FeaturedImage
                    featuredImage={story.featuredImage}
                    dimensions={props.dimensions}
                    onFullscreenImage={props.onImageClick}
                />
            );
        }

        if (story?.featuredVideo?.url) {
            return renderFeaturedVideo();
        }

        return null;
    };

    const renderContentTags = () => {
        return (
            <ContentTagsMenu
                tags={story.contentTags}
                isDyslexic={props.isDyslexic}
            />
        );
    };

    const renderInfoText = (text) => {
        const textClassName = classNames({
            [stylesFonts.header]: true,
            [stylesFonts.textDyslexicBold]: props.isDyslexic,
            [stylesDailyJuiceCommon.header]: true,
        });

        return (
            <div className={textClassName}>
                {text}
            </div>
        );
    };

    const renderContentInfo = () => {
        const content = [];

        if (props.showStoryIndex && props.storyIndex !== null) {
            content.push(
                renderInfoText(`${props.storyIndex + 1}`),
                renderInfoText(story.categoryName),
            );

            const sDate = props.storyDate || story.date;
            const storyDate = date.tryFormatDate(sDate, date.formatMonthDayYearDate);

            if (storyDate) {
                content.push(renderInfoText(storyDate));
            }
        } else {
            content.push(renderInfoText(story.categoryName));
        }

        if (props.withContentTags && story?.contentTags?.length) {
            content.push(renderContentTags());
        }

        const separatorClassName = classNames({
            [stylesFonts.header]: true,
            [stylesFonts.textDyslexicBold]: props.isDyslexic,
            [stylesDailyJuiceCommon.header]: true,
        });

        return (
            <LineWithSeparators
                className={styles.category}
                separatorClassName={separatorClassName}
                content={content}
            />
        );
    };

    const renderTitle = () => {
        return (
            <JuiceStoryTitle
                title={story.title}
                highlight={props.highlight}
            />
        );
    };

    const renderShareButton = () => {
        const storyId = props?.story?.ID || "";

        if (!props.withShareButton || !storyId) {
            return null;
        }

        return (
            <ButtonShare
                googleClassroom={props.share.googleClassroom}
                onCloseMenu={props.share.onCloseMenu}
                onCopyLink={props.share.onCopyLink}
                onClick={onShareButtonClick}
                isGoogleClassroomLoading={props.share.isGoogleClassroomLoading}
                isCopyLinkLoading={props.share.isCopyLinkLoading}
                dataComment="classroom-share-button-daily-juice"
                closeMenuOnClassroomClick={props.share.closeMenuOnClassroomClick}
                closeMenuOnCopyLinkClick={props.share.closeMenuOnCopyLinkClick}
                isVisibleMenu={props.share.isVisibleMenu}
                isAuto={false}
            />
        );
    };

    const renderPrintButton = () => {
        if (!props.withPrintButton) {
            return null;
        }

        const icon = (
            <IconPrint
                isOrange
            />
        );

        return (
            <ButtonCircle
                icon={icon}
                onClick={props.onPrint}
            />
        );
    };

    const renderAssignButton = () => {
        if (!props.withAssignButton) {
            return null;
        }

        const assignIcon = (
            <IconCopyWithArrow
                isOrange
            />
        );

        return (
            <ButtonCircle
                icon={assignIcon}
                dataComment="assign-daily-juice"
                onClick={props.onAssignmentsClick}
            />
        );
    };

    const renderListenButton = () => {
        if (!props.withListenButton) {
            return null;
        }

        return (
            <ButtonBig
                icon={IconPlayerHeadphone}
                onClick={onListenStory}
                isOrangeTheme
                isRounded
            >
                Listen
            </ButtonBig>
        );
    };

    const renderControls = () => {
        if (story?.featuredVideo?.url) {
            return null;
        }

        const hasControls = props.withListenButton
            || props.withShareButton
            || props.withPrintButton
            || props.withAssignButton;

        if (!hasControls) {
            return null;
        }

        return (
            <div className={styles.playerAudioToggleButton}>
                {renderListenButton()}
                <div className={styles.controls}>
                    {renderShareButton()}
                    {renderPrintButton()}
                    {renderAssignButton()}
                </div>
            </div>
        );
    };

    const renderJuiceContent = () => {
        const commonProps = {
            className: styles.content,
            content: story.content,
            extraJuices: story.extraJuices || [],
            onWordClick: props.onWordClick,
            onExtraJuiceWordClick: (openedBy) => {
                const sId = story.ID || story.id;
                const ejs = story.extraJuices || [];
                const ejId = ejs.length > 0 ? ejs[0].id : null;

                props.onExtraJuiceWordClick(sId, ejId, openedBy);
            },
        };

        const contentHighlight = props.highlight?.isContent
            ? props.highlight
            : null;

        if (contentHighlight) {
            return (
                <JuiceContentWithHighlight
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonProps}
                    highlight={contentHighlight}
                />
            );
        }

        return (
            <JuiceContent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...commonProps}
            />
        );
    };

    return (
        <div className={styles.juiceStory}>
            {renderFeaturedContent()}

            {renderContentInfo()}
            {renderTitle()}
            {renderControls()}

            {renderJuiceContent()}
        </div>
    );
};

DailyJuiceStory.defaultProps = {
    juiceId: "",
    story: null,
    storyDate: null,
    storyIndex: 0,
    dimensions: {},
    videoCaptionSrc: null,
    share: {},
    highlight: null,
    onCopyToClipboard: () => { },
    onImageClick: () => { },
    onWordClick: () => { },
    onAudioPlay: () => { },
    onVideoPlayStart: () => { },
    onVideoPlayPause: () => { },
    onVideoPlayEnd: () => { },
    onExtraJuiceWordClick: () => { },
    onAssignmentsClick: () => { },
    onPrint: () => { },
    isDyslexic: false,
    showStoryIndex: true,
    isDefaultVideo: true,
    withListenButton: true,
    withShareButton: false,
    withAssignButton: false,
    withPrintButton: false,
    withContentTags: false,
};

export default DailyJuiceStory;
