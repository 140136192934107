import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import User from "@/base/project/user.js";
import Subscription from "@/base/project/subscription.js";

import actionsSubscription from "@/base/actions/subscription.js";
import actionsUser from "@/base/actions/user.js";
import actions from "@/base/store/actions.js";

import text from "@/base/text/index.js";

import usePopup from "@/base/hooks/use-popup/index.js";

import IconArrowBold from "@/base/icons/arrow-bold/index.js";

import PopupWindow from "@/base/components/popup-window/index.js";
import Tabs from "@/base/components/tabs/index.js";
import PopupConfirmMessage from "@/base/components/popup-confirm-message/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";
import PopupConfirmError, { usePopupConfirmError } from "@/base/components/popup-confirm-error";

import PopupFullnameChange from "@/base/business/popup-fullname-change/index.js";
import PopupProfileAvatar from "@/base/business/popup-profile-avatar/index.js";
import Snackbar, { useSnackbar } from "@/base/business/snackbar/index.js";

import UserPlans from "@/app/containers/user-plans/index.js";
import Stripe from "@/app/containers/stripe/index.js";

import api from "@/app/api.js";

import CancelSubscription, { useCancelSubscription } from "./cancel-subscription/index.js";
import PersonalInfo from "./personal-info.js";
import PlanDetails from "./plan-details.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    paymentDetails: state.user.paymentDetails,
    isPaymentDetailsLoaded: state.user.isPaymentDetailsLoaded,
    subscription: state.subscription,
    hasPrimaryClasses: state.teacher.hasPrimaryClasses,
    geo: state.geo,
});

const getPlanDetailsTabState = () => ({
    isVisibleQuoteStatus: false,
    isVisiblePaymentDetails: false,
    isVisiblePaymentChange: false,
    isPaymentSubmitted: false,
});

const PAGES = {
    plans: "plans",
    cancelSubscription: "cancelSubscription",
};

const TABS = {
    personalInfo: "personalInfo",
    planDetails: "planDetails",
};

const UserPopupAccount = (props) => {
    const [selectedTab, setSelectedTab] = useState(() => TABS.personalInfo);
    const [selectedPage, setSelectedPage] = useState(null);
    const popupConfirmError = usePopupConfirmError();

    const [isLoadingAutoRenewSubscription, setIsLoadingAutoRenewSubscription] = useState(false);
    const [isLoadingEmailNotificationStatus, setIsLoadingEmailNotificationStatus] = useState(false);

    const [planDetailsTabState, setPlanDetailsTabState] = useState(() => getPlanDetailsTabState());

    /* --- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const uploadAvatarPopup = usePopup();
    const deleteAvatarPopup = usePopup();
    const fullNamePopup = usePopup();
    const snackbar = useSnackbar();

    const cancelSubscription = useCancelSubscription();

    const isTeacher = User.hasRoleTeacher(store.user);
    const isUserHasCardDetails = User.hasCardDetails(store.paymentDetails);
    const isUserHasPlan = User.hasPlan(store.user);

    /* --- */

    const isCancelledAccountImmediately = () => {
        const { isCanceled, cancelPreference } = cancelSubscription.state;

        return isCanceled && Subscription.isCancelImmediately(cancelPreference);
    };

    /* --- */

    const getCardDetails = () => {
        return {
            name: store.paymentDetails?.defaultPaymentMethod?.card?.name || "",
            last4: store.paymentDetails?.defaultPaymentMethod?.card?.last4digits || "",
            expMonth: store.paymentDetails?.defaultPaymentMethod?.card?.expMonth || "",
            expYear: store.paymentDetails?.defaultPaymentMethod?.card?.expYear || "",
        };
    };

    const getPlans = () => {
        return {
            plans: store.subscription.plans || [],
            activePlans: store.subscription.activePlans || [],
            freeTrialPlanFeatures: store.subscription.freeTrialPlanFeatures || [],
            isActivePlansLoaded: store.subscription.isActivePlansLoaded,
            isPlansLoaded: store.subscription.isPlansLoaded,
        };
    };

    const getUserPlan = () => {
        return {
            planId: store.user?.subscription?.planId,
            daysLeftToExpire: store.user?.daysLeftToExpire || 0,
            expiration: store.user?.expiration || "",
            hasPlan: isUserHasPlan,
            isFree: User.isTypeFree(store.user),
            hasActiveCancelRequest: store.user?.hasActiveCancelRequest,
        };
    };

    /* --- */

    const loadPlans = async () => {
        dispatch(actionsSubscription.loadActivePlans({ api, actions }));
        dispatch(actionsSubscription.loadPlans({ api, actions }));
    };

    const deleteAvatar = async () => {
        deleteAvatarPopup.open();

        const res = await dispatch(actionsUser.deleteAvatar({ api, actions }));

        deleteAvatarPopup.setIsLoaded();

        const message = res.error
            ? res.error
            : text.avatarDeleted;

        deleteAvatarPopup.setMessage(message);
    };

    const uploadOrDeleteAvatar = async (evt) => {
        const file = evt?.target?.files?.[0] || null;

        if (!file) {
            deleteAvatar();
            uploadAvatarPopup.close();
            return;
        }

        uploadAvatarPopup.setIsSaving();

        const res = await dispatch(actionsUser.uploadAvatar(
            { api, actions },
            { file },
        ));

        if (res.error) {
            uploadAvatarPopup.setError(res.error);
            return;
        }

        uploadAvatarPopup.close();
    };

    const onSaveFullName = async (params) => {
        fullNamePopup.setIsSaving();

        const res = await dispatch(actionsUser.updateFullName(
            { api, actions },
            { fullName: params.value },
        ));

        if (res.error) {
            fullNamePopup.setError(res.error);
            return;
        }

        fullNamePopup.close();
        snackbar.add({ message: text.fullNameChanged });
    };

    const changeEmailNotification = async (isEnabled) => {
        setIsLoadingEmailNotificationStatus(true);

        const res = await dispatch(actionsUser.updateEmailNotifications(
            { api, actions },
            { isEnabled },
        ));

        let message = isEnabled
            ? text.emailNotificationsEnabled
            : text.emailNotificationsDisabled;

        if (res.error) {
            message = res.error;
        }

        setIsLoadingEmailNotificationStatus(false);
        snackbar.add({ message });
    };

    const onChangeAutoRenewPlan = async (isEnabled) => {
        setIsLoadingAutoRenewSubscription(true);

        const res = await dispatch(actionsUser.updateAutoSubscription(
            { api, actions },
            { isEnabled },
        ));

        let message = isEnabled
            ? text.autoRenewEnabled
            : text.autoRenewDisabled;

        if (res.error) {
            message = res.error;
        }

        setIsLoadingAutoRenewSubscription(false);
        snackbar.add({ message });
    };

    const onCancelSubscription = async () => {
        cancelSubscription.cancel();

        const { hasPlan } = getUserPlan();

        const {
            selectedReasonOptions,
            cancelReason,
            cancelPreference,
        } = cancelSubscription.state;

        const res = await dispatch(actionsSubscription.cancelSubscription(
            { api, actions },
            {
                cancelReason: selectedReasonOptions,
                cancelReasonMessage: cancelReason,
                cancelPreference,
                hasPlan,
            },
        ));

        if (res.error) {
            cancelSubscription.error(res.error);
            return;
        }

        dispatch(actions.user.setHasActiveCancelRequest());
        cancelSubscription.success();
    };

    /* --- */

    const onTabChange = (value) => {
        if (value === TABS.planDetails) {
            loadPlans();
        }

        setSelectedTab(value);
    };

    /* --- */

    const onUpgradePlan = () => {
        setSelectedPage(PAGES.plans);
    };

    const onCancelPlan = () => {
        const { hasPlan, hasActiveCancelRequest } = getUserPlan();

        if (hasActiveCancelRequest) {
            popupConfirmError.open(text.cancellationHasAlreadyMade);
            return;
        }

        const initCancelPref = Subscription.getDefaultCancelPreference(hasPlan);
        cancelSubscription.setCancelOption(initCancelPref);

        setSelectedPage(PAGES.cancelSubscription);
    };

    const onClosePlans = () => {
        onTabChange(TABS.personalInfo);
        setSelectedPage(null);
        setPlanDetailsTabState(getPlanDetailsTabState());
    };

    /* --- */

    const onPlansSetIsPaymentVisible = (isVisible) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isVisiblePaymentDetails: isVisible,
        }));
    };

    const onPlansSetIsQuoteStatusVisible = (isVisible) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isVisibleQuoteStatus: isVisible,
        }));
    };

    const onPlansSetIsPaymentChangeVisible = (isVisible) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isVisiblePaymentChange: isVisible,
        }));
    };

    const onPlansSetIsPaymentSubmitted = (isSubmitted) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isPaymentSubmitted: isSubmitted,
        }));
    };

    /* --- */

    const onBackByArrow = () => {
        if (planDetailsTabState.isVisiblePaymentChange) {
            onPlansSetIsPaymentChangeVisible(false);
            return;
        }

        if (planDetailsTabState.isVisiblePaymentDetails) {
            onPlansSetIsPaymentVisible(false);
            return;
        }

        /* --- */

        setPlanDetailsTabState(getPlanDetailsTabState());

        cancelSubscription.reset();

        setSelectedPage(null);
    };

    /* --- */

    const onClose = () => {
        if (isCancelledAccountImmediately()) {
            props.onCloseAndSignOut();
            return;
        }

        props.onClose();
    };

    /* --- */

    const renderTabs = () => {
        if (!isTeacher || store.user.isLmsUser) {
            return null;
        }

        const tabs = [
            {
                label: "Personal Info",
                value: TABS.personalInfo,
            },
            {
                label: "Plan Details",
                value: TABS.planDetails,
            },
        ];

        return (
            <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                onChange={onTabChange}
                isBlueTheme
                onlyTabs
            />
        );
    };

    /* --- */

    const renderPersonalInfo = () => {
        const emailNotification = {
            onChange: changeEmailNotification,
            enabled: store.user?.settings?.isEmailNotificationEnabled || false,
            isLoading: isLoadingEmailNotificationStatus,
        };

        return (
            <PersonalInfo
                disabledDeleteAvatar={store.user.avatarUrl === ""}
                avatarIconName={props.avatarIconName}
                avatarUrl={store.user.avatarUrl}
                fullName={store.user.fullName}
                userName={store.user.userName}
                email={store?.user?.email || "email is missing"}
                district={store.user.district}
                school={store.user.school}
                department={store.user.department}
                onOpenUploadAvatar={uploadAvatarPopup.open}
                emailNotification={emailNotification}
                onContactSupport={props.onContactSupport}
                onEditEmail={props.onEditEmail}
                onEditPassword={props.onEditPassword}
                onEditFullName={fullNamePopup.open}
                onDeleteAvatar={deleteAvatar}
                isLMS={store.user.isLmsUser}
            />
        );
    };

    const renderPlanDetails = () => {
        const subscription = {
            isLoadingAutoRenewSubscription,
            isAutoRenewEnabled: store.user.subscription?.isAutoRenewEnabled || false,
            expireDate: store.user.subscription?.expiredDateFormatted || "",
            onUpgrade: onUpgradePlan,
            onCancel: onCancelPlan,
            onChangeAutoRenew: onChangeAutoRenewPlan,
        };

        const user = getUserPlan();
        const plans = getPlans();

        return (
            <PlanDetails
                user={user}
                plans={plans}
                subscription={subscription}
                onContactSupport={props.onContactSupport}
                hasPrimaryClasses={store.hasPrimaryClasses}
            />
        );
    };

    const renderTabContent = () => {
        if (selectedTab === TABS.personalInfo) {
            return renderPersonalInfo();
        }

        if (selectedTab === TABS.planDetails) {
            return renderPlanDetails();
        }

        return null;
    };

    const renderPlans = () => {
        return (
            <Stripe>
                <UserPlans
                    plans={store.subscription.plans}
                    userPlanId={store.user.subscription.planId}
                    card={getCardDetails()}
                    onLoadUser={props.onLoadUser}
                    onSetIsVisiblePaymentDetails={onPlansSetIsPaymentVisible}
                    onSetIsVisibleQuoteStatus={onPlansSetIsQuoteStatusVisible}
                    onSetIsVisiblePaymentChange={onPlansSetIsPaymentChangeVisible}
                    onSetIsPaymentSubmitted={onPlansSetIsPaymentSubmitted}
                    onClose={onClosePlans}
                    onFullClose={onClose}
                    hasCardDetails={isUserHasCardDetails}
                    isVisiblePaymentDetails={planDetailsTabState.isVisiblePaymentDetails}
                    isVisibleQuoteStatus={planDetailsTabState.isVisibleQuoteStatus}
                    isVisiblePaymentChange={planDetailsTabState.isVisiblePaymentChange}
                />
            </Stripe>
        );
    };

    const renderCancelSubscription = () => {
        const plans = getPlans();
        const userPlan = getUserPlan();

        return (
            <CancelSubscription
                userPlan={userPlan}
                step={cancelSubscription.state.step}
                cancelReason={cancelSubscription.state.cancelReason}
                error={cancelSubscription.state.error}
                plans={plans}
                cancelPreference={cancelSubscription.state.cancelPreference}
                selectedReasonOptions={cancelSubscription.state.selectedReasonOptions}
                onSelectCancelPreference={cancelSubscription.setCancelOption}
                onChangeStep={cancelSubscription.changeStep}
                onChangeCancelReason={cancelSubscription.setCancelReason}
                onToggleReasonOption={cancelSubscription.toggleReasonOption}
                onCancelSubscription={onCancelSubscription}
                onClose={onClose}
                isCancelling={cancelSubscription.state.isCancelling}
            />
        );
    };

    const renderContent = () => {
        let content = null;

        if (selectedPage === PAGES.plans) {
            content = renderPlans();
        } else if (selectedPage === PAGES.cancelSubscription) {
            content = renderCancelSubscription();
        } else {
            content = (
                <>
                    {renderTabs()}
                    {renderTabContent()}
                </>
            );
        }

        return (
            <div className={styles.popup}>
                {content}
            </div>
        );
    };

    /* --- */

    const renderUploadAvatarPopup = () => {
        const avatarUrl = store.user.avatarUrl || "";

        return (
            <PopupProfileAvatar
                avatarUrl={avatarUrl}
                error={uploadAvatarPopup.state.error}
                onUploadProfileImage={uploadOrDeleteAvatar}
                onClose={uploadAvatarPopup.close}
                isSaving={uploadAvatarPopup.state.isSaving}
            />
        );
    };

    const renderDeleteAvatarPopup = () => {
        return (
            <PopupConfirmMessage
                message={deleteAvatarPopup.state.message}
                onClose={deleteAvatarPopup.close}
                isLoading={deleteAvatarPopup.state.isLoading}
            />
        );
    };

    const renderFullNameEditPopup = () => {
        return (
            <PopupFullnameChange
                defaultValue={store.user.fullName}
                error={fullNamePopup.state.error}
                onSave={onSaveFullName}
                onClose={fullNamePopup.close}
                isSaving={fullNamePopup.state.isSaving}
            />
        );
    };

    const renderConfirmErrorPopup = () => {
        return (
            <PopupConfirmError
                error={popupConfirmError.state.error}
                onClose={popupConfirmError.close}
            />
        );
    };

    const renderPopups = () => {
        const popups = [];

        if (uploadAvatarPopup.state.isOpen) {
            popups.push(renderUploadAvatarPopup());
        }

        if (deleteAvatarPopup.state.isOpen) {
            popups.push(renderDeleteAvatarPopup());
        }

        if (fullNamePopup.state.isOpen) {
            popups.push(renderFullNameEditPopup());
        }

        if (popupConfirmError.state.isVisible) {
            popups.push(renderConfirmErrorPopup());
        }

        return popups;
    };

    /* --- */

    const renderSnackbar = () => {
        return (
            <Snackbar
                bars={snackbar.bars}
                onClose={snackbar.close}
            />
        );
    };

    /* --- */

    const renderPopupTitle = () => {
        if (planDetailsTabState.isVisiblePaymentChange) {
            if (isUserHasCardDetails) {
                return text.paymentDetails;
            }

            return text.addPaymentMethod;
        }

        if (planDetailsTabState.isPaymentSubmitted) {
            return text.paymentConfirmation;
        }

        if (planDetailsTabState.isVisiblePaymentDetails) {
            return text.confirmAndPay;
        }

        if (selectedPage === PAGES.plans) {
            return text.selectAPlan;
        }

        if (selectedPage === PAGES.cancelSubscription) {
            const { hasPlan } = getUserPlan();

            if (hasPlan) {
                return text.cancelSubscription;
            }

            return text.cancelFreeTrial;
        }

        return text.yourAccount;
    };

    const renderPopupLeftControl = () => {
        if (planDetailsTabState.isPaymentSubmitted) {
            return null;
        }

        if (selectedPage === PAGES.cancelSubscription && !cancelSubscription.isFirstStep()) {
            return null;
        }

        if (selectedPage) {
            return (
                <ButtonCircle
                    icon={<IconArrowBold isBlack />}
                    onClick={onBackByArrow}
                    isImageBackwards
                />
            );
        }

        return null;
    };

    /* --- */

    return (
        <>
            <PopupWindow
                title={renderPopupTitle()}
                leftControl={renderPopupLeftControl()}
                onClose={onClose}
                hideClose={planDetailsTabState.isPaymentSubmitted}
                hideContentScroll
                isDisplayFlex
            >
                {renderContent()}
            </PopupWindow>

            {renderPopups()}
            {renderSnackbar()}
        </>
    );
};

UserPopupAccount.defaultProps = {
    avatarIconName: "",
    emailNotification: {},
    onLoadUser: () => { },
    onContactSupport: () => { },
    onEditEmail: () => { },
    onEditPassword: () => { },
    onCloseAndSignOut: () => { },
    onClose: () => { },
};

export default UserPopupAccount;
