const newLetter = (value) => {
    return {
        value,
        isPresent: false,
        isCorrect: false,
    };
};

const newWord = (word) => {
    return word.split("").map(newLetter);
};

const wordsToLetters = (words) => {
    return words.map(newWord);
};

const lettersToWord = (letters) => {
    return letters.map((ltr) => ltr.value).join("");
};

const allLettersToWords = (allLetters) => {
    return allLetters.map(lettersToWord);
};

const compareLetters = (wordLetters, suggestedLetters) => {
    const letters = {};
    const lettersCounter = {};

    wordLetters.forEach((ltr) => {
        if (!letters[ltr]) {
            letters[ltr] = 0;
        }

        letters[ltr] += 1;

        if (!lettersCounter[ltr]) {
            lettersCounter[ltr] = 0;
        }
    });

    const correctLetters = suggestedLetters.map((ltr, i) => {
        const wordLetter = wordLetters[i] || "";

        if (ltr.value === wordLetter) {
            lettersCounter[ltr.value] += 1;

            return {
                ...ltr,
                isPresent: true,
                isCorrect: true,
            };
        }

        return ltr;
    });

    const correctAndPresentLetters = correctLetters.map((ltr) => {
        if (letters[ltr.value] && !ltr.isCorrect) {
            if (lettersCounter[ltr.value] < letters[ltr.value]) {
                lettersCounter[ltr.value] += 1;

                return {
                    ...ltr,
                    isPresent: true,
                };
            }
        }

        return ltr;
    });

    return correctAndPresentLetters;
};

const compareWordWithLetters = (word, suggestedLetters) => {
    const wordLetters = word.split("");

    return compareLetters(wordLetters, suggestedLetters);
};

const compareWordWithAllLetters = (word, allLetters) => {
    const wordLetters = word.split("");

    return allLetters.map((letters) => {
        return compareLetters(wordLetters, letters);
    });
};

const getUniqueLetters = (allLetters) => {
    const res = [];

    const lettersValues = [];

    const flatLetters = allLetters.flat().sort((a, b) => {
        return b.isCorrect - a.isCorrect
            || b.isPresent - a.isPresent;
    });

    flatLetters.forEach((ltr) => {
        if (lettersValues.indexOf(ltr.value) === -1) {
            res.push(ltr);
            lettersValues.push(ltr.value);
        }
    });

    return res;
};

const getLetterByValue = (letters, value) => {
    if (!letters || !Array.isArray(letters)) {
        return null;
    }

    for (let i = 0; i < letters.length; i += 1) {
        const ltr = letters[i];

        if (ltr.value === value) {
            return ltr;
        }
    }

    return null;
};

export default {
    newLetter,
    newWord,
    wordsToLetters,
    lettersToWord,
    allLettersToWords,
    compareWordWithLetters,
    compareWordWithAllLetters,
    getUniqueLetters,
    getLetterByValue,
};
