import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconSpeedometer = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M14.2732 3.41331L13.3507 4.80081C13.7955 5.68811 14.0133 6.67193 13.9846 7.6641C13.9558 8.65627 13.6813 9.6258 13.1857 10.4858H2.79067C2.14654 9.36839 1.87964 8.07315 2.02952 6.79211C2.17941 5.51107 2.7381 4.31243 3.62278 3.37388C4.50745 2.43533 5.67102 1.80684 6.94097 1.58158C8.21093 1.35632 9.51966 1.54628 10.6732 2.12331L12.0607 1.20081C10.648 0.294971 8.97241 -0.110938 7.30183 0.0480109C5.63126 0.20696 4.06225 0.921581 2.84579 2.07757C1.62932 3.23355 0.835688 4.76411 0.591829 6.42442C0.347971 8.08473 0.667979 9.77885 1.50067 11.2358C1.63155 11.4625 1.81948 11.651 2.04579 11.7826C2.2721 11.9142 2.5289 11.9842 2.79067 11.9858H13.1782C13.4425 11.9869 13.7024 11.918 13.9315 11.7863C14.1607 11.6546 14.351 11.4647 14.4832 11.2358C15.1742 10.0387 15.521 8.67401 15.4853 7.29227C15.4495 5.91054 15.0327 4.56555 14.2807 3.40581L14.2732 3.41331Z"
                        fill="currentColor"
                    />
                    <path
                        d="M6.93067 8.54331C7.06998 8.68277 7.23541 8.79341 7.41751 8.8689C7.5996 8.94438 7.79479 8.98324 7.99192 8.98324C8.18904 8.98324 8.38423 8.94438 8.56633 8.8689C8.74842 8.79341 8.91386 8.68277 9.05317 8.54331L13.2982 2.17581L6.93067 6.42081C6.7912 6.56012 6.68056 6.72555 6.60508 6.90765C6.52959 7.08975 6.49074 7.28494 6.49074 7.48206C6.49074 7.67918 6.52959 7.87437 6.60508 8.05647C6.68056 8.23857 6.7912 8.404 6.93067 8.54331Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconSpeedometer;
