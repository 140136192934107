import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import text from "@/base/text/index.js";

import IconEmojiSad from "@/base/icons/emoji-sad/index.js";

import Text from "@/base/components/text/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";

import settings from "@/app/settings.js";

import linkStyles from "@/base/components/link/styles.module.css";


const UserPopupConfirmExpiration = (props) => {
    const store = useSelector((state) => ({
        session: state.user.session,
        user: state.user.user,
        location: state.navigation.location,
    }));

    const navigate = useNavigate();

    if (!store.user.isExpired) {
        return null;
    }

    if (store.location.pathname
        && store.location.pathname.indexOf("subscribe") !== -1) {
        return null;
    }

    const onSubscribe = () => {
        navigate("/subscribe");
    };

    /* --- */

    const renderContent = () => {
        if (props.isStudent) {
            return text.studentExpired;
        }

        const linkSupport = `
            <a
                href=${settings.landingSite.support}
                target="_blank"
            >
                ${settings.supportEmail}
            </a>
        `;

        const message = `
            Subscribe to one of our current plans to keep using The Juice,
            or please contact ${linkSupport} for more options
        `;

        return (
            <Text className={linkStyles.contentWithLink}>
                {message}
            </Text>
        );
    };

    const buttons = [
        <ButtonFlat onClick={props.onSignOut}>
            Log out
        </ButtonFlat>,
    ];

    if (props.isTeacher) {
        buttons.push(
            <ButtonFlat onClick={onSubscribe}>
                Subscribe
            </ButtonFlat>,
        );
    }

    return (
        <PopupConfirm>
            <PopupConfirmIcon
                icon={<IconEmojiSad title="Sad" isBlack />}
                big
            />

            <PopupConfirmTitle>
                {text.accountExpired}
            </PopupConfirmTitle>

            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                {buttons}
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

UserPopupConfirmExpiration.defaultProps = {
    isTeacher: false,
    isStudent: false,
    onSignOut: () => { },
};

export default UserPopupConfirmExpiration;
