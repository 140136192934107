// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zk3W0F1uOaN_BYo4O25A {\n    z-index: var(--z-index-header);\n    position: absolute;\n\n    top: calc(100% + 1rem);\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.XdNPYsZuuUoNXiHde6tw {\n    top: calc(100% + 1rem);\n}\n\n.ccm1MYokuWHMM8HubEol {\n    top: unset;\n    bottom: calc(100% + 1rem);\n}\n\n.j9nnXAK7WSIi5bthIVIL {\n    transform: translateX(-78%);\n}\n\n.hFPN7Bv1wvGrVCSwAgih {\n    transform: translateX(-22%);\n}\n", "",{"version":3,"sources":["webpack://./base/components/tooltip-position/styles.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kBAAkB;;IAElB,sBAAsB;IACtB,SAAS;IACT,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".tooltip {\n    z-index: var(--z-index-header);\n    position: absolute;\n\n    top: calc(100% + 1rem);\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.tooltipBottom {\n    top: calc(100% + 1rem);\n}\n\n.tooltipTop {\n    top: unset;\n    bottom: calc(100% + 1rem);\n}\n\n.tooltipLeft {\n    transform: translateX(-78%);\n}\n\n.tooltipRight {\n    transform: translateX(-22%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "Zk3W0F1uOaN_BYo4O25A",
	"tooltipBottom": "XdNPYsZuuUoNXiHde6tw",
	"tooltipTop": "ccm1MYokuWHMM8HubEol",
	"tooltipLeft": "j9nnXAK7WSIi5bthIVIL",
	"tooltipRight": "hFPN7Bv1wvGrVCSwAgih"
};
export default ___CSS_LOADER_EXPORT___;
