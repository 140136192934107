// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yaaCxL97E8ImCoC1Wrku {\n    display: grid;\n}\n\n.XYIwwwRrpnWNGTclRRsM {\n    position: sticky;\n    top: 0;\n\n    background-color: var(--background-color);\n\n    font-weight: 600;\n}\n\n.wRatRLcnS7P1riGnNRx1 {\n    display: grid;\n    grid-template-columns: 26% 17% 20% 25% 12%;\n    align-items: center;\n\n    height: 5.5rem;\n    padding: 0rem 1rem;\n\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n.KuRAyhBb8CASYW7dZHJg {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 0.5rem;\n\n    justify-content: start;\n}\n\n/* --- */\n\n.L5cvKTS2LOp0b0lhmuCA {\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n/* --- */\n\n.ZMgxZ4XbR_1PmjiAejCi {\n    margin: 1rem 0;\n}\n", "",{"version":3,"sources":["webpack://./base/business/table-daily-juices/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,MAAM;;IAEN,yCAAyC;;IAEzC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,0CAA0C;IAC1C,mBAAmB;;IAEnB,cAAc;IACd,kBAAkB;;IAElB,uDAAuD;AAC3D;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;;IAEhB,sBAAsB;AAC1B;;AAEA,QAAQ;;AAER;IACI,uDAAuD;AAC3D;;AAEA,QAAQ;;AAER;IACI,cAAc;AAClB","sourcesContent":[".table {\n    display: grid;\n}\n\n.header {\n    position: sticky;\n    top: 0;\n\n    background-color: var(--background-color);\n\n    font-weight: 600;\n}\n\n.row {\n    display: grid;\n    grid-template-columns: 26% 17% 20% 25% 12%;\n    align-items: center;\n\n    height: 5.5rem;\n    padding: 0rem 1rem;\n\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n.rowData {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 0.5rem;\n\n    justify-content: start;\n}\n\n/* --- */\n\n.rowMobile {\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n/* --- */\n\n.loadMoreButton {\n    margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "yaaCxL97E8ImCoC1Wrku",
	"header": "XYIwwwRrpnWNGTclRRsM",
	"row": "wRatRLcnS7P1riGnNRx1",
	"rowData": "KuRAyhBb8CASYW7dZHJg",
	"rowMobile": "L5cvKTS2LOp0b0lhmuCA",
	"loadMoreButton": "ZMgxZ4XbR_1PmjiAejCi"
};
export default ___CSS_LOADER_EXPORT___;
