import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import actionsUserEmail from "@/base/actions/user-email.js";
import actions from "@/base/store/actions.js";

import PopupConfirm from "@/base/components/popup-confirm/index.js";

import api from "@/app/api.js";

import PageDefault from "./pages/index.js";
import PageEmailChange from "./pages/email-change.js";
import PageEmailConfirm from "./pages/email-confirm.js";
import PageEmailSuccessfullyChanged from "./pages/email-successfully-changed.js";
import PageEmailConfirmed from "./pages/email-confirmed.js";


const PAGES = {
    emailChange: "emailChange",
    emailConfirm: "emailConfirm",
    emailConfirmed: "emailConfirmed",
    emailSuccessfullyChanged: "emailSuccessfullyChanged",
};

const getState = () => ({
    page: "",
    error: "",
});

const storeSelector = (state) => ({
    user: state.user.user,
});

const PopupBadEmail = (props) => {
    const [state, setState] = useState(() => getState());

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* --- */

    const resetPage = () => {
        setState(getState());
    };

    const goToChangeEmail = () => {
        setState((prev) => ({
            ...prev,
            page: PAGES.emailChange,
        }));
    };

    const goToConfirmEmail = () => {
        setState((prev) => ({
            ...prev,
            page: PAGES.emailConfirm,
        }));
    };

    /* --- */

    const onConfirmCurrentEmail = async () => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));

        const { error } = await dispatch(actionsUserEmail.confirmBadEmail({ actions, api }));

        if (error) {
            setState((prev) => ({
                ...prev,
                error,
                isLoading: false,
            }));
            return;
        }

        setState((prev) => ({
            ...prev,
            page: PAGES.emailConfirmed,
            isLoading: false,
        }));
    };

    const onChangeEmail = async (values) => {
        const { email } = values;

        setState((prev) => ({
            ...prev,
            newEmail: email,
            isLoading: true,
        }));

        const { error } = await dispatch(actionsUserEmail.changeBadEmail(
            { actions, api },
            { email },
        ));

        if (error) {
            setState((prev) => ({
                ...prev,
                error,
                isLoading: false,
            }));
            return;
        }

        setState((prev) => ({
            ...prev,
            page: PAGES.emailSuccessfullyChanged,
            isLoading: false,
        }));
    };

    /* --- */

    const renderPopupContent = () => {
        const { page } = state;

        if (page === PAGES.emailChange) {
            return (
                <PageEmailChange
                    email={state.newEmail}
                    error={state.error}
                    onSave={onChangeEmail}
                    onBack={resetPage}
                    isLoading={state.isLoading}
                />
            );
        }

        if (page === PAGES.emailConfirm) {
            return (
                <PageEmailConfirm
                    email={store.user.email}
                    error={state.error}
                    onConfirm={onConfirmCurrentEmail}
                    onBack={resetPage}
                    isLoading={state.isLoading}
                />
            );
        }

        if (page === PAGES.emailSuccessfullyChanged) {
            return (
                <PageEmailSuccessfullyChanged
                    email={state.newEmail}
                    onClose={props.onClose}
                />
            );
        }

        if (page === PAGES.emailConfirmed) {
            return (
                <PageEmailConfirmed
                    onClose={props.onClose}
                />
            );
        }

        return (
            <PageDefault
                email={store.user.email}
                onConfirm={goToConfirmEmail}
                onChange={goToChangeEmail}
            />
        );
    };

    return (
        <PopupConfirm
            hideScrollbar
            isPopupMedium
        >
            {renderPopupContent()}
        </PopupConfirm>
    );
};

PopupBadEmail.defaultProps = {
    onClose: () => { },
};

export default PopupBadEmail;
