import * as types from "./types.js";


export const setLastDailyJuiceLoading = (payload) => ({
    type: types.SET_STUDENT_DAILY_JUICES_LAST_DAILY_JUICE_LOADING,
    payload,
});

export const setLastDailyJuice = (payload) => ({
    type: types.SET_STUDENT_DAILY_JUICES_LAST_DAILY_JUICE,
    payload,
});
