import * as types from "./types.js";

// -----------

export const openDistrictEdit = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_EDIT_DISTRICT,
    payload,
});

export const setDistrictEditLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_EDIT_DISTRICT_LOADING,
    payload: { isLoading },
});

export const setDistrictEditSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_EDIT_DISTRICT_SUCCESS,
    payload,
});

export const clearDistrictEdit = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_EDIT_DISTRICT,
    payload,
});

// -----------

export const openDistrictDelete = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_DELETE_DISTRICT,
    payload,
});

export const setDistrictDeleteLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_DELETE_DISTRICT_LOADING,
    payload: { isLoading },
});

export const setDistrictDeleteSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_DELETE_DISTRICT_SUCCESS,
    payload,
});

export const clearDistrictDelete = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_DELETE_DISTRICT,
    payload,
});

// -----------

export const openEditSchool = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_EDIT_SCHOOL,
    payload,
});

export const setSchoolEditLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_SCHOOL_EDIT_LOADING,
    payload: { isLoading },
});

export const setSchoolEditSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS,
    payload,
});

export const clearSchoolEdit = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_SCHOOL_EDIT,
    payload,
});

/* --- */

export const openDeleteSchool = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_SCHOOL_DELETE,
    payload,
});

export const setSchoolDeleteLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_SCHOOL_DELETE_LOADING,
    payload: { isLoading },
});

export const setSchoolDeleteSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS,
    payload,
});

export const clearSchoolDelete = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_SCHOOL_DELETE,
    payload,
});

/* --- */

export const openEditDepartment = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_DEPARTMENT_EDIT,
    payload,
});

export const setDepartmentEditLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING,
    payload: { isLoading },
});

export const setDepartmentEditSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS,
    payload,
});

export const clearDepartmentEdit = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_DEPARTMENT_EDIT,
    payload,
});

/* --- */

export const openDeleteDepartment = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_DEPARTMENT_DELETE,
    payload,
});

export const setDepartmentDeleteLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING,
    payload: { isLoading },
});

export const setDepartmentDeleteSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS,
    payload,
});

export const clearDepartmentDelete = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_DEPARTMENT_DELETE,
    payload,
});

/* --- */

export const openEditClass = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_CLASS_EDIT,
    payload,
});

export const setClassEditLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_CLASS_EDIT_LOADING,
    payload: { isLoading },
});

export const setClassEditSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_CLASS_EDIT_SUCCESS,
    payload,
});

export const clearClassEdit = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_CLASS_EDIT,
    payload,
});

/* --- */

export const openDeleteClass = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_CLASS_DELETE,
    payload,
});

export const setClassDeleteLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_CLASS_DELETE_LOADING,
    payload: { isLoading },
});

export const setClassDeleteSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_CLASS_DELETE_SUCCESS,
    payload,
});

export const clearClassDelete = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_CLASS_DELETE,
    payload,
});

/* --- */

export const openClassMove = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_CLASS_MOVE,
    payload,
});

export const setClassMoveDistrict = (districtId) => ({
    type: types.SET_ADMIN_POPUP_CLASS_MOVE_DISTRICT,
    payload: { districtId },
});

export const setClassMoveSchool = (schoolId) => ({
    type: types.SET_ADMIN_POPUP_CLASS_MOVE_SCHOOL,
    payload: { schoolId },
});

export const setClassMoveLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_CLASS_MOVE_LOADING,
    payload: { isLoading },
});

export const setClassMoveSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_CLASS_MOVE_SUCCESS,
    payload,
});

export const clearClassMove = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_CLASS_MOVE,
    payload,
});

/* --- */

export const openDepartmentMove = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_DEPARTMENT_MOVE,
    payload,
});

export const setDepartmentMoveDistrict = (districtId) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_MOVE_DISTRICT,
    payload: { districtId },
});

export const setDepartmentMoveLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_MOVE_LOADING,
    payload: { isLoading },
});

export const setDepartmentMoveSuccess = (payload) => ({
    type: types.SET_ADMIN_POPUP_DEPARTMENT_MOVE_SUCCESS,
    payload,
});

export const clearDepartmentMove = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_DEPARTMENT_MOVE,
    payload,
});

/* --- */

export const openMoveSchool = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_SCHOOL_MOVE,
    payload,
});

export const setSchoolMoveLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_SCHOOL_MOVE_LOADING,
    payload: { isLoading },
});

export const setSchoolMoveSuccess = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_SCHOOL_MOVE_SUCCESS,
    payload: { isLoading },
});

export const clearMoveSchool = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_SCHOOL_MOVE,
    payload,
});

/* --- */

export const openEntitiesAddDistrict = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "districts",
    },
});

export const openEntitiesAddSchool = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "schools",
    },
});

export const openEntitiesAddDepartment = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "departments",
    },
});

export const openEntitiesAddClass = (payload) => ({
    type: types.OPEN_ADMIN_POPUP_ENTITIES_ADD,
    payload: {
        ...payload,
        selectedTab: "classes",
    },
});

export const setEntitiesAddLoading = (isLoading) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_LOADING,
    payload: { isLoading },
});

export const setEntitiesAddDepartmentAdded = (payload) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED,
    payload,
});

export const setEntitiesAddDistrictAdded = (payload) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_DISTRICT_ADDED,
    payload,
});

export const setEntitiesAddSchoolAdded = (payload) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED,
    payload,
});

export const setEntitiesAddClassAdded = (payload) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED,
    payload,
});

export const setEntitiesAddSelectedDistrict = (selectedDistrict) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DISTRICT,
    payload: { selectedDistrict },
});

export const setEntitiesAddSelectedSchool = (selectedSchool) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL,
    payload: { selectedSchool },
});

export const setEntitiesAddSelectedDepartment = (selectedDepartment) => ({
    type: types.SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT,
    payload: { selectedDepartment },
});

export const clearEntitiesAddStatuses = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_ENTITIES_ADD_STATUSES,
    payload,
});

export const clearEntitiesAdd = (payload) => ({
    type: types.CLEAR_ADMIN_POPUP_ENTITIES_ADD,
    payload,
});

