import Translation from "@/base/project/translation.js";

import text from "@/base/text/index.js";


const setDefaultLanguage = (services, params = {}) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        if (state.user.user?.translationLanguageCode === params.languageCode) {
            return;
        }

        dispatch(actions.user.setTranslationLanguage({
            languageCode: params.languageCode,
            languageName: params.languageName,
        }));

        api.translation.setDefaultLanguage({
            session: state?.user?.session || "",
            code: params.languageCode,
        });
    };
};

const loadLanguagesOnce = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        if (state.translation.isLanguagesLoaded) {
            return;
        }

        const res = await api.translation.getLanguages({
            session: state?.user?.session || "",
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.translation.setLanguages({
            languages: res.languages,
        }));
    };
};

const sendTranslateStoryByIdEvent = (services, params) => {
    const { storyId, language, isExtraJuice } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const session = state?.user?.session || "";
        const { languages } = state.translation;

        const eventParams = {
            session,
            storyId,
            languageId: Translation.getLanguageIdByCode(languages, language),
        };

        if (params.isMini) {
            if (isExtraJuice) {
                services.events.mini.translateExtraJuice(eventParams);
                return;
            }

            services.events.mini.translateStory(eventParams);
            return;
        }

        if (params.isMiniSearch) {
            if (isExtraJuice) {
                services.events.searchMini.translateExtraJuice(eventParams);
                return;
            }

            services.events.searchMini.translateStory(eventParams);
            return;
        }

        if (isExtraJuice) {
            services.events.translation.translateExtraJuice(eventParams);
            return;
        }

        services.events.translation.translateStory(eventParams);
    };
};

const translateStoryById = (services, params = {}) => {
    const { api, actions } = services;
    const { storyId, language } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const session = state?.user?.session || "";

        const sendEvent = sendTranslateStoryByIdEvent(services, params);
        sendEvent(dispatch, getState);

        const story = state.translation.storiesById?.[storyId]?.[language];

        if (story && (story?.isLoading || !story?.error)) {
            return;
        }

        dispatch(actions.translation.setStoryLoadingById({
            storyId,
            language,
        }));

        const res = await api.translation.getStoryById({
            session,
            storyId,
            code: language,
        });

        if (!res.ok) {
            dispatch(actions.translation.setStoryErrorById({
                storyId,
                language,
                error: res.error || text.error,
            }));
            return;
        }

        dispatch(actions.translation.setStoryById({
            language,
            translation: res.translation,
        }));
    };
};

const changeStoryUITranslationById = (services, params) => {
    const { actions } = services;

    return async (dispatch) => {
        dispatch(actions.translation.changeStoryUITranslationById({
            storyId: params.storyId,
            languageCode: params.languageCode,
            isChecked: params.isChecked,
        }));
    };
};

export default {
    setDefaultLanguage,
    loadLanguagesOnce,
    translateStoryById,

    changeStoryUITranslationById,
};
