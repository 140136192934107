import text from "@/base/text/index.js";


const translateVocabWord = (services, params = {}) => {
    const { api, actions } = services;
    const { wordId, language } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const session = state?.user?.session || "";

        dispatch(actions.translation.setVocabWordLoading({
            wordId,
            language,
        }));

        const res = await api.translation.getVocabWordById({
            session,
            wordId,
            code: language,
        });

        let word = {};
        let definitions = [];
        let error = "";

        if (res.ok) {
            word = res.word || {};
            definitions = res.definitions || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.translation.setVocabWord({
            wordId,
            language,
            word,
            definitions,
            error,
        }));

        return {
            word,
            definitions,
            error,
        };
    };
};

export default {
    translateVocabWord,
};
