import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    plans: [],
    activePlans: [],
    freeTrialPlanFeatures: [],
    privatePlanByCode: {},
    plansBySlug: {},
    isPlansLoaded: false,
    isActivePlansLoaded: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_PLANS: {
            return {
                ...state,
                isPlansLoaded: true,
                plans: action.payload.plans,
                freeTrialPlanFeatures: action.payload.freeTrialPlanFeatures,
            };
        }

        case types.SET_ACTIVE_PLANS: {
            return {
                ...state,
                isActivePlansLoaded: true,
                activePlans: action.payload.plans,
            };
        }

        /* --- */

        case types.SET_PRIVATE_PLAN_BY_CODE_LOADING: {
            const { code } = action.payload;

            return {
                ...state,
                privatePlanByCode: {
                    ...state.privatePlanByCode,
                    [code]: {
                        data: null,
                        error: "",
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_PRIVATE_PLAN_BY_CODE: {
            const { code, data, error } = action.payload;

            return {
                ...state,
                privatePlanByCode: {
                    ...state.privatePlanByCode,
                    [code]: {
                        data,
                        error,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
