const SET_COMPLETED_ASSIGNMENTS_BY_PAGE = "SET_COMPLETED_ASSIGNMENTS_BY_PAGE";
const SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING = "SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING";
const CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE = "CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE";
const SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING = "SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING";
const SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE = "SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE";
const CLEAR_INCOMPLETE_ASSIGNMENTS = "CLEAR_INCOMPLETE_ASSIGNMENTS";
const SET_ASSIGNMENTS_BY_PAGE_LOADING = "SET_ASSIGNMENTS_BY_PAGE_LOADING";
const SET_ASSIGNMENTS_BY_PAGE = "SET_ASSIGNMENTS_BY_PAGE";
const CLEAR_ASSIGNMENTS_BY_PAGE = "CLEAR_ASSIGNMENTS_BY_PAGE";

const SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING = "SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING";
const SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID = "SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID";
const CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID = "CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID";

const SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES_LOADING = "SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES_LOADING";
const SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES = "SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES";
const CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES = "CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES";

const SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES = "SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES";
const SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES_LOADING = "SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES_LOADING";
const CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES = "CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES";

const SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING = "SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING";
const SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID = "SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID";
const CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID = "CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID";


export {
    SET_COMPLETED_ASSIGNMENTS_BY_PAGE,
    SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING,
    CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE,

    SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING,
    SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE,
    CLEAR_INCOMPLETE_ASSIGNMENTS,

    SET_ASSIGNMENTS_BY_PAGE_LOADING,
    SET_ASSIGNMENTS_BY_PAGE,
    CLEAR_ASSIGNMENTS_BY_PAGE,

    SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING,
    SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID,
    CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID,

    SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES_LOADING,
    SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES,
    CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES,

    SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING,
    SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID,
    CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID,

    SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES_LOADING,
    SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES,
    CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES,
};
