import app from "@/base/application/app.js";

import configureStore from "@/base/store/index.js";
import actions from "@/base/store/actions.js";
import storage from "@/base/lib/storage/index.js";

import api from "./api.js";
import events from "./events.js";


const createApp = () => {
    const store = configureStore();

    return app.createApp({
        store,
        actions,
        api,
        events,
        storage,
    });
};

const mainApp = createApp();

export default mainApp;
