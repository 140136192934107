export default (apiURL, methods) => {
    return {
        setTeacherQuizAnswer(params = {}) {
            // NOTE: action=set_teacher_quiz_answer
            const url = `${apiURL}/teacher/quizzes/${params.quizId}/${params.questionId}`;

            return methods.post(url, {
                session_id: params.session,
                quiz_id: params.quizId,
                question_id: params.questionId,
                answer_id: params.answerId,
                juice_id: params.juiceId,
                time_took: params.timeTook,
            });
        },
    };
};
