// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w6VNwIefw2onP8DX17eL {\n    display: grid;\n    justify-content: center;\n}\n\n.WgoEMEX_YMxzl5XjbSmK {\n    padding: 3rem 3rem;\n\n    display: grid;\n    grid-row-gap: 1rem;\n    justify-content: center;\n\n    border: 0.2rem solid var(--border-default-color);\n    border-radius: 1rem;\n\n    background-color: var(--not-found-message-bg-color);\n\n    color: var(--text-primary-color);\n    font-weight: 600;\n    text-align: center;\n}\n\n.v9Wo2u4DPFKm1U90DdWW {\n    display: block;\n    margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./app/views/not-found/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;;IAElB,aAAa;IACb,kBAAkB;IAClB,uBAAuB;;IAEvB,gDAAgD;IAChD,mBAAmB;;IAEnB,mDAAmD;;IAEnD,gCAAgC;IAChC,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB","sourcesContent":[".notFound {\n    display: grid;\n    justify-content: center;\n}\n\n.message {\n    padding: 3rem 3rem;\n\n    display: grid;\n    grid-row-gap: 1rem;\n    justify-content: center;\n\n    border: 0.2rem solid var(--border-default-color);\n    border-radius: 1rem;\n\n    background-color: var(--not-found-message-bg-color);\n\n    color: var(--text-primary-color);\n    font-weight: 600;\n    text-align: center;\n}\n\n.homeIcon {\n    display: block;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": "w6VNwIefw2onP8DX17eL",
	"message": "WgoEMEX_YMxzl5XjbSmK",
	"homeIcon": "v9Wo2u4DPFKm1U90DdWW"
};
export default ___CSS_LOADER_EXPORT___;
