import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Redirects from "@/base/project/redirects.js";

import RequestLoader from "@/base/components/request-loader/index.js";


const Auth = (props) => {
    const store = useSelector((state) => state.user);

    if (store.isUserLoading) {
        return (
            <div>
                <RequestLoader />
            </div>
        );
    }

    if (!store.user.userId) {
        return (
            <Navigate to="/sign-in" replace />
        );
    }

    let isValidRole = false;

    for (let i = 0; i < store.user.roles.length; i += 1) {
        const userRole = store.user.roles[i];

        if (props.roles.indexOf(userRole) !== -1) {
            isValidRole = true;
            break;
        }
    }

    if (!isValidRole) {
        const url = Redirects.getDefaultRedirectUrl(store.user);

        return (
            <Navigate to={url} replace />
        );
    }

    return props.children;
};

Auth.defaultProps = {
    roles: [],
};


export const withAuth = (roles) => (Component) => {
    return (props) => {
        /* eslint-disable */
        return (
            <Auth roles={roles}>
                <Component {...props} />
            </Auth>
        );
        /* eslint-enable */
    };
};

export default Auth;
