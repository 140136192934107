// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QGjkoT6QYhbWTVQwdeQr {\n    position: relative;\n}\n\n.BKkMhoy0GyWyphwct4gO {\n    padding-bottom: var(--mobile-header-bottom-height);\n\n    position: absolute;\n    top: 5rem;\n    right: 0rem;\n}\n\n.q5d9M9urqh9aqM8HtLJZ {\n    display: grid;\n    justify-self: center;\n}\n\n/* --- */\n\n@media only screen and (min-width: 901px) {\n    .BKkMhoy0GyWyphwct4gO {\n        padding-bottom: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/avatar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kDAAkD;;IAElD,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA,QAAQ;;AAER;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".avatar {\n    position: relative;\n}\n\n.avatarMenuContainer {\n    padding-bottom: var(--mobile-header-bottom-height);\n\n    position: absolute;\n    top: 5rem;\n    right: 0rem;\n}\n\n.profileAvatar {\n    display: grid;\n    justify-self: center;\n}\n\n/* --- */\n\n@media only screen and (min-width: 901px) {\n    .avatarMenuContainer {\n        padding-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "QGjkoT6QYhbWTVQwdeQr",
	"avatarMenuContainer": "BKkMhoy0GyWyphwct4gO",
	"profileAvatar": "q5d9M9urqh9aqM8HtLJZ"
};
export default ___CSS_LOADER_EXPORT___;
