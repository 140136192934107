import * as types from "./types.js";


const initialState = {
    dimensions: {},
    isPWA: false,
    isSafariPWA: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_DIMENSIONS: {
            return {
                ...state,
                dimensions: action.payload.dimensions,
            };
        }

        case types.SET_IS_PWA: {
            return {
                ...state,
                isPWA: action.payload.isPWA,
                isSafariPWA: action.payload.isSafariPWA || false,
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
