import React, { useEffect, useRef } from "react";

import scroll from "@/base/lib/scroll.js";
import classNames from "@/base/lib/class-names.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";

import Title from "./title.js";
import Content from "./content.js";
import Buttons from "./buttons.js";
import Icon from "./icon.js";
import styles from "./styles.module.css";


const PopupConfirm = (props) => {
    const bgLayout = useRef(null);

    useEffect(() => {
        if (props.hideScrollbar) {
            scroll.hideBodyScroll();
        }

        return () => {
            if (props.hideScrollbar) {
                scroll.unhideBodyScroll();
            }
        };
    }, []);

    const onClose = (evt) => {
        if (props.closeOnLayoutClick && bgLayout?.current === evt.target) {
            props.onClose();
        }
    };

    const popupWindowClassName = classNames({
        [styles.popup]: true,
        [styles.popupMedium]: props.isPopupMedium,
        [styles.popupFullWidth]: props.fullWidth,
        [styles.popupWithScroll]: props.popupWithScroll,
        [scrollbarStyles.scrollbar]: props.popupWithScroll,
    });

    return (
        <div
            data-comment="popup-confirm"
            ref={bgLayout}
            className={styles.popupBackground}
            onClick={onClose}
            onKeyPress={onClose}
            role="button"
            tabIndex="-1"
        >
            <div className={popupWindowClassName}>
                {props.children}
            </div>
        </div>
    );
};

PopupConfirm.defaultProps = {
    children: null,
    popupClassName: "",
    onClose: () => { },
    popupWithScroll: false,
    hideScrollbar: true,
    closeOnLayoutClick: false,
    fullWidth: false,
    isPopupMedium: false,
};

export const PopupConfirmIcon = Icon;
export const PopupConfirmTitle = Title;
export const PopupConfirmContent = Content;
export const PopupConfirmButtons = Buttons;
export default PopupConfirm;
