import * as types from "./types.js";


export const setFivesyAttemptsLoading = (payload) => ({
    type: types.SET_FIVESY_ATTEMPTS_LOADING,
    payload,
});

export const setFivesyAttempts = (payload) => ({
    type: types.SET_FIVESY_ATTEMPTS,
    payload,
});

export const setFivesyStatsLoading = (payload) => ({
    type: types.SET_FIVESY_STATS_LOADING,
    payload,
});

export const setFivesyStats = (payload) => ({
    type: types.SET_FIVESY_STATS,
    payload,
});
