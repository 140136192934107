import text from "@/base/text/index.js";


const loadPlans = (services) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.subscription.getPlans();

        if (res.ok) {
            dispatch(actions.subscription.setPlans({
                plans: res.plans,
                freeTrialPlanFeatures: res.freeTrialFeatures,
            }));
        }
    };
};

const loadActivePlans = (services) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.subscription.getActivePlans();

        if (!res.ok) {
            return;
        }

        dispatch(actions.subscription.setActivePlans({
            plans: res.plans,
        }));
    };
};

const cancelSubscription = (services, params) => {
    const { api } = services;
    const {
        cancelReason,
        cancelReasonMessage,
        cancelPreference,
        hasPlan,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.cancelSubscription({
            session: state.user.session,
            cancelType: hasPlan ? "paid" : "free",
            cancelReason,
            cancelReasonMessage,
            cancelPreference,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const getUserPaymentDetails = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.user.setUserPaymentDetailsLoaded({
            isLoaded: false,
        }));

        const res = await api.subscription.getUserPaymentDetails({
            session: state.user.session,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.user.setUserPaymentDetails({
                card: res.data,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const applySubscriptionCoupon = (services, params) => {
    const { api } = services;
    const { couponCode, planId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.subscriptionApplyCoupon({
            session: state.user.session,
            coupon: couponCode,
            plan: planId,
        });

        let discountMessage = "";
        let discountUSD = 0;
        let discountPercent = 0;

        let error = "";

        if (res.ok) {
            discountUSD = res.data.discountUSD || 0;
            discountPercent = res.data.discountPercent || 0;

            discountMessage = discountUSD > 0
                ? `$${discountUSD} discount added`
                : `${discountPercent}% discount added`;
        } else {
            error = res.error || text.error;
        }

        return {
            discountMessage,
            discountPercent,
            discountUSD,
            error,
        };
    };
};

const createPaymentIntentByPlan = (services, params) => {
    const { api } = services;
    const { planId, coupon, isAutoRenew } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.createPaymentIntentByPlan({
            session: state.user.session,
            plan: planId,
            coupon,
            isAutoRenew,
        });

        let data = {
            intentId: null,
            subscriptionEncId: null,
            clientSecret: null,
            dates: {
                start: null,
                end: null,
            },
            error: "",
            is100PercentDiscount: false,
        };

        if (res.ok) {
            data = {
                intentId: res.data.intentExternalId || null,
                subscriptionEncId: res.data.subscriptionEncId || null,
                clientSecret: res.data.clientSecret || null,
                dates: {
                    start: res.data.subscriptionStartDateFormatted || null,
                    end: res.data.subscriptionEndDateFormatted || null,
                },
                error: "",
                is100PercentDiscount: res.data.is100PercentDiscount || false,
            };
        } else {
            data.error = res.error || text.error;
        }

        return data;
    };
};

const loadSessionPlan = (services) => {
    const { api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.getPlanBySession({
            session: state.user.session,
        });

        let plan = null;
        let error = "";

        if (res.ok) {
            plan = res.plan || null;
        }

        if (!plan) {
            error = res.error || text.error;
        }

        return {
            plan,
            error,
        };
    };
};


export default {
    loadPlans,
    loadActivePlans,
    getUserPaymentDetails,
    cancelSubscription,
    applySubscriptionCoupon,
    createPaymentIntentByPlan,
    loadSessionPlan,
};
