import * as types from "./types.js";

export const setClosedPollsLoading = (payload) => ({
    type: types.SET_CLOSED_POLLS_LOADING,
    payload,
});

export const setClosedPolls = (payload) => ({
    type: types.SET_CLOSED_POLLS,
    payload,
});

export const addClosedPolls = (payload) => ({
    type: types.ADD_CLOSED_POLLS,
    payload,
});

export const clearClosedPolls = (payload) => ({
    type: types.CLEAR_CLOSED_POLLS,
    payload,
});

export const setPollResultByClassIdLoading = (payload) => ({
    type: types.SET_POLL_RESULT_BY_CLASS_ID_LOADING,
    payload,
});

export const setPollResultByClassId = (payload) => ({
    type: types.SET_POLL_RESULT_BY_CLASS_ID,
    payload,
});

export const clearPollsResults = (payload) => ({
    type: types.CLEAR_POLLS_RESULTS,
    payload,
});

export const setPollResultByIdLoading = (payload) => ({
    type: types.SET_POLL_RESULT_BY_ID_LOADING,
    payload,
});

export const setPollResultById = (payload) => ({
    type: types.SET_POLL_RESULT_BY_ID,
    payload,
});

export default {};
