// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h7oiMqxlpjkGhxNtladt {\n    height: 100%;\n    max-height: calc(100vh - 6.2rem);\n\n    padding: 2rem;\n\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.C7avlFvYalNmLxSRw4wZ {\n    color: var(--color-1);\n\n    font-size: 1.8rem;\n    letter-spacing: 0.05rem;\n    line-height: 2.1rem;\n    text-align: center;\n}\n\n.fX5twRYHkM3cbMP7jLeA {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 1rem;\n    justify-content: center;\n}\n\n@media only screen and (min-width: 1024px) or (min-height: 651px) {\n    .h7oiMqxlpjkGhxNtladt {\n        max-height: calc(100vh - 20rem);\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-fivesy-howto/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gCAAgC;;IAEhC,aAAa;;IAEb,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;;IAErB,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI;QACI,+BAA+B;IACnC;AACJ","sourcesContent":[".content {\n    height: 100%;\n    max-height: calc(100vh - 6.2rem);\n\n    padding: 2rem;\n\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.text {\n    color: var(--color-1);\n\n    font-size: 1.8rem;\n    letter-spacing: 0.05rem;\n    line-height: 2.1rem;\n    text-align: center;\n}\n\n.letters {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 1rem;\n    justify-content: center;\n}\n\n@media only screen and (min-width: 1024px) or (min-height: 651px) {\n    .content {\n        max-height: calc(100vh - 20rem);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "h7oiMqxlpjkGhxNtladt",
	"text": "C7avlFvYalNmLxSRw4wZ",
	"letters": "fX5twRYHkM3cbMP7jLeA"
};
export default ___CSS_LOADER_EXPORT___;
