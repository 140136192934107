import * as types from "./types.js";


export const setSelectedStudent = (payload) => ({
    type: types.SET_GUARDIAN_SELECTED_STUDENT,
    payload,
});

export const setStudents = (payload) => ({
    type: types.SET_GUARDIAN_STUDENTS,
    payload,
});
