import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const Button = (props) => {
    const {
        type,
        disabled,
        theme,
    } = props;

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonYellow]: theme === "yellow",
        [styles.buttonBlue]: theme === "blue",
        [styles.buttonOrange]: theme === "orange",
        [styles.buttonPurple]: theme === "purple",
    });

    return (
        <button
            type={type} // eslint-disable-line react/button-has-type
            disabled={disabled}
            className={buttonClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
        >
            {props.children}
        </button>
    );
};

Button.defaultProps = {
    type: "submit",
    theme: "",
    onClick: () => { },
    disabled: false,
};

export default Button;
