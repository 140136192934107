import text from "@/base/text/index.js";


const moveDepartment = (services, params) => {
    const { api, actions } = services;
    const {
        districtId,
        schoolId,
        departmentId,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiAdminPopups.setDepartmentMoveLoading(true));

        const res = await api.admin.moveDepartment({
            session: state.user.session,
            districtId,
            schoolId,
            departmentId,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.uiAdminPopups.setDepartmentMoveSuccess());
        } else {
            error = res.error || text.error;
            dispatch(actions.uiAdminPopups.setDepartmentMoveLoading(false));
        }

        return {
            error,
        };
    };
};


export default {
    moveDepartment,
};
