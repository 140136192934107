import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import { hasEmptyValue, filterByKeys } from "@/base/lib/object.js";
import v from "@/base/lib/form-validators.js";

import ButtonBig from "@/base/components/button-big/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";

import Input from "@/base/components/forms/input/index.js";
import InputCard from "@/base/components/forms/input-card/index.js";
import FormRow from "@/base/components/forms/form-row/index.js";
import Error from "@/base/components/forms/error/index.js";
import Select from "@/base/components/forms/select/index.js";
import Checkbox from "@/base/components/forms/checkbox/index.js";

import CouponAttached from "@/base/business/coupon-attached/index.js";

import styles from "./styles.module.css";


const UserCheckout = (props) => {
    const [cardState, setCardState] = useState({
        error: null,
        complete: false,
    });

    useEffect(() => {
        setCardState({
            error: null,
            complete: false,
        });
    }, [props.theme]);

    const onCardChange = (e) => {
        let error = null;

        if (e?.error?.message) {
            error = e.error.message;
        }

        setCardState({
            error,
            complete: e.complete,
        });
    };

    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        const filteredValues = filterByKeys(
            formProps.values,
            [
                "isAutoRenew",
                "line2",
                "couponCode",
            ],
        );

        return hasEmptyValue(filteredValues)
            || hasErrors
            || formProps.isSubmitting
            || cardState.error
            || !cardState.complete
            || props.couponState.isLoading;
    };

    const validateForm = (values) => {
        const errorsName = v.validate(values.name, [
            v.required("Please enter name"),
        ]);

        const errorsLine1 = v.validate(values.line1, [
            v.required("Address line 1 is required"),
        ]);

        const errorsCity = v.validate(values.city, [
            v.required("City is required"),
        ]);

        const errorsState = v.validate(values.state, [
            v.required("State is required"),
        ]);

        const errorsZip = v.validate(values.zip, [
            v.required("ZIP is required"),
        ]);

        const errors = {};

        if (errorsName) {
            errors.name = errorsName;
        }

        if (errorsLine1) {
            errors.line1 = errorsLine1;
        }

        if (errorsCity) {
            errors.city = errorsCity;
        }

        if (errorsState) {
            errors.state = errorsState;
        }

        if (errorsZip) {
            errors.zip = errorsZip;
        }

        return errors;
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <Error>
                {props.error}
            </Error>
        );
    };

    const renderAutoRenew = (formProps) => {
        if (!props.withAutoRenew) {
            return (
                <div className={styles.formCheckboxes} />
            );
        }

        return (
            <div className={styles.formCheckboxes}>
                <Checkbox
                    name="isAutoRenew"
                    label="Auto Renew Yearly Subscription"
                    checked={formProps.values.isAutoRenew}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    onChange={formProps.handleChange}
                />
            </div>
        );
    };

    const renderSubmitButton = (formProps) => {
        let label = "Next";

        if (props.submitButtonLabel) {
            label = props.submitButtonLabel;
        }

        if (formProps.isSubmitting) {
            label = "Processing...";
        }

        return (
            <ButtonBig
                disabled={isDisabled(formProps)}
                isDisabledTabIndex={props.isDisabledTabIndex}
                type="submit"
            >
                {label}
            </ButtonBig>
        );
    };

    const renderSupportButton = () => {
        if (!props.showContactSupportButton) {
            return null;
        }

        return (
            <ButtonBig
                outlined
                type="button"
                isDisabledTabIndex={props.isDisabledTabIndex}
                onClick={props.onContactSupport}
            >
                Contact Support
            </ButtonBig>
        );
    };

    const renderCoupon = (formProps) => {
        if (!props.withCoupon) {
            return null;
        }

        if (props.couponState.isSubmitted && !props.couponState.error) {
            if (props.couponState.isLoading) {
                return (
                    <div className={styles.couponLoader}>
                        <RequestLoader />
                    </div>
                );
            }

            return (
                <CouponAttached
                    couponCode={props.couponState.couponCode}
                    discountMessage={props.couponState.discountMessage}
                />
            );
        }

        const {
            values,
            handleChange,
            handleBlur,
            isSubmitting,
        } = formProps;

        const isApplyDisabled = !values.couponCode || isSubmitting;

        return (
            <div className={styles.couponForm}>
                <Input
                    name="couponCode"
                    label="Coupon Code"
                    value={values.couponCode}
                    error={props.couponState.error}
                    isDisabledMinHeight
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    onChange={(evt) => {
                        handleChange(evt);
                        props.couponState.onChange(evt.target.value);
                    }}
                    onBlur={handleBlur}
                />
                <div className={styles.couponFormControls}>
                    <ButtonBig
                        type="button"
                        onClick={() => {
                            props.couponState.onApply(values.couponCode);
                        }}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        disabled={isApplyDisabled}
                    >
                        Apply
                    </ButtonBig>
                </div>
            </div>
        );
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formBlock}>
                    <div className={styles.formBlockName}>
                        Payment details
                    </div>
                    <FormRow>
                        <Input
                            name="name"
                            label="Name on Card *"
                            value={values.name}
                            error={v.getError(formProps, "name")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />

                        <InputCard
                            key={`card-${props.theme}`}
                            theme={props.theme}
                            error={cardState.error}
                            onChange={onCardChange}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />
                    </FormRow>
                </div>

                <div className={styles.formBlock}>
                    <div className={styles.formBlockName}>
                        Billing Address Details
                    </div>
                    <FormRow>
                        <Input
                            name="line1"
                            label="Address Line 1"
                            value={values.line1}
                            error={v.getError(formProps, "line1")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />
                        <Input
                            name="line2"
                            label="Address Line 2"
                            value={values.line2}
                            error={v.getError(formProps, "line2")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />
                    </FormRow>
                    <FormRow>
                        <Input
                            name="city"
                            label="City"
                            value={values.city}
                            error={v.getError(formProps, "city")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />
                        <Select
                            name="state"
                            label="State"
                            selected={values.state}
                            options={props.states}
                            error={v.getError(formProps, "state")}
                            isCreatable
                            isSearchable
                            onSelect={(val) => {
                                setFieldValue("state", val);
                            }}
                            onBlur={() => {
                                setFieldTouched("state");
                            }}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />
                        <Input
                            name="zip"
                            label="ZIP"
                            value={values.zip}
                            error={v.getError(formProps, "zip")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabledTabIndex={props.isDisabledTabIndex}
                        />
                    </FormRow>
                </div>

                {renderAutoRenew(formProps)}

                {renderCoupon(formProps)}

                {renderError()}

                <div className={styles.buttons}>
                    {renderSubmitButton(formProps)}
                    {renderSupportButton()}
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserCheckout.defaultProps = {
    initialValues: {
        name: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: "",
        isAutoRenew: false,
    },

    theme: "",

    submitButtonLabel: "",
    withAutoRenew: true,

    withCoupon: false,
    couponState: {
        isSubmitted: false,
        isLoading: false,
        couponCode: "",
        discountMessage: "",
        error: "",
        onApply: () => { },
        onChange: () => { },
    },

    showContactSupportButton: false,
    states: [],
    error: null,

    isDisabledTabIndex: false,

    onContactSupport: () => { },
    onSubmit: () => { },
};

export default UserCheckout;
