const isDashboard = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/dashboard") === 0;
};

/* --- */

const isClass = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/class") === 0;
};

const isClassProgress = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/class/progress") === 0;
};

/* --- */

const isClassDistrictAdmin = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/district-admin/class") === 0;
};

const isClassProgressDistrictAdmin = (url) => {
    const urlStr = url || "";

    return urlStr.startsWith("/district-admin/class")
        && (urlStr.endsWith("/progress") || urlStr.includes("/progress/"));
};

/* --- */

const isClassAdmin = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/admin/class") === 0;
};

const isClassProgressAdmin = (url) => {
    const urlStr = url || "";

    return urlStr.startsWith("/admin/class")
        && (urlStr.endsWith("/progress") || urlStr.includes("/progress/"));
};

/* --- */

const isDashboardDistrictAdmin = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/district-admin/dashboard") === 0;
};

const isDashboardAdmin = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/admin/dashboard") === 0;
};


export default {
    isDashboard,

    isClass,
    isClassProgress,
    isClassDistrictAdmin,
    isClassProgressDistrictAdmin,
    isClassAdmin,
    isClassProgressAdmin,

    isDashboardDistrictAdmin,
    isDashboardAdmin,
};
