import array from "@/base/lib/array.js";


const getDefaultLanguageCode = () => {
    return "es";
};

const getLanguagesOptions = (languages) => {
    return (languages || []).map((lang) => {
        return {
            id: lang.id,
            name: lang.name,
            value: lang.code,
            direction: lang.direction || "",
        };
    });
};

const getLanguageByCode = (languages, code) => {
    if (!code) {
        return null;
    }

    return array.findByFieldName(languages, "code", code);
};

const getLanguageIdByCode = (languages, code) => {
    const lang = array.findByFieldName(languages, "code", code);
    return lang?.id || null;
};

const isCyrillic = (code) => {
    const cyrillicLanguages = [
        "bg",
        "kk",
        "mk",
        "mn",
        "ru",
        "sr",
        "uk",
    ];

    return cyrillicLanguages.includes(code);
};

const isRightToLeft = (direction) => {
    return direction === "rtl";
};

const isSupportDyslexicFont = (language) => {
    if (!language) {
        return false;
    }

    const isCRL = isCyrillic(language?.value);
    const isRTL = isRightToLeft(language?.direction);

    return !(isCRL || isRTL);
};

export default {
    getDefaultLanguageCode,
    getLanguagesOptions,
    getLanguageByCode,
    getLanguageIdByCode,

    isCyrillic,
    isRightToLeft,
    isSupportDyslexicFont,
};
