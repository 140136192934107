import React from "react";

import LoaderSmall from "@/base/components/loader-small/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";
import MessageSuccess from "@/base/components/message-success/index.js";

import styles from "./styles.module.css";


const ClassRow = (props) => {
    const renderStatus = () => {
        if (props.isLoading) {
            return (
                <LoaderSmall />
            );
        }

        if (props.error) {
            return (
                <MessageDanger>
                    {props.error}
                </MessageDanger>
            );
        }

        if (props.isSuccess) {
            return (
                <MessageSuccess>
                    Done
                </MessageSuccess>
            );
        }

        return null;
    };

    return (
        <div className={styles.classRow}>
            <div>
                {props.name}
            </div>
            <div>
                {renderStatus()}
            </div>
        </div>
    );
};

ClassRow.defaultProps = {
    name: "",
    error: "",
    isLoading: false,
};

export default ClassRow;
