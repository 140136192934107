import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconStatistic = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM6 14H4V9H6V14ZM10 14H8V11H10V14ZM10 9H8V7H10V9ZM14 14H12V4H14V14Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconStatistic;
