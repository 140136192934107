import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    templatesBySlug: {},
    importStudentsTemplate: "",
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEMPLATE_LOADING_BY_SLUG: {
            const { slug } = action.payload;
            const template = state.templatesBySlug[slug] || {};

            return {
                ...state,
                templatesBySlug: {
                    ...state.templatesBySlug,
                    [slug]: {
                        isLoading: true,
                        data: {},
                        ...template,
                    },
                },
            };
        }

        case types.SET_TEMPLATE_BY_SLUG: {
            const { slug, template } = action.payload;

            return {
                ...state,
                templatesBySlug: {
                    ...state.templatesBySlug,
                    [slug]: {
                        isLoading: false,
                        data: template,
                    },
                },
            };
        }

        case types.SET_TEMPLATE_IMPORT_STUDENTS: {
            const { link } = action.payload;

            return {
                ...state,
                importStudentsTemplate: link,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
