import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    juiceStories: {},
    extraJuices: {},
    words: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.T2S_SET_JUICE_STORY_LOADING: {
            const { juiceStories } = state;
            const { storyId } = action.payload;
            const juiceStory = juiceStories[storyId] || {};

            return {
                ...state,
                juiceStories: {
                    ...juiceStories,
                    [storyId]: {
                        ...juiceStory,
                        isLoading: true,
                    },
                },
            };
        }

        case types.T2S_SET_JUICE_STORY_ERROR: {
            const { juiceStories } = state;
            const { juiceId, storyId, error } = action.payload;
            const juiceStory = juiceStories[storyId] || {};

            return {
                ...state,
                juiceStories: {
                    ...juiceStories,
                    [storyId]: {
                        ...juiceStory,
                        isLoading: false,
                        juiceId: parseInt(juiceId, 10) || -1,
                        storyId,
                        error,
                    },
                },
            };
        }

        case types.T2S_SET_JUICE_STORY: {
            const { juiceStories } = state;
            const { juiceId, storyId, audioFiles } = action.payload;
            const juiceStory = juiceStories[storyId] || {};

            return {
                ...state,
                juiceStories: {
                    ...juiceStories,
                    [storyId]: {
                        ...juiceStory,
                        isLoading: false,
                        error: "",
                        juiceId: parseInt(juiceId, 10) || -1,
                        storyId,
                        audioFiles,
                    },
                },
            };
        }

        case types.T2S_CLEAR_JUICE_STORY: {
            const { juiceStories } = state;
            const { storyId } = action.payload;

            delete juiceStories[storyId];

            return {
                ...state,
                juiceStories: {
                    ...juiceStories,
                },
            };
        }

        /* --- */

        case types.T2S_SET_EXTRA_JUICE_LOADING: {
            const { extraJuices } = state;
            const { extraJuiceId } = action.payload;
            const juice = extraJuices[extraJuiceId] || {};

            return {
                ...state,
                extraJuices: {
                    ...extraJuices,
                    [extraJuiceId]: {
                        ...juice,
                        isLoading: true,
                    },
                },
            };
        }

        case types.T2S_SET_EXTRA_JUICE_ERROR: {
            const { extraJuices } = state;
            const { extraJuiceId, error } = action.payload;
            const juice = extraJuices[extraJuiceId] || {};

            return {
                ...state,
                extraJuices: {
                    ...extraJuices,
                    [extraJuiceId]: {
                        ...juice,
                        isLoading: false,
                        error,
                    },
                },
            };
        }

        case types.T2S_SET_EXTRA_JUICE: {
            const { extraJuices } = state;
            const { juiceId, extraJuiceId, audioFiles } = action.payload;
            const juice = extraJuices[extraJuiceId] || {};
            return {
                ...state,
                extraJuices: {
                    ...extraJuices,
                    [extraJuiceId]: {
                        ...juice,
                        isLoading: false,
                        error: "",
                        juiceId: parseInt(juiceId, 10) || -1,
                        extraJuiceId,
                        audioFiles,
                    },
                },
            };
        }

        case types.T2S_CLEAR_EXTRA_JUICE: {
            const { extraJuices } = state;
            const { extraJuiceId } = action.payload;

            delete extraJuices[extraJuiceId];

            return {
                ...state,
                extraJuices: {
                    ...extraJuices,
                },
            };
        }

        /* --- */

        case types.T2S_SET_WORD_LOADING: {
            const { words } = state;
            const { text } = action.payload;
            const word = words[text] || {};

            return {
                ...state,
                words: {
                    ...words,
                    [text]: {
                        ...word,
                        isLoading: true,
                    },
                },
            };
        }

        case types.T2S_SET_WORD_ERROR: {
            const { words } = state;
            const { text, error } = action.payload;
            const word = words[text] || {};

            return {
                ...state,
                words: {
                    ...words,
                    [text]: {
                        ...word,
                        isLoading: false,
                        error,
                    },
                },
            };
        }

        case types.T2S_SET_WORD: {
            const { words } = state;
            const { text, audioFiles } = action.payload;
            const word = words[text] || {};

            return {
                ...state,
                words: {
                    ...words,
                    [text]: {
                        ...word,
                        isLoading: false,
                        audioFiles,
                    },
                },
            };
        }

        case types.T2S_CLEAR_WORD: {
            const { words } = state;
            const { text } = action.payload;

            delete words[text];

            return {
                ...state,
                words: {
                    ...words,
                },
            };
        }

        /* --- */

        case types.T2S_SET_HIGHLIGHT_LOADING: {
            const { trackGroupName, trackId } = action.payload;

            const trackByName = state?.[trackGroupName]?.[trackId] || {};

            return {
                ...state,
                [trackGroupName]: {
                    ...state.trackGroupName,
                    [trackId]: {
                        ...trackByName,
                        isHighlightLoaded: false,
                    },
                },
            };
        }

        case types.T2S_SET_HIGHLIGHT: {
            const { trackGroupName, trackId, highlight } = action.payload;

            const trackByName = state?.[trackGroupName]?.[trackId] || {};

            return {
                ...state,
                [trackGroupName]: {
                    ...state.trackGroupName,
                    [trackId]: {
                        ...trackByName,
                        highlight,
                        isHighlightLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
