import React from "react";

import IconCard from "@/base/icons/card/index.js";
import IconCoupon from "@/base/icons/coupon/index.js";
import IconDistrict from "@/base/icons/district/index.js";
import IconDepartment from "@/base/icons/department/index.js";
import IconGraduationCap from "@/base/icons/graduation-cap/index.js";
import IconLocation from "@/base/icons/location/index.js";
import IconMessage from "@/base/icons/message/index.js";
import IconMessageOutlined from "@/base/icons/message-outlined/index.js";
import IconPassword from "@/base/icons/password/index.js";
import IconPencil from "@/base/icons/pencil/index.js";
import IconReset from "@/base/icons/reset/index.js";
import IconTrial from "@/base/icons/trial/index.js";
import IconUser from "@/base/icons/user/index.js";
import IconUserIdentification from "@/base/icons/user-identification/index.js";

import MenuRow from "@/base/components/menu-row/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";

import styles from "./styles.module.css";


const ProfileRow = (props) => {
    const getName = () => {
        if (props.customName) {
            return props.customName;
        }

        if (props.isEmailNotification) {
            return "Daily Juice Email Notifications";
        }

        if (props.isAutoRenewPlan) {
            return "Auto-Renew Plan";
        }

        if (props.isCardName) {
            return "Name on card";
        }

        if (props.isCardNumber) {
            return "Card number";
        }

        if (props.isExpiration) {
            return "Free trial";
        }

        if (props.isZipCode) {
            return "ZIP code";
        }

        if (props.isCoupon) {
            return "Coupon Code";
        }

        if (props.isOrder) {
            return "Order Total";
        }

        if (props.isEmail) {
            return "email";
        }

        if (props.isPassword) {
            return "password";
        }

        if (props.isName) {
            return "Name";
        }

        if (props.isUsername) {
            return "Username";
        }

        if (props.isDistrict) {
            return "District";
        }

        if (props.isSchool) {
            return "School";
        }

        if (props.isDepartment) {
            return "Department";
        }

        return "";
    };

    const getLeftControl = () => {
        if (props.customLeftControl) {
            return props.customLeftControl;
        }

        if (props.isEmail) {
            return (
                <IconMessage
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isEmailNotification) {
            return (
                <IconMessageOutlined
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isAutoRenewPlan) {
            return (
                <IconReset
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isCardName) {
            return (
                <IconUser
                    className={styles.fieldIcon}
                    isBlack
                />
            );
        }

        if (props.isCardNumber) {
            return (
                <IconCard
                    className={styles.fieldIcon}
                    isBlack
                />
            );
        }

        if (props.isExpiration) {
            return (
                <IconTrial
                    className={styles.fieldIcon}
                    isBlack
                />
            );
        }

        if (props.isZipCode) {
            return (
                <IconLocation
                    className={styles.fieldIcon}
                    isBlack
                />
            );
        }

        if (props.isCoupon) {
            return (
                <IconCoupon
                    className={styles.fieldIcon}
                    isBlack
                />
            );
        }

        if (props.isPassword) {
            return (
                <IconPassword
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isName) {
            return (
                <IconUser
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isUsername) {
            return (
                <IconUserIdentification
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isDistrict) {
            return (
                <IconDistrict
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isSchool) {
            return (
                <IconGraduationCap
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        if (props.isDepartment) {
            return (
                <IconDepartment
                    className={styles.fieldIcon}
                    isSky
                />
            );
        }

        return <div />;
    };

    const getRightControl = () => {
        if (props.customRightControl) {
            return props.customRightControl;
        }

        let icon = null;

        if (props.isName) {
            icon = (
                <IconPencil isBlack />
            );
        }

        if (props.isEmail) {
            icon = (
                <IconPencil isBlack />
            );
        }

        if (props.isPassword) {
            icon = (
                <IconReset isBlack />
            );
        }

        if (!icon) {
            return <div />;
        }

        return (
            <ButtonCircle
                icon={icon}
                onClick={props.onClickRightControl}
            />
        );
    };

    const name = getName();
    const leftControl = getLeftControl();
    const rightControl = getRightControl();

    return (
        <MenuRow
            className={props.className}
            name={name}
            value={props.value}
            leftControl={leftControl}
            rightControl={rightControl}
            boldName={props.boldName}
            withValueEllipsis={props.withValueEllipsis}
        />
    );
};

ProfileRow.defaultProps = {
    isEmail: false,
    isPassword: false,
    isEmailNotification: false,
    isName: false,
    isUsername: false,
    isDistrict: false,
    isSchool: false,
    isDepartment: false,
    isAutoRenewPlan: false,
    isCardName: false,
    isOrder: false,
    isCardNumber: false,
    isExpiration: false,
    isZipCode: false,
    isCoupon: false,

    boldName: false,
    withValueEllipsis: true,

    className: "",

    customName: "",
    customLeftControl: null,
    customRightControl: null,

    value: "",

    onClickRightControl: () => { },
};

export default ProfileRow;
