import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAudioPlay = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <circle
                        cx="13"
                        cy="13"
                        r="12"
                        stroke="#48494b"
                        strokeWidth="1"
                    />
                    <path
                        fill="currentColor"
                        d="M13.61,10.05,12,8.84A.92.92,0,0,0,10.75,9a1.25,1.25,0,0,0-.18.52v6.85a.82.82,0,0,0,.87.87,1.2,1.2,0,0,0,.52-.18l1.65-1.21,3.2-2.25A.78.78,0,0,0,17,12.39l-.18-.17Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAudioPlay;
