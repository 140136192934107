import * as types from "./types.js";


export const setClassInfoLoading = (payload) => ({
    type: types.SET_STUDENT_CLASSES_CLASS_INFO_LOADING,
    payload,
});

export const setClassInfo = (payload) => ({
    type: types.SET_STUDENT_CLASSES_CLASS_INFO,
    payload,
});

export const setClassesLoading = (payload) => ({
    type: types.SET_STUDENT_CLASSES_LOADING,
    payload,
});

export const setClasses = (payload) => ({
    type: types.SET_STUDENT_CLASSES,
    payload,
});
