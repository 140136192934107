// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UxoXlwpG6QElNnOKHmAZ {\n    position: relative;\n    height: 0.5rem;\n\n    background-color: var(--player-audio-playback-bg-color);\n}\n\n.ooBe9v3TSgcU_tgqIsf2 {\n    position: absolute;\n    top: 0;\n    right: 100%;\n    bottom: 0;\n    left: 0;\n\n    background-color: var(--player-audio-playback-progress-bg-color);\n}\n\n", "",{"version":3,"sources":["webpack://./base/components/player-audio-playback/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;;IAEd,uDAAuD;AAC3D;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,SAAS;IACT,OAAO;;IAEP,gEAAgE;AACpE","sourcesContent":[".container {\n    position: relative;\n    height: 0.5rem;\n\n    background-color: var(--player-audio-playback-bg-color);\n}\n\n.progress {\n    position: absolute;\n    top: 0;\n    right: 100%;\n    bottom: 0;\n    left: 0;\n\n    background-color: var(--player-audio-playback-progress-bg-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UxoXlwpG6QElNnOKHmAZ",
	"progress": "ooBe9v3TSgcU_tgqIsf2"
};
export default ___CSS_LOADER_EXPORT___;
