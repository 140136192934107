import text from "@/base/text/index.js";

import Achievements from "@/base/project/achievements.js";


const loadStudentAchievements = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.studentAchievements.setAchievementsLoading());

        const res = await api.students.getStudentAchievements({
            session: state.user.session,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.data || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAchievements.setAchievements({
            data,
            error,
        }));
    };
};

const reloadStudentAchievements = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.students.getStudentAchievements({
            session: state.user.session,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.data || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAchievements.setAchievements({
            data,
            error,
        }));
    };
};

const loadOrReloadStudentAchievements = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        if (state.studentAchievements.isLoaded) {
            dispatch(reloadStudentAchievements(services));
            return;
        }

        dispatch(loadStudentAchievements(services));
    };
};

const updateStudentAchievements = (services, params) => {
    const { api } = services;
    const { type } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const value = Achievements.getAchievementsValueByType(state, type);

        const res = await api.student.updateAchievements({
            session: state.user.session,
            type,
            value,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const addStoriesRead = (services) => {
    const { actions } = services;

    return async (dispatch) => {
        dispatch(actions.studentAchievements.addStoriesRead());

        dispatch(updateStudentAchievements(services, {
            type: Achievements.getValueStoriesRead(),
        }));
    };
};

const addQuestionsAttempted = (services) => {
    const { actions } = services;

    return async (dispatch) => {
        dispatch(actions.studentAchievements.addQuestionsAttempted());

        dispatch(updateStudentAchievements(services, {
            type: Achievements.getValueQuestionsAttempted(),
        }));
    };
};


export default {
    loadStudentAchievements,
    reloadStudentAchievements,
    loadOrReloadStudentAchievements,
    updateStudentAchievements,
    addStoriesRead,
    addQuestionsAttempted,
};
