import React from "react";

import IconAnnouncement from "@/base/icons/announcement/index.js";

import Text from "@/base/components/text/index.js";
import MessageErrorWithTryAgain from "@/base/components/message-error-with-try-again/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import MessageSuccess from "@/base/components/message-success/index.js";

import SectionStudentV2 from "@/base/business/section-student-v2/index.js";

import stylesClassAnnouncement from "@/base/components/class-announcement/styles.module.css";
import styles from "./styles.module.css";


const SectionAnnouncement = (props) => {
    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return (
                <div className={styles.content}>
                    <MessageErrorWithTryAgain onTryAgain={props.onReload}>
                        {props.error}
                    </MessageErrorWithTryAgain>
                </div>
            );
        }

        if (!props.content) {
            return (
                <div className={styles.content}>
                    <MessageSuccess>
                        Announcement not found
                    </MessageSuccess>
                </div>
            );
        }

        return (
            <div className={styles.content}>
                <Text className={stylesClassAnnouncement.content}>
                    {props.content}
                </Text>
            </div>
        );
    };

    return (
        <SectionStudentV2
            title={props.title}
            icon={<IconAnnouncement isOrange />}
        >
            {renderContent()}
        </SectionStudentV2>
    );
};

SectionAnnouncement.defaultProps = {
    title: "Class announcement",
    error: "",
    content: null,
    onReload: () => { },
    isLoading: false,
};

export default SectionAnnouncement;
