import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconArrowInCircleDown = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M5.00027 5.70681L3.96413 4.67068C3.86151 4.56705 3.74086 4.51524 3.6022 4.51524C3.46353 4.51524 3.34477 4.56705 3.24592 4.67068C3.1423 4.76953 3.09098 4.88903 3.09198 5.02919C3.09297 5.16935 3.14403 5.28999 3.24516 5.39111L4.64071 6.78368C4.74545 6.88678 4.86597 6.93834 5.00227 6.93834C5.13857 6.93834 5.25801 6.88653 5.3606 6.7829L6.75347 5.39004C6.85387 5.28963 6.90507 5.17004 6.90707 5.03128C6.90906 4.89251 6.85825 4.77032 6.75462 4.6647C6.65299 4.56506 6.53279 4.51623 6.39402 4.51823C6.25525 4.52023 6.13556 4.57153 6.03495 4.67215L5.00027 5.70681ZM5.00372 10.4017C4.32715 10.4017 3.69122 10.2741 3.09596 10.0189C2.50069 9.76379 1.9799 9.41305 1.53357 8.96673C1.08725 8.5204 0.736508 7.99978 0.481358 7.40486C0.226208 6.80994 0.0986328 6.17308 0.0986328 5.4943C0.0986328 4.81551 0.226208 4.18056 0.481358 3.58946C0.736508 2.99836 1.08725 2.47965 1.53357 2.03333C1.9799 1.587 2.50052 1.23626 3.09543 0.981113C3.69036 0.725963 4.32721 0.598389 5.006 0.598389C5.68479 0.598389 6.31973 0.725963 6.91083 0.981113C7.50193 1.23626 8.02065 1.587 8.46697 2.03333C8.91329 2.47965 9.26403 2.9993 9.51918 3.59228C9.77433 4.18524 9.90191 4.82001 9.90191 5.49658C9.90191 6.17315 9.77433 6.80907 9.51918 7.40434C9.26403 7.9996 8.91329 8.5204 8.46697 8.96673C8.02065 9.41305 7.50099 9.76379 6.90802 10.0189C6.31505 10.2741 5.68029 10.4017 5.00372 10.4017ZM5.00027 9.36415C6.07563 9.36415 6.98849 8.98897 7.73886 8.23861C8.48921 7.48825 8.86439 6.57538 8.86439 5.50003C8.86439 4.42467 8.48921 3.51181 7.73886 2.76144C6.98849 2.01108 6.07563 1.6359 5.00027 1.6359C3.92491 1.6359 3.01205 2.01108 2.26168 2.76144C1.51132 3.51181 1.13615 4.42467 1.13615 5.50003C1.13615 6.57538 1.51132 7.48825 2.26168 8.23861C3.01205 8.98897 3.92491 9.36415 5.00027 9.36415Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconArrowInCircleDown;
