import React, {
    useCallback,
    useState,
    useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Achievements from "@/base/project/achievements.js";

import vocabularyActions from "@/base/actions/vocabulary.js";
import actions from "@/base/store/actions.js";

import LayoutContent from "@/base/components/layout-content/index.js";

import DailyJuiceExplore from "@/base/business/daily-juice-explore/index.js";
import DailyJuicePolls from "@/base/business/daily-juice-polls/index.js";
import DailyJuiceQuizResults from "@/base/business/daily-juice-quiz-results/index.js";
import DailyJuiceAchievements from "@/base/business/daily-juice-achievements/index.js";
import DailyJuiceCarousel from "@/base/business/carousel-daily-juice/index.js";

import StudentAnnouncements from "@/app/containers/student-announcements/index.js";
import PopupWord from "@/app/containers/popup-word/index.js";
import PopupDailyJuiceExplore, {
    usePopupDailyJuiceExplore,
} from "@/app/containers/popup-daily-juice-explore/index.js";
import UserPopupPolls from "@/app/containers/user-popup-polls/index.js";
import Tutorial from "@/app/containers/tutorial/index.js";
import UserFooter from "@/app/containers/user-footer/index.js";

import app from "@/app/app.js";

import styles from "./styles.module.css";


const RANGES = {
    weeks: "weeks",
    months: "months",
};

const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    theme: state.app.theme.theme,

    extraJuices: state.extraJuices,
    polls: state.polls,
    juicesVideos: state.juicesVideos,
    studentAchievements: state.studentAchievements.achievements,

    quizResults: state.student.quizResults,

    lastDailyJuice: state.studentDailyJuices.lastDailyJuice,
});

const StudentIndexRegularV2 = () => {
    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const navigate = useNavigate();

    const popupDailyJuiceExplore = usePopupDailyJuiceExplore();

    const isMobile400 = store.dimensions?.width <= 401;

    /* --- */

    const loadLastDailyJuice = useCallback(() => {
        app.actions.student.dailyJuices.loadLastDailyJuice();
    }, []);

    const loadFirstExtraJuices = useCallback(() => {
        app.actions.student.extraJuices.loadFirstExtraJuices({
            onlyFirst: true,
        });
    }, []);

    const loadExtraJuices = useCallback(() => {
        app.actions.student.extraJuices.loadMoreExtraJuices();
    }, []);

    const loadFirstVideos = useCallback(() => {
        app.actions.common.juicesVideos.loadFirstVideos({
            onlyFirst: true,
        });
    }, []);

    const loadVideos = useCallback(() => {
        app.actions.common.juicesVideos.loadMoreVideos();
    }, []);

    const loadQuizResultsByRange = useCallback((values) => {
        if (values.type === RANGES.weeks) {
            app.actions.student.quizzes.loadQuizWeeksResults({
                amount: values.amount,
            });
        } else if (values.type === RANGES.months) {
            app.actions.student.quizzes.loadQuizMonthsResults({
                amount: values.amount,
            });
        }
    }, []);

    /* --- */

    const onOpenUserPollsPopup = () => {
        app.actions.common.polls.preloadFirstClosedPolls();
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    const onExploreVideoClick = (video) => {
        const { videoID, newsTitle } = video;

        popupDailyJuiceExplore.openVideos();
        popupDailyJuiceExplore.selectVideo(videoID, newsTitle);

        app.actions.common.juicesVideos.loadVideoById({
            id: videoID,
        });
    };

    const onExploreExtraJuiceClick = (extraJuiceId) => {
        popupDailyJuiceExplore.openExtraJuices();
        popupDailyJuiceExplore.selectExtraJuice(extraJuiceId);
    };

    /* --- */

    useEffect(() => {
        app.actions.common.polls.loadFirstClosedPolls({
            onlyFirst: true,
        });

        app.actions.student.achievements.loadOrReloadStudentAchievements();

        loadLastDailyJuice();

        if (store.extraJuices.page === 0 && !store.extraJuices.isExtraJuicesLoaded) {
            loadFirstExtraJuices();
        }

        if (store.juicesVideos.page === 0 && !store.juicesVideos.isVideosLoaded) {
            loadFirstVideos();
        }

        app.actions.student.quizzes.loadQuizWeeksResults({
            amount: 1,
        });

        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
            dispatch(actions.polls.clearClosedPolls());
        };
    }, []);

    /* --- */

    const renderTutorial = () => {
        return (
            <Tutorial name="student-index" />
        );
    };

    const renderJuiceCard = () => {
        const { lastDailyJuice } = store;

        const juice = lastDailyJuice.data || {};
        const isLoading = !lastDailyJuice.isLoaded || lastDailyJuice.isLoading;

        return (
            <DailyJuiceCarousel
                juiceId={juice.id || null}
                juiceDate={juice.juiceDate || ""}
                stories={juice.stories?.juice || []}
                error={lastDailyJuice.error || ""}
                onTryAgain={loadLastDailyJuice}
                isLoading={isLoading}
            />
        );
    };

    const renderAchievements = () => {
        const { studentAchievements } = store;

        const data = studentAchievements.data || [];
        const achievements = Achievements.getAchievementValues(data);

        const isLoading = !studentAchievements.isLoaded
            || studentAchievements.isLoading;

        return (
            <DailyJuiceAchievements
                storiesRead={achievements.storiesRead}
                questionsAttempted={achievements.questionsAttempted}
                theme={store.theme}
                error={studentAchievements.error || ""}
                isLoading={isLoading}
            />
        );
    };

    const renderQuizResults = () => {
        return (
            <DailyJuiceQuizResults
                data={store.quizResults}
                onChangeTab={loadQuizResultsByRange}
                onGoToDailyJuices={() => {
                    navigate("/daily-juices");
                }}
                onGoToAssignments={() => {
                    navigate("/assignments");
                }}
                isMobile={isMobile400}
            />
        );
    };

    const renderUserPollsPopup = () => {
        if (!isVisibleUserPollsPopup) {
            return null;
        }

        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderPopupExplore = () => {
        if (!popupDailyJuiceExplore.state.isVisible) {
            return null;
        }

        return (
            <PopupDailyJuiceExplore
                defaultTab={popupDailyJuiceExplore.state.defaultTab}
                selectedExtraJuice={popupDailyJuiceExplore.state.selectedExtraJuice}
                selectedVideo={popupDailyJuiceExplore.state.selectedVideo}
                onSelectVideo={popupDailyJuiceExplore.selectVideo}
                onBackToList={popupDailyJuiceExplore.resetAllSelected}
                onSelectExtraJuice={popupDailyJuiceExplore.selectExtraJuice}
                onLoadMoreVideos={loadVideos}
                onLoadMoreExtraJuices={loadExtraJuices}
                onExtraJuiceShowPlayer={popupDailyJuiceExplore.showExtraJuicePlayer}
                onPlayerClose={popupDailyJuiceExplore.hideExtraJuicePlayer}
                onClose={popupDailyJuiceExplore.close}
            />
        );
    };

    const renderExplore = () => {
        return (
            <DailyJuiceExplore
                extraJuices={store.extraJuices.extraJuices}
                videos={store.juicesVideos.videos}
                isExtraJuicesLoaded={store.extraJuices.isExtraJuicesLoaded}
                isVideosLoaded={store.juicesVideos.isVideosLoaded}
                onViewMore={popupDailyJuiceExplore.open}
                onVideoClick={onExploreVideoClick}
                onExtraJuiceClick={onExploreExtraJuiceClick}
            />
        );
    };

    const renderLatestPoll = () => {
        const { polls } = store;

        const poll = polls?.closedPolls?.[0] || null;

        const isLoading = !polls.isClosedPollsLoaded
            || polls.isClosedPollsLoading;

        return (
            <DailyJuicePolls
                poll={poll}
                isLoading={isLoading}
                onViewMore={onOpenUserPollsPopup}
            />
        );
    };

    const renderWordPopup = () => {
        return (
            <PopupWord
                hideScrollbar={false}
            />
        );
    };

    return (
        <>
            {renderTutorial()}
            {renderPopupExplore()}
            {renderUserPollsPopup()}
            {renderWordPopup()}

            <LayoutContent>
                <div className={styles.container}>
                    <div className={styles.juiceCarousel}>
                        {renderJuiceCard()}
                    </div>
                    <div className={styles.bottomWidgets}>
                        {renderExplore()}
                        {renderLatestPoll()}
                    </div>
                    <div className={styles.asideWidgets}>
                        <StudentAnnouncements />
                        {renderAchievements()}
                        {renderQuizResults()}
                    </div>
                </div>
            </LayoutContent>

            <UserFooter />
        </>
    );
};


export default StudentIndexRegularV2;
