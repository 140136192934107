import * as types from "./types.js";


export const setProviders = (payload) => ({
    type: types.SET_LMS_PROVIDERS,
    payload,
});

export const setProvidersError = (payload) => ({
    type: types.SET_LMS_PROVIDERS_ERROR,
    payload,
});

export const setSyncingError = (payload) => ({
    type: types.SET_LMS_SYNCING_ERROR,
    payload,
});

export const setSyncingClasses = (payload) => ({
    type: types.SET_LMS_SYNCING_CLASSES,
    payload,
});

export const clearSyncingClasses = () => ({
    type: types.CLEAR_LMS_SYNC,
});

export default {};
