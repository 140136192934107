import array from "@/base/lib/array.js";

const SCHOOL_TYPES = [
    { value: "public", label: "Public School" },
    { value: "private", label: "Private/Other School" },
];


/* ------ */

const hasSameSchoolName = (schools, schoolName) => {
    if (!schools || schools.length === 0) {
        return false;
    }

    for (let i = 0; i < schools.length; i += 1) {
        const sName = schools[i].name || "";

        if (sName === schoolName) {
            return true;
        }
    }

    return false;
};

/* ------ */

const getSchoolsValues = (schools) => {
    return (schools || []).map((s) => ({
        value: s.id,
        name: s.name,
    }));
};

const getSchoolOptionsWithId = (schools) => {
    return (schools || []).map((s) => ({
        value: s.id,
        name: `${s.name} - ${s.id}`,
    }));
};

const getSchoolTypes = () => {
    return SCHOOL_TYPES;
};

const getPublicSchoolTypeValue = () => {
    return SCHOOL_TYPES[0].value;
};

const getPrivateSchoolTypeValue = () => {
    return SCHOOL_TYPES[1].value;
};

const getSchoolIdByName = (schools, name) => {
    const school = array.findByFieldName(schools, "name", name);

    if (school?.id) {
        return school.id;
    }

    return null;
};

const getSchoolNameById = (schools, id) => {
    const school = array.findOneById(schools, id);
    return school?.name || "";
};

/* ------ */

const isPublicSchoolType = (type) => {
    return type === SCHOOL_TYPES[0].value;
};

const isPrivateSchoolType = (type) => {
    return type === SCHOOL_TYPES[1].value;
};

/* ------ */

export default {
    hasSameSchoolName,

    getSchoolsValues,
    getSchoolOptionsWithId,
    getSchoolTypes,
    getPublicSchoolTypeValue,
    getPrivateSchoolTypeValue,
    getSchoolIdByName,
    getSchoolNameById,

    isPublicSchoolType,
    isPrivateSchoolType,
};
