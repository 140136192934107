const loadStudentsAchievements = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        if (!params.classId) {
            return;
        }

        const state = getState();

        dispatch(actions.teacherStudentsAchievements.setAchievementsLoading({
            classId: params.classId,
        }));

        const res = await api.teacherClasses.getStudentsAchievements({
            session: state.user.session,
            classId: params.classId,
        });

        const students = res?.students || [];

        dispatch(actions.teacherStudentsAchievements.setAchievements({
            classId: params.classId,
            students,
        }));
    };
};

const reloadStudentsAchievements = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        if (!params.classId) {
            return;
        }

        const state = getState();

        const res = await api.teacherClasses.getStudentsAchievements({
            session: state.user.session,
            classId: params.classId,
        });

        const students = res?.students || [];

        dispatch(actions.teacherStudentsAchievements.updateAchievements({
            classId: params.classId,
            students,
        }));

        const secondsInMS = 5 * 1000;

        globalThis.setTimeout(() => {
            dispatch(actions.teacherStudentsAchievements.clearUpdatedStatusAchievements({
                classId: params.classId,
                students,
            }));
        }, secondsInMS);
    };
};

const loadOrReloadStudentsAchievements = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const classId = state.navigation?.params?.classId || null;

        if (!classId) {
            return;
        }

        const isLoaded = state.teacherStudentsAchievements.achievementsByClass?.[classId];

        if (!isLoaded) {
            dispatch(loadStudentsAchievements(services, {
                classId,
            }));
            return;
        }

        dispatch(reloadStudentsAchievements(services, {
            classId,
        }));
    };
};

export default {
    loadStudentsAchievements,
    reloadStudentsAchievements,
    loadOrReloadStudentsAchievements,
};
