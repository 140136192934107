// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o5YC_Jkl065USO5R253N {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n    background-color: var(--transparent);\n\n    border: 0;\n    outline: 0;\n\n    cursor: pointer;\n\n    user-select: none;\n}\n\n.gNQnQ4UvpsezR2Bv_QK9 {\n    opacity: 0.4;\n}\n\n.T9iR7acFu2x1VLLOw6lE,\n._aE6dWL9H3r0mLLZHJH8 {\n    width: 3rem;\n}\n\n._aE6dWL9H3r0mLLZHJH8 {\n    transform: scaleX(-1);\n}\n\n.TwUeEDpqxCg52y5mqz5L {\n    visibility: hidden;\n    width: 0;\n    padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/horizontal-scrolling-menu/arrows.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;;IAEvB,oCAAoC;;IAEpC,SAAS;IACT,UAAU;;IAEV,eAAe;;IAEf,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd","sourcesContent":[".arrow {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n    background-color: var(--transparent);\n\n    border: 0;\n    outline: 0;\n\n    cursor: pointer;\n\n    user-select: none;\n}\n\n.arrowDisabled {\n    opacity: 0.4;\n}\n\n.arrowRight,\n.arrowLeft {\n    width: 3rem;\n}\n\n.arrowLeft {\n    transform: scaleX(-1);\n}\n\n.arrowHidden {\n    visibility: hidden;\n    width: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "o5YC_Jkl065USO5R253N",
	"arrowDisabled": "gNQnQ4UvpsezR2Bv_QK9",
	"arrowRight": "T9iR7acFu2x1VLLOw6lE",
	"arrowLeft": "_aE6dWL9H3r0mLLZHJH8",
	"arrowHidden": "TwUeEDpqxCg52y5mqz5L"
};
export default ___CSS_LOADER_EXPORT___;
