import text from "@/base/text/index.js";


const CANCEL_FREE_TRIAL = {
    cancelFreeTrial: "cancel_remove",
    cancelFreeAndStopDailyEmails: "stop_daily_keep_access",
};

const CANCEL_PAID = {
    cancelSubscription: "cancel_remove",
    cancelSubscriptionAndKeepDailyEmails: "keep_daily_keep_access",
    cancelSubscriptionAndStopDailyEmails: "stop_daily_keep_access",
};

/* --- */

const getPlanBillingCycles = () => {
    return {
        MONTHLY: "monthly",
        ANNUALLY: "annually",
    };
};

/* --- */

const isPaidCancel = (value) => {
    return value === CANCEL_PAID.cancelSubscription;
};

const isFreeCancel = (value) => {
    return value === CANCEL_FREE_TRIAL.cancelFreeTrial;
};

const isCancelImmediately = (value) => {
    return isFreeCancel(value) || isPaidCancel(value);
};

const isPaidCancelAndKeepEmails = (value) => {
    return value === CANCEL_PAID.cancelSubscriptionAndKeepDailyEmails;
};

const isPlanAnnually = (plan) => {
    const cycles = getPlanBillingCycles();

    if (plan) {
        return plan?.billingCycle === cycles.ANNUALLY;
    }

    return false;
};

const isPlanMonthly = (plan) => {
    const cycles = getPlanBillingCycles();

    if (plan) {
        return plan?.billingCycle === cycles.MONTHLY;
    }

    return false;
};

/* --- */

const getFreeTrialCancelPreferences = (expiration) => {
    return [
        {
            value: CANCEL_FREE_TRIAL.cancelFreeTrial,
            label: text.cancelFreeTrialDescription,
        },
        {
            value: CANCEL_FREE_TRIAL.cancelFreeAndStopDailyEmails,
            label: `I would like to cancel my Free trial, and STOP my daily email, but keep access to the platform for my students and myself until the expiration on ${expiration}.`,
        },
    ];
};

const getPaidCancelPreferences = (expiration) => {
    return [
        {
            value: CANCEL_PAID.cancelSubscription,
            label: text.cancelPaidSubscription,
        },
        {
            value: CANCEL_PAID.cancelSubscriptionAndKeepDailyEmails,
            label: `I would like to cancel any future payments and KEEP my daily email and access to the platform for my students and myself until the expiration on ${expiration}.`,
        },
        {
            value: CANCEL_PAID.cancelSubscriptionAndStopDailyEmails,
            label: `I would like to cancel any future payments and STOP my daily email, but keep access to the platform for my students and myself until the expiration on ${expiration}.`,
        },
    ];
};

const getDefaultCancelPreference = (hasPlan) => {
    if (hasPlan) {
        return CANCEL_PAID.cancelSubscription;
    }

    return CANCEL_FREE_TRIAL.cancelFreeTrial;
};

const getPlanByPlanId = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i];
        }
    }

    return {};
};

const getPlanBySlug = (plans, slug) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            return plans[i];
        }
    }

    return null;
};

/* --- */

const getPlanIdBySlug = (plans, slug) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            return plans[i].id;
        }
    }

    return null;
};

const getPlanNameBySlug = (plans, slug) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            return plans[i].name;
        }
    }

    return "";
};

const getPlanNameById = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i].name;
        }
    }

    return "";
};

/* --- */

const getPlanPriceById = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i].price;
        }
    }

    return "";
};

/* ------ */

const getPlanLimitsBySlug = (plans, slug) => {
    const limits = {
        maxStudents: 0,
        maxClasses: 0,
    };

    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            limits.maxClasses = plans[i].maxClasses;
            limits.maxStudents = plans[i].maxStudents;
            break;
        }
    }

    return limits;
};

/* ------ */

const getPlanFeaturesByPlanId = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i].features;
        }
    }

    return [];
};

/* --- */

export default {
    getPlanBillingCycles,

    isPlanAnnually,
    isPlanMonthly,
    isPaidCancel,
    isFreeCancel,
    isPaidCancelAndKeepEmails,
    isCancelImmediately,

    getFreeTrialCancelPreferences,
    getPaidCancelPreferences,
    getDefaultCancelPreference,
    getPlanByPlanId,
    getPlanBySlug,
    getPlanIdBySlug,
    getPlanNameBySlug,
    getPlanNameById,
    getPlanPriceById,
    getPlanLimitsBySlug,
    getPlanFeaturesByPlanId,
};
