const loadClassCategories = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { dateFrom, dateTo, classId } = state.navigation.params;

        if (!dateFrom || !dateTo || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        dispatch(actions.districtAdmin.setClassCategoriesLoading({
            classId,
            range,
        }));

        const res = await api.districtAdmin.getClassStoriesCategoriesByClassId({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.districtAdmin.setClassCategories({
            classId,
            range,
            data,
            error,
        }));
    };
};

const reloadClassCategories = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { dateFrom, dateTo, classId } = state.navigation.params;

        if (!dateFrom || !dateTo || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        const res = await api.districtAdmin.getClassStoriesCategoriesByClassId({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.districtAdmin.setClassCategories({
            classId,
            range,
            data,
            error,
        }));
    };
};

const loadOrReloadClassCategories = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo, classId } = state.navigation.params;

        if (!dateFrom || !dateTo || !classId) {
            return;
        }

        const { classCategoriesByRange } = state.districtAdmin;
        const range = `${dateFrom}-${dateTo}`;

        const isLoaded = classCategoriesByRange?.[classId]?.[range]?.data || false;

        if (isLoaded) {
            const reloadAction = reloadClassCategories(services);
            reloadAction(dispatch, getState);
            return;
        }

        const loadAction = loadClassCategories(services);
        loadAction(dispatch, getState);
    };
};

export default {
    loadClassCategories,
    reloadClassCategories,
    loadOrReloadClassCategories,
};
