import React from "react";

import RequestLoader from "@/base/components/request-loader/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";

import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";

import styles from "./styles.module.css";


const PopupConfirmMessage = (props) => {
    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.message}>
                {props.message}
            </div>
        );
    };

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={props.onClose}
                    disabled={props.isLoading}
                >
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmMessage.defaultProps = {
    isLoading: false,
    message: "",
    onClose: () => {},
};

export default PopupConfirmMessage;
