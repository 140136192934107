import * as types from "./types.js";


export const setPopups = (payload) => ({
    type: types.SET_POPUPS,
    payload,
});

export const setPopupOpened = (payload) => ({
    type: types.SET_POPUP_OPENED,
    payload,
});

export const setPopupClosing = (payload) => ({
    type: types.SET_POPUP_CLOSING,
    payload,
});

export const setPopupClosed = (payload) => ({
    type: types.SET_POPUP_CLOSED,
    payload,
});
