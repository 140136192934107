import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const POSITION_X = {
    left: "left",
    right: "right",
};

const POSITION_Y = {
    top: "top",
    bottom: "bottom",
};

const TooltipPosition = (props) => {
    const { positionX, positionY } = props;

    const className = classNames({
        [styles.tooltip]: true,

        [styles.tooltipTop]: positionY === POSITION_Y.top,
        [styles.tooltipBottom]: positionY === POSITION_Y.bottom,

        [styles.tooltipLeft]: positionX === POSITION_X.left,
        [styles.tooltipRight]: positionX === POSITION_X.right,
    });

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

TooltipPosition.defaultProps = {
    positionX: "",
    positionY: "",
    children: null,
};

export default TooltipPosition;
