import text from "@/base/text/index.js";


const loadDistricts = (services, params) => {
    const { api, actions } = services;
    const { search, page } = params;

    return async (dispatch, getState) => {
        const state = getState();

        if (search) {
            dispatch(actions.admin.setDistrictsByKeywordLoading({ search }));
        } else {
            dispatch(actions.admin.setDistrictsAllLoading());
        }

        const res = await api.admin.getDistricts({
            session: state.user.session,
            search,
            page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.districts || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        const payload = {
            data,
            error,
            hasMore,
            search,
        };

        if (search) {
            dispatch(actions.admin.setDistrictsByKeyword(payload));
        } else {
            dispatch(actions.admin.setDistrictsAll(payload));
        }

        return payload;
    };
};

const loadDistrictsBySearch = (services, params) => {
    const search = (params.search || "").trim();

    return (dispatch, getState) => {
        const state = getState();

        let page = 0;

        if (search) {
            page = state.admin.districtsByKeyword?.[search]?.page || 0;
        } else {
            page = state.admin.districtsAll?.page || 0;
        }

        dispatch(loadDistricts(services, {
            search,
            page,
        }));
    };
};

const reloadDistrictsBySearch = (services, params) => {
    const { actions, storage } = services;
    const search = (params.search || "").trim();

    return (dispatch) => {
        dispatch(actions.admin.clearDistricts());

        dispatch(loadDistricts(services, {
            search,
            page: 0,
        }));

        storage.session.saveSearchDistrict(search);
    };
};

const loadDistrictById = (services, params) => {
    const { actions, api } = services;
    const { districtId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.admin.setDistrictByIdLoading(districtId));

        const res = await api.admin.getDistrictById({
            session: state.user.session,
            districtId,
        });

        let error = "";
        let data = null;

        if (res.ok) {
            data = res.district || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.admin.setDistrictById({
            districtId,
            data,
            error,
        }));
    };
};

const editDistrict = (services, params) => {
    const { api } = services;
    const {
        districtId,
        districtName,
        country,
        subdivision,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.admin.editDistrict({
            session: state.user.session,
            districtId,
            districtName,
            country,
            subdivision,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};


export default {
    loadDistricts,
    loadDistrictsBySearch,
    reloadDistrictsBySearch,
    loadDistrictById,
    editDistrict,
};
