const isSuccess = (xhttp) => {
    if (!xhttp) {
        return false;
    }

    return xhttp.readyState === 4
        && xhttp.status === 200
        && xhttp.responseText;
};

const sendGET = (params = {}) => {
    if (!params.src) {
        return;
    }

    const xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = () => {
        if (isSuccess(xhttp)) {
            params.onSuccess?.(xhttp);
        }
    };

    xhttp.open("GET", params.src, true);
    xhttp.send();
};

export default {
    isSuccess,
    sendGET,
};
