import React from "react";

import date from "@/base/lib/date.js";
import parseTags from "@/base/lib/parse-tags.js";

import Text from "@/base/components/text/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import stylesCommon from "@/base/components/juice-content-common/styles.module.css";
import styles from "./styles.module.css";


const NotificationContent = (props) => {
    const renderFeaturedImage = () => {
        if (!props.imageUrl) {
            return null;
        }

        return (
            <div className={styles.featured}>
                <img
                    src={props.imageUrl}
                    alt={props.title || "Featured"}
                />
            </div>
        );
    };

    const renderContent = () => {
        const parsedContent = parseTags.parseShortcode(props.content).map((line) => {
            if (!parseTags.isTagShortcode(line)) {
                return `<div>${line}</div>`;
            }

            if (parseTags.isTagShortcodeName(line, "caption")) {
                const contentLines = parseTags.parse(line.tagContent, "<", "/>");

                if (contentLines.length === 0) {
                    return "";
                }

                const captionText = contentLines[1]
                    ? `<div class="${styles.contentCaptionText}">${contentLines[1]}</div>`
                    : "";

                return `<div class="${styles.contentCaptionImg}">${contentLines[0]}${captionText}</div>`;
            }

            return `<div>${line.tagContent}</div>`;
        }).join("");

        const textClassName = [
            stylesFonts.content,
            stylesCommon.content,
        ].join(" ");

        return (
            <Text className={textClassName}>
                {parsedContent}
            </Text>
        );
    };

    if (props.isLoading) {
        return (
            <RequestLoader />
        );
    }

    if (props.error) {
        return (
            <MessageDanger>
                {props.error}
            </MessageDanger>
        );
    }

    return (
        <div className={styles.notification}>
            {renderFeaturedImage()}
            <div>
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.date}>
                    {date.tryFormatDateUTC(props.date, date.formatMonthDayYearDate)}
                </div>
            </div>
            {renderContent()}
        </div>
    );
};

NotificationContent.defaultProps = {
    content: "",
    imageUrl: "",
    date: "",
    title: "",
    error: "",
    isLoading: false,
};

export default NotificationContent;
