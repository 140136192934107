import React from "react";

import styles from "./styles.module.css";


const IconSoundLoading = () => {
    return (
        <div className={styles.icon}>
            <div className={styles.spinner}>
                <div className={styles.circle1} />
            </div>
        </div>
    );
};

export default IconSoundLoading;
