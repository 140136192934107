const prepareActions = (services, actions) => {
    const res = {};

    const actionsNames = Object.keys(actions);

    actionsNames.forEach((actionName) => {
        res[actionName] = (params = {}) => {
            const action = actions[actionName](services, params);
            return services.store.dispatch(action);
        };
    });

    return res;
};

const prepareAllActions = (services, actionsByActor) => {
    const res = {};

    Object.keys(actionsByActor).forEach((actorName) => {
        const allActions = actionsByActor[actorName];

        res[actorName] = {};

        Object.keys(allActions).forEach((actionGroupName) => {
            const actions = allActions[actionGroupName];
            res[actorName][actionGroupName] = prepareActions(services, actions);
        });
    });


    return res;
};

export default {
    prepareActions,
    prepareAllActions,
};
