import React, { useState } from "react";

import Subscription from "@/base/project/subscription.js";

import date from "@/base/lib/date.js";

import text from "@/base/text/index.js";

import Accordion from "@/base/components/accordion/index.js";
import Switch from "@/base/components/switch/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import Message from "@/base/components/message/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import LoaderSmall from "@/base/components/loader-small/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";
import ProfileRow from "@/base/components/profile-row/index.js";

import settings from "@/app/settings.js";

import styles from "./styles.module.css";


const PlanDetails = (props) => {
    const [isOpenAccordion, setIsOpenAccordion] = useState(false);

    const userPlan = Subscription.getPlanByPlanId(props.plans?.plans, props.user?.planId);
    const userActivePlan = Subscription.getPlanByPlanId(
        props.plans?.activePlans,
        props.user?.planId,
    );

    const isEmptyPlan = Object.keys(userPlan).length === 0;
    const isEmptyActivePlan = Object.keys(userActivePlan).length === 0;

    /* --- */

    const isPaidUserWithNoPlan = () => {
        return isEmptyPlan && isEmptyActivePlan && !props.user?.isFree;
    };

    /* --- */

    const getPlanNameAndPrice = () => {
        let planName = "";
        let planPrice = "";

        if (props.user?.isFree) {
            const { daysLeftToExpire, expiration } = props.user;

            planPrice = "Free / No expiration";
            planName = "Free Trial";

            if (expiration && daysLeftToExpire > 0) {
                planPrice = `Free / ${date.tryFormatDate(expiration, date.formatShortMonthDayYearDate)}`;
            }

            return {
                planName,
                planPrice,
            };
        }

        if (!isEmptyPlan) {
            planName = userPlan?.name || "";

            if (Subscription.isPlanAnnually(userPlan)) {
                planPrice = `$${userPlan?.price} / year`;
            } else if (Subscription.isPlanMonthly(userPlan)) {
                planPrice = `$${userPlan?.price} / month`;
            }

            return {
                planName,
                planPrice,
            };
        }

        if (!isEmptyActivePlan) {
            planName = userActivePlan?.name || "";

            if (Subscription.isPlanAnnually(userActivePlan)) {
                planPrice = `$${userActivePlan?.price} / year`;
            } else if (Subscription.isPlanMonthly(userActivePlan)) {
                planPrice = `$${userActivePlan?.price} / month`;
            }

            return {
                planName,
                planPrice,
            };
        }

        return {
            planName: "",
            planPrice: "",
        };
    };

    const getPlanFeatures = () => {
        if (props.user?.isFree) {
            return props.plans?.freeTrialPlanFeatures || [];
        }

        if (!isEmptyPlan) {
            return userPlan?.features || [];
        }

        if (!isEmptyActivePlan) {
            return userActivePlan?.features || [];
        }

        return [];
    };

    /* --- */

    const onAccordionClick = () => {
        setIsOpenAccordion((prev) => !prev);
    };

    /* ------ */

    const renderAutoRenewRow = () => {
        if (!props.user?.hasPlan) {
            return null;
        }

        if (!Subscription.isPlanAnnually(userPlan)) {
            return null;
        }

        const loader = props.subscription?.isLoadingAutoRenewSubscription
            ? <LoaderSmall />
            : null;

        const isDisabled = props.subscription?.isLoadingAutoRenewSubscription
            || !props.plans?.isPlansLoaded;

        const rightControl = (
            <div className={styles.switchWithLoader}>
                {loader}

                <Switch
                    name="isAutoRenewEnabled"
                    disabled={isDisabled}
                    checked={props.subscription?.isAutoRenewEnabled}
                    onChange={props.subscription?.onChangeAutoRenew}
                />
            </div>
        );

        return (
            <ProfileRow
                customRightControl={rightControl}
                value={`Expires on ${props.subscription?.expireDate}`}
                isAutoRenewPlan
                boldName
            />
        );
    };

    const renderCancelSubscriptionButton = () => {
        const isFreeUser = props.user?.isFree || false;

        if (isPaidUserWithNoPlan()
            || (isFreeUser && !props.hasPrimaryClasses)) {
            return null;
        }

        const cancelAccountText = isFreeUser
            ? text.cancelFreeTrial
            : text.cancelSubscription;

        return (
            <ButtonBig
                outlined
                onClick={props.subscription?.onCancel}
            >
                {cancelAccountText}
            </ButtonBig>
        );
    };

    const renderUpgradeButton = () => {
        if (isEmptyPlan || !props.user?.hasPlan) {
            return null;
        }

        return (
            <ButtonBig
                disabled={!props.plans?.isPlansLoaded}
                onClick={props.subscription?.onUpgrade}
            >
                Upgrade plan
            </ButtonBig>
        );
    };

    const renderControls = () => {
        return (
            <div className={styles.personalInfoControls}>
                {renderAutoRenewRow()}

                {renderUpgradeButton()}
                {renderCancelSubscriptionButton()}

                <ButtonBig
                    outlined
                    onClick={props.onContactSupport}
                >
                    Contact Support
                </ButtonBig>
            </div>
        );
    };

    const renderContent = () => {
        if (!props.plans?.isPlansLoaded
            || !props.plans?.isActivePlansLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (isPaidUserWithNoPlan()) {
            const supportEmail = (
                <a
                    href={`mailto:${settings.supportEmail}`}
                >
                    {settings.supportEmail}
                </a>
            );

            return (
                <Message>
                    {text.userHasNoPaymentPlans}
                    {supportEmail}
                </Message>
            );
        }

        const features = getPlanFeatures();
        const { planName, planPrice } = getPlanNameAndPrice();

        return (
            <Accordion
                label={`The Juice ${planName} Plan`}
                label2={planPrice}
                onClick={onAccordionClick}
                expanded={isOpenAccordion}
            >
                <ul className={styles.planDetailsFeatures}>
                    {features.map((feat) => (
                        <li>{feat}</li>
                    ))}
                </ul>
            </Accordion>
        );
    };

    /* --- */

    return (
        <div className={styles.planDetailsContainer}>
            <WithScroll>
                <div className={styles.planDetails}>
                    {renderContent()}
                </div>
            </WithScroll>
            {renderControls()}
        </div>
    );
};

PlanDetails.defaultProps = {
    user: {},
    plans: {},
    subscription: {},
    onContactSupport: () => { },
    hasPrimaryClasses: false,
};

export default PlanDetails;
