import React, { useRef, useEffect, useState } from "react";

import useDimensions from "@/base/hooks/use-dimensions/index.js";

import classNames from "@/base/lib/class-names.js";
import scroll from "@/base/lib/scroll.js";
import styleProperties from "@/base/lib/style-properties.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import IconClose from "@/base/icons/close/index.js";

import WithScroll from "@/base/components/with-scroll/index.js";
import PopupConfirmUnsavedProgress from "@/base/components/popup-confirm-unsaved-progress/index.js";

import styles from "./styles.module.css";


const PopupWindow = (props) => {
    const popupLayout = useRef(null);
    // TODO: avoid conditions with hooks
    const withScrollRef = props.withScrollRef || useRef(null); // eslint-disable-line react-hooks/rules-of-hooks, max-len

    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleConfirmClose, setIsVisibleConfirmClose] = useState(false);

    const dimensions = useDimensions();

    const onAutoClose = () => {
        setIsVisible(false);

        const seconds = 1;

        globalThis.setTimeout(() => {
            props.onClose();
        }, seconds * 1000);
    };

    const onClose = (showConfirm) => {
        if (!isVisible) {
            return;
        }

        if (showConfirm) {
            setIsVisibleConfirmClose(true);
            return;
        }

        setIsVisible(false);

        globalThis.setTimeout(() => {
            props.onClose();
        }, 1000);
    };

    useEffect(() => {
        globalThis.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        if (props.autoClose) {
            const seconds = 3;

            globalThis.setTimeout(() => {
                onAutoClose();
            }, seconds * 1000);
        }

        if (props.hideScrollbar) {
            scroll.hideBodyScroll();
        }

        return () => {
            if (props.hideScrollbar) {
                scroll.unhideBodyScroll();
            }
        };
    }, []);

    const onLayoutClick = (evt) => {
        if (popupLayout?.current !== evt.target) {
            return;
        }

        props.onLayoutClick();

        if (props.hideClose) {
            return;
        }

        if (props.hideScrollbar) {
            scroll.unhideBodyScroll();
        }

        onClose(props.showConfirmOnClose);
    };

    const getPopupContentStyle = () => {
        const style = {};

        const popupTitleHeight = styleProperties.getHeightPopupTitle();
        const playerAudioHeight = styleProperties.getHeightPlayerAudioHeight();

        let popupHeight = dimensions.height;

        if (dimensions.width >= 1025) {
            popupHeight = dimensions.height - styleProperties.getPopupWindowTop();
        }

        style.maxHeight = popupHeight - popupTitleHeight;

        if (props.player) {
            style.maxHeight = popupHeight - popupTitleHeight - playerAudioHeight;
        }

        if (props.hideContentScroll) {
            style.overflow = "hidden";
        }

        return style;
    };

    const renderBarClose = () => {
        if (props.hideClose) {
            return null;
        }

        const isWhiteCloseIcon = props.pinkBar
            || props.greenBar
            || props.roseBar;

        return (
            <div className={styles.topTitleBarClose}>
                <IconClickable
                    className={styles.topTitleBarCloseIcon}
                    onClick={() => {
                        onClose(props.showConfirmOnClose);
                    }}
                >
                    <IconClose
                        isBlack={!isWhiteCloseIcon}
                        isWhite={isWhiteCloseIcon}
                    />
                </IconClickable>
            </div>
        );
    };

    const renderBar = () => {
        const barClassName = classNames({
            [styles.topTitleBar]: true,

            // NOTE: use without left control
            [styles.topTitleBarOrange]: props.orangeBar,
            [styles.topTitleBarPink]: props.pinkBar,
            [styles.topTitleBarGreen]: props.greenBar,
            [styles.topTitleBarRose]: props.roseBar,

            // NOTE: works with short titles
            [styles.topTitleBarCentered]: props.isTitleCentered,

            [props.barClassName]: props.barClassName,
        });

        const titleClassName = classNames({
            [styles.title]: true,
            [styles.titleToStart]: props.titleToStart,
        });

        const titleValueClassName = classNames({
            [styles.titleValue]: true,
            [styles.titleValueEllipsis]: props.withTitleEllipsis,
        });

        return (
            <div className={barClassName}>
                <div className={styles.leftControl}>
                    {props.leftControl}
                </div>

                <div className={titleClassName}>
                    <div className={titleValueClassName}>
                        {props.title}
                    </div>
                </div>

                <div className={styles.rightControl}>
                    {props.rightControl}
                    {renderBarClose()}
                </div>
            </div>
        );
    };

    const renderConfirmClose = () => {
        if (!isVisibleConfirmClose) {
            return null;
        }

        return (
            <PopupConfirmUnsavedProgress
                onLeave={() => {
                    setIsVisibleConfirmClose(false);
                    onClose(false);
                }}
                onClose={() => {
                    setIsVisibleConfirmClose(false);
                }}
            />
        );
    };

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupSmall]: props.isSmall,
        [props.popupClassName]: props.popupClassName,
        [styles.popupVisible]: isVisible,
        [styles.popupCentered]: isVisible && props.isCentered,
    });

    const windowClassName = classNames({
        [styles.popupWindow]: true,
        [styles.popupWindowRoundedCorners]: props.isCentered,
    });

    // TODO: add display:flex to styles.popupContent and check every popup
    const popupContentClassName = classNames({
        [styles.popupContent]: true,
        [styles.popupContentWithPlayer]: props.player,
        [styles.popupLayoutFlex]: props.isDisplayFlex,
    });

    const popupContentStyle = getPopupContentStyle();

    // NOTE: onKeyPress disabled to support key events inside PopupWindow
    return (
        <>
            <div
                ref={popupLayout}
                className={styles.popupLayout}
                onMouseDown={onLayoutClick}
                onKeyDown={() => { }}
                role="button"
                tabIndex="-1"
                data-comment="popup-window"
            >
                <div className={popupClassName}>
                    <div className={windowClassName}>
                        {renderBar()}

                        <WithScroll
                            scrollRef={withScrollRef}
                            style={popupContentStyle}
                            className={popupContentClassName}
                        >
                            {props.children}
                        </WithScroll>

                        {props.player}
                    </div>
                </div>
            </div>
            {renderConfirmClose()}
        </>
    );
};

PopupWindow.defaultProps = {
    title: null,
    leftControl: [],
    rightControl: [],
    player: null,

    orangeBar: false,
    pinkBar: false,
    greenBar: false,
    roseBar: false,
    barClassName: "",

    isCentered: false,
    isSmall: false,
    isDisplayFlex: false,
    titleToStart: false,
    isTitleCentered: false,
    withTitleEllipsis: true,

    withScrollRef: null,
    popupClassName: "",
    autoClose: false,
    hideClose: false,
    hideContentScroll: false,
    hideScrollbar: true,
    showConfirmOnClose: false,
    children: null,
    onLayoutClick: () => { },
    onClose: () => { },
};

export default PopupWindow;
