import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconCaret = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 10 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M0 0L5 5L10 0H0Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCaret;
