import React, { useState } from "react";

import IconImageNotFound from "@/base/icons/image-not-found/index.js";


const Image = (props) => {
    const [isLoadingError, setIsLoadingError] = useState(false);

    if (!props.src) {
        return null;
    }

    if (isLoadingError) {
        return (
            <IconImageNotFound
                title="Oops! We can't load this image."
                className={props.className}
                isBlack
            />
        );
    }

    return (
        <img
            src={props.src}
            alt={props.title}
            className={props.className}
            onError={() => { setIsLoadingError(true); }}
        />
    );
};

Image.defaultProps = {
    src: "",
    title: "",
    className: "",
};

export default Image;
