import React from "react";

import text from "@/base/text/index.js";

import IconCheck from "@/base/icons/check/index.js";

import TextWithIcon from "@/base/components/text-with-icon/index.js";

import styles from "./styles.module.css";

import NotificationBlock from "./notification-block.js";


const Notifications = (props) => {
    const renderNoDataMessage = () => {
        if (props.isLoading || props.error) {
            return null;
        }

        return (
            <div className={styles.noDataMessage}>
                <TextWithIcon
                    iconComponent={IconCheck}
                    text={text.noNotifications}
                    isGreen
                />
            </div>
        );
    };

    const renderNotifications = () => {
        if (!props.data.length) {
            return renderNoDataMessage();
        }

        return props.data.map((data) => (
            <NotificationBlock
                id={data.id}
                imageUrl={data.imageUrl}
                title={data.title}
                date={data.dateCreated}
                onMarkAsRead={props.onMarkAsRead}
                onReadMore={props.onReadMore}
                isContentExists={data.readMoreContentExist}
                isViewed={data.isViewed}
            />
        ));
    };

    /* --- */

    return (
        <div className={styles.notifications}>
            {renderNotifications()}
        </div>
    );
};

Notifications.defaultProps = {
    data: [],
    error: "",
    onMarkAsRead: () => { },
    onReadMore: () => { },
    isLoading: false,
};

export default Notifications;
