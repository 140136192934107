// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JFy4VPLjcYyO89oU73UQ {\n    cursor: pointer;\n    outline: none;\n    user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/icon-clickable/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".iconClickable {\n    cursor: pointer;\n    outline: none;\n    user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconClickable": "JFy4VPLjcYyO89oU73UQ"
};
export default ___CSS_LOADER_EXPORT___;
