export default (apiURL, methods) => {
    return {
        getTutorial(params = {}) {
            // NOTE: action=get_tutorial_by_slug
            const url = `${apiURL}/tutorials`;

            return methods.get(url, {
                session_id: params.session,
                tutorial_slug: params.slug,
            });
        },

        getTutorialVideoLink(params = {}) {
            // NOTE: action=get_tutorial_video_link
            const url = `${apiURL}/tutorials/video-link`;

            return methods.get(url, {
                session_id: params.session,
            });
        },
    };
};
