import * as types from "./types.js";


const setCompletedAssignmentsByPageLoading = (payload) => ({
    type: types.SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING,
    payload,
});

const setCompletedAssignmentsByPage = (payload) => ({
    type: types.SET_COMPLETED_ASSIGNMENTS_BY_PAGE,
    payload,
});

const clearCompletedAssignments = (payload) => ({
    type: types.CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE,
    payload,
});

/* ---- */

const setIncompleteAssignmentsByPageLoading = (payload) => ({
    type: types.SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING,
    payload,
});

const setIncompleteAssignmentsByPage = (payload) => ({
    type: types.SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE,
    payload,
});

const clearIncompleteAssignments = (payload) => ({
    type: types.CLEAR_INCOMPLETE_ASSIGNMENTS,
    payload,
});

/* ---- */

const setIncompleteAssignmentsByClassIdLoading = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING,
    payload,
});

const setIncompleteAssignmentsByClassId = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID,
    payload,
});

const clearIncompleteAssignmentsByClassId = (payload) => ({
    type: types.CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID,
    payload,
});

/* --- */

const setIncompleteAssignmentsByAllClassesLoading = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES_LOADING,
    payload,
});

const setIncompleteAssignmentsByAllClasses = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES,
    payload,
});

const clearIncompleteAssignmentsByAllClasses = (payload) => ({
    type: types.CLEAR_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_ALL_CLASSES,
    payload,
});

/* --- */

const setCompletedAssignmentsByClassIdLoading = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING,
    payload,
});

const setCompletedAssignmentsByClassId = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID,
    payload,
});

const clearCompletedAssignmentsByClassId = (payload) => ({
    type: types.CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID,
    payload,
});

/* --- */

const setCompletedAssignmentsByAllClassesLoading = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES_LOADING,
    payload,
});

const setCompletedAssignmentsByAllClasses = (payload) => ({
    type: types.SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES,
    payload,
});

const clearCompletedAssignmentsByAllClasses = (payload) => ({
    type: types.CLEAR_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_ALL_CLASSES,
    payload,
});

/* ---- */

const setAssignmentsByPageLoading = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_PAGE_LOADING,
    payload,
});

const setAssignmentsByPage = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_PAGE,
    payload,
});

const clearAssignmentsByPage = (payload) => ({
    type: types.CLEAR_ASSIGNMENTS_BY_PAGE,
    payload,
});

/* --- */

export {
    setCompletedAssignmentsByPageLoading,
    setCompletedAssignmentsByPage,
    clearCompletedAssignments,

    setIncompleteAssignmentsByPageLoading,
    setIncompleteAssignmentsByPage,
    clearIncompleteAssignments,

    setIncompleteAssignmentsByClassIdLoading,
    setIncompleteAssignmentsByClassId,
    clearIncompleteAssignmentsByClassId,

    setIncompleteAssignmentsByAllClassesLoading,
    setIncompleteAssignmentsByAllClasses,
    clearIncompleteAssignmentsByAllClasses,

    setCompletedAssignmentsByClassIdLoading,
    setCompletedAssignmentsByClassId,
    clearCompletedAssignmentsByClassId,

    setCompletedAssignmentsByAllClassesLoading,
    setCompletedAssignmentsByAllClasses,
    clearCompletedAssignmentsByAllClasses,

    setAssignmentsByPageLoading,
    setAssignmentsByPage,
    clearAssignmentsByPage,
};
