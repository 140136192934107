import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        signIn(params) {
            const event = {
                session: params.session,
                locationId: ids.location.login,
                eventId: ids.event.userLogin,
            };

            debugEvent("User sign in", event);
            methods.sendEvent(event);
        },

        signOut(params) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session,
                locationId,
                eventId: ids.event.userLogout,
            };

            debugEvent("User sign out", event);
            methods.sendEvent(event);
        },

        changePassword(params) {
            const event = {
                session: params.session,
                locationId: ids.location.changePassword,
                eventId: ids.event.userChangePassword,
            };

            debugEvent("User change password", event);
            methods.sendEvent(event);
        },
    };
};
