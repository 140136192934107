import React from "react";

import { Formik } from "formik";

import v from "@/base/lib/form-validators.js";

import ButtonBig from "@/base/components/button-big/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";
import Input from "@/base/components/forms/input/index.js";
import Textarea from "@/base/components/forms/textarea/index.js";

import styles from "./styles.module.css";


const RequestQuoteForm = (props) => {
    const validateForm = (values) => {
        const errorsRole = v.validate(values.role, [
            v.required("Please enter your role"),
        ]);

        const errorsPhone = v.validate(values.phone, [
            v.required("Please enter your phone number"),
        ]);

        const errors = {};

        if (errorsRole) {
            errors.role = errorsRole;
        }

        if (errorsPhone) {
            errors.phone = errorsPhone;
        }

        return errors;
    };

    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return hasErrors || formProps.isSubmitting;
    };

    const renderFormError = (error) => {
        if (error) {
            return (
                <MessageDanger>
                    {error}
                </MessageDanger>
            );
        }

        return null;
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleSubmit,
            handleChange,
            handleBlur,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Input
                    name="role"
                    label="Your Role *"
                    value={values.role}
                    error={v.getError(formProps, "role")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Input
                    name="phone"
                    type="tel"
                    label="Phone Number *"
                    value={values.phone}
                    error={v.getError(formProps, "phone")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Textarea
                    name="requestQuoteText"
                    placeholder="Tell us a bit about your interest in this plan (optional)"
                    value={values.requestQuoteText}
                    error={v.getError(formProps, "requestQuoteText")}
                    maxLength={500}
                    showMaxLengthMessage
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {renderFormError(formProps.errors.form)}
                <ButtonBig
                    type="submit"
                    disabled={isDisabled(formProps)}
                >
                    Request quote
                </ButtonBig>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

RequestQuoteForm.defaultProps = {
    initialValues: {},
    onSubmit: () => {},
};

export default RequestQuoteForm;
