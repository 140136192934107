import actionsTeacher from "./teacher.js";
import actionsTeacherClassDailyJuices from "./teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "./teacher-class-assignments.js";


const reloadClass = (services) => {
    return async (dispatch, getState) => {
        const reloadClasses = actionsTeacher.reloadClasses(services);

        const reloadDailyJuices = actionsTeacherClassDailyJuices.reloadDailyJuiceScores(services);
        const reloadAssignments = actionsTeacherClassAssignments.reloadAssignmentsScores(services);

        reloadClasses(dispatch, getState);
        reloadDailyJuices(dispatch, getState);
        reloadAssignments(dispatch, getState);
    };
};

export default {
    reloadClass,
};
