import React from "react";

import styles from "./styles.module.css";


const ButtonTool = (props) => {
    return (
        <button
            type="button"
            className={styles.button}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

ButtonTool.defaultProps = {
    onClick: () => { },
    children: null,
};

export default ButtonTool;
