import text from "@/base/text/index.js";


const loadCompletedAssignments = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.setCompletedAssignmentsByPageLoading());

        const state = getState();

        const page = state.studentAssignments.completedAssignmentsByPage?.page || 0;

        const res = await api.students.getStudentCompletedAssignments({
            session: state.user.session,
            page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setCompletedAssignmentsByPage({
            data,
            error,
            hasMore,
        }));
    };
};

const loadIncompleteAssignments = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.setIncompleteAssignmentsByPageLoading());

        const state = getState();

        const page = state.studentAssignments.incompleteAssignmentsByPage?.page || 0;

        const res = await api.students.getStudentIncompleteAssignments({
            session: state.user.session,
            page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByPage({
            data,
            error,
            hasMore,
        }));
    };
};

const loadAssignments = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.studentAssignments.setAssignmentsByPageLoading());

        const res = await api.students.getStudentAssignmentsByPage({
            session: state.user.session,
            page: params.page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setAssignmentsByPage({
            data,
            error,
            hasMore,
        }));
    };
};

/* --- */

const loadIncompleteAssignmentsByClassId = (services, params) => {
    const { actions, api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.setIncompleteAssignmentsByClassIdLoading({
            classId,
        }));

        const state = getState();

        const page = state.studentAssignments.incompleteAssignmentsByClassId?.[classId]?.page
            || 0;

        const res = await api.students.getStudentIncompleteAssignmentsByClassId({
            session: state.user.session,
            page,
            classId,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByClassId({
            classId,
            data,
            error,
            hasMore,
        }));
    };
};

const reloadIncompleteAssignmentsByClassId = (services, params) => {
    const { actions, api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.clearIncompleteAssignmentsByClassId({
            classId,
        }));

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByClassIdLoading({
            classId,
        }));

        const state = getState();

        const res = await api.students.getStudentIncompleteAssignmentsByClassId({
            session: state.user.session,
            classId,
            page: 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByClassId({
            classId,
            data,
            error,
            hasMore,
        }));
    };
};

const loadOrReloadIncompleteAssignmentsByClassId = (services, params) => {
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const data = state.studentAssignments.incompleteAssignmentsByClassId?.[classId] || {};

        if (data.isLoading) {
            return;
        }

        if (data.isLoaded) {
            dispatch(reloadIncompleteAssignmentsByClassId(services, params));
            return;
        }

        dispatch(loadIncompleteAssignmentsByClassId(services, params));
    };
};

/* --- */

const loadCompletedAssignmentsByClassId = (services, params) => {
    const { actions, api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.setCompletedAssignmentsByClassIdLoading({
            classId,
        }));

        const state = getState();

        const page = state.studentAssignments.completedAssignmentsByClassId?.[classId]?.page
            || 0;

        const res = await api.students.getStudentCompletedAssignmentsByClassId({
            session: state.user.session,
            page,
            classId,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setCompletedAssignmentsByClassId({
            classId,
            data,
            error,
            hasMore,
        }));
    };
};

const reloadCompletedAssignmentsByClassId = (services, params) => {
    const { actions, api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.clearCompletedAssignmentsByClassId({
            classId,
        }));

        dispatch(actions.studentAssignments.setCompletedAssignmentsByClassIdLoading({
            classId,
        }));

        const state = getState();

        const res = await api.students.getStudentCompletedAssignmentsByClassId({
            session: state.user.session,
            classId,
            page: 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setCompletedAssignmentsByClassId({
            classId,
            data,
            error,
            hasMore,
        }));
    };
};

const loadOrReloadCompletedAssignmentsByClassId = (services, params) => {
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const data = state.studentAssignments.completedAssignmentsByClassId?.[classId] || {};

        if (data.isLoading) {
            return;
        }

        if (data.isLoaded) {
            dispatch(reloadCompletedAssignmentsByClassId(services, params));
            return;
        }

        dispatch(loadCompletedAssignmentsByClassId(services, params));
    };
};

/* --- */

const loadAllClassesIncompleteAssignments = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.setIncompleteAssignmentsByAllClassesLoading());

        const state = getState();

        const page = state.studentAssignments.incompleteAssignmentsByAllClasses?.page
            || 0;

        const res = await api.students.getStudentIncompleteAssignmentsByAllClasses({
            session: state.user.session,
            page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByAllClasses({
            data,
            error,
            hasMore,
        }));
    };
};

const reloadAllClassesIncompleteAssignments = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.clearIncompleteAssignmentsByAllClasses());

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByAllClassesLoading());

        const state = getState();

        const res = await api.students.getStudentIncompleteAssignmentsByAllClasses({
            session: state.user.session,
            page: 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByAllClasses({
            data,
            error,
            hasMore,
        }));
    };
};

const loadOrReloadAllClassesIncompleteAssignments = (services, params) => {
    return async (dispatch, getState) => {
        const state = getState();

        const data = state.studentAssignments.incompleteAssignmentsByAllClasses || {};

        if (data.isLoading) {
            return;
        }

        if (data.isLoaded) {
            dispatch(reloadAllClassesIncompleteAssignments(services, params));
            return;
        }

        dispatch(loadAllClassesIncompleteAssignments(services, params));
    };
};

/* --- */

const loadAllClassesCompletedAssignments = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.setCompletedAssignmentsByAllClassesLoading());

        const state = getState();

        const page = state.studentAssignments.completedAssignmentsByAllClasses?.page || 0;

        const res = await api.students.getStudentCompletedAssignmentsByAllClasses({
            session: state.user.session,
            page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setCompletedAssignmentsByAllClasses({
            data,
            error,
            hasMore,
        }));
    };
};

const reloadAllClassesCompletedAssignments = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        dispatch(actions.studentAssignments.clearCompletedAssignmentsByAllClasses());
        dispatch(actions.studentAssignments.setCompletedAssignmentsByAllClassesLoading());

        const state = getState();

        const res = await api.students.getStudentCompletedAssignmentsByAllClasses({
            session: state.user.session,
            page: 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setCompletedAssignmentsByAllClasses({
            data,
            error,
            hasMore,
        }));
    };
};

const loadOrReloadAllClassesCompletedAssignments = (services, params) => {
    return (dispatch, getState) => {
        const state = getState();

        const data = state.studentAssignments.completedAssignmentsByAllClasses || {};

        if (data.isLoading) {
            return;
        }

        if (data.isLoaded) {
            dispatch(reloadAllClassesCompletedAssignments(services, params));
            return;
        }

        dispatch(loadAllClassesCompletedAssignments(services, params));
    };
};

/* --- */

export default {
    loadCompletedAssignments,
    loadIncompleteAssignments,
    loadAssignments,

    loadAllClassesIncompleteAssignments,
    reloadAllClassesIncompleteAssignments,
    loadOrReloadAllClassesIncompleteAssignments,

    loadAllClassesCompletedAssignments,
    reloadAllClassesCompletedAssignments,
    loadOrReloadAllClassesCompletedAssignments,

    loadIncompleteAssignmentsByClassId,
    reloadIncompleteAssignmentsByClassId,
    loadOrReloadIncompleteAssignmentsByClassId,

    loadCompletedAssignmentsByClassId,
    reloadCompletedAssignmentsByClassId,
    loadOrReloadCompletedAssignmentsByClassId,
};
