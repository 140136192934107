import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconJuiceCup = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.68629 18 9M6 9H12M6 9V9C5.72386 9 5.5 9.22386 5.5 9.5V9.5C5.5 9.77614 5.72386 10 6 10H6.07692M6 9L6.07692 10M18 9H12M18 9V9C18.2761 9 18.5 9.22386 18.5 9.5V9.5C18.5 9.77614 18.2761 10 18 10H17.9231M18 9L17.9231 10M12 0V9M12 9L12 14M6.07692 10H17.9231M6.07692 10L6.38462 14M17.9231 10L17.6154 14M17.6154 14L17.071 21.0767C17.0309 21.5977 16.5965 22 16.074 22H7.92603C7.40349 22 6.96905 21.5977 6.92898 21.0767L6.38462 14M17.6154 14H12M6.38462 14H12"
                        strokeWidth="2"
                        stroke="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconJuiceCup;
