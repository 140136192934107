import React, { useState, useEffect } from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const FivesyLetter = (props) => {
    const isRevealed = props.isAbsent
        || props.isPresent
        || props.isCorrect;

    const [isTyped, setIsTyped] = useState(false);

    useEffect(() => {
        if (props.value && !isRevealed) {
            setIsTyped(true);
        } else {
            setIsTyped(false);
        }
    }, [props.value]);

    const withValue = props.value
        && !props.isAbsent
        && !props.isPresent
        && !props.isCorrect;

    const letterClassName = classNames({
        [styles.letter]: true,
        [styles.letterTyped]: isTyped,
        [styles.letterRevealed]: isRevealed,
        [styles.letterFlipping]: props.isFlipping,
    });

    const letterStyle = {
        "transition-delay": props.isFlipping ? `${props.index * 350}ms` : "",
    };

    const frontClassName = classNames({
        [styles.letterFront]: true,
        [styles.letterFrontWithValue]: withValue,
    });

    const backClassName = classNames({
        [styles.letterBack]: true,
        [styles.letterBackAbsent]: props.isAbsent,
        [styles.letterBackPresent]: props.isPresent,
        [styles.letterBackCorrect]: props.isCorrect,
    });

    return (
        <div
            className={letterClassName}
            style={letterStyle}
        >
            <div className={frontClassName}>
                {props.value}
            </div>
            <div className={backClassName}>
                {props.value}
            </div>
        </div>
    );
};

FivesyLetter.defaultProps = {
    index: 0,
    value: "",
    isAbsent: false,
    isPresent: false,
    isCorrect: false,
    isFlipping: false,
};

export default FivesyLetter;
