import React from "react";

import styles from "./styles.module.css";


const RowItem = (props) => {
    const renderImage = () => {
        if (!props.src) {
            return (
                <div className={styles.rowFeatured} />
            );
        }

        return (
            <img
                className={styles.rowFeatured}
                src={props.src}
                alt="Featured"
            />
        );
    };

    const renderIcon = () => {
        if (!props.icon) {
            return (
                <div />
            );
        }

        return props.icon;
    };

    return (
        <div
            className={styles.row}
            role="button"
            tabIndex="-1"
            onClick={props.onClick}
            onKeyPress={props.onClick}
        >
            {renderImage()}
            <div className={styles.rowTitleWithIcon}>
                <div className={styles.rowTitle}>
                    {props.title}
                </div>
                {renderIcon()}
            </div>
        </div>
    );
};

RowItem.defaultProps = {
    src: "",
    title: "",
    icon: null,
    onClick: () => { },
};

export default RowItem;
