import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        // TODO: no usage?
        addStudent(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.classAddStudent,
                objectId: params.classId,
            };

            debugEvent("Add class student", event);
            methods.sendEvent(event);
        },

        deleteStudent(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.classDeleteStudent,
                objectId: params.classId,
                subObjectId: params.studentId,
            };

            debugEvent("Delete class student", event);
            methods.sendEvent(event);
        },

        editStudent(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.classEditStudent,
                objectId: params.classId,
            };

            debugEvent("Edit class student", event);
            methods.sendEvent(event);
        },

        resetPassword(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.classResetPassword,
                objectId: params.classId,
            };

            debugEvent("Class reset student password", event);
            methods.sendEvent(event);
        },

        changeClass(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.classChangeClass,
                objectId: params.classId,
            };

            debugEvent("Change class", event);
            methods.sendEvent(event);
        },

        openStudentInfo(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.classOpenStudentInfo,
                objectId: params.classId,
            };

            debugEvent("Open class student info", event);
            methods.sendEvent(event);
        },
    };
};
