import * as types from "./types.js";


export const setPlans = (payload) => ({
    type: types.SET_PLANS,
    payload,
});

export const setActivePlans = (payload) => ({
    type: types.SET_ACTIVE_PLANS,
    payload,
});

export const setPrivatePlanByCodeLoading = (code) => ({
    type: types.SET_PRIVATE_PLAN_BY_CODE_LOADING,
    payload: { code },
});

export const setPrivatePlanByCode = (payload) => ({
    type: types.SET_PRIVATE_PLAN_BY_CODE,
    payload,
});

export default {};
