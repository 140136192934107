import * as types from "./types.js";


const setStudentAssignmentsByClassIdLoading = (payload) => ({
    type: types.SET_TEACHER_STUDENT_ASSIGNMENTS_BY_CLASS_ID_LOADING,
    payload,
});

const setStudentAssignmentsByClassId = (payload) => ({
    type: types.SET_TEACHER_STUDENT_ASSIGNMENTS_BY_CLASS_ID,
    payload,
});

export {
    setStudentAssignmentsByClassIdLoading,
    setStudentAssignmentsByClassId,
};
