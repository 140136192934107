// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mg1mF_hn0gnzLXdsVVFU {\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/events-wrapper/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".eventsWrapper {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventsWrapper": "Mg1mF_hn0gnzLXdsVVFU"
};
export default ___CSS_LOADER_EXPORT___;
