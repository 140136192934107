import text from "@/base/text/index.js";


const loadSchools = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.getSchools({
            session: state.user.session,
        });

        let schools = [];
        let error = "";

        if (res.ok) {
            schools = res.schools || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.districtAdmin.setSchools({
            schools,
            error,
        }));

        return {
            schools,
            error,
        };
    };
};

const loadSchoolDepartments = (services, params) => {
    const { api, actions } = services;
    const { schoolId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.getDepartmentsBySchool({
            session: state.user.session,
            schoolId,
        });

        let departments = [];
        let error = "";

        if (res.ok) {
            departments = res.departments || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.districtAdmin.setDepartmentsBySchoolId({
            schoolId,
            departments,
            error,
        }));

        return {
            departments,
            error,
        };
    };
};

const loadDepartmentClasses = (services, params) => {
    const { api, actions } = services;
    const { departmentId, schoolId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.getClassesByDepartment({
            session: state.user.session,
            schoolId,
            departmentId,
        });

        let classes = [];
        let error = "";

        if (res.ok) {
            classes = res.classes || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.districtAdmin.setClassesByDepartmentId({
            departmentId,
            classes,
            error,
        }));

        return {
            classes,
            error,
        };
    };
};

const setClassPrimaryTeacher = (services, params) => {
    const { api, actions } = services;
    const { classId, teacherId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.setClassPrimaryTeacher({
            session: state.user.session,
            classId,
            teacherId,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.teacher.setTeacherClassesPrimaryTeacher({
                classId,
                teacherId,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const deleteSchool = (services, params) => {
    const { api } = services;
    const { schoolId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.deleteSchool({
            session: state.user.session,
            schoolId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const deleteDepartment = (services, params) => {
    const { api } = services;
    const { schoolId, departmentId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.deleteDepartment({
            session: state.user.session,
            schoolId,
            departmentId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const editSchool = (services, params) => {
    const { api } = services;
    const { schoolId, schoolName } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.editSchool({
            session: state.user.session,
            schoolId,
            schoolName,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const editDepartment = (services, params) => {
    const { api } = services;
    const { departmentId, departmentName } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.editDepartment({
            session: state.user.session,
            departmentId,
            departmentName,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const addSchool = (services, params) => {
    const { api, actions } = services;
    const { schoolName } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.addSchool({
            session: state.user.session,
            schoolName,
        });

        let error = "";
        const schoolId = res?.school?.id || null;

        if (res.error || !schoolId) {
            error = res.error || text.error;
            dispatch(actions.districtAdminPopups.setEntitiesAddLoading(false));
        } else {
            dispatch(actions.districtAdminPopups.setEntitiesAddSchoolAdded({
                schoolId,
                schoolName,
            }));
        }

        return {
            schoolId,
            error,
        };
    };
};

const addDepartment = (services, params) => {
    const { api, actions } = services;
    const { departmentName, schoolId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.districtAdminPopups.setEntitiesAddLoading(true));

        const state = getState();

        const res = await api.districtAdmin.addDepartment({
            session: state.user.session,
            schoolId,
            departmentName,
        });

        let error = "";

        if (res.error || !res?.department?.id) {
            error = res.error || text.error;
            dispatch(actions.districtAdminPopups.setEntitiesAddLoading(false));
        } else {
            dispatch(actions.districtAdminPopups.setEntitiesAddDepartmentAdded({
                schoolId,
                departmentId: res.department.id,
            }));
        }

        return { error };
    };
};

const addClass = (services, params) => {
    const { api } = services;
    const {
        schoolId,
        departmentId,
        className,
        grades,
        endDate,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.addClass({
            session: state.user.session,
            schoolId,
            departmentId,
            className,
            grades,
            endDate,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const deleteClass = (services, params) => {
    const { api } = services;
    const {
        schoolId,
        departmentId,
        classId,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.deleteClass({
            session: state.user.session,
            schoolId,
            departmentId,
            classId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const editClass = (services, params) => {
    const { api } = services;
    const {
        schoolId,
        departmentId,
        classId,
        className,
        grade,
        endDate,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.districtAdmin.editClass({
            session: state.user.session,
            schoolId,
            departmentId,
            classId,
            className,
            grade,
            endDate,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};


export default {
    loadSchools,
    loadSchoolDepartments,
    loadDepartmentClasses,

    setClassPrimaryTeacher,
    editSchool,
    deleteSchool,
    addSchool,

    addDepartment,
    editDepartment,
    deleteDepartment,

    addClass,
    deleteClass,
    editClass,
};
