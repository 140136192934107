import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        open(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.fivesyOpen,
                objectId: params.gameId || "",
            };

            debugEvent("Fivesy open", event);

            methods.sendEvent(event);
        },

        close(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.fivesyClose,
                objectId: params.gameId || "",
            };

            debugEvent("Fivesy close", event);

            methods.sendEvent(event);
        },

        guess(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.fivesyGuess,
                objectId: params.gameId || "",
                subObjectId: params.guess || "",
            };

            debugEvent("Fivesy guess", event);

            methods.sendEvent(event);
        },

        play(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.fivesyPlay,
                objectId: params.gameId || "",
                subObjectId: params.numberOfTries || 0,
                subSubObjectId: params.isWon ? 1 : 0,
                timeTook: params.timeTook || 0,
            };

            debugEvent("Fivesy play", event);

            return methods.sendEvent(event);
        },
    };
};
