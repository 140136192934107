import { useState } from "react";

const getState = () => ({
    isOpen: false,
    planName: "",
    maxClasses: 0,
    maxStudents: 0,
});

const useTeacherLimit = () => {
    const [state, setState] = useState(() => getState());

    const open = (params = {}) => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            planName: params.planName,
            maxClasses: params.maxClasses,
            maxStudents: params.maxStudents,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};

export default useTeacherLimit;
