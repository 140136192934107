import React from "react";

import Skeleton from "@/base/components/skeleton/index.js";
import WordCardAudioButton from "./audio-button.js";

import styles from "./definitions.module.css";


const WordCardDefinitions = (props) => {
    const getGroupedDefinitions = (pos, definitions, type) => {
        const texts = [pos];
        const list = [];

        definitions.forEach((d, i) => {
            texts.push(`${i + 1}. ${d.definition}.`);

            list.push(
                <li
                    key={`definition-${pos}-${i}-${type}`}
                    className={styles.posValue}
                >
                    {d.definition}
                </li>,
            );
        });

        return {
            texts,
            list,
        };
    };

    /* --- */

    const renderTranslation = (index) => {
        const { translation } = props;

        if (!translation.isVisible || translation.error) {
            return null;
        }

        let content = null;

        if (translation.isLoading) {
            content = (
                <div className={styles.skeleton}>
                    <Skeleton
                        fullWidth
                        fullHeight
                        withAnimation
                    />
                </div>
            );
        } else if (translation.definitions[index]) {
            const [pos, definitions] = translation.definitions[index];
            const { list } = getGroupedDefinitions(pos, definitions, "translation");

            content = (
                <>
                    <div className={styles.posValue}>
                        {pos}
                    </div>
                    <ul className={styles.posValues}>
                        {list}
                    </ul>
                </>
            );
        }

        return (
            <div className={styles.translation}>
                {content}
            </div>
        );
    };

    /* --- */

    const groups = props.groupedDefinitions.map(([pos, gDefinitions], index) => {
        const { texts, list } = getGroupedDefinitions(pos, gDefinitions, "definition");

        return (
            <div
                className={styles.group}
                key={`word-definitions-${props.word.id}-${pos}`}
            >
                <WordCardAudioButton
                    text={texts.join(". ")}
                    audio={props.audio}
                    word={props.word}
                    onLoad={props.onLoad}
                    onPlay={props.onPlay}
                    onStop={props.onStop}
                    isDefinition
                />
                <div className={styles.groupData}>
                    <div className={styles.definition}>
                        <div className={styles.posValue}>
                            {pos}
                        </div>
                        <ul className={styles.posValues}>
                            {list}
                        </ul>
                    </div>
                    {renderTranslation(index)}
                </div>
            </div>
        );
    });

    return (
        <div>
            {groups}
        </div>
    );
};

WordCardDefinitions.defaultProps = {
    translation: {},
    groupedDefinitions: [],
    word: null,
    audio: {},
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
};

export default WordCardDefinitions;
