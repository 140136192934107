import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    playerState: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_PLAYER_STATE: {
            const { playerState } = action.payload;

            return {
                ...state,
                playerState: {
                    ...state.playerState,
                    ...playerState,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
