import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconTags = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M2.9751 17.8001L2.1251 17.4501C1.60843 17.2334 1.2626 16.8584 1.0876 16.3251C0.912597 15.7918 0.941763 15.2668 1.1751 14.7501L2.9751 10.8501V17.8001ZM6.9751 20.0001C6.4251 20.0001 5.95426 19.8043 5.5626 19.4126C5.17093 19.0209 4.9751 18.5501 4.9751 18.0001V12.0001L7.6251 19.3501C7.6751 19.4668 7.7251 19.5793 7.7751 19.6876C7.8251 19.7959 7.89176 19.9001 7.9751 20.0001H6.9751ZM12.1251 19.9001C11.5918 20.1001 11.0751 20.0751 10.5751 19.8251C10.0751 19.5751 9.7251 19.1834 9.5251 18.6501L5.0751 6.45013C4.8751 5.91679 4.89176 5.39596 5.1251 4.88763C5.35843 4.37929 5.74176 4.03346 6.2751 3.85013L13.8251 1.10013C14.3584 0.90013 14.8751 0.92513 15.3751 1.17513C15.8751 1.42513 16.2251 1.81679 16.4251 2.35013L20.8751 14.5501C21.0751 15.0834 21.0584 15.6043 20.8251 16.1126C20.5918 16.6209 20.2084 16.9668 19.6751 17.1501L12.1251 19.9001ZM9.9751 8.00013C10.2584 8.00013 10.4959 7.9043 10.6876 7.71263C10.8793 7.52096 10.9751 7.28346 10.9751 7.00013C10.9751 6.71679 10.8793 6.47929 10.6876 6.28763C10.4959 6.09596 10.2584 6.00013 9.9751 6.00013C9.69176 6.00013 9.45426 6.09596 9.2626 6.28763C9.07093 6.47929 8.9751 6.71679 8.9751 7.00013C8.9751 7.28346 9.07093 7.52096 9.2626 7.71263C9.45426 7.9043 9.69176 8.00013 9.9751 8.00013Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconTags;
