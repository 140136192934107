import React, { useEffect, useState } from "react";

import IconCopy from "@/base/icons/copy/index.js";
import IconShare from "@/base/icons/share/index.js";

import MenuWithButton, {
    MenuOption,
} from "@/base/components/menu-with-button/index.js";

import useButtonShare from "./use-button-share.js";


const ButtonShare = (props) => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(() => props.isVisibleMenu);

    /* --- */

    useEffect(() => {
        setIsVisibleMenu(props.isVisibleMenu);
    }, [props.isVisibleMenu]);

    /* --- */

    const onClick = (evt) => {
        if (props.isDisabled) {
            return;
        }

        if (props.isAuto) {
            setIsVisibleMenu((prev) => !prev);
        }

        props.onClick(evt);
    };

    const onCloseMenu = () => {
        if (props.isAuto) {
            setIsVisibleMenu(false);
        }

        props.onCloseMenu();
    };

    const onShareClassroom = () => {
        props.onShareClassroom();

        if (props.closeMenuOnClassroomClick) {
            onCloseMenu();
        }
    };

    const onCopyLink = () => {
        props.onCopyLink();

        if (props.closeMenuOnCopyLinkClick) {
            onCloseMenu();
        }
    };

    /* --- */

    const renderGoogleClassroomOption = () => {
        if (!props.googleClassroom) {
            return null;
        }

        return (
            <MenuOption
                googleClassroom={props.googleClassroom}
                onClick={onShareClassroom}
                isLoading={props.isGoogleClassroomLoading}
            />
        );
    };

    /* --- */

    return (
        <MenuWithButton
            dataComment={props.dataComment}
            icon={<IconShare isOrange />}
            isVisibleMenu={isVisibleMenu}
            isMenuOpensDown={props.isMenuOpensDown}
            isMenuCentered={props.isMenuCentered}
            isDisabled={props.isDisabled}
            onClick={onClick}
            onCloseMenu={onCloseMenu}
        >
            {renderGoogleClassroomOption()}
            <MenuOption
                name="Copy Link"
                icon={<IconCopy title="Copy" isSky />}
                onClick={onCopyLink}
                isLoading={props.isCopyLinkLoading}
            />
        </MenuWithButton>
    );
};

ButtonShare.defaultProps = {
    dataComment: "",
    googleClassroom: null,
    isCopyLinkLoading: false,
    isGoogleClassroomLoading: false,
    isMenuOpensDown: true,
    isMenuCentered: false,
    closeMenuOnClassroomClick: true,
    closeMenuOnCopyLinkClick: true,
    isDisabled: false,
    isAuto: true,
    isVisibleMenu: false,
    onShareClassroom: () => { },
    onCopyLink: () => { },
    onClick: () => { },
    onCloseMenu: () => { },
};

export { useButtonShare };
export default ButtonShare;
