import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconGraduationCapEmpty = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M22 17V9M18 10.6667L21 9L12 4L3 9L6 10.6667M18 10.6667L12 14L6 10.6667M18 10.6667V16.5L12 20L6 16.5V10.6667"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconGraduationCapEmpty;
