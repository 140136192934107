import * as types from "./types.js";


export const setCountries = (payload) => ({
    type: types.SET_COUNTRIES,
    payload,
});

export const setSubdivisionsByAlpha2Loading = (payload) => ({
    type: types.SET_SUBDIVISIONS_BY_APLHA2_LOADING,
    payload,
});

export const setSubdivisionsByAlpha2 = (payload) => ({
    type: types.SET_SUBDIVISIONS_BY_APLHA2,
    payload,
});

/* ----- */

export const setDistrictsBySubdivision = (payload) => ({
    type: types.SET_DISTRICTS_BY_SUBDIVISION,
    payload,
});

/* ----- */

export const setCitiesBySubdivision = (payload) => ({
    type: types.SET_CITIES_BY_SUBDIVISION,
    payload,
});

/* ----- */

export const setSchoolsByCity = (payload) => ({
    type: types.SET_SCHOOLS_BY_CITY,
    payload,
});

/* ----- */

export const setSchoolsByDistrictId = (payload) => ({
    type: types.SET_SCHOOLS_BY_DISTRICT_ID,
    payload,
});
