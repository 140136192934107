import React from "react";

import text from "@/base/text/index.js";

import IconEmojiHappy from "@/base/icons/emoji-happy/index.js";

import ButtonBig from "@/base/components/button-big/index.js";

import styles from "./styles.module.css";


const PageConfirmation = (props) => {
    const renderControls = () => {
        const buttons = [];

        if (props.withTourButton && props.isToursLinksLoaded) {
            buttons.push(
                <ButtonBig
                    onClick={props.onTakeTour}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    uppercase
                >
                    Watch a video tour
                </ButtonBig>,
            );
        }

        const exploreButtonText = props.withTourButton
            ? "Explore on my own"
            : "Continue to use The Juice!";

        buttons.push(
            <ButtonBig
                onClick={props.onExploreOnMyOwn}
                isDisabledTabIndex={props.isDisabledTabIndex}
                uppercase
                outlined
            >
                {exploreButtonText}
            </ButtonBig>,
        );

        return buttons;
    };

    const confirmText = props.isParentOrFamily
        ? text.teacherSignUpForm8
        : text.teacherSignUpForm7;

    return (
        <div className={styles.wizardPage}>
            <div className={styles.confirmEmoji}>
                <IconEmojiHappy
                    className={styles.confirmEmojiIcon}
                    title="Happy Emoji"
                    isBlack
                />
            </div>
            <div className={styles.confirmText}>
                {confirmText}
            </div>
            {renderControls()}
            <div className={styles.confirmText}>
                {text.teacherSignUpForm9}
            </div>
        </div>
    );
};

PageConfirmation.defaultProps = {
    onExploreOnMyOwn: () => { },
    onTakeTour: () => { },
    isParentOrFamily: false,
    isDisabledTabIndex: false,
    isToursLinksLoaded: false,
    withTourButton: false,
};

export default PageConfirmation;
