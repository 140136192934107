import React, { useState, useEffect, useRef } from "react";

import AvatarIcon from "@/base/components/avatar-icon/index.js";
import AvatarMenu from "@/base/components/avatar-menu/index.js";

import styles from "./styles.module.css";


const Avatar = (props) => {
    const menuRef = useRef(null);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    /* --- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (props.disabledMenuGlobalClose) {
                return;
            }

            if (menuRef?.current?.contains
                && menuRef.current.contains(evt.target)) {
                return;
            }

            setIsVisibleMenu(false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, [props.disabledMenuGlobalClose]);

    /* --- */

    const onAvatarClick = () => {
        setIsVisibleMenu((prev) => !prev);
    };

    const renderMenu = () => {
        if (!isVisibleMenu) {
            return null;
        }

        return (
            <div className={styles.avatarMenuContainer}>
                <AvatarMenu
                    data={props.data}
                    onEditPassword={props.onEditPassword}
                    onEditEmail={props.onEditEmail}
                    onChangeReadingMode={props.onChangeReadingMode}
                    onLogout={props.onLogout}
                    onManageAccount={props.onManageAccount}
                    onSettings={props.onSettings}
                    onContactSupport={props.onContactSupport}
                    isVisibleReadingMode={props.isVisibleReadingMode}
                    isEnabledReadingMode={props.isEnabledReadingMode}
                    isVisibleFreeTrialRow={props.isVisibleFreeTrialRow}
                    isVisibleManageButton={props.isVisibleManageButton}
                />
            </div>
        );
    };

    return (
        <div
            ref={menuRef}
            className={styles.avatar}
        >
            <AvatarIcon
                name={props.data.name}
                avatarUrl={props.data.avatarUrl}
                onClick={onAvatarClick}
            />
            {renderMenu()}
        </div>
    );
};

Avatar.defaultProps = {
    data: {
        name: "A",
        fullname: "",
        username: "",
        email: "",
        avatarUrl: "",
        daysLeftToExpire: 0,
        isLmsUser: false,
    },

    onEditPassword: () => { },
    onEditEmail: () => { },
    onChangeReadingMode: () => { },
    onLogout: () => { },
    onManageAccount: () => { },
    onSettings: () => { },
    onContactSupport: () => { },

    disabledMenuGlobalClose: false,
    isVisibleReadingMode: false,
    isEnabledReadingMode: false,
    isVisibleFreeTrialRow: false,
    isVisibleManageButton: false,
};

export default Avatar;
