import text from "@/base/text/index.js";


const setNotificationViewed = (services, params) => {
    const { api, actions } = services;
    const { id } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.notifications.setNotificationViewed({
            session: state.user.session,
            id,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.notifications.setNotificationViewed(id));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const loadNotifications = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.notifications.setNotificationsLoading());

        const res = await api.notifications.getNotifications({
            session: state.user.session,
            page: state.notifications.notifications.page || 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.data || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.notifications.setNotifications({
            data,
            error,
            hasMore,
        }));
    };
};

const loadNotificationById = (services, params) => {
    const { id } = params;
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.notifications.setNotificationByIdLoading(id));

        const res = await api.notifications.getNotificationById({
            session: state.user.session,
            id,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.notifications.setNotificationById({
            id,
            data,
            error,
        }));

        return {
            data,
            error,
        };
    };
};

/* --- */

const loadOnceNotificationByIdAndSetViewed = (services, params) => {
    const { id } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const notification = state.notifications.notificationsById[id] || {};

        if (!id || notification.isLoaded) {
            return;
        }

        const loadNotificationByIdAction = loadNotificationById(services, { id });
        const { data, error } = await loadNotificationByIdAction(dispatch, getState);

        if (!error && !data?.isViewed) {
            const setNotificationViewedAction = setNotificationViewed(services, { id });
            setNotificationViewedAction(dispatch, getState);
        }
    };
};

/* --- */

export default {
    setNotificationViewed,
    loadNotifications,
    loadNotificationById,
    loadOnceNotificationByIdAndSetViewed,
};
