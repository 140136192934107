// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k5RdM7vS8pHUK0bOxfzN {\n    display: grid;\n    justify-content: center;\n\n    background-color: var(--color-1);\n    color: var(--color-6);\n\n    transition: opacity 0.5s ease-in-out;\n}\n\n.Jd1NfoWvgEiNbHmFTdDX {\n    opacity: 0;\n}\n\n.YRjpUptfdvbWSKEXPTRm {\n    max-width: 110rem;\n\n    padding: 2rem;\n\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-column-gap: 1rem;\n    align-items: center;\n}\n\n.gpgx8JF4E4rOzPUEto2H {\n    margin: 0 0 1rem 0;\n\n    font-weight: 600;\n    font-size: 1.8rem;\n}\n\n.xIpIZXwKN7OgqbBnkWIz {\n    display: grid;\n    grid-auto-flow: column;\n    align-items: center;\n    grid-column-gap: 1rem;\n}", "",{"version":3,"sources":["webpack://./base/components/popup-message/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;;IAEvB,gCAAgC;IAChC,qBAAqB;;IAErB,oCAAoC;AACxC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;;IAEjB,aAAa;;IAEb,aAAa;IACb,uCAAuC;IACvC,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;;IAElB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".popupMessage {\n    display: grid;\n    justify-content: center;\n\n    background-color: var(--color-1);\n    color: var(--color-6);\n\n    transition: opacity 0.5s ease-in-out;\n}\n\n.popupMessageHidden {\n    opacity: 0;\n}\n\n.contentBox {\n    max-width: 110rem;\n\n    padding: 2rem;\n\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-column-gap: 1rem;\n    align-items: center;\n}\n\n.title {\n    margin: 0 0 1rem 0;\n\n    font-weight: 600;\n    font-size: 1.8rem;\n}\n\n.control {\n    display: grid;\n    grid-auto-flow: column;\n    align-items: center;\n    grid-column-gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupMessage": "k5RdM7vS8pHUK0bOxfzN",
	"popupMessageHidden": "Jd1NfoWvgEiNbHmFTdDX",
	"contentBox": "YRjpUptfdvbWSKEXPTRm",
	"title": "gpgx8JF4E4rOzPUEto2H",
	"control": "xIpIZXwKN7OgqbBnkWIz"
};
export default ___CSS_LOADER_EXPORT___;
