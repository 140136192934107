import urls from "@/base/lib/urls.js";


export const getGoogleClassroomShareLink = (googleClassroomUrl, path, title) => {
    if (!path || !googleClassroomUrl) {
        return "";
    }

    const host = urls.getSiteUrl();
    const linkTitle = title ? `&title=${title}` : "";

    return `${googleClassroomUrl}?url=${host}${path}${linkTitle}`;
};

export default {
    getGoogleClassroomShareLink,
};
