import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        printStory(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.printStory,
                objectId: params.storyId,
                subObjectId: params.items || "",
            };

            debugEvent("printStory", event);

            methods.sendEvent(event);
        },
    };
};
