const randomHEX = (size = 16) => {
    let result = "";

    const chars = "0123456789abcdef";

    for (let i = 0; i < size; i += 1) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return result;
};

const randomInt = (min, max) => {
    return Math.round(min + ((max - min) * Math.random()));
};

export default {
    randomHEX,
    randomInt,
};
