// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b0P2ufx_zhTfe75WcqYA {\n    padding: 2rem;\n}\n\n.tOPPj5Nf0Q59hksQlkv4 {\n    padding: 2rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-profile-avatar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;;IAEb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".content {\n    padding: 2rem;\n}\n\n.error {\n    padding: 2rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "b0P2ufx_zhTfe75WcqYA",
	"error": "tOPPj5Nf0Q59hksQlkv4"
};
export default ___CSS_LOADER_EXPORT___;
