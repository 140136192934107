import React from "react";
import { Formik } from "formik";

import v from "@/base/lib/form-validators.js";

import Input from "@/base/components/forms/input/index.js";


const FullnameChangeForm = (props) => {
    const getFullnameError = (value) => {
        const errorsFullname = v.validate(value, [
            v.required("Please enter fullname"),
        ]);

        return errorsFullname;
    };

    const validateForm = (values) => {
        const errorsFullname = getFullnameError(values.fullname);

        const errors = {};

        if (errorsFullname) {
            errors.fullname = errorsFullname;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            errors,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit}>
                <Input
                    name="fullname"
                    label="Fullname"
                    value={values.fullname}
                    error={errors.fullname}
                    onChange={(e) => {
                        handleChange(e);
                        props.onChange({
                            value: e.target.value,
                            error: getFullnameError(e.target.value),
                        });
                    }}
                    onBlur={handleBlur}
                />
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FullnameChangeForm.defaultProps = {
    initialValues: {},
    onChange: () => {},
};

export default FullnameChangeForm;
