import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import date from "@/base/lib/date.js";
import text from "@/base/text/index.js";

import IconScoresHorizontal from "@/base/icons/scores-horizontal/index.js";

import PopupWindow from "@/base/components/popup-window/index.js";
import PollSection from "@/base/components/poll-section/index.js";
import TilesEqual from "@/base/components/tiles-equal/index.js";
import ButtonRounded from "@/base/components/button-rounded/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import Message from "@/base/components/message/index.js";

import app from "@/app/app.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    polls: state.polls,

    dimensions: state.device.dimensions,
});

const UserPopupPolls = (props) => {
    const store = useSelector(storeSelector);

    const isMobile = store.dimensions.width <= 800;

    /* --- */

    const onLoadClosedPolls = () => {
        app.actions.common.polls.loadMoreClosedPolls();
    };

    /* --- */

    useEffect(() => {
        if (store.polls.isClosedPollsLoaded) {
            return;
        }

        app.actions.common.polls.loadFirstClosedPolls();
    }, []);

    /* --- */

    const renderLoadMoreButton = () => {
        const { hasMorePages, isClosedPollsLoading } = store.polls;

        if (isClosedPollsLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!hasMorePages) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonRounded
                    uppercase
                    onClick={onLoadClosedPolls}
                >
                    Load More
                </ButtonRounded>
            </div>
        );
    };

    const renderPoll = (poll) => {
        return (
            <div className={styles.poll}>
                <div className={styles.pollDate}>
                    {date.tryFormatDateUTC(poll.pollStart, date.formatMonthDayYearDate)}
                </div>
                <PollSection
                    isDisabled
                    isVisibleMessage={false}
                    isOutdated={poll.isOutdated}
                    selectedAnswer={poll.userAnswerId}
                    question={poll.question}
                    answers={poll.answers}
                />
            </div>
        );
    };

    const renderContent = () => {
        const {
            isClosedPollsLoaded,
            isClosedPollsLoading,
            closedPolls,
        } = store.polls;

        if (!isClosedPollsLoaded && isClosedPollsLoading) {
            return (
                <RequestLoader />
            );
        }

        if (closedPolls.length === 0) {
            return (
                <Message>
                    {text.noClosedPolls}
                </Message>
            );
        }

        const pollsByMonths = [];

        for (let i = 0; i < closedPolls.length; i += 1) {
            const poll = closedPolls[i];
            const pollDate = date.getYearMonthFromDate(date.newDateUTC(poll.pollStart));

            const index = pollsByMonths.map((c) => c.date).indexOf(pollDate);

            if (index !== -1) {
                pollsByMonths[index].polls.push(poll);
            } else {
                pollsByMonths.push({
                    date: pollDate,
                    polls: [poll],
                });
            }
        }

        const sections = pollsByMonths.map((data) => {
            const polls = data.polls.map(renderPoll);
            const pollDate = date.tryFormatDate(data.date, date.formatMonthYearDate);

            return (
                <div className={styles.section}>
                    <div className={styles.date}>
                        {pollDate}
                    </div>
                    <div className={styles.polls}>
                        <TilesEqual isMobile={isMobile}>
                            {polls}
                        </TilesEqual>
                    </div>
                </div>
            );
        });

        return (
            <div>
                {sections}
                {renderLoadMoreButton()}
            </div>
        );
    };

    const renderLeftControl = () => {
        return (
            <div className={styles.icon}>
                <IconScoresHorizontal
                    className={styles.iconSvg}
                    title="Polls"
                    isOrange
                />
            </div>
        );
    };

    return (
        <PopupWindow
            title="Polls"
            titleToStart
            leftControl={renderLeftControl()}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

UserPopupPolls.defaultProps = {
    onClose: () => { },
};

export default UserPopupPolls;
