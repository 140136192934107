import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const MessagePassword = (props) => {
    if (props.passwordMinLength === -1) {
        return null;
    }

    const message = `
        Passwords must be at least ${props.passwordMinLength} characters,
        include one upper case letter, one lower case letter, and one special character.
    `;

    const messageClassName = classNames({
        [styles.message]: true,
        [styles.messageRed]: props.isRed,
    });

    return (
        <div className={messageClassName}>
            {message}
        </div>
    );
};

MessagePassword.defaultProps = {
    passwordMinLength: -1,
    isRed: false,
};

export default MessagePassword;
