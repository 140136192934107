import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        translateStory(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.translateStory,
                objectId: params.storyId,
                subObjectId: params.languageId,
            };

            debugEvent("Translate story", event);
            methods.sendEvent(event);
        },

        translateExtraJuice(params = {}) {
            const locationId = methods.parsePathname(params.location);

            const event = {
                session: params.session || "",
                locationId,
                eventId: ids.event.translateExtraJuice,
                objectId: params.storyId,
                subObjectId: params.languageId,
            };

            debugEvent("Translate extra juice", event);
            methods.sendEvent(event);
        },
    };
};
