import React from "react";

import DailyJuice from "@/base/project/daily-juice.js";

import IconPollAnswered from "@/base/icons/poll-answered/index.js";
import IconPollUnanswered from "@/base/icons/poll-unanswered/index.js";

// import number from "@/base/lib/number.js";
import classNames from "@/base/lib/class-names.js";

import Poll from "@/base/components/poll/index.js";

import styles from "./styles.module.css";


const PollSection = (props) => {
    const {
        isDisabled,
        isOutdated,
        isLoadingResults,
        isVisibleMessage,
        selectedAnswer,
    } = props;

    const getAnswers = () => {
        if (props.answers.length === 0) {
            return [];
        }

        const ps = props.answers.map((a) => {
            const value = a.percent || 0;
            return Math.floor(value);
        });

        const sum = ps.reduce((acc, val) => acc + val, 0);

        if (sum !== 100 && sum > 0) {
            const diff = 100 - sum;
            ps[ps.length - 1] += diff;
        }

        return props.answers.map((ans, i) => {
            return {
                ...ans,
                percent: ps[i],
            };
        });
    };

    const renderQuestion = () => {
        const questionSectionClassName = classNames({
            [styles.questionSectionWithControl]: props.questionControl,
        });

        return (
            <div className={questionSectionClassName}>
                <div className={styles.question}>
                    {props.question}
                </div>
                {props.questionControl}
            </div>
        );
    };

    const renderStatus = () => {
        const pollClassName = classNames({
            [styles.pollStatus]: true,
            [styles.pollStatusOpen]: !props.selectedAnswer,
        });

        let statusIcon = (
            <>
                <IconPollUnanswered
                    className={styles.pollStatusIcon}
                    title={DailyJuice.StoryStates.pollOpen}
                    isSky
                />
                {DailyJuice.StoryStates.pollOpen}
            </>
        );

        if (props.selectedAnswer) {
            statusIcon = (
                <>
                    <IconPollAnswered
                        className={styles.pollStatusIcon}
                        title={DailyJuice.StoryStates.pollVoted}
                        isSky
                    />
                    {DailyJuice.StoryStates.pollVoted}
                </>
            );
        }

        return (
            <div className={pollClassName}>
                {statusIcon}
            </div>
        );
    };

    const renderMessageOrStatus = () => {
        if (!isVisibleMessage) {
            return null;
        }

        let message = "";

        if (isOutdated) {
            message = "Voting closed already!";
        }

        if (props.error) {
            message = props.error;
        }

        if (!message) {
            return renderStatus();
        }

        return (
            <div className={styles.message}>
                {message}
            </div>
        );
    };

    const renderAnswers = () => {
        const ans = getAnswers();

        const answers = ans.map((a) => {
            let isVisibleResults = false;

            if (selectedAnswer || isOutdated || props.isVisibleResults) {
                isVisibleResults = true;
            }

            return (
                <Poll
                    key={a.id}
                    isOutdated={isOutdated}
                    isDisabled={isDisabled}
                    isLoading={isLoadingResults}
                    isSelected={a.id === selectedAnswer}
                    isVisibleResults={isVisibleResults}
                    percent={a.percent}
                    answer={a.value}
                    onSelect={() => {
                        if (isOutdated) {
                            return;
                        }

                        props.onSelect(a.id);
                    }}
                />
            );
        });

        return (
            <div className={styles.answers}>
                {answers}
            </div>
        );
    };

    const renderSectionBottom = () => {
        // let votes = "";

        // if (props.votes === 1) {
        //     votes = "1 vote";
        // } else {
        //     const formattedVotes = number.formatNumber(props.votes);

        //     votes = `${formattedVotes} votes`;
        // }

        // TODO: show votes later
        return (
            <div className={styles.sectionBottom}>
                <div className={styles.sectionLeftBottom}>
                    {/*
                    <div className={styles.votes}>
                        votes
                    </div>
                    */}
                    {props.bottomControl}
                </div>
                {renderMessageOrStatus()}
            </div>
        );
    };

    return (
        <div className={styles.section}>
            {renderQuestion()}
            {renderAnswers()}
            {renderSectionBottom()}
        </div>
    );
};

PollSection.defaultProps = {
    isOutdated: false,
    isDisabled: false,
    isLoadingResults: false,
    isVisibleResults: false,
    isVisibleMessage: true,

    selectedAnswer: null,
    questionControl: null,
    bottomControl: null,

    error: "",
    question: "",
    answers: [],
    votes: 0,
    onSelect: () => { },
};

export default PollSection;
