import React from "react";

import text from "@/base/text/index.js";

import WithScroll from "@/base/components/with-scroll/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import Countdown from "@/base/components/countdown/index.js";
import PopupWithTitle from "@/base/components/popup-with-title/index.js";
import WordCard from "@/base/components/word-card/index.js";
import FivesyLetter from "@/base/components/fivesy-letter/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";

import styles from "./styles.module.css";


const PopupFivesyResult = (props) => {
    const renderWordLetters = (word) => {
        const letters = word.toLowerCase().split("").map((letter) => {
            return (
                <FivesyLetter
                    value={letter}
                    isCorrect
                />
            );
        });

        return (
            <div className={styles.todayWord}>
                <div className={styles.todayWordHeader}>
                    Today&apos;s word is:
                </div>

                <div className={styles.wordLetters}>
                    {letters}
                </div>
            </div>
        );
    };

    const renderWord = () => {
        if (!props.vocabularyWord?.isLoaded) {
            return null;
        }

        const trackGroupName = "words";
        const audioData = props?.audioState?.[trackGroupName] || {};

        const { word, definitions } = props.vocabularyWord;

        return (
            <>
                {renderWordLetters(word.word || "")}
                <WordCard
                    word={word}
                    definitions={definitions}
                    audio={audioData}
                    onLoad={(txt) => {
                        if (props.audio) {
                            props.audio.loadWord(txt);
                        }
                    }}
                    onPlay={(txt) => {
                        if (props.audio) {
                            props.audio.play(trackGroupName, txt);
                        }
                    }}
                    onStop={(txt) => {
                        if (props.audio) {
                            props.audio.stop(trackGroupName, txt);
                        }
                    }}
                    onStopAll={(words) => {
                        if (props.audio) {
                            props.audio.stopAllTracks(trackGroupName, words);
                        }
                    }}
                    isTitleVisible={false}
                />
            </>
        );
    };

    const renderStatsBlock = (number, label) => {
        return (
            <div className={styles.statsBlock}>
                <div>{number}</div>
                <div>{label}</div>
            </div>
        );
    };

    const renderStatsContent = () => {
        const { stats } = props;

        if (stats.isLoading) {
            return (
                <div className={styles.statsContent}>
                    <RequestLoader />
                </div>
            );
        }

        if (stats.error) {
            return (
                <div className={styles.statsContent}>
                    <MessageDanger>
                        {`Failed to load statistic: ${stats.error}`}
                    </MessageDanger>
                    <ButtonBig
                        onClick={stats.onLoad}
                    >
                        {text.tryAgain}
                    </ButtonBig>
                </div>
            );
        }

        const data = stats?.data || {};

        const gamesPlayed = data?.gamesPlayed || 0;
        const totalWins = data?.totalWins || 0;
        const gamesStreak = data?.gamesStreak || 0;
        const longestStreak = data?.longestStreak || 0;

        return (
            <div className={styles.statsBlocks}>
                {renderStatsBlock(gamesPlayed, "Games Played")}
                {renderStatsBlock(totalWins, "Total Wins")}
                {renderStatsBlock(gamesStreak, "Games Streak")}
                {renderStatsBlock(longestStreak, "Longest Streak")}
            </div>
        );
    };

    const renderStats = () => {
        if (!props.stats?.isVisible) {
            return null;
        }

        return (
            <div className={styles.stats}>
                {renderStatsContent()}
            </div>
        );
    };

    const renderWordContent = () => {
        const message = props.isSuccess
            ? `Congratulations! You got it in ${props.numberOfGuesses} tries!`
            : "Better luck next time.";

        return (
            <>
                <div className={styles.content}>
                    {message}
                    <Countdown
                        prefix="Next Fivesy available in"
                        endDate={props.nextDate}
                    />
                </div>
                {renderWord()}
            </>
        );
    };

    const renderContent = () => {
        if (!props.isFinished) {
            return renderStats();
        }

        return (
            <WithScroll className={styles.popupContent}>
                {renderStats()}
                {renderWordContent()}
            </WithScroll>
        );
    };

    return (
        <PopupWithTitle
            title="Statistics"
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWithTitle>
    );
};

PopupFivesyResult.defaultProps = {
    numberOfGuesses: 0,
    nextDate: null,
    vocabularyWord: null,
    audio: null,
    audioData: null,
    stats: {
        data: null,
        error: "",
        onLoad: () => { },
        isVisible: false,
        isLoading: false,
    },
    onClose: () => { },
    isFinished: false,
    isSuccess: false,
};

export default PopupFivesyResult;
