import React from "react";

import {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";
import AlertBox from "@/base/components/alert-box/index.js";


const EmailConfirmed = (props) => {
    return (
        <>
            <PopupConfirmTitle>
                Email confirmed
            </PopupConfirmTitle>
            <PopupConfirmContent>
                <AlertBox theme="black-success">
                    Email confirmed successfully.
                </AlertBox>
            </PopupConfirmContent>
            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose}>
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </>
    );
};

EmailConfirmed.defaultProps = {
    email: "",
    onClose: () => { },
};

export default EmailConfirmed;
