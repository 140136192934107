export default (apiURL, methods) => {
    return {
        getTemplate(params = {}) {
            const url = `${apiURL}/template`;

            return methods.get3(url, {
                template_slug: params.slug,
            });
        },
    };
};
