const loadClassPerformance = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const {
            dateFrom,
            dateTo,
            categories,
            classId,
        } = state.navigation.params;

        if (!dateFrom || !dateTo || !categories || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;
        const cats = categories.replaceAll("-", ",");

        dispatch(actions.districtAdmin.setClassPerformanceLoading({
            classId,
            range,
            categories: cats,
        }));

        const res = await api.districtAdmin.getClassPerformanceByClassId({
            session: state.user.session,
            classId,
            categories: cats,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.districtAdmin.setClassPerformance({
            classId,
            range,
            categories: cats,
            data,
            error,
        }));
    };
};

const reloadClassPerformance = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const {
            dateFrom,
            dateTo,
            categories,
            classId,
        } = state.navigation.params;

        if (!dateFrom || !dateTo || !categories || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;
        const cats = categories.replaceAll("-", ",");

        const res = await api.districtAdmin.getClassPerformanceByClassId({
            session: state.user.session,
            classId,
            categories: cats,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.districtAdmin.setClassPerformance({
            classId,
            range,
            categories: cats,
            data,
            error,
        }));
    };
};

const loadOrReloadClassPerformance = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const {
            dateFrom,
            dateTo,
            categories,
            classId,
        } = state.navigation.params;

        if (!dateFrom || !dateTo || !categories || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;
        const { classPerformanceById } = state.districtAdmin;

        const isLoaded = classPerformanceById?.[classId]?.[range]?.[categories]?.data
            || false;

        if (isLoaded) {
            const reloadAction = reloadClassPerformance(services);
            reloadAction(dispatch, getState);
            return;
        }

        const loadAction = loadClassPerformance(services);
        loadAction(dispatch, getState);
    };
};

export default {
    loadClassPerformance,
    reloadClassPerformance,
    loadOrReloadClassPerformance,
};
