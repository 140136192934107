const getJobStatuses = () => {
    return {
        pending: "Pending",
        running: "Running",
        succeeded: "Succeeded",
        failed: "Failed",
    };
};

export default {
    getJobStatuses,
};
