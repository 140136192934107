import React, { useMemo, useCallback } from "react";

import classNames from "@/base/lib/class-names.js";

import RateLike from "@/base/project/rate-like.js";

import IconLike from "@/base/icons/like/index.js";
import IconLikeEmpty from "@/base/icons/like-empty/index.js";
import IconDislikeEmpty from "@/base/icons/dislike-empty/index.js";
import IconDislike from "@/base/icons/dislike/index.js";

import ButtonIcon from "@/base/components/button-icon/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";

import styles from "./styles.module.css";


const RateWithLike = (props) => {
    const storyRate = useMemo(() => ({
        isLiked: RateLike.isStoryRateLiked(props.rate),
        isDisliked: RateLike.isStoryRateDisliked(props.rate),


    }), [props.rate]);

    const isDisabled = props.isDisabled || !!props.loadingRatingByName;

    const loadingRating = useMemo(() => {
        const data = {
            isLikeLoading: false,
            isDislikeLoading: false,
        };

        if (!props.loadingRatingByName) {
            return data;
        }

        if (RateLike.isStoryRateLiked(props.loadingRatingByName)) {
            data.isLikeLoading = true;
        } else if (RateLike.isStoryRateDisliked(props.loadingRatingByName)) {
            data.isDislikeLoading = true;
        }

        return data;
    }, [props.loadingRatingByName]);

    /* --- */

    const onLike = useCallback(() => {
        if (storyRate.isLiked) {
            return;
        }

        const rating = RateLike.getLikeValue();
        props.onRate(rating);
    }, [props.rate]);

    const onDislike = useCallback(() => {
        if (storyRate.isDisliked) {
            return;
        }

        const rating = RateLike.getDislikeValue();
        props.onRate(rating);
    }, [props.rate]);

    /* --- */

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <MessageDanger>
                {props.error}
            </MessageDanger>
        );
    };

    /* --- */

    return (
        <div className={styles.container}>
            <div className={styles.rate}>
                <div className={styles.title}>
                    {props.title}
                </div>
                <ButtonIcon
                    icon={<IconLikeEmpty isBlack />}
                    iconPressed={<IconLike isGreen />}
                    onClick={onLike}
                    isPressed={storyRate.isLiked}
                    isDisabled={isDisabled}
                    isLoading={loadingRating.isLikeLoading}
                />
                <ButtonIcon
                    icon={<IconDislikeEmpty isBlack />}
                    iconPressed={<IconDislike isRed />}
                    onClick={onDislike}
                    isPressed={storyRate.isDisliked}
                    isDisabled={isDisabled}
                    isLoading={loadingRating.isDislikeLoading}
                />
            </div>
            {renderError()}
        </div>
    );
};

RateWithLike.defaultProps = {
    title: "Rate this story",
    rate: "",
    loadingRatingByName: "",
    error: "",
    onRate: () => { },
    isDisabled: false,
};

export default RateWithLike;
