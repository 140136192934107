import React from "react";

import IconCheckInCircle from "@/base/icons/check-in-circle/index.js";

import styles from "./styles.module.css";


const MessageSuccess = (props) => {
    const imgTitle = "Success!";

    return (
        <div className={styles.message}>
            <IconCheckInCircle
                className={styles.icon}
                title={imgTitle}
                isGreen
            />
            <div className={styles.text}>
                {props.children}
            </div>
        </div>
    );
};

MessageSuccess.defaultProps = {
    children: null,
};

export default MessageSuccess;
