import * as types from "./types.js";


export const setAchievementsLoading = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_ACHIEVEMENTS_LOADING,
    payload,
});

export const setAchievements = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_ACHIEVEMENTS,
    payload,
});

export const updateAchievements = (payload) => ({
    type: types.UPDATE_TEACHER_STUDENTS_ACHIEVEMENTS,
    payload,
});

export const updateStudentAchievements = (payload) => ({
    type: types.UPDATE_TEACHER_STUDENT_ACHIEVEMENTS,
    payload,
});

export const clearUpdatedStatusStudentAchievements = (payload) => ({
    type: types.CLEAR_UPDATED_STATUS_TEACHER_STUDENT_ACHIEVEMENTS,
    payload,
});

export const clearUpdatedStatusAchievements = (payload) => ({
    type: types.CLEAR_UPDATED_STATUS_TEACHER_STUDENTS_ACHIEVEMENTS,
    payload,
});
