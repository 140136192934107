// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E8JG5A1PyJVvVrlRIH4B {\n    position: relative;\n}\n\n.UGsfOksNfFetZPD6zUS8 {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n/* --- */\n\n.IDR8wKsfWM1ETY0BqQaF {\n    height: 0;\n\n    position: absolute;\n    left: 0;\n    right: 0;\n\n    opacity: 0;\n    visibility: hidden;\n    overflow: hidden;\n}\n\n/* --- */\n\n.C5mqtaCHGPEUDX2Hj1Bj,\n.IDR8wKsfWM1ETY0BqQaF {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 2rem;\n    grid-template-columns: 1fr 1fr;\n}\n\n.UNhoxOjNFrDheogpUiFd {\n    display: flex;\n    flex-direction: column;\n}\n\n.UNhoxOjNFrDheogpUiFd > div {\n    margin-bottom: 2rem;\n}\n\n.UNhoxOjNFrDheogpUiFd > div:last-child {\n    margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/tiles-equal/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA,QAAQ;;AAER;IACI,SAAS;;IAET,kBAAkB;IAClB,OAAO;IACP,QAAQ;;IAER,UAAU;IACV,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA,QAAQ;;AAER;;IAEI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container {\n    position: relative;\n}\n\n.tilesMobile {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n/* --- */\n\n.blocksMeasurer {\n    height: 0;\n\n    position: absolute;\n    left: 0;\n    right: 0;\n\n    opacity: 0;\n    visibility: hidden;\n    overflow: hidden;\n}\n\n/* --- */\n\n.tilesDesktop,\n.blocksMeasurer {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 2rem;\n    grid-template-columns: 1fr 1fr;\n}\n\n.tilesDesktopCol {\n    display: flex;\n    flex-direction: column;\n}\n\n.tilesDesktopCol > div {\n    margin-bottom: 2rem;\n}\n\n.tilesDesktopCol > div:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "E8JG5A1PyJVvVrlRIH4B",
	"tilesMobile": "UGsfOksNfFetZPD6zUS8",
	"blocksMeasurer": "IDR8wKsfWM1ETY0BqQaF",
	"tilesDesktop": "C5mqtaCHGPEUDX2Hj1Bj",
	"tilesDesktopCol": "UNhoxOjNFrDheogpUiFd"
};
export default ___CSS_LOADER_EXPORT___;
