import React from "react";

import classNames from "@/base/lib/class-names.js";

import Text from "@/base/components/text/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import stylesCommon from "@/base/components/juice-content-common/styles.module.css";


const JuiceContentPlain = (props) => {
    const contentClassName = classNames({
        [stylesFonts.content]: true,
        [stylesFonts.contentDyslexic]: props.isDyslexic,
        [stylesCommon.content]: true,
        [props.className]: props.className,
    });

    return (
        <Text className={contentClassName}>
            {props.content}
        </Text>
    );
};

JuiceContentPlain.defaultProps = {
    content: "",
    className: "",
    isDyslexic: false,
};

export default JuiceContentPlain;
