import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import PopupWindow from "@/base/components/popup-window/index.js";
import NotificationContent from "@/base/business/notification-content/index.js";

import app from "@/app/app.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    notificationsById: state.notifications.notificationsById,
});

const PopupNotification = (props) => {
    const store = useSelector(storeSelector);

    const notification = useMemo(() => {
        if (!props.id) {
            return {};
        }

        return store.notificationsById[props.id] || {};
    }, [
        props.id,
        store.notificationsById,
    ]);

    /* --- */

    useEffect(() => {
        app.actions.common.notifications.loadOnceNotificationByIdAndSetViewed({
            id: props.id,
        });
    }, [props.id]);

    /* --- */

    const renderContent = () => {
        const { error, isLoaded } = notification;

        const data = notification?.data || {};

        return (
            <NotificationContent
                imageUrl={data.imageUrl || ""}
                title={data.title || ""}
                content={data.readMoreContent || ""}
                date={data.dateCreated || ""}
                error={error || ""}
                isLoading={!isLoaded}
            />
        );
    };

    /* --- */

    return (
        <PopupWindow
            title={notification?.data?.title || "Notification"}
            onClose={props.onClose}
            hideScrollbar={false}
        >
            <div className={styles.container}>
                {renderContent()}
            </div>
        </PopupWindow>
    );
};

PopupNotification.defaultProps = {
    id: null,
    onClose: () => { },
};

export default PopupNotification;
