import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconQuestion = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        stroke="currentColor"
                        strokeWidth="2"
                        cx="12"
                        cy="12"
                        r="10"
                    />
                    <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M8.68848 9.79889C8.68848 7.88508 10.2731 6.49829 12.0349 6.49829C13.7967 6.49829 15.3095 7.88508 15.3095 9.79889C15.3095 11.2724 14.0914 11.7439 12.9715 12.9817C12.3339 13.6865 12.0349 14.7892 12.0349 15.2804M12.0349 16.5V18.5"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconQuestion;
