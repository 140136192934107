// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pElF3viDhi7LiA1bcjU8 {\n    position: fixed;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n\n    width: 100%;\n    height: 100%;\n\n    background-color: var(--black);\n    margin: 0;\n    padding: 0;\n\n    z-index: var(--z-index-overlay);\n}", "",{"version":3,"sources":["webpack://./base/components/overlay/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,OAAO;IACP,SAAS;;IAET,WAAW;IACX,YAAY;;IAEZ,8BAA8B;IAC9B,SAAS;IACT,UAAU;;IAEV,+BAA+B;AACnC","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n\n    width: 100%;\n    height: 100%;\n\n    background-color: var(--black);\n    margin: 0;\n    padding: 0;\n\n    z-index: var(--z-index-overlay);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "pElF3viDhi7LiA1bcjU8"
};
export default ___CSS_LOADER_EXPORT___;
