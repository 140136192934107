const getMemoryStorage = () => {
    const values = {};

    return {
        getItem(key) {
            return values[key] || null;
        },

        setItem(key, value) {
            values[key] = value;
        },

        removeItem(key) {
            delete values[key];
        },
    };
};

const getLocalStorage = () => {
    try {
        if (globalThis.localStorage) {
            return globalThis.localStorage;
        }

        return getMemoryStorage();
    } catch (err) {
        console.error(err); // eslint-disable-line no-console

        return getMemoryStorage();
    }
};

const getSessionStorage = () => {
    try {
        if (globalThis.sessionStorage) {
            return globalThis.sessionStorage;
        }

        return getMemoryStorage();
    } catch (err) {
        console.error(err); // eslint-disable-line no-console

        return getMemoryStorage();
    }
};

export default {
    localStorage: getLocalStorage(),
    sessionStorage: getSessionStorage(),
};
