import React from "react";

import styles from "./styles.module.css";


const PlayerAudioPlayback = (props) => {
    const renderProgress = () => {
        let widthPercent = (props.currentTime * 100) / props.duration;

        if (Number.isNaN(widthPercent)) {
            widthPercent = 0;
        }

        const right = 100 - widthPercent;

        return (
            <div
                className={styles.progress}
                style={{
                    right: `${right}%`,
                }}
            />
        );
    };

    return (
        <div className={styles.container}>
            {renderProgress()}
        </div>
    );
};

PlayerAudioPlayback.defaultProps = {
    currentTime: 0,
    duration: 0,
};

export default PlayerAudioPlayback;
