import staticFiles from "@/base/static-files.js";
import device from "@/base/lib/device.js";
import urls from "@/base/lib/urls.js";

import storage from "@/base/lib/storage/index.js";
import lazyLoader from "@/base/lib/lazy-loader.js";

import api from "@/app/api.js";
import settings from "@/app/settings.js";


const loadVideojs = (callback) => {
    const styleParams = {
        url: staticFiles.videoCss,
        delay: 5,
    };

    lazyLoader.loadStylesheet(styleParams, () => {
        const params = {
            url: staticFiles.videoJs,
            delay: 0,
        };

        lazyLoader.loadScriptWithDelay(params, callback);
    });
};

const loadGooglePlatform = (callback) => {
    const params = {
        url: "//apis.google.com/js/platform.js",
        delay: 7,
    };

    lazyLoader.loadScriptWithDelay(params, callback);
};

/* --- */

const initGlobalErrorHandler = () => {
    if (!globalThis.onerror) {
        return;
    }

    globalThis.onerror = (message, url, line, column, error) => {
        const userSession = storage.session.loadSession()
            || storage.local.loadSession();

        if (!userSession) {
            return false;
        }

        const err = {
            message,
            url,
            line,
            column,
            error: error ? error.toString() : "",
        };

        api.error.sendError({
            session: userSession,
            error: err,
        });

        return false;
    };
};

const setTitle = () => {
    const els = document.getElementsByTagName("title");

    if (els.length > 0) {
        els[0].textContent = settings.appName;
    }
};

const hideAppLoader = () => {
    const body = document.querySelector("body");

    if (body) {
        body.classList.remove("bodyLoading");
    }

    const els = document.getElementsByClassName("appLoader");

    if (els.length > 0) {
        els[0].remove();
    }
};

const preInit = (app) => {
    setTitle();

    loadVideojs(() => {
        app.actions.common.app.setVideojsLoaded();
    });

    loadGooglePlatform(() => {
        app.actions.common.app.setLibGAPILoaded();
    });

    initGlobalErrorHandler();

    const isIframeMode = !!urls.getSearchParamByName("iframe");
    const isA2HS = urls.getSearchParamByName("utm_source") === "a2hs";
    const isSafariAPP = device.isSafari && isA2HS;

    // NOTE: Safari Application with PWA builder detected as normal website.
    //       We need force change it to PWA.
    const isPWA = device.isPWA || isSafariAPP;

    app.actions.common.device.setIsPWA({
        isPWA,
        isSafariPWA: isPWA && device.isSafari,
    });

    // NOTE: check webpack for global VERSION variable
    app.actions.common.app.setConfiguration({
        features: settings.features,
        version: VERSION, // eslint-disable-line no-undef
        isIframeMode,
    });

    app.actions.common.app.initTheme();

    app.actions.common.app.initSiteInfo();

    app.actions.common.user.setUserLoading();
};

const postInit = () => {
    hideAppLoader();
};

export default {
    preInit,
    postInit,
};
