import React, {
    useCallback,
    useState,
    useRef,
    useContext,
    useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import array from "@/base/lib/array.js";
import styleProperties from "@/base/lib/style-properties.js";

import T2SHighlight from "@/base/project/t2s-highlight.js";
import Translation from "@/base/project/translation.js";

import AudioManagerContext from "@/base/context/audio-manager/index.js";

import vocabularyActions from "@/base/actions/vocabulary.js";
import actionsJuicesVideos from "@/base/actions/juices-videos.js";
import actionsText2Speech from "@/base/actions/text2speech.js";
import selectorsTranslation from "@/base/store-selectors/translation.js";

import useText2SpeechHighlight from "@/base/hooks/use-t2s-highlight/index.js";
import usePlayerAudioPlayback from "@/base/hooks/player-audio-playback/index.js";

import IconArrowBold from "@/base/icons/arrow-bold/index.js";
import IconNotebook from "@/base/icons/notebook/index.js";
import IconOrange from "@/base/icons/orange/index.js";
import IconPlayInCircle from "@/base/icons/play-in-circle/index.js";

import PopupWindow from "@/base/components/popup-window/index.js";
import PlayerAudioV2 from "@/base/components/player-audio-v2/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";
import Tabs from "@/base/components/tabs/index.js";

import app from "@/app/app.js";
import settings from "@/app/settings.js";

import usePopupDailyJuiceExploreHook from "./use-popup-daily-juice-explore.js";
import styles from "./styles.module.css";

import ContentExtraJuice from "./content-extra-juice.js";
import ContentVideo from "./content-video.js";
import RowItem from "./row-item.js";
import SectionLoadMore from "./section-load-more.js";


const TAB_VALUES = {
    extraJuices: "extraJuices",
    videos: "videos",
};

const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    dimensions: state.device.dimensions,
    extraJuices: state.extraJuices,
    juicesVideos: state.juicesVideos,
    juiceVideoById: state.juicesVideos.videoById,
    playerState: state.player.playerState,
    text2speech: state.text2speech,
    translation: state.translation,
    extraJuicesRatingById: state.uiStoriesRating.extraJuicesRatingById,
});

// TODO: split component: list, extra juice, video
const PopupDailyJuiceExplore = (props) => {
    const audioManager = useContext(AudioManagerContext);
    const popupContentRef = useRef(null);

    const [selectedPage, setSelectedPage] = useState(() => {
        if (props.defaultTab) {
            return props.defaultTab;
        }

        return TAB_VALUES.extraJuices;
    });

    /* --- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const selectedExtraJuiceId = props.selectedExtraJuice?.id || null;

    const trackGroupName = "extraJuices";
    const trackId = selectedExtraJuiceId;

    const text2SpeechHighlight = useText2SpeechHighlight();
    const playerAudioPlayback = usePlayerAudioPlayback(store.playerState, {
        getPlayerInfo: audioManager.getPlayerInfo,
    });

    const isMobile700 = store.dimensions.width <= 700;
    const isMobile900 = store.dimensions.width <= 900;

    /* --- */

    const selectedExtraJuice = useMemo(() => {
        if (!selectedExtraJuiceId) {
            return null;
        }

        return array.findOneById(
            store.extraJuices.extraJuices,
            selectedExtraJuiceId,
        );
    }, [
        selectedExtraJuiceId,
        store.extraJuices.extraJuices,
    ]);

    const selectedVideo = useMemo(() => {
        const id = props.selectedVideo?.id || null;
        const video = store.juiceVideoById?.[id] || null;

        const captionSrc = app.services.api.videos.getVideoCaptionURL({
            session: store.session,
            id,
        });

        const videoData = video?.data || null;

        return {
            id,
            title: props.selectedVideo?.title || "",
            url: videoData?.videoUrl || "",
            poster: videoData?.featuredImage?.sizes?.medium?.src || null,
            captionSrc,
            mimeType: videoData?.mimeType || "",
            error: video?.error || "",
            isLoading: !video?.isLoaded,
        };
    }, [
        props.selectedVideo,
        store.juiceVideoById,
    ]);

    /* --- */

    const getStoryTranslation = () => {
        return selectorsTranslation.storySelector(store, selectedExtraJuiceId);
    };

    /* --- */

    const onSelectVideo = (id, title) => {
        props.onSelectVideo(id, title);

        dispatch(actionsJuicesVideos.loadVideoById(
            {
                actions: app.services.actions,
                api: app.services.api,
            },
            { id },
        ));
    };

    const onWordClick = (word) => {
        const translation = getStoryTranslation();

        const language = translation.isChecked
            ? translation.language
            : "";

        app.actions.common.vocabulary.openPopupAndLoadTranslation({
            word,
            language,
        });
    };

    /* --- */

    const onHighlight = async () => {
        const { isVisible } = text2SpeechHighlight.state;

        if (isVisible) {
            text2SpeechHighlight.stop();
            return;
        }

        text2SpeechHighlight.setActive();

        let highlightData = [];

        if (store.text2speech?.[trackGroupName]?.[trackId]?.isHighlightLoaded) {
            highlightData = store.text2speech[trackGroupName][trackId].highlight;
        }

        if (!highlightData.length) {
            highlightData = await dispatch(actionsText2Speech.loadHighlight(
                {
                    actions: app.services.actions,
                    api: app.services.api,
                },
                { trackGroupName, trackId },
            ));
        }

        text2SpeechHighlight.start({
            scroller: {
                element: popupContentRef.current,
                offsetTop: styleProperties.getHeightPopupTitle(),
            },
            highlight: highlightData,
            getPlayerCurrentTime() {
                const { currentTime } = audioManager.getPlayerInfo(trackGroupName, trackId);
                return currentTime * 1000;
            },
            isPaused: !store.playerState?.[trackGroupName]?.[trackId]?.isPlaying,
            isMobile: isMobile900,
        });
    };

    const onAudioPlay = () => {
        props.onExtraJuiceShowPlayer(selectedExtraJuiceId);
        playerAudioPlayback.setCurrentTrack(trackGroupName, selectedExtraJuiceId);
        audioManager.loadExtraJuice(selectedExtraJuiceId);
    };

    /* --- */

    const onTranslate = useCallback((values) => {
        const languageCode = values.selectedValue;
        const languageName = values.selectedName;

        app.actions.common.translation.changeStoryUITranslationById({
            storyId: selectedExtraJuiceId,
            languageCode,
            isChecked: values.isChecked,
        });

        app.actions.common.translation.setDefaultLanguage({
            languageCode,
            languageName,
        });

        if (!values.isChecked) {
            return;
        }

        app.actions.common.translation.translateStoryById({
            storyId: selectedExtraJuiceId,
            language: languageCode,
        });
    }, [selectedExtraJuiceId]);

    /* --- */

    const renderPopupTitle = () => {
        if (selectedExtraJuice?.id) {
            return (
                <div className={styles.headerExtraJuice}>
                    <IconOrange
                        className={styles.headerExtraJuiceIcon}
                        title="Orange"
                    />

                    <div className={styles.headerExtraJuiceText}>
                        {selectedExtraJuice.title}
                    </div>
                </div>
            );
        }

        if (selectedVideo?.title) {
            return selectedVideo.title;
        }

        return "Explore";
    };

    const renderBackArrow = () => {
        if (!selectedExtraJuice?.id && !selectedVideo?.id) {
            return null;
        }

        return (
            <ButtonCircle
                icon={<IconArrowBold />}
                onClick={props.onBackToList}
                isImageBackwards
            />
        );
    };

    const renderExtraJuicePage = () => {
        const extraJuices = (store.extraJuices.extraJuices || []).map((ej) => {
            const icon = (
                <IconNotebook title="Read" isOrange />
            );

            return (
                <RowItem
                    src={ej.image}
                    title={ej.title}
                    icon={icon}
                    onClick={() => {
                        props.onSelectExtraJuice(ej.id);
                    }}
                />
            );
        });

        return (
            <div className={styles.explorePage}>
                {extraJuices}
                <SectionLoadMore
                    onClick={props.onLoadMoreExtraJuices}
                    hasMore={store.extraJuices.hasMorePages}
                    isLoading={store.extraJuices.isExtraJuicesLoading}
                />
            </div>
        );
    };

    const renderVideoPage = () => {
        const videos = (store.juicesVideos?.videos || []).map((video) => {
            const videoId = video?.videoID || null;
            const videoTitle = video?.newsTitle || "";

            const icon = (
                <IconPlayInCircle title="Play" isOrange />
            );

            return (
                <RowItem
                    src={video?.featuredImage?.sizes?.thumbnail?.src || null}
                    title={videoTitle}
                    icon={icon}
                    onClick={() => {
                        onSelectVideo(videoId, videoTitle);
                    }}
                />
            );
        });

        return (
            <div className={styles.explorePage}>
                {videos}
                <SectionLoadMore
                    onClick={props.onLoadMoreVideos}
                    hasMore={store.juicesVideos.hasMorePages}
                    isLoading={store.juicesVideos.isVideosLoading}
                />
            </div>
        );
    };

    const renderPage = () => {
        if (selectedPage === TAB_VALUES.extraJuices) {
            return renderExtraJuicePage();
        }

        if (selectedPage === TAB_VALUES.videos) {
            return renderVideoPage();
        }

        return null;
    };

    const renderTabs = () => {
        const tabs = [
            { value: TAB_VALUES.extraJuices, label: "Extra Juice" },
            { value: TAB_VALUES.videos, label: "Videos" },
        ];

        return (
            <div className={styles.tabs}>
                <Tabs
                    tabs={tabs}
                    selectedTab={selectedPage}
                    onChange={setSelectedPage}
                />
            </div>
        );
    };

    const renderPlayer = () => {
        if (!props.selectedExtraJuice?.isVisiblePlayer || !selectedExtraJuice) {
            return null;
        }

        let audioData = null;

        if (props.selectedExtraJuice?.isVisiblePlayer) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        const highlight = {
            onClick: onHighlight,
            isActive: text2SpeechHighlight.state.isActive,
            isLoaded: store.text2speech?.[trackGroupName]?.[trackId]?.isHighlightLoaded || false,
        };

        const playback = playerAudioPlayback.getPlayback(trackGroupName, trackId);

        return (
            <PlayerAudioV2
                image={selectedExtraJuice?.image || null}
                title={selectedExtraJuice.title}
                audio={audioData}
                error={audioData?.error || ""}
                highlight={highlight}
                playback={playback}
                onRetryPlay={() => {
                    audioManager.load(trackGroupName, trackId);
                }}
                onPlay={() => {
                    text2SpeechHighlight.resume();
                    playerAudioPlayback.setCurrentTrack(trackGroupName, trackId);
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                    text2SpeechHighlight.pause();
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                    text2SpeechHighlight.changeRate(rate);
                }}
                onClose={() => {
                    audioManager.pauseAll();
                    audioManager.stop(trackGroupName, trackId);
                    text2SpeechHighlight.stop();
                    props.onPlayerClose();
                }}
                isMobile={isMobile700}
                roundedPlayer
            />
        );
    };

    const renderSelectedExtraJuice = () => {
        const highlight = T2SHighlight.getStoryHighlight({
            ...text2SpeechHighlight.state,
            title: selectedExtraJuice?.title,
        });

        const withTranslation = settings.features.TRANSLATION
            && store.translation.isLanguagesLoaded;

        const translationStory = getStoryTranslation();

        const selectedLanguage = array.findOneByValue(
            translationStory.languages,
            translationStory.language,
        );

        const isDyslexicFont = Translation.isSupportDyslexicFont(selectedLanguage);

        const rateData = store.extraJuicesRatingById[selectedExtraJuice.id] || {};

        const rate = {
            rate: selectedExtraJuice.rate,
            error: rateData.error,
            loadingRatingByName: rateData.loadingRatingByName,
            onRate(rating) {
                app.actions.common.rate.rateExtraJuice({
                    extraJuiceId: selectedExtraJuice.id,
                    rating,
                });
            },
        };

        return (
            <ContentExtraJuice
                extraJuiceId={selectedExtraJuice.id}
                translation={translationStory}
                highlight={highlight}
                content={selectedExtraJuice.content}
                rate={rate}
                onListen={onAudioPlay}
                onTranslate={onTranslate}
                onReadingModeChange={app.actions.common.user.setReadingMode}
                onWordClick={onWordClick}
                withTranslation={withTranslation}
                withReadingModeButton={settings.features.ENHANCED_READING_MODE}
                isDyslexic={store.user.isEnabledReadingMode}
                isDyslexicFont={isDyslexicFont}
            />
        );
    };

    const renderSelectedVideo = () => {
        return (
            <ContentVideo
                id={selectedVideo.id}
                src={selectedVideo.url}
                poster={selectedVideo.poster}
                type={selectedVideo.mimeType}
                captionSrc={selectedVideo.captionSrc}
                isLoading={selectedVideo.isLoading}
            />
        );
    };

    const renderContent = () => {
        if (selectedExtraJuice?.id) {
            return renderSelectedExtraJuice();
        }

        if (selectedVideo?.id) {
            return renderSelectedVideo();
        }

        return (
            <div className={styles.content}>
                {renderTabs()}
                {renderPage()}
            </div>
        );
    };

    return (
        <PopupWindow
            withScrollRef={popupContentRef}
            title={renderPopupTitle()}
            leftControl={renderBackArrow()}
            player={renderPlayer()}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupDailyJuiceExplore.defaultProps = {
    defaultTab: "",
    highlight: {},
    selectedExtraJuice: null,
    selectedVideo: null,
    onBackToList: () => { },
    onSelectVideo: () => { },
    onSelectExtraJuice: () => { },
    onLoadMoreVideos: () => { },
    onLoadMoreExtraJuices: () => { },
    onExtraJuiceShowPlayer: () => { },
    onPlay: () => { },
    onPlayerClose: () => { },
    onClose: () => { },
};

export const usePopupDailyJuiceExplore = usePopupDailyJuiceExploreHook;
export default PopupDailyJuiceExplore;
