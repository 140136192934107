import utils from "@/base/application/utils.js";

import actionsApp from "@/base/actions/app.js";
import actionsDevice from "@/base/actions/device.js";
import actionsSite from "@/base/actions/site.js";
import actionsUser from "@/base/actions/user.js";
import actionsTranslation from "@/base/actions/translation.js";
import actionsDailyJuice from "@/base/actions/daily-juice.js";
import actionsAssignments from "@/base/actions/assignments.js";
import actionsNotifications from "@/base/actions/notifications.js";
import actionsSubscription from "@/base/actions/subscription.js";
import actionsSignUp from "@/base/actions/sign-up.js";
import actionsPaymentPlans from "@/base/actions/payment-plans.js";
import actionsPayment from "@/base/actions/payment.js";
import actionsRate from "@/base/actions/rate.js";
import actionsGeo from "@/base/actions/geo.js";
import actionsPolls from "@/base/actions/polls.js";
import actionsJuicesVideos from "@/base/actions/juices-videos.js";
import actionsJuicesStories from "@/base/actions/juices-stories.js";
import actionsTemplate from "@/base/actions/template.js";
import actionsSearch from "@/base/actions/search.js";
import actionsPages from "@/base/actions/pages.js";
import actionsFivesy from "@/base/actions/fivesy.js";
import actionsVocabulary from "@/base/actions/vocabulary.js";
import actionsPrint from "@/base/actions/print.js";
import actionsStandards from "@/base/actions/standards.js";

import actionsStudentExtraJuices from "@/base/actions/student-extra-juices.js";
import actionsStudentQuizzes from "@/base/actions/student-quizzes.js";
import actionsStudentGrades from "@/base/actions/student-grades.js";
import actionsStudentClasses from "@/base/actions/student-classes.js";
import actionsStudentAchievements from "@/base/actions/student-achievements.js";
import actionsStudentDailyJuices from "@/base/actions/student-daily-juices.js";
import actionsStudentAssignments from "@/base/actions/student-assignments.js";

import actionsTeacherClass from "@/base/actions/teacher-class.js";
import actionsTeacherClasses from "@/base/actions/teacher-classes.js";
import actionsTeacherClassDailyJuices from "@/base/actions/teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "@/base/actions/teacher-class-assignments.js";
import actionsTeacherClassAchievements from "@/base/actions/teacher-class-achievements.js";
import actionsTeacherStudentsProgress from "@/base/actions/teacher-students-progress.js";
import actionsTeacherStudentsScoreReport from "@/base/actions/teacher-students-score-report.js";
import actionsTeacherStudentsAssignments from "@/base/actions/teacher-students-assignments.js";

import actionsDistrictAdminSchools from "@/base/actions/district-admin-schools.js";
import actionsDistrictAdminClassAssignments from "@/base/actions/district-admin-class-assignments.js";
import actionsDistrictAdminClassDailyJuices from "@/base/actions/district-admin-class-daily-juices.js";
import actionsDistrictAdminClassScoreReport from "@/base/actions/district-admin-class-score-report.js";
import actionsDistrictAdminClassProgress from "@/base/actions/district-admin-class-progress.js";
import actionsDistrictAdminAchievements from "@/base/actions/district-admin-class-achievements.js";

import actionsAdminClass from "@/base/actions/admin-class.js";
import actionsAdminDepartment from "@/base/actions/admin-department.js";
import actionsAdminSchool from "@/base/actions/admin-school.js";
import actionsAdminDistricts from "@/base/actions/admin-districts.js";


const createApp = (services) => {
    const actionsByActor = {
        common: {
            app: actionsApp,
            device: actionsDevice,
            site: actionsSite,
            user: actionsUser,
            translation: actionsTranslation,
            dailyJuice: actionsDailyJuice,
            assignments: actionsAssignments,
            notifications: actionsNotifications,
            rate: actionsRate,
            subscription: actionsSubscription,
            signUp: actionsSignUp,
            paymentPlans: actionsPaymentPlans,
            payment: actionsPayment,
            geo: actionsGeo,
            polls: actionsPolls,
            juicesVideos: actionsJuicesVideos,
            juicesStories: actionsJuicesStories,
            template: actionsTemplate,
            search: actionsSearch,
            pages: actionsPages,
            fivesy: actionsFivesy,
            vocabulary: actionsVocabulary,
            print: actionsPrint,
            standards: actionsStandards,
        },
        student: {
            extraJuices: actionsStudentExtraJuices,
            quizzes: actionsStudentQuizzes,
            grades: actionsStudentGrades,
            classes: actionsStudentClasses,
            achievements: actionsStudentAchievements,
            dailyJuices: actionsStudentDailyJuices,
            assignments: actionsStudentAssignments,
        },
        teacher: {
            class: actionsTeacherClass,
            classes: actionsTeacherClasses,
            dailyJuices: actionsTeacherClassDailyJuices,
            classAssignments: actionsTeacherClassAssignments,
            classAchievements: actionsTeacherClassAchievements,
            studentsProgress: actionsTeacherStudentsProgress,
            studentsScoreReport: actionsTeacherStudentsScoreReport,
            studentsAssignments: actionsTeacherStudentsAssignments,
        },
        districtAdmin: {
            schools: actionsDistrictAdminSchools,
            classAssignments: actionsDistrictAdminClassAssignments,
            classDailyJuices: actionsDistrictAdminClassDailyJuices,
            classScoreReport: actionsDistrictAdminClassScoreReport,
            classProgress: actionsDistrictAdminClassProgress,
            classAchievements: actionsDistrictAdminAchievements,
        },
        admin: {
            class: actionsAdminClass,
            department: actionsAdminDepartment,
            school: actionsAdminSchool,
            districts: actionsAdminDistricts,
        },
    };

    const allActions = utils.prepareAllActions(services, actionsByActor);

    return {
        services,
        actions: allActions,
    };
};

export default {
    createApp,
};
