import { useState } from "react";


const useSnackbar = () => {
    const [bars, setBars] = useState([]);

    /* --- */

    const add = (params = {}) => {
        setBars((prevBars) => {
            const snackbar = {
                message: params.message || "",
                autoCloseInSeconds: params.autoCloseInSeconds || 5,
                onClose: params.onClose,
                onCloseWithUndo: params.onCloseWithUndo,
                withUndo: params.withUndo || false,
                isVisible: true,
            };

            return [...prevBars].concat(snackbar);
        });
    };

    const close = (index) => {
        setBars((prevBars) => {
            const newBars = [...prevBars];

            if (newBars[index]) {
                newBars[index].isVisible = false;
            }

            return newBars;
        });
    };

    /* --- */

    return {
        bars,
        add,
        close,
    };
};

export default useSnackbar;
