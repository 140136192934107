import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import array from "@/base/lib/array.js";

import TabsV2 from "@/base/components/tabs-v2/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import SectionSponsor from "@/base/components/section-sponsor/index.js";
import TabsScrolling from "@/base/components/tabs-scrolling/index.js";
import MessageErrorWithTryAgain from "@/base/components/message-error-with-try-again/index.js";
import Message from "@/base/components/message/index.js";

import SectionAnnouncement from "@/base/business/section-announcement/index.js";

import app from "@/app/app.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    classes: state.studentClasses.classes,
    sponsors: state.info.sponsors,
    isSponsorsLoaded: state.info.isSponsorsLoaded,
});

const StudentAnnouncements = () => {
    const [selectedClassId, setSelectedClassId] = useState(null);

    const store = useSelector(storeSelector);

    const classes = {
        data: store.classes.data || [],
        error: store.classes.error || "",
        isLoading: store.classes.isLoading
            || !store.classes.isLoaded,
    };

    const classesWithAnnouncements = useMemo(() => {
        const withAnnouncements = [];

        for (let i = 0; i < classes.data.length; i += 1) {
            const cl = classes.data[i];

            if (cl.announcementContent) {
                withAnnouncements.push(cl);
            }
        }

        return withAnnouncements;
    }, [classes.data.length]);

    /* --- */

    const loadStudentClasses = async () => {
        const res = await app.actions.student.classes.loadClasses();

        const classId = res?.data?.[0]?.id || null;

        if (classId) {
            setSelectedClassId(classId);
        }
    };

    /* --- */

    useEffect(() => {
        loadStudentClasses();
    }, []);

    /* --- */

    const getAnnouncement = () => {
        if (!selectedClassId) {
            return {};
        }

        const selectedClass = array.findOneById(
            classesWithAnnouncements,
            selectedClassId,
        );

        return {
            content: selectedClass?.announcementContent || "",
            title: selectedClass?.announcementTitle || "",
        };
    };

    /* --- */

    const renderSponsor = () => {
        return (
            <SectionSponsor
                imgSrc={store.sponsors.djHomeImage || ""}
                link={store.sponsors.djHomeUrl || ""}
            />
        );
    };

    const renderAnnouncement = () => {
        if (!selectedClassId) {
            return (
                <Message>
                    Please select class
                </Message>
            );
        }

        const announcement = getAnnouncement();

        const content = announcement.content || "";
        const title = announcement.title || "Class announcement";

        if (!content) {
            return null;
        }

        return (
            <SectionAnnouncement
                content={content}
                title={title}
            />
        );
    };

    const renderClasses = () => {
        const tabs = classesWithAnnouncements.map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (tabs.length > 3) {
            return (
                <div className={styles.tabsScrollable}>
                    <TabsScrolling
                        tabs={tabs}
                        selected={selectedClassId}
                        onChange={setSelectedClassId}
                    />
                </div>
            );
        }

        return (
            <div className={styles.tabs}>
                <TabsV2
                    tabs={tabs}
                    selectedTab={selectedClassId}
                    onChange={setSelectedClassId}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (!store.isSponsorsLoaded) {
            return (
                <RequestLoader />
            );
        }

        return (
            <>
                {renderSponsor()}
                {renderAnnouncement()}
            </>
        );
    };

    const renderClassesError = () => {
        return (
            <div className={styles.containerContent}>
                <MessageErrorWithTryAgain
                    onTryAgain={loadStudentClasses}
                >
                    {classes.error}
                </MessageErrorWithTryAgain>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <div className={styles.containerContent}>
                <RequestLoader />
            </div>
        );
    };

    /* --- */

    if (classes.isLoading) {
        return renderLoader();
    }

    if (classes.error) {
        return renderClassesError();
    }

    if (classesWithAnnouncements.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            {renderClasses()}
            <div className={styles.content}>
                {renderContent()}
            </div>
        </div>
    );
};

StudentAnnouncements.defaultProps = {};

export default StudentAnnouncements;
