import { useState } from "react";


const getValuePopupDefaultState = () => ({
    isOpen: false,
    isSaving: false,
    error: null,
});

const useValuePopup = () => {
    const [state, setState] = useState(getValuePopupDefaultState());

    const open = () => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
        }));
    };

    const close = () => {
        setState(getValuePopupDefaultState());
    };

    const setError = (error) => {
        setState((prev) => ({
            ...prev,
            isSaving: false,
            error,
        }));
    };

    const setIsSaving = () => {
        setState((prev) => ({
            ...prev,
            isSaving: true,
            error: null,
        }));
    };

    return {
        state,
        open,
        close,
        setError,
        setIsSaving,
    };
};

export default useValuePopup;
