import React from "react";

import styles from "./styles.module.css";


const EventsWrapper = (props) => {
    const onClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    const classes = [styles.eventsWrapper];

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="-1"
            className={classes.join(" ")}
        >
            {props.children}
        </div>
    );
};

EventsWrapper.defaultProps = {
    className: "",
};

export default EventsWrapper;
