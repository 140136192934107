import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const TooltipToggler = (props) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    /* --- */

    const onMouseEnter = () => {
        setTooltipVisible(true);
        props.onHover(true);
    };

    const onMouseLeave = () => {
        setTooltipVisible(false);
        props.onHover(false);
    };

    /* --- */

    const renderTooltip = () => {
        if (!isTooltipVisible) {
            return null;
        }

        return props.tooltip || null;
    };

    /* --- */

    const containerClassName = classNames({
        [styles.container]: true,
        [props.className]: !!props.className,
    });

    return (
        <div
            className={containerClassName}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {props.children}
            {renderTooltip()}
        </div>
    );
};

TooltipToggler.defaultProps = {
    className: "",
    tooltip: null,
    children: null,
    onHover: () => { },
};

export default TooltipToggler;
