const SET_STATE_STANDARDS_BY_STATE = "SET_STATE_STANDARDS_BY_STATE";

const SET_STANDARDS_BY_GROUPS_LOADING = "SET_STANDARDS_BY_GROUPS_LOADING";
const SET_STANDARDS_BY_GROUPS = "SET_STANDARDS_BY_GROUPS";

export {
    SET_STATE_STANDARDS_BY_STATE,
    SET_STANDARDS_BY_GROUPS_LOADING,
    SET_STANDARDS_BY_GROUPS,
};
