import React from "react";

import classNames from "@/base/lib/class-names.js";

import useButtonAnimation from "@/base/hooks/use-button-animation/index.js";

import StylesAnimation from "@/base/hooks/use-button-animation/styles.module.css";
import styles from "./styles.module.css";


const ButtonBigRounded = (props) => {
    const [isAnimate, addAnimate, aStyles] = useButtonAnimation();

    const onClick = (evt) => {
        addAnimate(evt);

        if (props.onClick) {
            props.onClick(evt);
        }
    };

    const renderAnimation = () => {
        const aClassName = classNames({
            [StylesAnimation.animation]: true,
            [styles.animationButtonPrimary]: props.isPrimary,
            [styles.animationButtonDelete]: props.isDelete,
            [StylesAnimation.animate]: isAnimate,
        });

        return (
            <div
                className={aClassName}
                style={aStyles}
            />
        );
    };

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonPrimary]: props.isPrimary,
        [styles.buttonDelete]: props.isDelete,
    });

    return (
        <button
            className={buttonClassName}
            onClick={onClick}
            disabled={props.isDisabled}
            // eslint-disable-next-line react/button-has-type
            type={props.type}
        >
            {renderAnimation()}
            {props.children}
        </button>
    );
};

ButtonBigRounded.defaultProps = {
    children: null,
    type: "button",
    isPrimary: true,
    isDelete: false,
    isDisabled: false,

    onClick: () => { },
};

export default ButtonBigRounded;
