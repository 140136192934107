import React from "react";
import DOMPurify from "dompurify";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const Text = (props) => {
    const className = classNames({
        [styles.text]: true,
        [props.className]: props.className,
    });

    const cleanText = DOMPurify.sanitize(props.children || "", {
        USE_PROFILES: { html: true },
        ADD_ATTR: ["target"],
    });

    if (props.onClick) {
        /* eslint-disable */
        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{
                    __html: cleanText,
                }}
                onClick={props.onClick}
            />
        );
        /* eslint-enable */
    }

    /* eslint-disable */
    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: cleanText,
            }}
        />
    );
    /* eslint-enable */
};

Text.defaultProps = {
    className: "",
    children: null,
    onClick: null,
};

export default Text;
