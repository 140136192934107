import React from "react";

import IconOrange from "@/base/icons/orange/index.js";

import ButtonRounded from "@/base/components/button-rounded/index.js";

import styles from "./styles.module.css";


const ExtraJuiceButtons = (props) => {
    const renderButtons = () => {
        return props.extraJuices.map((ej) => {
            return (
                <ButtonRounded onClick={props.onExtraJuiceClick}>
                    <IconOrange
                        className={styles.extraJuiceIcon}
                        isOrange
                    />
                    {`Extra Juice: ${ej.title}`}
                </ButtonRounded>
            );
        });
    };

    if (props.extraJuices.length === 0) {
        return null;
    }

    return (
        <div className={styles.extraJuices}>
            {renderButtons()}
        </div>
    );
};

ExtraJuiceButtons.defaultProps = {
    extraJuices: [],
    onExtraJuiceClick: () => { },
};

export default ExtraJuiceButtons;
