
import dimensions from "@/base/lib/dimensions.js";


const PROPERTIES = {
    height: {
        mainHeader: "--main-header-height",
        dailyJuiceNavHeightDesktop: "--daily-juice-nav-height-desktop",
        mobileHeaderBottomHeightDefault: "--mobile-header-bottom-height-default",
        mobileHeaderBottomHeightHigh: "--mobile-header-bottom-height-high",
        mobileHeaderBottomHeight: "--mobile-header-bottom-height",
        playerAudioHeight: "--player-audio-height",
        popupTitle: "--popup-title-height",
    },
    top: {
        popupWindow: "--popup-window-top",
    },
};

/* --- */

const getPropertyValue = (value) => {
    if (!window?.getComputedStyle || !window?.document?.documentElement) {
        return null;
    }

    return window.getComputedStyle(window.document.documentElement)
        .getPropertyValue(value);
};

const getPixels = (value) => {
    const valueREM = parseFloat(value, 10) || 0;
    return dimensions.convertRemToPixels(valueREM);
};

const getPropertyPixels = (property) => {
    const value = getPropertyValue(property);
    return getPixels(value);
};

/* --- */

const getHeightMainHeader = () => {
    return getPropertyPixels(PROPERTIES.height.mainHeader);
};

// TODO: we need more height in variable name
const getHeightMobileHeaderBottomHeight = () => {
    return getPropertyPixels(PROPERTIES.height.mobileHeaderBottomHeight);
};

/* --- */

const getMobileHeaderBottomHeightDefault = () => {
    return getPropertyValue(PROPERTIES.height.mobileHeaderBottomHeightDefault);
};

const getMobileHeaderBottomHeightHigh = () => {
    return getPropertyValue(PROPERTIES.height.mobileHeaderBottomHeightHigh);
};

const setMobileHeaderBottomHeight = (value) => {
    globalThis.document.documentElement.style.setProperty(PROPERTIES.height.mobileHeaderBottomHeight, value);
};

const setMobileHeaderBottomHeightDefault = () => {
    setMobileHeaderBottomHeight(getMobileHeaderBottomHeightDefault());
};

const setMobileHeaderBottomHeightHigh = () => {
    setMobileHeaderBottomHeight(getMobileHeaderBottomHeightHigh());
};

/* --- */

const getHeightPlayerAudioHeight = () => {
    return getPropertyPixels(PROPERTIES.height.playerAudioHeight);
};

/* --- */

const getHeightDailyJuiceNavDesktop = () => {
    return getPropertyPixels(PROPERTIES.height.dailyJuiceNavHeightDesktop);
};

/* --- */

const getHeightPopupTitle = () => {
    return getPropertyPixels(PROPERTIES.height.popupTitle);
};

const getPopupWindowTop = () => {
    return getPropertyPixels(PROPERTIES.top.popupWindow);
};

/* --- */

export default {
    getHeightMainHeader,
    getMobileHeaderBottomHeightDefault,
    getMobileHeaderBottomHeightHigh,
    setMobileHeaderBottomHeight,
    setMobileHeaderBottomHeightDefault,
    setMobileHeaderBottomHeightHigh,
    getHeightMobileHeaderBottomHeight,
    getHeightPlayerAudioHeight,
    getHeightDailyJuiceNavDesktop,
    getHeightPopupTitle,
    getPopupWindowTop,
};
