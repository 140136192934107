import React from "react";

import staticFiles from "@/base/static-files.js";

import urls from "@/base/lib/urls.js";
import classNames from "@/base/lib/class-names.js";

import Google from "@/base/project/google.js";

import LoaderSmall from "@/base/components/loader-small/index.js";

import styles from "./styles.module.css";


const ClassroomShareButtonWithLabel = (props) => {
    const onClick = () => {
        if (props.isDisabled) {
            return;
        }

        props.onClick();

        if (!props.path) {
            return;
        }

        const link = Google.getGoogleClassroomShareLink(
            props.shareUrl,
            props.path,
            props.title,
        );

        urls.openUrl(link);
    };

    let loader = null;

    if (props.isLoading) {
        loader = (
            <LoaderSmall />
        );
    }

    const containerClassNames = classNames({
        [styles.container]: true,
        [props.className]: props.className,
    });

    return (
        <div
            className={containerClassNames}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
            role="button"
        >
            <img
                src={staticFiles.googleClassroom32}
                alt="Google Classroom"
            />
            <div>
                Google Classroom
            </div>
            {loader}
        </div>
    );
};

ClassroomShareButtonWithLabel.defaultProps = {
    className: "",
    title: "",
    path: "",
    shareUrl: "",

    onClick: () => { },

    isLoading: false,
    isDisabled: false,
};

export default ClassroomShareButtonWithLabel;
