// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mCR6WXyvgY5VEfNKug1i {\n    color: var(--text-primary-color);\n    padding: 0 2rem 2rem 2rem;\n}\n\n/* --- */\n\n@media only screen and (min-width: 620px) {\n    .mCR6WXyvgY5VEfNKug1i {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/juice-story-title/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA,QAAQ;;AAER;IACI;QACI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".headline {\n    color: var(--text-primary-color);\n    padding: 0 2rem 2rem 2rem;\n}\n\n/* --- */\n\n@media only screen and (min-width: 620px) {\n    .headline {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "mCR6WXyvgY5VEfNKug1i"
};
export default ___CSS_LOADER_EXPORT___;
