import Achievements from "@/base/project/achievements.js";

import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    achievements: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_ACHIEVEMENTS_LOADING: {
            return {
                ...state,
                achievements: {
                    ...state.achievements,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        }

        case types.SET_ACHIEVEMENTS: {
            const { data, error } = action.payload;

            return {
                ...state,
                achievements: {
                    data,
                    error,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        case types.SET_ACHIEVEMENT_VALUE_BY_TYPE: {
            const { value, type } = action.payload;
            const prevData = state.achievements.data || [];

            if (prevData.length === 0) {
                return state;
            }

            for (let i = 0; i < prevData.length; i += 1) {
                const d = prevData[i];

                if (d.type === type) {
                    d.value = value;
                }
            }

            return {
                ...state,
                achievements: {
                    ...state.achievements,
                    data: prevData,
                },
            };
        }

        case types.ADD_STORIES_READ: {
            const prevData = state.achievements?.data || [];
            const data = [];

            for (let i = 0; i < prevData.length; i += 1) {
                const d = { ...prevData[i] };

                if (Achievements.isStoriesRead(d.type)) {
                    d.value += 1;
                }

                data.push(d);
            }

            return {
                ...state,
                achievements: {
                    ...state.achievements,
                    data,
                },
            };
        }

        case types.ADD_QUESTIONS_ATTEMPTED: {
            const prevData = state.achievements?.data || [];
            const data = [];

            for (let i = 0; i < prevData.length; i += 1) {
                const d = { ...prevData[i] };

                if (Achievements.isQuestionsAttempted(d.type)) {
                    d.value += 1;
                }

                data.push(d);
            }

            return {
                ...state,
                achievements: {
                    ...state.achievements,
                    data,
                },
            };
        }

        case types.CLEAR_ACHIEVEMENTS: {
            return {
                ...state,
                achievements: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
