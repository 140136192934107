// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wkusXznscHxZZFfW100X {}\n\n/* --- */\n\n.CSrbDU5ZI7AYIFih4IqA {\n    border-radius: var(--border-default-radius-2);\n\n    background-color: var(--red-500);\n\n    width: 1rem;\n    height: 1rem;\n\n    transition: all 200ms ease-out 0s;\n}\n\n.ZcOyf35v93nGq6FNBl56 {\n    transform: translateY(-10px);\n    opacity: 0;\n}\n\n.pvQMsJwludOlB3dSHJZA {\n    width: 1.8rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/notification-badge/styles.module.css"],"names":[],"mappings":"AAAA,uBAAY;;AAEZ,QAAQ;;AAER;IACI,6CAA6C;;IAE7C,gCAAgC;;IAEhC,WAAW;IACX,YAAY;;IAEZ,iCAAiC;AACrC;;AAEA;IACI,4BAA4B;IAC5B,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".container {}\n\n/* --- */\n\n.badge {\n    border-radius: var(--border-default-radius-2);\n\n    background-color: var(--red-500);\n\n    width: 1rem;\n    height: 1rem;\n\n    transition: all 200ms ease-out 0s;\n}\n\n.badgeHidden {\n    transform: translateY(-10px);\n    opacity: 0;\n}\n\n.badgeIcon {\n    width: 1.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "wkusXznscHxZZFfW100X",
	"badge": "CSrbDU5ZI7AYIFih4IqA",
	"badgeHidden": "ZcOyf35v93nGq6FNBl56",
	"badgeIcon": "pvQMsJwludOlB3dSHJZA"
};
export default ___CSS_LOADER_EXPORT___;
