import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconProfileSettings = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="15"
                    height="13"
                    viewBox="0 0 15 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M6 6.00097C7.65685 6.00097 9 4.65783 9 3.00098C9 1.34412 7.65685 0.000976562 6 0.000976562C4.34315 0.000976562 3 1.34412 3 3.00098C3 4.65783 4.34315 6.00097 6 6.00097Z"
                    />
                    <path
                        fill="currentColor"
                        d="M6.5025 6.76598C6.3375 6.75848 6.1725 6.75098 6 6.75098C4.185 6.75098 2.49 7.25348 1.0425 8.11598C0.3825 8.50598 0 9.24098 0 10.0135V12.001H6.945C6.3525 11.1535 6 10.1185 6 9.00098C6 8.19848 6.1875 7.44848 6.5025 6.76598Z"
                    />
                    <path
                        fill="currentColor"
                        d="M14.0627 9.00098C14.0627 8.83598 14.0402 8.68598 14.0177 8.52848L14.8727 7.77098L14.1227 6.47348L13.0352 6.84098C12.7952 6.63848 12.5252 6.48098 12.2252 6.36848L12.0002 5.25098H10.5002L10.2752 6.36848C9.97519 6.48098 9.70519 6.63848 9.46519 6.84098L8.37769 6.47348L7.62769 7.77098L8.48269 8.52848C8.46019 8.68598 8.43769 8.83598 8.43769 9.00098C8.43769 9.16598 8.46019 9.31598 8.48269 9.47348L7.62769 10.231L8.37769 11.5285L9.46519 11.161C9.70519 11.3635 9.97519 11.521 10.2752 11.6335L10.5002 12.751H12.0002L12.2252 11.6335C12.5252 11.521 12.7952 11.3635 13.0352 11.161L14.1227 11.5285L14.8727 10.231L14.0177 9.47348C14.0402 9.31598 14.0627 9.16598 14.0627 9.00098ZM11.2502 10.501C10.4252 10.501 9.75019 9.82598 9.75019 9.00098C9.75019 8.17598 10.4252 7.50098 11.2502 7.50098C12.0752 7.50098 12.7502 8.17598 12.7502 9.00098C12.7502 9.82598 12.0752 10.501 11.2502 10.501Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconProfileSettings;
