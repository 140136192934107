import React from "react";

import IconDashboard from "@/base/icons/dashboard/index.js";
import IconGraph from "@/base/icons/graph/index.js";
import IconHome from "@/base/icons/home/index.js";
import IconClipboard from "@/base/icons/clipboard/index.js";
import IconSearch from "@/base/icons/search/index.js";
import IconUsers from "@/base/icons/users/index.js";
import IconFolders from "@/base/icons/folders/index.js";

import IconTeacherEdition from "@/base/icons/teacher-edition/index.js";
import IconStudentEdition from "@/base/icons/student-edition/index.js";

import IconNotepadCheck from "@/base/icons/notepad-check/index.js";
import IconNotepadExclamation from "@/base/icons/notepad-exclamation/index.js";
import IconNotepadStop from "@/base/icons/notepad-stop/index.js";
import IconNotepadX from "@/base/icons/notepad-x/index.js";

import IconPlayInCircle from "@/base/icons/play-in-circle/index.js";
import IconPlayInDottedCircle from "@/base/icons/play-in-dotted-circle/index.js";

import IconPollAnswered from "@/base/icons/poll-answered/index.js";
import IconPollUnanswered from "@/base/icons/poll-unanswered/index.js";


const ICONS_BY_NAME = {
    dashboard: IconDashboard,
    graph: IconGraph,
    home: IconHome,
    teacherEdition: IconTeacherEdition,
    studentEdition: IconStudentEdition,
    resources: IconFolders,
    clipboard: IconClipboard,
    users: IconUsers,
    search: IconSearch,
};

const STORY_LEGEND_ICONS = {
    notepadCheck: {
        icon: IconNotepadCheck,
        props: {
            isGreen: true,
        },
    },

    notepadExclamation: {
        icon: IconNotepadExclamation,
        props: {
            isSky: true,
        },
    },

    notepadStop: {
        icon: IconNotepadStop,
        props: {
            isRed: true,
        },
    },

    notepadX: {
        icon: IconNotepadX,
        props: {
            isGreen: true,
        },
    },

    playInCircle: {
        icon: IconPlayInCircle,
        props: {
            isGreen: true,
        },
    },

    playInDottedCircle: {
        icon: IconPlayInDottedCircle,
        props: {
            isSky: true,
        },
    },

    pollAnswered: {
        icon: IconPollAnswered,
        props: {
            isSky: true,
        },
    },

    pollUnanswered: {
        icon: IconPollUnanswered,
        props: {
            isSky: true,
        },
    },
};

const getIconByName = (name) => {
    return ICONS_BY_NAME[name] || null;
};

const getStoryLegendIconByName = (name, additionalProps = {}) => {
    const iconData = STORY_LEGEND_ICONS[name] || null;

    if (!iconData) {
        return null;
    }

    const props = iconData.props || {};

    const componentProps = {
        ...props,
        ...additionalProps,
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <iconData.icon {...componentProps} />
    );
};

export {
    getIconByName,
    getStoryLegendIconByName,
};

export default {};
