import React from "react";

import classNames from "@/base/lib/class-names.js";

import Text from "@/base/components/text/index.js";

import styles from "./styles.module.css";


const RowText = (props) => {
    const {
        text,
        onClick,
        isMobile,
        isClickable,
    } = props;

    const rowClassName = classNames({
        [styles.rowText]: true,
        [styles.rowTextClickable]: isClickable,
        [styles.rowTextMobile]: isMobile,
    });

    return (
        <Text
            className={rowClassName}
            onClick={onClick}
        >
            {text}
        </Text>
    );
};

RowText.defaultProps = {
    text: "",
    onClick: () => { },
    isClickable: false,
    isMobile: false,
};

export default RowText;
