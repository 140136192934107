import React from "react";

import { Formik } from "formik";

import v from "@/base/lib/form-validators.js";
import date from "@/base/lib/date.js";

import ButtonBig from "@/base/components/button-big/index.js";

import Input from "@/base/components/forms/input/index.js";
import Select from "@/base/components/forms/select/index.js";
import InputDate from "@/base/components/forms/input-date/index.js";

import styles from "./styles.module.css";


const TeacherClassSetUpForm = (props) => {
    const getFormErrors = (formProps) => {
        const errors = {};

        const endDate = v.getError(formProps, "endDate");
        const className = v.getError(formProps, "className");

        if (endDate) {
            errors.endDate = endDate;
        }

        if (className) {
            errors.className = className;
        }

        const hasErrors = Object.keys(errors).length > 0;

        return {
            endDate,
            className,
            hasErrors,
        };
    };

    /* --- */

    const validateForm = (values) => {
        const errors = {};

        if (props.withEndDate) {
            const endDateError = v.validate(values.endDate, [
                v.required("Please select end date"),
            ]);

            if (endDateError) {
                errors.endDate = endDateError;
            }
        }

        return errors;
    };

    /* --- */

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            isSubmitting,
        } = formProps;

        let departmentField = null;

        const errors = getFormErrors(formProps);

        if (!props.isFamily) {
            departmentField = (
                <Select
                    name="department"
                    label="Department"
                    selected={values.department}
                    options={props.departmentsOptions}
                    onSelect={(val) => {
                        setFieldValue("department", val);
                    }}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    isCreatable
                />
            );
        }

        let endDateField = null;

        if (props.withEndDate) {
            const endDate = values.endDate
                ? date.newDateUTC(values.endDate)
                : null;

            endDateField = (
                <InputDate
                    value={endDate}
                    label="End date *"
                    min={new Date()}
                    error={errors.endDate}
                    onSelect={(d) => {
                        const selectedDate = date.getDateFromDate(d);
                        setFieldValue("endDate", selectedDate);
                    }}
                    onBlur={() => {
                        setFieldTouched("endDate", true);
                    }}
                    isDropUp
                />
            );
        }

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.fields}>
                    {departmentField}
                    <Input
                        name="className"
                        label="Class Name"
                        value={values.className}
                        error={errors.className}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Select
                        name="grades"
                        label="Default Reading Level"
                        selected={values.grades}
                        options={props.grades}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onSelect={(val) => {
                            setFieldValue("grades", val);
                        }}
                    />
                    {endDateField}
                    {props.message}
                </div>

                <div className={styles.submitButton}>
                    <ButtonBig
                        type="button"
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onClick={props.onBack}
                    >
                        Back
                    </ButtonBig>
                    <ButtonBig
                        type="submit"
                        disabled={errors.hasErrors || isSubmitting}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                    >
                        {props.submitButtonText}
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

TeacherClassSetUpForm.defaultProps = {
    initialValues: {},
    grades: [],
    departmentsOptions: [],
    message: null,
    submitButtonText: "Finish",
    onBack: () => { },
    onSubmit: () => { },
    isDisabledTabIndex: false,
    isFamily: false,
    withEndDate: false,
};

export default TeacherClassSetUpForm;
