import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        open(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportOpen,
                objectIdString: params.reportId,
            };

            debugEvent("Open progress report", event);
            methods.sendEvent(event);
        },

        runNow(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportRunNow,
                objectIdString: params.reportId,
            };

            debugEvent("Progress report run now", event);
            methods.sendEvent(event);
        },

        changeRecommendationOnly(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportRecommendationOnly,
                objectId: params.isSelected ? 1 : 0,
            };

            debugEvent("Progress report change recommendation only", event);
            methods.sendEvent(event);
        },

        changeAutoAdjust(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportAutoAdjust,
                objectIdString: params.reportId,
                objectId: params.isEnabled ? 1 : 0,
            };

            debugEvent("Progress report change auto adjust", event);
            methods.sendEvent(event);
        },

        updateStudentGrade(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportUpdateStudentGrade,
                objectIdString: params.reportId,
                objectId: params.studentId,
                subObjectId: params.grade,
            };

            debugEvent("Progress report update student grade", event);
            methods.sendEvent(event);
        },

        bulkUpdateStudentsGrade(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportBulkUpdateStudentsGrade,
                objectIdString: params.reportId,
            };

            debugEvent("Progress report bulk update students grade", event);
            methods.sendEvent(event);
        },

        updateFrequencySetting(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportFrequencySetting,
                objectId: params.frequency,
            };

            debugEvent("Progress report frequency setting", event);
            methods.sendEvent(event);
        },

        download(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.progressReportDownload,
                objectIdString: params.reportId,
            };

            debugEvent("Progress report download", event);
            methods.sendEvent(event);
        },
    };
};
