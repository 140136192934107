const START_KEY = "[[";
const END_KEY = "]]";

const RE_START_KEY = /\[\[/g;
const RE_END_KEY = /\]\]/g;


const parse = (str, startKey = START_KEY, endKey = END_KEY) => {
    let startValue = 0;
    const indexes = [];

    for (;;) {
        const indexOfStart = str.indexOf(startKey, startValue);
        const indexOfEnd = str.indexOf(endKey, indexOfStart);

        if (indexOfStart === -1 || indexOfEnd === -1) {
            indexes.push(str.slice(startValue));
            break;
        }

        const beforeStr = str.slice(startValue, indexOfStart);
        const contentStr = str.slice(indexOfStart, indexOfEnd + endKey.length);

        if (beforeStr) {
            indexes.push(beforeStr);
        }

        if (contentStr) {
            indexes.push(contentStr);
        }

        startValue = indexOfEnd + endKey.length;
    }

    return indexes;
};

const isTag = (str, startKey = START_KEY, endKey = END_KEY) => {
    return str.indexOf(startKey) === 0
        && str.indexOf(endKey) === (str.length - endKey.length);
};

const trimTag = (str, reStartKey = RE_START_KEY, reEndKey = RE_END_KEY) => {
    return str.replace(reStartKey, "").replace(reEndKey, "").trim();
};

/* --- */

const parseShortcode = (str) => {
    let startValue = 0;

    const indexes = [];

    for (;;) {
        const openIndexOfStart = str.indexOf("[", startValue);
        const openIndexOfEnd = str.indexOf("]", openIndexOfStart);

        if (openIndexOfStart === -1 || openIndexOfEnd === -1) {
            const endContent = str.slice(startValue);

            if (endContent) {
                indexes.push(endContent);
            }

            break;
        }

        const closeIndexOfStart = str.indexOf("[", openIndexOfEnd);
        const closeIndexOfEnd = str.indexOf("]", closeIndexOfStart);

        const tagOpen = str.slice(openIndexOfStart, openIndexOfEnd + 1);
        const tagContent = str.slice(openIndexOfEnd + 1, closeIndexOfStart);
        const tagClose = str.slice(closeIndexOfStart, closeIndexOfEnd + 1);

        const beforeStr = str.slice(startValue, openIndexOfStart);

        if (beforeStr) {
            indexes.push(beforeStr);
        }

        indexes.push({
            tagOpen,
            tagContent,
            tagClose,
        });

        startValue = closeIndexOfEnd + 1;
    }

    return indexes;
};

const isTagShortcode = (tag) => {
    if (typeof tag === "object" && tag.tagOpen && tag.tagClose) {
        return true;
    }

    return false;
};

const isTagShortcodeName = (tag, name) => {
    if (tag && tag.tagOpen) {
        return tag.tagOpen.indexOf(`[${name}`) === 0;
    }
    return false;
};

/* --- */

const parseCurlyBrackets = (str) => {
    return parse(str, "{{", "}}");
};

const isTagCurlyBrackets = (str) => {
    return isTag(str, "{{", "}}");
};

const trimTagCurlyBrackets = (str) => {
    return trimTag(str, /\{\{/g, /\}\}/g);
};

/* --- */

export default {
    parse,
    isTag,
    trimTag,

    isTagCurlyBrackets,
    parseCurlyBrackets,
    trimTagCurlyBrackets,

    parseShortcode,
    isTagShortcode,
    isTagShortcodeName,
};
