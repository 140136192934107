// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wVmcnceqkpCiFYIMo8Gd {\n    resize: none;\n    height: 20rem;\n}\n\n.Ji2h7blQ5v54uMXmQNgD {\n    height: 15rem;\n}\n\n.C2pQwohvCAf10appLGLG {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 0.5rem 1rem;\n\n    color: var(--text-secondary-color);\n    font-size: 1.4rem;\n}\n\n.cZ5h6QfIlEYMXkfg6I0x {\n    color: var(--field-info-color);\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/textarea/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,8BAA8B;IAC9B,mBAAmB;;IAEnB,oBAAoB;;IAEpB,kCAAkC;IAClC,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".textarea {\n    resize: none;\n    height: 20rem;\n}\n\n.textareaMini {\n    height: 15rem;\n}\n\n.maxLengthMessage {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 0.5rem 1rem;\n\n    color: var(--text-secondary-color);\n    font-size: 1.4rem;\n}\n\n.maxLengthMessageMax {\n    color: var(--field-info-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "wVmcnceqkpCiFYIMo8Gd",
	"textareaMini": "Ji2h7blQ5v54uMXmQNgD",
	"maxLengthMessage": "C2pQwohvCAf10appLGLG",
	"maxLengthMessageMax": "cZ5h6QfIlEYMXkfg6I0x"
};
export default ___CSS_LOADER_EXPORT___;
