import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconClock = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        opacity="0.12"
                        d="M13.3327 2.66699C7.43935 2.66699 2.66602 7.44032 2.66602 13.3337C2.66602 19.227 7.43935 24.0003 13.3327 24.0003C19.226 24.0003 23.9993 19.227 23.9993 13.3337C23.9993 7.44032 19.226 2.66699 13.3327 2.66699ZM18.9993 18.867L11.9993 14.667V6.66699H13.9993V13.667L19.9993 17.227L18.9993 18.867Z"
                    />
                    <path
                        fill="currentColor"
                        opacity="0.8"
                        d="M13.32 0C5.96 0 0 5.97334 0 13.3333C0 20.6933 5.96 26.6667 13.32 26.6667C20.6933 26.6667 26.6667 20.6933 26.6667 13.3333C26.6667 5.97334 20.6933 0 13.32 0ZM13.3333 24C7.44 24 2.66667 19.2267 2.66667 13.3333C2.66667 7.44 7.44 2.66667 13.3333 2.66667C19.2267 2.66667 24 7.44 24 13.3333C24 19.2267 19.2267 24 13.3333 24ZM14 6.66667H12V14.6667L19 18.8667L20 17.2267L14 13.6667V6.66667Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconClock;
