import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import ButtonBig from "@/base/components/button-big/index.js";

import TextEditor from "@/base/components/forms/text-editor/index.js";

import styles from "./styles.module.css";


const TeacherNotes = (props) => {
    const [editorState, setEditorState] = useState({
        content: null,
        size: 0,
        isChanged: false,
    });

    /* ------ */

    const onSave = () => {
        props.onSave(editorState);
    };

    const onEditorChange = (content, size) => {
        const params = {
            content,
            size,
            isChanged: props.defaultText !== content,
        };

        setEditorState(params);
        props.onChange(params);
    };

    /* ------ */

    const renderTextEditor = () => {
        return (
            <TextEditor
                defaultValue={props.defaultText}
                onChange={onEditorChange}
            />
        );
    };

    const renderControls = () => {
        const isMaxCharsReached = editorState.size > props.maxChars;

        const charactersClassName = classNames({
            [styles.maxChars]: true,
            [styles.maxCharsReached]: isMaxCharsReached,
        });

        return (
            <div className={styles.controls}>
                <div className={charactersClassName}>
                    {`${editorState.size}/${props.maxChars} characters`}
                </div>
                <ButtonBig
                    disabled={isMaxCharsReached}
                    onClick={onSave}
                >
                    Save
                </ButtonBig>
            </div>
        );
    };

    return (
        <div className={styles.content}>
            {renderTextEditor()}
            {renderControls()}
        </div>
    );
};

TeacherNotes.defaultProps = {
    defaultText: "",
    maxChars: 100,
    onChange: () => { },
    onSave: () => { },
};

export default TeacherNotes;
