// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lZmE9ThuqGGDFNIyYjk4 {\n    padding: 3rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    font-size: 2.5rem;\n\n    color: var(--text-primary-color);\n}\n", "",{"version":3,"sources":["webpack://./base/components/error-boundary-with-value/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;;IAEjB,gCAAgC;AACpC","sourcesContent":[".error {\n    padding: 3rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    font-size: 2.5rem;\n\n    color: var(--text-primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "lZmE9ThuqGGDFNIyYjk4"
};
export default ___CSS_LOADER_EXPORT___;
