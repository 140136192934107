import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconStandards = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="21"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M9.5 9.55C7.14 7.35 3.98 6 0.5 6V17C3.98 17 7.14 18.35 9.5 20.55C11.86 18.36 15.02 17 18.5 17V6C15.02 6 11.86 7.35 9.5 9.55ZM9.5 6C11.16 6 12.5 4.66 12.5 3C12.5 1.34 11.16 0 9.5 0C7.84 0 6.5 1.34 6.5 3C6.5 4.66 7.84 6 9.5 6Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconStandards;
