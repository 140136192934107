const hasUnreadNotifications = (data) => {
    for (let i = 0; i < data.length; i += 1) {
        const notification = data[i];

        if (!notification.isViewed) {
            return true;
        }
    }

    return false;
};

export default {
    hasUnreadNotifications,
};
