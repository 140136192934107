import * as types from "./types.js";


export const setExtraJuices = (payload) => ({
    type: types.SET_EXTRA_JUICES,
    payload,
});

export const setExtraJuicesLoading = () => ({
    type: types.SET_EXTRA_JUICES_LOADING,
});

export const setExtraJuice = (payload) => ({
    type: types.SET_EXTRA_JUICE,
    payload,
});

export const setExtraJuiceRate = (payload) => ({
    type: types.SET_EXTRA_JUICE_RATE,
    payload,
});
