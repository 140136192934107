import React from "react";

import styles from "./styles.module.css";


const IconWithName = (props) => {
    return (
        <div
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
            className={styles.container}
        >
            {props.children}
            <div className={styles.iconName}>
                {props.name}
            </div>
        </div>
    );
};

IconWithName.defaultProps = {
    name: "",
    children: null,
    onClick: () => { },
};

export default IconWithName;
