import date from "@/base/lib/date.js";
import Popups from "@/base/project/popups.js";


const popupState = {
    id: -1,
    timesDisplayed: 0,
    timeLastClosed: new Date().getTime(),
};

const loadPopups = (services) => {
    const { actions, api, storage } = services;

    return async (dispatch) => {
        const localPopups = storage.local.loadPopups();

        const res = await api.popups.getPopups();
        const loadedPopups = res.ok ? res.popups || [] : [];

        const ps = loadedPopups.map((popup) => {
            const localPopup = Popups.getPopupById(localPopups, popup.id);

            const pState = localPopup
                ? { ...popupState, id: popup.id, ...localPopup }
                : { ...popupState, id: popup.id };

            return {
                popup,
                state: pState,
            };
        });

        dispatch(actions.popups.setPopups({
            popups: ps,
        }));
    };
};

const closePopup = (services, params) => {
    const { actions, storage } = services;

    return async (dispatch) => {
        if (!params.isTest) {
            const localPopups = storage.local.loadPopups();
            const localPopup = Popups.getPopupById(localPopups, params.popupId);

            let newPopup = {};
            let newPopups = [];

            if (!localPopup) {
                newPopup = {
                    ...popupState,
                    id: params.popupId,
                    timesDisplayed: 1,
                    timeLastClosed: new Date().getTime(),
                };

                newPopups = [
                    ...localPopups,
                    newPopup,
                ];
            } else {
                newPopup = {
                    ...localPopup,
                    timesDisplayed: localPopup.timesDisplayed + 1,
                    timeLastClosed: new Date().getTime(),
                };

                newPopups = [...localPopups].filter((p) => p.id !== newPopup.id);
                newPopups.push(newPopup);
            }

            storage.local.savePopups(newPopups);
        }

        dispatch(actions.popups.setPopupClosing({
            popupId: params.popupId,
        }));

        const seconds = 0.5;

        setTimeout(() => {
            dispatch(actions.popups.setPopupClosed({
                popupId: params.popupId,
            }));
        }, seconds * 1000);
    };
};

const closePopupAfterTimeout = (services, params) => {
    return async (dispatch) => {
        const operation = closePopup(services, params);

        setTimeout(() => {
            operation(dispatch);
        }, params.timeoutSeconds * 1000);
    };
};

const checkPopupsStates = (services) => {
    const { actions } = services;

    return async (dispatch, getState) => {
        const popups = getState()?.popups?.popups || [];

        for (let i = 0; i < popups.length; i += 1) {
            const { popup, state } = popups[i];

            const limitReached = popup.totalTimesToDisplay === state.timesDisplayed;

            const diffHours = date.getDiffHours(new Date(state.timeLastClosed), new Date());
            const canShowPopupAgain = diffHours >= popup.nextTimeToDisplay;

            if (state.isClosed && canShowPopupAgain && !limitReached) {
                dispatch(actions.popups.setPopupOpened({
                    popupId: popup.id,
                }));
            }
        }
    };
};

export default {
    loadPopups,
    closePopup,
    closePopupAfterTimeout,
    checkPopupsStates,
};
