import text from "@/base/text/index.js";


const loadUserAttempts = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.fivesy.setFivesyAttemptsLoading());

        const res = await api.fivesy.getUserAttempts({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.words || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.fivesy.setFivesyAttempts({
            data,
            error,
        }));
    };
};

const loadUserStats = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.fivesy.setFivesyStatsLoading());

        const res = await api.fivesy.getUserStats({
            session: state.user.session,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.fivesy.setFivesyStats({
            data,
            error,
        }));
    };
};

const guessWord = (services, params) => {
    const { api } = services;
    const { gameId, location, word } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const { session } = state.user;

        services.events.fivesy.guess({
            session,
            gameId,
            location,
            guess: word,
        });

        const res = await api.fivesy.guessWord({
            session,
            word,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const loadWord = (services) => {
    const { api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.fivesy.getWord({
            session: state.user.session,
        });

        let words = [];
        let error = "";

        if (res.ok) {
            words = res.data || [];
        } else {
            error = res.error || text.error;
        }

        return {
            words,
            error,
        };
    };
};

export default {
    loadUserAttempts,
    loadUserStats,
    loadWord,
    guessWord,
};
