import { useState, useEffect } from "react";


const getState = () => ({
    trackId: null,
    trackGroupName: null,
    players: {},
});

const usePlayerAudioPlayback = (playerState, callbacks) => {
    const [state, setState] = useState(() => getState());

    /* --- */

    const updatePlayback = () => {
        const { trackId, trackGroupName } = state;
        const { currentTime, totalDuration } = callbacks.getPlayerInfo(trackGroupName, trackId);

        setState((prev) => {
            const players = { ...prev.players };
            const prevByGroundName = players?.[trackGroupName] || {};

            players[trackGroupName] = {
                ...prevByGroundName,
                [trackId]: {
                    duration: totalDuration,
                    currentTime,
                },
            };

            return {
                ...prev,
                players,
            };
        });
    };

    /* --- */

    useEffect(() => {
        const { trackGroupName, trackId } = state;

        if (!trackGroupName || !trackId) {
            return;
        }

        const audio = playerState?.[trackGroupName]?.[trackId] || null;

        if (!audio) {
            return;
        }

        const { isLoading, isPlaying, error } = audio;

        if (isLoading || error || !isPlaying) {
            return;
        }

        const playbackChecker = globalThis.setInterval(() => {
            updatePlayback();
        }, 250);

        // eslint-disable-next-line consistent-return
        return () => {
            globalThis.clearInterval(playbackChecker);
        };
    }, [playerState, state.trackId, state.trackGroupName]);

    /* --- */

    const getPlayback = (trackGroupName, trackId) => {
        return {
            duration: state.players?.[trackGroupName]?.[trackId]?.duration || 0,
            currentTime: state.players?.[trackGroupName]?.[trackId]?.currentTime || 0,
        };
    };

    const setCurrentTrack = (trackGroupName, trackId) => {
        setState((prev) => ({
            ...prev,
            trackGroupName,
            trackId,
        }));
    };

    /* --- */

    return {
        players: state.players,
        setCurrentTrack,
        getPlayback,
    };
};

export default usePlayerAudioPlayback;
