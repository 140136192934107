import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        open(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.scoreReportOpen,
                objectId: params.classId,
            };

            debugEvent("Score report open", event);
            methods.sendEvent(event);
        },

        run(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.scoreReportRun,
                objectId: params.classId,
            };

            debugEvent("Score report run", event);
            methods.sendEvent(event);
        },

        downloadAll(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.scoreReportDownloadAll,
                objectId: params.classId,
            };

            debugEvent("Score report download all", event);
            methods.sendEvent(event);
        },

        downloadStudent(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.scoreReportDownloadStudent,
                objectId: params.classId,
                subObjectId: params.studentId,
            };

            debugEvent("Score report download student", event);
            methods.sendEvent(event);
        },

        downloadSummary(params = {}) {
            const event = {
                session: params.session || "",
                locationId: ids.location.class,
                eventId: ids.event.scoreReportDownloadSummary,
                objectId: params.classId,
            };

            debugEvent("Score report download summary", event);
            methods.sendEvent(event);
        },
    };
};
