import T2SHighlight from "@/base/project/t2s-highlight.js";


const loadHighlight = (services, params) => {
    const { actions, api } = services;
    const { trackGroupName, trackId } = params;

    return async (dispatch, getState) => {
        dispatch(actions.t2s.setHighlightLoading({
            trackGroupName,
            trackId,
        }));

        const state = getState();
        const audioFiles = state.text2speech?.[trackGroupName]?.[trackId]?.audioFiles || [];
        const highlight = [];

        let lastWordTime = 0;

        for (let i = 0; i < audioFiles.length; i += 1) {
            const url = audioFiles[i].json || "";

            if (url) {
                // eslint-disable-next-line no-await-in-loop
                const highlightJSON = await api.t2s.getHighlightJSON({ url });

                if (Array.isArray(highlightJSON)) {
                    for (let j = 0; j < highlightJSON.length; j += 1) {
                        const word = highlightJSON[j];

                        highlight.push({
                            time: word.time + lastWordTime,
                            value: word.value,
                        });
                    }

                    if (highlightJSON.length) {
                        const breakTime = 700;
                        lastWordTime += highlightJSON[highlightJSON.length - 1].time + breakTime;
                    }
                }
            }
        }

        const filteredHighlight = T2SHighlight.getFilteredHighlights(highlight);

        dispatch(actions.t2s.setHighlight({
            trackGroupName,
            trackId,
            highlight: filteredHighlight,
        }));

        return filteredHighlight;
    };
};

export default {
    loadHighlight,
};
