import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    progressByClassId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENTS_PROGRESS_LOADING_BY_CLASS_ID: {
            const { classId } = action.payload;

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        isLoading: true,
                        isRenewing: false,
                        error: "",
                        students: [],
                        dates: [],
                        autoAdjust: false,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_BY_CLASS_ID: {
            const {
                classId,
                reportId,
                students,
                dates,
                quizType,
                autoAdjust,
            } = action.payload;

            const report = state?.progressByClassId?.[classId] || {};

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        reportId,
                        isLoading: false,
                        isRenewing: false,
                        error: "",
                        students,
                        dates,
                        quizType,
                        autoAdjust,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_ERROR_BY_CLASS_ID: {
            const { classId, error } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state?.progressByClassId?.[classId] || {};

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        isLoading: false,
                        error,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID: {
            const { classId, frequency } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state?.progressByClassId?.[classId] || {};
            const reportDates = report.dates || {};

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        dates: {
                            ...reportDates,
                            reportFrequency: frequency,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_FREQUENCY_BY_CLASS_ID_LOADING: {
            const { classId, isLoading } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state.progressByClassId[classId] || {};

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        isFrequencyLoading: isLoading,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_QUIZ_TYPE_BY_CLASS_ID_LOADING: {
            const { classId, isLoading } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...state.progressByClassId[classId],
                        isQuizTypeLoading: isLoading,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_QUIZ_TYPE_BY_CLASS_ID: {
            const { classId, quizType } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state.progressByClassId[classId];

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        quizType,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_RENEWING_BY_CLASS_ID: {
            const { classId, isRenewing } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state?.progressByClassId?.[classId] || {};

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        isRenewing,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_AUTO_ADJUST_BY_CLASS_ID: {
            const { classId, autoAdjust } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state?.progressByClassId?.[classId] || {};

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        autoAdjust,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_STUDENT_IS_LOADING: {
            const { classId, studentId, isLoading } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state?.progressByClassId?.[classId] || {};
            const students = report?.students || [];

            const newStudents = students.map((student) => {
                if (student.id !== studentId) {
                    return student;
                }

                return {
                    ...student,
                    isLoading,
                };
            });

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        students: newStudents,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_PROGRESS_STUDENT_RECOMMENDATION_ACCEPTED: {
            const {
                classId,
                studentId,
                isRecommendationAccepted,
                summary,
            } = action.payload;

            if (!state.progressByClassId[classId]) {
                return state;
            }

            const report = state?.progressByClassId?.[classId] || {};
            const students = report?.students || [];

            const newStudents = students.map((student) => {
                if (student.id !== studentId) {
                    return student;
                }

                return {
                    ...student,
                    isRecommendationAccepted,
                    summary,
                };
            });

            return {
                ...state,
                progressByClassId: {
                    [classId]: {
                        ...report,
                        students: newStudents,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
