import React from "react";

import styles from "./styles.module.css";


const Overlay = (props) => {
    if (props.opacity === 0) {
        return null;
    }

    return (
        <div
            className={styles.overlay}
            style={{
                backgroundColor: props.color || "",
                opacity: `${props.opacity}%`,
            }}
        />
    );
};

Overlay.defaultProps = {
    color: "",
    opacity: 0,
};

export default Overlay;
