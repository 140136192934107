import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";

import dimensions from "@/base/lib/dimensions.js";
import document from "@/base/lib/document.js";

import Text from "@/base/components/text/index.js";

import Beacon from "./beacon.js";


const TUTORIAL_OFFSET = dimensions.convertRemToPixels(10);


const Tutorial = (props) => {
    const [isClosed, setIsClosed] = useState(false);

    useEffect(() => {
        return () => {
            props.onEnd();
        };
    }, []);

    const steps = [];

    for (let i = 0; i < props.steps.length; i += 1) {
        const step = props.steps[i];
        const elem = document.getElement(step.target);

        if (elem) {
            steps.push({
                ...step,
                target: elem,
                content: (
                    <Text>
                        {step.content}
                    </Text>
                ),
            });
        }
    }

    const tutorialStyles = {
        options: {
            zIndex: 1000,
        },
    };

    if (isClosed) {
        return null;
    }

    const scrollOffset = props.isMobile ? 0 : TUTORIAL_OFFSET;

    return (
        <Joyride
            run
            scrollToFirstStep
            showProgress
            showSkipButton
            continuous
            disableOverlayClose
            beaconComponent={Beacon}
            scrollOffset={scrollOffset}
            steps={steps}
            styles={tutorialStyles}
            callback={(tutorialState) => {
                if (tutorialState.action === "start"
                    && tutorialState.lifecycle === "ready") {
                    props.onStart(tutorialState.index);
                }

                if (tutorialState.action === "next"
                    && tutorialState.lifecycle === "ready") {
                    props.onStep(tutorialState.index);
                }

                if (tutorialState.action === "reset") {
                    props.onEnd(tutorialState.index);
                }

                if (tutorialState.action === "close") {
                    setIsClosed(true);
                    props.onEnd(tutorialState.index);
                }
            }}
        />
    );
};

Tutorial.defaultProps = {
    steps: [],
    onStart: () => { },
    onStep: () => { },
    onEnd: () => { },
    isMobile: false,
};

export default Tutorial;
