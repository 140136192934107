import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import User from "@/base/project/user.js";

import ErrorBoundarySmall from "@/base/components/error-boundary-small/index.js";
import Tutorial from "@/base/components/tutorial/index.js";

import actions from "@/base/store/actions.js";
import api from "@/app/api.js";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,

    isOptionsLoaded: state.user.isOptionsLoaded,
    options: state.user.options,

    user: state.user.user,
    isTeacherWelcomePopupOpen: state.user.isTeacherWelcomePopupOpen,

    tutorialsBySlug: state.tutorials.tutorialsBySlug,
    isClassesLoaded: state.teacher.isClassesLoaded,
    classes: state.teacher.classes,
});

const TutorialContainer = (props) => {
    const dispatch = useDispatch();

    const store = useSelector(storeSelector);

    const isMobile = store.dimensions.width <= 900;

    const onEnd = () => {
        dispatch(actions.user.setUserOption({
            key: props.name,
            value: true,
        }));

        if (!store.session) {
            return;
        }

        const tutorial = store.tutorialsBySlug[props.name] || {};

        if (tutorial.isAlwaysVisible) {
            return;
        }

        api.user.setOption({
            session: store.session,
            key: props.name,
            value: true,
        });
    };

    // TODO: enable later(after API optimizations)
    // useEffect(() => {
    //     if (store.tutorialsBySlug[props.name]) {
    //         return;
    //     }

    //     api.tutorials.getTutorial({
    //         session: store.session,
    //         slug: props.name,
    //     }).then((res) => {
    //         if (res.ok && res.tutorial) {
    //             dispatch(actions.tutorials.setTutorialBySlug({
    //                 slug: props.name,
    //                 tutorial: res.tutorial || {},
    //             }));
    //         }
    //     });
    // }, []);

    useEffect(() => {
        if (props.autoSkip) {
            onEnd();
        }
    }, [props.autoSkip]);

    const getSteps = (tut) => {
        const steps = [];

        tut.steps.forEach((step, i) => {
            let target = step.desktopSelector;

            if (isMobile) {
                target = step.mobileSelector;
            }

            if (target) {
                let placement = "auto";

                if (isMobile && !step.isMobileStepAutoPlacement) {
                    placement = "bottom-end";
                }

                steps.push({
                    target,
                    content: step.message,
                    disableBeacon: i === 0,
                    placement,
                });
            }
        });

        return steps;
    };

    if (!props.name) {
        return null;
    }

    const tutorial = store.tutorialsBySlug[props.name];

    if (!store.isOptionsLoaded || !tutorial || !tutorial.steps) {
        return null;
    }

    const options = store.options || {};
    const isFinished = options[props.name];

    if (isFinished && !tutorial.isAlwaysVisible) {
        return null;
    }

    const steps = getSteps(tutorial);

    if (props.disabled) {
        return null;
    }

    if (User.hasRoleTeacher(store.user)) {
        if (store.isTeacherWelcomePopupOpen) {
            return null;
        }

        if (!store.isClassesLoaded) {
            return null;
        }

        if (store.classes.length === 0) {
            return null;
        }
    }

    return (
        <ErrorBoundarySmall>
            <Tutorial
                key={props.name}
                steps={steps}
                onEnd={onEnd}
                isMobile={isMobile}
            />
        </ErrorBoundarySmall>
    );
};

TutorialContainer.defaultProps = {
    disabled: false,
    autoSkip: false,
    name: "",
};

export default TutorialContainer;
