import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";


const TilesEqual = (props) => {
    const blocksMeasurerRef = useRef(null);

    const [sortedIndexes, setSortedIndexes] = useState(() => ({
        col1: [],
        col2: [],
    }));

    const childrenCount = props.children?.length || 0;

    /* --- */

    useEffect(() => {
        if (props.isMobile
            || childrenCount === 0
            || !blocksMeasurerRef.current) {
            return;
        }

        const columns = blocksMeasurerRef.current.children || null;

        const heightsCol1 = Array.from(columns?.[0]?.children || []).map(c => c.offsetHeight);
        const heightsCol2 = Array.from(columns?.[1]?.children || []).map(c => c.offsetHeight);

        let col1Height = 0;
        let col2Height = 0;
        let indexCol1 = 0;
        let indexCol2 = 0;

        const col1 = [];
        const col2 = [];

        for (let i = 0; i < heightsCol1.length + heightsCol2.length; i += 1) {
            if (i % 2 === 0) {
                const col1ChildHeight = heightsCol1[indexCol1];

                if (col1Height >= col2Height && i > 0) {
                    col2.push(i);
                    col2Height += col1ChildHeight;
                } else {
                    col1.push(i);
                    col1Height += col1ChildHeight;
                }

                indexCol1 += 1;
            } else {
                const col2ChildHeight = heightsCol2[indexCol2];

                if (col2Height >= col1Height) {
                    col1.push(i);
                    col1Height += col2ChildHeight;
                } else {
                    col2.push(i);
                    col2Height += col2ChildHeight;
                }

                indexCol2 += 1;
            }
        }

        setSortedIndexes({
            col1,
            col2,
        });
    }, [
        childrenCount,
        props.isMobile,
    ]);

    /* --- */

    const renderTiles = () => {
        const col1 = sortedIndexes.col1.map((i) => props.children[i]);
        const col2 = sortedIndexes.col2.map((i) => props.children[i]);

        return (
            <>
                <div className={styles.tilesDesktopCol}>
                    {col1}
                </div>
                <div className={styles.tilesDesktopCol}>
                    {col2}
                </div>
            </>
        );
    };

    /* --- */

    const renderBlocksToMeasure = () => {
        const col1 = [];
        const col2 = [];

        for (let i = 0; i < childrenCount; i += 1) {
            const child = props.children[i];

            if (i % 2 === 0) {
                col1.push(child);
            } else {
                col2.push(child);
            }
        }

        return (
            <div
                ref={blocksMeasurerRef}
                className={styles.blocksMeasurer}
            >
                <div className={styles.tilesDesktopCol}>
                    {col1}
                </div>
                <div className={styles.tilesDesktopCol}>
                    {col2}
                </div>
            </div>
        );
    };

    /* --- */

    if (!props.children) {
        return null;
    }

    if (props.isMobile) {
        return (
            <div className={styles.tilesMobile}>
                {props.children}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {renderBlocksToMeasure()}

            <div className={styles.tilesDesktop}>
                {renderTiles()}
            </div>
        </div>
    );
};

TilesEqual.defaultProps = {
    children: null,
    isMobile: false,
};

export default TilesEqual;
