import React from "react";

import urls from "@/base/lib/urls.js";

import Image from "@/base/components/image/index.js";
import DivButton from "@/base/components/div-button/index.js";

import SectionStudent from "@/base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const SectionSponsor = (props) => {
    const onImageClick = () => {
        urls.openUrl(props.link);
    };

    /* --- */

    const renderImage = () => {
        return (
            <Image
                className={styles.sponsorImg}
                src={props.imgSrc}
                title="Sponsor image"
            />
        );
    };

    const renderClickableImage = () => {
        return (
            <DivButton
                className={styles.button}
                onClick={onImageClick}
            >
                {renderImage()}
            </DivButton>
        );
    };

    const renderContent = () => {
        let content = null;

        if (props.link) {
            content = renderClickableImage();
        } else {
            content = renderImage();
        }

        return (
            <div className={styles.content}>
                {content}
            </div>
        );
    };

    /* --- */

    if (!props.imgSrc) {
        return null;
    }

    return (
        <SectionStudent
            dataComment="section-sponsor"
            titleBottomBorder={false}
        >
            {renderContent()}
        </SectionStudent>
    );
};

SectionSponsor.defaultProps = {
    link: "",
    imgSrc: "",
};

export default SectionSponsor;
