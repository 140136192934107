const isCountryUS = (country) => {
    if (country === "US") {
        return true;
    }

    return false;
};

export default {
    isCountryUS,
};
