import * as types from "./types.js";


const initialState = {
    isStandardsLoaded: false,
    standards: [],

    isCategoriesLoaded: false,
    categories: {},

    contentTags: {
        data: [],
        error: "",
        isLoading: false,
        isLoaded: false,
    },

    searchEmpty: {
        isLoading: false,
        data: {},
    },
    searchByKeyword: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_SEARCH_STANDARDS: {
            return {
                ...state,
                isStandardsLoaded: true,
                standards: action.payload.standards || [],
            };
        }

        /* --- */

        case types.SET_SEARCH_CATEGORIES: {
            return {
                ...state,
                isCategoriesLoaded: true,
                categories: action.payload.categories || {},
            };
        }

        /* --- */

        case types.SET_SEARCH_CONTENT_TAGS_LOADING: {
            return {
                ...state,
                contentTags: {
                    data: [],
                    error: "",
                    isLoading: true,
                    isLoaded: false,
                },
            };
        }

        case types.SET_SEARCH_CONTENT_TAGS: {
            const { data, error } = action.payload;

            return {
                ...state,
                contentTags: {
                    data,
                    error,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        /* --- */

        case types.SET_SEARCH_EMPTY_LOADING: {
            return {
                ...state,
                searchEmpty: {
                    ...state.searchEmpty.data,
                    isLoading: true,
                    data: {},
                },
            };
        }

        case types.SET_SEARCH_EMPTY_DATA: {
            const { data } = action.payload;

            return {
                ...state,
                searchEmpty: {
                    ...state.searchEmpty.data,
                    isLoading: false,
                    data,
                },
            };
        }

        /* --- */

        case types.SET_SEARCH_BY_KEYWORD_LOADING: {
            const { keyword } = action.payload;

            const { searchByKeyword } = state;

            return {
                ...state,
                searchByKeyword: {
                    ...searchByKeyword,
                    [keyword]: {
                        isLoading: true,
                        data: {},
                    },
                },
            };
        }

        case types.SET_SEARCH_BY_KEYWORD: {
            const { keyword, data } = action.payload;

            const { searchByKeyword } = state;

            return {
                ...state,
                searchByKeyword: {
                    ...searchByKeyword,
                    [keyword]: {
                        ...searchByKeyword[keyword],
                        isLoading: false,
                        data,
                    },
                },
            };
        }

        case types.SET_SEARCH_STORY_RATING: {
            const { storyId, search, rating } = action.payload;

            const searchByKeyword = { ...state.searchByKeyword };

            if (search) {
                const prevByKeyword = searchByKeyword?.[search] || {};
                const prevData = prevByKeyword?.data || {};

                searchByKeyword[search] = {
                    ...prevByKeyword,
                    data: {
                        ...prevData,
                        results: (prevData?.results || []).map((story) => {
                            if (story?.ID === storyId) {
                                return {
                                    ...story,
                                    rating,
                                };
                            }

                            return story;
                        }),
                    },
                };

                return {
                    ...state,
                    searchByKeyword,
                };
            }

            const prevData = { ...state.searchEmpty?.data };

            const searchEmpty = {
                ...state.searchEmpty,
                data: {
                    ...prevData,
                    results: (prevData?.results || []).map((story) => {
                        if (story?.ID === storyId) {
                            return {
                                ...story,
                                rating,
                            };
                        }

                        return story;
                    }),
                },
            };

            return {
                ...state,
                searchEmpty,
            };
        }

        case types.SET_SEARCH_STORY_EXTRA_JUICE_RATING: {
            const {
                extraJuiceId,
                rating,
            } = action.payload;

            const searchByKeyword = {
                ...state.searchByKeyword,
            };

            Object.keys(searchByKeyword).forEach((searchValue) => {
                const results = searchByKeyword[searchValue]?.data?.results;

                if (!results) {
                    return;
                }

                const newResults = results.map((res) => {
                    if (res.type === "extra_juice"
                        && res.ID === extraJuiceId) {
                        return {
                            ...res,
                            rating,
                        };
                    }

                    const eJs = Array.isArray(res.extraJuices)
                        ? res.extraJuices
                        : [];

                    let isChanged = false;

                    const newExtraJuice = eJs.map((eJuice) => {
                        if (eJuice.id === extraJuiceId) {
                            isChanged = true;
                            return {
                                ...eJuice,
                                rating,
                            };
                        }

                        return eJuice;
                    });

                    if (!isChanged) {
                        return res;
                    }

                    return {
                        ...res,
                        extraJuices: newExtraJuice,
                    };
                });

                searchByKeyword[searchValue].data.results = newResults;
            });

            return {
                ...state,
                searchByKeyword,
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
