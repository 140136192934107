import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconInbox from "@/base/icons/inbox/index.js";

import NotificationBadge from "@/base/components/notification-badge/index.js";

import styles from "./styles.module.css";


const ButtonNotification = (props) => {
    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerActive]: props.isActive,
    });

    return (
        <div
            className={containerClassName}
            onClick={props.onClick}
            onKeyDown={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <NotificationBadge
                className={styles.badge}
                isVisible={props.isBadgeVisible}
            />
            <IconInbox
                className={styles.badgeIcon}
                isSky
            />
        </div>
    );
};

ButtonNotification.defaultProps = {
    onClick: () => { },
    isBadgeVisible: false,
    isActive: false,
};

export default ButtonNotification;
