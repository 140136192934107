export default (apiURL, methods) => {
    return {
        setDefaultLanguage(params = {}) {
            const url = `${apiURL}/translation/languages/set-default`;

            return methods.get3(url, {
                session_id: params.session || "",
                code: params.code || "",
            });
        },

        getLanguages(params = {}) {
            const url = `${apiURL}/translation/languages`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        getStoryById(params = {}) {
            // NOTE: action=translation_story_by_id
            const url = `${apiURL}/translation/stories/${params.storyId || ""}`;

            return methods.get3(url, {
                session_id: params.session || "",
                code: params.code || "",
            });
        },

        getVocabWordById(params = {}) {
            // NOTE: action=translation_vocab_word_by_id
            const url = `${apiURL}/translation/vocabulary/${params.wordId}`;

            return methods.get3(url, {
                session_id: params.session || "",
                code: params.code || "",
            });
        },
    };
};
