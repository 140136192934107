import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupConfirmButtons = (props) => {
    const buttonsClassName = classNames({
        [styles.buttons]: true,
        [styles.buttonsVertical]: props.vertical,
    });
    return (
        <div className={buttonsClassName}>
            {props.children}
        </div>
    );
};

PopupConfirmButtons.defaultProps = {
    vertical: false,
    children: null,
};

export default PopupConfirmButtons;
