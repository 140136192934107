import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import IconQuestion from "@/base/icons/question/index.js";

import TooltipInfo from "@/base/components/tooltip-info/index.js";
import TooltipPosition from "@/base/components/tooltip-position/index.js";
import TooltipToggler from "@/base/components/tooltip-toggler/index.js";
import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const TooltipInfoWithIcon = (props) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    /* --- */

    const onToggleTooltip = (evt) => {
        evt.stopPropagation();
    };

    const onHover = (isHovering) => {
        setTooltipVisible(isHovering);
        props.onHover(isHovering);
    };

    /* --- */

    const renderTooltip = () => {
        if (!props.tooltip) {
            return null;
        }

        const positionX = props.tooltip.positionX || "";
        const positionY = props.tooltip.positionY || "";

        return (
            <TooltipPosition
                positionX={positionX}
                positionY={positionY}
            >
                <TooltipInfo
                    isRight={positionX === "right"}
                    isLeft={positionX === "left"}
                    isTextCentered
                >
                    {props.tooltip.message || ""}
                </TooltipInfo>
            </TooltipPosition>
        );
    };

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerSkySelected]: props.isSky && isTooltipVisible,
    });

    return (
        <DivButton
            onClick={onToggleTooltip}
        >
            <TooltipToggler
                tooltip={renderTooltip()}
                onHover={onHover}
            >
                <div className={containerClassName}>
                    <IconQuestion
                        className={styles.icon}
                        isSky={props.isSky}
                        isGrey={!props.isSky}
                    />
                </div>
            </TooltipToggler>
        </DivButton>
    );
};

TooltipInfoWithIcon.defaultProps = {
    tooltip: null,
    onHover: () => { },
    isSky: false,
};

export default TooltipInfoWithIcon;
