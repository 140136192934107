import React from "react";

import ButtonFlat from "@/base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";
import Text from "@/base/components/text/index.js";

import styles from "./styles.module.css";


const PopupConfirmClassesLimitExceeded = (props) => {
    const renderContent = () => {
        const studentToRemove = props.totalStudents - props.maxStudents;
        const studentsText = studentToRemove === 1
            ? "student"
            : "students";

        const messages = [
            `You have a total of <strong>${props.totalStudents} students</strong> in your account.`,
            `You can continue to use the product, but your student count exceeds the <strong>${props.maxStudents} limits</strong> of your plan.`,
            `Either you can remove <strong>${studentToRemove} ${studentsText}</strong> from your classes, or contact customer support if you want to request additional pricing options.`,
        ];

        return (
            <PopupConfirmContent>
                <div className={styles.content}>
                    {messages.map((msg) => (
                        <Text>{msg}</Text>
                    ))}
                </div>
            </PopupConfirmContent>
        );
    };

    return (
        <PopupConfirm>
            {renderContent()}
            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={props.onClose}
                >
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmClassesLimitExceeded.defaultProps = {
    totalStudents: 0,
    maxStudents: 0,
    onClose: () => { },
};

export default PopupConfirmClassesLimitExceeded;
