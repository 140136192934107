import * as types from "./types.js";


export const setLocation = (payload) => ({
    type: types.SET_LOCATION,
    payload,
});

export const setDocTitle = (title) => ({
    type: types.SET_DOC_TITLE,
    payload: {
        title,
    },
});

export default {};
