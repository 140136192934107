import text from "@/base/text/index.js";


const loadQuizWeeksResults = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const amount = params.amount || 0;

        if (!amount) {
            return;
        }

        const isLoading = state.student.quizResults.weeks?.[amount]?.isLoading;

        if (isLoading) {
            return;
        }

        dispatch(actions.student.setStudentQuizResultsLoading({
            type: "weeks",
            amount,
        }));

        const res = await api.students.getStudentQuizChartByRange({
            session: state.user.session,
            numberOfWeeks: amount,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.data || {};
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.student.setStudentQuizResults({
            type: "weeks",
            amount,
            data,
            error,
        }));
    };
};

const loadQuizMonthsResults = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const amount = params.amount || 0;

        if (!amount) {
            return;
        }

        const isLoading = state.student.quizResults.months?.[amount]?.isLoading;

        if (isLoading) {
            return;
        }

        dispatch(actions.student.setStudentQuizResultsLoading({
            type: "months",
            amount,
        }));

        const res = await api.students.getStudentQuizChartByRange({
            session: state.user.session,
            numberOfMonths: amount,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.data || {};
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.student.setStudentQuizResults({
            type: "months",
            amount,
            data,
            error,
        }));
    };
};

export default {
    loadQuizWeeksResults,
    loadQuizMonthsResults,
};
