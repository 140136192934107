import Translation from "@/base/project/translation.js";


const storySelector = (store, storyId) => {
    const uiState = store.translation.ui.stories[storyId];

    const languages = Translation.getLanguagesOptions(store.translation.languages);
    const language = uiState?.languageCode
        || store.user?.translationLanguageCode
        || "";

    const lang = Translation.getLanguageByCode(store.translation.languages, language);

    const languageDirection = lang
        ? lang?.direction || ""
        : "";

    const content = store.translation.storiesById?.[storyId]?.[language] || null;

    return {
        languages,
        language,
        direction: languageDirection,
        isChecked: uiState?.isChecked || false,
        isLoading: content?.isLoading || false,
        translation: content?.translation || null,
        error: content?.error || null,
    };
};

export default {
    storySelector,
};
