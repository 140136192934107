const getProviderClassById = (providers, classId) => {
    for (let i = 0; i < providers.length; i += 1) {
        const p = providers[i];
        const schools = p.schools || [];

        for (let j = 0; j < schools.length; j += 1) {
            const school = schools[j];
            const classes = school.classes || [];

            for (let k = 0; k < classes.length; k += 1) {
                const schoolClass = classes[k];

                if (schoolClass.id === classId) {
                    return {
                        providerId: p.providerId,
                        schooldId: school.id,
                        classId: schoolClass.id,
                    };
                }
            }
        }
    }

    return null;
};

export default {
    getProviderClassById,
};
