export default (apiURL, methods) => {
    return {
        getDistricts(params = {}) {
            // NOTE: action=admin_get_all_districts
            const url = `${apiURL}/admin/geo/districts`;

            return methods.get(url, {
                session_id: params.session,
                s: params.search,
                page: params.page,
            });
        },

        getDistrictById(params = {}) {
            // NOTE: action=admin_get_district_by_id
            const url = `${apiURL}/admin/geo/districts/${params.districtId}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getSchoolsByDistrict(params = {}) {
            // NOTE: action=admin_get_schools_by_district_id
            const url = `${apiURL}/admin/geo/districts/${params.districtId}/schools`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        deleteDistrict(params = {}) {
            // NOTE: action=admin_district_delete
            const url = `${apiURL}/admin/geo/districts/${params.districtId}/delete`;

            return methods.post(url, {
                session_id: params.session,
                district_id: params.districtId,
            });
        },

        editDistrict(params = {}) {
            // NOTE: action=admin_district_edit
            const url = `${apiURL}/admin/geo/districts/${params.districtId}/edit`;

            return methods.post(url, {
                session_id: params.session,
                district_id: params.districtId,
                name: params.districtName,
                country: params.country,
                subdivision: params.subdivision,
            });
        },

        addDistrict(params = {}) {
            // NOTE: action=admin_district_add
            const url = `${apiURL}/admin/geo/districts/add`;

            return methods.post(url, {
                session_id: params.session,
                name: params.districtName,
                country: params.country,
                subdivision: params.subdivision,
                check_duplicate: params.checkDuplicate,
            });
        },

        addSchool(params = {}) {
            // NOTE: action=admin_school_add
            const url = `${apiURL}/admin/geo/districts/${params.districtId}/schools/add`;

            return methods.post(url, {
                district_id: params.districtId,
                session_id: params.session,
                school_name: params.schoolName,
            });
        },

        addDepartment(params = {}) {
            // NOTE: action=admin_department_add
            const url = `${apiURL}/admin/geo/districts/${params.districtId}/schools/${params.schoolId}/departments/add`;

            return methods.post(url, {
                session_id: params.session,
                district_id: params.districtId,
                school_id: params.schoolId,
                department_name: params.departmentName,
            });
        },

        addClass(params = {}) {
            // NOTE: action=admin_class_add
            const url = `${apiURL}/admin/geo/districts/${params.districtId}/schools/${params.schoolId}/departments/${params.departmentId}/classes/add`;

            return methods.post(url, {
                session_id: params.session,
                district_id: params.districtId,
                school_id: params.schoolId,
                department_id: params.departmentId,
                class_name: params.className,
                grades: params.grades,
                end_date: params.endDate,
            });
        },

        moveClass(params = {}) {
            // NOTE: action=admin_move_class_by_id
            const url = `${apiURL}/admin/classes/${params.classId}/move`;

            return methods.post(url, {
                session_id: params.session || "",
                district_id: params.districtId || "",
                school_id: params.schoolId || "",
                department_id: params.departmentId || "",
                class_id: params.classId || "",
            });
        },

        moveDepartment(params = {}) {
            // NOTE: action=admin_move_department_by_id
            const url = `${apiURL}/admin/departments/${params.departmentId}/move`;

            return methods.post(url, {
                session_id: params.session || "",
                district_id: params.districtId || "",
                school_id: params.schoolId || "",
                department_id: params.departmentId || "",
            });
        },

        moveSchool(params = {}) {
            // NOTE: action=admin_move_school_by_id
            const url = `${apiURL}/admin/schools/${params.schoolId}/move`;

            return methods.post(url, {
                session_id: params.session || "",
                district_id: params.districtId || "",
                school_id: params.schoolId || "",
            });
        },
    };
};
