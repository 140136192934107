import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconFolders = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M2 18L0 6H18L16 18H2ZM7 12H11C11.2833 12 11.5208 11.9042 11.7125 11.7125C11.9042 11.5208 12 11.2833 12 11C12 10.7167 11.9042 10.4792 11.7125 10.2875C11.5208 10.0958 11.2833 10 11 10H7C6.71667 10 6.47917 10.0958 6.2875 10.2875C6.09583 10.4792 6 10.7167 6 11C6 11.2833 6.09583 11.5208 6.2875 11.7125C6.47917 11.9042 6.71667 12 7 12ZM3 5C2.71667 5 2.47917 4.90417 2.2875 4.7125C2.09583 4.52083 2 4.28333 2 4C2 3.71667 2.09583 3.47917 2.2875 3.2875C2.47917 3.09583 2.71667 3 3 3H15C15.2833 3 15.5208 3.09583 15.7125 3.2875C15.9042 3.47917 16 3.71667 16 4C16 4.28333 15.9042 4.52083 15.7125 4.7125C15.5208 4.90417 15.2833 5 15 5H3ZM5 2C4.71667 2 4.47917 1.90417 4.2875 1.7125C4.09583 1.52083 4 1.28333 4 1C4 0.716667 4.09583 0.479167 4.2875 0.2875C4.47917 0.0958333 4.71667 0 5 0H13C13.2833 0 13.5208 0.0958333 13.7125 0.2875C13.9042 0.479167 14 0.716667 14 1C14 1.28333 13.9042 1.52083 13.7125 1.7125C13.5208 1.90417 13.2833 2 13 2H5Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconFolders;
