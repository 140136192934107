export default (apiURL, methods) => {
    return {
        rateExtraJuice(params = {}) {
            // NOTE: action=set_user_story_rating
            const url = `${apiURL}/rate/like`;

            return methods.post(url, {
                session_id: params.session,
                content_id: params.extraJuiceId,
                rating: params.rating,
            });
        },

        rateJuiceStory(params = {}) {
            // NOTE: action=set_user_rating_by_type
            const url = `${apiURL}/rate/like/by-type`;

            return methods.post(url, {
                session_id: params.session,
                type: "story",
                juice_id: params.juiceId,
                content_id: params.storyId,
                rating: params.rating,
            });
        },

        rateJuiceExtraJuice(params = {}) {
            // NOTE: action=set_user_rating_by_type
            const url = `${apiURL}/rate/like/by-type`;

            return methods.post(url, {
                session_id: params.session,
                type: "storyExtraJuice",
                juice_id: params.juiceId,
                content_id: params.extraJuiceId,
                rating: params.rating,
            });
        },

        rateAssignmentStory(params = {}) {
            // NOTE: action=set_user_story_rating
            const url = `${apiURL}/rate/like`;

            return methods.post(url, {
                session_id: params.session,
                assignment_id: params.assignmentId,
                content_id: params.storyId,
                rating: params.rating,
            });
        },

        rateAssignmentExtraJuice(params = {}) {
            // NOTE: action=set_user_story_rating
            const url = `${apiURL}/rate/like`;

            return methods.post(url, {
                session_id: params.session,
                assignment_id: params.assignmentId,
                content_id: params.extraJuiceId,
                rating: params.rating,
            });
        },

        rateSearchStory(params = {}) {
            // NOTE: action=set_user_story_rating
            const url = `${apiURL}/rate/like`;

            return methods.post(url, {
                session_id: params.session,
                juice_id: params.juiceId,
                content_id: params.storyId,
                rating: params.rating,
            });
        },
    };
};
