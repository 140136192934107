import * as types from "./types.js";


export const setTemplateLoadingBySlug = (payload) => ({
    type: types.SET_TEMPLATE_LOADING_BY_SLUG,
    payload: {
        slug: payload.slug,
    },
});

export const setTemplateBySlug = (payload) => ({
    type: types.SET_TEMPLATE_BY_SLUG,
    payload: {
        slug: payload.slug,
        template: payload.template,
    },
});

export const setImportStudentsTemplate = (link) => ({
    type: types.SET_TEMPLATE_IMPORT_STUDENTS,
    payload: { link },
});

export default {};
