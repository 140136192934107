import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconTeacherEdition = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16.666697"
                    height="15"
                    viewBox="0 0 16.666697 15"
                    fill="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >

                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M 16.666696,0 15.274996,1.39167 13.891696,0 12.499996,1.39167 11.108296,0 9.724996,1.39167 8.333336,0 6.941666,1.39167 5.558336,0 4.166666,1.39167 2.774996,0 1.391666,1.39167 0,0 V 13.3333 C 0,14.25 0.75,15 1.666666,15 h 13.33333 c 0.9167,0 1.6667,-0.75 1.6667,-1.6667 z m -9.1667,13.3333 H 1.666666 V 8.33333 h 5.83333 z m 7.5,0 h -5.83333 v -1.6666 h 5.83333 z m 0,-3.3333 H 9.166666 V 8.33333 h 5.83333 z m 0,-3.33333 H 1.666666 v -2.5 h 13.33333 z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconTeacherEdition;
