import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconPollUnanswered = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M14 10H3V12H14V10Z"
                    />
                    <path
                        fill="currentColor"
                        d="M14 6H3V8H14V6Z"
                    />
                    <path
                        fill="currentColor"
                        d="M10 14H3V16H10V14Z"
                    />
                    <path
                        d="M17 19C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17C16.4477 17 16 17.4477 16 18C16 18.5523 16.4477 19 17 19Z"
                        fill="#ef4444"
                    />
                    <path
                        d="M16 11H18V16H16V11Z"
                        fill="#ef4444"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPollUnanswered;
