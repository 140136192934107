import React from "react";

import classNames from "@/base/lib/class-names.js";

import LoaderSmall from "@/base/components/loader-small/index.js";
import ClassroomShareButtonWithLabel from "@/base/components/google-classroom-share-button-with-label/index.js";

import styles from "./styles.module.css";


const MenuOption = (props) => {
    const { googleClassroom } = props;

    const onClick = (evt) => {
        if (props.disabled || props.isLoading) {
            return;
        }

        evt.stopPropagation();
        props.onClick();
    };

    const renderContent = () => {
        if (googleClassroom) {
            return (
                <ClassroomShareButtonWithLabel
                    shareUrl={googleClassroom.shareUrl}
                    title={googleClassroom.title}
                    path={googleClassroom.path}
                    onClick={googleClassroom.onClick}
                    isLoading={props.isLoading}
                />
            );
        }

        let icon = null;
        let loader = null;

        if (props.icon) {
            icon = props.icon;
        }

        if (props.isLoading) {
            loader = (
                <LoaderSmall />
            );
        }

        return (
            <div className={styles.menuOptionContent}>
                {icon}
                {props.name}
                {loader}
            </div>
        );
    };

    const menuOptionClassName = classNames({
        [styles.menuOption]: true,
        [styles.menuOptionBlue]: props.blueTheme,
        [styles.menuOptionDisabled]: props.disabled || props.isLoading,
        [styles.classroomShareButton]: googleClassroom,
    });

    return (
        <div
            className={menuOptionClassName}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
            role="button"
        >
            {renderContent()}
        </div>
    );
};

MenuOption.defaultProps = {
    googleClassroom: null,
    icon: null,
    name: "",

    onClick: () => { },

    blueTheme: false,
    isLoading: false,
    disabled: false,
};

export default MenuOption;
