import { useState, useEffect } from "react";


const getWidth = (elem) => {
    return elem ? elem.clientWidth : 0;
};

const getHeight = (elem) => {
    return elem ? elem.clientHeight : 0;
};

const useElemDimensions = (elem) => {
    const [dimensions, setDimensions] = useState({
        width: getWidth(elem),
        height: getHeight(elem),
    });

    useEffect(() => {
        let timeoutId = null;

        const onChange = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setDimensions({
                    width: getWidth(elem),
                    height: getHeight(elem),
                });
            }, 150);
        };

        window.addEventListener("resize", onChange);

        onChange();

        return () => {
            window.removeEventListener("resize", onChange);
        };
    }, [elem]);

    return dimensions;
};

export default useElemDimensions;
