import { useState } from "react";


const getInitialState = () => ({
    studentId: -1,
    error: "",
    student: {},
    isLoaded: false,
    isOpen: false,
});

const useStudentInfoPopup = () => {
    const [state, setState] = useState(getInitialState());

    const open = (studentId) => {
        setState((prev) => ({
            ...prev,
            error: "",
            studentId,
            student: {},
            isLoaded: false,
            isOpen: true,
        }));
    };

    const close = () => {
        setState(getInitialState());
    };

    const setLoading = () => {
        setState((prev) => ({
            ...prev,
            error: "",
            student: {},
            isLoaded: false,
        }));
    };

    const setStudent = (student) => {
        setState((prev) => ({
            ...prev,
            error: "",
            student: student || {},
            isLoaded: true,
        }));
    };

    const setStudentError = (error) => {
        setState((prev) => ({
            ...prev,
            error,
            student: {},
            isLoaded: true,
        }));
    };

    return {
        state,
        open,
        close,
        setLoading,
        setStudent,
        setStudentError,
    };
};

export default useStudentInfoPopup;
