// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bNoGQjsLjBmvw28qdQOZ {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 2rem;\n\n    margin: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-achievements/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;;IAEd,YAAY;AAChB","sourcesContent":[".content {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 2rem;\n\n    margin: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "bNoGQjsLjBmvw28qdQOZ"
};
export default ___CSS_LOADER_EXPORT___;
