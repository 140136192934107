import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    badEmail: {},
    isVisibleRestoreClasses: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.OPEN_UI_POPUP_BAD_EMAIL: {
            return {
                ...state,
                badEmail: {
                    isVisible: true,
                },
            };
        }

        /* --- */

        case types.OPEN_UI_POPUP_RESTORE_CLASSES: {
            return {
                ...state,
                isVisibleRestoreClasses: true,
            };
        }

        /* --- */

        case types.CLEAR_UI_POPUPS: {
            return { ...initialState };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
