export default (apiURL, methods) => {
    return {
        all(params = {}) {
            // NOTE: action=search2
            // /wp-content/themes/thejuice-theme/search2.php
            const url = `${apiURL}/juice-stories/search`;

            return methods.get3(url, {
                session_id: params.session,
                s: params.keyword,
                page: params.page,
                grades: params.grades,
                order_by: params.orderBy,
                categories: params.categories,
                content_tags: params.contentTags || "",
                standards: params.standards,
                type: params.type,
                show_excluded_items: params.showExcludedItems ? 1 : 0,
            });
        },

        categories(params = {}) {
            const url = `${apiURL}/juice-stories/search2`;

            return methods.get3(url, {
                session_id: params.session || "",
                action: "getCategories",
            });
        },

        getStandards(params = {}) {
            const url = `${apiURL}/juice-stories/search/standards`;

            return methods.get3(url, {
                session_id: params.session || "",
                state: params.state,
            });
        },

        getContentTags(params = {}) {
            // NOTE: action=content_tags_get_all
            const url = `${apiURL}/tags/content`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        getStoryUserInfo(params = {}) {
            // NOTE: action=get_search_story_user_info
            const url = `${apiURL}/juice-stories/search/${params.storyId}/user-info`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },
    };
};
