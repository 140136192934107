import React from "react";

import classNames from "@/base/lib/class-names.js";

import DivButton from "@/base/components/div-button/index.js";
import LoaderSmall from "@/base/components/loader-small/index.js";

import styles from "./styles.module.css";


const ButtonIcon = (props) => {
    const isDisabled = props.isDisabled || props.isLoading;

    const onClick = (evt) => {
        if (isDisabled) {
            return;
        }

        props.onClick(evt);
    };

    /* --- */

    const renderIconOrLoader = () => {
        if (props.isLoading) {
            return (
                <LoaderSmall />
            );
        }

        if (props.isPressed) {
            return (
                <div className={styles.iconPressed}>
                    {props.iconPressed}
                </div>
            );
        }

        return props.icon;
    };

    /* --- */

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonDisabled]: isDisabled,
    });

    return (
        <DivButton
            className={buttonClassName}
            onClick={onClick}
        >
            {renderIconOrLoader()}
        </DivButton>
    );
};

ButtonIcon.defaultProps = {
    icon: null,
    iconPressed: null,
    onClick: () => { },
    isPressed: false,
    isDisabled: false,
    isLoading: false,
};

export default ButtonIcon;
