import React from "react";

import classNames from "@/base/lib/class-names.js";
import symbols from "@/base/lib/symbols.js";

import Text from "@/base/components/text/index.js";

import styles from "./styles.module.css";


const Radio = (props) => {
    const fieldClassName = classNames({
        [styles.field]: true,
        [styles.fieldDarkPink]: props.isDarkPink,
    });

    return (
        <div className={fieldClassName}>
            <label
                htmlFor={`${props.id}-${props.name}-${props.value}`}
                className={styles.fieldLabel}
            >
                <Text>
                    {props.label || symbols.nbsp}
                </Text>

                <input
                    id={`${props.id}-${props.name}-${props.value}`}
                    name={props.name}
                    type="radio"
                    value={props.value}
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    className={styles.fieldInput}
                />

                <div className={styles.fieldRadio}>
                    <div className={styles.fieldRadioDot} />
                </div>
            </label>
        </div>
    );
};

Radio.defaultProps = {
    id: "",
    name: "",
    label: "",
    value: "",
    checked: false,
    disabled: false,
    isDarkPink: false,
    onChange: () => { },
    onBlur: () => { },
};

export default Radio;
