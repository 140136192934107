// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CM7pDl4lX4t3BQCy4YVr {\n    width: 100%;\n\n    display: grid;\n    grid-template-rows: 4rem auto;\n    justify-content: center;\n    justify-items: center;\n    align-items: center;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.Pbm7GrDSklb95X27YNso {\n    font-size: 1rem;\n    font-weight: 500;\n\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./base/components/icon-with-name/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX,aAAa;IACb,6BAA6B;IAC7B,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;;IAEnB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;;IAEhB,kBAAkB;AACtB","sourcesContent":[".container {\n    width: 100%;\n\n    display: grid;\n    grid-template-rows: 4rem auto;\n    justify-content: center;\n    justify-items: center;\n    align-items: center;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.iconName {\n    font-size: 1rem;\n    font-weight: 500;\n\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CM7pDl4lX4t3BQCy4YVr",
	"iconName": "Pbm7GrDSklb95X27YNso"
};
export default ___CSS_LOADER_EXPORT___;
