import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconTranslate = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M9.99497 19.1545C8.42552 19.1545 6.98455 18.7939 5.67205 18.0727C4.35955 17.3515 3.29388 16.3882 2.47503 15.1825V16.6545H0.825573V12.4253H5.05474V14.0747H3.73726C4.40393 15.1104 5.28173 15.9402 6.37066 16.5641C7.45957 17.188 8.66768 17.5 9.99497 17.5C11.0683 17.5 12.0721 17.2915 13.0063 16.8746C13.9405 16.4576 14.753 15.8862 15.4438 15.1604C16.1346 14.4345 16.6622 13.5906 17.0267 12.6286C17.3911 11.6667 17.5419 10.6371 17.4791 9.53987H19.1336C19.1964 10.8687 18.9989 12.1169 18.5412 13.2847C18.0834 14.4524 17.4374 15.471 16.603 16.3404C15.7686 17.2098 14.7841 17.8962 13.6494 18.3995C12.5147 18.9028 11.2966 19.1545 9.99497 19.1545ZM6.35909 3.44839L5.58961 1.98371C6.23848 1.61989 6.93715 1.33924 7.68564 1.14177C8.43412 0.944311 9.20556 0.845581 9.99995 0.845581C11.5694 0.845581 13.012 1.20782 14.3278 1.93231C15.6437 2.6568 16.7077 3.61853 17.5199 4.8175V3.34558H19.1743V7.57475H14.9452V5.92529H16.2626C15.586 4.87969 14.6999 4.04742 13.6043 3.42846C12.5088 2.8095 11.3073 2.50002 9.99995 2.50002C9.33992 2.50002 8.70352 2.58086 8.09076 2.74254C7.47798 2.90423 6.90076 3.13952 6.35909 3.44839ZM3.50493 10.1445L2.37178 7.62819L-0.144531 6.50002L2.37178 5.37185L3.50493 2.85554L4.63309 5.37185L7.14941 6.50002L4.63309 7.62819L3.50493 10.1445ZM6.31064 13.9352L9.24678 5.99006H10.7739L13.6992 13.9352H12.1739L11.5104 11.9534H8.49543L7.81109 13.9352H6.31064ZM8.88128 10.7269H11.0978L10.0208 7.55889H9.95828L8.88128 10.7269Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconTranslate;
