import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        openStory(params) {
            const event = {
                session: params.session,
                locationId: ids.location.assignment,
                eventId: ids.event.assignmentStoryOpen,
                objectId: params.assignmentId,
                subObjectId: params.storyId,
            };

            debugEvent("open assignment story", event);
            return methods.sendEvent(event);
        },

        submitQuizAnswer(params) {
            const event = {
                session: params.session,
                locationId: ids.location.assignment,
                eventId: ids.event.assignmentStorySubmitQuiz,
                objectId: params.assignmentId,
                subObjectId: params.storyId,
                subSubObjectId: params.answerId,
            };

            debugEvent("submit quiz answer", event);
            methods.sendEvent(event);
        },

        openExtraJuice(params) {
            const event = {
                session: params.session,
                locationId: ids.location.assignment,
                eventId: ids.event.assignmentStoryOpenExtraJuice,

                objectId: params.assignmentId,
                subObjectId: params.storyId,
                subSubObjectId: params.extraJuiceId,
            };

            debugEvent("open extra juice", event);
            methods.sendEvent(event);
        },

        openVocab(params) {
            const event = {
                session: params.session,
                locationId: ids.location.assignment,
                eventId: ids.event.assignmentStoryOpenVocab,

                objectId: params.assignmentId,
                subObjectId: params.storyId,
                subSubObjectId: params.wordId,
            };

            debugEvent("open vocab", event);
            methods.sendEvent(event);
        },

        openInfographic(params) {
            const event = {
                session: params.session,
                locationId: ids.location.assignment,
                eventId: ids.event.assignmentStoryOpenInfographic,
                objectId: params.assignmentId,
                subObjectId: params.storyId,
                subSubObjectId: params.imageId,
            };

            debugEvent("open infographic", event);
            methods.sendEvent(event);
        },

        onListenStory(params) {
            const event = {
                session: params.session,
                locationId: ids.location.assignment,
                eventId: ids.event.assignmentStoryListen,
                objectId: params.assignmentId,
                subObjectId: params.storyId,
            };

            debugEvent("listen story", event);
            methods.sendEvent(event);
        },
    };
};
