import styleProperties from "@/base/lib/style-properties.js";

const setDimensions = (services, params) => {
    const { actions } = services;

    return (dispatch) => {
        dispatch(actions.device.setDimensions(params.dimensions));
    };
};

const setIsPWA = (services, params) => {
    const { actions } = services;

    return (dispatch) => {
        dispatch(actions.device.setIsPWA({
            isPWA: params.isPWA,
            isSafariPWA: params.isSafariPWA,
        }));

        if (params.isSafariPWA) {
            styleProperties.setMobileHeaderBottomHeightHigh();
        } else {
            styleProperties.setMobileHeaderBottomHeightDefault();
        }
    };
};


export default {
    setDimensions,
    setIsPWA,
};
