import * as types from "./types.js";


export const setScoreReportLoading = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_SCORE_REPORT_LOADING,
    payload,
});

export const setScoreReport = (payload) => ({
    type: types.SET_TEACHER_STUDENTS_SCORE_REPORT,
    payload,
});
