import device from "./device.js";
import array from "./array.js";


const getSiteUrl = () => {
    if (window) {
        const proto = window?.location?.protocol || "";
        const hostname = window?.location?.hostname || "";
        return `${proto}//${hostname}`;
    }

    return "";
};

/* --- */

const getSearchParams = () => {
    if (window) {
        return window.location.search || "";
    }

    return "";
};

const getSearchParamByName = (name) => {
    try {
        const url = new URL(window.location); // eslint-disable-line compat/compat
        const param = url.searchParams.get(name);
        return param || "";
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return "";
    }
};

const getPathnameWithSearchParams = (pathname) => {
    const searchParams = getSearchParams();

    return [pathname, searchParams].join("");
};

const getDecodedArrayFromSearchParam = (encodedValue = "", parseToInt = false) => {
    const value = encodedValue || "";

    let arr = globalThis.decodeURIComponent(value).split(",");

    if (parseToInt) {
        arr = arr.map((val) => {
            const num = parseInt(val, 10);

            if (Number.isNaN(num)) {
                return "";
            }

            return num;
        });
    }

    return arr.filter(array.filterCallbackNonEmptyString);
};

const getFormattedSearchParams = (filters = {}) => {
    const keys = Object.keys(filters);

    const params = {};

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const param = filters[key];

        if (Array.isArray(param)) {
            params[key] = param.join(",");
        } else {
            params[key] = param;
        }
    }

    return params;
};

/* --- */

const redirect = (url) => {
    if (window) {
        window.location = url;
    }
};

const reload = () => {
    if (window) {
        window.location.reload(true);
    }
};

const openUrl = (url) => {
    if (window) {
        window.open(url, "_blank");
    }
};

const download = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.click();
};

const universalDownload = (url) => {
    if (device.isSafari) {
        download(url);
    } else {
        openUrl(url);
    }
};

const stripProtocol = (url) => {
    const protocols = [
        "https://",
        "http://",
        "//",
    ];

    for (let i = 0; i < protocols.length; i += 1) {
        const prt = protocols[i];

        if (url.indexOf(prt) === 0) {
            return url.slice(prt.length);
        }
    }

    return url;
};

export default {
    getSiteUrl,
    getSearchParams,
    getSearchParamByName,
    getPathnameWithSearchParams,
    getDecodedArrayFromSearchParam,
    getFormattedSearchParams,

    redirect,
    reload,
    openUrl,
    download,
    universalDownload,
    stripProtocol,
};
