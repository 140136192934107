// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dFLKchNw90YXblF9loCa {\n    position: relative;\n\n    width: 2.4rem;\n    height: 2.4rem;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.dFLKchNw90YXblF9loCa:before,\n.dFLKchNw90YXblF9loCa:after {\n    position: absolute;\n    top: 0.35rem;\n    left: 1.1rem;\n    content: \" \";\n\n    height: 1.6rem;\n    width: 0.3rem;\n\n    background-color: var(--color-1);\n}\n\n.dFLKchNw90YXblF9loCa:before {\n    transform: rotate(45deg);\n}\n\n.dFLKchNw90YXblF9loCa:after {\n    transform: rotate(-45deg);\n}\n\n/* --------- */\n\n.WqAVZzgkND2GhBioMpeg:before,\n.WqAVZzgkND2GhBioMpeg:after {\n    background-color: var(--color-2);\n}\n", "",{"version":3,"sources":["webpack://./base/components/close/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,aAAa;IACb,cAAc;;IAEd,eAAe;IACf,aAAa;AACjB;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,YAAY;;IAEZ,cAAc;IACd,aAAa;;IAEb,gCAAgC;AACpC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,cAAc;;AAEd;;IAEI,gCAAgC;AACpC","sourcesContent":[".close {\n    position: relative;\n\n    width: 2.4rem;\n    height: 2.4rem;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.close:before,\n.close:after {\n    position: absolute;\n    top: 0.35rem;\n    left: 1.1rem;\n    content: \" \";\n\n    height: 1.6rem;\n    width: 0.3rem;\n\n    background-color: var(--color-1);\n}\n\n.close:before {\n    transform: rotate(45deg);\n}\n\n.close:after {\n    transform: rotate(-45deg);\n}\n\n/* --------- */\n\n.closeWhite:before,\n.closeWhite:after {\n    background-color: var(--color-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "dFLKchNw90YXblF9loCa",
	"closeWhite": "WqAVZzgkND2GhBioMpeg"
};
export default ___CSS_LOADER_EXPORT___;
