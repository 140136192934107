import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    studentsById: {},
    quizPerformancesByStudentId: {},
    dailyJuices: {},
    assignmentsByStudentId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENT_BY_ID: {
            const { account, overview } = action.payload.student;
            const studentId = account.ID;

            return {
                ...state,
                studentsById: {
                    ...state.studentsById,
                    [studentId]: {
                        isLoaded: true,
                        account,
                        overview,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_LOADING_BY_ID: {
            const { studentId, range } = action.payload;

            const student = state.quizPerformancesByStudentId[studentId] || {};

            return {
                ...state,
                quizPerformancesByStudentId: {
                    ...state.quizPerformancesByStudentId,
                    [studentId]: {
                        ...student,
                        [range]: {
                            isLoading: true,
                            data: {},
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_BY_ID: {
            const { studentId, range, data } = action.payload;

            const student = state.quizPerformancesByStudentId[studentId] || {};

            return {
                ...state,
                quizPerformancesByStudentId: {
                    ...state.quizPerformancesByStudentId,
                    [studentId]: {
                        ...student,
                        [range]: {
                            isLoading: false,
                            data,
                        },
                    },
                },
            };
        }

        case types.CLEAR_TEACHER_STUDENT_QUIZ_PERFORMANCES: {
            return {
                ...state,
                quizPerformancesByStudentId: {},
            };
        }

        /* --- */

        case types.SET_TEACHER_STUDENT_ASSIGNMENTS_LOADING_BY_ID: {
            const { studentId } = action.payload;

            const page = state.assignmentsByStudentId?.[studentId]?.page || 0;
            const assignments = state.assignmentsByStudentId?.[studentId]?.assignments || [];

            return {
                ...state,
                assignmentsByStudentId: {
                    ...state.assignmentsByStudentId,
                    [studentId]: {
                        assignments,
                        error: "",
                        page,
                        isLoading: true,
                        hasMore: false,
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENT_ASSIGNMENTS_BY_ID: {
            const {
                studentId,
                data,
                error,
                hasMore,
            } = action.payload;

            const assignments = (state.assignmentsByStudentId?.[studentId]?.assignments || [])
                .concat(data);

            let page = state.assignmentsByStudentId?.[studentId]?.page || 0;

            if (!error) {
                page += 1;
            }

            return {
                ...state,
                assignmentsByStudentId: {
                    ...state.assignmentsByStudentId,
                    [studentId]: {
                        isLoading: false,
                        assignments,
                        page,
                        hasMore,
                        error,
                    },
                },
            };
        }

        case types.CLEAR_TEACHER_STUDENT_ASSIGNMENTS: {
            return {
                ...state,
                assignmentsByStudentId: {},
            };
        }

        /* --- */

        case types.SET_TEACHER_STUDENT_DAILY_JUICES_LOADING_BY_ID: {
            const {
                studentId,
                dateFrom,
                dateTo,
                isBefore,
            } = action.payload;

            const dailyJuices = { ...state.dailyJuices };
            const student = dailyJuices?.[studentId] || {};

            dailyJuices[studentId] = {
                ...student,
                dailyJuices: student?.dailyJuices || [],
                dateFrom,
                dateTo,
            };

            if (isBefore) {
                dailyJuices[studentId].isLoadingBefore = true;
                dailyJuices[studentId].errorBefore = "";
            } else {
                dailyJuices[studentId].isLoadingAfter = true;
                dailyJuices[studentId].errorAfter = "";
            }

            return {
                ...state,
                dailyJuices,
            };
        }

        case types.SET_TEACHER_STUDENT_DAILY_JUICES_BY_ID: {
            const {
                studentId,
                juices,
                dateFrom,
                dateTo,
                errorBefore,
                errorAfter,
                hasMoreBefore,
                hasMoreAfter,
                isBefore,
            } = action.payload;

            const dailyJuices = { ...state.dailyJuices };
            const studentData = dailyJuices?.[studentId] || {};

            let isLastLoaded = studentData?.isLastLoaded || false;
            let isFirstLoaded = studentData?.isFirstLoaded || false;

            if (!hasMoreAfter) {
                isLastLoaded = true;
            }

            if (!hasMoreBefore) {
                isFirstLoaded = true;
            }

            const studentJuices = studentData?.dailyJuices || [];

            const djs = isBefore
                ? [...studentJuices].concat(juices)
                : [...juices].concat(studentJuices);

            dailyJuices[studentId] = {
                dailyJuices: djs,
                dateFrom,
                dateTo,
                errorBefore,
                errorAfter,
                isLoadingBefore: false,
                isLoadingAfter: false,
                isLastLoaded,
                isFirstLoaded,
            };

            return {
                ...state,
                dailyJuices,
            };
        }

        case types.CLEAR_TEACHER_STUDENT_DAILY_JUICES: {
            return {
                ...state,
                dailyJuices: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
