import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconStudentEdition = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="13.333292"
                    height="16.666674"
                    viewBox="0 0 13.333292 16.666674"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M 11.666692,0 H 1.666662 C 0.750002,0 0,0.750004 0,1.666664 v 13.33331 c 0,0.9167 0.750002,1.6667 1.666662,1.6667 h 10.00003 c 0.9166,0 1.6666,-0.75 1.6666,-1.6667 V 1.666664 C 13.333292,0.750004 12.583292,0 11.666692,0 Z M 1.666662,1.666664 h 4.16667 v 6.66667 l -2.08333,-1.25 -2.08334,1.25 z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconStudentEdition;
