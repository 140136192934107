// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yPkSsk6DwDyJGsID1k6W {\n    display: grid;\n}\n\n.m4uCvRGYVRpymgYxJuU5 {\n    padding: 1.5rem 2rem;\n}\n\n.IaU4CxJFeGZCdtlWmhbH {\n    padding: 1.5rem 2rem;\n\n    border-top: solid 0.1rem var(--border-default-color);\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-polls/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;;IAEpB,oDAAoD;AACxD","sourcesContent":[".container {\n    display: grid;\n}\n\n.section {\n    padding: 1.5rem 2rem;\n}\n\n.buttonMore {\n    padding: 1.5rem 2rem;\n\n    border-top: solid 0.1rem var(--border-default-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "yPkSsk6DwDyJGsID1k6W",
	"section": "m4uCvRGYVRpymgYxJuU5",
	"buttonMore": "IaU4CxJFeGZCdtlWmhbH"
};
export default ___CSS_LOADER_EXPORT___;
