import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    stateStandardsByState: {},
    standardsByGroups: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_STATE_STANDARDS_BY_STATE: {
            return {
                ...state,
                stateStandardsByState: {
                    ...state.stateStandardsByState,
                    [action.payload.stateName]: action.payload.standards,
                },
            };
        }

        /* --- */

        case types.SET_STANDARDS_BY_GROUPS_LOADING: {
            return {
                ...state,
                standardsByGroups: {
                    ...state.standardsByGroups,
                    error: "",
                    isLoading: true,
                },
            };
        }

        case types.SET_STANDARDS_BY_GROUPS: {
            const { data, error } = action.payload;

            return {
                ...state,
                standardsByGroups: {
                    data,
                    error,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
