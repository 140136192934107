import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grades from "@/base/project/grades.js";

import dailyJuiceActions from "@/base/actions/daily-juice.js";
import actions from "@/base/store/actions.js";

import storage from "@/base/lib/storage/index.js";
import { tryFormatDate, formatDayMonthYearDate } from "@/base/lib/date.js";

import IconArrowRight from "@/base/icons/arrow-right/index.js";
import IconGraduationCup from "@/base/icons/graduation-cap-empty/index.js";
import IconNotebook from "@/base/icons/notebook/index.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import SelectCustom from "@/base/components/select-custom/index.js";

import api from "@/app/api.js";

import styles from "./styles.module.css";


const getDefaultFilterGrades = (grades) => {
    let defaultValue = "";
    const defaultGrades = storage.local.loadDailyJuicesDefaultGrades();

    if (grades && grades.length > 0) {
        if (!defaultGrades) {
            for (let i = 0; i < grades.length; i += 1) {
                const grade = grades[i];
                if (grade.value === defaultGrades) {
                    defaultValue = { ...grade };
                    break;
                }
            }
        } else {
            defaultValue = grades[grades.length - 1];
        }
    }

    return defaultValue;
};

const StudentIndexTrial = () => {
    const [filterByGrade, setFilterByGrade] = useState(() => {
        const gs = getDefaultFilterGrades(Grades.getGradesOptions());
        return gs ? gs?.value : "";
    });

    const store = useSelector((state) => ({
        session: state.user.session,
        juices: state.juices.juices,
        isJuicesLoaded: state.juices.isJuicesLoaded,
        isJuicesLoading: state.juices.isJuicesLoading,
        juicesPage: state.juices.juicesPage,
        juicesHasMorePages: state.juices.juicesHasMorePages,
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadJuices = (page, grades, clearJuices) => {
        if (clearJuices) {
            dispatch(actions.juices.clearJuices());
        }

        dispatch(dailyJuiceActions.loadDailyJuicesByPage(
            {
                api,
                actions,
            },
            {
                session: store.session,
                page,
                grades: grades.split("-").join(",").toLowerCase(),
            },
        ));
    };

    useEffect(() => {
        if (store.juicesPage === 0 && !store.isJuicesLoaded) {
            loadJuices(0, filterByGrade, false);
        }
    }, [store.session]);

    const onOpenJuice = (juice) => {
        navigate(`/daily-juices/${juice.id}`);
    };

    const onChangeGrade = (value) => {
        setFilterByGrade(value);
        storage.local.saveDailyJuicesDefaultGrades(value);

        dispatch(actions.juices.setLastVisitedJuice({
            lastVisitedJuice: null,
        }));

        loadJuices(0, value, true);
    };

    const onLoadMore = () => {
        loadJuices(store.juicesPage, filterByGrade, false);
    };

    const renderGradeFilter = () => {
        return (
            <div className={styles.gradesSelector}>
                <SelectCustom
                    defaultValueLabel="Reading level"
                    selected={filterByGrade}
                    options={Grades.getGradesOptions()}
                    icon={<IconGraduationCup />}
                    onSelect={onChangeGrade}
                    withBorder={false}
                />
            </div>
        );
    };

    const renderJuices = () => {
        if (store.isJuicesLoading && store.juices.length === 0) {
            return (
                <RequestLoader />
            );
        }

        const js = store.juices.map((j) => {
            const title = [];

            if (j.juiceDate) {
                const juiceDate = tryFormatDate(j.juiceDate, formatDayMonthYearDate);
                title.push(juiceDate);
            } else if (j.title) {
                title.push(j.title);
            }

            let featuredImage = null;

            if (j
                && j.featuredImage
                && j.featuredImage.sizes
                && j.featuredImage.sizes.thumbnail) {
                featuredImage = (
                    <img
                        src={j.featuredImage.sizes.thumbnail.src}
                        alt="Featured"
                    />
                );
            }

            return (
                <div
                    className={styles.juice}
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                        onOpenJuice(j);
                    }}
                    onKeyPress={() => {
                        onOpenJuice(j);
                    }}
                >
                    <div className={styles.featuredImage}>
                        {featuredImage}
                    </div>

                    <div>{title.join(" - ")}</div>

                    <div className={styles.openStoryIcon}>
                        <IconNotebook
                            className={styles.readStoryIcon}
                            title="Open story"
                            isOrange
                        />
                        <IconArrowRight
                            className={styles.goToStoryArrow}
                            title="Go to story"
                            isBlack
                        />
                    </div>
                </div>
            );
        });

        if (js.length === 0) {
            const noJuices = (
                <div className={styles.noJuices}>
                    No juices
                </div>
            );

            js.push(noJuices);
        }

        return (
            <div className={styles.juices}>
                {js}
            </div>
        );
    };

    const renderLoadMoreButton = () => {
        if (!store.isJuicesLoaded
            || store.juices.length === 0
            || !store.juicesHasMorePages) {
            return null;
        }

        if (store.isJuicesLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div
                className={styles.loadMoreButton}
                tabIndex="-1"
                role="button"
                onClick={onLoadMore}
                onKeyPress={onLoadMore}
            >
                Load More
            </div>
        );
    };

    if (!store.isJuicesLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {/* TODO: move backgroundLayout to component */}
            <div className={styles.backgroundLayout} />

            <div className={styles.index}>
                {renderGradeFilter()}
                {renderJuices()}
                {renderLoadMoreButton()}
            </div>
        </>
    );
};

export default StudentIndexTrial;
