import React from "react";

import SliderRange from "@/base/components/slider-range/index.js";

import styles from "./styles.module.css";


const PlayerAudioSpeed = (props) => {
    const renderRates = () => {
        const rates = props.rates.map((rate) => {
            return (
                <div className={styles.rate}>
                    {rate.text}
                </div>
            );
        });

        return (
            <div className={styles.rates}>
                {rates}
            </div>
        );
    };

    const renderSlider = () => {
        const max = props.rates.length - 1;

        let value = 0;

        for (let i = 0; i < props.rates.length; i += 1) {
            const rate = props.rates[i];

            if (rate.value === props.rate) {
                value = i;
            }
        }

        return (
            <SliderRange
                min={0}
                max={max}
                step={1}
                value={value}
                onChange={(index) => {
                    props.onChange(props.rates[index].value);
                }}
                isStepsVisible
            />
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.label}>
                Playback speed
            </div>
            {renderSlider()}
            {renderRates()}
        </div>
    );
};

PlayerAudioSpeed.defaultProps = {
    rate: 1,
    rates: [
        { value: 0.5, text: "0.5x" },
        { value: 0.75, text: "0.75x" },
        { value: 1, text: "1.0x" },
        { value: 1.5, text: "1.5x" },
        { value: 2, text: "2.0x" },
    ],
    onChange: () => { },
};

export default PlayerAudioSpeed;
