// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mWXCe3ivhFzHREUh8dAX {\n    max-width: 100%;\n\n    color: var(--icon-bg-color-grey);\n}\n\n.PTcyOF9teJjrRKZK8aaI {\n    color: var(--icon-bg-color-white);\n}\n\n.KfESOpoo_9REsXl6fr4w {\n    color: var(--icon-bg-color-black);\n}\n\n.IxdUDSA67fzjal3BhpTa {\n    color: var(--icon-bg-color-red);\n}\n\n.X1USg8TYE3Dxxu2dVGoM {\n    color: var(--icon-bg-color-orange);\n}\n\n.sAwDTigG5SrnPZIldkUS {\n    color: var(--icon-bg-color-sky);\n}\n\n.PASWSd8SUhNQpVSNCM05 {\n    color: var(--icon-bg-color-green);\n}", "",{"version":3,"sources":["webpack://./base/icons/wrapper/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;IAEf,gCAAgC;AACpC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".iconGrey {\n    max-width: 100%;\n\n    color: var(--icon-bg-color-grey);\n}\n\n.iconWhite {\n    color: var(--icon-bg-color-white);\n}\n\n.iconBlack {\n    color: var(--icon-bg-color-black);\n}\n\n.iconRed {\n    color: var(--icon-bg-color-red);\n}\n\n.iconOrange {\n    color: var(--icon-bg-color-orange);\n}\n\n.iconSky {\n    color: var(--icon-bg-color-sky);\n}\n\n.iconGreen {\n    color: var(--icon-bg-color-green);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconGrey": "mWXCe3ivhFzHREUh8dAX",
	"iconWhite": "PTcyOF9teJjrRKZK8aaI",
	"iconBlack": "KfESOpoo_9REsXl6fr4w",
	"iconRed": "IxdUDSA67fzjal3BhpTa",
	"iconOrange": "X1USg8TYE3Dxxu2dVGoM",
	"iconSky": "sAwDTigG5SrnPZIldkUS",
	"iconGreen": "PASWSd8SUhNQpVSNCM05"
};
export default ___CSS_LOADER_EXPORT___;
