// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lzfeHhgya9kAqmR8x2qM {\n    display: flex;\n    place-content: center;\n\n    font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-confirm-message/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;;IAErB,gBAAgB;AACpB","sourcesContent":[".message {\n    display: flex;\n    place-content: center;\n\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "lzfeHhgya9kAqmR8x2qM"
};
export default ___CSS_LOADER_EXPORT___;
