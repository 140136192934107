// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t9vjmI6EskEpG06_ANFf {\n    display: grid;\n    grid-gap: 7rem;\n}\n\n.jigOSXrRw8LWNPgyWsuE {\n    display: grid;\n}\n\n.Ab5m06odqezxDHU44jyl {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/teacher-class-set-up/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 7rem;\n}\n\n.fields {\n    display: grid;\n}\n\n.submitButton {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "t9vjmI6EskEpG06_ANFf",
	"fields": "jigOSXrRw8LWNPgyWsuE",
	"submitButton": "Ab5m06odqezxDHU44jyl"
};
export default ___CSS_LOADER_EXPORT___;
