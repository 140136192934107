import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import text from "@/base/text/index.js";
import actionsUser from "@/base/actions/user.js";
import actions from "@/base/store/actions.js";

import Geo from "@/base/project/geo.js";
import Subscription from "@/base/project/subscription.js";

import useCoupon from "@/base/hooks/use-coupon/index.js";
import usePaymentDetails from "@/base/hooks/use-payment-details/index.js";
import usePaymentSubscription from "@/base/hooks/use-payment-subscription/index.js";

import MessageDanger from "@/base/components/message-danger/index.js";
import UserCheckoutForm from "@/base/forms/user-checkout/index.js";

import Stripe from "@/app/containers/stripe/index.js";

import api from "@/app/api.js";
import app from "@/app/app.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    session: state.user.session,
    subdivisionsByAlpha2: state.geo.subdivisionsByAlpha2,
});

const DEFAULT_ALPHA_2 = Geo.getDefaultAlpha2();

// NOTE: payment
const UserCheckoutPayment = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const elements = useElements();
    const stripe = useStripe();

    const [sessionPlan, setSessionPlan] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const paymentDetails = usePaymentDetails();
    const paymentSubscription = usePaymentSubscription();
    const coupon = useCoupon();

    /* --- */

    const getSubdivisions = useCallback(() => {
        const subdivisions = store.subdivisionsByAlpha2[DEFAULT_ALPHA_2]?.subdivisions || [];
        return Geo.getSubdivisionOptions(subdivisions);
    }, [store.subdivisionsByAlpha2]);

    /* --- */

    const loadSessionPlan = useCallback(async () => {
        const { plan, error } = await app.actions.common.subscription.loadSessionPlan();

        if (error) {
            setErrorMessage(error);
            return;
        }

        setSessionPlan(plan);
    }, []);

    /* --- */

    const onApplyCoupon = async (couponCode) => {
        if (!couponCode) {
            return;
        }

        coupon.setSubmitted(couponCode);

        const {
            discountMessage,
            error,
        } = await app.actions.common.subscription.applySubscriptionCoupon({
            couponCode,
            planId: sessionPlan.id,
        });

        coupon.setLoaded({
            discountMessage,
            error,
        });
    };

    /* --- */

    const onPaymentTrackActivity = useCallback((intentId, stripeRes) => {
        app.actions.common.payment.setPaymentActivityLog({
            intentId,
            stripeRes,
        });
    }, []);

    const completePayment = useCallback(async (intentId) => {
        const { message, error } = await app.actions.common.payment.setPaymentCompleted({
            intentId,
        });

        if (error) {
            return [false, ""];
        }

        return [true, message];
    }, []);

    const confirmPayment = async (details) => {
        const res = await stripe.confirmCardPayment(details.clientSecret, {
            payment_method: details.paymentMethodId,
            setup_future_usage: "off_session",
        });

        onPaymentTrackActivity(details.intentId, res);

        return res?.paymentIntent?.status === "succeeded";
    };

    const loadPaymentPriceDetails = useCallback(async (intentId) => {
        const { price, error } = await app.actions.common.payment.loadPaymentDetails({
            intentId,
        });

        paymentSubscription.setData({
            price,
            error,
            isPriceLoaded: true,
        });
    }, []);

    const createPaymentMethod = async (intentId, subscriptionDates, billingValues) => {
        const billingDetails = {
            name: billingValues.name,
            address: {
                line1: billingValues.line1,
                line2: billingValues.line2,
                city: billingValues.city,
                state: billingValues.state.value,
                postal_code: billingValues.zip,
            },
        };

        const cardElement = elements.getElement(CardElement);

        const res = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
        });

        onPaymentTrackActivity(intentId, res);

        if (!res?.paymentMethod) {
            paymentDetails.setError(res.error?.message || text.error);
            return "";
        }

        paymentSubscription.setData({
            isCardLoaded: true,
            card: {
                name: res.paymentMethod.billing_details.name,
                expYear: res.paymentMethod.card.exp_year,
                expMonth: res.paymentMethod.card.exp_month,
                last4: res.paymentMethod.card.last4,
            },
            subscriptionDates,
            isAutoRenew: billingValues.isAutoRenew,
        });

        paymentDetails.setData({
            paymentMethodId: res.paymentMethod.id,
        });

        return res.paymentMethod.id;
    };

    const onPaymentSubmit = async (values, formActions) => {
        paymentDetails.setError("");

        const res = await app.actions.common.subscription.createPaymentIntentByPlan({
            planId: sessionPlan.id,
            coupon: coupon.state.couponCode,
            isAutoRenew: values.isAutoRenew,
        });

        paymentDetails.setData(res);

        if (res.error) {
            formActions.setSubmitting(false);
            return;
        }

        const paymentMethodId = await createPaymentMethod(
            res.intentId,
            res.dates,
            values,
        );

        if (!paymentMethodId) {
            formActions.setSubmitting(false);
            return;
        }

        if (res.is100PercentDiscount) {
            await api.subscription.paymentAttachPaymentMethod({
                session: store.session,
                paymentMethodId,
            });

            const completeRes = await api.subscription.paymentCompleteWithDiscount100({
                session: store.session,
                subscriptionId: res.subscriptionEncId,
            });

            if (!completeRes.ok) {
                paymentDetails.setError(completeRes.error || text.error);
                return;
            }

            props.onPayment();
            return;
        }

        await loadPaymentPriceDetails(res.intentId);

        const isSubscribed = await confirmPayment({
            intentId: res.intentId,
            clientSecret: res.clientSecret,
            paymentMethodId,
        });

        if (!isSubscribed) {
            paymentDetails.setError(text.errorTryAgain);
            formActions.setSubmitting(false);
            return;
        }

        const [isCompleted, msg] = await completePayment(res.intentId);

        if (!isCompleted) {
            paymentDetails.setError(msg || text.errorTryAgain);
            formActions.setSubmitting(false);
            return;
        }

        dispatch(actionsUser.loadSession({ api, actions }, {
            session: store.session,
        }));

        props.onPayment();
    };

    /* --- */

    useEffect(() => {
        loadSessionPlan();
        app.actions.common.geo.loadSubdivisionsByAlpha2({
            alpha2: DEFAULT_ALPHA_2,
        });
    }, []);

    /* --- */

    if (!store.session) {
        return null;
    }

    if (errorMessage) {
        return (
            <div className={styles.message}>
                <MessageDanger>
                    {errorMessage}
                </MessageDanger>
            </div>
        );
    }

    const couponState = {
        ...coupon.state,
        onApply: onApplyCoupon,
        onChange: coupon.reset,
    };

    const isPlanAnnually = Subscription.isPlanAnnually(sessionPlan);

    return (
        <UserCheckoutForm
            theme={store.theme}
            states={getSubdivisions()}
            error={paymentDetails.state.error}
            onSubmit={onPaymentSubmit}
            couponState={couponState}
            isDisabledTabIndex={props.isDisabledTabIndex}
            withAutoRenew={isPlanAnnually}
            withCoupon
        />
    );
};

UserCheckoutPayment.defaultProps = {
    isDisabledTabIndex: false,
    onPayment: () => { },
};

/* --- */

const UserCheckoutPaymentContainer = (props) => {
    return (
        <Stripe>
            <UserCheckoutPayment
                isDisabledTabIndex={props.isDisabledTabIndex}
                onPayment={props.onPayment}
            />
        </Stripe>
    );
};

UserCheckoutPaymentContainer.defaultProps = {
    isDisabledTabIndex: false,
    onPayment: () => { },
};

export default UserCheckoutPaymentContainer;
