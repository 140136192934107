import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        onSearch() {
            const event = {
                locationId: ids.location.searchMini,
                eventId: ids.event.onSearch,
            };

            debugEvent("Search", event);
            methods.sendEvent(event);
        },

        storyClick(params) {
            const event = {
                locationId: ids.location.searchMini,
                eventId: ids.event.searchStoryClick,
                objectId: params.storyId,
            };

            debugEvent("Click on found story", event);
            methods.sendEvent(event);
        },

        filterClick() {
            const event = {
                locationId: ids.location.searchMini,
                eventId: ids.event.searchFilterClick,
            };

            debugEvent("Click on search filter", event);
            methods.sendEvent(event);
        },

        openExtraJuice(params) {
            const event = {
                locationId: ids.location.searchMini,
                eventId: ids.event.searchMiniOpenExtraJuice,
                objectId: params.extraJuiceId,
            };

            debugEvent("Search Mini - open EJ", event);
            methods.sendEvent(event);
        },

        translateStory(params = {}) {
            const event = {
                locationId: ids.location.searchMini,
                eventId: ids.event.translateMiniSearchStory,
                objectId: params.storyId,
                subObjectId: params.languageId,
            };

            debugEvent("Translate search mini story", event);
            methods.sendEvent(event);
        },

        translateExtraJuice(params = {}) {
            const event = {
                locationId: ids.location.searchMini,
                eventId: ids.event.translateMiniSearchExtraJuice,
                objectId: params.storyId,
                subObjectId: params.languageId,
            };

            debugEvent("Translate search mini extra juice", event);
            methods.sendEvent(event);
        },
    };
};
