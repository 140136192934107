export default (apiURL, methods) => {
    return {
        setQuizAnswer(params = {}) {
            // NOTE: action=set_quiz_answer
            const url = `${apiURL}/quizzes/${params.quizId}/${params.questionId}`;

            return methods.get3(url, {
                session_id: params.session,
                answer_id: params.answerId,
                juice_id: params.juiceId,
                story_id: params.storyId,
                time_took: params.timeTook,
            });
        },

        getQuizzesByJuiceId(params = {}) {
            // NOTE: action=get_users_answers_by_juice
            const {
                session,
                juiceId,
                quizId,
            } = params;

            const url = `${apiURL}/quizzes/${quizId}`;

            return methods.get(url, {
                juice_id: juiceId,
                session_id: session,
            });
        },
    };
};
