import React, { useCallback } from "react";

import staticFiles from "@/base/static-files.js";

import parseTags from "@/base/lib/parse-tags.js";
import classNames from "@/base/lib/class-names.js";
import highlight from "@/base/lib/highlight.js";

import IconOrange from "@/base/icons/orange/index.js";

import Text from "@/base/components/text/index.js";
import ButtonRounded from "@/base/components/button-rounded/index.js";
import WordHighlighted from "@/base/components/word-highlighted/index.js";

import styles from "./styles.module.css";


const JuiceContentWithHighlight = (props) => {
    const getParsedContent = useCallback(() => {
        let content = parseTags.parse(props.content).map((line) => {
            if (parseTags.isTag(line)) {
                const wordVal = parseTags.trimTag(line);
                return `<span data-word="word" class="${styles.vocabWord}">${wordVal}</span>`;
            }

            return line;
        }).join("");

        content = parseTags.parseCurlyBrackets(content).map((line) => {
            if (parseTags.isTagCurlyBrackets(line)) {
                const wordVal = parseTags.trimTagCurlyBrackets(line);

                if (props.hideExtraJuicesButtons) {
                    return (`
                        <span class="${styles.extraJuiceWordOnDesktop}">${wordVal}</span>
                    `);
                }

                return (`
                    <span data-word="extraJuiceWord" class="${styles.extraJuiceWord}">
                        <img
                            src="${staticFiles.iconOrange}"
                            alt="extra-juice"
                            title="extra-juice"
                        />${wordVal}</span>
                `);
            }

            return line;
        }).join("");

        const parsedContent = [];
        const captions = [];

        parseTags.parseShortcode(content).forEach((line) => {
            if (!parseTags.isTagShortcode(line)) {
                parsedContent.push(line);
            } else if (parseTags.isTagShortcodeName(line, "caption")) {
                const contentLines = parseTags.parse(line.tagContent, "<", "/>");

                if (contentLines.length) {
                    let captionText = null;

                    if (contentLines[1]) {
                        captionText = `<div class="${styles.captionText}">${contentLines[1]}</div>`;
                    }

                    const captionContent = `<div class="${styles.captionImg}">${contentLines[0]}${captionText}</div>`;
                    const contentLength = parsedContent.join("").length;

                    captions.push({
                        from: contentLength,
                        to: contentLength + captionContent.length,
                    });

                    parsedContent.push(captionContent);
                }
            } else {
                parsedContent.push(line.tagContent);
            }
        });

        return {
            content: parsedContent.join(""),
            captions,
        };
    }, [props.content]);

    const getHighlightedContent = useCallback((content, skips) => {
        const word = props?.highlight?.word || "";
        const skipCount = props?.highlight?.skipSameWordCount || 0;

        return highlight.getHighlightedContent({
            content: {
                content,
                skips,
            },
            word: {
                word,
                skipCount,
                wrapped: WordHighlighted(word),
            },
        });
    }, [props.highlight]);

    // ----------

    const onContentClick = (evt) => {
        if (evt.target && evt.target.dataset.word) {
            const word = (evt.target.innerText || "").trim();

            if (evt.target.dataset.word === "word") {
                props.onWordClick(word);
            } else if (evt.target.dataset.word === "extraJuiceWord") {
                props.onExtraJuiceWordClick("story");
            }
        }
    };

    const onExtraJuiceClick = () => {
        props.onExtraJuiceWordClick("story-button");
    };

    const renderExtraJuices = () => {
        if (props.extraJuices.length === 0 || props.hideExtraJuicesButtons) {
            return null;
        }

        const extraJuicesWords = [];

        props.extraJuices.forEach((ej) => {
            extraJuicesWords.push(
                <ButtonRounded onClick={onExtraJuiceClick}>
                    <IconOrange
                        className={styles.extraJuiceIcon}
                        isOrange
                    />
                    {`Extra Juice: ${ej.title}`}
                </ButtonRounded>,
            );
        });

        return (
            <div className={styles.extraJuices}>
                {extraJuicesWords}
            </div>
        );
    };

    const renderJuiceContent = () => {
        const { content, captions } = getParsedContent();
        const contentHighlighted = getHighlightedContent(content, captions);

        return (
            <Text onClick={onContentClick}>
                {contentHighlighted}
            </Text>
        );
    };

    const contentClassName = classNames({
        [styles.content]: true,
        [props.className]: props.className,
    });

    return (
        <div className={contentClassName}>
            {renderJuiceContent()}
            {renderExtraJuices()}
        </div>
    );
};

JuiceContentWithHighlight.defaultProps = {
    className: "",
    content: "",
    extraJuices: [],
    highlight: null,
    onWordClick: () => { },
    onExtraJuiceWordClick: () => { },
    hideExtraJuicesButtons: false,
};

export default JuiceContentWithHighlight;
