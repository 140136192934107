const getDailyJuiceScoreDateByJuiceId = (dates, juiceId) => {
    if (!Array.isArray(dates) || !juiceId) {
        return "";
    }

    for (let i = 0; i < dates.length; i += 1) {
        const d = dates[i];
        const juiceIds = d.ids || [];

        if (juiceIds.indexOf(juiceId) !== -1) {
            return d.date || "";
        }
    }

    return "";
};

const setUpdatedStudentsDailyJuiceScores = (rangeData, juiceId, studentId, isUpdated) => {
    const students = rangeData?.students || [];
    const newStudents = [...students];

    for (let i = 0; i < newStudents.length; i += 1) {
        const student = newStudents[i];

        if (student.id === studentId) {
            const juiceIds = (student.quizzesScores || []).map((q) => q.id);
            const juiceIdIndex = juiceIds.indexOf(juiceId);

            if (juiceIdIndex !== -1) {
                newStudents[i].quizzesScores[juiceIdIndex].isUpdated = isUpdated;

                if (isUpdated) {
                    newStudents[i].quizzesScores[juiceIdIndex].totalAnswered += 1;
                }
            } else if (isUpdated) {
                const dates = rangeData?.dates || [];

                newStudents[i].quizzesScores.push({
                    id: juiceId,
                    date: getDailyJuiceScoreDateByJuiceId(dates, juiceId),
                    totalAnswered: 1,
                    isUpdated: true,
                });
            }
        }
    }

    return newStudents;
};

const setUpdatedDailyJuicesScores = (classesScoresByDailyJuices, juiceId, studentId, isUpdated) => {
    const scores = { ...classesScoresByDailyJuices };
    const classIds = Object.keys(scores);

    for (let i = 0; i < classIds.length; i += 1) {
        const classId = classIds[i];
        const ranges = Object.keys(scores[classId]);

        for (let j = 0; j < ranges.length; j += 1) {
            const range = ranges[j];
            const rangeData = scores[classId][range]?.data || {};

            scores[classId][range].data.students = setUpdatedStudentsDailyJuiceScores(
                rangeData,
                juiceId,
                studentId,
                isUpdated,
            );
        }
    }

    return scores;
};

const setStudentDailyJuiceScoreUpdatedStatus = (classesScoresByDailyJuices, juiceId, studentId) => {
    return setUpdatedDailyJuicesScores(
        classesScoresByDailyJuices,
        juiceId,
        studentId,
        true,
    );
};

const clearStudentDailyJuiceScoreUpdatedStatus = (classesScoresByDailyJuices, juiceId, studentId) => {
    return setUpdatedDailyJuicesScores(
        classesScoresByDailyJuices,
        juiceId,
        studentId,
        false,
    );
};

/* --- */

export default {
    setStudentDailyJuiceScoreUpdatedStatus,
    clearStudentDailyJuiceScoreUpdatedStatus,
};
