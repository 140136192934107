import text from "@/base/text/index.js";


const getAssignmentStories = (services, params) => {
    const { actions, api } = services;

    return async (dispatch) => {
        const res = await api.assignments.getAssignmentStories({
            session: params.session,
            groupCode: params.groupCode,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.assignments.setAssignmentByGroupCode({
            groupCode: params.groupCode,
            data,
            error,
        }));

        if (params.onFinish) {
            params.onFinish(res);
        }
    };
};

export default {
    getAssignmentStories,
};
