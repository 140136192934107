import { useEffect } from "react";
import { useDispatch } from "react-redux";

import actions from "@/base/store/actions.js";


const useTitle = (callback, dependencies = []) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const title = callback();
        dispatch(actions.navigation.setDocTitle(title));

        return () => {
            dispatch(actions.navigation.setDocTitle(""));
        };
    }, dependencies);
};

export default useTitle;
