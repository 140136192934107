import React from "react";

import IconImageAdd from "@/base/icons/image-add/index.js";
import IconTrash from "@/base/icons/trash/index.js";

import AvatarIcon from "@/base/components/avatar-icon/index.js";
import ButtonCircle from "@/base/components/button-circle/index.js";
import LoaderSmall from "@/base/components/loader-small/index.js";
import Switch from "@/base/components/switch/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import ProfileRow from "@/base/components/profile-row/index.js";

import styles from "./styles.module.css";


const PersonalInfo = (props) => {
    const renderControls = () => {
        const rightControl = (
            <div className={styles.switchWithLoader}>
                {props.emailNotification.isLoading ? <LoaderSmall /> : null}
                <Switch
                    name="isEmailNotificationEnabled"
                    checked={props.emailNotification.enabled}
                    disabled={props.emailNotification.isLoading}
                    onChange={props.emailNotification.onChange}
                />
            </div>
        );

        return (
            <div className={styles.personalInfoControls}>
                <ProfileRow
                    isEmailNotification
                    withValueEllipsis={false}
                    boldName
                    customRightControl={rightControl}
                    value="Receive emails summarizing each new issue"
                />
                <ButtonBig
                    outlined
                    onClick={props.onContactSupport}
                >
                    Contact support
                </ButtonBig>
            </div>
        );
    };

    const renderPassword = () => {
        if (props.isLMS) {
            return null;
        }

        return (
            <ProfileRow
                isPassword
                value="********"
                onClickRightControl={props.onEditPassword}
            />
        );
    };

    const profileRightControl = (
        <div className={styles.profileRightControl}>
            <ButtonCircle
                icon={<IconImageAdd isBlack />}
                onClick={props.onOpenUploadAvatar}
            />
            <ButtonCircle
                icon={<IconTrash isBlack />}
                disabled={props.disabledDeleteAvatar}
                onClick={props.onDeleteAvatar}
            />
        </div>
    );

    const profileLeftControl = (
        <AvatarIcon
            avatarUrl={props.avatarUrl}
            name={props.avatarIconName}
            disabled
        />
    );

    return (
        <div className={styles.personalInfoContainer}>
            <WithScroll>
                <div className={styles.personalInfo}>
                    <ProfileRow
                        withValueEllipsis={false}
                        customRightControl={profileRightControl}
                        customLeftControl={profileLeftControl}
                        customName="Photo"
                        value="A photo helps personalize your account"
                    />
                    <ProfileRow
                        value={props.fullName}
                        onClickRightControl={props.onEditFullName}
                        customRightControl={props.isLMS ? <div /> : null}
                        isName
                    />
                    <ProfileRow
                        isUsername
                        value={props.userName}
                    />
                    <ProfileRow
                        value={props.email}
                        onClickRightControl={props.onEditEmail}
                        customRightControl={props.isLMS ? <div /> : null}
                        isEmail
                    />
                    {renderPassword()}
                    <ProfileRow
                        isDistrict
                        value={props.district}
                    />
                    <ProfileRow
                        isSchool
                        value={props.school}
                    />
                    <ProfileRow
                        isDepartment
                        value={props.department}
                    />
                </div>
            </WithScroll>
            <div>
                {renderControls()}
            </div>
        </div>
    );
};

PersonalInfo.defaultProps = {
    avatarUrl: "",
    avatarIconName: "",
    fullName: "",
    userName: "",
    email: "",
    district: "",
    school: "",
    department: "",
    disabledDeleteAvatar: true,
    isLMS: false,
    isEmailNotificationEnabled: false,
    emailNotification: {
        enabled: false,
        isLoading: false,
        onChange: () => { },
    },
    onContactSupport: () => { },
    onEditFullName: () => { },
    onDeleteAvatar: () => { },
    onEditEmail: () => { },
    onEditPassword: () => { },
    onOpenUploadAvatar: () => { },
};

export default PersonalInfo;
