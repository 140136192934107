const routeDashboard = {
    to: "/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    dataComment: "link-dashboard",
};

const routeDashboardDistrictAdmin = {
    to: "/district-admin/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    dataComment: "link-dashboard",
};

const routeDashboardAdmin = {
    to: "/admin/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    dataComment: "link-dashboard",
};

// TODO: enable later
// const routeAnalytics = {
//     to: "/analytics",
//     name: "Analytics",
//     icon: "dashboard",
//     dataComment: "link-analytics",
// };

const routeDailyJuices = {
    to: "/daily-juices",
    name: "Daily Juices",
    icon: "studentEdition",
    dataComment: "link-daily-juice",
};

const routeDailyJuiceTeacherEdition = {
    to: "/teacher-daily-juices",
    name: "Teacher Edition",
    icon: "teacherEdition",
    dataComment: "link-daily-juice-teacher-edition",
};

const routeDailyJuiceStudentEdition = {
    to: "/daily-juices",
    name: "Student Edition",
    icon: "studentEdition",
    dataComment: "link-daily-juice-student-edition",
};

const routeResources = {
    to: "/resources",
    name: "Resources",
    icon: "resources",
    dataComment: "link-resources",
};

const routeAssignments = {
    to: "/assignments",
    name: "Assignments",
    icon: "clipboard",
    dataComment: "link-assignments",
};

const routeClass = {
    to: "/class",
    name: "Class",
    icon: "users",
    dataComment: "link-class",
};

const routeClassAdmin = {
    to: "/admin/class",
    name: "Class",
    icon: "users",
    dataComment: "link-class",
};

const routeClassDistrictAdmin = {
    to: "/district-admin/class",
    name: "Class",
    icon: "users",
    dataComment: "link-class",
};

const routeStudent = {
    to: "/student",
    name: "Student",
    icon: "graph",
    dataComment: "link-student",
};

const routeSearch = {
    to: "/search",
    name: "Search",
    icon: "search",
    dataComment: "link-search",
};

/* ---- */

const studentDefaultSections = [
    routeDailyJuices,
    routeAssignments,
    routeSearch,
];

const homeStudentSections = [...studentDefaultSections];
const freeStudentSections = [...studentDefaultSections];
const regularStudentSections = [...studentDefaultSections];

/* ---- */

const sectionsByRoles = {
    home: {
        student: homeStudentSections,
        child: homeStudentSections,
        guardian: [
            routeDailyJuices,
            routeStudent,
            routeSearch,
        ],
        teacher: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeResources,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
    },
    trial: {
        student: [routeDailyJuices],
        child: [routeDailyJuices],
    },
    free: {
        student: freeStudentSections,
        child: freeStudentSections,
        teacher: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeResources,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
        parent: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeResources,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
    },
    regular: {
        student: regularStudentSections,
        child: regularStudentSections,
        guardian: [
            routeDailyJuices,
            routeStudent,
            routeSearch,
        ],
        teacher: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeResources,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
        districtAdmin: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeResources,
            routeDashboardDistrictAdmin,
            routeClassDistrictAdmin,
            routeSearch,
        ],
        admin: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeDashboardAdmin,
            routeClassAdmin,
            routeSearch,
        ],
        parent: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeResources,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
    },
};

export default sectionsByRoles;
