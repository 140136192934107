const start = (tickFunction) => {
    const minutes = 1;
    const ms = minutes * 1000 * 60;

    if (!window.Worker) {
        window.setInterval(tickFunction, ms);
        return;
    }

    const blob = new Blob([ // eslint-disable-line compat/compat
        `
        const minutes = 1;

        self.addEventListener("message", (evt) => {
            if (evt.data === "start") {
                setInterval(() => {
                    self.postMessage('tick');
                }, ${ms});
            }
        });
        `,
    ], {
        type: "text/javascript",
    });

    const worker = new Worker(window.URL.createObjectURL(blob)); // eslint-disable-line compat/compat, max-len
    worker.postMessage("start");

    worker.addEventListener("message", tickFunction);
};

export default {
    start,
};
