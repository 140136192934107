// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GcyMYsAIMPgk1j2anRm0 {\n    display: grid;\n    padding: 0 0 2rem 0;\n\n    transition: padding-top 500ms linear 0ms;\n}\n\n.vj06NyI_hRc7XxnvhVO4 {\n    padding-right: 2rem;\n    padding-left: 2rem;\n}\n\n@media only screen and (min-width: 901px) {\n    .GcyMYsAIMPgk1j2anRm0 {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/layout-content/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".content {\n    display: grid;\n    padding: 0 0 2rem 0;\n\n    transition: padding-top 500ms linear 0ms;\n}\n\n.contentWithSidePadding {\n    padding-right: 2rem;\n    padding-left: 2rem;\n}\n\n@media only screen and (min-width: 901px) {\n    .content {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "GcyMYsAIMPgk1j2anRm0",
	"contentWithSidePadding": "vj06NyI_hRc7XxnvhVO4"
};
export default ___CSS_LOADER_EXPORT___;
