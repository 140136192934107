import { useState } from "react";


const getPopupDefaultState = () => ({
    isOpen: false,
    isLoading: false,
    isSaving: false,
    isSubmitted: false,
    data: {},
    message: "",
    error: "",
});

const usePopup = () => {
    const [state, setState] = useState(getPopupDefaultState());

    const setData = (data) => {
        setState((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                ...data,
            },
        }));
    };

    const open = () => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            isLoading: true,
        }));
    };

    const setIsLoaded = () => {
        setState((prev) => ({
            ...prev,
            isLoading: false,
        }));
    };

    const setIsSaving = () => {
        setState((prev) => ({
            ...prev,
            isSaving: true,
            isSubmitted: true,
            message: "",
            error: "",
        }));
    };

    const setMessage = (message) => {
        setState((prev) => ({
            ...prev,
            isSaving: false,
            message,
        }));
    };

    const setError = (error) => {
        setState((prev) => ({
            ...prev,
            isSaving: false,
            error,
        }));
    };

    const close = () => {
        setState(getPopupDefaultState());
    };

    return {
        state,
        setData,
        open,
        setIsLoaded,
        setIsSaving,
        setMessage,
        setError,
        close,
    };
};

export default usePopup;
