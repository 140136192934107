import React from "react";

import array from "@/base/lib/array.js";
import classNames from "@/base/lib/class-names.js";

import CheckInCircle from "@/base/icons/check-in-circle/index.js";
import Clock from "@/base/icons/clock/index.js";

import styles from "./styles.module.css";


const JUICE_STATUSES = [
    { id: 0, title: "Unopened" },
    { id: 1, title: "In Progress" },
    { id: 2, title: "Complete" },
    { id: 3, title: "Opened" },
    { id: 6, title: "Missing Assignments" },
    { id: 7, title: "All complete" },
];

const JuiceStatus = ({ statusId }) => {
    if (statusId === 4) {
        return (
            <CheckInCircle
                className={styles.statusIcon}
                isGreen
            />
        );
    }

    if (statusId === 5) {
        return (
            <Clock
                className={styles.statusIcon}
            />
        );
    }

    /* ------ */

    const renderTitle = () => {
        const status = array.findOneById(JUICE_STATUSES, statusId);

        return status?.title || "-";
    };

    /* ------ */

    const statusClassName = classNames({
        [styles.statusRed]: statusId === 0 || statusId === 6,
        [styles.statusYellow]: statusId === 1,
        [styles.statusGreen]: statusId === 2 || statusId === 7,
        [styles.statusSky]: statusId === 3,
    });

    return (
        <div className={statusClassName}>
            {renderTitle()}
        </div>
    );
};

JuiceStatus.defaultProps = {
    statusId: null,
};

export default JuiceStatus;
