import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isJuicesLoaded: false,
    isJuicesLoading: false,

    juicesPage: 0,
    juicesHasMorePages: false,

    juices: [],
    juicesError: "",

    juicesById: {},

    lastVisitedJuice: null,

    isJuicesDatesLoaded: false,
    juicesDates: [],

    studentJuicesById: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_JUICES: {
            const {
                hasMorePages,
                juices,
                error,
            } = action.payload;

            const newJuices = [...state.juices].concat(juices);

            return {
                ...state,
                isJuicesLoaded: true,
                isJuicesLoading: false,
                juicesHasMorePages: hasMorePages,
                juices: newJuices,
                juicesPage: state.juicesPage + 1,
                juicesError: error,
            };
        }

        case types.SET_JUICES_LOADING: {
            return {
                ...state,
                isJuicesLoading: true,
                juicesError: "",
            };
        }

        case types.SET_LAST_VISITED_JUICE: {
            return {
                ...state,
                lastVisitedJuice: action.payload.lastVisitedJuice || null,
            };
        }

        case types.CLEAR_JUICES: {
            return {
                ...state,
                isJuicesLoaded: false,
                juices: [],
                juicesError: "",

                juicesPage: 0,
                juicesHasMorePages: false,
            };
        }

        /* --- */

        case types.SET_JUICES_DATES: {
            return {
                ...state,
                isJuicesDatesLoaded: true,
                juicesDates: action.payload.dates,
            };
        }

        case types.SET_JUICES_DATES_LOADED: {
            return {
                ...state,
                isJuicesDatesLoaded: action.payload.isLoaded,
            };
        }

        case types.CLEAR_JUICES_DATES: {
            return {
                ...state,
                isJuicesDatesLoaded: false,
                juicesDates: [],
            };
        }

        /* --- */

        case types.SET_JUICE: {
            const { juice } = action.payload;

            const { juicesById } = state;

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juice.id]: juice,
                },
            };
        }

        case types.CLEAR_JUICE: {
            const { juiceId } = action.payload;

            const { juicesById } = state;

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juiceId]: undefined,
                },
            };
        }

        /* --- */

        case types.SET_JUICE_QUESTION: {
            const { juiceId, quizId, question } = action.payload;
            const { juicesById } = state;

            const juice = juicesById[juiceId];

            if (!juice || !juice.stories || !juice.stories.juice) {
                return state;
            }

            juice.stories.juice = juice.stories.juice.map((j) => {
                const quizzes = (j.quizzes || []).map((q) => {
                    if (q.quizId === quizId && q.id === question.id) {
                        return {
                            ...q,
                            ...question,
                        };
                    }

                    return q;
                });

                return {
                    ...j,
                    quizzes,
                };
            });

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juice.id]: juice,
                },
            };
        }

        case types.SET_JUICE_QUESTION_ANSWER: {
            const {
                juiceId,
                quizId,
                questionId,
                answerId,
            } = action.payload;

            const { juicesById } = state;

            const juice = juicesById[juiceId];

            if (!juice || !juice.stories || !juice.stories.juice) {
                return state;
            }

            juice.stories.juice = juice.stories.juice.map((j) => {
                const quizzes = (j.quizzes || []).map((q) => {
                    if (q.quizId !== quizId || q.id !== questionId) {
                        return q;
                    }

                    const answers = (q.answers || []).map((ans, ansId) => {
                        return {
                            ...ans,
                            is_user_choice: ansId === answerId - 1,
                        };
                    });

                    return {
                        ...q,
                        answers,
                    };
                });

                return {
                    ...j,
                    quizzes,
                };
            });

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juice.id]: {
                        ...juice,
                    },
                },
            };
        }

        /* --- */

        case types.SET_JUICE_POLL_LOADING: {
            const { juiceId, storyId } = action.payload;
            const { juicesById } = state;

            const newJuice = juicesById[juiceId];

            for (let i = 0; i < newJuice.stories.juice.length; i += 1) {
                const s = newJuice.stories.juice[i];

                if (s.ID === storyId) {
                    if (newJuice?.stories?.juice?.[i]?.polls?.[0]) {
                        newJuice.stories.juice[i].polls[0] = {
                            ...newJuice.stories.juice[i].polls[0],
                            isLoading: true,
                        };
                    }

                    break;
                }
            }

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juiceId]: newJuice,
                },
            };
        }

        case types.SET_JUICE_POLL_ANSWERED: {
            const { juiceId, storyId, answerId } = action.payload;
            const { juicesById } = state;

            const newJuice = juicesById[juiceId];

            for (let i = 0; i < newJuice.stories.juice.length; i += 1) {
                const s = newJuice.stories.juice[i];

                if (s.ID === storyId) {
                    if (newJuice?.stories?.juice?.[i]?.polls?.[0]) {
                        newJuice.stories.juice[i].polls[0] = {
                            ...newJuice.stories.juice[i].polls[0],
                            isLoading: false,
                            userAnswerId: answerId,
                        };
                    }

                    break;
                }
            }

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juiceId]: newJuice,
                },
            };
        }

        case types.SET_JUICE_POLL_LOADED: {
            const { juiceId, storyId } = action.payload;
            const { juicesById } = state;

            const newJuice = juicesById[juiceId];

            for (let i = 0; i < newJuice.stories.juice.length; i += 1) {
                const s = newJuice.stories.juice[i];

                if (s.ID === storyId) {
                    const prevPoll = newJuice?.stories?.juice?.[i]?.polls?.[0] || null;

                    if (prevPoll) {
                        newJuice.stories.juice[i].polls[0] = {
                            ...prevPoll,
                            isLoading: false,
                        };
                    }

                    break;
                }
            }

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juiceId]: newJuice,
                },
            };
        }

        case types.SET_JUICE_POLL_ERROR: {
            const { juiceId, storyId, error } = action.payload;
            const { juicesById } = state;

            const newJuice = juicesById[juiceId];

            for (let i = 0; i < newJuice.stories.juice.length; i += 1) {
                const s = newJuice.stories.juice[i];

                if (s.ID === storyId) {
                    if (newJuice?.stories?.juice?.[i]?.polls?.[0]) {
                        newJuice.stories.juice[i].polls[0] = {
                            ...newJuice.stories.juice[i].polls[0],
                            isLoading: false,
                            error,
                        };
                    }

                    break;
                }
            }

            return {
                ...state,
                juicesById: {
                    ...juicesById,
                    [juiceId]: newJuice,
                },
            };
        }

        /* --- */

        case types.SET_STUDENT_JUICES_BY_ID: {
            let prevStudentJuices = [];

            if (state?.studentJuicesById[action.payload.studentId]?.juices) {
                prevStudentJuices = state.studentJuicesById[action.payload.studentId].juices;
            }

            const newStudentJuices = {
                juices: [
                    ...prevStudentJuices,
                    ...action.payload.juices,
                ],
                hasMore: action.payload.hasMore || false,
                page: action.payload.page,
                isLoading: false,
            };

            return {
                ...state,
                studentJuicesById: {
                    ...state.studentJuicesById,
                    [action.payload.studentId]: newStudentJuices,
                },
            };
        }

        case types.SET_STUDENT_JUICES_BY_ID_LOADING: {
            const prevStudentJuices = state.studentJuicesById[action.payload.studentId] || {};

            const newStudentJuices = {
                ...prevStudentJuices,
                isLoading: true,
            };

            return {
                ...state,
                studentJuicesById: {
                    ...state.studentJuicesById,
                    [action.payload.studentId]: newStudentJuices,
                },
            };
        }

        case types.CLEAR_STUDENT_JUICES_BY: {
            return {
                ...state,
                studentJuicesById: {
                    ...state.studentJuicesById,
                    [action.payload.studentId]: {},
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
