import React from "react";

import classNames from "@/base/lib/class-names.js";

import FivesyLetter from "@/base/components/fivesy-letter/index.js";

import styles from "./styles.module.css";


const FivesyBoard = (props) => {
    const renderPreviousWord = (attemptIndex) => {
        const wordLetters = props.prevWords[attemptIndex] || [];
        const letters = [];

        const isLast = attemptIndex === props.prevWords.length - 1;

        for (let i = 0; i < props.wordLength; i += 1) {
            const ltr = wordLetters[i] || null;

            let elem = null;

            if (ltr) {
                const isAbsent = !ltr.isPresent && !ltr.isCorrect;
                const isFlipping = isLast && props.isFlipping;

                elem = (
                    <FivesyLetter
                        index={i}
                        value={ltr.value}
                        isAbsent={isAbsent}
                        isPresent={ltr.isPresent}
                        isCorrect={ltr.isCorrect}
                        isFlipping={isFlipping}
                    />
                );
            } else {
                elem = (
                    <FivesyLetter
                        index={i}
                    />
                );
            }

            letters.push(elem);
        }

        return (
            <div className={styles.word}>
                {letters}
            </div>
        );
    };

    const renderCurrentWord = () => {
        const letters = [];

        for (let i = 0; i < props.wordLength; i += 1) {
            const ltr = props.currWordLetters[i] || null;

            if (ltr) {
                letters.push(<FivesyLetter index={i} value={ltr.value} />);
            } else {
                letters.push(<FivesyLetter index={i} />);
            }
        }

        const wordClassName = classNames({
            [styles.word]: true,
            [styles.wordWithError]: props.isError,
        });

        return (
            <div className={wordClassName}>
                {letters}
            </div>
        );
    };

    const renderNextWord = () => {
        const letters = [];

        for (let i = 0; i < props.wordLength; i += 1) {
            letters.push(<FivesyLetter index={i} value="" />);
        }

        return (
            <div className={styles.word}>
                {letters}
            </div>
        );
    };

    const renderWords = () => {
        const words = [];

        const currentAttempt = props.prevWords.length;

        for (let i = 0; i < props.maxNumberOfAttempts; i += 1) {
            if (currentAttempt > i) {
                words.push(renderPreviousWord(i));
            } else if (currentAttempt === i) {
                words.push(renderCurrentWord());
            } else {
                words.push(renderNextWord());
            }
        }

        return words;
    };

    return (
        <div className={styles.words}>
            {renderWords()}
        </div>
    );
};

FivesyBoard.defaultProps = {
    wordLength: 0,
    maxNumberOfAttempts: 0,
    prevWords: [],
    currWordLetters: [],
    isFlipping: false,
    isError: false,
};

export default FivesyBoard;
