import { useState } from "react";


const getPaymentSubscriptionState = () => ({
    isCardLoaded: false,
    card: {},

    isPriceLoaded: false,
    price: null,

    subscriptionDates: {},
    isAutoRenew: false,

    error: "",
});

const usePaymentSubscription = () => {
    const [state, setState] = useState(getPaymentSubscriptionState());

    const reset = () => {
        setState(() => getPaymentSubscriptionState());
    };

    const setData = (data) => {
        setState((prev) => ({
            ...prev,
            ...data,
        }));
    };

    const setError = (error) => {
        setState((prev) => ({
            ...prev,
            error,
        }));
    };

    return {
        state,
        reset,
        setData,
        setError,
    };
};

export default usePaymentSubscription;
