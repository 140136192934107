import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const LayoutContent = (props) => {
    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentWithSidePadding]: props.withSidePadding,
    });

    return (
        <div
            data-comment={props.dataComment}
            className={contentClassName}
        >
            {props.children}
        </div>
    );
};

LayoutContent.defaultProps = {
    dataComment: "",
    withSidePadding: true,
    children: null,
};

export default LayoutContent;
