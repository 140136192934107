import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    districtsAll: {},
    districtsByKeyword: {},
    schoolsByDistrictId: {},
    districtsById: {},
    selectedDistrictId: null,
    selectedSchoolId: null,
    selectedDepartmentId: null,
    selectedClassId: null,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_ADMIN_DISTRICTS_ALL_LOADING: {
            return {
                ...state,
                districtsAll: {
                    ...state.districtsAll,
                    error: "",
                    isLoading: true,
                    isLoaded: false,
                },
            };
        }

        case types.SET_ADMIN_DISTRICTS_ALL: {
            const { error, data, hasMore } = action.payload;

            const districtsAll = { ...state.districtsAll };

            const prevData = districtsAll?.data || [];
            let page = districtsAll?.page || 0;

            if (!error) {
                page += 1;
            }

            return {
                ...state,
                districtsAll: {
                    data: [...prevData].concat(data),
                    error,
                    page,
                    hasMore,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        case types.SET_ADMIN_DISTRICTS_BY_KEYWORD_LOADING: {
            const { search } = action.payload;

            const districtsByKeyword = { ...state.districtsByKeyword };

            districtsByKeyword[search] = {
                ...districtsByKeyword[search],
                error: "",
                isLoading: true,
                isLoaded: false,
            };

            return {
                ...state,
                districtsByKeyword,
            };
        }

        case types.SET_ADMIN_DISTRICTS_BY_KEYWORD: {
            const {
                search,
                error,
                data,
                hasMore,
            } = action.payload;

            const districtsByKeyword = { ...state.districtsByKeyword };

            const districtsBySearch = districtsByKeyword?.[search] || {};
            const prevData = districtsBySearch?.data || [];

            let page = districtsBySearch?.page || 0;

            if (!error) {
                page += 1;
            }

            districtsByKeyword[search] = {
                ...districtsBySearch,
                data: [...prevData].concat(data),
                error,
                page,
                hasMore,
                isLoading: false,
                isLoaded: true,
            };

            return {
                ...state,
                districtsByKeyword,
            };
        }

        case types.CLEAR_ADMIN_DISTRICTS: {
            return {
                ...state,
                districtsByKeyword: {},
                districtsAll: {},
            };
        }

        /* --- */

        case types.SET_ADMIN_SELECTED_DISTRICT: {
            const { selectedDistrictId } = action.payload;

            return {
                ...state,
                selectedDistrictId,
                selectedSchoolId: null,
                selectedDepartmentId: null,
                selectedClassId: null,
            };
        }

        case types.SET_ADMIN_SELECTED_SCHOOL: {
            const { selectedSchoolId } = action.payload;

            return {
                ...state,
                selectedSchoolId,
                selectedDepartmentId: null,
                selectedClassId: null,
            };
        }

        case types.SET_ADMIN_SELECTED_DEPARTMENT: {
            const { selectedDepartmentId } = action.payload;

            return {
                ...state,
                selectedDepartmentId,
                selectedClassId: null,
            };
        }

        case types.SET_ADMIN_SELECTED_CLASS: {
            const { selectedClassId } = action.payload;

            return {
                ...state,
                selectedClassId,
            };
        }

        /* --- */

        case types.SET_ADMIN_SCHOOL_BY_DISTRICT_ID_LOADING: {
            const { districtId } = action.payload;

            return {
                ...state,
                schoolsByDistrictId: {
                    ...state.schoolsByDistrictId,
                    [districtId]: {
                        data: [],
                        error: "",
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_ADMIN_SCHOOL_BY_DISTRICT_ID: {
            const { data, error, districtId } = action.payload;

            return {
                ...state,
                schoolsByDistrictId: {
                    ...state.schoolsByDistrictId,
                    [districtId]: {
                        data,
                        error,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case types.UPDATE_ADMIN_DISTRICT_BY_ID: {
            const {
                districtId,
                districtName,
                country,
                subdivision,
            } = action.payload;

            const districtsAll = { ...state.districtsAll };
            const districtsByKeyword = { ...state.districtsByKeyword };
            const districtsById = { ...state.districtsById };

            const changedData = {
                name: districtName,
                country,
                subdivision,
            };

            if (districtsAll.data) {
                districtsAll.data = districtsAll.data.map((d) => {
                    if (d.id === districtId) {
                        return {
                            ...d,
                            ...changedData,
                        };
                    }

                    return d;
                });
            }

            const keys = Object.keys(districtsByKeyword);

            for (let i = 0; i < keys.length; i += 1) {
                const search = keys[i];
                const data = districtsByKeyword[search]?.data || [];

                districtsByKeyword[search].data = data.map((d) => {
                    if (d.id === districtId) {
                        return {
                            ...d,
                            ...changedData,
                        };
                    }

                    return d;
                });
            }

            if (districtsById?.[districtId]?.data) {
                districtsById[districtId] = {
                    ...districtsById[districtId],
                    data: {
                        ...districtsById[districtId].data,
                        ...changedData,
                    },
                };
            }

            return {
                ...state,
                districtsAll,
                districtsByKeyword,
                districtsById,
            };
        }

        /* --- */

        case types.SET_ADMIN_DISTRICT_BY_ID_LOADING: {
            const { districtId } = action.payload;

            return {
                ...state,
                districtsById: {
                    ...state.districtsById,
                    [districtId]: {
                        isLoading: true,
                        isLoaded: false,
                        data: null,
                        error: "",
                    },
                },
            };
        }

        case types.SET_ADMIN_DISTRICT_BY_ID: {
            const {
                districtId,
                data,
                error,
            } = action.payload;

            return {
                ...state,
                districtsById: {
                    ...state.districtsById,
                    [districtId]: {
                        isLoading: false,
                        isLoaded: true,
                        data,
                        error,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
