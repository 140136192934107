import React from "react";

import styles from "./styles.module.css";


const PlayerLoader = () => {
    return (
        <div className={styles.loader} />
    );
};

export default PlayerLoader;
