import React, { useEffect, useState } from "react";

import date from "@/base/lib/date.js";

import styles from "./styles.module.css";


const Countdown = (props) => {
    const [dateNow, setDateNow] = useState(() => {
        return new Date();
    });

    useEffect(() => {
        const secondsInMS = 1 * 1000;

        globalThis.setInterval(() => {
            setDateNow(new Date());
        }, secondsInMS);
    }, []);

    const renderPrefix = () => {
        if (!props.prefix) {
            return "";
        }

        return `${props.prefix} `;
    };

    const renderDate = () => {
        if (!props.endDate) {
            return `${renderPrefix()}00:00:00`;
        }

        if (dateNow > props.endDate) {
            return `${renderPrefix()}00:00:00`;
        }

        const diffInSeconds = date.getDiffSeconds(dateNow, props.endDate);

        const value = date.secondsToValues(diffInSeconds);

        const hours = value.hours > 9
            ? `${value.hours}`
            : `0${value.hours}`;

        const minutes = value.minutes > 9
            ? `${value.minutes}`
            : `0${value.minutes}`;

        const seconds = value.seconds > 9
            ? `${value.seconds}`
            : `0${value.seconds}`;

        return `${renderPrefix()}${hours}:${minutes}:${seconds}`;
    };

    return (
        <div className={styles.countdown}>
            {renderDate()}
        </div>
    );
};

Countdown.defaultProps = {
    prefix: "",
    endDate: null,
};

export default Countdown;
