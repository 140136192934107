import React from "react";

import styles from "./styles.module.css";


const ButtonLink = (props) => {
    return (
        <a
            className={styles.link}
            href={props.href}
            target="_blank"
            rel="noopener"
            data-comment={props.dataComment}
        >
            {props.title}
        </a>
    );
};

ButtonLink.defaultProps = {
    title: "",
    dataComment: "",
    href: "",
};

export default ButtonLink;
