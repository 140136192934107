import { useState } from "react";


const getPaymentDetailsState = () => ({
    clientSecret: null,
    intentId: null,
    paymentMethodId: null,

    error: "",
});

const usePaymentDetails = () => {
    const [state, setState] = useState(getPaymentDetailsState());

    const reset = () => {
        setState(() => getPaymentDetailsState());
    };

    const setData = (data) => {
        setState((prev) => ({
            ...prev,
            ...data,
        }));
    };

    const setError = (error) => {
        setState((prev) => ({
            ...prev,
            error,
        }));
    };

    return {
        state,
        reset,
        setData,
        setError,
    };
};

export default usePaymentDetails;
