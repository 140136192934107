const DEFAULT_ALPHA_2 = "US";
const DEFAULT_SECOND_ALPHA_2 = "CA";

/* --- */

const isCountryWithSubdivisions = (alpha2) => {
    return [
        DEFAULT_ALPHA_2,
        DEFAULT_SECOND_ALPHA_2,
    ].indexOf(alpha2) !== -1;
};

/* --- */

const getDefaultAlpha2 = () => {
    return DEFAULT_ALPHA_2;
};

const getDefaultCountry = (countries) => {
    const countriesNames = (countries || []).map((c) => c.value);

    if (countriesNames.indexOf(DEFAULT_ALPHA_2) === -1) {
        return "";
    }

    return DEFAULT_ALPHA_2;
};

/* --- */

const getCountryOptions = (countries) => {
    return (countries || []).map((country) => ({
        value: country.alpha2,
        name: country.name,
    }));
};

const getSubdivisionOptions = (subdivisions) => {
    return (subdivisions || []).map((div) => ({
        value: div.code,
        name: div.name,
        name2: div.code,
    })).concat({
        value: "Other",
        name: "Other",
    });
};

/* --- */

export default {
    DEFAULT_ALPHA_2,
    isCountryWithSubdivisions,
    getDefaultCountry,
    getDefaultAlpha2,
    getCountryOptions,
    getSubdivisionOptions,
};
