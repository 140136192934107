import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import IconZoomIn from "@/base/icons/zoom-in/index.js";
import IconZoomOut from "@/base/icons/zoom-out/index.js";
import ButtonTool from "@/base/components/button-tool/index.js";

import styles from "./styles.module.css";


const ImageWithZoom = (props) => {
    const renderComponent = ({ zoomIn, zoomOut }) => {
        const toolsClassName = [
            styles.tools,
            props.toolsClassName,
        ].join(" ");

        return (
            <div
                style={props.containerStyle}
                className={props.containerClassName}
                data-comment="image-with-zoom"
            >
                <TransformComponent>
                    <img
                        src={props.src}
                        alt={props.title}
                        title={props.title}
                        style={props.imageStyle}
                        className={props.className}
                    />
                </TransformComponent>

                <div className={toolsClassName}>
                    <ButtonTool onClick={() => { zoomIn(); }}>
                        <IconZoomIn isBlack />
                    </ButtonTool>

                    <ButtonTool onClick={() => { zoomOut(); }}>
                        <IconZoomOut isBlack />
                    </ButtonTool>
                </div>
            </div>
        );
    };

    return (
        <TransformWrapper
            zoomIn={{
                step: props.buttonZoomLevel,
            }}
            zoomOut={{
                step: props.buttonZoomLevel,
            }}
        >
            {renderComponent}
        </TransformWrapper>
    );
};

ImageWithZoom.defaultProps = {
    src: "",
    title: "",
    className: "",
    toolsClassName: "",
    containerStyle: {},
    imageStyle: {},
    containerClassName: "",
    buttonZoomLevel: 10,
};

export default ImageWithZoom;
