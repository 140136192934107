import { useState, useEffect } from "react";

import { getDimensions } from "@/base/lib/dimensions.js";


const useDimensions = () => {
    const [dimensions, setDimensions] = useState(() => getDimensions());

    useEffect(() => {
        let timeoutId = null;

        const onChange = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setDimensions(getDimensions());
            }, 150);
        };

        window.addEventListener("resize", onChange);
        window.addEventListener("orientationchange", onChange);

        return () => {
            window.removeEventListener("resize", onChange);
            window.removeEventListener("orientationchange", onChange);
        };
    }, []);

    return dimensions;
};

export default useDimensions;
