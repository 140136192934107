export default (apiURL, methods) => {
    return {
        getJobById(params = {}) {
            // NOTE: action=job_get_status_by_id
            const url = `${apiURL}/jobs/${params.jobId}`;

            return methods.get(url, {
                session_id: params.session,
            });
        },
    };
};
