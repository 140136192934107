export const SET_COUNTRIES = "SET_COUNTRIES";

export const SET_SUBDIVISIONS_BY_APLHA2 = "SET_SUBDIVISIONS_BY_APLHA2";
export const SET_SUBDIVISIONS_BY_APLHA2_LOADING = "SET_SUBDIVISIONS_BY_APLHA2_LOADING";

export const SET_DISTRICTS_BY_SUBDIVISION = "SET_DISTRICTS_BY_SUBDIVISION";

export const SET_CITIES_BY_SUBDIVISION = "SET_CITIES_BY_SUBDIVISION";

export const SET_SCHOOLS_BY_CITY = "SET_SCHOOLS_BY_CITY";

export const SET_SCHOOLS_BY_DISTRICT_ID = "SET_SCHOOLS_BY_DISTRICT_ID";
