import storages from "@/base/lib/storage/storages.js";

/* --- */

const keys = {
    fivesyHowTo: "fivesyHowTo",
    fivesyTime: "fivesyTime",
    fivesyState: "fivesyState",
    fivesyStats: "fivesyStats",
};

/* --- */

const getFivesyStorage = (isRememberMe = true) => {
    const storage = isRememberMe
        ? storages.localStorage
        : storages.sessionStorage;

    return {
        saveHowTo() {
            if (!storage) {
                return;
            }

            storage.setItem(keys.fivesyHowTo, true);
        },

        loadHowTo() {
            if (!storage) {
                return false;
            }

            const value = storage.getItem(keys.fivesyHowTo);

            try {
                return JSON.parse(value) || false;
            } catch {
                return false;
            }
        },

        loadTime() {
            if (!storage) {
                return null;
            }

            const value = storage.getItem(keys.fivesyTime);

            try {
                return JSON.parse(value) || null;
            } catch {
                return null;
            }
        },

        saveTime(state) {
            if (!storage) {
                return;
            }

            const value = JSON.stringify(state);
            storage.setItem(keys.fivesyTime, value);
        },

        saveState(state) {
            if (!storage) {
                return;
            }

            const value = JSON.stringify(state);
            storage.setItem(keys.fivesyState, value);
        },

        loadState() {
            if (!storage) {
                return {};
            }

            const value = storage.getItem(keys.fivesyState);

            try {
                return JSON.parse(value) || {};
            } catch {
                return {};
            }
        },

        saveStats(stats) {
            if (!storage) {
                return;
            }

            const value = JSON.stringify(stats);
            storage.setItem(keys.fivesyStats, value);
        },

        loadStats() {
            if (!storage) {
                return {};
            }

            const value = storage.getItem(keys.fivesyStats);

            try {
                return JSON.parse(value) || {};
            } catch {
                return {};
            }
        },
    };
};

export default {
    getFivesyStorage,
};
