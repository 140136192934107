// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g3LHlxYWjF5S8PhvF0SW {\n    width: 3.6rem;\n    height: 3.6rem;\n\n    margin-left: 0.4rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    background-color: var(--button-tool-bg-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.8rem;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-tool/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,mBAAmB;;IAEnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,6CAA6C;;IAE7C,gDAAgD;IAChD,qBAAqB;;IAErB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".button {\n    width: 3.6rem;\n    height: 3.6rem;\n\n    margin-left: 0.4rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    background-color: var(--button-tool-bg-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.8rem;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "g3LHlxYWjF5S8PhvF0SW"
};
export default ___CSS_LOADER_EXPORT___;
