import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grades from "@/base/project/grades.js";
import Standards from "@/base/project/standards.js";
import DailyJuices from "@/base/project/daily-juices.js";
import Country from "@/base/project/country.js";

import storage from "@/base/lib/storage/index.js";

import urls from "@/base/lib/urls.js";
import date from "@/base/lib/date.js";
import array from "@/base/lib/array.js";

import text from "@/base/text/index.js";

import actions from "@/base/store/actions.js";
import siteActions from "@/base/actions/site.js";
import standardsActions from "@/base/actions/standards.js";
import teacherActions from "@/base/actions/teacher.js";
import studentActions from "@/base/actions/student.js";

import usePopup from "@/base/hooks/use-popup/index.js";
import useValuePopup from "@/base/hooks/use-value-popup/index.js";

import PopupNameChange from "@/base/business/popup-name-change/index.js";
import PopupEmailChange from "@/base/business/popup-email-change/index.js";
import PopupGradeChange from "@/base/business/popup-grade-change/index.js";
import PopupNewPassword from "@/base/business/popup-new-password/index.js";
import PopupConfirmStandardType from "@/base/business/popup-confirm-standard-type/index.js";
import PopupStudentInfo from "@/base/business/popup-student-info/index.js";
import PopupQuiz from "@/base/business/popup-quiz/index.js";
import PopupLearningStandards from "@/base/business/popup-learning-standards/index.js";

import PopupStory from "@/app/containers/popup-story/index.js";

import app from "@/app/app.js";
import api from "@/app/api.js";
import events from "@/app/events.js";
import settings from "@/app/settings.js";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    info: state.info,
    dimensions: state.device.dimensions,
    studentsNotifications: state.notifications.studentsNotifications,
    stateStandardsByState: state.standards.stateStandardsByState,
    quizPerformancesByStudentId: state.teacherStudents.quizPerformancesByStudentId,
    assignmentsByStudentId: state.teacherStudentsAssignments.assignmentsByStudentId,
    dailyJuicesByStudentId: state.teacherStudents.dailyJuices,
    classes: state.teacher.classes,
    selectedClassId: state.teacher.selectedClassId,
    juices: state.juices,
    siteDate: state.info.siteDate,
});

const TeacherPopupStudentInfo = (props) => {
    const namePopup = useValuePopup();
    const emailPopup = useValuePopup();
    const gradePopup = useValuePopup();
    const passwordPopup = usePopup();

    const [storyPopup, setStoryPopup] = useState(-1);

    const [juiceDates, setJuiceDates] = useState({
        dates: [],
        error: "",
        isLoaded: false,
    });

    const [quizPerformanceRange, setQuizPerformanceRange] = useState("");
    // NOTE: Used props.dateFrom as init value, but asked to remove(juic-1106)
    const [quizPerformanceDefaultDate, setQuizPerformanceDefaultDate] = useState(null);

    const [standardTypePopup, setStandardTypePopup] = useState({
        isVisible: false,
        standardType: "",
    });

    const [quizPopup, setQuizPopup] = useState({
        isVisible: false,
        quiz: null,
    });

    const [standardsPopup, setStandardsPopup] = useState({
        isVisible: false,
        standardId: null,
        standards: [],
    });

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isLMS = settings.features.LMS && store.user.isLmsUser;
    const isMobile1300 = store.dimensions.width < 1300;

    const classNotifications = store.studentsNotifications[props.classId] || [];
    const hasAccountEmailNotification = classNotifications.indexOf(props.studentId) !== -1;
    const emailNotification = hasAccountEmailNotification
        ? text.userInvalidEmail
        : "";

    let withCommonCore = true;

    if (settings.features.COMMON_CORE_FILTER && !store.user.showCommonCoreStandards) {
        withCommonCore = false;
    }

    /* --- */

    const isEditGradeEnabled = () => {
        const { classes, selectedClassId } = store;
        const selectedClass = array.findOneById(classes, selectedClassId);

        return selectedClass?.isGradeChangingAllowed || false;
    };

    /* --- */

    const getQuizPerformanceData = () => {
        const studentQuizPerfs = store.quizPerformancesByStudentId[props.studentId] || {};

        const data = studentQuizPerfs[quizPerformanceRange] || {
            isLoading: false,
            data: {},
        };

        return data;
    };

    const setQuizPerformanceLoading = (range, forceUpdate) => {
        const studentQuizPerfs = store.quizPerformancesByStudentId[props.studentId] || {};

        const data = studentQuizPerfs[range];

        if (!data || forceUpdate) {
            dispatch(actions.teacherStudents.setStudentQuizPerformanceLoadingById({
                studentId: props.studentId,
                range,
            }));
        }
    };

    /* --- */

    const onLoadStudentDailyJuices = async (studentId, isBefore, isInit) => {
        let d = null;

        const dailyJuices = store.dailyJuicesByStudentId?.[studentId]?.dailyJuices || [];

        if (isInit || dailyJuices.length === 0) {
            d = props.dateTo || store.siteDate;

            if (d) {
                d = date.getDateFromDate(date.addDays(date.newDateUTC(d), 1));
            }
        } else {
            d = isBefore
                ? dailyJuices[dailyJuices.length - 1]?.juiceDate
                : dailyJuices[0].juiceDate;
        }

        if (!d) {
            return;
        }

        dispatch(studentActions.loadDailyJuicesResults(
            { api, actions },
            {
                session: store.session,
                studentId,
                isBefore,
                date: d,
                onLoad: (res) => {
                    if (isInit
                        && res.ok
                        && res.juices?.length === 0
                        && res.hasMoreBefore) {
                        onLoadStudentDailyJuices(studentId, true, false);
                    }
                },
            },
        ));
    };

    /* --- */

    const getDailyJuiceData = () => {
        const data = store.dailyJuicesByStudentId?.[props.studentId] || {};

        return {
            dailyJuices: data?.dailyJuices || [],
            errorBefore: data?.errorBefore || "",
            errorAfter: data?.errorAfter || "",
            isLoadingBefore: data?.isLoadingBefore || false,
            isLoadingAfter: data?.isLoadingAfter || false,
            isFirstLoaded: data?.isFirstLoaded || false,
            isLastLoaded: data?.isLastLoaded || false,
        };
    };

    /* --- */

    const getAssignmentsData = () => {
        const data = store.assignmentsByStudentId?.[props.studentId]?.[props.classId] || {};

        return {
            stateStandards: store.stateStandardsByState[store.user.subdivision],
            assignments: data.data || [],
            error: data.error || "",
            page: data.page || 0,
            hasMore: data.hasMore || false,
            isLoading: data.isLoading || false,
        };
    };

    const onLoadStudentAssignments = async (studentId) => {
        if (!studentId) {
            return;
        }

        const { classId } = props;

        if (settings.features.MULTIPLE_CLASSES) {
            app.actions.teacher.studentsAssignments.loadStudentAssignmentsByClassId({
                classId,
                studentId,
            });
            return;
        }

        dispatch(actions.teacherStudentsAssignments.setStudentAssignmentsByClassIdLoading({
            studentId,
            classId,
        }));

        const res = await api.students.getStudentAssignments({
            session: store.session,
            classId,
            studentId,
            page: store.assignmentsByStudentId?.[studentId]?.[classId]?.page || 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments;
            hasMore = res.hasMore;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacherStudentsAssignments.setStudentAssignmentsByClassId({
            classId,
            studentId,
            data,
            error,
            hasMore,
        }));
    };

    /* --- */

    const onOpenStandardTypePopup = (standardType) => {
        setStandardTypePopup((prev) => ({
            ...prev,
            isVisible: true,
            standardType,
        }));
    };

    const onCloseStandardTypePopup = () => {
        setStandardTypePopup((prev) => ({
            ...prev,
            isVisible: false,
            standardType: "",
        }));
    };

    const onClosePopups = () => {
        namePopup.close();
        emailPopup.close();
        gradePopup.close();
    };

    /* --- */

    const onQuizPerformanceClear = () => {
        setQuizPerformanceRange("");
    };

    const onQuizPerformanceChange = async (values) => {
        setQuizPerformanceLoading(values.range, values.forceUpdate);

        const apiParams = {
            session: store.session,
            studentId: props.studentId,
            classId: props.classId,
            standardId: values.standardId,
            filterBy: values.filterBy,
        };

        if (values.dateFrom && values.dateTo) {
            apiParams.dateFrom = values.dateFrom;
            apiParams.dateTo = values.dateTo;
        } else if (values.range) {
            apiParams.range = values.range;
        }

        const perfResp = await api.students.getStudentQuizPerformance(apiParams);

        let perfData = {};

        if (perfResp.ok) {
            perfData = perfResp?.quizPerformance || {};
        }

        dispatch(actions.teacherStudents.setStudentQuizPerformanceById({
            studentId: props.studentId,
            range: values.range,
            data: perfData,
        }));
    };

    const onQuizPerformanceDateChange = (values = {}) => {
        const d = values.date;

        setQuizPerformanceDefaultDate(d);

        const dateValue = date.stringToDayDate(d);
        const range = `${dateValue}-${dateValue}`;

        setQuizPerformanceRange(range);

        onQuizPerformanceChange({
            forceUpdate: false,
            filterBy: "by-quiz",
            range,
            dateFrom: dateValue,
            dateTo: dateValue,
        });
    };

    const onQuizPerformanceDateRangeChange = (values = {}) => {
        setQuizPerformanceRange(values.range);

        onQuizPerformanceChange({
            forceUpdate: true,
            standardId: values.standardId,
            filterBy: "by-standard",
            range: values.range,
            dateFrom: values.dateFrom || "",
            dateTo: values.dateTo || "",
        });
    };

    /* --- */

    const onCloseStandardsPopup = () => {
        setStandardsPopup({
            isVisible: false,
            standardId: null,
            standards: [],
        });
    };

    const onOpenStandardsPopup = (standardId, standards) => {
        setStandardsPopup({
            isVisible: true,
            standardId,
            standards,
        });

        events.teacher.openAssignmentStandards({
            session: store.session,
            studentId: props.studentId,
        });
    };

    /* --- */

    const onOpenStoryPopup = (storyId) => {
        setStoryPopup(storyId);
    };

    const onAssignmentsOpenStoryPopup = (storyId) => {
        setStoryPopup(storyId);

        events.teacher.openAssignmentStory({
            session: store.session,
            storyId,
        });
    };

    const onCloseStoryPopup = () => {
        setStoryPopup(-1);
    };

    /* --- */

    const onCloseQuizPopup = () => {
        setQuizPopup({
            isVisible: false,
            quiz: null,
        });
    };

    const onAssignmentOpenQuizPopup = (quiz) => {
        setQuizPopup({
            isVisible: true,
            quiz,
        });

        events.teacher.openAssignmentQuiz({
            session: store.session,
            studentId: props.studentId,
            quizId: quiz?.quizId || 0,
        });
    };

    /* --- */

    const onAssignmentsSortByDate = () => {
        events.teacher.sortAssignmentsByDate({
            session: store.session,
            studentId: props.studentId,
        });
    };

    const onAssignmentsSortByScore = () => {
        events.teacher.sortAssignmentsByScore({
            session: store.session,
            studentId: props.studentId,
        });
    };

    const onAssignmentsSortByGrade = () => {
        events.teacher.sortAssignmentsByGrade({
            session: store.session,
            studentId: props.studentId,
        });
    };

    const onAssignmentsSortByStandard = () => {
        events.teacher.sortAssignmentsByStandards({
            session: store.session,
            studentId: props.studentId,
        });
    };

    /* --- */

    const loadJuicesDates = async () => {
        setJuiceDates((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        const res = await api.dailyJuices.getJuicesDates({
            session: store.session,
            studentId: props.studentId,
            classId: props.classId,
        });

        let dates = [];
        let error = "";

        if (res.ok) {
            dates = DailyJuices.sortJuiceDatesASC(res.juices || []);
        } else {
            error = res.error || text.error;
        }

        setJuiceDates((prev) => ({
            ...prev,
            error,
            dates,
            isLoaded: true,
        }));
    };

    const onUpdateStudentInfo = async (params) => {
        if (params.newFullname) {
            namePopup.setIsSaving();
        } else if (params.newEmail) {
            emailPopup.setIsSaving();
        } else if (params.newGrade) {
            gradePopup.setIsSaving();
        }

        const res = await api.students.updateStudentDataV2({
            session: store.session,
            studentId: props.studentId,

            fullname: params.newFullname || "",
            email: params.newEmail || "",
            grade: params.newGrade || "",
        });

        events.classes.editStudent({
            session: store.session,
            classId: props.classId,
        });

        if (!res.ok) {
            const error = res.error || text.error;

            if (params.newFullname) {
                namePopup.setError(error);
            } else if (params.newEmail) {
                emailPopup.setError(error);
            } else if (params.newGrade) {
                gradePopup.setError(error);
            }
            return;
        }

        onClosePopups();

        props.onUpdate();

        dispatch(teacherActions.loadAllNotifications({ api, storage, actions }, {
            session: store.session,
            classId: props.classId,
        }));
    };

    const onChangeStudentPassword = async (newPassword) => {
        passwordPopup.setIsSaving();

        const res = await api.students.setPassword({
            session: store.session,
            studentId: props.studentId,
            password: newPassword,
        });

        let message = "";

        if (res.ok) {
            if (res.isEmailSentToStudent) {
                message = text.passwordUpdated;
            } else {
                message = text.studentResetPassword;
            }

            passwordPopup.setMessage(message);
            props.onChangePassword(props.studentId);
        } else {
            message = res.error || text.error;
            passwordPopup.setError(message);
        }
    };

    /* --- */

    const onExportStudentDailyJuicesResults = (studentId) => {
        const dailyJuices = store.dailyJuicesByStudentId?.[studentId].dailyJuices || [];

        const reqParams = {
            session: store.session,
            studentId,
            dateFrom: dailyJuices?.[dailyJuices.length - 1]?.juiceDate || "",
            dateTo: dailyJuices?.[0]?.juiceDate || "",
        };

        events.teacher.exportStudentActivityReport({
            ...reqParams,
            userId: store.user.userId,
        });

        const exportUrl = api.export.getStudentDailyJuicesResultsExportUrl(reqParams);

        urls.universalDownload(exportUrl);
    };

    const onExportStudentPerformance = (params = {}) => {
        const range = params.range && params.range !== "custom"
            ? params.range
            : "";

        const reqParams = {
            session: store.session,
            studentId: params.studentId,
            filterBy: params.filterBy,
            standardType: params.standardType,
            standardId: "",
            range,
            dateFrom: params.dateFrom || "",
            dateTo: params.dateTo || "",
        };

        if (params.filterBy === "by-standard") {
            reqParams.standardId = params.standardId;
        }

        events.teacher.exportStudentPerformanceReport({
            ...reqParams,
            userId: store.user.userId,
        });

        const exportUrl = api.export.getStudentQuizPerformanceExportUrl(reqParams);

        urls.universalDownload(exportUrl);
    };

    const onExportAssignments = () => {
        if (!props.studentId) {
            return;
        }

        let { page } = getAssignmentsData();

        if (page > 0) {
            page -= 1;
        }

        const exportUrl = api.export.getAssignmentsByStudentId({
            session: store.session,
            studentId: props.studentId,
            classId: props.classId,
            page,
        });

        urls.universalDownload(exportUrl);

        events.teacher.exportAssignments({
            session: store.session,
            studentId: props.studentId,
        });
    };

    /* --- */

    const onLoadSiteDate = async () => {
        if (store.siteDate) {
            return;
        }

        dispatch(siteActions.loadSiteDate({
            api,
            actions,
        }));
    };

    /* --- */

    const onLoadStateStandards = async () => {
        if (!Country.isCountryUS(store.user.country) || !store.user.subdivision) {
            return;
        }

        dispatch(standardsActions.loadStateStandards(
            { api, actions },
            { subdivision: store.user.subdivision },
        ));
    };

    /* --- */

    useEffect(() => {
        onLoadSiteDate();
        onLoadStateStandards();

        return () => {
            dispatch(actions.teacherStudents.clearStudentQuizPerformances());
            dispatch(actions.teacherStudents.clearStudentDailyJuices());
            dispatch(actions.teacherStudents.clearStudentAssignments());
        };
    }, []);

    useEffect(() => {
        if (!store.siteDate || props.classId === -1) {
            return;
        }

        loadJuicesDates();

        if (!store.assignmentsByStudentId[props.studentId]?.[props.classId]) {
            onLoadStudentAssignments(props.studentId);
        }

        if (!store.dailyJuicesByStudentId?.[props.studentId]) {
            onLoadStudentDailyJuices(props.studentId, true, true);
        }
    }, [
        props.studentId,
        props.classId,
        store.siteDate,
    ]);

    /* --- */

    const renderNameChangePopup = () => {
        if (!namePopup.state.isOpen) {
            return null;
        }

        const defaultValue = props.student?.account?.fullName || "";

        return (
            <PopupNameChange
                defaultValue={defaultValue}
                isSaving={namePopup.state.isSaving}
                error={namePopup.state.error}
                onSave={(newFullname) => {
                    onUpdateStudentInfo({
                        newFullname,
                    });
                }}
                onClose={namePopup.close}
            />
        );
    };

    const renderEmailChangePopup = () => {
        if (!emailPopup.state.isOpen) {
            return null;
        }

        const defaultValue = props.student?.account?.email || "";

        return (
            <PopupEmailChange
                defaultValue={defaultValue}
                isSaving={emailPopup.state.isSaving}
                error={emailPopup.state.error}
                onSave={(newEmail) => {
                    onUpdateStudentInfo({
                        newEmail,
                    });
                }}
                onClose={emailPopup.close}
            />
        );
    };

    const renderGradeChangePopup = () => {
        if (!gradePopup.state.isOpen) {
            return null;
        }

        const studentGrade = props.student?.overview?.studentGrade;
        let defaultGrade = null;

        if (studentGrade) {
            defaultGrade = Grades.getGradeRange(studentGrade);
        }

        return (
            <PopupGradeChange
                defaultGrade={defaultGrade}
                grades={Grades.getGradesRanges()}
                isSaving={gradePopup.state.isSaving}
                error={gradePopup.state.error}
                onSave={(newGrades) => {
                    const sGrades = newGrades.split("-");

                    if (sGrades.length > 1) {
                        onUpdateStudentInfo({
                            newGrade: `g${sGrades[1]}`,
                        });
                    }
                }}
                onClose={gradePopup.close}
            />
        );
    };

    const renderPasswordPopup = () => {
        if (!passwordPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={passwordPopup.state.message || passwordPopup.state.error}
                isSaving={passwordPopup.state.isSaving}
                isSubmitted={passwordPopup.state.isSubmitted}
                hideScrollbar={false}
                onSave={onChangeStudentPassword}
                onClose={passwordPopup.close}
            />
        );
    };

    const renderQuizPopup = () => {
        const { isVisible, quiz } = quizPopup;

        if (!isVisible || !quiz) {
            return null;
        }

        const answers = (quiz.answers || []).map((q) => ({
            ...q,
            isCorrect: q.is_correct_answer,
            isUserChoice: q.is_user_choice,
        }));

        return (
            <PopupQuiz
                question={quiz.question || ""}
                answers={answers}
                standards={quiz.standards || []}
                onClose={onCloseQuizPopup}
            />
        );
    };

    const renderStandardTypePopup = () => {
        if (!standardTypePopup.isVisible) {
            return null;
        }

        const quizData = getQuizPerformanceData();
        const standards = quizData?.data?.standards || [];
        const stdType = Standards.findStandardType(standards, standardTypePopup.standardType);

        return (
            <PopupConfirmStandardType
                standardType={stdType}
                onClose={onCloseStandardTypePopup}
            />
        );
    };

    const renderStandardsPopup = () => {
        if (!standardsPopup.isVisible) {
            return null;
        }

        const standards = [...standardsPopup.standards];

        const stateStandard = Standards.getStandardById(
            store.stateStandardsByState[store.user.subdivision],
            standardsPopup.standardId,
        );

        if (stateStandard) {
            standards.push(stateStandard);
        }

        const withStateStandard = Country.isCountryUS(store.user.country)
            && stateStandard;

        const defaultType = Standards.getDefaultStandard(withCommonCore, withStateStandard);

        return (
            <PopupLearningStandards
                standards={standards}
                defaultStandardType={defaultType}
                onClose={onCloseStandardsPopup}
                withStateStandard={withStateStandard}
                withCommonCore={withCommonCore}
                hideScrollbar={false}
                isMobile={isMobile1300}
            />
        );
    };

    const renderStoryPopup = () => {
        if (storyPopup === -1) {
            return null;
        }

        return (
            <PopupStory
                storyId={storyPopup}
                onClose={onCloseStoryPopup}
            />
        );
    };

    /* --- */

    const studentProps = {
        id: props.studentId,
        data: props.student,
        onReload: props.onReloadStudent,
        error: props.studentError,
        onEditName: namePopup.open,
        onEditEmail: emailPopup.open,
        onEditGrade: gradePopup.open,
        onEditPassword: passwordPopup.open,
        onDeleteAccount: props.onRemoveStudent,
        isLoaded: props.isStudentLoaded,
        withEditGrade: isEditGradeEnabled(),
    };

    const quizPerformanceProps = {
        data: getQuizPerformanceData(),
        defaultDate: quizPerformanceDefaultDate,
        onDateChange: onQuizPerformanceDateChange,
        onDateRangeChange: onQuizPerformanceDateRangeChange,
        onStandardTypeClick: onOpenStandardTypePopup,
        onOpenStory: onOpenStoryPopup,
        onClear: onQuizPerformanceClear,
    };

    const assignmentsProps = {
        data: getAssignmentsData(),
        onOpenStory: onAssignmentsOpenStoryPopup,
        onOpenQuiz: onAssignmentOpenQuizPopup,
        onOpenStandards: onOpenStandardsPopup,
        onExport: onExportAssignments,
        onSortByDateAssigned: onAssignmentsSortByDate,
        onSortByGrade: onAssignmentsSortByScore,
        onSortByReadingLevel: onAssignmentsSortByGrade,
        onSortByStandard: onAssignmentsSortByStandard,
        onLoadMore: () => {
            onLoadStudentAssignments(props.studentId);
        },
        isMobile: isMobile1300,
    };

    const dailyJuicesProps = {
        data: getDailyJuiceData(),
        onLoadMoreBefore: () => {
            onLoadStudentDailyJuices(props.studentId, true, false);
        },
        onLoadMoreAfter: () => {
            onLoadStudentDailyJuices(props.studentId, false, false);
        },
        onExport: () => {
            onExportStudentDailyJuicesResults(props.studentId);
        },
    };

    const dailyJuiceDates = {
        ...juiceDates,
        onReload: loadJuicesDates,
    };

    return (
        <>
            {renderNameChangePopup()}
            {renderGradeChangePopup()}
            {renderEmailChangePopup()}
            {renderPasswordPopup()}
            {renderStandardTypePopup()}
            {renderQuizPopup()}
            {renderStoryPopup()}
            {renderStandardsPopup()}

            <PopupStudentInfo
                student={studentProps}
                emailNotification={emailNotification}
                quizPerformance={quizPerformanceProps}
                assignments={assignmentsProps}
                dailyJuice={dailyJuicesProps}
                siteDate={store.siteDate}
                studentsList={props.students}
                defaultSelectedMenu={props.defaultSelectedMenu}
                dailyJuiceDates={dailyJuiceDates}
                onMenuChange={props.onMenuChange}
                onExportStudentPerformance={onExportStudentPerformance}
                onSelectStudent={props.onSelectStudent}
                onClose={props.onClose}
                withStateStandard={Country.isCountryUS(store.user.country)}
                withCommonCore={withCommonCore}
                hideArrows={props.hideArrows}
                isMobile={props.isMobile}
                isCards={props.isMobile}
                isLMS={isLMS}
            />
        </>
    );
};

TeacherPopupStudentInfo.defaultProps = {
    classId: -1,
    defaultSelectedMenu: 0,

    /* --- */

    studentId: -1,
    student: null,
    studentError: "",
    onReloadStudent: () => { },
    isStudentLoaded: false,

    /* --- */

    dateFrom: null,
    dateTo: null,
    students: [],

    onMenuChange: () => { },
    onSelectStudent: () => { },
    onChangePassword: () => { },
    onRemoveStudent: () => { },
    onUpdate: () => { },
    onClose: () => { },

    isMobile: false,
    isMobile1300: false,
    hideArrows: false,
};

export default TeacherPopupStudentInfo;
