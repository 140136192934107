import { useState, useEffect } from "react";


import document from "@/base/lib/document.js";
import scroll from "@/base/lib/scroll.js";
import styleProperties from "@/base/lib/style-properties.js";


const getState = () => ({
    playerCurrentTime: 0,
    rate: 1,
    highlight: [],
    scroller: null,
    getPlayerCurrentTime: () => { },
    isPaused: false,
    isScrolling: false,
    isActive: false,
    isVisible: false,
});

const useText2SpeechHighlight = () => {
    const [state, setState] = useState(() => getState());

    /* --- */

    const isElementVisible = (element) => {
        const { offsetTop } = state.scroller;
        const { offsetBottom } = state.scroller;

        return document.isElementInViewport(element, offsetTop, offsetBottom);
    };

    /* --- */

    const setActive = () => {
        setState((prev) => ({
            ...prev,
            isActive: true,
        }));
    };

    const pause = () => {
        setState((prev) => ({
            ...prev,
            isPaused: true,
        }));
    };

    const resume = () => {
        if (!state.isVisible || !state.isPaused) {
            return;
        }

        setState((prev) => ({
            ...prev,
            isPaused: false,
        }));
    };

    const changeRate = (rate) => {
        setState((prev) => ({
            ...prev,
            rate,
        }));
    };

    const stop = () => {
        setState(getState());
    };

    /* --- */

    const updatePlayerCurrentTime = async () => {
        const {
            highlight,
            rate,
            getPlayerCurrentTime,
        } = state;

        const playerCurrentTime = getPlayerCurrentTime();

        let word = null;

        for (let i = 0; i < highlight.length; i += 1) {
            const hLight = highlight[i];

            if (hLight.time >= playerCurrentTime && state.playerCurrentTime !== hLight.time) {
                word = hLight;
                break;
            }
        }

        if (!word) {
            pause();
            return;
        }

        const timeout = (word.time - playerCurrentTime) / rate;

        globalThis.setTimeout(() => {
            setState((prev) => {
                if (prev.isPaused || !prev.isVisible) {
                    return prev;
                }

                return {
                    ...prev,
                    playerCurrentTime: word.time,
                };
            });
        }, timeout);
    };

    const scrollToHighlightedWord = () => {
        const { scroller, isScrolling } = state;

        if (isScrolling || !scroller) {
            return;
        }

        const highlightedWord = document.getElement("span[data-word=word-highlight]");

        if (!highlightedWord || isElementVisible(highlightedWord)) {
            return;
        }

        const offsetTop = highlightedWord?.offsetTop || 0;
        const scrollerHeight = scroller.element.innerHeight || scroller.element.clientHeight;
        const top = offsetTop - (scrollerHeight / 2);

        scroll.elementScrollTo(scroller.element, top, 0, false);

        setState((prev) => ({
            ...prev,
            isScrolling: true,
        }));

        globalThis.setTimeout(() => {
            setState((prev) => ({
                ...prev,
                isScrolling: false,
            }));
        }, 1000);
    };

    useEffect(() => {
        if (!state.isVisible || state.isPaused) {
            return;
        }

        scrollToHighlightedWord();
        updatePlayerCurrentTime();
    }, [
        state.playerCurrentTime,
        state.isPaused,
        state.isVisible,
    ]);

    /* --- */

    const start = (params) => {
        const {
            highlight,
            scroller,
            getPlayerCurrentTime,
            isPaused,
            isMobile,
        } = params;

        if (!highlight) {
            return;
        }

        const playerCurrentTime = getPlayerCurrentTime();

        const offsetTop = scroller?.offsetTop || 0;
        const offsetBottom = scroller?.offsetBottom || 0;

        const scrollerObj = {
            ...scroller,
            offsetTop,
            offsetBottom,
        };

        if (isMobile) {
            scrollerObj.offsetBottom += styleProperties.getHeightMobileHeaderBottomHeight();
        } else {
            scrollerObj.offsetTop += styleProperties.getHeightMainHeader();
        }

        scrollerObj.offsetBottom += styleProperties.getHeightPlayerAudioHeight();

        setState((prev) => ({
            ...prev,
            highlight,
            playerCurrentTime,
            scroller: scrollerObj,
            getPlayerCurrentTime,
            isPaused,
            isActive: true,
            isVisible: true,
        }));
    };

    /* --- */

    return {
        state,
        setActive,
        start,
        stop,
        pause,
        resume,
        changeRate,
    };
};

export default useText2SpeechHighlight;
