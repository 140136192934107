import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconCalendar = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M14 11.001H9V16.001H14V11.001ZM13 0.000976562V2.00098H5V0.000976562H3V2.00098H2C0.89 2.00098 0.00999999 2.90098 0.00999999 4.00098L0 18.001C0 19.101 0.89 20.001 2 20.001H16C17.1 20.001 18 19.101 18 18.001V4.00098C18 2.90098 17.1 2.00098 16 2.00098H15V0.000976562H13ZM16 18.001H2V7.00098H16V18.001Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCalendar;
