// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slahzefU_YBOtzHUaof8 {\n    display: grid;\n    grid-template-columns: max-content auto;\n    grid-column-gap: 1rem;\n    align-items: center;\n\n    color: var(--color-4);\n    font-family: \"Soleil-Semibold\";\n    font-size: 1.7rem;\n}\n\n.VShoolPpAkFy9PMi5QjQ {\n    width: 2.6rem;\n    height: 2.6rem;\n}\n\n.i5O2L4XO4Kn1F_mwQ12V {\n    text-align: center;\n}\n\n@media only screen and (min-width: 901px) {\n    .i5O2L4XO4Kn1F_mwQ12V {\n        text-align: initial;\n    }\n}", "",{"version":3,"sources":["webpack://./base/components/message-danger/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uCAAuC;IACvC,qBAAqB;IACrB,mBAAmB;;IAEnB,qBAAqB;IACrB,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".message {\n    display: grid;\n    grid-template-columns: max-content auto;\n    grid-column-gap: 1rem;\n    align-items: center;\n\n    color: var(--color-4);\n    font-family: \"Soleil-Semibold\";\n    font-size: 1.7rem;\n}\n\n.icon {\n    width: 2.6rem;\n    height: 2.6rem;\n}\n\n.text {\n    text-align: center;\n}\n\n@media only screen and (min-width: 901px) {\n    .text {\n        text-align: initial;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "slahzefU_YBOtzHUaof8",
	"icon": "VShoolPpAkFy9PMi5QjQ",
	"text": "i5O2L4XO4Kn1F_mwQ12V"
};
export default ___CSS_LOADER_EXPORT___;
