const isSpace = (char) => {
    return char.trim() === "";
};

const isApostrophe = (char) => {
    return ["'", "’"].indexOf(char) !== -1;
};

const isDash = (char) => {
    return char === "-";
};

const isSymbol = (char) => {
    if (isSpace(char) || isApostrophe(char) || isDash(char)) {
        return true;
    }

    const symbols = [
        "<", ">", "/",
        '"', "‘", "“", "”",
        ",", ".", "?", "!", ":", ";",
        "[", "]", "(", ")",
    ];

    return symbols.indexOf(char) !== -1;
};

const getHighlightedContent = (params = {}) => {
    if (!params.word?.word || !params.content?.content) {
        return params.content?.content || "";
    }

    const { content } = params.content;
    const contentSkips = params.content?.skips || [];

    const {
        word,
        skipCount: skipSameWordCount,
        wrapped: wordWrapped,
    } = params.word;

    /* --- */

    let isTagOpen = false;
    let isTagClosed = true;

    /* --- */

    let inTagContent = "";

    /* --- */

    let wordFoundIndex = -1;
    let wordFoundCount = 0;
    const wordSpacesCount = word.split(" ").length - 1;

    /* --- */

    let contentSkipIndex = 0;
    let contentSkip = contentSkips[contentSkipIndex];
    let isSkipping = false;
    let hasSkips = !!contentSkip;

    /* --- */

    for (let i = 0; i < content.length; i += 1) {
        if (hasSkips) {
            if (isSkipping) {
                if (i >= contentSkip.to) {
                    contentSkipIndex += 1;
                    contentSkip = contentSkips[contentSkipIndex];
                    isSkipping = false;

                    if (!contentSkip) {
                        hasSkips = false;
                    }
                }
                continue;
            } else if (i >= contentSkip.from) {
                isSkipping = true;
                continue;
            }
        }

        const char = content[i];

        if (char === "<") {
            isTagOpen = true;
            isTagClosed = false;
            inTagContent = "";
            continue;
        } else if (char === ">") {
            isTagClosed = true;
            isTagOpen = false;
            continue;
        }

        if (isTagOpen || !isTagClosed) {
            continue;
        }

        const isCharSpace = isSpace(char);
        const isCharDash = isDash(char);

        if (isCharSpace && wordSpacesCount && inTagContent.length <= word.length) {
            const wordStart = word.slice(0, inTagContent.length);

            if (wordStart === inTagContent) {
                inTagContent += char;
                continue;
            }
        } else if (isCharSpace || isCharDash || (!inTagContent && isSymbol(char))) {
            inTagContent = "";
            continue;
        } else {
            inTagContent += char;
        }

        if (inTagContent === word) {
            const nextChar = content[i + 1];

            if (!nextChar) {
                if (wordFoundCount >= skipSameWordCount) {
                    wordFoundIndex = i - (word.length - 1);
                }

                break;
            }

            if (isSymbol(nextChar) && !isApostrophe(nextChar)) {
                if (skipSameWordCount > wordFoundCount) {
                    wordFoundCount += 1;
                } else {
                    wordFoundIndex = i - (word.length - 1);
                    break;
                }
            }
        }
    }

    if (wordFoundIndex === -1) {
        return content;
    }

    const contentStart = content.slice(0, wordFoundIndex);
    const contentEnd = content.slice(wordFoundIndex + word.length);

    return `${contentStart}${wordWrapped}${contentEnd}`;
};


export default {
    getHighlightedContent,
};
