export const SET_DISTRICT_ADMIN_SCHOOLS = "SET_DISTRICT_ADMIN_SCHOOLS";
export const SET_DISTRICT_ADMIN_SCHOOL_NAME = "SET_DISTRICT_ADMIN_SCHOOL_NAME";
export const SET_DEPARTMENT_BY_SCHOOL_ID = "SET_DEPARTMENT_BY_SCHOOL_ID";

export const SET_CLASS_CODE_BY_DEPARTMENT_ID = "SET_CLASS_CODE_BY_DEPARTMENT_ID";

export const SET_DISTRICT_ADMIN_DEPARTMENTS_BY_SCHOOL_ID = "SET_DISTRICT_ADMIN_DEPARTMENTS_BY_SCHOOL_ID";
export const SET_DISTRICT_ADMIN_CLASSES_BY_DEPARTMENT_ID = "SET_DISTRICT_ADMIN_CLASSES_BY_DEPARTMENT_ID";
export const SET_DISTRICT_ADMIN_TEACHERS_BY_DEPARTMENT_ID = "SET_DISTRICT_ADMIN_TEACHERS_BY_DEPARTMENT_ID";

export const SET_DISTRICT_ADMIN_CLASS_PERFORMANCE_LOADING = "SET_DISTRICT_ADMIN_CLASS_PERFORMANCE_LOADING";
export const SET_DISTRICT_ADMIN_CLASS_PERFORMANCE = "SET_DISTRICT_ADMIN_CLASS_PERFORMANCE";
export const CLEAR_DISTRICT_ADMIN_CLASS_PERFORMANCE = "CLEAR_DISTRICT_ADMIN_CLASS_PERFORMANCE";

export const SET_DISTRICT_ADMIN_CLASS_CATEGORIES_LOADING = "SET_DISTRICT_ADMIN_CLASS_CATEGORIES_LOADING";
export const SET_DISTRICT_ADMIN_CLASS_CATEGORIES = "SET_DISTRICT_ADMIN_CLASS_CATEGORIES";
export const CLEAR_DISTRICT_ADMIN_CLASS_CATEGORIES = "CLEAR_DISTRICT_ADMIN_CLASS_CATEGORIES";

export const SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS_LOADING = "SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS_LOADING";
export const SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS = "SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS";
export const CLEAR_DISTRICT_ADMIN_CLASS_STANDARDS_STATS = "CLEAR_DISTRICT_ADMIN_CLASS_STANDARDS_STATS";

export const SET_DISTRICT_ADMIN_SELECTED_SCHOOL = "SET_DISTRICT_ADMIN_SELECTED_SCHOOL";
export const SET_DISTRICT_ADMIN_SELECTED_DEPARTMENT = "SET_DISTRICT_ADMIN_SELECTED_DEPARTMENT";
export const SET_DISTRICT_ADMIN_SELECTED_CLASS = "SET_DISTRICT_ADMIN_SELECTED_CLASS";
