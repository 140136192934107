import React, { useState, useEffect } from "react";

import classNames from "@/base/lib/class-names.js";
import scroll from "@/base/lib/scroll.js";

import IconArrowBold from "@/base/icons/arrow-bold/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import PopupConfirmUnsavedProgressV2 from "@/base/components/popup-confirm-unsaved-progress-v2/index.js";
import Close from "@/base/components/close/index.js";

import styles from "./styles.module.css";


const PopupFullScreen = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    const [isVisibleConfirmClosePopup, setIsVisibleConfirmClosePopup] = useState(false);

    /* --- */

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        // NOTE: fix left-right content shift
        window.setTimeout(() => {
            scroll.hideBodyScroll();
        }, 300);

        return () => {
            scroll.unhideBodyScroll();
        };
    }, []);

    useEffect(() => {
        if (props.isArrowBackVisible) {
            props.onArrowBack(true);
        }
    }, [props.isMobile]);

    /* --- */

    const onClose = (showConfirm) => {
        if (!isVisible) {
            return;
        }

        if (showConfirm) {
            setIsVisibleConfirmClosePopup(true);
            return;
        }

        setIsVisibleConfirmClosePopup(false);
        setIsVisible(false);

        scroll.unhideBodyScroll();

        window.setTimeout(() => {
            props.onClose();
        }, 300);
    };

    const onArrowBack = () => {
        props.onArrowBack(true);
    };

    const onCloseConfirmPopup = () => {
        setIsVisibleConfirmClosePopup(false);
    };

    /* --- */

    const renderBackArrow = () => {
        if (props.isMobile && props.isArrowBackVisible) {
            return (
                <IconClickable
                    className={styles.arrowBack}
                    onClick={onArrowBack}
                >
                    <IconArrowBold
                        className={styles.arrowBackIcon}
                        title="Go Back"
                        isBlack
                    />
                </IconClickable>
            );
        }

        return null;
    };

    const renderConfirmClosePopup = () => {
        if (!isVisibleConfirmClosePopup) {
            return null;
        }

        return (
            <PopupConfirmUnsavedProgressV2
                onLeave={() => {
                    onClose(false);
                }}
                onClose={onCloseConfirmPopup}
            />
        );
    };

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupVisible]: isVisible,
    });

    const headerClassName = classNames({
        [styles.header]: true,
        [styles.headerWithSubheader]: props.subheader,
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentWithSubheader]: props.subheader,
    });

    return (
        <>
            {renderConfirmClosePopup()}

            <div className={popupClassName}>
                <div className={headerClassName}>
                    <div className={styles.headerMain}>
                        <div>
                            {renderBackArrow()}
                        </div>
                        <div className={styles.headerTitle}>
                            {props.title}
                        </div>
                        <Close
                            onClose={() => {
                                onClose(props.showConfirmOnClose);
                            }}
                        />
                    </div>
                    {props.subheader}
                </div>
                <div className={contentClassName}>
                    {props.children}
                </div>
            </div>
        </>
    );
};

PopupFullScreen.defaultProps = {
    title: "",
    subheader: null,
    children: null,
    isArrowBackVisible: false,
    showConfirmOnClose: false,
    isMobile: false,
    onArrowBack: () => { },
    onClose: () => { },
};

export default PopupFullScreen;
