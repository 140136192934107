import React from "react";

import MessageDanger from "@/base/components/message-danger/index.js";
import ButtonBig from "@/base/components/button-big/index.js";

import styles from "./styles.module.css";


const MessageErrorWithTryAgain = (props) => {
    if (!props.children) {
        return null;
    }

    return (
        <div className={styles.container}>
            <MessageDanger>
                {props.children}
            </MessageDanger>
            <div className={styles.button}>
                <ButtonBig
                    onClick={props.onTryAgain}
                    isRounded
                >
                    Try again
                </ButtonBig>
            </div>
        </div>
    );
};

MessageErrorWithTryAgain.defaultProps = {
    children: null,
    onTryAgain: () => { },
};

export default MessageErrorWithTryAgain;
