// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XiYGy6Fh21aYinv3vPKP {\n    display: grid;\n    justify-content: center;\n    grid-row-gap: 2rem;\n}\n\n.twEuEk9KYzBFjw_Y65PE {\n    width: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/extra-juice-buttons/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".extraJuices {\n    display: grid;\n    justify-content: center;\n    grid-row-gap: 2rem;\n}\n\n.extraJuiceIcon {\n    width: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraJuices": "XiYGy6Fh21aYinv3vPKP",
	"extraJuiceIcon": "twEuEk9KYzBFjw_Y65PE"
};
export default ___CSS_LOADER_EXPORT___;
