import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import IconStandards from "@/base/icons/standards/index.js";
import IconMinusInRing from "@/base/icons/minus-in-ring/index.js";
import IconPlusInRing from "@/base/icons/plus-in-ring/index.js";

import styles from "./styles.module.css";


const StandardType = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!props.type) {
        return null;
    }

    const onToggle = () => {
        setIsExpanded((val) => !val);
    };

    const renderToggler = () => {
        if (isExpanded) {
            return (
                <IconMinusInRing
                    className={styles.togglerImg}
                    isRed
                />
            );
        }

        return (
            <IconPlusInRing
                className={styles.togglerImg}
                isRed
            />
        );
    };

    const descClassName = classNames({
        [styles.description]: true,
        [styles.descriptionExpanded]: isExpanded,
    });

    return (
        <div className={styles.standardType}>
            <div
                className={styles.header}
                onClick={onToggle}
                onKeyPress={onToggle}
                role="button"
                tabIndex="-1"
            >
                <IconStandards
                    className={styles.headerImg}
                    isRed
                />
                <div className={styles.headerTitle}>
                    {props.type.name}
                </div>
                <div className={styles.toggler}>
                    {renderToggler()}
                </div>
            </div>
            <div className={descClassName}>
                <div className={styles.descriptionContent}>
                    <div className={styles.descriptionName}>
                        {props.type.standard}
                    </div>
                    <div>{props.type.details}</div>
                </div>
            </div>
        </div>
    );
};

StandardType.defaultProps = {
    type: null,
};

export default StandardType;
