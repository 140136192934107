import React from "react";

import Skeleton from "@/base/components/skeleton/index.js";
import WordCardAudioButton from "./audio-button.js";

import styles from "./word.module.css";


const WordCardWord = (props) => {
    const { word, translation } = props;

    /* --- */

    const renderTranslation = () => {
        if (!translation.isVisible || translation.error) {
            return null;
        }

        let content = null;

        if (translation.isLoading) {
            content = (
                <div className={styles.skeleton}>
                    <Skeleton
                        fullWidth
                        fullHeight
                        withAnimation
                    />
                </div>
            );
        } else if (translation.word?.word) {
            content = (
                <div className={styles.wordValue}>
                    {translation.word.word}
                </div>
            );
        }

        return (
            <div className={styles.translation}>
                {content}
            </div>
        );
    };

    /* --- */

    return (
        <div className={styles.container}>
            <WordCardAudioButton
                text={word.word}
                audio={props.audio}
                word={word}
                onLoad={props.onLoad}
                onPlay={props.onPlay}
                onStop={props.onStop}
            />
            <div className={styles.wordContainer}>
                <div className={styles.word}>
                    <div className={styles.wordValue}>
                        {word.word}
                    </div>
                    <div className={styles.wordPronunciation}>
                        {word.pronunciation}
                    </div>
                </div>
                {renderTranslation()}
            </div>
        </div>
    );
};

WordCardWord.defaultProps = {
    translation: {},
    word: null,
    audio: {},
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
};

export default WordCardWord;
