import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

import staticFiles from "@/base/static-files.js";

import Subscription from "@/base/project/subscription.js";
import PaymentPlans from "@/base/project/payment-plans.js";

import urls from "@/base/lib/urls.js";
import text from "@/base/text/index.js";

import actionsSubscription from "@/base/actions/subscription.js";
import actionsTeacher from "@/base/actions/teacher.js";
import actions from "@/base/store/actions.js";

import useCoupon from "@/base/hooks/use-coupon/index.js";
import useTeacherLimit from "@/base/hooks/use-teacher-limit/index.js";

import IconEmojiHappy from "@/base/icons/emoji-happy/index.js";
import IconEmojiSad from "@/base/icons/emoji-sad/index.js";

import Tabs from "@/base/components/tabs/index.js";
import ButtonBig from "@/base/components/button-big/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import Checkbox from "@/base/components/forms/checkbox/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";
import ProfileRow from "@/base/components/profile-row/index.js";

import RequestQuoteForm from "@/base/forms/request-quote/index.js";

import Coupon from "@/base/business/coupon/index.js";
import PopupConfirmTeacherLimit from "@/base/business/popup-confirm-teacher-limit/index.js";

import UserPaymentDetailsChange from "@/app/containers/user-payment-details-change/index.js";
import Stripe from "@/app/containers/stripe/index.js";

import settings from "@/app/settings.js";
import api from "@/app/api.js";

import Card from "./card.js";
import styles from "./styles.module.css";


const getPaymentState = () => ({
    isClientSecretLoading: false,

    clientSecret: "",
    intentId: "",
    autoRenewDate: "",
    subscriptionEncId: "",
    is100PercentDiscount: false,

    isSubmitted: false,
    isStatusLoaded: false,
    isSuccess: false,

    price: "",
    isPriceWithDiscount: false,
});

// NOTE: payment
const UserPlans = (props) => {
    const withScrollRef = useRef(null);

    const [selectedPlan, setSelectedPlan] = useState(() => {
        const userPlan = Subscription.getPlanByPlanId(props.plans, props.userPlanId);
        const plans = props.plans.filter((plan) => plan.id !== props.userPlanId);

        if (userPlan?.slug === "large-class" && plans.length > 0) {
            return plans?.[plans.length - 1]?.id || "";
        }

        return plans?.[0]?.id || "";
    });

    const [isAutoRenewSubscription, setIsAutoRenewSubscription] = useState(true);

    const [paymentState, setPaymentState] = useState(() => getPaymentState());

    /* --- */

    const coupon = useCoupon();
    const teacherLimit = useTeacherLimit();

    const stripe = useStripe();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const store = useSelector((state) => ({
        session: state.user.session,
        geo: state.geo,
        teacher: state.teacher,
        paymentDetails: state.user.paymentDetails,
    }));

    /* --- */

    const loadTeacherClasses = () => {
        dispatch(actionsTeacher.loadAllClasses({
            actions,
            api,
        }, {
            session: store.session,
        }));
    };

    useEffect(() => {
        loadTeacherClasses();
    }, []);

    /* --- */

    const onScrollToTop = () => {
        if (withScrollRef?.current?.scrollTo) {
            withScrollRef.current.scrollTo(0, 0);
        }
    };

    const onTabChange = (value) => {
        setSelectedPlan(value);
        onScrollToTop();
    };

    const onContactSupport = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        urls.openUrl(supportLink);
    };

    /* --- */

    const onOpenPaymentDetailsChange = () => {
        props.onSetIsVisiblePaymentChange(true);
        onScrollToTop();
    };

    const onClosePaymentDetailsChange = () => {
        props.onSetIsVisiblePaymentChange(false);
        onScrollToTop();
    };

    /* --- */

    const onTryAgain = () => {
        setPaymentState(getPaymentState());
        props.onSetIsPaymentSubmitted(false);
        onScrollToTop();
    };

    const onBackToAccountManagement = () => {
        props.onClose();
        props.onLoadUser();
    };

    const onCloseTeacherLimitPopup = () => {
        teacherLimit.close();
    };

    const onEditClasses = () => {
        onCloseTeacherLimitPopup();
        props.onFullClose();

        navigate("/class");
    };

    /* --- */

    const isTabsVisible = () => {
        if (props.isVisiblePaymentChange) {
            return false;
        }

        if (paymentState.isSubmitted) {
            return false;
        }

        if (props.isVisiblePaymentDetails) {
            return false;
        }

        if (props.isVisibleQuoteStatus) {
            return false;
        }

        return true;
    };

    /* --- */

    const onCreatePaymentIntent = async (params = {}) => {
        setPaymentState((prev) => ({
            ...prev,
            isClientSecretLoading: true,
        }));

        const res = await api.subscription.createPaymentIntentByPlan({
            session: store.session,
            plan: params.planId || selectedPlan,
            coupon: params.coupon || "",
            isAutoRenew: isAutoRenewSubscription,
        });

        if (!res.ok) {
            return;
        }

        const { clientSecret } = res.data;
        const { intentExternalId } = res.data;

        setPaymentState((prev) => ({
            ...prev,
            isClientSecretLoading: false,
            clientSecret,
            intentId: intentExternalId,
            price: res.data.price,
            isPriceWithDiscount: res.data.isPriceWithDiscount,
            autoRenewDate: res.data.subscriptionEndDateFormatted,
            subscriptionEncId: res.data.subscriptionEncId,
            is100PercentDiscount: res.data.is100PercentDiscount,
        }));
    };

    const onLoadUserPaymentDetails = async () => {
        if (!props.isVisiblePaymentDetails) {
            onClosePaymentDetailsChange();
            props.onSetIsVisiblePaymentDetails(true);
            onScrollToTop();
        }

        const res = await dispatch(actionsSubscription.getUserPaymentDetails({ api, actions }));

        if (!res.error) {
            let couponCode = "";

            if (coupon.state.isSubmitted && !coupon.state.error) {
                couponCode = coupon.state.couponCode;
            }

            onCreatePaymentIntent({
                coupon: couponCode,
            });
        }
    };

    const onProcessToPayment = (planId = selectedPlan) => {
        const plan = Subscription.getPlanByPlanId(props.plans, planId);

        if (PaymentPlans.isPlanLimitExceeded(store.teacher.classes, plan)) {
            teacherLimit.open({
                planName: plan.name,
                maxClasses: plan.maxClasses,
                maxStudents: plan.maxStudents,
            });
            return;
        }

        if (!props.hasCardDetails) {
            onOpenPaymentDetailsChange();
            return;
        }

        props.onSetIsVisiblePaymentDetails(true);
        onScrollToTop();

        let couponCode = "";

        if (coupon.state.isSubmitted && !coupon.state.error) {
            couponCode = coupon.state.couponCode;
        }

        onCreatePaymentIntent({
            coupon: couponCode,
            planId,
        });
    };

    const onSubscribeToLargePlan = () => {
        onCloseTeacherLimitPopup();

        const largeClassPlanId = Subscription.getPlanIdBySlug(props.plans, "large-class");

        setSelectedPlan(largeClassPlanId);
        onProcessToPayment(largeClassPlanId);
    };

    /* --- */

    const onApplyCoupon = async (values) => {
        if (!values.couponCode) {
            return;
        }

        coupon.setSubmitted(values.couponCode);

        const res = await api.subscription.subscriptionApplyCoupon({
            session: store.session,
            coupon: values.couponCode,
            plan: selectedPlan,
        });

        if (!res.ok) {
            coupon.setLoaded({
                error: res.error || text.error,
            });
            return;
        }

        let discountMessage = "";

        if (res.data.discountUSD > 0) {
            discountMessage = `$${res.data.discountUSD} discount added`;
        } else {
            discountMessage = `${res.data.discountPercent}% discount added`;
        }

        coupon.setLoaded({
            discountMessage,
            error: "",
        });

        onCreatePaymentIntent({
            coupon: values.couponCode,
        });
    };

    const onPay = async () => {
        props.onSetIsPaymentSubmitted(true);
        onScrollToTop();

        setPaymentState((prev) => ({
            ...prev,
            isSubmitted: true,
        }));

        if (paymentState.is100PercentDiscount) {
            await api.subscription.paymentAttachPaymentMethod({
                session: store.session,
                paymentMethodId: store.paymentDetails.defaultPaymentMethod.id,
            });

            const completeRes = await api.subscription.paymentCompleteWithDiscount100({
                session: store.session,
                subscriptionId: paymentState.subscriptionEncId,
            });

            setPaymentState((prev) => ({
                ...prev,
                isStatusLoaded: true,
                isSuccess: completeRes.ok,
            }));
            return;
        }

        const confirmPaymentRes = await stripe.confirmCardPayment(paymentState.clientSecret, {
            payment_method: store.paymentDetails.defaultPaymentMethod.id,
            setup_future_usage: "off_session",
        });

        let isPaymentSuccessful = false;

        if (confirmPaymentRes?.paymentIntent?.status === "succeeded") {
            const onPaymentCompleteRes = await api.subscription.paymentCompleted({
                session: store.session,
                intentId: paymentState.intentId,
            });

            if (onPaymentCompleteRes.ok) {
                isPaymentSuccessful = true;
                props.onLoadUser();
            }
        }

        setPaymentState((prev) => ({
            ...prev,
            isStatusLoaded: true,
            isSuccess: isPaymentSuccessful,
        }));
    };

    const onRequestQuote = async (values, { setSubmitting, setErrors }) => {
        const res = await api.subscription.paymentRequestQuote({
            session: store.session,
            role: values.role,
            phone: values.phone,
            message: values.requestQuoteText,
        });

        if (res.ok) {
            props.onSetIsVisibleQuoteStatus(true);
            onScrollToTop();
        } else {
            setErrors({
                form: res.error || text.error,
            });
        }

        setSubmitting(false);
    };

    /* --- */

    const renderTabs = () => {
        if (!isTabsVisible()) {
            return null;
        }

        const plans = props.plans.filter((plan) => plan.id !== props.userPlanId);

        const tabs = plans.map((plan) => {
            return {
                value: plan.id,
                label: plan.name,
            };
        });

        return (
            <Tabs
                tabs={tabs}
                selectedTab={selectedPlan}
                onChange={onTabChange}
                isBlueTheme
                onlyTabs
            />
        );
    };

    const renderRequestQuoteForm = () => {
        return (
            <div>
                <div className={styles.requestQuoteLabel}>
                    <div>Interested in The Juice for your school or district?</div>
                    <div>Contact us to get a quote.</div>
                </div>
                <RequestQuoteForm
                    onSubmit={onRequestQuote}
                />
            </div>
        );
    };

    const renderStatus = (isSuccess) => {
        let title = "";
        let image = null;
        let description = "";
        let controls = null;

        if (isSuccess) {
            title = "Huzzah! Your payment was successful.";

            description = "Check your email for confirmation and a receipt.";

            image = (
                <img
                    src={staticFiles.iconCardSuccessBlue}
                    alt="Success"
                />
            );

            controls = (
                <ButtonBig
                    onClick={onBackToAccountManagement}
                >
                    Back to account management
                </ButtonBig>
            );
        } else {
            title = "We are sorry but the payment failed! Please try again or contact support.";

            image = (
                <IconEmojiSad
                    className={styles.paymentConfirmationStatusEmoji}
                    title="Failed"
                    isBlack
                />
            );

            controls = [
                <ButtonBig onClick={onTryAgain}>
                    Try again
                </ButtonBig>,
                <ButtonBig onClick={onContactSupport}>
                    Contact support
                </ButtonBig>,
            ];
        }

        return (
            <div className={styles.paymentConfirmationStatusContainer}>
                <div className={styles.paymentConfirmationStatus}>
                    {image}
                    <div className={styles.textBig}>
                        {title}
                    </div>
                    <div className={styles.textGrey}>
                        {description}
                    </div>
                </div>
                {controls}
            </div>
        );
    };

    const renderCouponRow = () => {
        const content = (
            <Coupon
                isLoading={coupon.state.isLoading}
                isSubmitted={coupon.state.isSubmitted}
                couponCode={coupon.state.couponCode}
                discountMessage={coupon.state.discountMessage}
                error={coupon.state.error}
                onChange={coupon.reset}
                onApply={onApplyCoupon}
            />
        );

        return (
            <ProfileRow
                isCoupon
                value={content}
            />
        );
    };

    const renderPaymentDetails = () => {
        if (!props.hasCardDetails) {
            return (
                <RequestLoader />
            );
        }

        if (paymentState.isClientSecretLoading) {
            return (
                <RequestLoader />
            );
        }

        const planName = Subscription.getPlanNameById(props.plans, selectedPlan);

        const orderRightControl = (
            <div className={styles.paymentConfirmationPrice}>
                {`$${paymentState.price}`}
            </div>
        );

        const autoRenewControl = (
            <div>
                <Checkbox
                    name="isAutoRenewSubscription"
                    checked={isAutoRenewSubscription}
                    onChange={(evt) => {
                        setIsAutoRenewSubscription(evt.target.checked);
                    }}
                />
            </div>
        );

        return (
            <div>
                <div className={styles.paymentConfirmation}>
                    <ProfileRow
                        isCardName
                        value={props.card.name}
                    />
                    <ProfileRow
                        isCardNumber
                        value={`**** **** **** ${props.card.last4}`}
                    />
                    <ProfileRow
                        isExpiration
                        value={`${props.card.expMonth}/${props.card.expYear}`}
                    />
                    {renderCouponRow()}
                </div>
                <div>
                    <ProfileRow
                        boldName
                        className={styles.paymentConfirmationOrder}
                        customRightControl={autoRenewControl}
                        customName="Auto-Renew Plan"
                        value={`Renews on ${paymentState.autoRenewDate}`}
                    />
                    <ProfileRow
                        isOrder
                        boldName
                        className={styles.paymentConfirmationOrder}
                        customRightControl={orderRightControl}
                        value={`The Juice ${planName} Plan`}
                    />
                    <div className={styles.paymentConfirmationControls}>
                        <ButtonBig
                            isGreenTheme={paymentState.isPriceWithDiscount}
                            onClick={onPay}
                        >
                            {`Pay $${paymentState.price}`}
                        </ButtonBig>
                        <ButtonBig onClick={onOpenPaymentDetailsChange}>
                            Change payment details
                        </ButtonBig>
                    </div>
                </div>
            </div>
        );
    };

    const renderPaymentStatus = () => {
        if (!paymentState.isStatusLoaded) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                {renderStatus(paymentState.isSuccess)}
            </div>
        );
    };

    const renderQuoteStatus = () => {
        return (
            <div className={styles.requestQuoteStatus}>
                <IconEmojiHappy
                    className={styles.requestQuoteStatusEmoji}
                    title="Emoji Happy"
                    isBlack
                />

                <div className={styles.requestQuoteStatusTitle}>
                    We have received your request
                </div>
                <div>
                    Thanks for submitting your details.
                </div>
                <div>
                    A member from our sales team will be reaching out soon.
                </div>
                <div className={styles.requestQuoteStatusControls}>
                    <ButtonBig onClick={onBackToAccountManagement}>
                        Back to account management
                    </ButtonBig>
                </div>
            </div>
        );
    };

    const renderCard = (plan) => {
        return (
            <>
                <Card
                    price={plan?.price || ""}
                    billingCycle={plan.billingCycle}
                    features={plan?.features || []}
                />
                <ButtonBig onClick={() => { onProcessToPayment(); }}>
                    Proceed to payment
                </ButtonBig>
            </>
        );
    };

    const renderClassLimitPopup = () => {
        if (!teacherLimit.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmTeacherLimit
                planName={teacherLimit.state.planName}
                maxStudents={teacherLimit.state.maxStudents}
                maxClasses={teacherLimit.state.maxClasses}
                onSubscribeToLargePlan={onSubscribeToLargePlan}
                onEditClasses={onEditClasses}
                onClose={onCloseTeacherLimitPopup}
            />
        );
    };

    const renderContent = () => {
        if (!store.teacher.isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (props.isVisiblePaymentChange) {
            return (
                <Stripe>
                    <UserPaymentDetailsChange
                        isAddPaymentDetails={!props.hasCardDetails}
                        onContactSupport={onContactSupport}
                        onBackToManagement={onClosePaymentDetailsChange}
                        onLoadUserPaymentDetails={onLoadUserPaymentDetails}
                    />
                </Stripe>
            );
        }

        if (paymentState.isSubmitted) {
            return renderPaymentStatus();
        }

        if (props.isVisiblePaymentDetails) {
            return renderPaymentDetails();
        }

        if (props.isVisibleQuoteStatus) {
            return renderQuoteStatus();
        }

        if (!selectedPlan) {
            return null;
        }

        let content = null;

        const plan = Subscription.getPlanByPlanId(props.plans, selectedPlan);

        if (plan?.isRequestAQuotePlan) {
            content = renderRequestQuoteForm();
        } else {
            content = renderCard(plan);
        }

        return (
            <div className={styles.content}>
                {content}
            </div>
        );
    };

    return (
        <>
            {renderClassLimitPopup()}

            <div className={styles.contentContainer}>
                {renderTabs()}
                <WithScroll scrollRef={withScrollRef}>
                    {renderContent()}
                </WithScroll>
            </div>
        </>
    );
};

UserPlans.defaultProps = {
    userPlanId: 0,
    plans: [],
    hasCardDetails: false,
    card: {
        name: "",
        last4: "",
        expMonth: "",
        expYear: "",
    },
    isVisiblePaymentChange: false,
    isVisibleQuoteStatus: false,
    isVisiblePaymentDetails: false,
    onSetIsVisiblePaymentDetails: () => { },
    onSetIsVisibleQuoteStatus: () => { },
    onSetIsVisiblePaymentChange: () => { },
    onSetIsPaymentSubmitted: () => { },
    onLoadUser: () => { },
    onClose: () => { },
    onFullClose: () => { },
};

export default UserPlans;
