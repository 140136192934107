import React from "react";

import styles from "./styles.module.css";


const RequestLoader = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
            <div className={styles.loaderBar} />
        </div>
    );
};

export default RequestLoader;
