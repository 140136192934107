const loadFirstExtraJuices = (services, params = {}) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.extraJuices.setExtraJuicesLoading());

        const page = 0;
        const onlyFirst = params.onlyFirst || false;

        const res = await api.extraJuices.getExtraJuices({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let extraJuices = [];

        if (res.ok) {
            extraJuices = [
                ...res.extraJuices,
            ];
        }

        const hasMorePages = res.ok ? res.hasMore : false;

        dispatch(actions.extraJuices.setExtraJuices({
            extraJuices,
            page,
            hasMorePages,
        }));
    };
};

const loadExtraJuices = (services, params = {}) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.extraJuices.setExtraJuicesLoading());

        const page = params.page || 0;

        const res = await api.extraJuices.getExtraJuices({
            session: state.user.session,
            page,
        });

        let extraJuices = [];

        if (res.ok) {
            // TODO: incorrect redux usage
            extraJuices = [
                ...state.extraJuices.extraJuices,
                ...res.extraJuices,
            ];
        }

        const hasMorePages = res.ok ? res.hasMore : false;

        dispatch(actions.extraJuices.setExtraJuices({
            extraJuices,
            page,
            hasMorePages,
        }));
    };
};

const loadMoreExtraJuices = (services) => {
    return (dispatch, getState) => {
        const state = getState();

        const ejs = state.extraJuices.extraJuices || [];
        const page = state.extraJuices.page || 0;

        if (page === 0 && ejs.length < 10) {
            dispatch(loadFirstExtraJuices(services, {
                onlyFirst: false,
            }));
            return;
        }

        dispatch(loadExtraJuices(services, {
            page: page + 1,
        }));
    };
};

export default {
    loadFirstExtraJuices,
    loadExtraJuices,
    loadMoreExtraJuices,
};
