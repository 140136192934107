import text from "@/base/text/index.js";


const loadStudentGrades = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.student.getStudentGrades({
            session: state.user.session,
        });

        let data = null;
        let label = "";
        let error = "";
        let pendingGrade = "";
        let isAdjustmentAllowed = false;

        if (res.ok) {
            data = res.data || [];
            label = res.label || "";
            pendingGrade = res.pendingGrade || "";
            isAdjustmentAllowed = res.isAdjustmentAllowed || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.student.setStudentGrades({
            data,
            label,
            error,
            pendingGrade,
            isAdjustmentAllowed,
        }));
    };
};

const setStudentGrade = (services, params) => {
    const { api, actions } = services;
    const { grade } = params;

    return async (dispatch, getState) => {
        const state = getState();
        const prevGrades = state.student.grades.pendingGrade || "";

        dispatch(actions.user.setUserGradesLoading(true));
        dispatch(actions.student.setStudentPendingGrade(grade));

        const res = await api.student.setStudentGrade({
            session: state.user.session,
            grade,
        });

        let error = "";

        if (!res.ok) {
            dispatch(actions.student.setStudentPendingGrade(prevGrades));
            error = res.error || text.error;
        }

        dispatch(actions.user.setUserGradesLoading(false));

        return { error };
    };
};

export default {
    loadStudentGrades,
    setStudentGrade,
};
