import types from "./types.js";

/* --- */

const setJuiceStoryRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_JUICE_STORY_LOADING,
    payload,
});

const setJuiceStoryRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_JUICE_STORY,
    payload,
});

/* --- */

const setJuiceStoryExtraJuiceRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE_LOADING,
    payload,
});

const setJuiceStoryExtraJuiceRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE,
    payload,
});

/* --- */

const setExtraJuiceRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_EXTRA_JUICE_LOADING,
    payload,
});

const setExtraJuiceRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_EXTRA_JUICE,
    payload,
});

/* --- */

const setAssignmentExtraJuiceRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE_LOADING,
    payload,
});

const setAssignmentExtraJuiceRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE,
    payload,
});

/* --- */

const setAssignmentStoryRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_ASSIGNMENT_STORY_LOADING,
    payload,
});

const setAssignmentStoryRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_ASSIGNMENT_STORY,
    payload,
});

/* --- */

const setSearchStoryRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_SEARCH_STORY_LOADING,
    payload,
});

const setSearchStoryRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_SEARCH_STORY,
    payload,
});

/* --- */

const setSearchStoryExtraJuiceRatingLoading = (payload) => ({
    type: types.SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE_LOADING,
    payload,
});

const setSearchStoryExtraJuiceRating = (payload) => ({
    type: types.SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE,
    payload,
});

/* --- */

export default {
    setJuiceStoryRatingLoading,
    setJuiceStoryRating,

    setJuiceStoryExtraJuiceRatingLoading,
    setJuiceStoryExtraJuiceRating,

    setExtraJuiceRatingLoading,
    setExtraJuiceRating,

    setAssignmentExtraJuiceRatingLoading,
    setAssignmentExtraJuiceRating,

    setAssignmentStoryRatingLoading,
    setAssignmentStoryRating,

    setSearchStoryRatingLoading,
    setSearchStoryRating,

    setSearchStoryExtraJuiceRatingLoading,
    setSearchStoryExtraJuiceRating,
};
