import * as types from "./types.js";


export const setDistrictsAllLoading = (payload) => ({
    type: types.SET_ADMIN_DISTRICTS_ALL_LOADING,
    payload,
});

export const setDistrictsAll = (payload) => ({
    type: types.SET_ADMIN_DISTRICTS_ALL,
    payload,
});

export const setDistrictsByKeyword = (payload) => ({
    type: types.SET_ADMIN_DISTRICTS_BY_KEYWORD,
    payload,
});

export const setDistrictsByKeywordLoading = (payload) => ({
    type: types.SET_ADMIN_DISTRICTS_BY_KEYWORD_LOADING,
    payload,
});

export const updateDistrictById = (payload) => ({
    type: types.UPDATE_ADMIN_DISTRICT_BY_ID,
    payload,
});

export const clearDistricts = (payload) => ({
    type: types.CLEAR_ADMIN_DISTRICTS,
    payload,
});

/* --- */

export const setSelectedDistrict = (selectedDistrictId) => ({
    type: types.SET_ADMIN_SELECTED_DISTRICT,
    payload: { selectedDistrictId },
});

export const setSelectedSchool = (selectedSchoolId) => ({
    type: types.SET_ADMIN_SELECTED_SCHOOL,
    payload: { selectedSchoolId },
});

export const setSelectedDepartment = (selectedDepartmentId) => ({
    type: types.SET_ADMIN_SELECTED_DEPARTMENT,
    payload: { selectedDepartmentId },
});

export const setSelectedClass = (selectedClassId) => ({
    type: types.SET_ADMIN_SELECTED_CLASS,
    payload: { selectedClassId },
});

/* --- */

export const setSchoolsByDistrictIdLoading = (districtId) => ({
    type: types.SET_ADMIN_SCHOOL_BY_DISTRICT_ID_LOADING,
    payload: { districtId },
});

export const setSchoolsByDistrictId = (payload) => ({
    type: types.SET_ADMIN_SCHOOL_BY_DISTRICT_ID,
    payload,
});

/* --- */

export const setDistrictByIdLoading = (districtId) => ({
    type: types.SET_ADMIN_DISTRICT_BY_ID_LOADING,
    payload: { districtId },
});

export const setDistrictById = (payload) => ({
    type: types.SET_ADMIN_DISTRICT_BY_ID,
    payload,
});
