import React, { useState, useEffect, useRef } from "react";

import classNames from "@/base/lib/class-names.js";

import ButtonBig from "@/base/components/button-big/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";

import styles from "./styles.module.css";


const UploaderImage = (props) => {
    const [isVisibleField, setVisibleField] = useState(true);
    const [isDragOverField, setDragOverField] = useState(false);
    const [imageFileError, setImageFileError] = useState(null);

    const dropAreaRef = useRef(null);

    /* ------ */

    useEffect(() => {
        if (props.imageUrl) {
            setVisibleField(false);
        } else {
            setVisibleField(true);
        }
    }, [props.imageUrl]);

    /* ------ */

    const onDropAreaClick = () => {
        if (!dropAreaRef?.current?.click) {
            return;
        }

        dropAreaRef.current.click();
    };

    const onRemoveImage = (evt) => {
        evt.preventDefault();
        props.onFileChange(null);
    };

    const onFileChange = (evt) => {
        if (props.imageRequiredHeight && props.imageRequiredHeight) {
            if (evt.target.files.length > 0) {
                const file = evt.target.files[0];
                const img = new Image();
                img.src = window.URL.createObjectURL(file);

                img.onload = () => {
                    let sizesError = null;

                    if (img.width !== props.imageRequiredWidth
                        && img.height !== props.imageRequiredHeight) {
                        sizesError = `The width and height of the image should be: ${props.imageRequiredWidth} x ${props.imageRequiredHeight}`;
                    } else if (img.width !== props.imageRequiredWidth) {
                        sizesError = `The width of the image should be: ${props.imageRequiredWidth}px`;
                    } else if (img.height !== props.imageRequiredHeight) {
                        sizesError = `The height of the image should be: ${props.imageRequiredHeight}px`;
                    }

                    if (sizesError) {
                        setDragOverField(false);
                        setImageFileError(sizesError);
                    } else {
                        setImageFileError(null);
                        props.onFileChange(evt);
                    }

                    // NOTE: This is HACK to upload the same file multiple times in a row
                    if (dropAreaRef.current && dropAreaRef.current.value) {
                        dropAreaRef.current.value = null;
                    }
                };
            }
        } else {
            props.onFileChange(evt);
        }
    };

    /* ------ */

    const renderControls = () => {
        const uploadButton = (
            <ButtonBig
                onClick={onDropAreaClick}
            >
                Upload
            </ButtonBig>
        );

        if (!props.imageUrl) {
            return [
                uploadButton,
                null,
                null,
            ];
        }

        return [
            uploadButton,
            <div className={styles.browseButtonOR}>
                or
            </div>,
            <ButtonBig
                onClick={onRemoveImage}
                isRoseTheme
            >
                Remove
            </ButtonBig>,
        ];
    };

    const renderDragHereText = () => {
        if (!isDragOverField) {
            return null;
        }

        return (
            <div className={styles.dragImageHereText}>
                Drop the image here
            </div>
        );
    };

    const renderAdImage = () => {
        if (!props.imageUrl) {
            return null;
        }

        const adImageClasses = classNames({
            [styles.fieldImage]: true,
            [styles.lowOpacityElement]: isVisibleField || isDragOverField,
        });

        return (
            <div className={adImageClasses}>
                <img
                    className={styles.image}
                    src={props.imageUrl}
                    alt="Uploaded"
                    title="Uploaded"
                />
            </div>
        );
    };

    const renderBrowseText = () => {
        if (props.imageUrl) {
            return "Drag image here to load, or click browse to select file!";
        }

        return "Drag an image here or upload one from your computer.";
    };

    const renderField = () => {
        if (props.isLoading) {
            if (isDragOverField) {
                setDragOverField(false);
            }

            return (
                <div className={styles.formFieldLoader}>
                    <RequestLoader />
                </div>
            );
        }

        const adImg = renderAdImage();
        const [uploadButton, or, removeButton] = renderControls();

        const isHoveringOrHasImage = adImg && (!isVisibleField || isDragOverField);
        const noImageOrHovering = !adImg && isDragOverField;

        const formFieldClassName = classNames({
            [styles.formField]: true,
            [styles.formSolidBorder]: isHoveringOrHasImage,
            [styles.formFieldOnDragOver]: isDragOverField,
        });

        const browserTextClassName = classNames({
            [styles.browseText]: true,
            [styles.hiddenElement]: isHoveringOrHasImage || noImageOrHovering,
        });

        const buttonsClassName = classNames({
            [styles.buttons]: true,
            [styles.hiddenElement]: isHoveringOrHasImage || noImageOrHovering,
        });

        return (
            <div
                className={formFieldClassName}
                onFocus={() => {
                    setVisibleField(true);
                }}
                onMouseOver={() => {
                    setVisibleField(true);
                }}
                onMouseLeave={() => {
                    setVisibleField(false);
                }}
                onDragOver={() => {
                    setDragOverField(true);
                }}
                onDragLeave={() => {
                    setDragOverField(false);
                }}
            >
                <label
                    htmlFor="drop-area"
                    className={styles.label}
                >
                    {adImg}

                    <input
                        ref={dropAreaRef}
                        id="drop-area"
                        name="drop-area"
                        type="file"
                        accept="image/*"
                        className={styles.input}
                        onChange={onFileChange}
                    />

                    <div className={browserTextClassName}>
                        {renderBrowseText()}
                    </div>

                    <div className={buttonsClassName}>
                        {uploadButton}
                        {or}
                        {removeButton}
                    </div>

                    {renderDragHereText()}
                </label>
            </div>
        );
    };

    return (
        <>
            <div className={styles.uploader}>
                {renderField()}
            </div>
            <div className={styles.error}>
                {imageFileError}
            </div>
        </>
    );
};


UploaderImage.defaultProps = {
    imageUrl: "",
    imageRequiredWidth: null,
    imageRequiredHeight: null,

    onFileChange: () => { },

    isLoading: false,
};

export default UploaderImage;
