import { useState } from "react";


const getState = () => ({
    isSubmitted: false,
    isLoading: false,
    couponCode: "",
    discountMessage: "",
    error: "",
});

const useCoupon = () => {
    const [state, setState] = useState(getState());

    const setSubmitted = (couponCode) => {
        setState((prev) => ({
            ...prev,
            isSubmitted: true,
            isLoading: true,
            couponCode,
            discountMessage: "",
            error: "",
        }));
    };

    const setLoaded = (params = {}) => {
        setState((prev) => ({
            ...prev,
            isLoading: false,
            discountMessage: params.discountMessage,
            error: params.error,
        }));
    };

    const reset = () => {
        setState(getState());
    };

    return {
        state,
        setSubmitted,
        setLoaded,
        reset,
    };
};

export default useCoupon;
