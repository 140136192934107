import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const POPUP_POSITION = {
    bottom: "bottom_of_screen",
    top: "top_of_page",
    center: "center_of_screen",
    belowHeader: "bellow_header",
};

const PopupMessagePosition = (props) => {
    const className = classNames({
        [styles.position]: true,
        [styles.positionBottom]: POPUP_POSITION.bottom === props.position,
        [styles.positionTop]: POPUP_POSITION.top === props.position,
        [styles.positionCenter]: POPUP_POSITION.center === props.position,
        [styles.positionBelowHeader]: POPUP_POSITION.belowHeader === props.position,
    });

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

PopupMessagePosition.defaultProps = {
    position: POPUP_POSITION.bottom,
    children: null,
};

export default PopupMessagePosition;
