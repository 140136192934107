import storages from "@/base/lib/storage/storages.js";

/* --- */

const keys = {
    session: "session",

    themeAutomatic: "themeAutomatic",
    theme: "theme",

    readingMode: "readingMode",

    selectedGrade: "selectedGrade",

    userIds: "userIds",

    popups: "popups",

    dailyJuicesDefaultGrades: "dailyJuicesDefaultGrades",
    dailyJuices: "dailyJuices",
    dailyJuiceQuizzes: "dailyJuiceQuizzes",
    dailyJuiceConfetti: "dailyJuiceConfetti",
    teacherSelectedClass: "teacherSelectedClass",

    teacherSelectedClassTable: "teacherSelectedClassTable",
};

/* --- */

export const saveSession = (session) => {
    storages.localStorage.setItem(keys.session, session);
};

export const loadSession = () => {
    return storages.localStorage.getItem(keys.session) || "";
};

export const clearSession = () => {
    storages.localStorage.removeItem(keys.session);
};

/* --- */

export const saveAutomaticTheme = (isAutomatic) => {
    storages.localStorage.setItem(keys.themeAutomatic, isAutomatic);
};

export const loadAutomaticTheme = () => {
    const isAutomatic = storages.localStorage.getItem(keys.themeAutomatic);

    if (isAutomatic === "true") {
        return true;
    }

    if (isAutomatic === "false") {
        return false;
    }

    return true;
};

export const loadTheme = () => {
    return storages.localStorage.getItem(keys.theme) || "";
};

export const saveTheme = (theme) => {
    storages.localStorage.setItem(keys.theme, theme || "");
};

/* --- */

export const loadReadingMode = () => {
    const val = storages.localStorage.getItem(keys.readingMode);

    if (val === "true") {
        return true;
    }

    if (val === "false") {
        return false;
    }

    return false;
};

export const saveReadingMode = (isEnabledReadingMode) => {
    storages.localStorage.setItem(keys.readingMode, isEnabledReadingMode);
};

/* --- */

export const loadUserIds = () => {
    const userIds = storages.localStorage.getItem(keys.userIds) || "[]";

    try {
        return JSON.parse(userIds) || [];
    } catch {
        return [];
    }
};

export const saveUserIds = (userIds) => {
    storages.localStorage.setItem(keys.userIds, JSON.stringify(userIds));
};

/* --- */

export const loadPopups = () => {
    const popups = storages.localStorage.getItem(keys.popups) || "[]";

    try {
        return JSON.parse(popups) || [];
    } catch {
        return [];
    }
};

export const savePopups = (popups) => {
    const item = JSON.stringify(popups || "[]");
    storages.localStorage.setItem(keys.popups, item);
};

/* --- */

export const loadDailyJuicesDefaultGrades = () => {
    return storages.localStorage.getItem(keys.dailyJuicesDefaultGrades) || "";
};

export const saveDailyJuicesDefaultGrades = (grades) => {
    storages.localStorage.setItem(keys.dailyJuicesDefaultGrades, grades || "");
};

/* --- */

export const loadDailyJuices = () => {
    const juices = storages.localStorage.getItem(keys.dailyJuices) || "[]";

    try {
        return JSON.parse(juices) || [];
    } catch {
        return [];
    }
};

export const saveDailyJuices = (juices) => {
    const item = JSON.stringify(juices || "[]");
    storages.localStorage.setItem(keys.dailyJuices, item);
};

/* --- */

export const saveTeacherSelectedClass = (id) => {
    storages.localStorage.setItem(keys.teacherSelectedClass, id);
};


export const loadTeacherSelectedClass = () => {
    let classId = storages.localStorage.getItem(keys.teacherSelectedClass) || "";
    classId = parseInt(classId, 10);

    return Number.isNaN(classId) ? "" : classId;
};

export const clearTeacherSelectedClass = () => {
    storages.localStorage.removeItem(keys.teacherSelectedClass);
};

/* --- */

export const loadTeacherSelectedClassTableValues = () => {
    const values = storages.localStorage.getItem(keys.teacherSelectedClassTable) || "{}";

    try {
        return JSON.parse(values) || {};
    } catch {
        return {};
    }
};

export const loadTeacherSelectedClassTable = (userId) => {
    if (!userId) {
        return "";
    }

    const values = loadTeacherSelectedClassTableValues();

    return values[userId] || "";
};

export const saveTeacherSelectedClassTable = (userId, tableName) => {
    if (!userId) {
        return;
    }

    const values = loadTeacherSelectedClassTableValues();

    values[userId] = tableName;

    storages.localStorage.setItem(keys.teacherSelectedClassTable, JSON.stringify(values));
};

/* --- */

export const loadSelectedGrade = () => {
    return storages.localStorage.getItem(keys.selectedGrade) || "";
};

export const saveSelectedGrade = (grade) => {
    storages.localStorage.setItem(keys.selectedGrade, grade);
};
