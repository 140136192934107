import { useEffect, useState } from "react";

import scroll from "@/base/lib/scroll.js";


const hasScrollableContent = (dimensions) => {
    const scrollHeight = dimensions?.scrollHeight || 0;
    const height = dimensions?.height || 0;

    return scrollHeight >= height;
};

const useBodyScroll = (dimensions) => {
    const [bodyScrollHeight, setBodyScrollHeight] = useState(0);

    const [state, setState] = useState(() => ({
        hasScrollableContent: hasScrollableContent(dimensions),
        scrollbarWidth: scroll.getScrollbarWidth(),
    }));

    /* --- */

    useEffect(() => {
        let timeoutId = null;

        const onChange = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setBodyScrollHeight(globalThis.document.body.scrollHeight);
            }, 150);
        };

        let resizeObserver = null;

        if (globalThis.ResizeObserver) {
            resizeObserver = new ResizeObserver(onChange);
            resizeObserver.observe(globalThis.document.body);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.unobserve(globalThis.document.body);
            }
        };
    }, []);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            hasScrollableContent: hasScrollableContent({
                ...dimensions,
                scrollHeight: bodyScrollHeight,
            }),
        }));
    }, [
        dimensions,
        bodyScrollHeight,
    ]);

    /* --- */

    return state;
};

export default useBodyScroll;
