import React, { useEffect, useRef } from "react";

import classNames from "@/base/lib/class-names.js";
import scroll from "@/base/lib/scroll.js";
import Close from "@/base/components/close/index.js";

import styles from "./styles.module.css";


const PopupCommon = (props) => {
    const bgLayout = useRef(null);

    /* --- */

    useEffect(() => {
        if (props.hideBodyScrollOnOpen) {
            scroll.hideBodyScroll();
        }

        return () => {
            if (props.hideBodyScrollOnOpen) {
                scroll.unhideBodyScroll();
            }
        };
    }, [props.hideBodyScrollOnOpen]);

    /* --- */

    const onClose = (evt) => {
        if (props.hideBodyScrollOnOpen) {
            scroll.unhideBodyScroll();
        }

        props.onClose(evt);
    };

    const onLayoutClick = (evt) => {
        if (props.closeOnLayoutClick && bgLayout.current === evt.target) {
            onClose(evt);
        }
    };

    /* --- */

    let close = null;

    if (props.showClose) {
        close = (
            <Close
                className={styles.popupClose}
                onClose={onClose}
            />
        );
    }

    const popupClassName = classNames({
        [styles.popup]: true,
        [props.className]: !!props.className,
    });

    return (
        <div
            ref={bgLayout}
            className={popupClassName}
            onMouseDown={onLayoutClick}
            onKeyDown={onLayoutClick}
            role="button"
            tabIndex={props.disableTabIndex ? "-1" : ""}
            data-comment={props.dataComment}
        >
            {close}
            {props.children}
        </div>
    );
};

PopupCommon.defaultProps = {
    className: "",
    dataComment: "popup-common",
    showClose: false,
    children: null,
    closeOnLayoutClick: true,
    hideBodyScrollOnOpen: false,
    disableTabIndex: true,
    onClose: () => { },
};

export default PopupCommon;
