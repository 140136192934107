// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LkQG4UXGMCHQIVisUStc {\n    position: relative;\n\n    width: 100%;\n    height: auto;\n    min-height: 3rem;\n\n    outline: none;\n}\n\n.KM2Z4AN2nLT1zv2yYbUv {\n    cursor: pointer;\n    outline: none;\n}\n\n.NbSIXni4o7_lgaAqdlIu {\n    position: relative;\n}\n\n.GnLiWpxm2VBc9RtNXBOC {\n    display: block;\n\n    margin: 0 auto;\n\n    width: auto;\n    max-width: 100%;\n\n    height: auto;\n    max-height: 35rem;\n}\n\n.OZLOAevsqugyRltUY5xe {\n    position: absolute;\n    bottom: 0.7rem;\n    right: 0.8rem;\n}\n\n.OZLOAevsqugyRltUY5xe > svg {\n    width: 3.4rem;\n    height: 3.4rem;\n}\n\n@media only screen and (min-width: 601px) {\n    .GnLiWpxm2VBc9RtNXBOC {\n        border-radius: 0.8rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/featured-image/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;IACZ,gBAAgB;;IAEhB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;;IAEd,cAAc;;IAEd,WAAW;IACX,eAAe;;IAEf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".featuredImgContainer {\n    position: relative;\n\n    width: 100%;\n    height: auto;\n    min-height: 3rem;\n\n    outline: none;\n}\n\n.featuredImgContainerClickable {\n    cursor: pointer;\n    outline: none;\n}\n\n.featuredImgWrapper {\n    position: relative;\n}\n\n.featuredImg {\n    display: block;\n\n    margin: 0 auto;\n\n    width: auto;\n    max-width: 100%;\n\n    height: auto;\n    max-height: 35rem;\n}\n\n.featuredImgFullscreen {\n    position: absolute;\n    bottom: 0.7rem;\n    right: 0.8rem;\n}\n\n.featuredImgFullscreen > svg {\n    width: 3.4rem;\n    height: 3.4rem;\n}\n\n@media only screen and (min-width: 601px) {\n    .featuredImg {\n        border-radius: 0.8rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featuredImgContainer": "LkQG4UXGMCHQIVisUStc",
	"featuredImgContainerClickable": "KM2Z4AN2nLT1zv2yYbUv",
	"featuredImgWrapper": "NbSIXni4o7_lgaAqdlIu",
	"featuredImg": "GnLiWpxm2VBc9RtNXBOC",
	"featuredImgFullscreen": "OZLOAevsqugyRltUY5xe"
};
export default ___CSS_LOADER_EXPORT___;
