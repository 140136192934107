// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bQ_KWiII4QC7wr63wuuu {\n    text-align: center;\n    font-weight: 600;\n}\n\n.ZUH_JFjieyL2vXwckk7q {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-email-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".header {\n    text-align: center;\n    font-weight: 600;\n}\n\n.content {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "bQ_KWiII4QC7wr63wuuu",
	"content": "ZUH_JFjieyL2vXwckk7q"
};
export default ___CSS_LOADER_EXPORT___;
