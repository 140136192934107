import array from "@/base/lib/array.js";


const ACHIEVEMENTS = {
    storiesRead: {
        value: "storiesRead",
        label: "Stories Read",
    },
    questionsAttempted: {
        value: "questionsAttempted",
        label: "Questions Attempted",
    },
};

/* --- */

const getAchievements = () => {
    return { ...ACHIEVEMENTS };
};

const getValueStoriesRead = () => {
    return ACHIEVEMENTS.storiesRead.value;
};

const getValueQuestionsAttempted = () => {
    return ACHIEVEMENTS.questionsAttempted.value;
};

const getAchievementsValueByType = (store, type) => {
    const values = store?.studentAchievements?.achievements?.data || [];

    for (let i = 0; i < values.length; i += 1) {
        const val = values[i];

        if (val.type === type) {
            return val.value;
        }
    }

    return 0;
};

const getAchievementValues = (achievements) => {
    const storiesRead = array.findOneByType(
        achievements,
        ACHIEVEMENTS.storiesRead.value,
    )?.value || 0;

    const questionsAttempted = array.findOneByType(
        achievements,
        ACHIEVEMENTS.questionsAttempted.value,
    )?.value || 0;

    return {
        storiesRead,
        questionsAttempted,
    };
};

const isAchievementsUpdated = (achievements) => {
    if (!achievements) {
        return false;
    }

    for (let i = 0; i < achievements.length; i += 1) {
        if (achievements[i].isUpdated) {
            return true;
        }
    }

    return false;
};

/* --- */

const isStoriesRead = (value) => {
    return value === ACHIEVEMENTS.storiesRead.value;
};

const isQuestionsAttempted = (value) => {
    return value === ACHIEVEMENTS.questionsAttempted.value;
};

/* --- */

export default {
    getAchievements,
    getAchievementValues,
    getValueQuestionsAttempted,
    getValueStoriesRead,
    getAchievementsValueByType,

    isAchievementsUpdated,

    isStoriesRead,
    isQuestionsAttempted,
};
