// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uqPzNzcjQ4YeoFYordxp {\n    margin-top: 0.6rem;\n\n    display: flex;\n    justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./base/components/image-with-zoom/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".tools {\n    margin-top: 0.6rem;\n\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tools": "uqPzNzcjQ4YeoFYordxp"
};
export default ___CSS_LOADER_EXPORT___;
