const setTitle = (title) => {
    if (window.document) {
        window.document.title = title;
    }
};

const setTheme = (theme) => {
    const html = document.querySelector("html");

    if (html) {
        html.dataset.theme = theme;
    }
};

const getElement = (selector) => {
    return document.querySelector(selector);
};

const isElementInViewport = (element, offsetTop = 0, offsetBottom = 0) => {
    if (!element?.getBoundingClientRect) {
        return false;
    }

    const rect = element.getBoundingClientRect();

    const bottom = (window.innerHeight || document.documentElement.clientHeight) - offsetBottom;
    const right = window.innerWidth || document.documentElement.clientWidth;

    return rect.top >= offsetTop
        && rect.left >= 0
        && rect.bottom <= bottom
        && rect.right <= right;
};

export default {
    setTitle,
    setTheme,
    getElement,
    isElementInViewport,
};
