import React, { useEffect } from "react";

import classNames from "@/base/lib/class-names.js";

import IconClose from "@/base/icons/close/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import Text from "@/base/components/text/index.js";
import Button from "@/base/components/button/index.js";

import styles from "./styles.module.css";


const PopupMessage = (props) => {
    const getMessageStyles = () => {
        const ss = {};

        if (props.colors.backgroundColor) {
            ss.backgroundColor = props.colors.backgroundColor;
        }

        if (props.colors.textColor) {
            ss.color = props.colors.textColor;
        }

        return ss;
    };

    /* --- */

    useEffect(() => {
        props.onMount();
    }, []);

    /* --- */

    const renderTitle = () => {
        if (!props.title) {
            return null;
        }

        return (
            <div className={styles.title}>
                {props.title}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <Text>
                {props.content}
            </Text>
        );
    };

    const renderButton = () => {
        if (!props.buttonText) {
            return null;
        }

        return (
            <Button
                theme="blue"
                onClick={props.onButtonClick}
            >
                {props.buttonText}
            </Button>
        );
    };

    const messageClassName = classNames({
        [styles.popupMessage]: true,
        [styles.popupMessageHidden]: props.isHidden,
    });

    const contentBoxStyles = {};

    if (props.maxWidth) {
        contentBoxStyles.maxWidth = `${props.maxWidth}px`;
    }

    return (
        <div
            className={messageClassName}
            style={getMessageStyles()}
        >
            <div className={styles.contentBox} style={contentBoxStyles}>
                <div className={styles.message}>
                    {renderTitle()}
                    {renderContent()}
                </div>
                <div className={styles.control}>
                    {renderButton()}
                    <IconClickable onClick={props.onClose}>
                        <IconClose
                            isWhite
                        />
                    </IconClickable>
                </div>
            </div>
        </div>
    );
};

PopupMessage.defaultProps = {
    title: "",
    content: "",
    maxWidth: 0,
    colors: {
        backgroundColor: "",
        textColor: "",
    },
    onMount: () => { },
    onButtonClick: () => { },
    onClose: () => { },
    isHidden: false,
};

export default PopupMessage;
