import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        selectPollAnswer(params) {
            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.studentPollAnswerSelect,
                objectId: params.juiceId,
                subObjectId: params.storyId,
                subSubObjectId: params.answerId,
            };

            debugEvent("student poll answer select", event);
            methods.sendEvent(event);
        },
    };
};
