import text from "@/base/text/index.js";


const loadPaymentDetails = (services, params) => {
    const { api } = services;
    const { intentId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.getPaymentDetails({
            session: state.user.session,
            intentId,
        });

        let price = null;
        let error = null;

        if (res.ok) {
            price = res.data.amount;
        } else {
            error = res.error || text.error;
        }

        return {
            price,
            error,
        };
    };
};

const setPaymentActivityLog = (services, params) => {
    const { api } = services;
    const { intentId, stripeRes } = params;

    return async (dispatch, getState) => {
        const state = getState();

        api.subscription.paymentLogActivity({
            session: state.user.session,
            intentId,
            body: stripeRes,
        });
    };
};

const setPaymentCompleted = (services, params) => {
    const { api } = services;
    const { intentId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.subscription.paymentCompleted({
            session: state.user.session,
            intentId,
        });

        let message = "";
        let error = "";

        if (res.ok) {
            message = res?.data?.thankYouMessage || "";
        } else {
            error = res.error || text.error;
        }

        return {
            message,
            error,
        };
    };
};


export default {
    loadPaymentDetails,
    setPaymentActivityLog,
    setPaymentCompleted,
};
