import React from "react";


const StripeContext = React.createContext({
    stripe: null,
    loadStripe: null,

    isInitialized() {},
    initStripe() {},
    loadStripeLib() {},
});

export default StripeContext;
