export default (apiURL, methods) => {
    return {
        signIn(params = {}) {
            // NOTE: action=lms_sign_in_by_code
            const url = `${apiURL}/lms/sign-in`;

            return methods.get3(url, {
                code: params.code,
            });
        },

        providersSummary(params = {}) {
            // NOTE: action=lms_get_providers_summary_info
            const url = `${apiURL}/lms/providers/summary`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        syncClasses(params = {}) {
            // NOTE: action=lms_sync_classes
            const url = `${apiURL}/lms/classes/sync`;

            return methods.postForm(url, {
                session_id: params.session,
                classes: JSON.stringify(params.classes),
            });
        },
    };
};
