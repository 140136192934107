const staticFilesWrapper = () => {
    const staticFiles = STATIC_FILES; // eslint-disable-line no-undef

    if (window.Proxy === undefined) {
        return staticFiles;
    }

    const handler = {
        get(target, name) {
            if (name in target) {
                return target[name];
            }

            console.error(`[error] unknown file path "${name}"`); // eslint-disable-line no-console
            return "";
        },
    };

    return new Proxy(staticFiles, handler); // eslint-disable-line compat/compat
};

export default staticFilesWrapper();
