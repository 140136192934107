const loadPageBySlug = (services, params) => {
    const { actions, api } = services;

    return async (dispatch) => {
        dispatch(actions.pages.setPageLoading({
            slug: params.slug,
        }));

        const res = await api.pages.getPageBySlug({
            slug: params.slug,
        });

        dispatch(actions.pages.setPage({
            slug: params.slug,
            page: res.page || {},
            error: res.error || "",
        }));
    };
};

export default {
    loadPageBySlug,
};
