// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ff2qg9tS2wRInuq2a8Cb {\n    min-height: 20rem;\n\n    background-color: var(--background-color);\n}\n\n.lMuDCLx8FH8Iyo0irc9E { }\n\n.AH184ysYSILuFST4R6l4 { }\n", "",{"version":3,"sources":["webpack://./base/components/forms/text-editor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;IAEjB,yCAAyC;AAC7C;;AAEA,wBAAkB;;AAElB,wBAAS","sourcesContent":[".formField {\n    min-height: 20rem;\n\n    background-color: var(--background-color);\n}\n\n.formFieldError { }\n\n.error { }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": "Ff2qg9tS2wRInuq2a8Cb",
	"formFieldError": "lMuDCLx8FH8Iyo0irc9E",
	"error": "AH184ysYSILuFST4R6l4"
};
export default ___CSS_LOADER_EXPORT___;
