import * as types from "./types.js";


export const setPageLoading = (payload) => ({
    type: types.SET_PAGE_LOADING,
    payload,
});

export const setPage = (payload) => ({
    type: types.SET_PAGE,
    payload,
});

export default {};
