import React, { useState } from "react";
import { Formik } from "formik";

import LMS from "@/base/project/lms.js";
import Grades from "@/base/project/grades.js";

import object from "@/base/lib/object.js";

import IconMinusInCircle from "@/base/icons/minus-in-circle/index.js";
import IconPlusInCircle from "@/base/icons/plus-in-circle/index.js";

import ButtonBig from "@/base/components/button-big/index.js";
import SelectCustom from "@/base/components/select-custom/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";
import IconClickable from "@/base/components/icon-clickable/index.js";
import Error from "@/base/components/forms/error/index.js";
import Checkbox from "@/base/components/forms/checkbox/index.js";
import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const LMSClassesForm = (props) => {
    const [visibleSchools, setVisibleSchools] = useState({});

    /* --- */

    const isDisabled = (formActions) => {
        const hasValues = Object.keys(formActions.values).length > 0;
        const hasErrors = Object.keys(formActions.errors).length > 0;

        return !hasValues
            || !object.hasValue(formActions.values)
            || hasErrors
            || formActions.isSubmitting;
    };

    /* --- */

    const validateForm = (values) => {
        const errorsValue = !object.hasValue(values);

        const errors = {};

        if (errorsValue) {
            errors.values = "Please select at least one class";
        }

        return errors;
    };

    /* --- */

    const onToggleSchool = (schoolId) => {
        setVisibleSchools((prev) => ({
            ...prev,
            [schoolId]: !prev[schoolId],
        }));
    };

    const onSubmit = (values, { setSubmitting }) => {
        const classes = [];

        Object.keys(values).forEach((key) => {
            if (values[key].isChecked) {
                const classId = key;
                const schoolClass = LMS.getProviderClassById(props.providers, classId);

                if (schoolClass) {
                    classes.push({
                        ...schoolClass,
                        grade: values[key].grade,
                    });
                }
            }
        });

        props.onSubmit({
            classes,
            setSubmitting,
        });
    };

    /* --- */

    const renderErrors = (errors) => {
        if (!errors.values) {
            return null;
        }

        return (
            <Error>{errors.values}</Error>
        );
    };

    const renderSchoolClasses = (school, formActions) => {
        const {
            values,
            setFieldValue,
        } = formActions;

        const schoolClasses = school.classes || [];

        const classes = schoolClasses.map((cl, index) => {
            const id = cl.id || null;

            const isCheckedClass = !!values[id]?.isChecked;
            const classGrade = values?.[id]?.grade || Grades.getMaxGradeValue();

            let gradeSelector = null;

            if (isCheckedClass) {
                gradeSelector = (
                    <SelectCustom
                        key={`${school.id}-${id}`}
                        options={Grades.getGradesOptions()}
                        selected={classGrade}
                        onSelect={(val) => {
                            setFieldValue(id, {
                                isChecked: true,
                                grade: val,
                            });
                        }}
                        isDropUp={index >= 5 && index > schoolClasses.length - 4}
                    />
                );
            }

            return (
                <div className={styles.classRow}>
                    <Checkbox
                        key={`${school.id}-${id}`}
                        name={id}
                        label={cl.name}
                        checked={isCheckedClass}
                        onChange={(evt) => {
                            setFieldValue(id, {
                                isChecked: evt.target.checked,
                                grade: classGrade,
                            });
                        }}
                    />
                    {gradeSelector}
                </div>
            );
        });

        return (
            <div className={styles.classes}>
                {classes}
            </div>
        );
    };

    const renderSchool = (school, formActions) => {
        const isVisible = visibleSchools[school.id];

        let classes = null;
        let icon = null;

        if (isVisible) {
            classes = renderSchoolClasses(school, formActions);
            icon = (
                <IconMinusInCircle
                    title="Collapse"
                />
            );
        } else {
            icon = (
                <IconPlusInCircle
                    title="Expand"
                />
            );
        }

        return (
            <div className={styles.school}>
                <div className={styles.schoolName}>
                    <IconClickable
                        onClick={() => {
                            onToggleSchool(school.id);
                        }}
                    >
                        {icon}
                    </IconClickable>
                    <DivButton
                        onClick={() => {
                            onToggleSchool(school.id);
                        }}
                    >
                        {school.name}
                    </DivButton>
                </div>
                {classes}
            </div>
        );
    };

    const renderProvider = (provider, formActions) => {
        const schools = provider.schools || [];
        const ss = schools.map((s) => renderSchool(s, formActions));

        return (
            <div
                key={provider.providerName}
                className={styles.provider}
            >
                <div className={styles.providerName}>
                    {provider.providerName}
                </div>
                {ss}
            </div>
        );
    };

    const renderProviders = (formActions) => {
        if (props.providers.length === 0) {
            return (
                <div className={styles.message}>
                    No providers
                </div>
            );
        }

        const ps = props.providers.map((p) => renderProvider(p, formActions));

        return (
            <WithScroll className={styles.providers}>
                {ps}
            </WithScroll>
        );
    };

    const renderButtonSubmit = (formActions) => {
        let title = "Link Classes";

        if (formActions.isSubmitting) {
            title = "Loading...";
        }

        return (
            <div className={styles.buttons}>
                <ButtonBig
                    disabled={isDisabled(formActions)}
                >
                    {title}
                </ButtonBig>
            </div>
        );
    };

    const renderForm = (formActions) => {
        const { handleSubmit } = formActions;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                {renderProviders(formActions)}
                {renderErrors(formActions.errors)}
                {renderButtonSubmit(formActions)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

LMSClassesForm.defaultProps = {
    initialValues: {},
    onSubmit: () => { },
};

export default LMSClassesForm;
