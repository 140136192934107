import React from "react";

import IconClose from "@/base/icons/close/index.js";

import PopupCommon from "@/base/components/popup-common-v2/index.js";
import IconClickable from "@/base/components/icon-clickable/index.js";

import styles from "./styles.module.css";


const PopupWithTitle = (props) => {
    const renderClose = () => {
        if (props.disabledClose) {
            return null;
        }

        return (
            <IconClickable
                className={styles.buttonClose}
                onClick={props.onClose}
            >
                <IconClose isBlack />
            </IconClickable>
        );
    };

    const renderContentPopup = () => {
        return (
            <div className={styles.window}>
                {renderClose()}
                <div className={styles.bar}>
                    {props.title}
                </div>
                {props.children}
            </div>
        );
    };

    return (
        <PopupCommon
            onClose={props.onClose}
            disabledClose={props.disabledClose}
        >
            {renderContentPopup()}
        </PopupCommon>
    );
};

PopupWithTitle.defaultProps = {
    title: "",
    children: null,
    disabledClose: false,
    onClose: () => { },
};

export default PopupWithTitle;
