import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const IconWrapper = (props) => {
    const iconClassName = classNames({
        [props.className]: props.className,
        [styles.iconGrey]: true,
        [styles.iconWhite]: props.isWhite,
        [styles.iconBlack]: props.isBlack,
        [styles.iconRed]: props.isRed,
        [styles.iconOrange]: props.isOrange,
        [styles.iconSky]: props.isSky,
        [styles.iconGreen]: props.isGreen,
    });

    let title = null;

    if (props.title) {
        title = (
            <title>{props.title}</title>
        );
    }

    return props.children({
        className: iconClassName,
        title,
    });
};

IconWrapper.defaultProps = {
    className: "",
    title: "",

    isWhite: false,
    isBlack: false,
    isRed: false,
    isOrange: false,
    isSky: false,
    isGreen: false,
};

export default IconWrapper;
