import translationVocabulary from "./translation-vocabulary.js";


const loadWord = (services, params) => {
    const { api, actions } = services;
    const { wordId } = params;

    return async (dispatch, getState) => {
        if (!wordId) {
            return;
        }

        const state = getState();

        if (state.vocabulary.wordsById?.[wordId]?.isLoaded) {
            return;
        }

        const res = await api.vocabulary.getWord({
            session: state.user.session,
            wordId,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.vocabulary.setVocabularyWord({
            word: res.word,
            definitions: res.definitions,
        }));
    };
};

const openPopup = (services, params) => {
    return async (dispatch, getState) => {
        if (!params.word) {
            return {};
        }

        const offsetY = params.offsetY || 0;
        const translationLanguage = params.language || "";

        const state = getState();

        dispatch(services.actions.vocabulary.openWordPopup({
            translationLanguage,
            wordAlias: params.word,
            offsetY,
        }));

        const res = await services.api.vocabulary.getWordByText({
            session: state.user?.session || "",
            word: params.word,
        });

        let word = {};
        let definitions = [];
        let error = "";

        if (res.ok) {
            word = res.word || {};
            definitions = res.definitions || [];
        } else {
            error = res.error || "Cannot load word";
        }

        dispatch(services.actions.vocabulary.setVocabularyWordAndPopup({
            wordAlias: params.word,
            word,
            definitions,
            error,
        }));

        return {
            word,
            error,
        };
    };
};

const closePopup = (services) => {
    return (dispatch) => {
        dispatch(services.actions.vocabulary.closeWordPopup());
    };
};

const openPopupAndLoadTranslation = (services, params) => {
    return async (dispatch) => {
        const { word } = await dispatch(openPopup(services, params));

        const wordId = word?.id || null;

        if (wordId && params.language) {
            dispatch(translationVocabulary.translateVocabWord(
                services,
                {
                    wordId,
                    language: params.language,
                },
            ));
        }

        return {
            word,
        };
    };
};

export default {
    loadWord,

    openPopupAndLoadTranslation,
    openPopup,
    closePopup,
};
