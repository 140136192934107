import React from "react";

import Theme from "@/base/project/theme.js";

import ThemeSelector from "@/base/components/theme-selector/index.js";

import styles from "./styles.module.css";


const THEMES = Theme.getThemes();

const ThemeSelectors = (props) => {
    return (
        <div className={styles.selectors}>
            <ThemeSelector
                onClick={() => { props.onChange(THEMES.LIGHT); }}
                selected={props.selected === THEMES.LIGHT}
                disableOutline={props.disableOutline}
                isLight
            />
            <ThemeSelector
                onClick={() => { props.onChange(THEMES.DARK); }}
                selected={props.selected === THEMES.DARK}
                disableOutline={props.disableOutline}
                isDark
            />
            <ThemeSelector
                onClick={() => { props.onChange(THEMES.HIGH_CONTRAST); }}
                selected={props.selected === THEMES.HIGH_CONTRAST}
                disableOutline={props.disableOutline}
                isHighContrast
            />
        </div>
    );
};

ThemeSelectors.defaultProps = {
    selected: Theme.getDefaultTheme(),
    onChange: () => { },
    disableOutline: false,
};

export default ThemeSelectors;
