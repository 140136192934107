import React, { useCallback } from "react";

// NOTE: Web Accessibility requires key handlers.
//       Every button must have role="button" and onKeyDown event handler.
//       Allow only "Enter" key to be propagated.
const DivButton = (props) => {
    const onClick = useCallback((evt) => {
        if (evt.code === undefined
            || evt.code === "Enter") {
            props.onClick(evt);
        }
    }, [props.onClick]);

    if (props.dataComment) {
        return (
            <div
                className={props.className}
                style={props.style}
                onClick={onClick}
                onKeyDown={onClick}
                role="button"
                tabIndex={props.tabIndex}
                data-comment={props.dataComment}
            >
                {props.children}
            </div>
        );
    }

    return (
        <div
            data-comment={props.dataComment}
            className={props.className}
            style={props.style}
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex={props.tabIndex}
        >
            {props.children}
        </div>
    );
};

DivButton.defaultProps = {
    className: "",
    style: {},
    tabIndex: "-1",
    dataComment: "",
    children: null,
    onClick: () => { },
};

export default DivButton;
