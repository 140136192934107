import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    assignmentsByGroupCode: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_ASSIGNMENTS_BY_GROUP_CODE: {
            const { groupCode, data, error } = action.payload;

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        data,
                        error,
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_VIEWED: {
            const { groupCode, storyId } = action.payload;

            const data = state.assignmentsByGroupCode?.[groupCode]?.data;
            const stories = data?.stories || [];

            const newStories = [];

            for (let i = 0; i < stories.length; i += 1) {
                const story = stories[i];

                if (story.ID === storyId) {
                    story.isUserOpenedStory = true;
                }

                newStories.push(story);
            }

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...state.assignmentsByGroupCode[groupCode],
                        data: {
                            ...data,
                            stories: newStories,
                        },
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_RATE: {
            const { groupCode, storyId, rating } = action.payload;

            const data = state.assignmentsByGroupCode?.[groupCode]?.data;
            const stories = data?.stories || [];

            const newStories = [];

            for (let i = 0; i < stories.length; i += 1) {
                const story = stories[i];

                if (story.ID === storyId) {
                    story.rate = rating;
                }

                newStories.push(story);
            }

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...state.assignmentsByGroupCode[groupCode],
                        data: {
                            ...data,
                            stories: newStories,
                        },
                    },
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_GROUP_CODE_STORY_EXTRA_JUICE_RATE: {
            const {
                groupCode,
                storyId,
                extraJuiceId,
                rating,
            } = action.payload;

            const data = state.assignmentsByGroupCode?.[groupCode]?.data;
            const stories = data?.stories || [];

            const newStories = [];

            for (let i = 0; i < stories.length; i += 1) {
                const story = stories[i];

                if (story.ID === storyId) {
                    const extraJuices = story.extraJuices || [];

                    for (let j = 0; j < extraJuices.length; j += 1) {
                        const ej = extraJuices[j];

                        if (ej.id === extraJuiceId) {
                            story.extraJuices[j].rate = rating;
                        }
                    }
                }

                newStories.push(story);
            }

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        ...state.assignmentsByGroupCode[groupCode],
                        data: {
                            ...data,
                            stories: newStories,
                        },
                    },
                },
            };
        }

        case types.SET_ASSIGNMENT_STORY_QUESTION: {
            const { groupCode, quizId, question } = action.payload;
            const { assignmentsByGroupCode } = state;

            const assignment = assignmentsByGroupCode?.[groupCode] || null;

            if (!assignment?.data?.stories) {
                return state;
            }

            assignment.data.stories = assignment.data.stories.map((s) => {
                const quizzes = (s.quizzes || []).map((q) => {
                    if (q.quizId === quizId && q.id === question.id) {
                        return {
                            ...q,
                            ...question,
                        };
                    }

                    return q;
                });

                return {
                    ...s,
                    quizzes,
                };
            });

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...assignmentsByGroupCode,
                    [groupCode]: assignment,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
