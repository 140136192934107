export default (apiURL, methods) => {
    return {
        sendError(params = {}) {
            // NOTE: action=send_js_error
            const url = `${apiURL}/error`;

            return methods.postForm(url, {
                session_id: params.session,
                error: params.error || {},
            });
        },
    };
};
