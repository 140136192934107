import { useState } from "react";


const initialState = {
    isVisibleMenu: false,
    isCopyLinkLoading: false,
    isGoogleClassroomLoading: false,
};

const useButtonShare = () => {
    const [state, setState] = useState({
        ...initialState,
    });

    const toggleMenu = () => {
        setState((prev) => ({
            ...prev,
            isVisibleMenu: !prev.isVisibleMenu,
        }));
    };

    const setVisible = (isVisible) => {
        setState((prev) => ({
            ...prev,
            isVisibleMenu: isVisible,
        }));
    };

    const setCopyLinkLoading = (isLoading) => {
        setState((prev) => ({
            ...prev,
            isCopyLinkLoading: isLoading,
        }));
    };

    const setGoogleClassroomLoading = (isLoading) => {
        setState((prev) => ({
            ...prev,
            isGoogleClassroomLoading: isLoading,
        }));
    };

    return {
        state,
        toggleMenu,
        setVisible,
        setCopyLinkLoading,
        setGoogleClassroomLoading,
    };
};

export default useButtonShare;
