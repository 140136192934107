import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconDanger from "@/base/icons/danger/index.js";
import IconUserAdd from "@/base/icons/user-add/index.js";
import IconCheckInCircle from "@/base/icons/check-in-circle/index.js";

import styles from "./styles.module.css";


const AlertBox = (props) => {
    const isPrimary = props.theme === "primary";
    const isDanger = props.theme === "danger";
    const isBlackSuccess = props.theme === "black-success";
    const isBlackError = props.theme === "black-error";
    const isAddStudent = props.theme === "add-student";

    /* --------- */

    const renderIcon = () => {
        let icon = null;

        if (isDanger) {
            icon = (
                <IconDanger
                    title="danger"
                    isRed
                />
            );
        }

        if (isBlackSuccess) {
            icon = (
                <IconCheckInCircle
                    title="success"
                    isGreen
                />
            );
        }

        if (isBlackError) {
            icon = (
                <IconDanger
                    title="error"
                    isOrange
                />
            );
        }

        if (isAddStudent) {
            icon = (
                <IconUserAdd
                    title="Add student"
                    isSky
                />
            );
        }

        if (icon) {
            return (
                <div className={styles.icon}>
                    {icon}
                </div>
            );
        }

        return null;
    };

    /* --------- */

    const boxClassName = classNames({
        [styles.box]: true,
        [styles.primary]: isPrimary,
        [styles.danger]: isDanger,
        [styles.blackSuccess]: isBlackSuccess,
        [styles.blackError]: isBlackError,
        [styles.addStudent]: isAddStudent,
        [styles.isHiddenBox]: !props.isVisibleBox,
    });

    const icon = renderIcon();

    const boxMessageClassName = classNames({
        [styles.boxMessage]: true,
        [styles.boxMessageWithIcon]: icon,
    });

    return (
        <div className={boxClassName}>
            <div className={boxMessageClassName}>
                {icon}
                <div className={styles.boxMessageWithControls}>
                    {props.children}
                    <div className={styles.rightControls}>
                        {props.rightControls}
                    </div>
                </div>
            </div>
        </div>
    );
};

AlertBox.defaultProps = {
    isVisibleBox: true,
    theme: "primary",
    rightControls: [],
    children: null,
};

export default AlertBox;
