import React, {
    useMemo,
    useRef,
    useState,
    useCallback,
} from "react";

import array from "@/base/lib/array.js";
import classNames from "@/base/lib/class-names.js";

import IconCaret from "@/base/icons/caret/index.js";
import IconCheck from "@/base/icons/check/index.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";
import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";

import DivButton from "@/base/components/div-button/index.js";

import useToggleDropdown from "./use-toggle-dropdown.js";

import styles from "./styles.module.css";


const ToggleDropdown = (props) => {
    const toggleDropdownRef = useRef(null);

    const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);

    const selectedValue = useMemo(() => {
        return array.findOneByValue(props.options, props.selectedOption);
    }, [props.options, props.selectedOption]);

    /* --- */

    const onChange = useCallback((values) => {
        setIsVisibleDropdown(false);

        if (props.isDisabled) {
            return;
        }

        props.onChange({
            isChecked: true,
            selectedValue: values.value,
            selectedName: values.name,
        });
    }, [props.onChange, props.isDisabled]);

    const onToggle = useCallback(() => {
        if (!props.selectedOption) {
            setIsVisibleDropdown((prev) => !prev);
            return;
        }

        if (props.isDisabled) {
            return;
        }

        const val = array.findOneByValue(props.options, props.selectedOption);

        props.onChange({
            isChecked: !props.isChecked,
            selectedValue: props.selectedOption,
            selectedName: val?.name || "",
        });
    }, [
        props.isChecked,
        props.isDisabled,
        props.selectedOption,
        props.onChange,
    ]);

    const onToggleDropdown = useCallback(() => {
        if (props.isDisabled) {
            return;
        }

        setIsVisibleDropdown((prev) => !prev);
    }, [props.isDisabled]);

    /* --- */

    useGlobalClose((evt) => {
        if (toggleDropdownRef?.current?.contains
            && toggleDropdownRef.current.contains(evt.target)) {
            return;
        }

        setIsVisibleDropdown(false);
    }, []);

    /* --- */

    const renderToggle = () => {
        const toggleClassName = classNames({
            [styles.toggle]: true,
            [styles.toggleChecked]: props.isChecked,
            [styles.buttonDisabled]: props.isDisabled,
        });

        return (
            <DivButton
                className={toggleClassName}
                onClick={onToggle}
            >
                {props.iconToggle}
            </DivButton>
        );
    };

    const renderValue = () => {
        if (!selectedValue) {
            return null;
        }

        const toggleClassName = classNames({
            [styles.toggleValue]: true,
            [styles.buttonDisabled]: props.isDisabled,
            [styles.toggleValueChecked]: props.isChecked,
        });

        return (
            <DivButton
                className={toggleClassName}
                onClick={onToggle}
            >
                {selectedValue?.name || ""}
            </DivButton>
        );
    };

    const renderCaret = () => {
        const caretClassName = classNames({
            [styles.toggleDropdownCaret]: true,
            [styles.toggleDropdownCaretUp]: isVisibleDropdown,
            [styles.toggleDropdownCaretChecked]: props.isChecked,
            [styles.buttonDisabled]: props.isDisabled,
        });

        const caretIconClassName = classNames({
            [styles.toggleDropdownCaretIcon]: true,
            [styles.toggleDropdownCaretIconUp]: isVisibleDropdown,
        });

        return (
            <DivButton
                className={caretClassName}
                onClick={onToggleDropdown}
            >
                <IconCaret
                    className={caretIconClassName}
                    isOrange={props.isChecked}
                />
            </DivButton>
        );
    };

    const renderDropdownHeader = () => {
        if (!props.name) {
            return null;
        }

        return (
            <div className={styles.dropdownHeader}>
                {props.name}
            </div>
        );
    };

    const renderDropdownOptions = () => {
        return props.options.map((option) => {
            const icon = props.selectedOption === option.value
                ? <IconCheck />
                : null;

            return (
                <DivButton
                    className={styles.dropdownOption}
                    onClick={() => {
                        onChange(option);
                    }}
                >
                    {option.name}
                    {icon}
                </DivButton>
            );
        });
    };

    const renderDropdown = () => {
        if (!isVisibleDropdown || props.options.length === 0) {
            return null;
        }

        const dropdownClassName = classNames({
            [scrollbarStyles.scrollbar]: true,
            [styles.dropdown]: true,
            [styles.dropdownCentered]: props.isCentered,
        });

        return (
            <div className={dropdownClassName}>
                {renderDropdownHeader()}
                {renderDropdownOptions()}
            </div>
        );
    };

    const toggleClassName = classNames({
        [styles.toggleDropdown]: true,
        [styles.toggleDropdownWithValue]: selectedValue,
    });

    return (
        <div
            ref={toggleDropdownRef}
            className={toggleClassName}
        >
            {renderToggle()}
            {renderValue()}
            {renderCaret()}

            {renderDropdown()}
        </div>
    );
};

ToggleDropdown.defaultProps = {
    name: "",
    options: [],
    selectedOption: "",
    iconToggle: null,
    isDisabled: false,
    isChecked: false,
    isCentered: false,
    onChange: () => { },
};

export {
    useToggleDropdown,
};

export default ToggleDropdown;
