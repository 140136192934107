import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconCheck from "@/base/icons/check/index.js";

import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const Option = (props) => {
    const onSelect = (evt) => {
        if (props.isDisabled || !props.isClickable) {
            return;
        }

        props.onSelect(evt);
    };

    /* --- */

    const renderCheckOrLabel2 = () => {
        if (props.isSelected) {
            let isSky = false;
            let isOrange = false;

            if (props.isSky) {
                isSky = true;
            } else {
                isOrange = true;
            }

            return (
                <IconCheck
                    className={styles.check}
                    isOrange={isOrange}
                    isSky={isSky}
                />
            );
        }

        if (props.label2) {
            return (
                <div className={styles.optionLabel2}>
                    {props.label2}
                </div>
            );
        }

        return null;
    };

    /* --- */

    const optionClassName = classNames({
        [styles.option]: true,
        [styles.optionCheckedOrWithLabel2]: props.isSelected || !!props.label2,
        [styles.optionSky]: props.isSky,
        [styles.optionClickable]: props.isClickable,
        [styles.optionSelected]: props.isSelected,
    });

    return (
        <DivButton
            onClick={onSelect}
            className={optionClassName}
        >
            {props.label}
            {renderCheckOrLabel2()}
        </DivButton>
    );
};

Option.defaultProps = {
    label: "",
    label2: "",
    onSelect: () => { },
    isDisabled: false,
    isSelected: false,
    isClickable: false,
    isSky: false,
};

export default Option;
