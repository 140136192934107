import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconArrowInCircleDown from "@/base/icons/arrow-in-circle-down/index.js";
import IconTags from "@/base/icons/tags/index.js";

import MenuInfoByGroups from "@/base/components/menu-info-by-groups/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import stylesDailyJuiceCommon from "@/base/business/daily-juice-common/styles.module.css";


const getGroups = (tags) => {
    const groups = [];

    for (let i = 0; i < tags.length; i += 1) {
        const tag = tags[i];
        const groupName = tag.groupName || "";

        if (groupName) {
            const index = groups.map((g) => g.name).indexOf(groupName);

            const option = {
                label: tag.subtagName || "",
                label2: tag.tagName || "",
            };

            if (index === -1) {
                groups.push({
                    name: groupName,
                    options: [option],
                });
            } else {
                groups[index] = {
                    ...groups[index],
                    options: [
                        ...groups[index].options,
                        option,
                    ],
                };
            }
        }
    }

    return groups;
};

const ContentTagsMenu = (props) => {
    const renderLabel = () => {
        const labelClassName = classNames({
            [stylesDailyJuiceCommon.header]: true,
            [stylesFonts.header]: true,
            [stylesFonts.textDyslexicBold]: props.isDyslexic,
        });

        return (
            <div className={labelClassName}>
                {`${props.tags.length} Content Tags`}
            </div>
        );
    };

    /* --- */

    const groups = getGroups(props.tags);

    return (
        <MenuInfoByGroups
            groups={groups}
            label={renderLabel()}
            menuLabel="Content Tags"
            menuIcon={IconTags}
            labelIcon={IconArrowInCircleDown}
            onToggle={props.onToggle}
            onMenuClick={props.onMenuClick}
        />
    );
};

ContentTagsMenu.defaultProps = {
    tags: [],
    onToggle: () => { },
    onMenuClick: () => { },
    isDyslexic: false,
};

export default ContentTagsMenu;
