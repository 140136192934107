import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import AudioManagerContext from "@/base/context/audio-manager/index.js";

import actions from "@/base/store/actions.js";
import vocabularyActions from "@/base/actions/vocabulary.js";

import PopupWordCard from "@/base/components/popup-word-card/index.js";


const storeSelector = (state) => ({
    session: state.user.session,
    wordPopup: state.vocabulary.popup,
    wordsByAlias: state.vocabulary.wordsByAlias,
    playerState: state.player.playerState,
    text2speech: state.text2speech,
    vocabWordById: state.translation.vocabWordById,
});

const PopupWord = (props) => {
    const audioManager = useContext(AudioManagerContext);

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const trackGroupName = "words";
    const audio = store.playerState?.[trackGroupName] || {};

    const word = store.wordsByAlias[store.wordPopup.wordAlias] || {};

    /* --- */

    const getWordTranslation = () => {
        const wordId = word?.word?.id || null;
        const translationLanguage = store.wordPopup.translationLanguage || "";

        if (!wordId || !translationLanguage) {
            return {};
        }

        const data = store.vocabWordById[wordId]?.[translationLanguage] || {};

        return {
            ...data,
            isVisible: true,
        };
    };

    /* --- */

    const onAudioLoad = (text, wordId, isDefinition) => {
        audioManager.loadWord(text);
        props.onAudioLoad(text, wordId, isDefinition);
    };

    const onAudioPlay = (text, wordId, isDefinition) => {
        audioManager.play(trackGroupName, text);
        props.onAudioPlay(text, wordId, isDefinition);
    };

    const onAudioStop = (text, wordId, isDefinition) => {
        audioManager.stop(trackGroupName, text);
        props.onAudioStop(text, wordId, isDefinition);
    };

    const onAudioStopAll = (words) => {
        audioManager.stopAllTracks(trackGroupName, words);
        props.onAudioStopAll(words);
    };

    /* --- */

    const onClose = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    /* --- */

    if (!store.wordPopup.isVisible) {
        return null;
    }

    const translation = getWordTranslation();

    return (
        <PopupWordCard
            word={word?.word || {}}
            definitions={word?.definitions || []}
            error={word?.error || ""}
            translation={translation}
            audio={audio}
            offsetY={store.wordPopup.offsetY || 0}
            onLoad={onAudioLoad}
            onPlay={onAudioPlay}
            onStop={onAudioStop}
            onStopAll={onAudioStopAll}
            onClose={onClose}
            hideScrollbar={props.hideScrollbar}
            isLoading={!word.isLoaded}
        />
    );
};

PopupWord.defaultProps = {
    onAudioLoad: () => { },
    onAudioPlay: () => { },
    onAudioStop: () => { },
    onAudioStopAll: () => { },
    hideScrollbar: true,
};

export default PopupWord;
