// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CblpxHTTJog9TF0t1jDo {\n    width: max-content;\n    margin: 0 0 2rem 0;\n}\n\n.w5rXP21CYZSgOVQJEnvG {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: auto max-content;\n    grid-gap: 1.5rem;\n\n    align-items: center;\n\n    background-color: var(--coupon-bg-color);\n    padding: 1rem;\n}\n\n.H6AJFaqu_7JpXPwTog5t {\n    color: var(--color-3);\n    font-weight: normal;\n\n    margin: 0.5rem 0 0 1rem;\n    margin: 0.5rem 1rem;\n}", "",{"version":3,"sources":["webpack://./base/business/coupon-attached/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uCAAuC;IACvC,gBAAgB;;IAEhB,mBAAmB;;IAEnB,wCAAwC;IACxC,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;;IAEnB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: max-content;\n    margin: 0 0 2rem 0;\n}\n\n.couponCode {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: auto max-content;\n    grid-gap: 1.5rem;\n\n    align-items: center;\n\n    background-color: var(--coupon-bg-color);\n    padding: 1rem;\n}\n\n.helperText {\n    color: var(--color-3);\n    font-weight: normal;\n\n    margin: 0.5rem 0 0 1rem;\n    margin: 0.5rem 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CblpxHTTJog9TF0t1jDo",
	"couponCode": "w5rXP21CYZSgOVQJEnvG",
	"helperText": "H6AJFaqu_7JpXPwTog5t"
};
export default ___CSS_LOADER_EXPORT___;
