import * as types from "./types.js";


export const setStudentById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_BY_ID,
    payload,
});

/* ----- */

export const setStudentQuizPerformanceLoadingById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_LOADING_BY_ID,
    payload,
});

export const setStudentQuizPerformanceById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_QUIZ_PERFORMANCE_BY_ID,
    payload,
});

export const clearStudentQuizPerformances = () => ({
    type: types.CLEAR_TEACHER_STUDENT_QUIZ_PERFORMANCES,
});

/* ----- */

export const setStudentAssignmentsLoadingById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_ASSIGNMENTS_LOADING_BY_ID,
    payload,
});

export const setStudentAssignmentsById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_ASSIGNMENTS_BY_ID,
    payload,
});

export const clearStudentAssignments = () => ({
    type: types.CLEAR_TEACHER_STUDENT_ASSIGNMENTS,
});

/* ----- */

export const setStudentDailyJuicesLoadingById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_DAILY_JUICES_LOADING_BY_ID,
    payload,
});

export const setStudentDailyJuicesById = (payload) => ({
    type: types.SET_TEACHER_STUDENT_DAILY_JUICES_BY_ID,
    payload,
});

export const clearStudentDailyJuices = () => ({
    type: types.CLEAR_TEACHER_STUDENT_DAILY_JUICES,
});
