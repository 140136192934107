import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconPrint = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M17 5.20508H16V0.205078H4V5.20508H3C1.34 5.20508 0 6.54508 0 8.2051V14.2051H4V18.2051H16V14.2051H20V8.2051C20 6.54508 18.66 5.20508 17 5.20508ZM6 2.20508H14V5.20508H6V2.20508ZM14 16.2051H6V12.2051H14V16.2051ZM16 12.2051V10.2051H4V12.2051H2V8.2051C2 7.6551 2.45 7.2051 3 7.2051H17C17.55 7.2051 18 7.6551 18 8.2051V12.2051H16Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPrint;
