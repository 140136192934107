import text from "@/base/text/index.js";


const loadAssignmentsScores = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const { classId } = params;

        if (!classId) {
            return;
        }

        const forceUpdate = params.forceUpdate || false;

        const state = getState();

        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        const range = `${dateFrom}-${dateToOrLoadType}`;

        if (!dateFrom || !dateToOrLoadType) {
            return;
        }

        const isLoaded = state.teacher.classesScoresByAssignments?.[classId]?.[range]?.data;

        if (isLoaded && !forceUpdate) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassScoresByAssignmentsLoading({
            range,
            classId,
        }));

        const res = await api.teacherClasses.getTeacherClassScoresByAssignments({
            session: state.user.session,
            classId,
            date: dateFrom,
            loadType: dateToOrLoadType,
        });

        let data = null;
        let error = "";
        let hasMoreBefore = false;
        let hasMoreAfter = false;

        if (res.ok) {
            data = res.data || null;
            hasMoreBefore = res.hasMoreBefore || false;
            hasMoreAfter = res.hasMoreAfter || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassScoresByAssignments({
            classId,
            range,
            data,
            error,
            hasMoreBefore,
            hasMoreAfter,
        }));
    };
};

const reloadAssignmentsScores = (services, params = {}) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { classId } = params;

        if (!classId) {
            return;
        }

        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        const range = `${dateFrom}-${dateToOrLoadType}`;

        if (!dateFrom || !dateToOrLoadType) {
            return;
        }

        const res = await api.teacherClasses.getTeacherClassScoresByAssignments({
            session: state.user.session,
            classId,
            date: dateFrom,
            loadType: dateToOrLoadType,
        });

        let data = null;
        let error = "";
        let hasMoreBefore = false;
        let hasMoreAfter = false;

        if (res.ok) {
            data = res.data || null;
            hasMoreBefore = res.hasMoreBefore || false;
            hasMoreAfter = res.hasMoreAfter || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.updateTeacherClassScoresByAssignments({
            classId,
            range,
            data,
            error,
            hasMoreBefore,
            hasMoreAfter,
        }));

        /* --- */

        const seconds = 5;

        globalThis.setTimeout(() => {
            dispatch(actions.teacher.clearUpdatedStatusTeacherClassScoresByAssignments({
                classId,
                range,
                data,
            }));
        }, seconds * 1000);
    };
};

const loadOrReloadAssignmentsScores = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const urlParams = state.navigation?.params || {};
        const { classId, dateFrom, dateToOrLoadType } = urlParams;

        if (!classId || !dateFrom || !dateToOrLoadType) {
            return;
        }

        const range = `${dateFrom}-${dateToOrLoadType}`;

        const isLoaded = state.teacher.classesScoresByAssignments?.[classId]?.[range]?.data;

        if (isLoaded) {
            const reloadAction = reloadAssignmentsScores(services, { classId });
            reloadAction(dispatch, getState);
            return;
        }

        const loadAction = loadAssignmentsScores(services, { classId });
        loadAction(dispatch, getState);
    };
};

export default {
    loadAssignmentsScores,
    reloadAssignmentsScores,
    loadOrReloadAssignmentsScores,
};
