import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    assignmentsByStudentId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENT_ASSIGNMENTS_BY_CLASS_ID_LOADING: {
            const {
                studentId,
                classId,
            } = action.payload;

            const assignmentsByStudentId = { ...state.assignmentsByStudentId };
            const prevByStudentId = assignmentsByStudentId[studentId] || {};
            const prevByClassId = prevByStudentId[classId] || {};

            assignmentsByStudentId[studentId] = {
                ...prevByStudentId,
                [classId]: {
                    ...prevByClassId,
                    error: "",
                    isLoading: true,
                },
            };

            return {
                ...state,
                assignmentsByStudentId,
            };
        }

        case types.SET_TEACHER_STUDENT_ASSIGNMENTS_BY_CLASS_ID: {
            const {
                studentId,
                classId,
                data,
                error,
                hasMore,
            } = action.payload;

            const assignmentsByStudentId = { ...state.assignmentsByStudentId };
            const prevByStudentId = assignmentsByStudentId[studentId] || {};
            const prevByClassId = prevByStudentId[classId] || {};

            let page = prevByClassId.page || 0;
            let dataAll = prevByClassId.data || [];

            if (!error) {
                dataAll = [...dataAll].concat(data);
                page += 1;
            }

            assignmentsByStudentId[studentId] = {
                ...prevByStudentId,
                [classId]: {
                    data: dataAll,
                    error,
                    hasMore,
                    page,
                    isLoading: false,
                    isLoaded: true,
                },
            };

            return {
                ...state,
                assignmentsByStudentId,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
