import React from "react";

import IconAODTablet from "@/base/icons/aod-tablet/index.js";

import Switch from "@/base/components/switch/index.js";

import styles from "./styles.module.css";


const ProfileReadingMode = (props) => {
    return (
        <div className={styles.readingMode}>
            <div className={styles.iconWrapper}>
                <IconAODTablet
                    className={styles.icon}
                    isSky
                />
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    Focused Reading
                </div>
                <div className={styles.description}>
                    Improve readability with an optimized font and
                    reduced visual disturbances
                </div>
            </div>
            <div className={styles.switch}>
                <Switch
                    name="isProfileFocusedReadingMode"
                    checked={props.isChecked}
                    onChange={props.onChange}
                />
            </div>
        </div>

    );
};

ProfileReadingMode.defaultProps = {
    onChange: () => { },
    isChecked: false,
};

export default ProfileReadingMode;
