import React from "react";

import styles from "./styles.module.css";


class ErrorBoundaryWithValue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            changeValue: props.changeValue,
        };
    }

    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.changeValue !== state.changeValue) {
            return {
                ...state,
                hasError: false,
                changeValue: props.changeValue,
            };
        }

        return state;
    }

    componentDidCatch(error) {
        this.props.onError({
            url: "/",
            line: 1,
            column: 1,
            message: error.message,
            error: error.toString(),
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.error}>
                    Oops! Something went wrong.
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundaryWithValue.defaultProps = {
    changeValue: "",
    onError: () => {},
};

export default ErrorBoundaryWithValue;
