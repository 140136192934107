import React from "react";

import styles from "./styles.module.css";


const TextLine = (props) => {
    const classes = [styles.textLine];

    if (props.multiline) {
        classes.push(styles.textLineMultiline);
    }

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div className={classes.join(" ")}>
            {props.children}
        </div>
    );
};

TextLine.defaultProps = {
    className: "",
    multiline: false,
    children: null,
};

export default TextLine;
