import React from "react";

import classNames from "@/base/lib/class-names.js";

import IconArrowRight from "@/base/icons/arrow-right/index.js";

import styles from "./styles.module.css";


const DLCustom = (props) => {
    const renderDL = () => {
        const dlClassName = classNames({
            [styles.dl]: true,
            [styles.dlVisible]: props.isExpanded,
        });

        const values = props.values.map((val) => (
            <>
                <dt className={styles.dt}>{val.label}</dt>
                <dd className={styles.dd}>{val.value}</dd>
            </>
        ));

        return (
            <dl className={dlClassName}>
                {values}
            </dl>
        );
    };

    const togglerIconClassName = classNames({
        [styles.headerTogglerIcon]: true,
        [styles.headerTogglerIconUp]: props.isExpanded,
    });

    return (
        <div className={styles.dlCustom}>
            <div
                className={styles.header}
                onClick={props.onToggle}
                onKeyPress={props.onToggle}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.headerTitle}>
                    {props.title}
                </div>
                <IconArrowRight
                    className={togglerIconClassName}
                    isBlack
                />
            </div>
            {renderDL()}
        </div>
    );
};

DLCustom.defaultProps = {
    title: "",
    values: [
        { label: "Quiz Status", value: "complete" },
        { label: "Quiz Status", value: "complete" },
        { label: "Quiz Status", value: "complete" },
    ],
    isExpanded: false,
    onToggle: () => { },
};

export default DLCustom;
