// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yRHxz7ibltw_gmY7dq9o {}\n\n.qdHZnszT6xRmGbrij2RO {\n    margin: 0 0 1rem 0;\n\n    display: grid;\n    grid-template-areas:\n        \"title    value\";\n    grid-row-gap: 0.5rem;\n    align-items: center;\n}\n\n.zAveItWIBCSntUs3BKqv {\n    grid-template-areas:\n        \"title    value\"\n        \"subtitle value\";\n}\n\n.AcGpvkFFZQ2WRgp7QBdE {\n    grid-area: title;\n\n    color: var(--text-primary-color);\n    font-size: 1.6rem;\n    font-weight: 600;\n}\n\n.QowajFObmAn_SJlXhVtX {\n    grid-area: subtitle;\n\n    color: var(--progress-value-subtitle-color);\n    font-size: 1.4rem;\n}\n\n.hcLUTEzBnuZjxsDXjPwY {\n    grid-area: value;\n\n    color: var(--progress-value-color);\n    font-weight: 600;\n    text-align: right;\n}\n", "",{"version":3,"sources":["webpack://./base/components/progress-value/styles.module.css"],"names":[],"mappings":"AAAA,uBAAgB;;AAEhB;IACI,kBAAkB;;IAElB,aAAa;IACb;wBACoB;IACpB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI;;wBAEoB;AACxB;;AAEA;IACI,gBAAgB;;IAEhB,gCAAgC;IAChC,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;;IAEnB,2CAA2C;IAC3C,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;;IAEhB,kCAAkC;IAClC,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".progressValue {}\n\n.info {\n    margin: 0 0 1rem 0;\n\n    display: grid;\n    grid-template-areas:\n        \"title    value\";\n    grid-row-gap: 0.5rem;\n    align-items: center;\n}\n\n.infoWithSubtitle {\n    grid-template-areas:\n        \"title    value\"\n        \"subtitle value\";\n}\n\n.title {\n    grid-area: title;\n\n    color: var(--text-primary-color);\n    font-size: 1.6rem;\n    font-weight: 600;\n}\n\n.subtitle {\n    grid-area: subtitle;\n\n    color: var(--progress-value-subtitle-color);\n    font-size: 1.4rem;\n}\n\n.value {\n    grid-area: value;\n\n    color: var(--progress-value-color);\n    font-weight: 600;\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressValue": "yRHxz7ibltw_gmY7dq9o",
	"info": "qdHZnszT6xRmGbrij2RO",
	"infoWithSubtitle": "zAveItWIBCSntUs3BKqv",
	"title": "AcGpvkFFZQ2WRgp7QBdE",
	"subtitle": "QowajFObmAn_SJlXhVtX",
	"value": "hcLUTEzBnuZjxsDXjPwY"
};
export default ___CSS_LOADER_EXPORT___;
