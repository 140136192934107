// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes p3J1C8zYRZBImlPjEu5O {\n    0% {\n        transform: translateY(0);\n    }\n    0.2% {\n        transform: translateY(0);\n    }\n    0.4% {\n        transform: translateY(-2rem);\n    }\n    0.5% {\n        transform: translateY(0);\n    }\n    0.6% {\n        transform: translateY(-1rem);\n    }\n    1% {\n        transform: translateY(0);\n    }\n\n    100% {\n        transform: translateY(0);\n    }\n}\n\n.X2uBkTBi8mVErLJmrjyy {\n    width: 2rem;\n    height: 2rem;\n\n    display: grid;\n    align-items: center;\n    justify-content: center;\n\n    animation: p3J1C8zYRZBImlPjEu5O 100s ease 0s infinite;\n}\n", "",{"version":3,"sources":["webpack://./base/components/icon-jumping/styles.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,wBAAwB;IAC5B;IACA;QACI,4BAA4B;IAChC;IACA;QACI,wBAAwB;IAC5B;IACA;QACI,4BAA4B;IAChC;IACA;QACI,wBAAwB;IAC5B;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,qDAA6C;AACjD","sourcesContent":["@keyframes icon-jumping {\n    0% {\n        transform: translateY(0);\n    }\n    0.2% {\n        transform: translateY(0);\n    }\n    0.4% {\n        transform: translateY(-2rem);\n    }\n    0.5% {\n        transform: translateY(0);\n    }\n    0.6% {\n        transform: translateY(-1rem);\n    }\n    1% {\n        transform: translateY(0);\n    }\n\n    100% {\n        transform: translateY(0);\n    }\n}\n\n.icon {\n    width: 2rem;\n    height: 2rem;\n\n    display: grid;\n    align-items: center;\n    justify-content: center;\n\n    animation: icon-jumping 100s ease 0s infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "X2uBkTBi8mVErLJmrjyy",
	"icon-jumping": "p3J1C8zYRZBImlPjEu5O"
};
export default ___CSS_LOADER_EXPORT___;
