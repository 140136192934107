import React from "react";

import classNames from "@/base/lib/class-names.js";

import Achievements from "@/base/project/achievements.js";

import AchievementIcon from "@/base/business/achievement-icon/index.js";

import styles from "./styles.module.css";


const AchievementCard = (props) => {
    const renderIcon = () => {
        if (!props.type) {
            return null;
        }

        const icon = (
            <AchievementIcon
                className={styles.icon}
                theme={props.theme}
                type={props.type}
            />
        );

        return (
            <div className={styles.iconContainer}>
                {icon}
            </div>
        );
    };

    const renderData = () => {
        const countClassName = classNames({
            [styles.count]: true,
            [styles.countStoriesRead]: Achievements.isStoriesRead(props.type),
            [styles.countQuestionsAttempted]: Achievements.isQuestionsAttempted(props.type),
        });

        return (
            <div className={styles.data}>
                <div className={styles.label}>
                    {props.label}
                </div>
                <div className={countClassName}>
                    {`x${props.count}`}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {renderIcon()}
            {renderData()}
        </div>
    );
};

AchievementCard.defaultProps = {
    type: "",
    label: "",
    theme: "",
    count: 0,
};

export default AchievementCard;
