import React from "react";

import ButtonFlat from "@/base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";


const PopupUserLogout = (props) => {
    return (
        <PopupConfirm onClose={props.onClose}>
            <PopupConfirmContent>
                Are you sure want to sign out of The Juice?
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose} danger>
                    Cancel
                </ButtonFlat>
                <ButtonFlat onClick={props.onLogout} danger>
                    Sign Out
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupUserLogout.defaultProps = {
    onClose: () => {},
    onLogout: () => {},
};

export default PopupUserLogout;
