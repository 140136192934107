const SET_UI_STORIES_RATING_JUICE_STORY_LOADING = "SET_UI_STORIES_RATING_JUICE_STORY_LOADING";
const SET_UI_STORIES_RATING_JUICE_STORY = "SET_UI_STORIES_RATING_JUICE_STORY";

const SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE_LOADING = "SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE_LOADING";
const SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE = "SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE";

const SET_UI_STORIES_RATING_EXTRA_JUICE_LOADING = "SET_UI_STORIES_RATING_EXTRA_JUICE_LOADING";
const SET_UI_STORIES_RATING_EXTRA_JUICE = "SET_UI_STORIES_RATING_EXTRA_JUICE";

const SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE_LOADING = "SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE_LOADING";
const SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE = "SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE";

const SET_UI_STORIES_RATING_ASSIGNMENT_STORY_LOADING = "SET_UI_STORIES_RATING_ASSIGNMENT_STORY_LOADING";
const SET_UI_STORIES_RATING_ASSIGNMENT_STORY = "SET_UI_STORIES_RATING_ASSIGNMENT_STORY";

const SET_UI_STORIES_RATING_SEARCH_STORY_LOADING = "SET_UI_STORIES_RATING_SEARCH_STORY_LOADING";
const SET_UI_STORIES_RATING_SEARCH_STORY = "SET_UI_STORIES_RATING_SEARCH_STORY";

const SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE_LOADING = "SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE_LOADING";
const SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE = "SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE";


export default {
    SET_UI_STORIES_RATING_JUICE_STORY_LOADING,
    SET_UI_STORIES_RATING_JUICE_STORY,

    SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE_LOADING,
    SET_UI_STORIES_RATING_JUICE_STORY_EXTRA_JUICE,

    SET_UI_STORIES_RATING_EXTRA_JUICE_LOADING,
    SET_UI_STORIES_RATING_EXTRA_JUICE,

    SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE_LOADING,
    SET_UI_STORIES_RATING_ASSIGNMENT_EXTRA_JUICE,

    SET_UI_STORIES_RATING_ASSIGNMENT_STORY_LOADING,
    SET_UI_STORIES_RATING_ASSIGNMENT_STORY,

    SET_UI_STORIES_RATING_SEARCH_STORY_LOADING,
    SET_UI_STORIES_RATING_SEARCH_STORY,

    SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE_LOADING,
    SET_UI_STORIES_RATING_SEARCH_STORY_EXTRA_JUICE,
};
