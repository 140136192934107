export default (apiURL, methods) => {
    return {
        getPageBySlug(params = {}) {
            // NOTE: action=get_page_content_by_slug
            const url = `${apiURL}/pages/${params.slug || ""}`;

            return methods.get3(url, {});
        },
    };
};
