import React from "react";

import SnackbarBox from "@/base/components/snackbar-box/index.js";

import useSnackbar from "./use-snackbar.js";


const Snackbar = (props) => {
    const onClose = (bar, index) => {
        if (bar.onClose) {
            bar.onClose();
        }

        props.onClose(index);
    };

    const onCloseWithUndo = (bar, index) => {
        if (bar.onCloseWithUndo) {
            bar.onCloseWithUndo();
        }

        props.onClose(index);
    };

    /* --- */

    return props.bars.map((bar, i) => {
        if (!bar.isVisible) {
            return null;
        }

        return (
            <SnackbarBox
                message={bar.message}
                autoCloseInSeconds={bar.autoCloseInSeconds}
                onClose={() => {
                    onClose(bar, i);
                }}
                onCloseWithUndo={() => {
                    onCloseWithUndo(bar, i);
                }}
                withUndo={bar.withUndo}
            />
        );
    });
};

Snackbar.defaultProps = {
    bars: [],
    onClose: () => { },
};

export { useSnackbar };
export default Snackbar;
