import * as types from "./types.js";


export const setVersion = (version) => ({
    type: types.SET_VERSION,
    payload: {
        version,
    },
});

/* --- */

export const setFeatures = (features) => ({
    type: types.SET_APP_FEATURES,
    payload: {
        features,
    },
});

export const setIframeMode = (isIframeMode) => ({
    type: types.SET_APP_IFRAME_MODE,
    payload: {
        isIframeMode,
    },
});

/* --- */

export const setAutomaticTheme = (payload) => ({
    type: types.SET_AUTOMATIC_THEME,
    payload,
});

export const setTheme = (payload) => ({
    type: types.SET_THEME,
    payload,
});

/* --- */

export const setLibVideojsLoaded = () => ({
    type: types.SET_LIB_VIDEOJS_LOADED,
});

export const setLibGAPILoaded = () => ({
    type: types.SET_LIB_GAPI_LOADED,
});

export const setLibStripeLoaded = () => ({
    type: types.SET_LIB_STRIPE_LOADED,
});

export const setLibStripeError = (payload) => ({
    type: types.SET_LIB_STRIPE_ERROR,
    payload,
});

export const setLibStripeInitLoaded = () => ({
    type: types.SET_LIB_STRIPE_INIT_LOADED,
});

export default {};
