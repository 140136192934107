import User from "./user.js";


const getDefaultRedirectUrl = (user) => {
    if (User.hasRoleTeacher(user)) {
        return "/dashboard";
    }

    if (User.hasRoleAdmin(user)) {
        return "/admin/dashboard";
    }

    if (User.hasRoleDistrictAdmin(user)) {
        return "/district-admin/dashboard";
    }

    if (User.hasRoleGuardian(user)) {
        return "/";
    }

    if (User.hasRoleStudent(user)) {
        return "/";
    }

    return "/not-found";
};

export default {
    getDefaultRedirectUrl,
};
