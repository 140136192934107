import React, { useState, useRef } from "react";

import moment from "moment";

import { DayPicker } from "react-dates";
import "react-dates/initialize.js";
import "react-dates/lib/css/_datepicker.css";

import classNames from "@/base/lib/class-names.js";
import date from "@/base/lib/date.js";

import useGlobalClose from "@/base/hooks/use-global-close/index.js";

import Error from "@/base/components/forms/error/index.js";
import DivButton from "@/base/components/div-button/index.js";

import fieldCommonStyles from "@/base/components/forms/field-common/styles.module.css";
import styles from "./styles.module.css";
import "./styles.css";


const InputDate = (props) => {
    const inputRef = useRef(null);

    const [isFocused, setIsFocused] = useState(false);

    /* --- */

    const isDayDisabled = (d) => {
        if (!props.min && !props.max) {
            return false;
        }

        let isEarlier = false;

        if (props.min) {
            isEarlier = date.newDate(d) < date.newDate(props.min);

            if (isEarlier) {
                const isSame = date.isDatesSame(d, props.min);

                if (isSame) {
                    isEarlier = false;
                }
            }
        }

        if (isEarlier) {
            return true;
        }

        let isGreater = false;

        if (props.max) {
            isGreater = date.newDate(d) > date.newDate(props.max);

            if (isGreater) {
                const isSame = date.isDatesSame(d, props.max);

                if (isSame) {
                    isGreater = false;
                }
            }
        }

        if (isGreater) {
            return true;
        }

        return false;
    };

    /* --- */

    const onDayClick = (day) => {
        if (isDayDisabled(day)) {
            return;
        }

        const d = date.newDate(day);

        props.onBlur();
        props.onSelect(d);
        setIsFocused(false);
    };

    const onInputClick = () => {
        if (props.isDisabled) {
            return;
        }

        setIsFocused((prev) => !prev);
    };

    /* --- */

    useGlobalClose((evt) => {
        if (!isFocused) {
            return;
        }

        if (inputRef?.current?.contains
            && inputRef.current.contains(evt.target)) {
            return;
        }

        setIsFocused(false);
        props.onBlur();
    }, [isFocused]);

    /* --- */

    const renderLabel = () => {
        if (!props.label) {
            return null;
        }

        const labelClassName = classNames({
            [fieldCommonStyles.label]: true,
            [styles.labelFocused]: props.value || isFocused,
        });

        return (
            <div className={labelClassName}>
                {props.label}
            </div>
        );
    };

    const renderValue = () => {
        if (!props.value) {
            return null;
        }

        if (!date.isDate(props.value)) {
            return "Failed to set date";
        }

        return date.getDateFromDate(props.value, "/");
    };

    const renderDayContents = (d) => {
        let isSameDay = false;

        if (props.value) {
            isSameDay = date.isDatesSame(d, props.value);
        }

        const dayClassName = classNames({
            [styles.day]: true,
            [styles.daySelected]: isSameDay,
            [styles.dayDisabled]: isDayDisabled(d),
        });

        return (
            <div className={dayClassName}>
                {date.newDate(d).getDate()}
            </div>
        );
    };

    const renderCalendar = () => {
        if (!isFocused) {
            return null;
        }

        const calendarClassName = classNames({
            [styles.calendar]: true,
            [styles.calendarUp]: props.isDropUp,
            [props.calendarClassName]: props.calendarClassName,
        });

        const d = props.value || new Date();

        return (
            <div className={calendarClassName}>
                <DayPicker
                    id={`input-date-${props.id}`}
                    date={moment(d)}
                    numberOfMonths={1}
                    firstDayOfWeek={1}
                    enableOutsideDays
                    renderDayContents={renderDayContents}
                    initialVisibleMonth={() => moment(d)}
                    onDayClick={onDayClick}
                />
            </div>
        );
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <Error>
                {props.error}
            </Error>
        );
    };

    /* --- */

    const fieldClassName = classNames({
        [fieldCommonStyles.field]: true,
        [styles.fieldDisabledMinHeight]: props.isDisabledMinHeight,
    });

    const datepickerClassName = classNames({
        [styles.container]: true,
        inputDateContainer: true,
        [fieldCommonStyles.container]: true,
        [fieldCommonStyles.containerFocused]: isFocused,
        [fieldCommonStyles.containerError]: props.error,
        [fieldCommonStyles.containerDisabled]: props.isDisabled,
    });

    const inputClassName = classNames({
        [fieldCommonStyles.input]: true,
        [styles.input]: true,
        [fieldCommonStyles.inputRounded]: props.isRounded,
        [fieldCommonStyles.inputFocused]: isFocused || (props.value && !props.isDisabled),
        [fieldCommonStyles.inputError]: props.error,
        [fieldCommonStyles.inputDisabled]: props.isDisabled,
    });

    return (
        <div className={fieldClassName}>
            <div
                className={datepickerClassName}
                ref={inputRef}
            >
                <DivButton
                    className={inputClassName}
                    onClick={onInputClick}
                >
                    {renderLabel()}
                    {renderValue()}
                </DivButton>
                {renderCalendar()}
            </div>
            {renderError()}
        </div>
    );
};

InputDate.defaultProps = {
    id: null,
    value: null,
    calendarClassName: "",
    error: "",
    label: "Label",
    min: null,
    max: null,
    onBlur: () => { },
    onSelect: () => { },
    isDropUp: false,
    isDisabledMinHeight: false,
    isDisabled: false,
    isRounded: false,
};

export default InputDate;
