import * as types from "./types.js";


export const openWordPopup = (payload) => ({
    type: types.OPEN_VOCABULARY_WORD_POPUP,
    payload,
});

export const closeWordPopup = () => ({
    type: types.CLOSE_VOCABULARY_WORD_POPUP,
});

/* --- */

export const setVocabularyWords = (payload) => ({
    type: types.SET_VOCABULARY_WORDS,
    payload,
});

/* --- */

export const setVocabularyWord = (payload) => ({
    type: types.SET_VOCABULARY_WORD,
    payload,
});

export const setVocabularyWordAndPopup = (payload) => ({
    type: types.SET_VOCABULARY_WORD_AND_POPUP,
    payload,
});
