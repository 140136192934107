import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isVideosLoaded: false,
    isVideosLoading: false,
    hasMorePages: false,
    page: 0,
    videos: [],
    videoById: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_JUICES_VIDEOS: {
            return {
                ...state,
                isVideosLoaded: true,
                isVideosLoading: false,
                videos: action.payload.videos,
                page: action.payload.page,
                hasMorePages: action.payload.hasMorePages,
            };
        }

        case types.SET_JUICES_VIDEOS_LOADING: {
            return {
                ...state,
                isVideosLoading: true,
            };
        }

        /* --- */

        case types.SET_JUICE_VIDEO_BY_ID_LOADING: {
            const { id } = action.payload;

            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    [id]: {
                        data: null,
                        error: "",
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_JUICE_VIDEO_BY_ID: {
            const { id, data, error } = action.payload;

            return {
                ...state,
                videoById: {
                    ...state.videoById,
                    [id]: {
                        data,
                        error,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
