const getTimeDiffFromNow = (timeStart) => {
    const now = new Date().getTime();

    const diff = now - timeStart;

    if (Number.isNaN(diff)) {
        return 0;
    }

    return Math.round(Math.abs(diff) / 1000);
};

const getTimeBySeconds = (sec) => {
    const hours = Math.floor(sec / 3600);
    const timeLeft = sec - hours * 3600;

    const minutes = Math.floor(timeLeft / 60);
    const seconds = Math.round(timeLeft - (minutes * 60));

    const minutesFormatted = minutes >= 10
        ? minutes
        : `0${minutes}`;

    const secondsFormatted = seconds >= 10
        ? seconds
        : `0${seconds}`;

    let timeFormatted = `${minutesFormatted}:${secondsFormatted}`;

    if (hours) {
        const hoursFormatted = hours >= 10
            ? hours
            : `0${hours}`;

        timeFormatted = `${hoursFormatted}:${timeFormatted}`;
    }

    return {
        seconds,
        minutes,
        hours,
        timeFormatted,
    };
};

export default {
    getTimeDiffFromNow,
    getTimeBySeconds,
};
