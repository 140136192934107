import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAchievement = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16"
                    height="22"
                    viewBox="0 0 16 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M5.68 12.19L8 10.43L10.31 12.19L9.43 9.34L11.75 7.5H8.91L8 4.69L7.09 7.5H4.25L6.56 9.34L5.68 12.19ZM16 8.5C16 4.08 12.42 0.5 8 0.5C3.58 0.5 0 4.08 0 8.5C0 10.53 0.76 12.37 2 13.78V21.5L8 19.5L14 21.5V13.78C15.24 12.37 16 10.53 16 8.5ZM8 2.5C11.31 2.5 14 5.19 14 8.5C14 11.81 11.31 14.5 8 14.5C4.69 14.5 2 11.81 2 8.5C2 5.19 4.69 2.5 8 2.5Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievement;
