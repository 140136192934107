// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YzEA_tWmxAe213eVKjqA {\n    position: relative;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    width: 4rem;\n    height: 4rem;\n\n    transition: background-color 100ms linear;\n\n    border-radius: 5rem;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.Bn0CTDzB3VLbf738mmd2,\n.YzEA_tWmxAe213eVKjqA:hover {\n    background-color: var(--button-notification-hover-bg-color);\n}\n\n/* --- */\n\n.yjJEOWFv8BDO9m0R7uE9 {\n    position: absolute;\n    top: 0.6rem;\n    right: 0.6rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-notification/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,WAAW;IACX,YAAY;;IAEZ,yCAAyC;;IAEzC,mBAAmB;;IAEnB,iBAAiB;;IAEjB,eAAe;IACf,aAAa;AACjB;;AAEA;;IAEI,2DAA2D;AAC/D;;AAEA,QAAQ;;AAER;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB","sourcesContent":[".container {\n    position: relative;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    width: 4rem;\n    height: 4rem;\n\n    transition: background-color 100ms linear;\n\n    border-radius: 5rem;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.containerActive,\n.container:hover {\n    background-color: var(--button-notification-hover-bg-color);\n}\n\n/* --- */\n\n.badge {\n    position: absolute;\n    top: 0.6rem;\n    right: 0.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "YzEA_tWmxAe213eVKjqA",
	"containerActive": "Bn0CTDzB3VLbf738mmd2",
	"badge": "yjJEOWFv8BDO9m0R7uE9"
};
export default ___CSS_LOADER_EXPORT___;
