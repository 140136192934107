// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fqPsLoB3LpmlKkjYVT36 {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    width: 100%;\n\n    z-index: var(--z-index-popup-message);\n}\n\n._3IJ0INawZbUzglUuvTs {\n    bottom: 0;\n}\n\n.Gfk7Zz3CsiTZdDOoclXO {\n    top: 0;\n}\n\n.J6PLlXHkt4_JAr6Trb9C {\n    top: 25%;\n}\n\n.s7ttQxc5ipLbCMFAnLEj {\n    top: var(--main-header-height);\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-message-position/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,WAAW;;IAEX,qCAAqC;AACzC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,MAAM;AACV;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".position {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    width: 100%;\n\n    z-index: var(--z-index-popup-message);\n}\n\n.positionBottom {\n    bottom: 0;\n}\n\n.positionTop {\n    top: 0;\n}\n\n.positionCenter {\n    top: 25%;\n}\n\n.positionBelowHeader {\n    top: var(--main-header-height);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position": "fqPsLoB3LpmlKkjYVT36",
	"positionBottom": "_3IJ0INawZbUzglUuvTs",
	"positionTop": "Gfk7Zz3CsiTZdDOoclXO",
	"positionCenter": "J6PLlXHkt4_JAr6Trb9C",
	"positionBelowHeader": "s7ttQxc5ipLbCMFAnLEj"
};
export default ___CSS_LOADER_EXPORT___;
