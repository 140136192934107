import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconScoresHorizontal = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M0 6V10L16 10V6L0 6ZM0 0V4H8V0L0 0ZM11 12L0 12L0 16H11V12Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconScoresHorizontal;
