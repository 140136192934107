// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AmzXXrARScmKSxAWHQjK,\n.Egxd2Bs_a1caAl0UYudb {\n    display: grid;\n\n    background-color: var(--background-secondary-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius-2);\n}\n\n.Egxd2Bs_a1caAl0UYudb {\n    padding: 2rem;\n}\n\n/* --- */\n\n.aYn81aGOjt9nLUdEHmvS {\n    padding: 0 2rem;\n}\n\n.mMd2ezP4HiJ1q2GQ41p1 {\n    overflow: hidden;\n}\n\n.aYn81aGOjt9nLUdEHmvS,\n.mMd2ezP4HiJ1q2GQ41p1 {\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n/* --- */\n\n.ousogkxz_oS0kg_jf6YQ {\n    padding: 2rem;\n\n    display: grid;\n    grid-row-gap: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/student-announcements/styles.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,mDAAmD;;IAEnD,gDAAgD;IAChD,6CAA6C;AACjD;;AAEA;IACI,aAAa;AACjB;;AAEA,QAAQ;;AAER;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,uDAAuD;AAC3D;;AAEA,QAAQ;;AAER;IACI,aAAa;;IAEb,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".container,\n.containerContent {\n    display: grid;\n\n    background-color: var(--background-secondary-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius-2);\n}\n\n.containerContent {\n    padding: 2rem;\n}\n\n/* --- */\n\n.tabs {\n    padding: 0 2rem;\n}\n\n.tabsScrollable {\n    overflow: hidden;\n}\n\n.tabs,\n.tabsScrollable {\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n/* --- */\n\n.content {\n    padding: 2rem;\n\n    display: grid;\n    grid-row-gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AmzXXrARScmKSxAWHQjK",
	"containerContent": "Egxd2Bs_a1caAl0UYudb",
	"tabs": "aYn81aGOjt9nLUdEHmvS",
	"tabsScrollable": "mMd2ezP4HiJ1q2GQ41p1",
	"content": "ousogkxz_oS0kg_jf6YQ"
};
export default ___CSS_LOADER_EXPORT___;
