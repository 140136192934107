export default (apiURL, methods) => {
    return {
        getPrivatePlanByCode(params = {}) {
            // NOTE: action=get_private_payment_plan_by_custom_code
            const url = `${apiURL}/payment/plans/private/by-code`;

            return methods.get3(url, {
                session_id: params.session,
                custom_code: params.code,
            });
        },
    };
};
