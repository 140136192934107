import * as types from "./types.js";


export const setStudentsNotifications = (payload) => ({
    type: types.SET_STUDENTS_NOTIFICATIONS,
    payload,
});

/* --- */

export const setNotificationsLoading = (payload) => ({
    type: types.SET_NOTIFICATIONS_LOADING,
    payload,
});

export const setNotificationViewed = (id) => ({
    type: types.SET_NOTIFICATION_VIEWED,
    payload: { id },
});

export const setNotifications = (payload) => ({
    type: types.SET_NOTIFICATIONS,
    payload,
});

/* --- */

export const setNotificationByIdLoading = (id) => ({
    type: types.SET_NOTIFICATION_BY_ID_LOADING,
    payload: { id },
});

export const setNotificationById = (payload) => ({
    type: types.SET_NOTIFICATION_BY_ID,
    payload,
});

/* --- */

export default {};
