import user from "./user.js";
import classes from "./classes.js";
import dailyJuice from "./daily-juice.js";
import teacher from "./teacher.js";
import student from "./student.js";
import assignment from "./assignment.js";
import guardian from "./guardian.js";
import search from "./search.js";
import searchMini from "./searchMini.js";
import mini from "./mini.js";
import fivesy from "./fivesy.js";
import print from "./print.js";
import translation from "./translation.js";
import progressReport from "./progress-report.js";
import scoreReport from "./score-report.js";


const locationIds = {
    dashboard: 0,
    dailyJuice: 1,
    vocabulary: 2,
    myAccount: 3,
    login: 4,
    logout: 5,
    signup: 6,
    recoverPassword: 7,
    class: 9,
    search: 10,
    home: 11,
    changePassword: 12,
    student: 13,
    mini: 14,
    searchMini: 15,
    assignment: 16,
    dailyJuices: 17,
};

const eventIds = {
    juiceOpen: 0,
    juiceClose: 1,
    juiceStoryOpen: 2,
    juiceStoryClose: 3,
    juiceStoryReadStory: 4,
    juiceStoryExtraJuiceOpen: 5,
    juiceStoryExtraJuiceClose: 6,
    juiceStoryExtraJuiceVocabOpen: 7,
    juiceStoryVideoPlay: 8,
    juiceStoryVideoPause: 9,
    juiceStoryVocabOpen: 10,
    juiceStoryVocabClose: 11,
    studentHomeVideoOpen: 12,
    studentHomeVideoPlay: 13,
    studentHomeVideoPause: 14,
    juiceStoryImageOpen: 15,
    juiceStoryImageClose: 16,
    juiceStoryQuizQuestionOpen: 17,
    juiceStoryQuizQuestionClose: 18,
    juiceStoryQuizQuestionSubmit: 19,
    juiceSponsorHome: 20,
    juiceSponsorFirst: 21,
    juiceSponsorLast: 22,
    juiceStoryWordSpeechPause: 23,
    juiceStoryWordSpeechPlay: 24,
    juiceStoryVideoEnd: 25,
    userLogin: 26,
    userLogout: 27,
    extraJuiceOpenFromWordInStory: 28,
    extraJuiceOpenFromButtonInStory: 29,
    extraJuiceOpenFromExplore: 30,
    extraJuiceReadMore: 31,
    juiceStoryDefinitionPlay: 32,
    dailyJuiceStoryListen: 33,
    vocabOpenFromExtraJuice: 34,
    juiceStoryQuizQuestionAnswerClick: 35,
    classAddStudent: 36,
    classDeleteStudent: 37,
    classEditStudent: 38,
    classResetPassword: 39,
    classChangeClass: 40,
    classOpenStudentInfo: 41,
    teacherDailyJuiceOpen: 42,
    teacherDailyJuiceExtraJuiceOpen: 43,
    teacherDailyJuiceExtraJuiceVocabOpen: 44,
    onSearch: 45,
    searchStoryClick: 46,
    searchFilterClick: 47,
    teacherHomeChangeClass: 48,
    teacherHomeEditClassAnnouncement: 49,
    teacherHomeUploadAnImage: 50,
    teacherDailyJuiceVideoStart: 51,
    teacherDailyJuiceVideoPause: 52,
    teacherDailyJuiceDefinitionPlay: 53,
    teacherDailyJuicePronouncePlay: 54,
    teacherHomeViewAllScores: 55,
    userChangePassword: 56,
    guardianHomeSelectStudent: 57,
    guardianStudentEditStudent: 58,
    guardianStudentSelectStudent: 59,
    dailyJuiceOnQuizAnswerClick: 60,
    teacherDailyJuiceClose: 61,
    studentPollAnswerSelect: 62,
    teacherExportClassReport: 63,
    teacherExportStudentActivityReport: 64,
    teacherExportStudentPerformanceReport: 65,
    miniShow: 66,
    miniDailyJuiceOpen: 67,
    miniStoryExpandInfographic: 68,
    miniStoryVocabOpen: 69,
    miniStoryOpenEJFromButton: 70,
    miniStoryOpenEJFromStoryLink: 71,
    miniExtraJuiceOpenVocabWord: 72,
    miniStoryQuizAnswerClick: 73,
    miniStoryQuizSubmit: 74,
    miniStoryListen: 75,
    miniStoryPause: 76,
    miniStoryVocabPlay: 77,
    miniStoryVocabPlayDefinition: 78,
    miniStoryVocabPause: 79,
    miniStoryVocabPauseDefinition: 80,
    miniStoryVideoPlay: 81,
    miniStoryVideoEnd: 82,
    miniStoryOpen: 83,
    storyReadTime: 84,
    extraJuiceReadTime: 85,
    videoPlayTime: 86,
    miniStoryVideoPlayTime: 87,
    teacherShareStory: 88,
    teacherShareStoryWithGoogleClassroom: 89,
    teacherExportClassScores: 90,
    teacherExportClassWeekScores: 91,
    searchMiniOpenExtraJuice: 92,
    teacherOpenSearch: 93,
    teacherOnSearch: 94,
    teacherSearchSortChange: 95,
    teacherSearchGradeChange: 96,
    teacherSearchCategoriesChange: 97,
    teacherSearchOpenStory: 98,
    teacherExportAssignments: 99,
    teacherOpenAssignmentStory: 100,
    teacherOpenAssignmentQuiz: 101,
    teacherOpenAssignmentStandards: 102,
    teacherSortAssignmentsByDate: 103,
    teacherSortAssignmentsByScore: 104,
    teacherSortAssignmentsByGrade: 105,
    teacherSortAssignmentsByStandard: 106,
    teacherCreateAssignment: 107,
    assignmentStoryOpen: 108,
    assignmentStorySubmitQuiz: 109,
    assignmentStoryOpenExtraJuice: 110,
    assignmentStoryOpenVocab: 111,
    assignmentStoryPlayVideo: 112,
    assignmentStoryOpenInfographic: 113,
    assignmentStoryListen: 114,
    teacherSignUpTakeTheTour: 115,
    teacherSignUpExploreOnMyOwn: 116,
    teacherSignUpTourVideoPlay: 117,
    teacherSignUpTourVideoPause: 118,
    teacherSignUpTourVideoEnd: 119,
    printStory: 120,
    searchStoryListen: 121,
    text2SpeechHighlight: 122,

    fivesyPlay: 200,
    fivesyOpen: 201,
    fivesyClose: 202,
    fivesyGuess: 203,

    translateStory: 250,
    translateMiniStory: 251,
    translateMiniSearchStory: 252,
    translateExtraJuice: 253,
    translateMiniExtraJuice: 254,
    translateMiniSearchExtraJuice: 255,

    progressReportOpen: 300,
    progressReportRunNow: 301,
    progressReportRecommendationOnly: 302,
    progressReportAutoAdjust: 303,
    progressReportUpdateStudentGrade: 304,
    progressReportUserAutoAdjust: 305,
    progressReportBulkUpdateStudentsGrade: 306,
    progressReportFrequencySetting: 307,
    progressReportDownload: 308,

    scoreReportOpen: 350,
    scoreReportRun: 351,
    scoreReportDownloadAll: 352,
    scoreReportDownloadStudent: 353,
    scoreReportDownloadSummary: 354,

    juiceStoryVocabOpenWithTranslation: 380,
};


const parsePathname = (pathname) => {
    let newPath = "";

    if (pathname) {
        newPath = pathname
            .split("/")
            .filter((path) => path !== "");
    }

    const loc = newPath[0] || "";

    if (loc === "") {
        return locationIds.home;
    }

    if (loc === "daily-juice" || loc === "teacher-daily-juices") {
        return locationIds.dailyJuice;
    }

    if (loc === "daily-juices") {
        return locationIds.dailyJuices;
    }

    if (loc === "assignments") {
        return locationIds.assignment;
    }

    if (loc === "dashboard") {
        return locationIds.dashboard;
    }

    if (loc === "class") {
        return locationIds.class;
    }

    if (loc === "search") {
        return locationIds.dailyJuice;
    }

    if (loc === "user" && newPath[1] === "profile") {
        return locationIds.myAccount;
    }

    return "";
};

export default (methods) => {
    const ids = {
        location: locationIds,
        event: eventIds,
    };

    return {
        user: user({ ...methods, parsePathname }, ids),

        classes: classes(methods, ids),
        dailyJuice: dailyJuice(methods, ids),
        search: search(methods, ids),
        searchMini: searchMini(methods, ids),
        guardian: guardian(methods, ids),
        teacher: teacher(methods, ids),
        student: student(methods, ids),
        assignment: assignment(methods, ids),
        mini: mini(methods, ids),
        fivesy: fivesy({ ...methods, parsePathname }, ids),
        print: print({ ...methods, parsePathname }, ids),
        translation: translation({ ...methods, parsePathname }, ids),
        progressReport: progressReport(methods, ids),
        scoreReport: scoreReport(methods, ids),

        // -------- old events --------

        dailyJuicesHomePageSponsor(params) {
            methods.methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceSponsorHome,
            });
        },

        dailyJuiceFirstPageSponsor(params) {
            methods.methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceSponsorFirst,
                objectId: params.juiceId,
            });
        },

        dailyJuiceOnClickReadStory(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceStoryReadStory,
                objectId: params.juiceId,
                subObjectId: params.postId,
            });
        },

        extraJuiceReadMore(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.extraJuiceReadMore,
            });
        },

        dailyJuiceOnOpenQuiz(params) {
            // TODO
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.juiceStoryQuizQuestionOpen,
            });
        },

        // -------- old events --------

        studentHomeVideoOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.studentHomeVideoOpen,
                objectId: params.videoId,
            });
        },

        studentHomeVideoPlay(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.studentHomeVideoPlay,
                objectId: params.videoId,
            });
        },

        studentHomeVideoPause(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.home,
                eventId: eventIds.studentHomeVideoPause,
                objectId: params.videoId,
            });
        },

        /* --- */

        teacherDailyJuiceOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceOpen,
                objectId: params.juiceId,
            });
        },

        teacherDailyJuiceClose(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceClose,
            });
        },

        teacherDailyJuiceExtraJuiceOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceExtraJuiceOpen,
                objectId: params.juiceId,
                subObjectId: params.extraJuiceId,
            });
        },

        teacherDailyJuiceExtraJuiceVocabOpen(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceExtraJuiceVocabOpen,
                objectId: params.extraJuiceId,
                subObjectId: params.wordId,
            });
        },

        teacherDailyJuiceVideoStart(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceVideoStart,
                objectId: params.storyId,
                subObjectId: params.videoId,
            });
        },

        teacherDailyJuiceVideoPause(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceVideoPause,
                objectId: params.storyId,
                subObjectId: params.videoId,
            });
        },

        // TODO:
        teacherDailyJuiceDefinitionPlay(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuiceDefinitionPlay,
                objectId: params.wordId,
            });
        },

        teacherDailyJuicePronouncePlay(params) {
            methods.sendEvent({
                session: params.session,
                locationId: locationIds.dailyJuice,
                eventId: eventIds.teacherDailyJuicePronouncePlay,
                objectId: params.wordId,
            });
        },
    };
};
