import * as types from "./types.js";


export const setSchools = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_SCHOOLS,
    payload,
});

export const setSchoolName = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_SCHOOL_NAME,
    payload,
});

/* --------- */

export const setClassCodeByDepartmentId = (payload) => ({
    type: types.SET_CLASS_CODE_BY_DEPARTMENT_ID,
    payload,
});

/* --------- */

export const setDepartmentsBySchoolId = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_DEPARTMENTS_BY_SCHOOL_ID,
    payload,
});

export const setClassesByDepartmentId = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASSES_BY_DEPARTMENT_ID,
    payload,
});

export const setTeachersByDepartmentId = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_TEACHERS_BY_DEPARTMENT_ID,
    payload,
});

export const setDepartmentBySchoolId = (payload) => ({
    type: types.SET_DEPARTMENT_BY_SCHOOL_ID,
    payload,
});

/* --- */

export const setClassPerformanceLoading = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASS_PERFORMANCE_LOADING,
    payload,
});

export const setClassPerformance = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASS_PERFORMANCE,
    payload,
});

export const clearClassPerformance = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_CLASS_PERFORMANCE,
    payload,
});

/* --- */

export const setClassCategoriesLoading = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASS_CATEGORIES_LOADING,
    payload,
});

export const setClassCategories = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASS_CATEGORIES,
    payload,
});

export const clearClassCategories = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_CLASS_CATEGORIES,
    payload,
});

/* --- */

export const setClassStandardsStatsLoading = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS_LOADING,
    payload,
});

export const setClassStandardsStats = (payload) => ({
    type: types.SET_DISTRICT_ADMIN_CLASS_STANDARDS_STATS,
    payload,
});

export const clearClassStandardsStats = (payload) => ({
    type: types.CLEAR_DISTRICT_ADMIN_CLASS_STANDARDS_STATS,
    payload,
});

/* --- */

export const setSelectedSchool = (schoolId) => ({
    type: types.SET_DISTRICT_ADMIN_SELECTED_SCHOOL,
    payload: { schoolId },
});

export const setSelectedDepartment = (departmentId) => ({
    type: types.SET_DISTRICT_ADMIN_SELECTED_DEPARTMENT,
    payload: { departmentId },
});

export const setSelectedClass = (classId) => ({
    type: types.SET_DISTRICT_ADMIN_SELECTED_CLASS,
    payload: { classId },
});
