// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xXpEd7FdIqam4FhJ1LrR {\n    width: 100%;\n    height: auto;\n    background-color: var(--black);\n\n    outline: none;\n}\n\n.wsRaTR_effdYZMUEDFuK[poster] {\n    object-fit: cover;\n}\n", "",{"version":3,"sources":["webpack://./base/components/video-default/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,8BAA8B;;IAE9B,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".video {\n    width: 100%;\n    height: auto;\n    background-color: var(--black);\n\n    outline: none;\n}\n\n.videoPoster[poster] {\n    object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": "xXpEd7FdIqam4FhJ1LrR",
	"videoPoster": "wsRaTR_effdYZMUEDFuK"
};
export default ___CSS_LOADER_EXPORT___;
