import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const PlayerButton = (props) => {
    const onClick = (evt) => {
        evt.stopPropagation();
        props.onClick();
    };

    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        const iconClassName = classNames({
            [styles.controlBtnImg]: true,
            [styles.controlBtnImgBig]: props.isBig,
            [styles.controlBtnImgMobile]: props.isMobile,
            [styles.controlBtnImgReversed]: props.isIconReversed,
        });

        return React.cloneElement(props.icon, {
            className: iconClassName,
        });
    };

    const renderLabel = () => {
        if (!props.label) {
            return null;
        }

        const labelClassName = classNames({
            [styles.label]: true,
            [styles.labelGreen]: props.isGreen,
        });

        return (
            <div className={labelClassName}>
                {props.label}
            </div>
        );
    };

    const containerClassName = classNames({
        [styles.controlContainer]: true,
        [styles.controlContainerMobile]: props.isMobile,
        [styles.controlContainerWithBorder]: props.withBorder,
    });

    const controlClassName = classNames({
        [styles.controlBtn]: true,
        [styles.controlBtnBig]: props.isBig,
        [styles.controlBtnMobile]: props.isMobile,
        [styles.controlBtnPressedGreen]: props.isPressed && props.isGreen,
        [styles.controlBtnPressedOrange]: props.isPressed && props.isOrange,
        [props.className]: !!props.className,
    });

    return (
        <div className={containerClassName}>
            <div
                className={controlClassName}
                onClick={onClick}
                onKeyPress={onClick}
                tabIndex="-1"
                role="button"
            >
                {renderIcon()}
                {renderLabel()}
            </div>
        </div>
    );
};

PlayerButton.defaultProps = {
    className: "",
    icon: null,
    label: "",
    onClick: () => { },
    isIconReversed: false,
    withBorder: false,
    isPressed: false,
    isGreen: false,
    isOrange: false,
    isBig: false,
    isMobile: false,
};

export default PlayerButton;
