import { useEffect, useState } from "react";


const getState = () => ({
    isVisible: false,
    isBarVisible: false,
});

const useNotificationsNav = () => {
    const [state, setState] = useState(() => getState());

    /* --- */

    const showBar = () => {
        setState((prev) => ({
            ...prev,
            isBarVisible: true,
        }));
    };

    const hideBar = () => {
        setState((prev) => ({
            ...prev,
            isBarVisible: false,
        }));
    };

    const open = () => {
        setState((prev) => ({
            ...prev,
            isVisible: true,
        }));

        globalThis.setTimeout(showBar, 50);
    };

    const close = () => {
        setState(getState());
    };

    /* --- */

    useEffect(() => {
        if (state.isBarVisible) {
            return;
        }

        const closeTimeout = globalThis.setTimeout(close, 300);

        // eslint-disable-next-line consistent-return
        return () => {
            globalThis.clearTimeout(closeTimeout);
        };
    }, [state.isBarVisible]);

    /* --- */

    const toggle = () => {
        if (!state.isVisible) {
            open();
            return;
        }

        if (!state.isBarVisible) {
            showBar();
            return;
        }

        hideBar();
    };

    /* --- */

    return {
        state,
        hideBar,
        toggle,
    };
};

export default useNotificationsNav;
