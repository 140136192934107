import React from "react";

import text from "@/base/text/index.js";

import Grades from "@/base/project/grades.js";

import TeacherClassSetUpForm from "@/base/forms/teacher-class-set-up/index.js";

import styles from "./styles.module.css";


const PageClassSetup = (props) => {
    const getInitialValues = () => {
        let department = "";
        let className = "";

        if (props.isParentOrFamily) {
            department = {
                value: "Family",
                isCreated: true,
            };
            className = props.userLastName || "Family";
        }

        return {
            department,
            className,
            grades: "",
            endDate: "",
        };
    };

    const renderMessage = () => {
        if (props.isParentOrFamily) {
            return (
                <div className={styles.classSetupMessage}>
                    You can adjust this default reading level or each
                    student&apos;s reading level in your teacher portal.
                </div>
            );
        }

        return (
            <div className={styles.classSetupMessage}>
                By default, your class reading level will be set at this selected level.
                You can adjust this default reading level or each student&apos;s reading level
                in your teacher portal.
            </div>
        );
    };

    const renderTeacherClassSetUpMessage = () => {
        if (props.isParentOrFamily) {
            return null;
        }

        return (
            <div className={styles.classSetupMessage}>
                Your account is setup with a single class that falls under a specific department.
                You can set yours up now with the fields below.
                If you choose to skip this step, your trial account will be established with
                a generic department and class name.
                Your class reading level will automatically be set at the Grade 5-6 level.
            </div>
        );
    };

    const renderFamilyClassSetUpMessage = () => {
        if (!props.isParentOrFamily) {
            return null;
        }

        return (
            <div className={styles.classSetupMessage}>
                Family accounts are setup as a single class with a specific reading level.
                If you choose to skip this step, your trial account will be established with
                the class name already shown below and the reading level will
                automatically be set at the Grade 5-6 level.
            </div>
        );
    };

    /* --- */

    const grades = Grades.getGradesOptions()
        .map((grade) => ({
            value: grade.range,
            name: Grades.getGradeGroup([grade.value.split("-")[0]]),
        }));

    const submitButtonText = props.withStepPayment
        ? "Payment"
        : "Finish";

    return (
        <div className={styles.wizardPage}>
            <div className={styles.schoolDetailsLabel}>
                {text.teacherSignUpForm4}
            </div>
            {renderTeacherClassSetUpMessage()}
            {renderFamilyClassSetUpMessage()}
            <TeacherClassSetUpForm
                initialValues={getInitialValues()}
                grades={grades}
                departmentsOptions={props.departments}
                message={renderMessage()}
                submitButtonText={submitButtonText}
                onBack={props.onBack}
                onSubmit={props.onSubmit}
                isFamily={props.isParentOrFamily}
                isDisabledTabIndex={props.isDisabledTabIndex}
                withEndDate={props.withEndDate}
            />
        </div>
    );
};

PageClassSetup.defaultProps = {
    userLastName: "",
    departments: [],
    onBack: () => { },
    onSubmit: () => { },
    isParentOrFamily: false,
    isDisabledTabIndex: false,
    withStepPayment: false,
    withEndDate: false,
};

export default PageClassSetup;
