import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import urls from "@/base/lib/urls.js";

import User from "@/base/project/user.js";
import Popups from "@/base/project/popups.js";

import popupsActions from "@/base/actions/popups.js";
import actions from "@/base/store/actions.js";
import storage from "@/base/lib/storage/index.js";

import Overlay from "@/base/components/overlay/index.js";
import PopupMessage from "@/base/components/popup-message/index.js";
import PopupMessageWithImage from "@/base/components/popup-message-with-image/index.js";
import PopupMessagePosition from "@/base/components/popup-message-position/index.js";

import api from "@/app/api.js";


const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    user: state.user.user,
    isTeacherWelcomePopupOpen: state.user.isTeacherWelcomePopupOpen,

    isPopupsLoaded: state.popups.isPopupsLoaded,
    popups: state.popups.popups,

    isClassesLoaded: state.teacher.isClassesLoaded,
    classes: state.teacher.classes,
});

const PopupMessagesContainer = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const filteredPopups = store.popups.filter((data) => {
        const { state, popup } = data;

        if (!Popups.isAccessiblePopup(popup, store.user)) {
            return false;
        }

        if (User.hasRoleTeacher(store.user)
            && Popups.isPopupForTeacher(popup)) {
            if (store.isTeacherWelcomePopupOpen) {
                return null;
            }

            if (!store.isClassesLoaded) {
                return false;
            }

            if (store.classes.length === 0) {
                return false;
            }
        }

        if (state.isClosed) {
            return false;
        }

        const expirationDays = popup.expirationDays || 0;

        if (expirationDays > 0
            && !User.hasEnoughExpirationDaysLeft(store.user, expirationDays)) {
            return false;
        }

        if (popup.testMode) {
            return true;
        }

        if (popup.totalTimesToDisplay <= state.timesDisplayed) {
            return false;
        }

        return true;
    });

    /* --- */

    const onMountPopup = (popup) => {
        if (popup.timeToDisplay <= 0) {
            return;
        }

        dispatch(popupsActions.closePopupAfterTimeout({ actions, storage }, {
            popupId: popup.id,
            timeoutSeconds: popup.timeToDisplay,
            isTest: popup.testMode,
        }));
    };

    const onClosePopup = (popupId, isTest) => {
        dispatch(popupsActions.closePopup({ actions, storage }, {
            popupId,
            isTest,
        }));
    };

    const renderOverlay = () => {
        for (let i = 0; i < filteredPopups.length; i += 1) {
            const { state, popup } = filteredPopups[i];

            const colors = popup.colors?.[store.theme] || {};
            const opacity = colors?.overlayTransperency || 0;
            const opacityColor = colors?.overlayColor || "";

            if (!state.isClosing && opacity > 0) {
                return (
                    <Overlay
                        color={opacityColor}
                        opacity={opacity}
                    />
                );
            }
        }

        return null;
    };

    const renderPopups = () => {
        return filteredPopups.map((data) => {
            const { state, popup } = data;

            const title = popup.displayTitle
                ? popup.title || ""
                : "";

            const buttonText = popup.showButton
                ? popup.buttonText || "Agree"
                : "";

            const maxWidth = popup.popupMaximumWidth || 0;
            const colors = popup.colors?.[store.theme] || {};

            let popupElem = null;

            if (popup.image) {
                popupElem = (
                    <PopupMessageWithImage
                        key={popup.id}
                        title={popup.title}
                        content={popup.content}
                        image={popup.image}
                        maxWidth={maxWidth}
                        onMount={() => {
                            onMountPopup(popup);
                        }}
                        onOpenNewTab={(url) => {
                            urls.openUrl(url);
                        }}
                        onImageClick={() => {
                            urls.openUrl(popup.link);
                            onClosePopup(popup.id, popup.testMode);
                        }}
                        onNavigate={(url) => {
                            navigate(url);
                            onClosePopup(popup.id, popup.testMode);
                        }}
                        onClose={() => {
                            onClosePopup(popup.id, popup.testMode);
                        }}
                        isHidden={state.isClosing}
                    />
                );
            } else {
                popupElem = (
                    <PopupMessage
                        key={popup.id}
                        title={title}
                        content={popup.content}
                        maxWidth={maxWidth}
                        colors={colors}
                        buttonText={buttonText}
                        onMount={() => {
                            onMountPopup(popup);
                        }}
                        onButtonClick={() => {
                            onClosePopup(popup.id, popup.testMode);
                        }}
                        onClose={() => {
                            onClosePopup(popup.id, popup.testMode);
                        }}
                        isHidden={state.isClosing}
                    />
                );
            }

            return (
                <PopupMessagePosition
                    key={popup.id}
                    position={popup.location}
                >
                    {popupElem}
                </PopupMessagePosition>
            );
        });
    };

    /* --- */

    useEffect(() => {
        dispatch(popupsActions.loadPopups({
            actions,
            api,
            storage,
        }));
    }, []);

    /* --- */

    if (!store.isPopupsLoaded) {
        return null;
    }

    return (
        <>
            {renderOverlay()}
            {renderPopups()}
        </>
    );
};

export default PopupMessagesContainer;
