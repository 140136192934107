import text from "@/base/text/index.js";


const rateExtraJuice = (services, params) => {
    const { api, actions } = services;
    const { extraJuiceId, rating } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setExtraJuiceRatingLoading({
            extraJuiceId,
            rating,
        }));

        const res = await api.rate.rateExtraJuice({
            session: state.user.session,
            extraJuiceId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.extraJuices.setExtraJuiceRate({
                id: extraJuiceId,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setExtraJuiceRating({
            extraJuiceId,
            error,
        }));
    };
};

/* --- */

const rateAssignmentExtraJuice = (services, params) => {
    const { api, actions } = services;
    const {
        groupCode,
        assignmentId,
        storyId,
        extraJuiceId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setAssignmentExtraJuiceRatingLoading({
            extraJuiceId,
            rating,
        }));

        const res = await api.rate.rateAssignmentExtraJuice({
            session: state.user.session,
            assignmentId,
            extraJuiceId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.assignments.setAssignmentByGroupCodeStoryExtraJuiceRate({
                groupCode,
                storyId,
                extraJuiceId,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setAssignmentExtraJuiceRating({
            extraJuiceId,
            error,
        }));
    };
};

const rateAssignmentStory = (services, params) => {
    const { api, actions } = services;
    const {
        groupCode,
        assignmentId,
        storyId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setAssignmentStoryRatingLoading({
            storyId,
            rating,
        }));

        const res = await api.rate.rateAssignmentStory({
            session: state.user.session,
            assignmentId,
            storyId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.assignments.setAssignmentByGroupCodeStoryRate({
                groupCode,
                storyId,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setAssignmentStoryRating({
            storyId,
            error,
        }));
    };
};

/* --- */

const rateDailyJuiceExtraJuice = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        storyId,
        extraJuiceId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setJuiceStoryExtraJuiceRatingLoading({
            extraJuiceId,
            rating,
        }));

        const res = await api.rate.rateJuiceExtraJuice({
            session: state.user.session,
            juiceId,
            extraJuiceId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.juiceStories.setStoryInfoStoryExtraJuiceRate({
                juiceId,
                storyId,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setJuiceStoryExtraJuiceRating({
            extraJuiceId,
            error,
        }));
    };
};

const rateDailyJuiceStory = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        storyId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setJuiceStoryRatingLoading({
            storyId,
            rating,
        }));

        const res = await api.rate.rateJuiceStory({
            session: state.user.session,
            juiceId,
            storyId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.juiceStories.setStoryInfoStoryRate({
                juiceId,
                storyId,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setJuiceStoryRating({
            storyId,
            error,
        }));
    };
};

/* --- */

const rateSearchStory = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        storyId,
        search,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setSearchStoryRatingLoading({
            storyId,
            rating,
        }));

        const res = await api.rate.rateSearchStory({
            session: state.user.session,
            juiceId,
            storyId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.search.setSearchStoryRating({
                storyId,
                search,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setSearchStoryRating({
            storyId,
            error,
        }));

        return {
            error,
        };
    };
};

const rateSearchStoryExtraJuice = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        extraJuiceId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.uiStoriesRating.setSearchStoryExtraJuiceRatingLoading({
            extraJuiceId,
            rating,
        }));

        const res = await api.rate.rateSearchStory({
            session: state.user.session,
            juiceId,
            storyId: extraJuiceId,
            rating,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.search.setSearchStoryExtraJuiceRating({
                extraJuiceId,
                rating,
            }));
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.uiStoriesRating.setSearchStoryExtraJuiceRating({
            extraJuiceId,
            error,
        }));

        return {
            error,
        };
    };
};

/* --- */

export default {
    rateExtraJuice,
    rateAssignmentStory,
    rateAssignmentExtraJuice,
    rateDailyJuiceStory,
    rateDailyJuiceExtraJuice,
    rateSearchStory,
    rateSearchStoryExtraJuice,
};
