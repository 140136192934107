export const OPEN_ADMIN_POPUP_EDIT_DISTRICT = "OPEN_ADMIN_POPUP_EDIT_DISTRICT";
export const SET_ADMIN_POPUP_EDIT_DISTRICT_LOADING = "SET_ADMIN_POPUP_EDIT_DISTRICT_LOADING";
export const SET_ADMIN_POPUP_EDIT_DISTRICT_SUCCESS = "SET_ADMIN_POPUP_EDIT_DISTRICT_SUCCESS";
export const CLEAR_ADMIN_POPUP_EDIT_DISTRICT = "CLEAR_ADMIN_POPUP_EDIT_DISTRICT";

export const OPEN_ADMIN_POPUP_DELETE_DISTRICT = "OPEN_ADMIN_POPUP_DELETE_DISTRICT";
export const SET_ADMIN_POPUP_DELETE_DISTRICT_LOADING = "SET_ADMIN_POPUP_DELETE_DISTRICT_LOADING";
export const SET_ADMIN_POPUP_DELETE_DISTRICT_SUCCESS = "SET_ADMIN_POPUP_DELETE_DISTRICT_SUCCESS";
export const CLEAR_ADMIN_POPUP_DELETE_DISTRICT = "CLEAR_ADMIN_POPUP_DELETE_DISTRICT";

export const OPEN_ADMIN_POPUP_EDIT_SCHOOL = "OPEN_ADMIN_POPUP_EDIT_SCHOOL";
export const SET_ADMIN_POPUP_SCHOOL_EDIT_LOADING = "SET_ADMIN_POPUP_SCHOOL_EDIT_LOADING";
export const SET_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS = "SET_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS";
export const CLEAR_ADMIN_POPUP_SCHOOL_EDIT = "CLEAR_ADMIN_POPUP_SCHOOL_EDIT";

export const OPEN_ADMIN_POPUP_SCHOOL_DELETE = "OPEN_ADMIN_POPUP_SCHOOL_DELETE";
export const SET_ADMIN_POPUP_SCHOOL_DELETE_LOADING = "SET_ADMIN_POPUP_SCHOOL_DELETE_LOADING";
export const SET_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS = "SET_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS";
export const CLEAR_ADMIN_POPUP_SCHOOL_DELETE = "CLEAR_ADMIN_POPUP_SCHOOL_DELETE";

export const OPEN_ADMIN_POPUP_DEPARTMENT_EDIT = "OPEN_ADMIN_POPUP_DEPARTMENT_EDIT";
export const SET_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING = "SET_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING";
export const SET_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS = "SET_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS";
export const CLEAR_ADMIN_POPUP_DEPARTMENT_EDIT = "CLEAR_ADMIN_POPUP_DEPARTMENT_EDIT";

export const OPEN_ADMIN_POPUP_DEPARTMENT_DELETE = "OPEN_ADMIN_POPUP_DEPARTMENT_DELETE";
export const SET_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING = "SET_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING";
export const SET_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS = "SET_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS";
export const CLEAR_ADMIN_POPUP_DEPARTMENT_DELETE = "CLEAR_ADMIN_POPUP_DEPARTMENT_DELETE";

export const OPEN_ADMIN_POPUP_CLASS_EDIT = "OPEN_ADMIN_POPUP_CLASS_EDIT";
export const SET_ADMIN_POPUP_CLASS_EDIT_LOADING = "SET_ADMIN_POPUP_CLASS_EDIT_LOADING";
export const SET_ADMIN_POPUP_CLASS_EDIT_SUCCESS = "SET_ADMIN_POPUP_CLASS_EDIT_SUCCESS";
export const CLEAR_ADMIN_POPUP_CLASS_EDIT = "CLEAR_ADMIN_POPUP_CLASS_EDIT";

export const OPEN_ADMIN_POPUP_CLASS_DELETE = "OPEN_ADMIN_POPUP_CLASS_DELETE";
export const SET_ADMIN_POPUP_CLASS_DELETE_LOADING = "SET_ADMIN_POPUP_CLASS_DELETE_LOADING";
export const SET_ADMIN_POPUP_CLASS_DELETE_SUCCESS = "SET_ADMIN_POPUP_CLASS_DELETE_SUCCESS";
export const CLEAR_ADMIN_POPUP_CLASS_DELETE = "CLEAR_ADMIN_POPUP_CLASS_DELETE";

export const OPEN_ADMIN_POPUP_CLASS_MOVE = "OPEN_ADMIN_POPUP_CLASS_MOVE";
export const SET_ADMIN_POPUP_CLASS_MOVE_DISTRICT = "SET_ADMIN_POPUP_CLASS_MOVE_DISTRICT";
export const SET_ADMIN_POPUP_CLASS_MOVE_SCHOOL = "SET_ADMIN_POPUP_CLASS_MOVE_SCHOOL";
export const SET_ADMIN_POPUP_CLASS_MOVE_LOADING = "SET_ADMIN_POPUP_CLASS_MOVE_LOADING";
export const SET_ADMIN_POPUP_CLASS_MOVE_SUCCESS = "SET_ADMIN_POPUP_CLASS_MOVE_SUCCESS";
export const CLEAR_ADMIN_POPUP_CLASS_MOVE = "CLEAR_ADMIN_POPUP_CLASS_MOVE";

export const OPEN_ADMIN_POPUP_DEPARTMENT_MOVE = "OPEN_ADMIN_POPUP_DEPARTMENT_MOVE";
export const SET_ADMIN_POPUP_DEPARTMENT_MOVE_DISTRICT = "SET_ADMIN_POPUP_DEPARTMENT_MOVE_DISTRICT";
export const SET_ADMIN_POPUP_DEPARTMENT_MOVE_LOADING = "SET_ADMIN_POPUP_DEPARTMENT_MOVE_LOADING";
export const SET_ADMIN_POPUP_DEPARTMENT_MOVE_SUCCESS = "SET_ADMIN_POPUP_DEPARTMENT_MOVE_SUCCESS";
export const CLEAR_ADMIN_POPUP_DEPARTMENT_MOVE = "CLEAR_ADMIN_POPUP_DEPARTMENT_MOVE";

export const OPEN_ADMIN_POPUP_SCHOOL_MOVE = "OPEN_ADMIN_POPUP_SCHOOL_MOVE";
export const SET_ADMIN_POPUP_SCHOOL_MOVE_LOADING = "SET_ADMIN_POPUP_SCHOOL_MOVE_LOADING";
export const SET_ADMIN_POPUP_SCHOOL_MOVE_SUCCESS = "SET_ADMIN_POPUP_SCHOOL_MOVE_SUCCESS";
export const CLEAR_ADMIN_POPUP_SCHOOL_MOVE = "CLEAR_ADMIN_POPUP_SCHOOL_MOVE";

export const OPEN_ADMIN_POPUP_ENTITIES_ADD = "OPEN_ADMIN_POPUP_ENTITIES_ADD";
export const SET_ADMIN_POPUP_ENTITIES_ADD_LOADING = "SET_ADMIN_POPUP_ENTITIES_ADD_LOADING";
export const SET_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED = "SET_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED";
export const SET_ADMIN_POPUP_ENTITIES_ADD_DISTRICT_ADDED = "SET_ADMIN_POPUP_ENTITIES_ADD_DISTRICT_ADDED";
export const SET_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED = "SET_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED";
export const SET_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED = "SET_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED";
export const SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DISTRICT = "SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DISTRICT";
export const SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL = "SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL";
export const SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT = "SET_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT";
export const CLEAR_ADMIN_POPUP_ENTITIES_ADD_STATUSES = "CLEAR_ADMIN_POPUP_ENTITIES_ADD_STATUSES";
export const CLEAR_ADMIN_POPUP_ENTITIES_ADD = "CLEAR_ADMIN_POPUP_ENTITIES_ADD";
