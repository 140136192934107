import text from "@/base/text/index.js";


const sendEventPrintStory = (services, params) => {
    const {
        storyId,
        includeNotesPage,
        includeVocabularyDefinitions,
        includeQuizQuestion,
        includeExtraJuice,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const items = [];

        if (includeQuizQuestion) {
            items.push("Q");
        }

        if (includeVocabularyDefinitions) {
            items.push("V");
        }

        if (includeExtraJuice) {
            items.push("E");
        }

        if (includeNotesPage) {
            items.push("N");
        }

        services.events.print.printStory({
            session: state.user.session,
            location: state.navigation.location?.pathname || "",
            storyId,
            items: items.join(""),
        });
    };
};

const getDailyJuiceStory = (services, params) => {
    const { api } = services;

    const {
        juiceId,
        storyId,
        grade,
        includeNotesPage,
        includeVocabularyDefinitions,
        includeQuizQuestion,
        includeExtraJuice,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(sendEventPrintStory(services, params));

        const res = await api.print.getDailyJuiceStory({
            session: state.user.session,
            juiceId,
            storyId,
            grade,
            includeNotesPage,
            includeVocabularyDefinitions,
            includeQuizQuestion,
            includeExtraJuice,
        });

        let error = "";
        let pdfUrl = "";

        if (res.ok) {
            pdfUrl = res.pdfUrl;
        } else {
            error = res.error || text.error;
        }

        return {
            pdfUrl,
            error,
        };
    };
};

export default {
    getDailyJuiceStory,
};
