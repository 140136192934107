import React, { useEffect } from "react";

import classNames from "@/base/lib/class-names.js";

import Vocabulary from "@/base/project/vocabulary.js";

import EventsWrapper from "@/base/components/events-wrapper/index.js";
import WordCardDefinitions from "./definitions.js";
import WordCardWord from "./word.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";


const WordCard = (props) => {
    const groupedDefinitions = Vocabulary.groupWordDefinitionsByPos(props.definitions);

    const translationState = {
        error: props.translation.error || "",
        isLoading: props.translation.isLoading || false,
        isVisible: props.translation.isVisible || false,
    };

    /* --- */

    useEffect(() => {
        return () => {
            const ds = groupedDefinitions.map(([pos, gDs]) => {
                const text = [pos];
                gDs.forEach((d, i) => {
                    text.push(`${i + 1}. ${d.definition}.`);
                });

                return text.join(". ");
            });

            props.onStopAll([
                props.word.word,
                ...ds,
            ]);
        };
    }, []);

    /* --- */

    const renderTitle = () => {
        if (!props.isTitleVisible) {
            return null;
        }

        return (
            <div className={styles.wordTitle}>
                Vocabulary
            </div>
        );
    };

    /* --- */

    const renderWord = () => {
        const translation = {
            ...translationState,
            word: props.translation.word || {},
        };

        return (
            <WordCardWord
                word={props.word}
                audio={props.audio}
                translation={translation}
                onLoad={props.onLoad}
                onPlay={props.onPlay}
                onStop={props.onStop}
            />
        );
    };

    const renderDefinitions = () => {
        const definitions = props.translation.definitions || [];

        const translation = {
            ...translationState,
            definitions: Vocabulary.groupWordDefinitionsByPos(definitions),
        };

        return (
            <WordCardDefinitions
                groupedDefinitions={groupedDefinitions}
                translation={translation}
                word={props.word}
                audio={props.audio}
                onLoad={props.onLoad}
                onPlay={props.onPlay}
                onStop={props.onStop}
            />
        );
    };

    /* --- */

    if (!props.word) {
        return null;
    }

    const wrapperClassName = classNames({
        [styles.wordCard]: true,
        [styles.wordCardWide]: translationState.isVisible,
        [scrollbarStyles.scrollbar]: true,
        [props.className]: !!props.className,
    });

    return (
        <EventsWrapper className={wrapperClassName}>
            {renderTitle()}
            {renderWord()}
            {renderDefinitions()}
        </EventsWrapper>
    );
};

WordCard.defaultProps = {
    word: null,
    definitions: [],
    audio: {},
    translation: {},
    className: "",
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
    onStopAll: () => { },
    isTitleVisible: false,
};

export default WordCard;
