export const SET_SEARCH_STANDARDS = "SET_SEARCH_STANDARDS";

export const SET_SEARCH_CATEGORIES = "SET_SEARCH_CATEGORIES";

export const SET_SEARCH_CONTENT_TAGS_LOADING = "SET_SEARCH_CONTENT_TAGS_LOADING";
export const SET_SEARCH_CONTENT_TAGS = "SET_SEARCH_CONTENT_TAGS";

export const SET_SEARCH_EMPTY_LOADING = "SET_SEARCH_EMPTY_LOADING";
export const SET_SEARCH_EMPTY_DATA = "SET_SEARCH_EMPTY_DATA";

export const SET_SEARCH_BY_KEYWORD = "SET_SEARCH_BY_KEYWORD";
export const SET_SEARCH_BY_KEYWORD_LOADING = "SET_SEARCH_BY_KEYWORD_LOADING";

export const SET_SEARCH_STORY_RATING = "SET_SEARCH_STORY_RATING";
export const SET_SEARCH_STORY_EXTRA_JUICE_RATING = "SET_SEARCH_STORY_EXTRA_JUICE_RATING";
