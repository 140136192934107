import { useState } from "react";


const getState = () => ({
    error: "",
    isVisible: false,
});

const usePopupConfirmError = () => {
    const [state, setState] = useState(() => getState());

    const open = (error) => {
        setState((prev) => ({
            ...prev,
            error,
            isVisible: true,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};

export default usePopupConfirmError;
