import * as types from "./types.js";


export const setUserLoading = (isUserLoading) => ({
    type: types.SET_USER_LOADING,
    payload: {
        isUserLoading,
    },
});

export const setUserSession = (session) => ({
    type: types.SET_USER_SESSION,
    payload: {
        session,
    },
});

export const setUser = (user) => ({
    type: types.SET_USER,
    payload: {
        user,
    },
});

export const clearUser = () => ({
    type: types.CLEAR_USER,
});

/* --- */

export const setUserEmailNotification = (payload) => ({
    type: types.SET_USER_EMAIL_NOTIFICATION,
    payload,
});

export const setUserAutoRenewSubscription = (payload) => ({
    type: types.SET_USER_AUTO_RENEW_SUBSCRIPTION,
    payload,
});

export const setHasActiveCancelRequest = (payload) => ({
    type: types.SET_USER_HAS_ACTIVE_CANCEL_REQUEST,
    payload,
});

/* --- */

export const setUserFullName = (payload) => ({
    type: types.SET_USER_FULL_NAME,
    payload,
});

export const setUserAvatar = (payload) => ({
    type: types.SET_USER_AVATAR,
    payload,
});

/* --- */

export const setUserRememberMe = (isRememberMe) => ({
    type: types.SET_USER_REMEMBER_ME,
    payload: { isRememberMe },
});

/* --- */

export const setUserGradesLoading = (isLoading) => ({
    type: types.SET_USER_GRADES_LOADING,
    payload: { isLoading },
});

export const setUserGrades = (grades) => ({
    type: types.SET_USER_GRADES,
    payload: { grades },
});

/* --- */

export const setTranslationLanguage = (payload) => ({
    type: types.SET_USER_TRANSLATION_LANGUAGE,
    payload,
});

/* --- */

export const setIsBadEmail = (isBadEmail) => ({
    type: types.SET_USER_IS_BAD_EMAIL,
    payload: { isBadEmail },
});

export const setEmail = (email) => ({
    type: types.SET_USER_EMAIL,
    payload: { email },
});

/* --- */

export const setReadingMode = (payload) => ({
    type: types.SET_USER_READING_MODE,
    payload,
});

/* --- */

export const setUserOptions = (payload) => ({
    type: types.SET_USER_OPTIONS,
    payload,
});

export const setUserOption = (payload) => ({
    type: types.SET_USER_OPTION,
    payload,
});

/* --- */

export const setUserPaymentDetails = (payload) => ({
    type: types.SET_USER_PAYMENT_DETAILS,
    payload,
});

export const setUserPaymentDetailsLoaded = (payload) => ({
    type: types.SET_USER_PAYMENT_DETAILS_LOADED,
    payload,
});

/* --- */

export const setClassesLimitInfoLoading = (payload) => ({
    type: types.SET_USER_CLASSES_LIMIT_INFO_LOADING,
    payload,
});

export const setClassesLimitInfo = (payload) => ({
    type: types.SET_USER_CLASSES_LIMIT_INFO,
    payload,
});

/* --- */

export const setTeacherWelcomePopupOpened = (payload) => ({
    type: types.SET_TEACHER_WELCOME_POPUP_OPENED,
    payload,
});

export const setTeacherWelcomePopupClosed = (payload) => ({
    type: types.SET_TEACHER_WELCOME_POPUP_CLOSED,
    payload,
});
