import React, { useState } from "react";

import classNames from "@/base/lib/class-names.js";

import IconArrowRight from "@/base/icons/arrow-right";

import InfoTooltip from "@/base/components/info-tooltip/index.js";
import PopupConfirmUnsavedProgressV2 from "@/base/components/popup-confirm-unsaved-progress-v2/index.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";

import Content from "./content.js";

import styles from "./styles.module.css";


const MenuWithContent = (props) => {
    const [currentMenuItem, setCurrentMenuItem] = useState(props.defaultSelected);

    const [confirmPopupState, setConfirmPopupState] = useState({
        isVisible: false,
        page: 0,
    });

    /* --- */

    const getMenu = () => {
        if (props.children) {
            if (Array.isArray(props.children)) {
                return props.children.map((child) => {
                    return {
                        name: child.props.name,
                        tooltip: child.props.tooltip || "",
                    };
                });
            }

            return [{
                name: props.children.props.name,
                tooltip: props.children.props.tooltip || "",
            }];
        }

        return [];
    };

    /* --- */

    const onCloseConfirmPopup = () => {
        setConfirmPopupState({
            isVisible: false,
            page: 0,
        });
    };

    const onChangeItem = (index, showConfirm) => {
        if (showConfirm) {
            setConfirmPopupState({
                isVisible: true,
                page: index,
            });

            return;
        }

        onCloseConfirmPopup();

        setCurrentMenuItem(index);
        props.onMenuClick(index);
    };

    /* --- */

    const renderConfirmPopup = () => {
        if (!confirmPopupState.isVisible) {
            return null;
        }

        return (
            <PopupConfirmUnsavedProgressV2
                onLeave={() => {
                    onChangeItem(confirmPopupState.page, false);
                }}
                onClose={onCloseConfirmPopup}
            />
        );
    };

    const renderMenuItems = () => {
        const menu = getMenu();

        return menu.map((data, i) => {
            const itemClassName = classNames({
                [styles.menuItem]: true,
                [styles.menuItemSelected]: i === currentMenuItem && !props.isMobile,
            });

            const showConfirm = props.showConfirmOnMenuClick && currentMenuItem !== i;

            let tooltip = null;

            if (data.tooltip) {
                const position = props.isMobile
                    ? "bottomLeft"
                    : "rightCenter";

                tooltip = (
                    <InfoTooltip
                        tooltip={data.tooltip}
                        position={position}
                    />
                );
            }

            return (
                <div
                    className={itemClassName}
                    onClick={() => { onChangeItem(i, showConfirm); }}
                    onKeyPress={() => { onChangeItem(i, showConfirm); }}
                    role="button"
                    tabIndex="-1"
                >
                    <div>
                        {data.name}
                    </div>
                    {tooltip}
                    <IconArrowRight
                        isBlack
                    />
                </div>
            );
        });
    };

    const renderContent = () => {
        if (props.children && Array.isArray(props.children)) {
            return props.children[currentMenuItem];
        }

        return props.children;
    };

    if (props.isMobile) {
        const sliderStyles = {};

        if (!props.isMobileMenuVisible) {
            sliderStyles.transform = "translateX(-100vw)";
        }

        const mobileContentClassName = classNames({
            [scrollbarStyles.scrollbar]: true,
            [styles.contentMobile]: true,
        });

        return (
            <>
                {renderConfirmPopup()}

                <div className={styles.menuWithContentMobile}>
                    <div
                        className={styles.slider}
                        style={sliderStyles}
                    >
                        <div className={styles.menuMobile}>
                            {renderMenuItems()}
                        </div>
                        <div className={mobileContentClassName}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </>

        );
    }

    const contentClassName = classNames({
        [scrollbarStyles.scrollbar]: true,
        [styles.content]: true,
    });

    return (
        <>
            {renderConfirmPopup()}

            <div className={styles.menuWithContent}>
                <div className={styles.menu}>
                    {renderMenuItems()}
                </div>
                <div className={contentClassName}>
                    {renderContent()}
                </div>
            </div>
        </>
    );
};

MenuWithContent.defaultProps = {
    isMobile: false,
    isMobileMenuVisible: true,
    showConfirmOnMenuClick: false,

    defaultSelected: 0,
    children: null,

    onMenuClick: () => { },
};

export const MenuContent = Content;
export default MenuWithContent;
