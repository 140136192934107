import Urls from "@/base/project/urls.js";

import actionsDistrictAdminClassStandards from "./district-admin-class-standards.js";
import actionsDistrictAdminClassPerformance from "./district-admin-class-performance.js";
import actionsDistrictAdminClassCategories from "./district-admin-class-categories.js";
import actionsTeacherClassDailyJuices from "./teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "./teacher-class-assignments.js";


const reloadDashboard = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const { pathname } = state.navigation.location;

        if (!Urls.isDashboardDistrictAdmin(pathname)) {
            return;
        }

        const categoriesAction = actionsDistrictAdminClassCategories
            .reloadClassCategories(services);
        const performanceAction = actionsDistrictAdminClassPerformance
            .reloadClassPerformance(services);
        const statsAction = actionsDistrictAdminClassStandards
            .reloadClassStandardsStats(services);

        categoriesAction(dispatch, getState);
        performanceAction(dispatch, getState);
        statsAction(dispatch, getState);
    };
};

const reloadClass = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const { pathname } = state.navigation.location;

        if (!Urls.isClassDistrictAdmin(pathname)) {
            return;
        }

        const reloadDailyJuices = actionsTeacherClassDailyJuices.reloadDailyJuiceScores(services);
        const reloadAssignments = actionsTeacherClassAssignments.reloadAssignmentsScores(services);

        reloadDailyJuices(dispatch, getState);
        reloadAssignments(dispatch, getState);
    };
};


export default {
    reloadDashboard,
    reloadClass,
};
