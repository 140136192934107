import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const LineWithSeparators = (props) => {
    const content = props.content.map((obj, i) => {
        let separator = null;

        if (i > 0) {
            separator = (
                <div className={props.separatorClassName}>|</div>
            );
        }

        return (
            <>
                {separator}
                {obj}
            </>
        );
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [props.className]: !!props.className,
    });

    return (
        <div className={contentClassName}>
            {content}
        </div>
    );
};

LineWithSeparators.defaultProps = {
    className: "",
    separatorClassName: "",
    content: [],
};

export default LineWithSeparators;
