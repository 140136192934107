import React, { useState } from "react";

import text from "@/base/text/index.js";

import IconCompass from "@/base/icons/compass/index.js";
import IconOrange from "@/base/icons/orange/index.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import IconClickable from "@/base/components/icon-clickable/index.js";
import TabsV2 from "@/base/components/tabs-v2/index.js";
import ButtonRounded from "@/base/components/button-rounded/index.js";
import Message from "@/base/components/message/index.js";
import DivButton from "@/base/components/div-button/index.js";

import SectionStudentV2 from "@/base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const TABS = {
    extraJuice: "extraJuice",
    videos: "videos",
};

const DailyJuiceExplore = (props) => {
    const [selectedTab, setSelectedTab] = useState(() => TABS.extraJuice);

    /* --- */

    const renderViewMoreButton = () => {
        return (
            <div className={styles.viewMoreButton}>
                <ButtonRounded
                    uppercase
                    onClick={() => {
                        props.onViewMore(selectedTab);
                    }}
                >
                    View More
                </ButtonRounded>
            </div>
        );
    };

    const renderExtraJuices = () => {
        if (!props.isExtraJuicesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const extraJuices = [];

        for (let i = 0; i < props.extraJuices.length; i += 1) {
            if (i > 3) {
                break;
            }

            const ej = props.extraJuices[i];

            extraJuices.push(
                <IconClickable
                    key={ej.id}
                    className={styles.row}
                    onClick={() => {
                        props.onExtraJuiceClick(ej.id);
                    }}
                >
                    <IconOrange
                        className={styles.rowIcon}
                        title="Extra Juice"
                    />
                    <div className={styles.rowTitle}>
                        {ej.title}
                    </div>
                </IconClickable>,
            );
        }

        if (extraJuices.length === 0) {
            return (
                <Message>
                    {text.noExtraJuices}
                </Message>
            );
        }

        return (
            <div>
                {extraJuices}
                {renderViewMoreButton()}
            </div>
        );
    };

    const renderVideos = () => {
        if (!props.isVideosLoaded) {
            return (
                <RequestLoader />
            );
        }

        const videos = [];

        for (let i = 0; i < props.videos.length; i += 1) {
            if (i > 3) {
                break;
            }

            const video = props.videos[i];

            let videoImage = null;

            if (video?.featuredImage?.url) {
                videoImage = (
                    <img
                        className={styles.rowIcon}
                        src={video.featuredImage.url}
                        alt="Preview"
                    />
                );
            }

            videos.push(
                <DivButton
                    className={styles.row}
                    onClick={() => {
                        props.onVideoClick(video);
                    }}
                >
                    {videoImage}
                    <div className={styles.rowTitle}>
                        {video.newsTitle}
                    </div>
                </DivButton>,
            );
        }

        if (videos.length === 0) {
            return (
                <Message>
                    {text.noVideos}
                </Message>
            );
        }

        return (
            <div>
                {videos}
                {renderViewMoreButton()}
            </div>
        );
    };

    const renderTabs = () => {
        const tabs = [
            { value: TABS.extraJuice, label: "Extra Juice" },
            { value: TABS.videos, label: "Videos" },
        ];

        return (
            <div className={styles.tabs}>
                <TabsV2
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onChange={setSelectedTab}
                />
            </div>
        );
    };

    const renderTabContent = () => {
        if (selectedTab === TABS.extraJuice) {
            return renderExtraJuices();
        }

        return renderVideos();
    };

    /* --- */

    return (
        <SectionStudentV2
            title="Explore"
            icon={<IconCompass isOrange />}
            titleBottomBorder={false}
        >
            {renderTabs()}
            {renderTabContent()}
        </SectionStudentV2>
    );
};

DailyJuiceExplore.defaultProps = {
    extraJuices: [],
    videos: [],

    isExtraJuicesLoaded: false,
    isVideosLoaded: false,

    onVideoClick: () => { },
    onExtraJuiceClick: () => { },

    onViewMore: () => { },
};

export default DailyJuiceExplore;
