import * as types from "./types.js";

/* --- */

export const openBadEmail = (payload) => ({
    type: types.OPEN_UI_POPUP_BAD_EMAIL,
    payload,
});

/* --- */

export const openRestoreClasses = (payload) => ({
    type: types.OPEN_UI_POPUP_RESTORE_CLASSES,
    payload,
});

/* --- */

export const clearPopups = (payload) => ({
    type: types.CLEAR_UI_POPUPS,
    payload,
});
