import React from "react";

import {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import AlertBox from "@/base/components/alert-box/index.js";


const EmailConfirm = (props) => {
    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return (
                <AlertBox theme="danger">
                    {props.error}
                </AlertBox>
            );
        }

        return (
            <div>
                <div>{`I confirm that my email address ${props.email} is correct.`}</div>
                <div>The administrators will be notified.</div>
            </div>
        );
    };

    const title = props.error
        ? "Failed to confirm"
        : "Are you sure?";

    return (
        <>
            <PopupConfirmTitle>
                {title}
            </PopupConfirmTitle>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>
            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={props.onBack}
                    disabled={props.isLoading}
                >
                    Back
                </ButtonFlat>
                <ButtonFlat
                    onClick={props.onConfirm}
                    disabled={props.isLoading}
                >
                    {props.error ? "Try again" : "Confirm"}
                </ButtonFlat>
            </PopupConfirmButtons>
        </>
    );
};

EmailConfirm.defaultProps = {
    email: "",
    error: "",
    onConfirm: () => { },
    onBack: () => { },
    isLoading: false,
};

export default EmailConfirm;
