import { useState } from "react";

import STEPS from "./steps.js";


const getState = () => ({
    step: STEPS.cancellationReason,
    cancelReason: "",
    error: "",
    cancelPreference: "",
    selectedReasonOptions: [],
    isCancelling: false,
    isCanceled: false,
});

const useCancelSubscription = () => {
    const [state, setState] = useState(() => getState());

    /* ------- */

    const isFirstStep = () => {
        return state.step === STEPS.cancellationReason;
    };

    /* ------- */

    const changeStep = (step) => {
        setState((prev) => ({
            ...prev,
            step,
            error: "",
        }));
    };

    const toggleReasonOption = (option) => {
        setState((prev) => {
            if (prev.selectedReasonOptions.indexOf(option) === -1) {
                return {
                    ...prev,
                    selectedReasonOptions: [...prev.selectedReasonOptions].concat(option),
                };
            }

            return {
                ...prev,
                selectedReasonOptions: [...prev.selectedReasonOptions]
                    .filter((opt) => opt !== option),
            };
        });
    };

    const setCancelOption = (cancelPreference) => {
        setState((prev) => ({
            ...prev,
            cancelPreference,
        }));
    };

    const setCancelReason = (cancelReason) => {
        setState((prev) => ({
            ...prev,
            cancelReason,
        }));
    };

    const cancel = () => {
        setState((prev) => ({
            ...prev,
            error: "",
            isCancelling: true,
        }));
    };

    const reset = () => {
        setState(getState());
    };

    const error = (err) => {
        setState((prev) => ({
            ...prev,
            error: err,
            isCancelling: false,
        }));
    };

    const success = () => {
        setState((prev) => ({
            ...prev,
            step: STEPS.cancellationCompleted,
            error: "",
            isCancelling: false,
            isCanceled: true,
        }));
    };

    /* ------- */

    return {
        state,

        isFirstStep,

        changeStep,
        toggleReasonOption,
        setCancelOption,
        setCancelReason,

        cancel,
        error,
        success,

        reset,
    };
};

export default useCancelSubscription;
