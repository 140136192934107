import React from "react";

import Achievements from "@/base/project/achievements.js";

import IconAchievement from "@/base/icons/achievement/index.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import Message from "@/base/components/message/index.js";

import AchievementCard from "@/base/business/achievement-card/index.js";
import SectionStudentV2 from "@/base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const DailyJuiceAchievements = (props) => {
    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return (
                <Message>
                    {props.error}
                </Message>
            );
        }

        const achievements = Achievements.getAchievements();

        return (
            <div className={styles.content}>
                <AchievementCard
                    type={achievements.storiesRead.value}
                    label={achievements.storiesRead.label}
                    theme={props.theme}
                    count={props.storiesRead}
                />
                <AchievementCard
                    type={achievements.questionsAttempted.value}
                    label={achievements.questionsAttempted.label}
                    theme={props.theme}
                    count={props.questionsAttempted}
                />
            </div>
        );
    };

    return (
        <SectionStudentV2
            title="Lifetime Achievements"
            icon={<IconAchievement isOrange />}
        >
            {renderContent()}
        </SectionStudentV2>
    );
};

DailyJuiceAchievements.defaultProps = {
    storiesRead: 0,
    questionsAttempted: 0,
    error: "",
    theme: "",
    isLoading: false,
};

export default DailyJuiceAchievements;
