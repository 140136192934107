export const SET_ADMIN_DISTRICTS_ALL_LOADING = "SET_ADMIN_DISTRICTS_ALL_LOADING";
export const SET_ADMIN_DISTRICTS_ALL = "SET_ADMIN_DISTRICTS_ALL";
export const SET_ADMIN_DISTRICTS_BY_KEYWORD_LOADING = "SET_ADMIN_DISTRICTS_BY_KEYWORD_LOADING";
export const SET_ADMIN_DISTRICTS_BY_KEYWORD = "SET_ADMIN_DISTRICTS_BY_KEYWORD";
export const UPDATE_ADMIN_DISTRICT_BY_ID = "UPDATE_ADMIN_DISTRICT_BY_ID";
export const CLEAR_ADMIN_DISTRICTS = "CLEAR_ADMIN_DISTRICTS";

export const SET_ADMIN_SELECTED_DISTRICT = "SET_ADMIN_SELECTED_DISTRICT";
export const SET_ADMIN_SELECTED_SCHOOL = "SET_ADMIN_SELECTED_SCHOOL";
export const SET_ADMIN_SELECTED_DEPARTMENT = "SET_ADMIN_SELECTED_DEPARTMENT";
export const SET_ADMIN_SELECTED_CLASS = "SET_ADMIN_SELECTED_CLASS";

export const SET_ADMIN_SCHOOL_BY_DISTRICT_ID_LOADING = "SET_ADMIN_SCHOOL_BY_DISTRICT_ID_LOADING";
export const SET_ADMIN_SCHOOL_BY_DISTRICT_ID = "SET_ADMIN_SCHOOL_BY_DISTRICT_ID";

export const SET_ADMIN_DISTRICT_BY_ID_LOADING = "SET_ADMIN_DISTRICT_BY_ID_LOADING";
export const SET_ADMIN_DISTRICT_BY_ID = "SET_ADMIN_DISTRICT_BY_ID";
