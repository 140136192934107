export default (apiURL, methods) => {
    return {
        getInfo() {
            const url = `${apiURL}/site/info`;

            return methods.get(url, {});
        },

        getSiteDate() {
            const url = `${apiURL}/site/date`;

            return methods.get(url, {});
        },
    };
};
