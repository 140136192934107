import React from "react";

import PopupConfirm, {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";
import ButtonFlat from "@/base/components/button-flat/index.js";


const PopupConfirmMissingClassesEndDate = (props) => {
    const renderContent = () => {
        return (
            <PopupConfirmContent>
                <div>There is no set end date for these classes:</div>
                <ul>
                    {props.classes.map((cl) => (
                        <li key={`class-${cl.id}`}>
                            {cl.name}
                        </li>
                    ))}
                </ul>
            </PopupConfirmContent>
        );
    };

    return (
        <PopupConfirm popupWithScroll>
            <PopupConfirmTitle>
                Class End Date is missing
            </PopupConfirmTitle>
            {renderContent()}
            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onAdd}>
                    Add
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmMissingClassesEndDate.defaultProps = {
    classes: [],
    onAdd: () => { },
};

export default PopupConfirmMissingClassesEndDate;
