import React, { useMemo } from "react";

import classNames from "@/base/lib/class-names.js";
import highlight from "@/base/lib/highlight.js";

import Text from "@/base/components/text/index.js";
import WordHighlighted from "@/base/components/word-highlighted/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import stylesDailyJuiceCommon from "@/base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const JuiceStoryTitle = (props) => {
    const title = useMemo(() => {
        if (props.highlight?.isTitle) {
            const word = props.highlight?.word || "";
            const skipCount = props.highlight?.skipSameWordCount || 0;

            return highlight.getHighlightedContent({
                content: {
                    content: props.title,
                },
                word: {
                    word,
                    skipCount,
                    wrapped: WordHighlighted(word),
                },
            });
        }

        return props.title;
    }, [props.title, props.highlight]);

    const headlineClassName = classNames({
        [stylesFonts.headline]: true,
        [stylesDailyJuiceCommon.headline]: true,
        [styles.headline]: true,
    });

    return (
        <Text className={headlineClassName}>
            {title}
        </Text>
    );
};

JuiceStoryTitle.defaultProps = {
    title: "",
    highlight: null,
};

export default JuiceStoryTitle;
