export default (apiURL, methods) => {
    return {
        getSchools(params = {}) {
            // NOTE: action=district_admin_get_schools_by_session
            const url = `${apiURL}/district-admin/schools`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        getDepartmentsBySchool(params = {}) {
            // NOTE: action=district_admin_get_departments_by_school_id
            const url = `${apiURL}/district-admin/schools/${params.schoolId}/departments`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        getClassesByDepartment(params = {}) {
            const { schoolId, departmentId } = params;

            // NOTE: action=district_admin_get_classes_by_department_id
            const url = `${apiURL}/district-admin/schools/${schoolId}/departments/${departmentId}/classes`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        setClassPrimaryTeacher(params = {}) {
            // NOTE: action=district_admin_change_class_primary_teacher
            const url = `${apiURL}/district-admin/teachers/set-primary`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                teacher_id: params.teacherId,
            });
        },

        getClassPerformanceByClassId(params = {}) {
            // NOTE: action=district_admin_get_class_performance_by_id
            const url = `${apiURL}/district-admin/classes/${params.classId}/performance`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
                categories: params.categories,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassStoriesCategoriesByClassId(params = {}) {
            // NOTE: action=district_admin_get_class_stories_categories_by_id
            const url = `${apiURL}/district-admin/classes/${params.classId}/stories`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassStandardsStatsByClassId(params = {}) {
            // NOTE: action=district_admin_get_class_stats_by_standards
            const url = `${apiURL}/district-admin/classes/${params.classId}/stats`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        /* ------- */

        editDepartment(params = {}) {
            const { schoolId, departmentId } = params;

            // NOTE: action=district_admin_department_edit
            const url = `${apiURL}/district-admin/schools/${schoolId}/departments/${departmentId}/edit`;

            return methods.post(url, {
                session_id: params.session,
                department_id: params.departmentId,
                department_name: params.departmentName,
            });
        },

        addDepartment(params = {}) {
            // NOTE: action=district_admin_department_add
            const url = `${apiURL}/district-admin/schools/${params.schoolId}/departments/add`;

            return methods.post(url, {
                session_id: params.session,
                school_id: params.schoolId,
                department_name: params.departmentName,
            });
        },

        deleteDepartment(params = {}) {
            const { schoolId, departmentId } = params;

            // NOTE: action=district_admin_department_delete
            const url = `${apiURL}/district-admin/schools/${schoolId}/departments/${departmentId}/delete`;

            return methods.post(url, {
                session_id: params.session,
                department_id: departmentId,
            });
        },

        /* ------- */

        addSchool(params = {}) {
            // NOTE: action=district_admin_school_add
            const url = `${apiURL}/district-admin/schools/add`;

            return methods.post(url, {
                session_id: params.session,
                school_name: params.schoolName,
            });
        },

        editSchool(params = {}) {
            // NOTE: action=district_admin_school_edit
            const url = `${apiURL}/district-admin/schools/${params.schoolId}/edit`;

            return methods.post(url, {
                session_id: params.session,
                school_id: params.schoolId,
                school_name: params.schoolName,
            });
        },

        deleteSchool(params = {}) {
            // NOTE: action=district_admin_school_delete
            const url = `${apiURL}/district-admin/schools/${params.schoolId}/delete`;

            return methods.post(url, {
                session_id: params.session,
                school_id: params.schoolId,
            });
        },

        /* ------- */

        addClass(params = {}) {
            // NOTE: action=district_admin_class_add
            const url = `${apiURL}/district-admin/classes/add`;

            return methods.post(url, {
                session_id: params.session,
                school_id: params.schoolId,
                department_id: params.departmentId,
                class_name: params.className,
                grades: params.grades,
                end_date: params.endDate,
            });
        },

        editClass(params = {}) {
            // NOTE: action=district_admin_class_edit
            const url = `${apiURL}/district-admin/classes/${params.classId}/edit`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                class_name: params.className,
                grades: params.grade,
                end_date: params.endDate,
            });
        },

        deleteClass(params = {}) {
            // NOTE: action=district_admin_class_delete
            const url = `${apiURL}/district-admin/classes/${params.classId}/delete`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
            });
        },
    };
};
