import React, { useCallback, useEffect, useState } from "react";

import classNames from "@/base/lib/class-names.js";

import TextLine from "@/base/components/text-line/index.js";
import PlayerLoader from "@/base/components/player-loader/index.js";
import PlayerAudioSpeed from "@/base/components/player-audio-speed/index.js";
import PlayerAudioPlayback from "@/base/components/player-audio-playback/index.js";

import IconClose from "@/base/icons/close/index.js";
import IconFlashlight from "@/base/icons/flashlight/index.js";
import IconPlayerPause from "@/base/icons/player-pause/index.js";
import IconPlayerPlay from "@/base/icons/player-play/index.js";
import IconPlayerReplay from "@/base/icons/player-replay/index.js";
import IconSpeedometer from "@/base/icons/speedometer/index.js";

import Button from "./button.js";
import styles from "./styles.module.css";


const RATES = [
    { value: 0.5, text: "0.5x" },
    { value: 0.75, text: "0.75x" },
    { value: 1, text: "1.0x" },
    { value: 1.5, text: "1.5x" },
    { value: 2, text: "2.0x" },
];

const PlayerAudioV2 = (props) => {
    const [audioState, setAudioState] = useState({
        selectedRate: props.audio.playbackRate || 1,
        isPopupRateVisible: false,
    });

    /* --- */

    const getRateLabel = useCallback(() => {
        for (let i = 0; i < RATES.length; i += 1) {
            const rate = RATES[i];

            if (rate.value === audioState.selectedRate) {
                return rate.text;
            }
        }

        return "1.0x";
    }, [audioState.selectedRate]);

    /* --- */

    const onCloseRatePopup = () => {
        setAudioState((prev) => ({
            ...prev,
            isPopupRateVisible: false,
        }));
    };

    const onToggleRatePopup = () => {
        setAudioState((prev) => ({
            ...prev,
            isPopupRateVisible: !prev.isPopupRateVisible,
        }));
    };

    const setRate = (rate) => {
        setAudioState((prev) => ({
            ...prev,
            selectedRate: rate,
        }));

        props.onChangeRate(rate);
    };

    /* --- */

    useEffect(() => {
        props.onFirstPlay();

        return () => {
            props.onClose();
        };
    }, []);

    useEffect(() => {
        if (!audioState.isPopupRateVisible) {
            return;
        }

        const autoClose = globalThis.setTimeout(() => {
            onCloseRatePopup();
        }, 1500);

        // eslint-disable-next-line consistent-return
        return () => {
            globalThis.clearTimeout(autoClose);
        };
    }, [audioState.selectedRate]);

    useEffect(() => {
        if (!audioState.isPopupRateVisible) {
            return;
        }

        const autoCloseByInactivity = globalThis.setTimeout(() => {
            onCloseRatePopup();
        }, 4000);

        // eslint-disable-next-line consistent-return
        return () => {
            globalThis.clearTimeout(autoCloseByInactivity);
        };
    }, [
        audioState.isPopupRateVisible,
        audioState.selectedRate,
    ]);

    /* --- */

    const renderBtnRetryPlay = () => {
        const { audio } = props;

        if (audio.isLoading
            || audio.isPlaying) {
            return null;
        }

        return (
            <Button
                icon={<IconPlayerReplay title="Replay" isWhite />}
                className={styles.controlBtnPlay}
                onClick={props.onRetryPlay}
                isMobile={props.isMobile}
                isBig
            />
        );
    };

    const renderBtnPlay = () => {
        const { audio } = props;

        if (audio.isLoading) {
            return (
                <div className={styles.playerLoader}>
                    <PlayerLoader />
                </div>
            );
        }

        if (audio.isPlaying) {
            return (
                <Button
                    icon={<IconPlayerPause title="Pause" isWhite />}
                    className={styles.controlBtnPause}
                    onClick={props.onPause}
                    isMobile={props.isMobile}
                    isBig
                />
            );
        }

        return (
            <Button
                icon={<IconPlayerPlay title="Play" isWhite />}
                className={styles.controlBtnPlay}
                onClick={props.onPlay}
                isMobile={props.isMobile}
                isBig
            />
        );
    };

    const renderBtnClose = () => {
        return (
            <Button
                icon={<IconClose title="Close" isRed />}
                onClick={props.onClose}
                isMobile={props.isMobile}
                withBorder={!props.isMobile}
            />
        );
    };

    const renderImage = () => {
        if (!props.image || props.isMobile) {
            return null;
        }

        const imgStyle = {
            backgroundImage: `url(${props.image})`,
        };

        return (
            <div
                className={styles.coverImage}
                style={imgStyle}
            />
        );
    };

    const renderHeader = () => {
        let text = "";

        if (props?.audio?.isLoading) {
            text = "Loading";
        } else if (props.error) {
            text = props.error;
        } else {
            text = "Now Playing";
        }

        return (
            <TextLine className={styles.playStatus}>
                {text}
            </TextLine>
        );
    };

    const renderInfo = () => {
        const infoClassName = classNames({
            [styles.info]: true,
            [styles.infoWithImage]: props.image && !props.isMobile,
        });

        return (
            <div className={infoClassName}>
                {renderImage()}
                <div className={styles.infoLabels}>
                    {renderHeader()}
                    <TextLine className={styles.title}>
                        {props.title || "Unknown title"}
                    </TextLine>
                </div>
            </div>
        );
    };

    const renderBtnHighlight = () => {
        const { highlight, audio } = props;

        if (!highlight || audio.isLoading) {
            return null;
        }

        if (highlight.isActive && !highlight.isLoaded) {
            return (
                <div className={styles.highlightLoader}>
                    <PlayerLoader />
                </div>
            );
        }

        return (
            <Button
                icon={<IconFlashlight title="highlight" isOrange />}
                onClick={highlight.onClick}
                isPressed={highlight.isActive}
                isMobile={props.isMobile}
                withBorder={!props.isMobile}
                isOrange
            />
        );
    };

    const renderBtnRate = () => {
        if (props.audio.isLoading) {
            return null;
        }

        const label = getRateLabel();

        return (
            <Button
                icon={<IconSpeedometer title="rate" isGreen />}
                onClick={onToggleRatePopup}
                label={label}
                isPressed={audioState.isPopupRateVisible}
                isMobile={props.isMobile}
                withBorder={!props.isMobile}
                isGreen
            />
        );
    };

    const renderBtnRewind = () => {
        if (props.audio.isLoading) {
            return null;
        }

        return (
            <Button
                icon={<IconPlayerReplay title="rewind" isSky />}
                onClick={props.onRewind}
                isMobile={props.isMobile}
                withBorder={!props.isMobile}
            />
        );
    };

    const renderBtnForward = () => {
        if (props.audio.isLoading) {
            return null;
        }

        return (
            <Button
                icon={<IconPlayerReplay title="forward" isSky />}
                onClick={props.onForward}
                isMobile={props.isMobile}
                isIconReversed
            />
        );
    };

    const renderControls = () => {
        if (props.error) {
            return (
                <div className={styles.controls}>
                    {renderBtnRetryPlay()}
                    {renderBtnClose()}
                </div>
            );
        }

        return (
            <div className={styles.controls}>
                {renderBtnRewind()}
                {renderBtnPlay()}
                {renderBtnForward()}
                {renderBtnRate()}
                {renderBtnHighlight()}
                {renderBtnClose()}
            </div>
        );
    };

    const renderPopupRate = () => {
        if (!audioState.isPopupRateVisible) {
            return null;
        }

        return (
            <PlayerAudioSpeed
                rate={audioState.selectedRate}
                rates={RATES}
                onChange={setRate}
            />
        );
    };

    const renderPlaybackProgress = () => {
        return (
            <PlayerAudioPlayback
                currentTime={props.playback?.currentTime || 0}
                duration={props.playback?.duration || 0}
            />
        );
    };

    const playerClassName = classNames({
        [styles.playerAudio]: true,
        [styles.playerAudioRounded]: props.roundedPlayer,
        [styles.playerAudioWithBorder]: props.isBorderVisible,
    });

    return (
        <div className={styles.container}>
            {renderPopupRate()}

            <div
                data-comment="player-audio"
                className={playerClassName}
            >
                {renderPlaybackProgress()}
                <div className={styles.playerAudioContent}>
                    {renderInfo()}
                    {renderControls()}
                </div>
            </div>
        </div>
    );
};

PlayerAudioV2.defaultProps = {
    image: "",
    title: "",
    category: "",
    error: "",
    audio: {},
    playback: {},
    highlight: null,
    onFirstPlay: () => { },
    onRetryPlay: () => { },
    onPlay: () => { },
    onPause: () => { },
    onRewind: () => { },
    onForward: () => { },
    onChangeRate: () => { },
    onClose: () => { },
    roundedPlayer: false,
    isBorderVisible: true,
    isMobile: false,
};

export default PlayerAudioV2;
