import React from "react";

import classNames from "@/base/lib/class-names.js";

import styles from "./styles.module.css";


const Tooltip = (props) => {
    const tooltipClassName = classNames({
        [styles.tooltip]: true,
        [styles.tooltipDark]: props.isDark,
    });

    return (
        <div className={tooltipClassName}>
            {props.children}
        </div>
    );
};

Tooltip.defaultProps = {
    children: null,
    isDark: false,
};

export default Tooltip;
