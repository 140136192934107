import React, { useEffect, useRef, useState } from "react";

import timer from "@/base/lib/timer.js";
import xmlHttpRequest from "@/base/lib/xml-http-request.js";

import VideoDefault from "@/base/components/video-default/index.js";
import VideoCustom from "@/base/components/video-custom/index.js";


const Video = (props) => {
    const videoTimer = useRef(null);

    const [captionState, setCaptionState] = useState(() => ({
        blobSrc: "",
        shouldLoad: false,
    }));

    /* --- */

    const getVideoPlayedTime = () => {
        return videoTimer?.current?.getDiffSeconds?.() || 0;
    };

    /* --- */

    const onPlayStart = () => {
        videoTimer.current = timer.startTimer();
        props.onPlayStart(props.src, props.videoId);

        if (captionState.shouldLoad || !props.captionSrc) {
            return;
        }

        setCaptionState((prev) => ({
            ...prev,
            shouldLoad: true,
        }));
    };

    const onPause = () => {
        const timePlayed = getVideoPlayedTime();

        props.onPause(props.src, props.videoId, timePlayed);
        videoTimer.current = null;
    };

    const onPlayEnd = () => {
        const timePlayed = getVideoPlayedTime();

        props.onPlayEnd(props.src, props.videoId, timePlayed);
        videoTimer.current = null;
    };

    /* --- */

    const onCaptionLoaded = ({ responseText }) => {
        const blob = new Blob([responseText], {
            type: "text/vtt",
        });

        setCaptionState((prev) => ({
            ...prev,
            blobSrc: URL.createObjectURL(blob),
        }));
    };

    /* --- */

    useEffect(() => {
        if (!captionState.shouldLoad) {
            return;
        }

        xmlHttpRequest.sendGET({
            src: props.captionSrc,
            onSuccess: onCaptionLoaded,
        });
    }, [captionState.shouldLoad]);

    /* --- */

    if (props.isDefault) {
        return (
            <VideoDefault
                videoId={props.videoId}
                src={props.src}
                type={props.type}
                poster={props.poster}
                videoHeight={props.videoHeight}
                canPlay={props.canPlay}
                withPosterCover={props.withPosterCover}
                captionSrc={captionState.blobSrc}
                onPlayStart={onPlayStart}
                onPlayEnd={onPlayEnd}
                onPause={onPause}
            />
        );
    }

    return (
        <VideoCustom
            videoId={props.videoId}
            src={props.src}
            type={props.type}
            poster={props.poster}
            videoHeight={props.videoHeight}
            canPlay={props.canPlay}
            withPosterCover={props.withPosterCover}
            captionSrc={captionState.blobSrc}
            onPlayStart={onPlayStart}
            onPlayEnd={onPlayEnd}
            onPause={onPause}
        />
    );
};

Video.defaultProps = {
    videoId: -1,
    src: "",
    type: "",
    poster: "",
    videoHeight: "auto",
    captionSrc: null,
    onPlayStart: () => { },
    onPlayEnd: () => { },
    onPause: () => { },
    canPlay: true,
    withPosterCover: true,
    isDefault: true,
};

export default Video;
