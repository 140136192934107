import React from "react";

import IconFivesy from "@/base/icons/fivesy/index.js";
import IconFivesyHC from "@/base/icons/fivesy-hc/index.js";

import ButtonBigRounded from "@/base/components/button-big-rounded/index.js";

import styles from "./styles.module.css";


const FivesyBanner = (props) => {
    const renderLogo = () => {
        if (props.isHighContrast) {
            return (
                <IconFivesyHC isBlack />
            );
        }

        return (
            <IconFivesy isBlack />
        );
    };

    return (
        <div className={styles.banner}>
            <div className={styles.logo}>
                {renderLogo()}
            </div>

            <div className={styles.info}>
                <div className={styles.infoTitle}>
                    Fivesy
                </div>
                <div className={styles.infoDescription}>
                    Get 6 chances to guess a 5-letter word.
                </div>
            </div>

            <div className={styles.button}>
                <ButtonBigRounded onClick={props.onClick}>
                    Play
                </ButtonBigRounded>
            </div>
        </div>
    );
};

FivesyBanner.defaultProps = {
    onClick: () => { },
    isHighContrast: false,
};

export default FivesyBanner;
