import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconMenuUnfurl = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M18 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H18C18.55 18 19 17.55 19 17V11C19 10.45 18.55 10 18 10ZM18 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H18C18.55 8 19 7.55 19 7V1C19 0.45 18.55 0 18 0Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconMenuUnfurl;
