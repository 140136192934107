import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import classNames from "@/base/lib/class-names.js";

import User from "@/base/project/user.js";
import Achievements from "@/base/project/achievements.js";

import vocabularyActions from "@/base/actions/vocabulary.js";
import actionsStudent from "@/base/actions/student.js";
import actions from "@/base/store/actions.js";

import AudioManagerContext from "@/base/context/audio-manager/index.js";

import RequestLoader from "@/base/components/request-loader/index.js";
import SelectCustom from "@/base/components/select-custom/index.js";
import SectionSponsor from "@/base/components/section-sponsor/index.js";
import LayoutContent from "@/base/components/layout-content/index.js";

import DailyJuiceExplore from "@/base/business/daily-juice-explore/index.js";
import DailyJuicePolls from "@/base/business/daily-juice-polls/index.js";
import DailyJuiceQuizResults from "@/base/business/daily-juice-quiz-results/index.js";
import DailyJuiceAchievements from "@/base/business/daily-juice-achievements/index.js";
import DailyJuiceCarousel from "@/base/business/carousel-daily-juice/index.js";
import SectionAnnouncement from "@/base/business/section-announcement/index.js";

import PopupWord from "@/app/containers/popup-word/index.js";
import PopupDailyJuiceExplore, {
    usePopupDailyJuiceExplore,
} from "@/app/containers/popup-daily-juice-explore/index.js";
import UserPopupPolls from "@/app/containers/user-popup-polls/index.js";
import Tutorial from "@/app/containers/tutorial/index.js";
import UserFooter from "@/app/containers/user-footer/index.js";

import app from "@/app/app.js";
import events from "@/app/events.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    student: state.student,
    guardian: state.guardian,

    dimensions: state.device.dimensions,
    theme: state.app.theme.theme,

    wordPopup: state.vocabulary.popup,

    extraJuices: state.extraJuices,
    polls: state.polls,
    juicesVideos: state.juicesVideos,
    studentAchievements: state.studentAchievements.achievements,

    playerState: state.player.playerState,

    quizResults: state.student.quizResults,

    sponsors: state.info.sponsors,
    isSponsorsLoaded: state.info.isSponsorsLoaded,
});

const StudentIndexRegular = () => {
    const audioManager = useContext(AudioManagerContext);

    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);

    const [juiceCarousel, setJuiceCarousel] = useState({
        isLoaded: false,
        juice: {},
    });

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const navigate = useNavigate();

    const isMobile400 = store.dimensions?.width <= 400;
    const hasGuardianRole = User.hasRoleGuardian(store.user);
    const popupDailyJuiceExplore = usePopupDailyJuiceExplore();

    /* --- */

    const loadFirstClosedPolls = useCallback(() => {
        app.actions.common.polls.loadFirstClosedPolls({
            onlyFirst: true,
        });
    }, []);

    const loadFirstExtraJuices = useCallback(() => {
        app.actions.student.extraJuices.loadFirstExtraJuices({
            onlyFirst: true,
        });
    }, []);

    const loadExtraJuices = useCallback(() => {
        app.actions.student.extraJuices.loadMoreExtraJuices();
    }, []);

    const loadFirstVideos = useCallback(() => {
        app.actions.common.juicesVideos.loadFirstVideos({
            onlyFirst: true,
        });
    }, []);

    const loadVideos = useCallback(() => {
        app.actions.common.juicesVideos.loadMoreVideos();
    }, []);

    const loadGuardianStudents = (req) => {
        app.services.api.students.getStudentsByGuardian(req).then((res) => {
            const students = res.students || [];

            let selectedStudentId = store.guardian.selectedStudent;

            if (!selectedStudentId) {
                selectedStudentId = students[0] ? students[0].id || null : null;
            }

            dispatch(actions.guardian.setSelectedStudent({
                selectedStudent: selectedStudentId,
            }));

            dispatch(actions.guardian.setStudents({
                students,
            }));
        });
    };

    const loadStudentClassInfo = (req) => {
        app.services.api.students.getStudentClassInfo(req).then((res) => {
            if (res.ok) {
                const classData = res.data || {};

                dispatch(actions.student.setStudentSponsor({
                    sponsor: {
                        imageId: classData.sponsorImageId || "",
                        imageUrl: classData.sponsorImageUrl || "",

                        sponsoredBy: classData.sponsoredBy || "",
                        sponsorLink: classData.sponsorLink || "",

                        content: classData.sponsorContent || "",
                    },
                }));

                dispatch(actions.student.setStudentAnnouncement({
                    announcement: {
                        title: classData.announcementTitle || "Class announcement",
                        content: classData.announcementContent || "",
                    },
                }));
            }
        });
    };

    const onChangeGuardianStudent = (value) => {
        events.guardian.homeSelectStudent({
            session: store.session,
            studentId: value,
        });

        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value,
        }));

        setJuiceCarousel((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        setJuiceCarousel((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value,
        }));
    };

    const onOpenUserPollsPopup = () => {
        app.actions.common.polls.preloadFirstClosedPolls();
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    const onExploreVideoClick = (video) => {
        const { videoID, newsTitle } = video;

        popupDailyJuiceExplore.openVideos();
        popupDailyJuiceExplore.selectVideo(videoID, newsTitle);

        app.actions.common.juicesVideos.loadVideoById({
            id: videoID,
        });
    };

    const onExploreExtraJuiceClick = (extraJuiceId) => {
        popupDailyJuiceExplore.openExtraJuices();
        popupDailyJuiceExplore.selectExtraJuice(extraJuiceId);
    };

    /* --- */

    const loadQuizResults = useCallback(() => {
        app.actions.student.quizzes.loadQuizWeeksResults({
            amount: 1,
        });
    }, []);

    const loadQuizResultsByRange = useCallback((values) => {
        if (values.type === "weeks") {
            app.actions.student.quizzes.loadQuizWeeksResults({
                amount: values.amount,
            });
        } else if (values.type === "months") {
            app.actions.student.quizzes.loadQuizMonthsResults({
                amount: values.amount,
            });
        }
    }, []);

    /* --- */

    useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
        if (!store.polls.isClosedPollsLoaded) {
            loadFirstClosedPolls();
        }

        if (store.studentAchievements.isLoaded) {
            dispatch(actionsStudent.reloadStudentAchievements({
                api: app.services.api,
                actions: app.services.actions,
            }));
        }

        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
            dispatch(actions.polls.clearClosedPolls());
        };
    }, []);

    useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
        const req = {
            session: store.session,
            studentId: hasGuardianRole ? store.guardian.selectedStudent : null,
        };

        if (!store.guardian.isStudentsLoaded) {
            if (hasGuardianRole) {
                loadGuardianStudents(req);
            } else {
                dispatch(actions.guardian.setStudents({
                    students: [],
                }));
            }
        } else {
            loadStudentClassInfo(req);

            app.services.api.dailyJuices.getLastJuice(req).then((res) => {
                setJuiceCarousel({
                    isLoaded: true,
                    juice: res.ok ? res.juice : null,
                });
            });

            if (store.extraJuices.page === 0 && !store.extraJuices.isExtraJuicesLoaded) {
                loadFirstExtraJuices();
            }

            if (store.juicesVideos.page === 0 && !store.juicesVideos.isVideosLoaded) {
                loadFirstVideos();
            }

            loadQuizResults();
        }
    }, [store.guardian]);

    /* --- */

    const renderTutorial = () => {
        if (hasGuardianRole) {
            return (
                <Tutorial name="guardian-index" />
            );
        }

        return (
            <Tutorial name="student-index" />
        );
    };

    const renderGuardianStudentsList = () => {
        if (!hasGuardianRole) {
            return null;
        }

        const studentsValues = [];

        store.guardian.students.forEach((student) => {
            studentsValues.push({ value: student.ID, label: student.fullName });
        });

        return (
            <div className={styles.guardianStudentsList}>
                <div className={styles.guardianStudentsListSelector}>
                    <SelectCustom
                        selected={store.guardian.selectedStudent}
                        options={studentsValues}
                        dataComment="guardian-students-list"
                        onSelect={onChangeGuardianStudent}
                        withBorder={false}
                    />
                </div>
            </div>
        );
    };

    const renderJuiceCard = () => {
        return (
            <DailyJuiceCarousel
                juiceId={juiceCarousel.juice?.id || null}
                juiceDate={juiceCarousel.juice?.juiceDate || ""}
                stories={juiceCarousel.juice?.stories?.juice || []}
                isLoading={!juiceCarousel.isLoaded}
            />
        );
    };

    const renderSponsor = () => {
        if (!store.isSponsorsLoaded) {
            return null;
        }

        return (
            <SectionSponsor
                imgSrc={store.sponsors.djHomeImage || ""}
                link={store.sponsors.djHomeUrl || ""}
            />
        );
    };

    const renderAnnouncement = () => {
        if (!store.student.isAnnouncementLoaded) {
            return null;
        }

        const cnt = store.student.announcement.content || "";
        const title = store.student.announcement.title || "";

        if (!cnt) {
            return null;
        }

        return (
            <SectionAnnouncement
                content={cnt}
                title={title}
            />
        );
    };

    const renderAchievements = () => {
        const achievementsData = store.studentAchievements?.data || [];
        const achievements = Achievements.getAchievementValues(achievementsData);

        let isLoading = false;

        if (store.studentAchievements?.isLoading || !store.studentAchievements?.isLoaded) {
            isLoading = true;
        }

        return (
            <DailyJuiceAchievements
                storiesRead={achievements.storiesRead}
                questionsAttempted={achievements.questionsAttempted}
                theme={store.theme}
                error={store.studentAchievements?.error}
                isLoading={isLoading}
            />
        );
    };

    const renderQuizResults = () => {
        return (
            <DailyJuiceQuizResults
                data={store.quizResults}
                onChangeTab={loadQuizResultsByRange}
                onGoToDailyJuices={() => {
                    navigate("/daily-juices");
                }}
                onGoToAssignments={() => {
                    navigate("/assignments");
                }}
                isMobile={isMobile400}
            />
        );
    };

    const renderUserPollsPopup = () => {
        if (!isVisibleUserPollsPopup) {
            return null;
        }

        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderPopupExplore = () => {
        if (!popupDailyJuiceExplore.state.isVisible) {
            return null;
        }

        return (
            <PopupDailyJuiceExplore
                defaultTab={popupDailyJuiceExplore.state.defaultTab}
                selectedExtraJuice={popupDailyJuiceExplore.state.selectedExtraJuice}
                selectedVideo={popupDailyJuiceExplore.state.selectedVideo}
                onSelectVideo={popupDailyJuiceExplore.selectVideo}
                onBackToList={popupDailyJuiceExplore.resetAllSelected}
                onSelectExtraJuice={popupDailyJuiceExplore.selectExtraJuice}
                onLoadMoreVideos={loadVideos}
                onLoadMoreExtraJuices={loadExtraJuices}
                onExtraJuiceShowPlayer={popupDailyJuiceExplore.showExtraJuicePlayer}
                onPlayerClose={popupDailyJuiceExplore.hideExtraJuicePlayer}
                onClose={popupDailyJuiceExplore.close}
            />
        );
    };

    const renderExplore = () => {
        return (
            <DailyJuiceExplore
                extraJuices={store.extraJuices.extraJuices}
                videos={store.juicesVideos.videos}
                isExtraJuicesLoaded={store.extraJuices.isExtraJuicesLoaded}
                isVideosLoaded={store.juicesVideos.isVideosLoaded}
                onViewMore={popupDailyJuiceExplore.open}
                onVideoClick={onExploreVideoClick}
                onExtraJuiceClick={onExploreExtraJuiceClick}
            />
        );
    };

    const renderLatestPoll = () => {
        const poll = store.polls?.closedPolls?.[0] || null;

        return (
            <DailyJuicePolls
                poll={poll}
                isLoading={store.polls.isClosedPollsLoading && !store.polls.isClosedPollsLoaded}
                onViewMore={onOpenUserPollsPopup}
            />
        );
    };

    const renderWordPopup = () => {
        return (
            <PopupWord
                hideScrollbar={false}
            />
        );
    };

    if (User.isTypeTrial(store.user)) {
        navigate("/daily-juices");
        return null;
    }

    if (!juiceCarousel.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    const indexClassName = classNames({
        [styles.index]: true,
        [styles.indexWithGuardian]: hasGuardianRole,
    });

    return (
        <>
            {renderTutorial()}
            {renderPopupExplore()}
            {renderUserPollsPopup()}
            {renderWordPopup()}

            <LayoutContent>
                <div className={indexClassName}>
                    {renderGuardianStudentsList()}

                    <div className={styles.juiceCarousel}>
                        {renderJuiceCard()}
                    </div>
                    <div className={styles.bottomWidgets}>
                        {renderExplore()}
                        {renderLatestPoll()}
                    </div>
                    <div className={styles.asideWidgets}>
                        {renderSponsor()}
                        {renderAnnouncement()}
                        {renderAchievements()}
                        {renderQuizResults()}
                    </div>
                </div>
            </LayoutContent>

            <UserFooter />
        </>
    );
};


export default StudentIndexRegular;
