import storages from "@/base/lib/storage/storages.js";

/* --- */

const keys = {
    session: "session",
    adminSearchDistrict: "adminSearchDistrict",
};

/* --- */

export const saveSession = (sessionId) => {
    storages.sessionStorage.setItem(keys.session, sessionId);
};

export const loadSession = () => {
    return storages.sessionStorage.getItem(keys.session) || "";
};

/* --- */

export const saveSearchDistrict = (search) => {
    storages.sessionStorage.setItem(keys.adminSearchDistrict, search);
};

export const loadSearchDistrict = () => {
    return storages.sessionStorage.getItem(keys.adminSearchDistrict) || "";
};
