// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mkC88fjoFy6w4aGQjbGz {\n    display: block;\n}\n\n.QsNIub5NCJDH7iTXY6Uo {\n    width: 2rem;\n}\n\n.wRNLqKVRVs3BpH9Khvkh {\n    padding: 1rem 2rem 0 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-story/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".topTitleHeadphone {\n    display: block;\n}\n\n.buttonListen {\n    width: 2rem;\n}\n\n.content {\n    padding: 1rem 2rem 0 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topTitleHeadphone": "mkC88fjoFy6w4aGQjbGz",
	"buttonListen": "QsNIub5NCJDH7iTXY6Uo",
	"content": "wRNLqKVRVs3BpH9Khvkh"
};
export default ___CSS_LOADER_EXPORT___;
