import React from "react";

import ButtonFlat from "@/base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "@/base/components/popup-confirm/index.js";


const PopupConfirmSignOut = (props) => {
    return (
        <PopupConfirm onClose={props.onClose}>
            <PopupConfirmContent>
                Stop configuration and sign out from your account.
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onSignOut} danger>
                    Sign Out
                </ButtonFlat>
                <ButtonFlat onClick={props.onClose}>
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmSignOut.defaultProps = {
    onSignOut: () => { },
    onClose: () => { },
};

export default PopupConfirmSignOut;
