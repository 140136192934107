export const OPEN_DISTRICT_ADMIN_POPUP_EDIT_SCHOOL = "OPEN_DISTRICT_ADMIN_POPUP_EDIT_SCHOOL";
export const SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_LOADING = "SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS = "SET_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT_SUCCESS";
export const CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT = "CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_EDIT";

export const OPEN_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE = "OPEN_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE";
export const SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_LOADING = "SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_ERROR = "SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_ERROR";
export const SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS = "SET_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE_SUCCESS";
export const CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE = "CLEAR_DISTRICT_ADMIN_POPUP_SCHOOL_DELETE";

export const OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT = "OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT";
export const SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING = "SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS = "SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT_SUCCESS";
export const CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT = "CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_EDIT";

export const OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE = "OPEN_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE";
export const SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING = "SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_ERROR = "SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_ERROR";
export const SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS = "SET_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE_SUCCESS";
export const CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE = "CLEAR_DISTRICT_ADMIN_POPUP_DEPARTMENT_DELETE";

export const OPEN_DISTRICT_ADMIN_POPUP_CLASS_EDIT = "OPEN_DISTRICT_ADMIN_POPUP_CLASS_EDIT";
export const SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_LOADING = "SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_SUCCESS = "SET_DISTRICT_ADMIN_POPUP_CLASS_EDIT_SUCCESS";
export const CLEAR_DISTRICT_ADMIN_POPUP_CLASS_EDIT = "CLEAR_DISTRICT_ADMIN_POPUP_CLASS_EDIT";

export const OPEN_DISTRICT_ADMIN_POPUP_CLASS_DELETE = "OPEN_DISTRICT_ADMIN_POPUP_CLASS_DELETE";
export const SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_LOADING = "SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_ERROR = "SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_ERROR";
export const SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_SUCCESS = "SET_DISTRICT_ADMIN_POPUP_CLASS_DELETE_SUCCESS";
export const CLEAR_DISTRICT_ADMIN_POPUP_CLASS_DELETE = "CLEAR_DISTRICT_ADMIN_POPUP_CLASS_DELETE";

export const OPEN_DISTRICT_ADMIN_POPUP_ENTITIES_ADD = "OPEN_DISTRICT_ADMIN_POPUP_ENTITIES_ADD";
export const SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_LOADING = "SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_LOADING";
export const SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED = "SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_DEPARTMENT_ADDED";
export const SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED = "SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SCHOOL_ADDED";
export const SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED = "SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_CLASS_ADDED";
export const SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL = "SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_SCHOOL";
export const SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT = "SET_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_SELECTED_DEPARTMENT";
export const CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_STATUSES = "CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD_STATUSES";
export const CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD = "CLEAR_DISTRICT_ADMIN_POPUP_ENTITIES_ADD";
