import { useState } from "react";


const getState = () => ({
    defaultTab: "",
    selectedExtraJuice: null,
    selectedVideo: null,
    isVisible: false,
});

const usePopupDailyJuiceExplore = () => {
    const [state, setState] = useState(() => getState());

    /* --- */

    const open = (defaultTab) => {
        setState((prev) => ({
            ...prev,
            defaultTab,
            isVisible: true,
        }));
    };

    const openVideos = () => {
        open("videos");
    };

    const openExtraJuices = () => {
        open("extraJuices");
    };

    /* --- */

    const showExtraJuicePlayer = () => {
        setState((prev) => ({
            ...prev,
            selectedExtraJuice: {
                ...prev.selectedExtraJuice,
                isVisiblePlayer: true,
            },
        }));
    };

    const hideExtraJuicePlayer = () => {
        setState((prev) => ({
            ...prev,
            selectedExtraJuice: {
                ...prev.selectedExtraJuice,
                isVisiblePlayer: false,
            },
        }));
    };

    const selectExtraJuice = (id) => {
        setState((prev) => ({
            ...prev,
            selectedExtraJuice: {
                id,
                isVisiblePlayer: false,
            },
        }));
    };

    /* --- */

    const selectVideo = (id, title) => {
        setState((prev) => ({
            ...prev,
            selectedVideo: {
                id,
                title,
            },
        }));
    };

    /* --- */

    const resetAllSelected = () => {
        setState((prev) => ({
            ...prev,
            selectedVideo: null,
            selectedExtraJuice: null,
        }));
    };

    const close = () => {
        setState(getState());
    };

    /* --- */

    return {
        state,
        open,
        openVideos,
        openExtraJuices,

        selectExtraJuice,
        showExtraJuicePlayer,
        hideExtraJuicePlayer,

        selectVideo,

        resetAllSelected,
        close,
    };
};

export default usePopupDailyJuiceExplore;
