import { useState } from "react";

const INITIAL_VALUES = {
    x: -1,
    y: -1,
    size: 0,
};


const useButtonAnimation = () => {
    const [animate, setAnimate] = useState(INITIAL_VALUES);

    const addAnimate = (evt) => {
        setAnimate((prev) => ({
            ...prev,
            ...INITIAL_VALUES,
        }));

        const { offsetHeight, offsetWidth } = evt.target;
        const size = Math.max(offsetHeight, offsetWidth);

        const rect = evt.target.getBoundingClientRect();
        const x = evt.clientX - rect.left;
        const y = evt.clientY - rect.top;

        setAnimate((prev) => ({
            ...prev,
            x,
            y,
            size,
        }));

        window.setTimeout(() => {
            setAnimate((prev) => ({
                ...prev,
                ...INITIAL_VALUES,
            }));
        }, 500);
    };

    let aStyles = {};
    const isAnimate = animate.x !== -1 && animate.y !== -1;

    if (isAnimate) {
        aStyles = {
            top: animate.y - Math.round(animate.size / 2),
            left: animate.x - Math.round(animate.size / 2),
            width: `${animate.size}px`,
            height: `${animate.size}px`,
        };
    }


    return [
        isAnimate,
        addAnimate,
        aStyles,
    ];
};

export default useButtonAnimation;
