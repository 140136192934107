import { debugEvent } from "@/base/lib/debug.js";


export default (methods, ids) => {
    return {
        onSearch(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.onSearch,
            };

            debugEvent("Search", event);
            methods.sendEvent(event);
        },

        storyClick(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.searchStoryClick,
                objectId: params.storyId,
            };

            debugEvent("Click on search story", event);
            return methods.sendEvent(event);
        },

        filterClick(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.searchFilterClick,
            };

            debugEvent("Click on search filter", event);
            methods.sendEvent(event);
        },

        storyListen(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.searchStoryListen,
                objectId: params.juiceId,
                subObjectId: params.storyId,
            };

            debugEvent("search story listen", event);
            methods.sendEvent(event);
        },
    };
};
