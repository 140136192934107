import text from "@/base/text/index.js";


const loadClassScoreReport = (services, params = {}) => {
    const { api, actions, events } = services;
    const { dateFrom, dateTo, standardIds } = params;

    const classId = params.classId || -1;

    return async (dispatch, getState) => {
        if (!classId || classId === -1) {
            return;
        }

        const state = getState();
        const { session } = state.user;

        dispatch(actions.teacherStudentsScoreReport.setScoreReportLoading({
            standardIds,
            classId,
            dateFrom,
            dateTo,
        }));

        const res = await api.teacherClass.getScoreReport({
            session,
            dateFrom,
            dateTo,
            classId,
        });

        events.scoreReport.run({
            session,
            classId,
        });

        let students = [];
        let summary = null;
        let error = "";

        if (res.ok) {
            students = res.data?.students || [];
            summary = res.data?.summary || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacherStudentsScoreReport.setScoreReport({
            classId,
            standardIds,
            dateFrom,
            dateTo,
            students,
            summary,
            error,
        }));
    };
};

const reloadClassScoreReport = (services, params = {}) => {
    const { api, actions, events } = services;
    const { dateFrom, dateTo, standardIds } = params;

    const classId = params.classId || -1;

    return async (dispatch, getState) => {
        if (!classId || classId === -1) {
            return;
        }

        const state = getState();
        const { session } = state.user;

        const res = await api.teacherClass.getScoreReport({
            session,
            classId,
            dateFrom,
            dateTo,
        });

        events.scoreReport.run({
            session,
            classId,
        });

        let students = [];
        let summary = null;
        let error = "";

        if (res.ok) {
            students = res.data?.students || [];
            summary = res.data?.summary || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacherStudentsScoreReport.setScoreReport({
            classId,
            standardIds,
            dateFrom,
            dateTo,
            students,
            summary,
            error,
        }));
    };
};

const loadOrReloadClassScoreReport = (services, params = {}) => {
    const standardIds = params.standards || [];

    return async (dispatch, getState) => {
        const state = getState();

        const {
            classId,
            dateFrom,
            dateToOrLoadType,
        } = state.navigation.params;

        if (!classId || !dateFrom || !dateToOrLoadType) {
            return;
        }

        const range = `${dateFrom}-${dateToOrLoadType}`;
        const { scoreReportByClassId } = state.teacherStudentsScoreReport;

        const isLoaded = !!scoreReportByClassId?.[classId]?.[range]?.isLoaded;

        const actionParams = {
            dateFrom,
            dateTo: dateToOrLoadType,
            classId,
            standardIds,
        };

        if (isLoaded) {
            dispatch(reloadClassScoreReport(services, actionParams));
            return;
        }

        dispatch(loadClassScoreReport(services, actionParams));
    };
};


export default {
    loadClassScoreReport,
    reloadClassScoreReport,
    loadOrReloadClassScoreReport,
};
