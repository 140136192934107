import date from "@/base/lib/date.js";


const startTimer = () => {
    const dateStart = new Date();

    return {
        getDiffSeconds: () => {
            const dateEnd = new Date();
            const diffSeconds = date.getDiffSeconds(dateStart, dateEnd);
            return diffSeconds;
        },
    };
};


export default {
    startTimer,
};
