import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    selectedStudent: null,

    isStudentsLoaded: false,
    students: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_GUARDIAN_SELECTED_STUDENT: {
            return {
                ...state,
                selectedStudent: action.payload.selectedStudent || null,
            };
        }

        case types.SET_GUARDIAN_STUDENTS: {
            return {
                ...state,
                isStudentsLoaded: true,
                students: action.payload.students || [],
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
