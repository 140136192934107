import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    classInfoById: {},
    classes: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_STUDENT_CLASSES_CLASS_INFO_LOADING: {
            const { classId } = action.payload;

            return {
                ...state,
                classInfoById: {
                    ...state.classInfoById,
                    [classId]: {
                        data: null,
                        error: "",
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_STUDENT_CLASSES_CLASS_INFO: {
            const {
                classId,
                data,
                error,
            } = action.payload;

            return {
                ...state,
                classInfoById: {
                    ...state.classInfoById,
                    [classId]: {
                        data,
                        error,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case types.SET_STUDENT_CLASSES_LOADING: {
            return {
                ...state,
                classes: {
                    data: null,
                    error: "",
                    isLoading: true,
                    isLoaded: false,
                },
            };
        }

        case types.SET_STUDENT_CLASSES: {
            const { data, error } = action.payload;

            return {
                ...state,
                classes: {
                    data,
                    error,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
