import React, { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

import classNames from "@/base/lib/class-names.js";
import symbols from "@/base/lib/symbols.js";

import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const Tab = (props) => {
    const visibility = useContext(VisibilityContext);

    const { isSelected } = props;

    /* --- */

    const onClick = (evt) => {
        props.onClick(evt, visibility);
    };

    /* --- */

    const renderCount = () => {
        if (!props.count) {
            return null;
        }

        const count = props.count > 99
            ? "99+"
            : props.count;

        return (
            <div className={styles.count}>
                {count}
            </div>
        );
    };

    /* --- */

    const optionClassName = classNames({
        [styles.optionContainer]: true,
        [styles.optionContainerSelected]: isSelected,
        [styles.optionContainerSelectedOrange]: isSelected && props.isOrange,
    });

    const optionLabelClassName = classNames({
        [styles.optionLabel]: true,
        [styles.optionLabelSelected]: isSelected,
        [styles.optionLabelOrangeSelected]: isSelected && props.isOrange,
    });

    let countOrNotification = null;

    if (props.withNotification) {
        countOrNotification = (
            <div className={styles.notification} />
        );
    } else if (props.count) {
        countOrNotification = renderCount();
    }

    return (
        <DivButton
            key={props.value}
            className={optionClassName}
            onClick={onClick}
        >
            <div className={styles.option}>
                <div className={optionLabelClassName}>
                    {(props.label || "").trim() || symbols.nbsp}
                </div>
                {countOrNotification}
            </div>
        </DivButton>
    );
};

Tab.defaultProps = {
    value: "",
    label: "",
    count: 0,
    onClick: () => { },
    withNotification: false,
    isSelected: false,
    isOrange: false,
};

export default Tab;
