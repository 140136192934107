import * as types from "./types.js";


const initialState = {
    location: {},
    params: {},
    prevLocation: {},

    docTitle: "",
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_LOCATION: {
            return {
                ...state,
                location: action.payload.location,
                params: action.payload.params || {},
                prevLocation: {
                    ...state.location,
                },
            };
        }

        case types.SET_DOC_TITLE: {
            return {
                ...state,
                docTitle: action.payload.title || "",
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
