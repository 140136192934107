import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAnnouncement = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="21"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M16.5 6.99991V8.99991H20.5V6.99991H16.5ZM14.5 13.6099C15.46 14.3199 16.71 15.2599 17.7 15.9999C18.1 15.4699 18.5 14.9299 18.9 14.3999C17.91 13.6599 16.66 12.7199 15.7 11.9999C15.3 12.5399 14.9 13.0799 14.5 13.6099ZM18.9 1.59991C18.5 1.06991 18.1 0.529908 17.7 -9.15527e-05C16.71 0.739908 15.46 1.67991 14.5 2.39991C14.9 2.92991 15.3 3.46991 15.7 3.99991C16.66 3.27991 17.91 2.34991 18.9 1.59991ZM2.5 4.99991C1.4 4.99991 0.5 5.89991 0.5 6.99991V8.99991C0.5 10.0999 1.4 10.9999 2.5 10.9999H3.5V14.9999H5.5V10.9999H6.5L11.5 13.9999V1.99991L6.5 4.99991H2.5ZM14 7.99991C14 6.66991 13.42 5.46991 12.5 4.64991V11.3399C13.42 10.5299 14 9.32991 14 7.99991Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAnnouncement;
