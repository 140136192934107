import React from "react";

import IconWrapper from "@/base/icons/wrapper/index.js";


const IconAchievementQuestionsAttemptedHighContrast = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="94"
                    height="94"
                    viewBox="0 0 94 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <g clipPath="url(#clip0_2474_12158)">
                        <rect width="94" height="94" rx="47" fill="#FDF2F8" />
                        <path d="M47.0006 27.4168C36.2053 27.4168 27.4172 36.2048 27.4172 47.0001C27.4172 57.7955 36.2053 66.5835 47.0006 66.5835C57.7959 66.5835 66.5839 57.7955 66.5839 47.0001C66.5839 36.2048 57.7959 27.4168 47.0006 27.4168ZM49.4485 61.6876H44.5527V56.7918H49.4485V61.6876ZM49.4485 54.3439H44.5527C44.5527 46.3882 51.8964 47.0001 51.8964 42.1043C51.8964 39.4116 49.6933 37.2085 47.0006 37.2085C44.3079 37.2085 42.1047 39.4116 42.1047 42.1043H37.2089C37.2089 36.6944 41.5907 32.3126 47.0006 32.3126C52.4105 32.3126 56.7922 36.6944 56.7922 42.1043C56.7922 48.2241 49.4485 48.8361 49.4485 54.3439Z" fill="white" />
                        <g filter="url(#filter0_dd_2474_12158)">
                            <path d="M44.5515 56.7915H49.4474V61.6874H44.5515V56.7915ZM46.9994 22.5207C33.4869 22.5207 22.5203 33.4874 22.5203 46.9999C22.5203 60.5124 33.4869 71.479 46.9994 71.479C60.5119 71.479 71.4786 60.5124 71.4786 46.9999C71.4786 33.4874 60.5119 22.5207 46.9994 22.5207ZM46.9994 66.5832C36.2041 66.5832 27.4161 57.7952 27.4161 46.9999C27.4161 36.2045 36.2041 27.4165 46.9994 27.4165C57.7948 27.4165 66.5828 36.2045 66.5828 46.9999C66.5828 57.7952 57.7948 66.5832 46.9994 66.5832ZM46.9994 32.3124C41.5895 32.3124 37.2078 36.6941 37.2078 42.104H42.1036C42.1036 39.4113 44.3067 37.2082 46.9994 37.2082C49.6921 37.2082 51.8953 39.4113 51.8953 42.104C51.8953 46.9999 44.5515 46.3879 44.5515 54.3436H49.4474C49.4474 48.8358 56.7911 48.2238 56.7911 42.104C56.7911 36.6941 52.4093 32.3124 46.9994 32.3124Z" fill="#9D174D" />
                        </g>
                    </g>
                    <rect x="2.5" y="2.5" width="89" height="89" rx="44.5" stroke="#9D174D" strokeWidth="5" />
                    <defs>
                        <filter id="filter0_dd_2474_12158" x="-3.91724" y="3.79412" width="101.833" height="101.833" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="7.71094" />
                            <feGaussianBlur stdDeviation="13.2188" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2474_12158" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2.20313" />
                            <feGaussianBlur stdDeviation="4.77344" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.039 0" />
                            <feBlend mode="normal" in2="effect1_dropShadow_2474_12158" result="effect2_dropShadow_2474_12158" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2474_12158" result="shape" />
                        </filter>
                        <clipPath id="clip0_2474_12158">
                            <rect width="94" height="94" rx="47" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievementQuestionsAttemptedHighContrast;
