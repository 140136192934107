const loadClassStandardsStats = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { dateFrom, dateTo, classId } = state.navigation.params;

        if (!dateFrom || !dateTo || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        dispatch(actions.districtAdmin.setClassStandardsStatsLoading({
            classId,
            range,
        }));

        const res = await api.districtAdmin.getClassStandardsStatsByClassId({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.districtAdmin.setClassStandardsStats({
            classId,
            range,
            data,
            error,
        }));
    };
};

const reloadClassStandardsStats = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();
        const { dateFrom, dateTo, classId } = state.navigation.params;

        if (!dateFrom || !dateTo || !classId) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        const res = await api.districtAdmin.getClassStandardsStatsByClassId({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.districtAdmin.setClassStandardsStats({
            classId,
            range,
            data,
            error,
        }));
    };
};

const loadOrReloadClassStandardsStats = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const {
            dateFrom,
            dateTo,
            classId,
        } = state.navigation.params;

        if (!dateFrom || !dateTo || !classId) {
            return;
        }

        const { classStandardsStats } = state.districtAdmin;

        const range = `${dateFrom}-${dateTo}`;

        const isLoaded = classStandardsStats?.[classId]?.[range]?.data || false;

        if (isLoaded) {
            const reloadAction = reloadClassStandardsStats(services);
            reloadAction(dispatch, getState);
            return;
        }

        const loadAction = loadClassStandardsStats(services);
        loadAction(dispatch, getState);
    };
};

export default {
    loadClassStandardsStats,
    reloadClassStandardsStats,
    loadOrReloadClassStandardsStats,
};
