const getWordByTime = (highlight, time) => {
    const passedWords = [];
    let word = "";

    for (let i = 0; i < highlight.length; i += 1) {
        const hLight = highlight[i];

        if (hLight.time >= time) {
            word = hLight.value;
            break;
        }

        passedWords.push(hLight.value);
    }

    if (!word) {
        return null;
    }

    let skipSameWordCount = 0;

    for (let i = 0; i < passedWords.length; i += 1) {
        const passedWord = passedWords[i];

        if (passedWord === word) {
            skipSameWordCount += 1;
        }
    }

    return {
        word,
        skipSameWordCount,
    };
};

const getStoryCurrentWord = (playerCurrTime, highlight, storyTitle) => {
    if (!highlight?.length) {
        return {};
    }

    const playerCurrentTime = playerCurrTime || 0;

    const titleWordsCount = (storyTitle || "").split(" ").length || 0;

    const highlightTitle = highlight.slice(0, titleWordsCount);
    const wordTitle = getWordByTime(highlightTitle, playerCurrentTime);

    if (wordTitle) {
        return {
            ...wordTitle,
            isTitle: true,
        };
    }

    const highlightContent = highlight.slice(titleWordsCount);
    const wordContent = getWordByTime(highlightContent, playerCurrentTime);

    if (wordContent) {
        return {
            ...wordContent,
            isContent: true,
        };
    }

    return {};
};

const getStoryHighlight = (params) => {
    if (!params?.isVisible) {
        return {};
    }

    const { title, highlight, playerCurrentTime } = params;

    return getStoryCurrentWord(playerCurrentTime, highlight, title);
};

const getFilteredHighlights = (data) => {
    const highlight = [];

    for (let i = 0; i < data.length; i += 1) {
        const word = data[i].value || 0;
        const wordTime = data[i].time || 0;

        const wordsSplitted = word.split("-");
        const nexWordTime = data[i + 1]?.time || 0;
        const wordTimeDiff = (nexWordTime - wordTime) / wordsSplitted.length;

        for (let j = 0; j < wordsSplitted.length; j += 1) {
            let timeDiff = j * wordTimeDiff;

            if (Number.isNaN(timeDiff)) {
                timeDiff = 0;
            }

            highlight.push({
                time: wordTime + timeDiff,
                value: wordsSplitted[j],
            });
        }
    }

    return highlight;
};

export default {
    getStoryHighlight,
    getFilteredHighlights,
};
